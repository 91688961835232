<template>
  <div class="welcome">
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-layout column>
      <v-flex xs12 class="mb-5">
        <v-container>
          <v-card outlined style="border-radius: 25px" class="mt-5">
            <v-card-text>
              <v-layout column>
                <v-flex xs12>
                  <v-layout row wrap class="my-5">
                    <v-flex xs12 md3>
                      <div align="center" class="mt-2 mx-2">
                        <v-img v-if="investmentFirm.cover_image == null" src="img/investmentFirm.png" height="200px" style="z-index: 2;">                              
                          <v-avatar
                            size="86"
                            style="position: absolute; top: 110px; left: 3px; z-index: 10"
                          >
                            <v-img
                              v-if="investmentFirm.logo==null"
                              src="img/investmentFirm.png"
                            >
                            </v-img>
                            <v-img
                              v-else
                              :src="path+'/storage/investment_firm_pictures/'+investmentFirm.logo"
                            >
                            </v-img>
                          </v-avatar>
                        </v-img>
                        <v-img v-else :src="path+'/storage/investment_firm_pictures/'+investmentFirm.cover_image" height="200px" style="z-index: 2; border-radius: 25px;">                              
                          <v-avatar
                            size="86"
                            style="position: absolute; top: 110px; left: 3px; z-index: 10"
                          >
                            <v-img
                              v-if="investmentFirm.logo==null"
                              src="img/investmentFirm.png"
                            >
                            </v-img>
                            <v-img
                              v-else
                              :src="path+'/storage/investment_firm_pictures/'+investmentFirm.logo"
                            >
                            </v-img>
                          </v-avatar>
                        </v-img>
                      </div>
                    </v-flex>
                    <v-flex xs12 md5>
                      <div class="mx-3">
                        <v-layout column>
                          <v-flex xs12>
                            <div class="title" style="font-family: 'poppins' !important;">{{ investmentFirm.name }}</div>
                          </v-flex>
                          <v-flex xs12>
                            <div> 
                              <b v-if="investmentFirm.county_id != null">{{ investmentFirm.county.name }} - </b> {{ investmentFirm.investor_type.name }}
                            </div>
                            <div>
                              <i>Established {{ investmentFirm.establishment_year }} • Joined {{ investmentFirm.created_at | moment("YYYY") }}</i>
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div class="mt-5">{{ investmentFirm.brief }}</div>
                          </v-flex>
                        </v-layout>
                      </div>
                      
                    </v-flex>
                    <v-flex xs12 md4>                        
                      <div
                        class="title mt-2 mx-3"
                        v-if="investmentFirm.investment_firm_contact != null"
                      >
                        Get in touch
                      </div>
                      
                      <div
                        v-if="investmentFirm.investment_firm_contact != null"
                        align="center"
                      >
                        <a
                          v-if="investmentFirm.investment_firm_contact.fb_url != null"
                          :href="investmentFirm.investment_firm_contact.fb_url"
                          target="_blank"
                          style="text-decoration: none"
                        >
                          <v-btn class="mx-2 text--text" icon>
                            <v-icon size="30px" color="text">
                              mdi-facebook
                            </v-icon>
                          </v-btn>
                        </a>
                        <a
                          v-if="
                            investmentFirm.investment_firm_contact.twitter_url != null
                          "
                          :href="investmentFirm.investment_firm_contact.twitter_url"
                          target="_blank"
                          style="text-decoration: none"
                        >
                          <v-btn class="mx-2 text--text" icon>
                            <v-icon size="30px" color="text">
                              mdi-twitter
                            </v-icon>
                          </v-btn>
                        </a>
                        <a
                          v-if="
                            investmentFirm.investment_firm_contact.linkedin_url != null
                          "
                          :href="investmentFirm.investment_firm_contact.linkedin_url"
                          target="_blank"
                          style="text-decoration: none"
                        >
                          <v-btn class="mx-2 text--text" icon>
                            <v-icon size="30px" color="text">
                              mdi-linkedin
                            </v-icon>
                          </v-btn>
                        </a>
                        <a
                          v-if="investmentFirm.investment_firm_contact.insta_url != null"
                          :href="investmentFirm.investment_firm_contact.insta_url"
                          target="_blank"
                          style="text-decoration: none"
                        >
                          <v-btn class="mx-2 text--text" icon>
                            <v-icon size="30px" color="text">
                              mdi-instagram
                            </v-icon>
                          </v-btn>
                        </a>
                        <a
                          v-if="investmentFirm.investment_firm_contact.email != null"
                          :href="
                            'mailto:' + investmentFirm.investment_firm_contact.email
                          "
                          target="_blank"
                          style="text-decoration: none"
                        >
                          <v-btn class="mx-2 text--text" icon>
                            <v-icon size="30px" color="text">
                              mdi-at
                            </v-icon>
                          </v-btn>
                        </a>
                        <a
                          v-if="investmentFirm.investment_firm_contact.phone != null"
                          :href="'tel:' + investmentFirm.investment_firm_contact.phone"
                          target="_blank"
                          style="text-decoration: none"
                        >
                          <v-btn class="mx-2 text--text" icon>
                            <v-icon size="30px" color="text">
                              mdi-phone
                            </v-icon>
                          </v-btn>
                        </a>
                        <a
                          v-if="investmentFirm.investment_firm_contact.web_url != null"
                          :href="investmentFirm.investment_firm_contact.web_url"
                          target="_blank"
                          style="text-decoration: none"
                        >
                          <v-btn class="mx-2 text--text" icon>
                            <v-icon size="30px" color="text">
                              mdi-web
                            </v-icon>
                          </v-btn>
                        </a>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 class="my-3">
                  <div class="title" style="font-family: 'poppins' !important;">Share Via</div>
                  <div class="mt-1">
                    <v-btn
                      small
                      rounded
                      depressed
                      class="text-none white--text mr-1 mb-1"
                      color="#3b5998"
                    >
                      <v-icon>mdi-facebook</v-icon>
                      <ShareNetwork
                        style="text-decoration: none"
                        class="white--text ml-2"
                        network="facebook"
                        :url="shareLink"
                        :title="investmentFirm.name"
                        :description="investmentFirm.brief"
                        hashtags="InnovationBridge"
                      >
                        <span>Facebook</span>
                      </ShareNetwork>
                    </v-btn>
                    <v-btn
                      small
                      rounded
                      depressed
                      class="text-none white--text mr-1 mb-1"
                      color="#1DA1F2"
                    >
                      <v-icon>mdi-twitter</v-icon>
                      <ShareNetwork
                        style="text-decoration: none"
                        class="white--text ml-2"
                        network="twitter"
                        :url="shareLink"
                        :title="investmentFirm.name"
                        :description="investmentFirm.brief"
                        hashtags="InnovationBridge"
                      >
                        <span>Twitter</span>
                      </ShareNetwork>
                    </v-btn>

                    <v-btn
                      small
                      rounded
                      depressed
                      class="text-none white--text mr-1 mb-1"
                      color="#0e76a8"
                    >
                      <v-icon>mdi-linkedin</v-icon>
                      <ShareNetwork
                        style="text-decoration: none"
                        class="white--text ml-2"
                        network="linkedin"
                        :url="shareLink"
                        :title="investmentFirm.name"
                        :description="investmentFirm.brief"
                        hashtags="InnovationBridge"
                      >
                        <span>LinkedIn</span>
                      </ShareNetwork>
                    </v-btn>
                    <v-btn
                      small
                      rounded
                      depressed
                      class="text-none white--text mr-1 mb-1"
                      color="#075e54"
                    >
                      <v-icon>mdi-whatsapp</v-icon>
                      <ShareNetwork
                        style="text-decoration: none"
                        class="white--text ml-2"
                        network="whatsapp"
                        :url="shareLink"
                        :title="investmentFirm.name"
                        :description="investmentFirm.brief"
                        hashtags="InnovationBridge"
                      >
                        <span>Whatsapp</span>
                      </ShareNetwork>
                    </v-btn>
                    <v-btn
                      small
                      rounded
                      depressed
                      class="text-none white--text mr-1 mb-1"
                      color="#0088cc"
                    >
                      <v-icon>mdi-send mdi-rotate-315</v-icon>
                      <ShareNetwork
                        style="text-decoration: none"
                        class="white--text ml-2"
                        network="telegram"
                        :url="shareLink"
                        :title="investmentFirm.name"
                        :description="investmentFirm.brief"
                        hashtags="InnovationBridge"
                      >
                        <span>Telegram</span>
                      </ShareNetwork>
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-container>
      </v-flex>

      <v-flex xs12 class="mt-2">
        <v-progress-linear
          v-if="investmentLoader"
          height="1"
          indeterminate
          color="primary"
        >
        </v-progress-linear>
        <v-card outlined>
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 md8>
                <v-tabs show-arrows v-model="tab" class="mt-6">
                  <v-tabs-slider color="primary"></v-tabs-slider>
                  <v-tab @click="activeTab = 'about'">
                    <div class="text-none text--text">About</div>
                  </v-tab>
                  <v-tab
                    @click="activeTab = 'investments'"
                    v-if="$can('investment_view')"
                  >
                    <div class="text-none text--text">
                      Investments ({{ investmentPagination.total }})
                    </div>
                  </v-tab>
                </v-tabs>
                <div v-if="activeTab == 'about'">
                  <p class="mx-3 mt-6" v-html="investmentFirm.description"></p>
                </div>

                <div v-if="activeTab == 'investments'">
                  <v-layout column>
                    <v-flex xs12 class="mx-2">
                      <div v-if="investments.length == 0">
                        <v-card
                          outlined
                          class="my-5 mx-5"
                          style="border-radius: 25px"
                        >
                          <v-layout row wrap class="my-10">
                            <v-flex xs12 md2>
                              <v-container fill-height fluid>
                                <v-row align="center" justify="center">
                                  <v-col class="text-center">
                                    <v-icon large class="text--text">
                                      mdi-alert-circle-outline
                                    </v-icon>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md10>
                              <v-container fill-height fluid>
                                <v-row align="center" justify="center">
                                  <v-col class="text-center">
                                    No Investments on Record</v-col
                                  >
                                </v-row>
                              </v-container>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </div>
                      <div v-else>
                        <v-layout column class="mt-4">
                          <template v-for="(investment, index) in investments">
                            <div :key="index">
                              <v-flex xs12 class="mb-4">                                
                                <v-card outlined style="border-radius: 25px">
                                  <v-card-text>
                                    <v-layout row wrap>
                                      <v-flex xs2>
                                        <v-layout column>
                                          <v-flex xs12>
                                            <div
                                              align="center"
                                              class="text--text display-2 font-weight-black mt-3"
                                            >
                                              {{
                                                investment.created_at
                                                  | moment("Do")
                                              }}
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div
                                              align="center"
                                              class="text--text mb-3"
                                            >
                                              {{
                                                investment.created_at
                                                  | moment("MMM YYYY")
                                              }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs10>
                                        <div class="pa-5">
                                          <v-layout row wrap>
                                            <v-flex xs2>
                                              <b>Amount:</b>
                                            </v-flex>
                                            <v-flex xs10>
                                              <div>
                                                Kshs
                                                {{
                                                  intToString(
                                                    investment.amount
                                                  )
                                                }}
                                              </div>
                                            </v-flex>
                                          </v-layout>
                                        </div>
                                        <div class="mx-5">
                                          <v-layout row wrap>
                                            <v-flex xs2>
                                              <b>Funding Type:</b>
                                            </v-flex>
                                            <v-flex xs10>
                                              <div>
                                                {{
                                                  investment.funding_type.name
                                                }}
                                              </div>
                                            </v-flex>
                                          </v-layout>
                                        </div>
                                        <div class="pa-5 mt-5">
                                          <v-layout column>
                                            <div>
                                              <v-flex xs12 class="mb-4">
                                                <v-layout row wrap>
                                                  <v-flex xs12 md3>
                                                    <div align="center">
                                                      <v-img
                                                        max-height="170"
                                                        v-if="investment.innovation.featured_image==null"
                                                        src="img/innovation.jpg"
                                                        style="border-radius: 25px;"
                                                      >
                                                      </v-img>
                                                      <v-img
                                                        max-height="170"
                                                        v-else
                                                        :src="path+'/storage/innovation_pictures/'+investment.innovation.featured_image"
                                                        style="border-radius: 25px;"
                                                      >
                                                    </v-img>
                                                    </div>
                                                  </v-flex>
                                                  <v-flex xs12 md9>
                                                    <div class="ml-7">
                                                      <v-layout column>
                                                      <v-flex
                                                        xs12
                                                        class="mt-4"
                                                      >
                                                        <v-layout row wrap>
                                                          <v-flex xs2>
                                                            Name:
                                                          </v-flex>
                                                          <v-flex xs10>
                                                            <a @click="goToInnovation(investment.innovation)">
                                                              {{
                                                                investment.innovation.name
                                                              }}
                                                            </a>
                                                          </v-flex>
                                                        </v-layout>
                                                      </v-flex>
                                                      <v-flex
                                                        xs12
                                                        class="mt-5"
                                                      >
                                                        <v-layout row wrap>
                                                          <v-flex xs2>
                                                            Brief:
                                                          </v-flex>
                                                          <v-flex xs10>
                                                            {{
                                                              investment.innovation.brief
                                                            }}
                                                          </v-flex>
                                                        </v-layout>
                                                      </v-flex>
                                                    </v-layout>
                                                    </div>
                                                    
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                            </div>
                                          </v-layout>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-card-text>
                                </v-card>                                
                              </v-flex>
                            </div>
                          </template>
                        </v-layout>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="mx-2">
                      <div align="center" class="my-7" v-if="investmentPagination.total != 0">
                        <v-pagination
                          v-if="investmentLength != 0"
                          :length="investmentLength"
                          total-visible="10"
                          v-model="investmentPagination.current_page"
                          @input="getInvestments()"
                          circle
                        >
                        </v-pagination>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>
              <v-flex xs12 md4>
                <v-flex xs12>
                  <v-card
                    outlined
                    elevation="0"
                    class="pa-5 ma-3 mt-5 tableHeader"
                  >
                    <div class="body" style="font-family: 'poppins' !important"><b>Deal Size Range (min-max)</b></div>
                    <div class="caption primary--text" style="font-family: 'poppins' !important">
                      Kshs {{ investmentFirm.min_investment }} - {{ investmentFirm.max_investment }}
                    </div>
                    <div class="body mt-5" style="font-family: 'poppins' !important"><b>Total Investments</b></div>
                    <div class="caption primary--text" style="font-family: 'poppins' !important">
                      {{ investmentFirm.total_investments }}
                    </div>
                    <div class="body mt-5" style="font-family: 'poppins' !important"><b>Total Investment(s) Value</b></div>
                    <div class="caption primary--text" style="font-family: 'poppins' !important">
                      Kshs {{ investmentFirm.sum_investments }}
                    </div>
                  </v-card>
                </v-flex>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";
import VClamp from "vue-clamp";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadAdapter from '@/uploadAdapter.js';

export default {
  components: {
    VClamp,
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      loading: false,
      selection: 1,
      drawer: false,
      dialog: false,
      notFound: false,
      inputRules: [(v) => !!v || "Input is required"],

      investmentLoader: false,

      formData: null,
      valid: true,

      editor: ClassicEditor,
      editorConfig: {
          extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
              return new uploadAdapter(loader);
          } }],
          language: 'en',
      },

      tab: null,
      activeTab: "about",

      shareLink: null,

      investments: [],
      investmentPagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },

    };
  },
  mounted() {
    this.initialize();    
  },
  watch: {
    notFound() {
      this.findInvestmentFirm();
    },
  },
  created() {
    window.scrollTo(0, 0);
    this.createShareLink();
  },
  methods: {
    ...mapActions(["filterSearchInvestmentFirms"]),
    initialize() {
      this.getInvestments();
    },
    intToString(value) {
      var suffixes = ["", "k", "M", "B", "T"];
      var suffixNum = Math.floor(("" + value).length / 3);
      var shortValue = parseFloat(
        (suffixNum != 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(2)
      );
      if (shortValue % 1 != 0) {
        shortValue = shortValue.toFixed(1);
      }
      return shortValue + suffixes[suffixNum];
    },
    getInvestments() {
      this.investmentLoader = true;
      apiCall({
        url:
          "/api/investment?type=investmentFirm&page="+this.investmentPagination.current_page+"&investment_firm_id=" +
          this.investmentFirm.id,
        method: "GET",
      })
        .then((resp) => {
          this.investmentLoader = false;
          this.investments = resp.data;
          this.investmentPagination.current_page = resp.current_page;
          this.investmentPagination.total = resp.total;
          this.investmentPagination.per_page = resp.per_page;
        })
        .catch((error) => {
          this.message = "An Error Occurred";
          this.color = "error";
          this.snackbar = true;
        });
    },
    findInvestmentFirm() {
      apiCall({
        url:
          "/api/invsetment-firm?type=findInvestmentFirm&page=1&slug=" +
          this.$route.params.slug,
        method: "GET",
      })
        .then((resp) => {
          this.filterSearchInvestmentFirms(resp);
        })
        .catch((error) => {
          this.message = "An Error Occurred";
          this.color = "error";
          this.snackbar = true;
        });
    },
    createShareLink() {
      this.shareLink = this.path + "/#/investors/" + this.$route.params.slug;
    },
    goToInnovation(innovation) {
      this.$router.push("/innovations/" + innovation.slug);
      this.findInvestmentFirm();
    },
  },
  computed: {
    ...mapGetters([
      "investmentFirms",
      "investmentFirmPagination",
    ]),

    investmentFirm() {
      if (
        this.$store.getters.investmentFirms.find(
          (investmentFirm) => investmentFirm.slug == this.$route.params.slug
        )
      ) {
        return this.$store.getters.investmentFirms.find(
          (investmentFirm) => investmentFirm.slug == this.$route.params.slug
        );
      } else {
        this.notFound = true;
      }
    },
    investmentLength: function () {
      return Math.ceil(
        this.investmentPagination.total /
          this.investmentPagination.per_page
      );
    },
  },
};
</script>
