<template>
  <div class="message">
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-dialog
      width="700"
      transition="dialog-bottom-transition"
      scrollable
      v-model="messageDialog"
    >
      <v-card>
        <v-toolbar flat class="transparent">
          <v-toolbar-title>New Message</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="messageDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form ref="form">
          <v-layout column class="mx-3">
            <v-flex xs12 class="mt-3">
              <v-layout row wrap>
                <v-flex xs12 md2>
                  <div class="ml-7 mt-3">Contact</div>
                </v-flex>
                <v-flex xs12 md10>
                  <v-autocomplete
                    v-model="newMessage.recipient_id"
                    :loading="contactLoading"
                    :items="items"
                    :search-input.sync="searchUsers"
                    item-value="id"
                    item-text="f_name"
                    cache-items
                    class="text_field background my-2 mx-3"
                    flat
                    hide-no-data
                    hide-details
                    outlined
                    dense
                    :rules="inputRules"
                  >
                    <template v-slot:selection="{ item }">
                      {{ getText(item) }}
                    </template>
                    <template v-slot:item="{ item }">
                      {{ getText(item) }}
                    </template>
                  </v-autocomplete>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 class="mt-7">
              <v-layout row wrap>
                <v-flex xs12 md2>
                  <div class="ml-7 mt-3">Message</div>
                </v-flex>
                <v-flex xs12 md10>
                  <div class="mx-3 black--text">
                    <ckeditor
                      :editor="editor"
                      v-model="newMessage.body"
                      :config="editorConfig"
                      :rules="inputRules"
                    ></ckeditor>
                  </div>
                  <div class="ml-3 mr-5 mt-3">
                    <v-file-input
                      :loading="loading"
                      filled
                      dense
                      show-size
                      chips
                      accept="image/*"
                      v-model="imageFile"
                      label="Add Image"
                    ></v-file-input>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12> </v-flex>
          </v-layout>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            class="primary text--text text-none"
            @click="submit"
            :loading="loading"
          >
            Send
            <v-icon right>mdi-send</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container class="mt-4">
      <v-layout row wrap>
        <v-flex xs12 md4 style="overflow: hidden">
          <v-card outlined>
            <v-progress-linear
                  v-if="searchLoader"
                  height="1"
                  indeterminate
                  color="primary"
                >
                </v-progress-linear>
                  <div class="pa-5 mt-2">
                    <v-layout column>
                      <v-flex xs12>
                        <div align="center">
                          <v-btn
                            class="primary text-none"
                            depressed
                            @click="messageDialog = true"
                          >
                            New Message
                            <v-icon right> mdi-message-draw </v-icon>
                          </v-btn>
                        </div>
                      </v-flex>
                      <v-divider class="mx-2 mt-5"></v-divider>
                      <v-flex xs12>
                        <v-text-field class="search_field background my-3" outlined dense
                            v-model="searchTerm" append-icon="mdi-magnify" required @click:append="search()"
                            v-on:keyup.enter="search()"></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <div
                          id="contactList"
                          @scroll="loadContacts"
                          style="
                            margin: 1px, 1px;
                            padding: 1px;
                            width: auto;
                            height: 72vh;
                            overflow-x: hidden;
                            overflow-y: scroll;
                            text-align: justify;
                          "
                        >
                          <div v-if="chatsClone.length == 0">
                            <v-card outlined router>
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <div align="center" class="my-10">
                                    <v-avatar color="primary" size="96">
                                      <v-icon class="text--text">
                                        mdi-file-document-alert
                                      </v-icon>
                                    </v-avatar>
                                  </div>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <v-layout column>
                                    <v-flex xs12 class="mt-1">
                                      <div class="mt-15 ml-5 title">
                                        No Chat History Found..
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </div>
                          <div v-else>
                            <template v-for="(chat, index) in chatsClone">
                            <a
                              :key="index"
                              v-if="activeChat != null && activeChat.id == chat.id"
                            >
                            
                              <v-card
                                class="mx-auto tableHeader"
                                outlined
                                
                                @click="assignChat(chat)"
                              >
                                <v-list-item three-line>
                                  <v-list-item-avatar color="primary" size="42" v-if="chat.sender.id != getProfile.id">
                                    <v-icon
                                      v-if="chat.sender.display_img == null"
                                      class="white--text"
                                    >
                                      {{ chat.sender.f_name[0] }}
                                    </v-icon>
                                    <img
                                      v-if="chat.sender.display_img != null"
                                      :src="path+'/storage/profile_pics/'+chat.sender.display_img"
                                      alt="pic"
                                    />
                                  </v-list-item-avatar>
                                  <v-list-item-avatar color="primary" size="42" v-else>
                                    <v-icon
                                      v-if="chat.recipient.display_img == null"
                                      class="white--text"
                                    >
                                      {{ chat.recipient.f_name[0] }}
                                    </v-icon>
                                    <img
                                      v-if="chat.recipient.display_img != null"
                                      :src="path+'/storage/profile_pics/'+chat.recipient.display_img"
                                      alt="pic"
                                    />
                                  </v-list-item-avatar>
                                  <v-list-item-content>
                                    <div class="mb-1 text-none body-2 mt-3">
                                      <v-layout row wrap>
                                        <v-flex xs11>
                                          <div v-if="chat.sender.id != getProfile.id" class="ml-3">
                                            {{ chat.sender.f_name }}
                                            {{ chat.sender.m_name }}
                                            {{ chat.sender.l_name }}
                                          </div>
                                          <div v-else class="ml-3">
                                            {{ chat.recipient.f_name }}
                                            {{ chat.recipient.m_name }}
                                            {{ chat.recipient.l_name }}
                                          </div>
                                        </v-flex>
                                        <v-flex xs1>
                                          <div class="red--text" v-if="chat.unread_messages!=0">
                                            {{ chat.unread_messages }}
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </div>
                                    <div
                                      v-if="chat.last_message_data.message_id != null"
                                    >
                                      <v-list-item-subtitle
                                        class="caption mr-10 mt-2"
                                      >
                                        <p
                                          v-html="
                                            chat.last_message_data.message
                                              .text_message.body
                                          "
                                        ></p>
                                      </v-list-item-subtitle>
                                    </div>
                                    <div
                                      v-if="chat.last_message_data.message_id != null"
                                      align="right"
                                      class="caption primary--text"
                                    >
                                      {{
                                        chat.last_message_data.message.created_at
                                          | moment("from", true)
                                      }}
                                    </div>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-card>
                          
                            </a>
                            <a :key="index + 1000" v-else>
                              <v-card
                                class="mx-auto"
                                outlined
                                
                                @click="assignChat(chat)"
                              >
                                <v-list-item three-line>
                                  <v-list-item-avatar color="primary" size="42" v-if="chat.sender.id != getProfile.id">
                                    <v-icon
                                      v-if="chat.sender.display_img == null"
                                      class="white--text"
                                    >
                                      {{ chat.sender.f_name[0] }}
                                    </v-icon>
                                    <img
                                      v-if="chat.sender.display_img != null"
                                      :src="path+'/storage/profile_pics/'+chat.sender.display_img"
                                      alt="pic"
                                    />
                                  </v-list-item-avatar>
                                  <v-list-item-avatar color="primary" size="42" v-else>
                                    <v-icon
                                      v-if="chat.recipient.display_img == null"
                                      class="white--text"
                                    >
                                      {{ chat.recipient.f_name[0] }}
                                    </v-icon>
                                    <img
                                      v-if="chat.recipient.display_img != null"
                                      :src="path+'/storage/profile_pics/'+chat.recipient.display_img"
                                      alt="pic"
                                    />
                                  </v-list-item-avatar>
                                  <v-list-item-content>
                                    <div class="mb-1 text-none body-2 mt-3">
                                      <v-layout row wrap>
                                        <v-flex xs11>
                                          <div v-if="chat.sender.id != getProfile.id" class="ml-3">
                                            {{ chat.sender.f_name }}
                                            {{ chat.sender.m_name }}
                                            {{ chat.sender.l_name }}
                                          </div>
                                          <div v-else class="ml-3">
                                            {{ chat.recipient.f_name }}
                                            {{ chat.recipient.m_name }}
                                            {{ chat.recipient.l_name }}
                                          </div>
                                        </v-flex>
                                        <v-flex xs1>
                                          <div class="red--text" v-if="chat.unread_messages!=0">
                                            {{ chat.unread_messages }}
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </div>
                                    <div
                                      v-if="chat.last_message_data.message_id != null"
                                    >
                                      <v-list-item-subtitle
                                        class="caption mr-10 mt-2"
                                      >
                                        <p
                                          v-html="
                                            chat.last_message_data.message
                                              .text_message.body
                                          "
                                        ></p>
                                      </v-list-item-subtitle>
                                    </div>
                                    <div
                                      v-if="chat.last_message_data.message_id != null"
                                      align="right"
                                      class="caption primary--text"
                                    >
                                      {{
                                        chat.last_message_data.message.created_at
                                          | moment("from", true)
                                      }}
                                    </div>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-card>
                            </a>
                            <v-divider class="mx-1"></v-divider>
                          </template>
                    </div>
                    
                  </div>
                </v-flex>
              </v-layout>
            </div>
          </v-card>
        </v-flex>
        <v-flex xs12 md8 style="overflow: hidden">
          <v-layout column>
            <v-flex xs12 class="mx-3">
              <!-- <v-card outlined class="pa-5">
                <v-layout row wrap>
                  <v-flex xs2>
                    <div v-if="activeChat != null">
                      <v-list-item-avatar size="42" color="primary">
                        <v-icon
                          v-if="activeChat.sender.id != getProfile.id"
                          class="text--text"
                          >{{ activeChat.sender.f_name[0] }}</v-icon
                        >
                        <v-icon v-else class="text--text">{{
                          activeChat.recipient.f_name[0]
                        }}</v-icon>
                      </v-list-item-avatar>
                    </div>
                  </v-flex>
                  <v-flex xs7>
                    <div v-if="activeChat != null">
                      <div
                        class="mt-4"
                        v-if="activeChat.sender.id != getProfile.id"
                      >
                        <b
                          >{{ activeChat.sender.f_name }}
                          {{ activeChat.sender.m_name }}
                          {{ activeChat.sender.l_name }}</b
                        >
                      </div>
                      <div class="mt-4" v-else>
                        <b
                          >{{ activeChat.recipient.f_name }}
                          {{ activeChat.recipient.m_name }}
                          {{ activeChat.recipient.l_name }}</b
                        >
                      </div>
                    </div>
                  </v-flex>
                  <v-flex xs2> </v-flex>
                </v-layout>
              </v-card> -->
              <v-card outlined style="border-radius: 25px;">
                <v-progress-linear
                  v-if="previousMessagesLoading"
                  height="1"
                  indeterminate
                  color="primary"
                >
                </v-progress-linear>
                <div
                  id="chatList"
                  @scroll="loadPreviousMessages"
                  class="pa-5"
                  style="
                    margin: 1px, 1px;
                    padding: 1px;
                    width: auto;
                    height: 59vh;
                    overflow-x: hidden;
                    overflow-y: scroll;
                    text-align: justify;
                  "
                >
                  <div v-if="messages.length == 0">
                    <v-card outlined style="border-radius: 25px;">
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <div align="center" class="my-10">
                                    <v-avatar color="primary" size="96">
                                      <v-icon class="white--text">
                                        mdi-file-document-alert
                                      </v-icon>
                                    </v-avatar>
                                  </div>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <v-layout column>
                                    <v-flex xs12 class="mt-1">
                                      <div class="mt-15 ml-5 title">
                                        No Conversation Selected..
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card>
                  </div>
                  <div v-else>
                    <template v-for="(message, index) in messages">
                    <div
                      :key="index"
                      align="right"
                      v-if="message.user_id == getProfile.id"
                      class="my-3"
                    >
                      <v-layout column>
                        <v-flex xs12>
                            
                                <v-card
                                    class="tableHeader pa-3"
                                    elevation="0"
                                    max-width="574"
                                    style="border-radius: 25px;"
                                >
                                    <p
                                    v-html="message.message.text_message.body"
                                    class="text--text"
                                    ></p>
                                </v-card>
                            
                        </v-flex>
                        <v-flex xs12>
                          <div class="caption">
                            {{
                              message.message.created_at | moment("from", true)
                            }}<i v-if="message.message.text_message.read_at != null"> • Read on {{ message.message.text_message.read_at | moment("ddd Do MMM, YYYY - h:mm a") }}</i>
                          </div>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div :key="index+1000" v-else class="my-3">
                      <v-layout column>
                        <v-flex xs12>
                            
                        <v-card
                          style="border-radius: 25px;"
                          class="primary lighten-5 pa-3 white--text"
                          elevation="0"
                          max-width="574"
                          v-if="message.message.text_message.read_at == null && message.user_id != getProfile.id"
                        >
                        
                          <p v-html="message.message.text_message.body">
                          </p>
                        </v-card>
                            
                        <v-card
                            style="border-radius: 25px;"
                            class="primary pa-3 white--text"
                            elevation="0"
                            max-width="574"
                            v-if="message.user_id != getProfile.id && message.message.text_message.read_at != null"
                          >
                            <p v-html="message.message.text_message.body"></p>
                          </v-card>
                        </v-flex>
                        <v-flex xs12>
                          <div class="caption">
                            {{
                              message.message.created_at | moment("from", true)
                            }}
                          </div>
                        </v-flex>
                      </v-layout>
                    </div>
                  </template>
                  </div>

                  
                </div>
              </v-card>
            </v-flex>
            <v-flex xs12 v-if="activeChat != null">
              <v-card outlined class="mx-3 mt-3 pa-5">
                <v-layout row wrap>
                  <v-flex xs12 class="black--text">
                    <ckeditor
                      :editor="editor"
                      v-model="messageData.body"
                      :config="editorConfig"
                      :rules="inputRules"
                      
                    ></ckeditor>
                  </v-flex>
                  <v-flex xs12>
                    <div align="right">
                      <v-btn
                        depressed
                        class="primary white--text mt-2 text-none"
                        :loading="messageLoader"
                        @click="sendMessage()"
                      >
                        Send
                        <v-icon small right>mdi-send</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 v-if="activeChat == null">
              <v-card outlined class="mx-3 mt-3 pa-5">
                <v-layout row wrap>
                  <v-flex xs12>
                    <ckeditor
                      :editor="editor"
                      v-model="newMessage.body"
                      :config="editorConfig"
                      :rules="inputRules"
                      disabled
                    ></ckeditor>
                  </v-flex>
                  <v-flex xs12>
                    <div align="right">
                      <v-btn
                        depressed
                        class="primary text--text mt-2 text-none"
                        disabled
                      >
                        Send
                        <v-icon small>mdi-send</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<style>
/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
<script>
import apiCall from "@/utils/api";
import Vue from "vue";
import VueChatScroll from "vue-chat-scroll";
import { mapGetters, mapActions } from "vuex";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadAdapter from '@/uploadAdapter.js';

Vue.use(VueChatScroll);
Vue.use(require("vue-moment"));

export default {
  name: "Home",
  components: {},
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: null,
      message: null,
      snackbar: false,
      messages: [],
      chatsClone: [],
      activeChat: null,
      contactLoading: false,
      previousMessagesLoading: false,
      expiredTime: false,
      searchLoader: false,
      searchTerm: null,
      inputRules: [(v) => !!v || "Input is Required"],
      reply: {
        chat_id: null,
        text: null,
      },

      editor: ClassicEditor,
      editorConfig: {
          extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
              return new uploadAdapter(loader);
          } }],
          language: 'en',
      },

      messageDialog: false,
      newMessage: {
        recipient_id: null,
        body: null,
      },
      messageData: {
        id: null,
        chat_id: null,
        sender_id: null,
        recipient_id: null,
        body: null,
        message: {
          created_at: null,
          text_message: {
            body: null,
          },
        },
        created_at: null,
      },
      messageLoader: false,
      items: [],
      searchUsers: null,
      chatPage: 2,
      contactPage: 2,
      filteredChats: false,

      loading: false,
      fileRules: [(v) => !!v || "Kindly Select a File"],
      imageFile: null,
      imageValid: true,
    };
  },
  created() {
    this.fetchChats();
    this.chatsClone = this.chats;
  },
  watch: {
    searchUsers(val) {
      val && val !== this.newMessage.user_id && this.findUser(val);
    },
    notification: function (newNotification, oldNotification) {
      if (this.notification.type == "App\\Notifications\\NewMessage") {
        if (
          this.activeChat != null &&
          this.activeChat.id == this.notification.data.chat.id
        ) {
          const lastMessage = this.messages[this.messages.length - 1];

          const message = new Object();
          message.id = lastMessage.id + 1;
          if(this.notification.data.chat.sender_id == this.getProfile.id){
            message.user_id = this.notification.data.chat.recipient_id;
          }else{
            message.user_id = this.notification.data.chat.sender_id;
          }
          
          message.created_at = this.now;

          const messageData = new Object();
          messageData.created_at = this.now;

          const messageBody = new Object();
          messageBody.body = this.notification.data.textMessage.body;

          messageData.text_message = messageBody

          message.message = messageData
          
          this.messages.push(message);
          this.$nextTick(() => {
            var div = document.getElementById("chatList");
            div.scrollTop = div.scrollHeight;
          });

          apiCall({
            url: "/api/chat?type=markAsRead",
            data: this.notification.data.chat,
            method: "POST",
          })
            .then((resp) => {        
              this.fetchUnreadMessages()
            })
            .catch((error) => {
              
            });
        } else {
          // this.fetchChats();
        }
      }
    },
    chats: function (newUserChat, oldUserChat) {
      this.chatsClone = this.chats;
    },
  },
  mounted() {},
  methods: {
    ...mapActions([
      "fetchChats",
      "filterChats",
      "fetchUnreadMessages"
    ]),
    search(){
      this.filteredChats = true
      this.searchLoader = true;
      apiCall({
            url: "/api/chat?type=search&search=" + this.searchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.filterChats(resp)
              this.searchLoader = false;
            })
            .catch((error) => {
              this.searchLoader = false;
            });
    },
    getText(item) {
      return (
        item.f_name + " " + item.m_name + " " + item.l_name + " #" + item.code
      );
    },
    findUser(v) {
      this.contactLoading = true;
      if (this.expiredTime == false) {
        this.expiredTime = true;
        setTimeout(() => {
          apiCall({
            url: "/api/chat?type=friendships&search=" + this.searchUsers,
            method: "GET",
          })
            .then((resp) => {
              this.expiredTime = false;
              this.items = resp.data;
            })
            .catch((error) => {
              this.expiredTime = false;
              this.contactLoading = false;
            });

          this.contactLoading = false;
        }, 3000);
      }
    },
    assignChat(chat) {
        this.messageData.chat_id = null
        this.activeChat = null
        this.messages = [];
        this.messageData.chat_id = chat.id;
        this.activeChat = chat;
        this.messages = chat.messages;

        this.$nextTick(() => {
          var div = document.getElementById("chatList");
          div.scrollTop = div.scrollHeight;
        });

        apiCall({
          url: "/api/chat?type=markAsRead",
          data: chat,
          method: "POST",
        })
          .then((resp) => {
            if(this.filteredChats==true){
              this.search()
            }else{
              this.fetchChats();
            }            
            this.fetchUnreadMessages()
          })
          .catch((error) => {
            
          });
    },
    submit() {
      if (this.$refs.form.validate()) {
        if (this.newMessage.body == null) {
          this.message = "Please enter your message";
          this.color = "error";
          this.snackbar = true;
        } else {
          this.loading = true;
          apiCall({
            url: "/api/message?type=new",
            data: this.newMessage,
            method: "POST",
          })
            .then((resp) => {
              this.loading = false;
              this.messageDialog = false;
              this.newMessage.body = null;
              this.newMessage.recipient_id = null;
              this.fetchChats();
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      }
    },
    sendMessage() {
      if (this.messageData.body == null) {
        this.message = "Please enter your message";
        this.color = "error";
        this.snackbar = true;
      } else {
        this.messageLoader = true;
        const lastMessage = this.messages[this.messages.length - 1];

        const message = new Object();
        message.id = lastMessage.id + 1;
        message.user_id = this.getProfile.id;
        message.created_at = this.now;

        const messageData = new Object();
        messageData.created_at = this.now;

        const messageBody = new Object();
        messageBody.body = this.messageData.body;

        messageData.text_message = messageBody

        message.message = messageData

        this.messages.push(message);

        this.$nextTick(() => {
          var div = document.getElementById("chatList");
          div.scrollTop = div.scrollHeight;
        });

        apiCall({
          url: "/api/message",
          data: this.messageData,
          method: "POST",
        })
          .then((resp) => {
            this.messageLoader = false;
            this.messageData.body = null;
            this.fetchChats();
          })
          .catch((error) => {
            this.messageLoader = false;
          });
      }
    },
    loadPreviousMessages() {
      var div = document.getElementById("chatList");
      if (div.scrollTop == 0 && this.previousMessagesLoading == false) {
        this.previousMessagesLoading = true;
        apiCall({
          url:
            "/api/chat?type=messageHistory&chatId=" +
            this.activeChat.id +
            "&page=" +
            this.chatPage,
          method: "GET",
        })
          .then((resp) => {
            this.previousMessagesLoading = false;
            if (resp.data.length != 0) {
              this.chatPage += 1;
            } else {
              this.message = "No More Messages";
              this.color = "orange";
              this.snackbar = true;
            }

            for (var i = 0; i < resp.data.length; i++) {
              this.messages.unshift(resp.data[i]);
            }
          })
          .catch((error) => {
            this.previousMessagesLoading = false;
          });
      }
    },
    loadContacts() {
      var div = document.getElementById("contactList");
      const maxScroll = div.scrollHeight - div.clientHeight;
      const scrollBottom = maxScroll - div.scrollTop;
      
      // if (scrollBottom <= 0 && this.searchLoader == false) {
      //   this.searchLoader = true;
      //   apiCall({
      //     url:
      //       "/api/chat?&page=" + this.contactPage,
      //     method: "GET",
      //   })
      //     .then((resp) => {
      //       this.searchLoader = false;
      //       if (resp.data.length != 0) {
      //         this.contactPage += 1;
      //       } else {
              // this.message = "No More Messages";
              // this.color = "orange";
              // this.snackbar = true;
            // }
            
            // let respCopy = resp;
            // console.log("here 1", respCopy)
            // console.log("this.chats 1", this.chats)
            // respCopy.data.unshift(this.chats)
            // console.log("here 2", respCopy)
            // console.log("this.chats 2", this.chats)
            // let chatsCopy = this.chats
            // chatsCopy.push(Object.assign({},this.cartItem))

          //   let dataCopy = resp.data            

          //   console.log("chatsCopy chatsCopy", chatsCopy.concat(dataCopy))

          //   dataCopy.resp = []

          //   dataCopy.resp.push(Object.assign({}, chatsCopy.concat(dataCopy)));

          //   console.log("dataCopy", dataCopy)

          //   let data = {
          //     current_page : resp.current_page,
          //     data  : chatsCopy.concat(dataCopy),
          //     age     : 50,
          //     eyeColor  : "blue"
          //   };
            
          //   this.filterChats(dataCopy)

          // })
          // .catch((error) => {
          //   this.searchLoader = false;
          // });
      // }
    },
  },
  computed: {
    ...mapGetters(["chats", "notification", "getProfile"]),
    now() {
      var today = new Date();

      // var timestamp =
      //   today.getFullYear() +
      //   "-" +
      //   this.months[today.getMonth()] +
      //   "-" +
      //   ("0" + today.getDate()).slice(-2);
      return today;
    },
  },
};
</script>
