import apiCall from '@/utils/api'

const state = {
	counties: [],
	allCounties: [],
    countyPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    countyLoader: false
};

const getters = {
	counties: (state) => state.counties,
	allCounties: (state) => state.allCounties,
    countyLoader: (state) => state.countyLoader,
    countyPagination: (state) => state.countyPagination
}

const actions = {
	async fetchAllCounties({commit}, page) {
		const response = await apiCall({url: `/api/county?type=all`, method: 'GET' });
		commit('setAllCounties', response)
        commit('stopLoader', response)
	},
    async fetchCounties({commit}, page) {
		const response = await apiCall({url: `/api/county?page=${page}`, method: 'GET' });
		commit('setCounties', response)
        commit('stopLoader', response)
	},
    async filterCounties({commit, state},resp){
		commit('setCounties', resp)
	},
	async startInterestLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopInterestLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setCounties: (state, counties) => {
		state.counties = counties.data
		state.countyPagination.current_page = counties.current_page
		state.countyPagination.total = counties.total
		state.countyPagination.per_page = counties.per_page
	},
	setAllCounties: (state, counties) => {
		state.allCounties = counties
	},
    startLoader: (state) => state.countyLoader = true,
	stopLoader: (state) => state.countyLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
