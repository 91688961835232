<template>
  <div>
    <!-- <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
              <span>{{message}}</span>
          </v-snackbar> -->
    <v-dialog v-model="loginCardState" persistent transition="dialog-bottom-transition" max-width="700">
      <v-card :loading="loading" class="elevation-1" max-width="700">
        <template slot="progress">
          <v-progress-linear color="primary" height="2" indeterminate></v-progress-linear>
        </template>
        <v-toolbar flat color="transparent">
          <v-btn icon @click="changeFormLogin" v-if="authKind != 'login'">
            <v-icon large>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn icon @click="changeLoginCardState">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-layout column>
            <v-flex xs12>
              <v-layout row wrap>
                <v-flex xs12 md6>
                  <div align="center">
                    <v-img max-width="250" src="img/logo_primary.png"> </v-img>
                  </div>
                </v-flex>
                <v-flex xs12 md6>
                  <div align="center">
                    <v-img max-width="160" src="img/logo_kenia.png"> </v-img>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>

        <div v-if="authKind == 'login'">
          <v-form ref="loginForm" v-model="valid" lazy-validation>
            <v-card-text>
              <div class="mb-5 mx-10">
                <v-alert class="mx-2 mt-2" v-if="snackbar" dense text dismissible :type="alertType">
                  {{ message }}
                </v-alert>
                <div><b>Email</b></div>
                <v-text-field outlined dense v-model="email" :rules="emailRules" prepend-inner-icon="mdi-email-outline"
                  required class="background login_field"></v-text-field>
                <div class="mt-5"><b>Password</b></div>
                <v-text-field outlined dense v-model="password" prepend-inner-icon="mdi-lock-outline"
                  :rules="inputRules" type="password" class="background login_field "></v-text-field>
                <v-btn depressed block large color="primary" :disabled="!valid" @click="login" :loading="loading"
                  class="text-none mt-7">
                  Login
                  <v-icon right>mdi-login</v-icon>
                </v-btn>
                <v-btn depressed block large color="secondary" @click="changeForm" class="text-none mt-4 primary--text">
                  Create an account
                  <v-icon right>mdi-account-plus</v-icon>
                </v-btn>
                <v-btn class="text--text text-none mt-4" text block large @click="changeFormPassword">Forgot Password?
                </v-btn>
              </div>
            </v-card-text>
          </v-form>
        </div>
        <div v-if="authKind == 'create'">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <v-alert class="mx-2 mt-2" v-if="snackbar" dense text dismissible :type="alertType">
                  {{ message }}
                </v-alert>
              <div class="my-5 mx-10">
                <div><b>First Name</b><span class="red--text">*</span></div>
                <v-text-field outlined dense v-model="userData.f_name" :rules="inputRules"
                  prepend-inner-icon="mdi-account-outline" class="background login_field"></v-text-field>
                  <div class="mt-5"><b>Middle Name</b></div>
                <v-text-field outlined dense v-model="userData.m_name"
                  prepend-inner-icon="mdi-account-outline" class="background login_field"></v-text-field>
                  <div class="mt-5"><b>Last Name</b><span class="red--text">*</span></div>
                <v-text-field outlined dense v-model="userData.l_name" :rules="inputRules"
                  prepend-inner-icon="mdi-account-outline" class="background login_field"></v-text-field>
                  <div class="mt-5"><b>Phone Number</b><span class="red--text">*</span></div>
                <v-text-field outlined dense v-model="userData.phone_number" :rules="inputRules"
                  prepend-inner-icon="mdi-phone" class="background login_field"></v-text-field>
                <div class="mt-5"><b>Email</b><span class="red--text">*</span></div>
                <v-text-field outlined dense v-model="userData.email" :rules="emailRules"
                  prepend-inner-icon="mdi-email-outline" required class="background login_field"></v-text-field>
                <div class="mt-5"><b>Password</b><span class="red--text">*</span><span class="red--text">*</span></div>
                <v-text-field outlined dense v-model="userData.password" :rules="passwordRules"
                  type="password" prepend-inner-icon="mdi-lock-outline" class="background login_field"></v-text-field>
                <div class="mt-5"><b>Confirm Password</b><span class="red--text">*</span></div>
                <v-text-field outlined dense v-model="confirmpassword" :rules="passwordConfirmRules"
                  type="password" prepend-inner-icon="mdi-lock-outline"
                  class="background login_field"></v-text-field>
                <v-btn depressed block large color="primary" :disabled="!valid" @click="create" :loading="loading"
                  class="text-none mt-7">
                  Create
                  <v-icon right>mdi-account-plus-outline</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-form>
        </div>
        <div v-if="authKind == 'password'">
          <v-form ref="passwordForm" v-model="valid" lazy-validation>
            <v-card-text>
              <div class="my-5 mx-10">
                <v-text-field outlined dense v-model="userData.email" :rules="emailRules"
                  prepend-inner-icon="mdi-email-outline" label="E-mail" required class="background login_field">
                </v-text-field>
                <v-btn depressed block large color="primary" :disabled="!valid" @click="changePassword"
                  :loading="loading" class="text-none mt-7">
                  Send me a link..
                  <v-icon right>mdi-login</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { AUTH_REQUEST } from "@/store/actions/auth";
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "AuthPopUp",

  data: () => {
    return {
      alertType: "",
      authKind: "login",
      snackbar: false,
      message: "",
      color: "",
      loading: false,
      valid: true,

      confirmpassword: "",

      passwordConfirmRules: [(v) => !!v || " "],

      inputRules: [(v) => !!v || "Input is Required"],

      password: "",
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 8) || "Minimum 8 characters",
        (v) =>
          /(?=.*[A-Z])/.test(v) || "Must have at least one uppercase character",
        (v) => /(?=.*\d)/.test(v) || "Must have at least one number",
        (v) =>
          /([!@#$%.])/.test(v) ||
          "Must have at least one special character [!@#$%.]",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      userData: {
        f_name: null,
        m_name: null,
        l_name: null,
        phone_number: null,
        email: null,
        password: null,
      },
    };
  },

  methods: {
    ...mapActions(["changeLoginCardState"]),
    reset() {
      this.$refs.form.reset();
    },
    login: function () {
      console.log(this.$store);
      if (this.$refs.loginForm.validate()) {
        this.loading = true;
        const { email, password } = this;
        this.$store
          .dispatch(AUTH_REQUEST, { email, password })
          .then((response) => {
            this.$store.dispatch("changeLoginCardState");
            this.loading = false;
            this.$router.push("/redirector");
          })
          .catch((response) => {
            this.message = "Wrong email or password/Account is not activated.";
            this.loading = false;
            this.snackbar = true;
            this.alertType = "error";
          });
      }
    },
    goToInterests(){
      this.$router.push("/interests");
    },
    changePassword() {
      if (this.$refs.passwordForm.validate()) {
        this.loading = true;
        apiCall({
          url: "/api/reset-password",
          data: this.userData,
          method: "POST",
        })
          .then((resp) => {
            this.message = "We've sent you a reset link via email.";
            this.color = "success";
            this.loading = false;
            this.snackbar = true;
            this.authKind = "login";
            this.alertType = "success";
          })
          .catch((error) => {
            this.message = "Account does not exist";
            this.loading = false;
            this.snackbar = true;
            this.alertType = "error";
          });
      }
    },
    create() {
      if (this.$refs.form.validate()) {
        if (this.userData.password != this.confirmpassword) {
          this.message = "Passwords do not match";
          this.color = "error";
          this.snackbar = true;
          this.alertType = "error";
        } else {
          this.loading = true;
          apiCall({ url: "/api/register", data: this.userData, method: "POST" })
            .then((resp) => {
              if(resp.status == 409){
                this.message = resp.message
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
                this.alertType = "error";
              }else{
                this.message =
                "Account Created. We've sent you an activation link via email.";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.authKind = "login";
                this.alertType = "success";
              }
              
            })
            .catch((error) => {
              console.log(error)
              this.message = "An error occurred; Please try again.";
              this.loading = false;
              this.snackbar = true;
              this.alertType = "error";
            });
        }
      }
    },
    changeForm() {
      this.authKind = "create";
      this.snackbar = false;
    },
    changeFormLogin() {
      this.authKind = "login";
      this.snackbar = false;
    },
    changeFormPassword() {
      this.authKind = "password";
      this.snackbar = false;
    },
  },
  computed: {
    ...mapGetters(["loginCardState", "nationalities", "genders"]),
    ...mapState({
      authLoading: (state) => state.auth.status === "loading",
    }),
  },
};
</script>