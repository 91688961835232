<template>
  <div class="openCalls" v-if="$can('call_view')">
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="imageDialog"
      >
        <v-card max-width="600">
          <v-toolbar flat>
            Upload Feature Image
            <v-spacer></v-spacer>
            <v-btn icon @click="imageDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
  
          <v-form ref="imageForm" v-model="imageValid" lazy-validation>
            <v-layout column>
              <v-flex xs12>
                <v-file-input
                  class="mx-2 mt-3"
                  :loading="imageLoading"
                  :rules="fileRules"
                  filled
                  dense
                  show-size
                  chips
                  accept="image/*"
                  v-model="imageFile"
                  label="Select Image"
                ></v-file-input>
              </v-flex>
              <v-flex xs12>
                <div class="mx-3 mb-5">
                  <v-btn
                    depressed
                    block
                    class="primary text-none"
                    @click="uploadImage"
                    :loading="imageLoading"
                    :disabled="!imageValid"
                  >
                    Submit <v-icon right dark>mdi-upload</v-icon>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card>
      </v-dialog>
    <v-container>
      <v-card outlined class="mt-5" v-if="view == 'open' || view == 'closed'">
        <v-tabs show-arrows v-model="viewTab">
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab @click="changeView('open')">
            <div class="text-none text--text">Open Calls ({{ openCallPagination.total }})</div>
          </v-tab>
          <v-tab @click="changeView('closed')">
            <div class="text-none text--text">Closed Calls ({{ closedCallPagination.total }})</div>
          </v-tab>

        </v-tabs>
      </v-card>

      <div v-if="view == 'open'">
        <v-layout column>
          <v-card outlined class="mt-2">
            <v-progress-linear v-if="openCallLoader" height="1" indeterminate color="primary"></v-progress-linear>
            <v-flex xs12 class="mt-10 mb-2">
              <div class="mx-5">
                <v-layout row wrap>
                  <v-flex xs12 md4>
                    <h1> </h1>
                  </v-flex>
                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-text-field class="text_field header" outlined dense label="Search"
                        append-icon="mdi-undo-variant" @click:append="resetOpenCallSearch()"
                        v-on:keyup.enter="searchOpenCall" v-model="openCallSearchTerm"
                        @input="enableOpenCallSearch()"></v-text-field>
                    </div>
                  </v-flex>
                  <v-flex xs12 md2>
                    <div class="pa-1">
                      <v-btn class="secondary primary--text text-none" depressed block @click="searchOpenCall"
                        :loading="searchOpenCallLoader" :disabled="openCallSearchButtonDisabled">
                        Search
                        <v-icon right>mdi-magnify</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs12 md2>
                    <div class="pa-1">
                      <v-btn block depressed class="primary text-none" @click="changeView('create')"
                        v-if="$can('call_create')">
                        Add Call
                        <v-icon right>mdi-plus-circle-outline</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
            <v-divider class="mt-9 mx-5"></v-divider>
            <v-flex xs12 class="mt-5">
              <div v-if="openCalls.length == 0">
                <v-card elevation="0">
                  <v-layout row wrap>
                    <v-flex xs12 md1>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center">
                            <v-icon large class="text--text">
                              mdi-alert-circle-outline
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                    <v-flex xs12 md11>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center"> No Open Calls Found </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                  </v-layout>
                </v-card>
              </div>
              <div v-else>
                <div class="hidden-sm-and-down">
                  <v-card elevation="0" class="pa-2">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead class="header">
                          <tr class="tableHeader">
                            <th class="text-left text--text">Title</th>
                            <th class="text-left text--text">Owner(s)</th>

                            <th class="text-left text--text">Status</th>
                            <th class="text-left text--text">Creation Date</th>
                            <th class="text-right text--text">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in openCalls" :key="item.id">
                            <td>
                              {{ item.title }}
                            </td>
                            <td>
                              <div v-if="item.institution_calls.length != 0">
                                <div v-for="item in item.institution_calls" :key="item.id">
                                  {{ item.institution.name }}
                                </div>
                              </div>
                              <div v-if="item.organisation_calls.length != 0">
                                <div v-for="item in item.organisation_calls" :key="item.id">
                                  {{ item.organisation.name }}
                                </div>
                              </div>
                              <div v-if="item.user_calls.length != 0">
                                <div v-for="item in item.user_calls" :key="item.id">
                                  {{ item.user.f_name }} {{ item.user.m_name }} {{ item.user.l_name }}
                                </div>
                              </div>
                            </td>

                            <td>
                              <div v-if="item.status == 1">Active</div>
                              <div v-else>Inactive</div>
                            </td>
                            <td>
                              {{
                                item.created_at | moment("DD/MM/YYYY - hh:mm a")
                              }}
                            </td>
                            <td>
                              <div align="right">
                                <v-tooltip top v-if="$can('call_view')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 universal--text" @click="showCall(item)">
                                      <v-icon small> mdi-eye </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Open Call</span>
                                </v-tooltip>
                                <v-tooltip top v-if="$can('call_edit')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 success--text" @click="editCall(item)" v-bind="attrs"
                                      v-on="on">
                                      <v-icon small> mdi-pencil </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>edit</span>
                                </v-tooltip>

                                <v-tooltip top v-if="$can('call_archive')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 error--text" @click="deleteCall(item)"
                                      :loading="loading && callIndex == item.id" v-bind="attrs" v-on="on">
                                      <v-icon small> mdi-delete </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>delete</span>
                                </v-tooltip>

                                <v-tooltip top v-if="
                                  $can('call_activate') && item.status == 0
                                ">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 red--text" @click="activateCall(item)" :loading="
                                      activateLoading && callIndex == item.id
                                    " v-bind="attrs" v-on="on">
                                      <v-icon small>
                                        mdi-toggle-switch-off-outline
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Activate Call</span>
                                </v-tooltip>
                                <v-tooltip top v-if="
                                  $can('call_deactivate') && item.status == 1
                                ">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 green--text" @click="deactivateCall(item)" :loading="
                                      deactivateLoading &&
                                      callIndex == item.id
                                    " v-bind="attrs" v-on="on">
                                      <v-icon small> mdi-toggle-switch </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Deactivate Call</span>
                                </v-tooltip>
                                <v-tooltip top v-if="$can('call_broadcast')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 purple--text" @click="broadcastCall(item)"
                                      :loading="broadcastLoading && callIndex == item.id" v-bind="attrs" v-on="on">
                                      <v-icon small> mdi-bullhorn-variant </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Broadcast</span>
                                </v-tooltip>
                                <v-tooltip top v-if="$can('log_view')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      class="button mr-1 purple--text"
                                      @click="viewLog(item)"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small> mdi-script-text </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Log</span>
                                </v-tooltip>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </div>
                <div class="hidden-md-and-up">
                  <v-layout column>
                    <template v-for="(call, index) in openCalls">
                      <div :key="index">
                        <v-flex xs12 class="mb-2">
                          <v-card elevation="0">
                            <div class="pa-5">
                              <v-layout column>
                                <v-flex xs12>
                                  <v-layout row wrap>
                                    <v-flex xs11>
                                      <div class="title text--text ml-3">
                                        <b>{{ call.title }}</b>
                                      </div>
                                    </v-flex>
                                    <v-flex xs1>
                                      <v-btn depressed class="red--text" :loading="
                                        loading && callIndex == call.id
                                      " icon @click="deleteCall(call)" v-if="$can('call_archive')">
                                        <v-icon> mdi-delete </v-icon>
                                      </v-btn>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-divider class="mt-2"></v-divider>
                                <v-flex xs12 class="mt-1">
                                  <v-layout column>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Owner(s)</b>
                                      </div>
                                      <div v-if="call.institution_calls.length != 0">
                                        <div v-for="call in call.institution_calls" :key="call.id">
                                          {{ call.institution.name }}
                                        </div>
                                      </div>
                                      <div v-if="call.organisation_calls.length != 0">
                                        <div v-for="call in call.organisation_calls" :key="call.id">
                                          {{ call.organisation.name }}
                                        </div>
                                      </div>
                                      <div v-if="call.user_calls.length != 0">
                                        <div v-for="call in call.user_calls" :key="call.id">
                                          {{ call.user.f_name }} {{ call.user.m_name }} {{ call.user.l_name }}
                                        </div>
                                      </div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Status</b>
                                      </div>
                                      <div v-if="call.status == 1">Active</div>
                                      <div v-else>Inactive</div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Creation Date</b>
                                      </div>
                                      <div>
                                        {{
                                          call.created_at | moment("DD/MM/YYYY - hh:mm a")
                                        }}
                                      </div>
                                    </v-flex>
                                    

                                    <v-flex xs12 class="mt-5">
                                      <v-layout row wrap>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn
                                              depressed
                                              class="
                                                primary
                                                text-none
                                                white--text
                                              "
                                              block
                                              @click="showCall(call)"
                                              v-if="$can('call_view')"
                                              >View
                                              <v-icon right> mdi-eye </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn depressed class="blue darken-1 text-none white--text" block
                                              @click="editCall(call)" v-if="$can('call_edit')">Edit
                                              <v-icon right>
                                                mdi-border-color
                                              </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn depressed class="green text-none white--text" block
                                              @click="activateCall(call)" v-if="
                                                $can('call_activate') && call.status == 0
                                              "
                                              :loading="
                                                activateLoading && callIndex == call.id
                                              "
                                              >Activate Call
                                              <v-icon right>
                                                mdi-toggle-switch-off-outline
                                              </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn depressed class="red text-none white--text" block
                                              @click="deactivateCall(call)" v-if="
                                                $can('call_deactivate') && call.status == 1
                                              "
                                              :loading="
                                                deactivateLoading &&
                                                callIndex == call.id
                                              "
                                              >Deactivate Call
                                              <v-icon right>
                                                mdi-toggle-switch
                                              </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn depressed class="purple darken-1 text-none white--text" block
                                              @click="viewLog(call)" v-if="$can('log_view')">Log
                                              <v-icon right>
                                                mdi-script-text
                                              </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </div>
                          </v-card>
                        </v-flex>
                      </div>
                    </template>
                  </v-layout>
                </div>
              </div>
            </v-flex>
          </v-card>

          <v-layout column class="mt-5">
            <v-flex xs12>
              <div align="center">
                <v-pagination v-if="openCallLength != 0" :length="openCallLength" total-visible="10"
                  v-model="openCallPagination.current_page" @input="changeOpenCallPage()" circle>
                </v-pagination>
              </div>
            </v-flex>
            <v-flex xs12>
              <div v-if="length != 0" align="center" class="mt-5">
                <b class="text--text">Total: </b>{{ openCallPagination.total | formatNumber }}
              </div>
            </v-flex>
          </v-layout>



        </v-layout>
      </div>
      <div v-if="view == 'closed'">
        <v-layout column>
          <v-card outlined class="mt-2">
            <v-progress-linear v-if="closedCallLoader" height="1" indeterminate color="primary"></v-progress-linear>
            <v-flex xs12 class="mt-10 mb-2">
              <div class="mx-5">
                <v-layout row wrap>
                  <v-flex xs12 md4>
                    <h1> </h1>
                  </v-flex>
                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-text-field class="text_field header" outlined dense label="Search"
                        append-icon="mdi-undo-variant" @click:append="resetClosedCallSearch()"
                        v-on:keyup.enter="searchClosedCall" v-model="closedCallSearchTerm"
                        @input="enableClosedCallSearch()"></v-text-field>
                    </div>
                  </v-flex>
                  <v-flex xs12 md2>
                    <div class="pa-1">
                      <v-btn class="secondary primary--text text-none" depressed block @click="searchClosedCall"
                        :loading="searchClosedCallLoader" :disabled="closedCallSearchButtonDisabled">
                        Search
                        <v-icon right>mdi-magnify</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs12 md2>
                    <div class="pa-1">
                      <v-btn block depressed class="primary text-none" @click="changeView('create')"
                        v-if="$can('call_create')">
                        Add Call
                        <v-icon right>mdi-plus-circle-outline</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
            <v-divider class="mt-9 mx-5"></v-divider>
            <v-flex xs12 class="mt-5">
              <div v-if="closedCalls.length == 0">
                <v-card elevation="0">
                  <v-layout row wrap>
                    <v-flex xs12 md1>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center">
                            <v-icon large class="text--text">
                              mdi-alert-circle-outline
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                    <v-flex xs12 md11>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center"> No Closed Calls Found </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                  </v-layout>
                </v-card>
              </div>
              <div v-else>
                <div class="hidden-sm-and-down">
                  <v-card elevation="0" class="pa-2">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead class="header">
                          <tr class="tableHeader">
                            <th class="text-left text--text">Title</th>
                            <th class="text-left text--text">Owner</th>

                            <th class="text-left text--text">Account Active</th>
                            <th class="text-left text--text">Account Creation Date</th>
                            <th class="text-right text--text">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in closedCalls" :key="item.id">
                            <td>
                              {{ item.title }}
                            </td>
                            <td>

                            </td>

                            <td>
                              <div v-if="item.status == 1">Active</div>
                              <div v-else>Inactive</div>
                            </td>
                            <td>
                              {{
                                item.created_at | moment("DD/MM/YYYY - hh:mm a")
                              }}
                            </td>
                            <td>
                              <div align="right">
                                <v-tooltip top v-if="$can('call_view')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 universal--text" @click="showCall(item)">
                                      <v-icon small> mdi-eye </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>View Call</span>
                                </v-tooltip>
                                <v-tooltip top v-if="$can('call_edit')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 success--text" @click="editCall(item)" v-bind="attrs"
                                      v-on="on">
                                      <v-icon small> mdi-pencil </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>edit</span>
                                </v-tooltip>

                                <v-tooltip top v-if="$can('call_archive')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 error--text" @click="deleteCall(item)"
                                      :loading="loading && callIndex == item.id" v-bind="attrs" v-on="on">
                                      <v-icon small> mdi-delete </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>delete</span>
                                </v-tooltip>

                                <v-tooltip top v-if="
                                  $can('call_activate') && item.status == 0
                                ">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 red--text" @click="activateCall(item)" :loading="
                                      activateLoading && callIndex == item.id
                                    " v-bind="attrs" v-on="on">
                                      <v-icon small>
                                        mdi-toggle-switch-off-outline
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Activate Account</span>
                                </v-tooltip>
                                <v-tooltip top v-if="
                                  $can('call_deactivate') && item.status == 1
                                ">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 green--text" @click="deactivateCall(item)" :loading="
                                      deactivateLoading &&
                                      callIndex == item.id
                                    " v-bind="attrs" v-on="on">
                                      <v-icon small> mdi-toggle-switch </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Deactivate Account</span>
                                </v-tooltip>
                                <v-tooltip top v-if="$can('log_view')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      class="button mr-1 purple--text"
                                      @click="viewLog(item)"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small> mdi-script-text </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Log</span>
                                </v-tooltip>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </div>
                <div class="hidden-md-and-up">
                  <v-layout column>
                    <template v-for="(call, index) in closedCalls">
                      <div :key="index">
                        <v-flex xs12 class="mb-2">
                          <v-card elevation="0">
                            <div class="pa-5">
                              <v-layout column>
                                <v-flex xs12>
                                  <v-layout row wrap>
                                    <v-flex xs11>
                                      <div class="title text--text ml-3">
                                        <b>{{ call.title }}</b>
                                      </div>
                                    </v-flex>
                                    <v-flex xs1>
                                      <v-btn depressed class="red--text" :loading="
                                        loading && callIndex == call.id
                                      " icon @click="deleteCall(call)" v-if="$can('call_archive')">
                                        <v-icon> mdi-delete </v-icon>
                                      </v-btn>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-divider class="mt-2"></v-divider>
                                <v-flex xs12 class="mt-1">
                                  <v-layout column>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Owner(s)</b>
                                      </div>
                                      <div v-if="call.institution_calls.length != 0">
                                        <div v-for="call in call.institution_calls" :key="call.id">
                                          {{ call.institution.name }}
                                        </div>
                                      </div>
                                      <div v-if="call.organisation_calls.length != 0">
                                        <div v-for="call in call.organisation_calls" :key="call.id">
                                          {{ call.organisation.name }}
                                        </div>
                                      </div>
                                      <div v-if="call.user_calls.length != 0">
                                        <div v-for="call in call.user_calls" :key="call.id">
                                          {{ call.user.f_name }} {{ call.user.m_name }} {{ call.user.l_name }}
                                        </div>
                                      </div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Status</b>
                                      </div>
                                      <div v-if="call.status == 1">Active</div>
                                      <div v-else>Inactive</div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Creation Date</b>
                                      </div>
                                      <div>
                                        {{
                                          call.created_at | moment("DD/MM/YYYY - hh:mm a")
                                        }}
                                      </div>
                                    </v-flex>
                                    

                                    <v-flex xs12 class="mt-5">
                                      <v-layout row wrap>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn
                                              depressed
                                              class="
                                                primary
                                                text-none
                                                white--text
                                              "
                                              block
                                              @click="showCall(call)"
                                              v-if="$can('call_view')"
                                              >View
                                              <v-icon right> mdi-eye </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn depressed class="blue darken-1 text-none white--text" block
                                              @click="editCall(call)" v-if="$can('call_edit')">Edit
                                              <v-icon right>
                                                mdi-border-color
                                              </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn depressed class="green text-none white--text" block
                                              @click="activateCall(call)" v-if="
                                                $can('call_activate') && call.status == 0
                                              "
                                              :loading="
                                                activateLoading && callIndex == call.id
                                              "
                                              >Activate Call
                                              <v-icon right>
                                                mdi-toggle-switch-off-outline
                                              </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn depressed class="red text-none white--text" block
                                              @click="deactivateCall(call)" v-if="
                                                $can('call_deactivate') && call.status == 1
                                              "
                                              :loading="
                                                deactivateLoading &&
                                                callIndex == call.id
                                              "
                                              >Deactivate Call
                                              <v-icon right>
                                                mdi-toggle-switch
                                              </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn depressed class="purple darken-1 text-none white--text" block
                                              @click="viewLog(call)" v-if="$can('log_view')">Log
                                              <v-icon right>
                                                mdi-script-text
                                              </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </div>
                          </v-card>
                        </v-flex>
                      </div>
                    </template>
                  </v-layout>
                </div>
              </div>
            </v-flex>
          </v-card>
          <v-layout column class="mt-5">
            <v-flex xs12>
              <div align="center">
                <v-pagination v-if="closedCallLength != 0" :length="closedCallLength" total-visible="10"
                  v-model="closedCallPagination.current_page" @input="changeClosedCallPage()" circle>
                </v-pagination>
              </div>
            </v-flex>
            <v-flex xs12>
              <div v-if="length != 0" align="center" class="mt-5">
                <b class="text--text">Total: </b>{{ closedCallPagination.total | formatNumber }}
              </div>
            </v-flex>
          </v-layout>
        </v-layout>
      </div>
      <div v-if="view == 'create'">
        <v-flex class="mt-5">
          <v-card outlined>
            <v-card-title class="tableHeader text--text">
              <v-spacer></v-spacer>
              <div v-if="newCall.id == null">Add Call</div>
              <div v-else>Edit Call</div>
              <v-spacer></v-spacer>
              <v-btn icon @click="changeView('open')">
                <v-icon class="text--text"> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-layout column>
                  <v-flex xs12>
                    <v-layout row wrap class="mt-5">
                      <v-flex xs12>
                        <div class="pa-3">
                          <div>Title<span class="error--text">*</span></div>
                          <v-text-field class="text_field background" outlined dense v-model="newCall.title"
                            :rules="inputRules"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div class="pa-3">
                          <div>Brief<span class="error--text">*</span></div>
                          <v-text-field class="text_field background" outlined dense v-model="newCall.brief"
                            :rules="inputRules"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div class="pa-3">
                          <div>
                            Description<span class="error--text">*</span>
                          </div>
                          <div class="black--text">
                            <ckeditor :editor="editor" v-model="newCall.description" :config="editorConfig"
                            :rules="inputRules"></ckeditor>
                          </div>
                          
                        </div>
                      </v-flex>
                      <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>
                            Deadline Date<span class="error--text">*</span>
                          </div>
                          <v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
                            v-model="datePicker" offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field outlined dense :value="formatDate" slot="activator" readonly v-bind="attrs"
                                v-on="on" class="text_field background" append-icon="mdi-calendar"></v-text-field>
                            </template>
                            <v-date-picker v-model="newCall.deadline" :min="dateToday" @change="closeDate">
                            </v-date-picker>
                          </v-menu>
                        </div>
                      </v-flex>
                      <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>
                            Deadline Time<span class="error--text">*</span>
                          </div>
                          <v-menu ref="menu12" v-model="menu1" :close-on-content-click="false"
                            :return-value.sync="newCall.endTime" transition="scale-transition" offset-y full-width>
                            <template v-slot:activator="{ on }">
                              <v-text-field v-model="newCall.endTime" class="text_field background"
                                append-icon="mdi-clock-time-five-outline" readonly v-on="on" outlined dense
                                :rules="inputRules"></v-text-field>
                            </template>
                            <v-time-picker v-if="menu1" v-model="newCall.endTime" :allowed-minutes="allowedStep"
                              full-width color="primary" @click:minute="
                                $refs.menu12.save(newCall.endTime)
                              " format="24hr"></v-time-picker>
                          </v-menu>
                        </div>
                      </v-flex>

                      <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>Link to Relevant Resource</div>
                          <v-text-field class="text_field background" outlined dense
                            v-model="newCall.action_url"></v-text-field>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-card-title class="tableHeader text--text mt-10">
                    <div align="center">
                      Call Owner
                    </div>
                  </v-card-title>
                  <v-flex xs12 class="mt-5 mx-1">
                    <v-tabs show-arrows v-model="viewTab">
                      <v-tabs-slider color="primary"></v-tabs-slider>

                      <v-tab @click="changeCallOwnerTab('self')">
                        <div class="text-none text--text">Self</div>
                      </v-tab>
                      <v-tab @click="changeCallOwnerTab('organisation')">
                        <div class="text-none text--text">Organisation ({{ formData.organisations.length }})</div>
                      </v-tab>
                      <v-tab @click="changeCallOwnerTab('institution')">
                        <div class="text-none text--text">Institution ({{ formData.institutions.length }})</div>
                      </v-tab>
                    </v-tabs>
                    <div class="mt-5" v-if="activeCallOwnerTab == 'self'">
                      <v-checkbox v-model="newCall.personal_visibility" label="Show My Details"></v-checkbox>
                    </div>
                    <div class="mt-5" v-if="activeCallOwnerTab == 'organisation'">
                      <v-combobox v-model="newCall.organisations" :items="formData.organisations" item-text="name"
                        item-value="id" chips clearable multiple prepend-inner-icon="mdi-office-building-outline"
                        outlined class="login_field mx-3 mb-10">
                        <template v-slot:selection="{ attrs, item, select, selected }">
                          <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                            @click:close="removeOrganisation(item)">
                            <strong>{{ item.name }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </div>
                    <div class="mt-5" v-if="activeCallOwnerTab == 'institution'">
                      <v-combobox v-model="newCall.institutions" :items="formData.institutions" item-text="name"
                        item-value="id" chips clearable multiple prepend-inner-icon="mdi-office-building-outline"
                        outlined class="login_field mx-3 mb-10">
                        <template v-slot:selection="{ attrs, item, select, selected }">
                          <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                            @click:close="removeInstitution(item)">
                            <strong>{{ item.name }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </div>
                  </v-flex>
                  <v-card-title class="tableHeader text--text mt-10">
                    <div align="centre">
                      Interest(s)
                    </div>
                  </v-card-title>
                  <v-flex xs12 class="mt-5 mx-3">
                    <v-layout row wrap>
                      <template v-for="(interest, index) in formData.interests">
                        <v-flex xs12 md3 :key="index">
                          <v-checkbox v-model="newCall.interests" :value="interest.id"
                            :label="interest.name"></v-checkbox>
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-flex>
                  <v-card-title class="tableHeader text--text mt-10">
                    <div align="center">
                      Sector(s)
                    </div>
                  </v-card-title>
                  <v-flex xs12 class="mt-5 mx-3">
                    <v-layout row wrap>
                      <template v-for="(sector, index) in formData.sectors">
                        <v-flex xs12 md3 :key="index">
                          <v-checkbox v-model="newCall.sectors" :value="sector.id" :label="sector.name"></v-checkbox>
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-flex>
                  <v-card-title class="tableHeader text--text mt-10">
                    <div align="center">
                      SDG(s)
                    </div>
                  </v-card-title>
                  <v-flex xs12 class="mt-5 mx-3">
                    <v-layout row wrap>
                      <template v-for="(sdg, index) in formData.sdgs">
                        <v-flex xs12 md3 :key="index">
                          <v-checkbox v-model="newCall.sdgs" :value="sdg.id" :label="sdg.name"></v-checkbox>
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed class="primary text-none mt-3" @click="save" :disabled="!valid" :loading="loading">
                Save
                <v-icon right> mdi-content-save </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </div>
      <div v-if="view == 'show'">
        <v-card outlined class="mt-2">
          <v-card-title class="tableHeader text--text">
            {{ assignedCall.title }}
            <v-spacer></v-spacer>
            <v-btn icon @click="changeView('open')">
              <v-icon class="text--text"> mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="pa-3">
              <v-tabs show-arrows v-model="callTab">
                <v-tabs-slider color="primary"></v-tabs-slider>

                <v-tab @click="activeTab = 'details'">
                  <div class="text-none text--text">Details</div>
                </v-tab>
                <v-tab @click="activeTab = 'callResponses'" v-if="$can('call_reponse_view')">
                  <div class="text-none text--text">
                    Call Reponses ({{callResponsePagination.total}})
                  </div>
                </v-tab>
              </v-tabs>
              <div v-if="activeTab == 'details'" class="mx-3 mt-2">
                <v-layout row wrap>
                        <v-flex xs12 md3>
                          <div class="mr-8 mt-5">
                            <v-img
                              max-height="150"
                              v-if="assignedCall.featured_image==null"
                              src="img/logo_primary.png"
                              style="border-radius: 25px;"
                            >
                            </v-img>
                            <v-img
                              max-height="150"
                              v-else
                              :src="path+'/storage/call_pics/'+assignedCall.featured_image"
                              style="border-radius: 25px;"
                            >
                            </v-img>
                          </div>
                          <div align="center" class="mt-5">
                            <v-btn class="primary text-none" depressed @click="imageDialog = true" v-if="$can('call_image_upload')">
                              Upload Image
                              <v-icon right>mdi-file-upload</v-icon>
                            </v-btn>
                          </div>
                          
                        </v-flex>
                        <v-flex xs12 md9>
                          <v-layout column>
                            <v-flex xs12 class="mt-7">
                              <v-layout row wrap>
                                <v-flex xs12 md2>
                                  <div><b>Action URL:</b></div>
                                </v-flex>
                                <v-flex xs12 md10>
                                  <div v-if="assignedCall.action_url == null">
                                    N/A
                                  </div>
                                  <div v-else>
                                    {{ assignedCall.action_url }}
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-5">
                              <v-layout row wrap>
                                <v-flex xs12 md2>
                                  <div><b>Creation Date:</b></div>
                                </v-flex>
                                <v-flex xs12 md10>
                                  {{
                                    assignedCall.created_at
                                      | moment("DD/MM/YYYY - hh:mm a")
                                  }}
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-5">
                              <v-layout row wrap>
                                <v-flex xs12 md2>
                                  <div><b>Status:</b></div>
                                </v-flex>
                                <v-flex xs12 md10>
                                  <div v-if="assignedCall.status == 1">Active</div>
                                  <div v-else>Inactive</div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            
                            <v-flex xs12 class="mt-12">
                              <v-layout row wrap>
                                <v-flex xs12 md2>
                                  <div><b>Brief:</b></div>
                                </v-flex>
                                <v-flex xs12 md10>
                                  {{ assignedCall.brief }}
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap>
                                <v-flex xs12 md2>
                                  <b>Description: </b>
                                </v-flex>
                                <v-flex xs12 md10>
                                  <p v-html="assignedCall.description"></p>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12>
                              <v-layout row wrap>
                                <v-flex xs12 md2>
                                  <div class="mt-12"><b>Visibility:</b></div>
                                </v-flex>
                                <v-flex xs12 md10>
                                  <v-layout row wrap>
                                    <v-flex xs2>
                                      <v-switch
                                        class="mt-13 ml-3"
                                        v-model="assignedCall.visibility"
                                        :loading="activateLoading"
                                        @change="updateVisibility(assignedCall)"
                                      ></v-switch>
                                    </v-flex>
                                    <v-flex xs10>
                                      <div class="grey--text ms-4 mt-14" v-if="assignedCall.visibility == 1">
                                      <b>Public</b>
                                    </div>
                                    <div class="grey--text ms-4 mt-14" v-if="assignedCall.visibility == 0">
                                      <b>Private</b>
                                    </div>
                                    </v-flex>
                                  </v-layout>
                                  
                                    
                                </v-flex>
                                
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                </v-layout>
              </div>
              <div v-if="activeTab == 'callResponses'" class="mt-2">
                <div v-if="callView == 'default'">
                    <v-card elevation="0" class="mt-5">
                      <v-flex xs12 class="mt-10 mb-2">
                        <div class="mx-5">
                          <v-layout row wrap>
                            <v-flex xs12 md4> </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <v-text-field
                                  class="text_field header"
                                  outlined
                                  dense
                                  label="Search"
                                  append-icon="mdi-undo-variant"
                                  @click:append="
                                    resetCallResponseSearch()
                                  "
                                  v-on:keyup.enter="
                                    callResponseSearch
                                  "
                                  v-model="callResponseSearchTerm"
                                  @input="enableInstitutionAffiliationSearch()"
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md2>
                              <div class="pa-1">
                                <v-btn
                                  class="secondary primary--text text-none"
                                  depressed
                                  block
                                  @click="callResponseSearch"
                                  :loading="callResponseSearchLoader"
                                  :disabled="
                                    callResponseSearchButtonDisabled
                                  "
                                >
                                  Search
                                  <v-icon right>mdi-magnify</v-icon>
                                </v-btn>
                              </div>
                            </v-flex>
                            <v-flex xs12 md2>
                              <div class="pa-1">
                                
                              </div>
                            </v-flex>
                          </v-layout>
                        </div>
                      </v-flex>
                      <v-divider class="mt-9 mx-5"></v-divider>
                      <v-flex xs12 class="mt-5">
                        <div v-if="callResponses.length == 0">
                          <v-card elevation="0">
                            <v-layout row wrap>
                              <v-flex xs12 md1>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      <v-icon large class="text--text">
                                        mdi-alert-circle-outline
                                      </v-icon>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                              <v-flex xs12 md11>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      No Responses Found
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </div>
                        <div v-else>
                          <div class="hidden-sm-and-down">
                            <v-card elevation="0" class="pa-2">
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead class="header">
                                    <tr class="tableHeader">
                                      <th class="text-left text--text">Responder</th>
                                      <th class="text-left text--text">Date Created</th>
                                      <th class="text-left text--text">Last Update</th>
                                      <th class="text-right text--text">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="item in callResponses"
                                      :key="item.id"
                                    >
                                      <td>
                                        {{ item.user.f_name }} {{ item.user.m_name }} {{ item.user.l_name }}
                                      </td>
                                      <td>
                                        {{ item.created_at | moment("DD/MM/YYYY - hh:mm a")}}
                                      </td>
                                      <td>
                                        {{ item.updated_at | moment("DD/MM/YYYY - hh:mm a") }}
                                      </td>
                                      <td>
                                        <div align="right">
                                          <v-btn
                                            icon
                                            @click="
                                              showCallResponse(
                                                item
                                              )
                                            "
                                            class="button mr-1 universal--text"
                                          >
                                            <v-icon small> mdi-eye </v-icon>
                                          </v-btn>
                                          <v-btn
                                            v-if="$can('log_view')"
                                            icon
                                            class="button mr-1 purple--text"
                                            @click="viewResponderLog(item)"
                                            v-bind="attrs"
                                            v-on="on"
                                          >
                                            <v-icon small> mdi-script-text </v-icon>
                                          </v-btn>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card>
                          </div>
                          <div class="hidden-md-and-up">
                            <div>
                              <v-layout column>
                                <template
                                  v-for="(
                                    call, index
                                  ) in callResponses"
                                >
                                  <div :key="index">
                                    <v-flex xs12 class="mb-2">
                                      <v-card elevation="0">
                                        <div class="pa-5">
                                          <v-layout column>
                                            <v-flex xs12>
                                              <v-layout row wrap>
                                                <v-flex xs12>
                                                  <div
                                                    class="title primary--text"
                                                  >
                                                    <b>{{ call.user.f_name }} {{ call.user.m_name }} {{ call.user.l_name }}</b>
                                                  </div>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-divider class="mt-2"></v-divider>
                                            <v-flex xs12 class="mt-1">
                                              <v-layout column>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Date Created</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{ call.created_at | moment("DD/MM/YYYY - hh:mm a")}}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Last Update</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{ call.updated_at | moment("DD/MM/YYYY - hh:mm a")}}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="primary text-none white--text mt-3"
                                                          block
                                                          @click="
                                                            showCallResponse(
                                                              call
                                                            )
                                                          "
                                                          >View
                                                          <v-icon right>
                                                            mdi-eye
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                      <div>
                                                        <v-btn
                                                        v-if="$can('log_view')"
                                                          depressed
                                                          class="purple text-none white--text mt-2"
                                                          block
                                                          @click="viewResponderLog(call)"
                                                          v-bind="attrs"
                                                          v-on="on"
                                                        >Log
                                                          <v-icon right> mdi-script-text </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                          </v-layout>
                                        </div>
                                      </v-card>
                                    </v-flex>
                                  </div>
                                </template>
                              </v-layout>
                            </div>
                          </div>
                        </div>
                      </v-flex>
                    </v-card>
                    <v-layout column class="mt-5">
                      <v-flex xs12>
                        <div align="left">
                          <v-pagination
                            v-if="callResponseLength != 0"
                            :length="callResponseLength"
                            total-visible="10"
                            v-model="
                              callResponsePagination.current_page
                            "
                            @input="changeCallResponsePage()"
                            circle
                          >
                          </v-pagination>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div
                          v-if="callResponseLength != 0"
                          align="center"
                          class="mt-5"
                        >
                          <b class="text--text">Total: </b
                          >{{
                            callResponsePagination.total
                              | formatNumber
                          }}
                        </div>
                      </v-flex>
                    </v-layout>
                  </div>
                <div v-if="callView == 'show'">
                  <v-card-title class="tableHeader text--text mt-5">
                    Responder Details
                    <v-spacer></v-spacer>
                    <v-btn icon @click="callView = 'default'">
                      <v-icon class="text--text"> mdi-close </v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-layout row rap class="mx-1 mt-3">
                      <v-flex xs12 md6 class="mt-5">
                        <v-layout column>
                          <v-flex xs12>
                            <v-layout row wrap>
                              <v-flex xs12 md4>
                                <b>Name:</b>
                              </v-flex>
                              <v-flex xs12 md8>
                                {{ assignedResponse.user.f_name }} {{ assignedResponse.user.m_name }} {{ assignedResponse.user.l_name }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 class="mt-5">
                            <v-layout row wrap>
                              <v-flex xs12 md4>
                                <b>Code:</b>
                              </v-flex>
                              <v-flex xs12 md8>
                                {{ assignedResponse.user.code }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 md6 class="mt-5">
                        <v-layout column>
                          <v-flex xs12>
                            <v-layout row wrap>
                              <v-flex xs12 md4>
                                <b>Email Address:</b>
                              </v-flex>
                              <v-flex xs12 md8>
                                {{ assignedResponse.user.email }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 class="mt-5">
                            <v-layout row wrap>
                              <v-flex xs12 md4>
                                <b>Phone #:</b>
                              </v-flex>
                              <v-flex xs12 md8>
                                {{ assignedResponse.user.phone }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 class="mt-5">
                        <v-layout row wrap>
                              <v-flex xs12 md2>
                                <b>Bio:</b>
                              </v-flex>
                              <v-flex xs12 md10>
                                <div class="ml-1">
                                  {{ assignedResponse.user.bio }}
                                </div>
                              </v-flex>
                            </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                  <v-card-title class="tableHeader text--text mt-5">
                    Response
                  </v-card-title>
                  <v-card-text>
                    <v-layout column>
                      <v-flex xs12 class="mt-3">
                        <div><b>1. Which Challenge does your solution address?</b></div>
                        <div v-html="assignedResponse.challenge_addressed"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>2. Provide a one-line summary of your solution.</b></div>
                        <div>{{ assignedResponse.one_line_summary }}</div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>3. What specific problem are you solving?</b></div>
                        <div v-html="assignedResponse.problem_solved"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>4. What is your solution?</b></div>
                        <div v-html="assignedResponse.solution"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>5. Who does your solution serve, and in what ways will the solution impact their lives?</b></div>
                        <div v-html="assignedResponse.target_audience"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>6. How are you and your team well-positioned to deliver this solution?</b></div>
                        <div v-html="assignedResponse.positioning"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>7. Why are you applying to Solve?</b></div>
                        <div v-html="assignedResponse.application_reason"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>8. What makes your solution innovative?</b></div>
                        <div v-html="assignedResponse.innovative"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>9. What are your impact goals for the next year and the next five years, and how will you achieve them?</b></div>
                        <div v-html="assignedResponse.impact_goals"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>10. How are you measuring your progress toward your impact goals?</b></div>
                        <div v-html="assignedResponse.impact_goal_measure"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>11. What is your theory of change?</b></div>
                        <div v-html="assignedResponse.change_theory"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>12. Describe the core technology that powers your solution.</b></div>
                        <div v-html="assignedResponse.core_technology"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>13. What is your approach to incorporating diversity, equity, and inclusivity into your work?</b></div>
                        <div v-html="assignedResponse.incorporation_approach"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>14. What is your business model?</b></div>
                        <div v-html="assignedResponse.business_model"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>15. What is your plan for becoming financially sustainable?</b></div>
                        <div v-html="assignedResponse.financial_stability"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>16. Share some examples of how your plan to achieve financial sustainability has been successful so far.</b></div>
                        <div v-html="assignedResponse.financial_stability_success"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>17. Link to Innovaton.</b></div>
                        <div> <a :href="
                          assignedResponse.innovation_link
                        " target="_blank" style="
                          text-decoration: none;
                        ">{{ assignedResponse.innovation_link }}</a> </div>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
    <logData v-if="logDataModel" v-model="logDataModel" />
  </div>
</template>
<style>
.ck-editor__editable {
  min-height: 200px;
}
</style>
<script>
import apiCall from "@/utils/api";
import { mapGetters, mapActions } from "vuex";
import logData from "@/views/accessControl/logData";
import Vue from "vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadAdapter from '@/uploadAdapter.js';

var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0");
});

Vue.use(require("vue-moment"));

export default {
  components: {
    logData,
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      view: "open",
      loading: false,
      activateLoading: false,
      deactivateLoading: false,
      broadcastLoading: false,
      callView: "default",

      activeTab: 'details',
      callTab: null,

      editor: ClassicEditor,
      editorConfig: {
          extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
              return new uploadAdapter(loader);
          } }],
          language: 'en',
      },

      menu1: false,
      allowedStep: (m) => m % 15 === 0,
      valid: true,
      datePicker: false,
      message: "",
      color: "",
      delete: false,
      activate: false,
      confirm: false,
      callIndex: null,
      snackbar: false,
      inputRules: [(v) => !!v || "Input is required"],

      openCallSearchTerm: null,
      closedCallSearchTerm: null,
      callResponseSearchTerm: null,
      searchOpenCallLoader: false,
      searchClosedCallLoader: false,
      openCallSearchButtonDisabled: false,
      closedCallSearchButtonDisabled: false,
      activeCallOwnerTab: 'self',
      filteredOpenCalls: false,
      filteredClosedCalls: false,

      callResponseLoader: false,
      callResponseSearchLoader: false,
      filteredCallResponses: false,
      callResponseSearchButtonDisabled: false,

      imageDialog: false,
      imageLoading: false,
      fileRules: [(v) => !!v || "Kindly selecta a File"],
      imageFile: null,
      imageValid: true,

      viewTab: null,
      newCall: {
        id: null,
        title: null,
        brief: null,
        description: null,
        action_url: null,
        deadline: null,
        endTime: null,
        personal: true,
        personal_visibility: true,
        institutions: [],
        organisations: [],
        interests: [],
        sectors: [],
        sdgs: [],
      },
      assignedCall: null,
      assignedResponse: null,
      formData: null,
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      monthNames: [
        { name: "January" },
        { name: "February" },
        { name: "March" },
        { name: "April" },
        { name: "May" },
        { name: "June" },
        { name: "July" },
        { name: "August" },
        { name: "September" },
        { name: "October" },
        { name: "November" },
        { name: "December" },
      ],
      callResponses: [],
      callResponsePagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },
      logData: {
        message: null,
        model: null,
      },
    };
  },
  created() {
    if (this.$can("call_view")) {
      this.startOpenCallLoader();
      this.startClosedCallLoader();
      this.fetchOpenCalls(this.openCallPagination.current_page);
      this.fetchClosedCalls(this.closedCallPagination.current_page);
      this.getFormData();
    }
  },
  methods: {
    ...mapActions([
      "fetchOpenCalls",
      "fetchClosedCalls",
      "startOpenCallLoader",
      "startClosedCallLoader",
      "stopOpenCallLoader",
      "stopClosedCallLoader",
      "filterOpenCalls",
      "filterClosedCalls",

      "filterLogData",
      "openLogDataModel",
    ]),
    enableOpenCallSearch() {
      this.openCallSearchButtonDisabled = false;
    },
    enableClosedCallSearch() {
      this.closedCallSearchButtonDisabled = false;
    },
    closeDate() {
      this.datePicker = false;
    },
    removeInstitution(item) {
      this.newCall.institutions.splice(this.newCall.institutions.indexOf(item), 1)
    },
    removeOrganisation(item) {
      this.newCall.organisations.splice(this.newCall.organisations.indexOf(item), 1)
    },
    resetCallResponseSearch() {
      this.callResponseSearchTerm = null;
      this.filteredCallResponses = false;
      this.callResponseLoader = false;
      this.changeCallResponsePage();
    },
    changeCallOwnerTab(tab) {
      if (tab == 'self') {
        this.newCall.personal = true
      } else {
        this.newCall.personal = false
      }
      this.newCall.institutions = [],
        this.newCall.organisations = [],

        this.activeCallOwnerTab = tab
    },
    resetOpenCallSearch() {
      this.openCallSearchTerm = null;
      this.filteredClosedCalls = true;
      this.startOpenCallLoader();
      this.fetchOpenCalls(1);
    },
    resetClosedCallSearch() {
      this.closedCallSearchTerm = null;
      this.filteredClosedCalls = true;
      this.stopClosedCallLoader();
      this.fetchClosedCalls(1);
    },
    showCall(call) {
      this.assignedCall = call;
      if (this.$can("call_reponse_view")) {
        this.changeCallResponsePage();
      }
      this.changeView("show");
    },
    showCallResponse(response){
      this.assignedResponse = response
      this.callView = 'show'
    },
    callResponseSearch() {
      if (this.callResponseSearchTerm == null) {
        this.callResponseSearchButtonDisabled = true;
      } else {
        this.callResponseSearchLoader = true;
        this.callResponseSearchButtonDisabled = false;
        this.startOpenCallLoader();
        this.startClosedCallLoader();
        apiCall({
          url:
          "/api/call-response?call_id=" +
            this.assignedCall.id +
            "&page=1&type=search&search=" +
            this.callResponseSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.callResponses = resp.data;
            this.callResponsePagination.current_page =
              resp.current_page;
            this.callResponsePagination.total = resp.total;
            this.callResponsePagination.per_page = resp.per_page;

            this.callResponseSearchLoader = false;
            this.filteredCallResponses = true;
          })
          .catch((error) => {
            this.callResponseSearchLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            console.log(error);
          });
      }
    },
    changeCallResponsePage() {
      this.callResponseLoader = true;
      if (this.filteredCallResponses == true) {
        this.callResponseSearchButtonDisabled = false;
        apiCall({
          url:
            "/api/call-response?call_id=" +
            this.assignedCall.id +
            "&page=" +
            this.callResponsePagination.current_page +
            "&type=search&search=" +
            this.callResponseSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.callResponseLoader = false;

            this.callResponses = resp.data;
            this.callResponsePagination.current_page =
              resp.current_page;
            this.callResponsePagination.total = resp.total;
            this.callResponsePagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.callResponseLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        apiCall({
          url:
            "/api/call-response?call_id=" +
            this.assignedCall.id +
            "&page=" +
            this.callResponsePagination.current_page,
          method: "GET",
        })
          .then((resp) => {
            this.callResponseLoader = false;

            this.callResponses = resp.data;
            this.callResponsePagination.current_page =
              resp.current_page;
            this.callResponsePagination.total = resp.total;
            this.callResponsePagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.callResponseLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    editCall(call) {
      this.newCall.id = call.id;
      this.newCall.title = call.title
      this.newCall.brief = call.brief
      this.newCall.description = call.description
      this.newCall.action_url = call.action_url
      this.newCall.deadline = call.deadline.split(/(\s+)/)[0]
      this.newCall.endTime = call.deadline.split(/(\s+)/)[2]

      if(call.user_calls.length != 0){
        this.newCall.personal = true
        if(call.user_calls[0].visibility == 1){
          this.newCall.personal_visibility = true
        }else{
          this.newCall.personal_visibility = false
        }
        this.changeCallOwnerTab('self')
      }
      if(call.institution_calls.length != 0){
        this.changeCallOwnerTab('institution')
        this.newCall.personal = false
        for (let i = 0; i < call.institution_calls.length; i++) {
          this.newCall.institutions.push(call.institution_calls[i].institution);
        }
        
      }
      if(call.organisation_calls.length != 0){
        this.changeCallOwnerTab('organisation')
        this.newCall.personal = false
        for (let i = 0; i < call.organisation_calls.length; i++) {
          this.newCall.organisations.push(call.organisation_calls[i].organisation);
        }
        
      }
      for (let i = 0; i < call.call_interests.length; i++) {
        this.newCall.interests.push(call.call_interests[i].interest_id);
      }
      for (let i = 0; i < call.call_sectors.length; i++) {
        this.newCall.sectors.push(call.call_sectors[i].sector_id);
      }
      for (let i = 0; i < call.call_sdgs.length; i++) {
        this.newCall.sdgs.push(call.call_sdgs[i].sdg_id);
      }
      

      this.changeView("create");
    },
    uploadImage() {
      if (this.$refs.imageForm.validate()) {
        this.imageLoading = true;
        let formData = new FormData();

        // files

        formData.append("files", this.imageFile, this.imageFile.name);
        formData.append("id", this.assignedCall.id);

        apiCall({
          url: "/api/call?type=image",
          data: formData,
          method: "POST",
        })
          .then((resp) => {
            this.snackbar = true;
            this.message = "Feature Image Uploaded Successfully";
            this.color = "success";
            this.fetchOpenCalls(this.openCallPagination.current_page);
            this.fetchClosedCalls(this.closedCallPagination.current_page);
            this.imageLoading = false;
            this.imageDialog = false;
            this.assignedCall.featured_image = resp.featured_image;
            // this.changeView('default')
          })
          .catch((error) => {
            this.imageLoading = false;
          });
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.newCall.id != null) {
          confirm("Are You Sure You Want to Update Call") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/call/" + this.newCall.id,
              data: this.newCall,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Call Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeView("open");
                this.fetchOpenCalls(1);
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm("Are You Sure You Want to Create Call?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/call",
              data: this.newCall,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Call Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.changeView("open");
                this.fetchOpenCalls(1);
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
                this.fetchOpenCalls(1);
              });
          }
        }
      }
    },
    searchOpenCall() {
      if (this.openCallSearchTerm == null) {
        this.openCallSearchButtonDisabled = true;
      } else {
        this.searchOpenCallLoader = true;
        this.openCallSearchButtonDisabled = false;
        this.stopOpenCallLoader();
        apiCall({
          url: "/api/call?type=open&typeB=search&search=" + this.openCallSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterOpenCalls(resp);
            this.searchOpenCallLoader = false;
            this.stopOpenCallLoader();
            this.filteredOpenCalls = true;
          })
          .catch((error) => {
            console.log(error.response);
            this.searchOpenCallLoader = false;
            this.stopOpenCallLoader();
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    searchClosedCall() {
      if (this.closedCallSearchTerm == null) {
        this.closedCallSearchButtonDisabled = true;
      } else {
        this.searchClosedCallLoader = true;
        this.closedCallSearchButtonDisabled = false;
        this.stopClosedCallLoader();
        apiCall({
          url: "/api/call?type=closed&typeB=search&search=" + this.closedCallSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterClosedCalls(resp);
            this.searchClosedCallLoader = false;
            this.stopClosedCallLoader();
            this.filteredOpenCalls = true;
          })
          .catch((error) => {
            this.searchClosedCallLoader = false;
            this.stopClosedCallLoader();
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            console.log(error);
          });
      }
    },
    changeView(view) {
      if (view == "open") {
        this.newCall.id = null;
        this.newCall.title = null
        this.newCall.brief = null
        this.newCall.description = null
        this.newCall.action_url = null
        this.newCall.deadline = null
        this.newCall.endTime = null
        this.newCall.personal = true
        this.newCall.personal_visibility = true
        this.newCall.institutions = []
        this.newCall.organisations = []
        this.newCall.interests = []
        this.newCall.sectors = []
        this.newCall.sdgs = []
      } else if (view == "show") {

      }
      this.view = view;
    },
    changeOpenCallPage() {
      this.startOpenCallLoader();
      if (this.filteredOpenCalls == true) {
        this.openCallSearchButtonDisabled = false;
        apiCall({
          url:
            "/api/call?type=open&page=" +
            this.openCallPagination.current_page +
            "&typeB=search&search=" +
            this.openCallSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterOpenCalls(resp);
            this.stopOpenCallLoader();
          })
          .catch((error) => {
            this.stopOpenCallLoader();
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            console.log(error);
          });
      } else {
        this.fetchOpenCalls(this.openCallPagination.current_page);
      }
    },
    changeClosedCallPage() {
      this.startClosedCallLoader();
      if (this.filteredClosedCalls == true) {
        this.closedCallSearchButtonDisabled = false;
        apiCall({
          url:
            "/api/call?type=closed&page=" +
            this.closedCallPagination.current_page +
            "&typeB=search&search=" +
            this.closedCallSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterClosedCalls(resp);
            this.stopClosedCallLoader();
          })
          .catch((error) => {
            this.stopClosedCallLoader();
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            console.log(error);
          });
      } else {
        this.fetchClosedCalls(this.closedCallPagination.current_page);
      }
    },
    deleteCall(item) {
      confirm("Are You Sure You Want to Delete Call?") && (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.callIndex = item.id;
        apiCall({ url: "/api/call/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "Call Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.callIndex = null;
            this.fetchOpenCalls(this.openCallPagination.current_page);
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.callIndex = null;
            console.log(error.response);
          });
      }
    },
    updateVisibility(item){
      confirm("Are You Sure You Want to Update Public Visibility?") &&
        (this.activate = true);
      if (this.activate) {
        this.activateLoading = true;
        this.newCall.id = item.id;
        apiCall({
          url: "/api/call?type=visibility",
          data: this.newCall,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Call Public Visibility Updated";
            this.color = "success";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.fetchOpenCalls(this.openCallPagination.current_page);
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            console.log(error);
          });
      }
    },
    activateCall(item) {
      confirm("Are You Sure You Want to Activate Call?") &&
        (this.activate = true);
      if (this.activate) {
        this.activateLoading = true;
        this.callIndex = item.id;
        this.newCall.id = item.id;
        apiCall({
          url: "/api/call?type=activate",
          data: this.newCall,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Call Activated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.callIndex = null;
            this.fetchOpenCalls(this.openCallPagination.current_page);
            this.fetchClosedCalls(this.closedCallPagination.current_page);
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.callIndex = null;
            console.log(error.response);
          });
      }
    },
    deactivateCall(item) {
      confirm("Are You Sure You Want to Deactivate Call?") &&
        (this.activate = true);
      if (this.activate) {
        this.deactivateLoading = true;
        this.callIndex = item.id;
        this.newCall.id = item.id;
        apiCall({
          url: "/api/call?type=deactivate",
          data: this.newCall,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Call Account Deactivated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.callIndex = null;
            this.fetchOpenCalls(this.openCallPagination.current_page);
            this.fetchClosedCalls(this.closedCallPagination.current_page);
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.callIndex = null;
            console.log(error);
          });
      }
    },
    broadcastCall(item) {
      confirm("Are You Sure You Want to Broadcast this Call?") &&
        (this.activate = true);
      if (this.activate) {
        this.broadcastLoading = true;
        this.callIndex = item.id;
        this.newCall.id = item.id;
        apiCall({
          url: "/api/call?type=broadcast",
          data: this.newCall,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Call Broadcastd Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.broadcastLoading = false;
            this.activate = false;
            this.callIndex = null;
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.callIndex = null;
            console.log(error);
          });
      }
    },
    getFormData() {
      apiCall({
        url: "/api/call?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
        })
        .catch((error) => {
          this.message = "An Error Occurred; Couldn't Get Form Data";
          this.color = "error";
        });
    },
    viewLog(data) {
      this.logData.message = data.id;
      this.logData.model = "App\\Models\\Call"
      this.filterLogData(this.logData);
      this.openLogDataModel();
    },
    viewResponderLog(data) {
      this.logData.message = data.id;
      this.logData.model = "App\\Models\\CallResponse"
      this.filterLogData(this.logData);
      this.openLogDataModel();
    },
  },
  computed: {
    ...mapGetters([
      "openCalls", "openCallPagination", "openCallLoader",
      "closedCalls", "closedCallPagination", "closedCallLoader",
      "logDataModel"
    ]),
    formatDate() {
      if (this.newCall.deadline != null) {
        const d = new Date(this.newCall.deadline);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
    dateToday() {
      var today = new Date();

      var timestamp =
        today.getFullYear() +
        "-" +
        this.months[today.getMonth()] +
        "-" +
        ("0" + today.getDate()).slice(-2);
      return timestamp;
    },

    openCallLength: function () {
      return Math.ceil(
        this.openCallPagination.total / this.openCallPagination.per_page
      );
    },
    closedCallLength: function () {
      return Math.ceil(
        this.closedCallPagination.total / this.closedCallPagination.per_page
      );
    },
    callResponseLength: function () {
      return Math.ceil(
        this.callResponsePagination.total / this.callResponsePagination.per_page
      );
    },
  },
};
</script>
