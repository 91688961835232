import apiCall from '@/utils/api'

const state = {
	esoTypes: [],
	allEsoTypes: [],
    esoTypePagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    esoTypeLoader: false
};

const getters = {
	esoTypes: (state) => state.esoTypes,
	allEsoTypes: (state) => state.allEsoTypes,
    esoTypeLoader: (state) => state.esoTypeLoader,
    esoTypePagination: (state) => state.esoTypePagination
}

const actions = {
	async fetchAllEsoTypes({commit}, page) {
		const response = await apiCall({url: `/api/eso-type?type=all`, method: 'GET' });
		commit('setAllEsoTypes', response)
        commit('stopLoader', response)
	},
    async fetchEsoTypes({commit}, page) {
		const response = await apiCall({url: `/api/eso-type?page=${page}`, method: 'GET' });
		commit('setEsoTypes', response)
        commit('stopLoader', response)
	},
    async filterEsoTypes({commit, state},resp){
		commit('setEsoTypes', resp)
	},
	async startEsoTypeLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopEsoTypeLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setEsoTypes: (state, esoTypes) => {
		state.esoTypes = esoTypes.data
		state.esoTypePagination.current_page = esoTypes.current_page
		state.esoTypePagination.total = esoTypes.total
		state.esoTypePagination.per_page = esoTypes.per_page
	},
	setAllEsoTypes: (state, esoTypes) => {
		state.allEsoTypes = esoTypes
	},
    startLoader: (state) => state.esoTypeLoader = true,
	stopLoader: (state) => state.esoTypeLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
