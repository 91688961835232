<template>
  <div class="welcome">
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      width="1150"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
            My Response
            <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
                <div class="mx-3">
                    <v-layout row wrap>
                        <v-flex xs12>
                            <div class="mt-5">
                                <b>1. Which Challenge does your solution address?</b
                                ><span class="red--text">*</span>
                            </div>
                            <div class="black--text">
                              <ckeditor
                                :editor="editor"
                                v-model="response.challenge_addressed"
                                :config="editorConfig"
                                :rules="inputRules"
                              ></ckeditor>
                            </div>                            
                        </v-flex>
                        <v-flex xs12>
                            <div class="mt-5">
                                <b>2. Provide a one-line summary of your solution.</b
                                ><span class="red--text">*</span>
                            </div>
                            <v-text-field
                                outlined
                                dense
                                v-model="response.one_line_summary"
                                :rules="inputRules"
                                class="login_field"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <div class="mt-5">
                                <b>3. What specific problem are you solving?</b
                                ><span class="red--text">*</span>
                            </div>
                            <div class="black--text">
                              <ckeditor
                                :editor="editor"
                                v-model="response.problem_solved"
                                :config="editorConfig"
                                :rules="inputRules"
                              ></ckeditor>
                            </div>
                              
                        </v-flex>
                        <v-flex xs12>
                            <div class="mt-5">
                                <b>4. What is your solution?</b
                                ><span class="red--text">*</span>
                            </div>
                            <div class="black--text">
                              <ckeditor
                                :editor="editor"
                                v-model="response.solution"
                                :config="editorConfig"
                                :rules="inputRules"
                              ></ckeditor>
                            </div>
                              
                        </v-flex>
                        <v-flex xs12>
                            <div class="mt-5">
                                <b>5. Who does your solution serve, and in what ways will the solution impact their lives?</b
                                ><span class="red--text">*</span>
                            </div>
                            <div class="black--text">
                              <ckeditor
                                :editor="editor"
                                v-model="response.target_audience"
                                :config="editorConfig"
                                :rules="inputRules"
                              ></ckeditor>
                            </div>
                            
                        </v-flex>
                        <v-flex xs12>
                            <div class="mt-5">
                                <b>6. How are you and your team well-positioned to deliver this solution?</b
                                ><span class="red--text">*</span>
                            </div>
                            <div class="black--text">
                              <ckeditor
                                :editor="editor"
                                v-model="response.positioning"
                                :config="editorConfig"
                                :rules="inputRules"
                              ></ckeditor>
                            </div>
                              
                        </v-flex>
                        <v-flex xs12>
                            <div class="mt-5">
                                <b>7. Why are you applying to Solve?</b
                                ><span class="red--text">*</span>
                            </div>
                            <div class="black--text">
                              <ckeditor
                                :editor="editor"
                                v-model="response.application_reason"
                                :config="editorConfig"
                                :rules="inputRules"
                              ></ckeditor>
                            </div>
                              
                        </v-flex>
                        <v-flex xs12>
                            <div class="mt-5">
                                <b>8. What makes your solution innovative?</b
                                ><span class="red--text">*</span>
                            </div>
                            <div class="black--text">
                              <ckeditor
                                :editor="editor"
                                v-model="response.innovative"
                                :config="editorConfig"
                                :rules="inputRules"
                              ></ckeditor>
                            </div>
                              
                        </v-flex>
                        <v-flex xs12>
                            <div class="mt-5">
                                <b>9. What are your impact goals for the next year and the next five years, and how will you
achieve them?</b
                                ><span class="red--text">*</span>
                            </div>
                            <div class="black--text">
                              <ckeditor
                                :editor="editor"
                                v-model="response.impact_goals"
                                :config="editorConfig"
                                :rules="inputRules"
                              ></ckeditor>
                            </div>
                              
                        </v-flex>
                        <v-flex xs12>
                            <div class="mt-5">
                                <b>10. How are you measuring your progress toward your impact goals?</b
                                ><span class="red--text">*</span>
                            </div>
                            <div class="black--text">
                              <ckeditor
                                :editor="editor"
                                v-model="response.impact_goal_measure"
                                :config="editorConfig"
                                :rules="inputRules"
                              ></ckeditor>
                            </div>
                              
                        </v-flex>
                        <v-flex xs12>
                            <div class="mt-5">
                                <b>11. What is your theory of change?</b
                                ><span class="red--text">*</span>
                            </div>
                            <div class="black--text">
                              <ckeditor
                                :editor="editor"
                                v-model="response.change_theory"
                                :config="editorConfig"
                                :rules="inputRules"
                              ></ckeditor>
                            </div>
                              
                        </v-flex>
                        <v-flex xs12>
                            <div class="mt-5">
                                <b>12. Describe the core technology that powers your solution.</b
                                ><span class="red--text">*</span>
                            </div>
                            <div class="black--text">
                              <ckeditor
                                :editor="editor"
                                v-model="response.core_technology"
                                :config="editorConfig"
                                :rules="inputRules"
                              ></ckeditor>
                            </div>
                              
                        </v-flex>
                        <v-flex xs12>
                            <div class="mt-5">
                                <b>13. What is your approach to incorporating diversity, equity, and inclusivity into your work?</b
                                ><span class="red--text">*</span>
                            </div>
                            <div class="black--text">
                              <ckeditor
                                :editor="editor"
                                v-model="response.incorporation_approach"
                                :config="editorConfig"
                                :rules="inputRules"
                              ></ckeditor>
                            </div>
                              
                        </v-flex>
                        <v-flex xs12>
                            <div class="mt-5">
                                <b>14. What is your business model?</b
                                ><span class="red--text">*</span>
                            </div>
                            <div class="black--text">
                              <ckeditor
                                :editor="editor"
                                v-model="response.business_model"
                                :config="editorConfig"
                                :rules="inputRules"
                              ></ckeditor>
                            </div>
                              
                        </v-flex>
                        <v-flex xs12>
                            <div class="mt-5">
                                <b>15. What is your plan for becoming financially sustainable?</b
                                ><span class="red--text">*</span>
                            </div>
                            <div class="black--text">
                              <ckeditor
                                :editor="editor"
                                v-model="response.financial_stability"
                                :config="editorConfig"
                                :rules="inputRules"
                              ></ckeditor>
                            </div>
                              
                        </v-flex>
                        <v-flex xs12>
                            <div class="mt-5">
                                <b>16. Share some examples of how your plan to achieve financial sustainability has been
successful so far.</b
                                ><span class="red--text">*</span>
                            </div>
                            <div class="black--text">
                              <ckeditor
                                :editor="editor"
                                v-model="response.financial_stability_success"
                                :config="editorConfig"
                                :rules="inputRules"
                              ></ckeditor>
                            </div>
                              
                        </v-flex> 
                        <v-flex xs12>
                            <div class="mt-5">
                                <b>17. Link to your Innovation</b
                                >
                            </div>
                            <v-text-field
                                outlined
                                dense
                                v-model="response.innovation_link"
                                :rules="inputRules"
                                class="login_field mb-5"
                            ></v-text-field>
                        </v-flex>
                    </v-layout>
                </div>
            </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            
            class="text-none my-3"
            depressed
            @click="save()"
            :loading="loading"
          >
            Submit
            <v-icon right small class="white--text"> mdi-upload </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout column>
      <v-flex xs12 class="mt-5 mb-5">
        <v-container>
          <v-card outlined style="border-radius: 25px;">
            <v-card-text>
              <v-layout column>
                <v-flex xs12>
                  <v-layout row wrap class="my-5">
                    <v-flex xs12 md3>
                      <div align="center" class="mt-2">
                        <v-img
                          v-if="call.featured_image==null" 
                          style="border-radius: 25px;"
                          max-width="250"
                          height="150"
                          src="img/innovation.jpg"
                        >
                        </v-img>
                        <v-img
                          v-else
                          style="border-radius: 25px;"
                          max-width="250"
                          height="150"
                          :src="path+'/storage/call_pics/'+call.featured_image"
                        >
                        </v-img>
                      </div>
                    </v-flex>
                    <v-flex xs12 md5>
                      <v-layout column>
                        <v-flex xs12>
                          <div class="title" style="font-family: 'poppins' !important;">{{ call.title }}</div>
                        </v-flex>
                        <v-flex xs12>
                          <div class="caption grey--text">
                            <i
                              ><b>Created:</b>
                              {{ call.created_at | moment("DD/MM/YYYY") }}</i
                            >
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div class="caption grey--text mb-3">
                            <i
                              ><b>Deadline:</b>
                              {{
                                call.deadline | moment("DD/MM/YYYY - h:mm a")
                              }}</i
                            >
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div class="grey--text">{{ call.brief }}</div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="title" style="font-family: 'poppins' !important;">Tags</div>
                      <template
                        v-for="(call_sector, index) in call.call_sectors"
                      >
                        <v-chip
                          x-small
                          :color="call_sector.sector.color"
                          class="white--text mr-1"
                          :key="index"
                        >
                          #{{ call_sector.sector.name }}
                        </v-chip>
                      </template>
                      <div class="title mt-2">Get in touch</div>
                      <div>
                        <a
                          v-if="call.action_url != null"
                          :href="call.action_url"
                        >
                          <v-btn
                            depressed
                            small
                            class="text-none primary white--text mx-1"
                          >
                            External Link
                            <v-icon right>mdi-open-in-new</v-icon>
                          </v-btn>
                        </a>
                        <v-btn
                          depressed
                          small
                          class="text-none secondary primary--text mx-1"
                          @click="dialog = true"
                        >
                          Reply to Call
                          <v-icon right>mdi-file-document-plus</v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 class="my-3">
                  <div class="title" style="font-family: 'poppins' !important;">Share Via</div>
                  <div class="mt-1">
                    <v-btn
                      small
                      rounded
                      depressed
                      class="text-none white--text mr-1 mb-1"
                      color="#3b5998"
                    >
                      <v-icon>mdi-facebook</v-icon>
                      <ShareNetwork
                        style="text-decoration: none"
                        class="white--text ml-2"
                        network="facebook"
                        :url="shareLink"
                        :title="call.title"
                        :description="call.brief"
                        hashtags="InnovationBridge"
                      >
                        <span>Facebook</span>
                      </ShareNetwork>
                    </v-btn>
                    <v-btn
                      small
                      rounded
                      depressed
                      class="text-none white--text mr-1 mb-1"
                      color="#1DA1F2"
                    >
                      <v-icon>mdi-twitter</v-icon>
                      <ShareNetwork
                        style="text-decoration: none"
                        class="white--text ml-2"
                        network="twitter"
                        :url="shareLink"
                        :title="call.title"
                        :description="call.brief"
                        hashtags="InnovationBridge"
                      >
                        <span>Twitter</span>
                      </ShareNetwork>
                    </v-btn>

                    <v-btn
                      small
                      rounded
                      depressed
                      class="text-none white--text mr-1 mb-1"
                      color="#0e76a8"
                    >
                      <v-icon>mdi-linkedin</v-icon>
                      <ShareNetwork
                        style="text-decoration: none"
                        class="white--text ml-2"
                        network="linkedin"
                        :url="shareLink"
                        :title="call.title"
                        :description="call.brief"
                        hashtags="InnovationBridge"
                      >
                        <span>LinkedIn</span>
                      </ShareNetwork>
                    </v-btn>
                    <v-btn
                      small
                      rounded
                      depressed
                      class="text-none white--text mr-1 mb-1"
                      color="#075e54"
                    >
                      <v-icon>mdi-whatsapp</v-icon>
                      <ShareNetwork
                        style="text-decoration: none"
                        class="white--text ml-2"
                        network="whatsapp"
                        :url="shareLink"
                        :title="call.title"
                        :description="call.brief"
                        hashtags="InnovationBridge"
                      >
                        <span>Whatsapp</span>
                      </ShareNetwork>
                    </v-btn>
                    <v-btn
                      small
                      rounded
                      depressed
                      class="text-none white--text mr-1 mb-1"
                      color="#0088cc"
                    >
                      <v-icon>mdi-send mdi-rotate-315</v-icon>
                      <ShareNetwork
                        style="text-decoration: none"
                        class="white--text ml-2"
                        network="telegram"
                        :url="shareLink"
                        :title="call.title"
                        :description="call.brief"
                        hashtags="InnovationBridge"
                      >
                        <span>Telegram</span>
                      </ShareNetwork>
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-container>
      </v-flex>
      <v-flex xs12 class="mt-2 mb-15">
        <v-progress-linear
          v-if="ratingLoader"
          height="1"
          indeterminate
          color="primary"
        >
        </v-progress-linear>
        <v-card outlined>
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 md8>
                <v-tabs show-arrows v-model="tab" class="mt-6">
                  <v-tabs-slider color="primary"></v-tabs-slider>
                  <v-tab @click="activeTab = 'about'">
                    <div class="text-none text--text">About</div>
                  </v-tab>
                  <v-tab
                    @click="activeTab = 'institutions'"
                    v-if="call.institution_calls.length != 0"
                  >
                    <div class="text-none">
                      Institutions ({{ call.institution_calls.length }})
                    </div>
                  </v-tab>
                  <v-tab
                    @click="activeTab = 'organisations'"
                    v-if="call.organisation_calls.length != 0"
                  >
                    <div class="text-none">
                      Organisations ({{ call.organisation_calls.length }})
                    </div>
                  </v-tab>
                </v-tabs>
                <div v-if="activeTab == 'about'">
                  <p class="caption mx-3 mt-6" v-html="call.description"></p>
                </div>
                <div v-if="activeTab == 'institutions'">
                  <v-layout column class="mt-5">
                    <template
                      v-for="(
                        institution_call, index
                      ) in call.institution_calls"
                    >
                      <div :key="index">
                        <v-flex xs12 class="mb-4">
                          <a style="text-decoration: none">
                            <v-card
                              outlined
                              @click="goToInstitution(institution_call.institution)"
                              style="border-radius: 25px"
                            >
                              <v-card-text>
                                <v-layout row wrap>
                                  <v-flex xs12 md4>
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div align="center" class="my-5 mx-3">
                                          <v-img
                                            max-height="150"
                                            v-if="
                                              institution_call
                                                .institution.logo == null
                                            "
                                            src="img/logo_fav.png"
                                            style="border-radius: 25px"
                                          >
                                          </v-img>
                                          <v-img
                                            max-height="150"
                                            v-else
                                            :src="
                                              path +
                                              '/storage/institution_pictures/' +
                                              institution_call
                                                .institution.logo
                                            "
                                            style="border-radius: 25px"
                                          >
                                          </v-img>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 md8>
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div class="mt-7 title">
                                          {{
                                            institution_call.institution
                                              .name
                                          }}
                                        </div>
                                        <v-clamp
                                          autoresize
                                          :max-lines="4"
                                          class="mb-5 mr-5 caption"
                                          >{{
                                            institution_call.institution
                                              .brief
                                          }}
                                        </v-clamp>
                                        <!-- <div class="subtitle-2"><b>Created:</b> </div> -->
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-card-text>
                            </v-card>
                          </a>
                        </v-flex>
                      </div>
                    </template>
                  </v-layout>
                </div>
                <div v-if="activeTab == 'organisations'">
                  <v-layout column class="mt-5">
                    <template
                      v-for="(
                        organisation_call, index
                      ) in call.organisation_calls"
                    >
                      <div :key="index">
                        <v-flex xs12 class="mb-4">
                          <a style="text-decoration: none">
                            <v-card
                              outlined
                              @click="goToOrganisation(organisation_call.organisation)"
                              style="border-radius: 25px"
                            >
                              <v-card-text>
                                <v-layout row wrap>
                                  <v-flex xs12 md4>
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div align="center" class="my-5 mx-3">
                                          <v-img
                                            max-height="150"
                                            v-if="
                                              organisation_call
                                                .organisation.logo == null
                                            "
                                            src="img/logo_fav.png"
                                            style="border-radius: 25px"
                                          >
                                          </v-img>
                                          <v-img
                                            max-height="150"
                                            v-else
                                            :src="
                                              path +
                                              '/storage/organisation_pictures/' +
                                              organisation_call
                                                .organisation.logo
                                            "
                                            style="border-radius: 25px"
                                          >
                                          </v-img>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 md8>
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div class="mt-7 title">
                                          {{
                                            organisation_call.organisation
                                              .name
                                          }}
                                        </div>
                                        <v-clamp
                                          autoresize
                                          :max-lines="4"
                                          class="mb-5 mr-5 caption"
                                          >{{
                                            organisation_call.organisation
                                              .brief
                                          }}
                                        </v-clamp>
                                        <!-- <div class="subtitle-2"><b>Created:</b> </div> -->
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-card-text>
                            </v-card>
                          </a>
                        </v-flex>
                      </div>
                    </template>
                  </v-layout>
                </div>
              </v-flex>
              <v-flex xs12 md4>
                <v-layout column>
                  <v-flex xs12>
                    <v-card
                      outlined
                      elevation="0"
                      class="pa-5 ma-3 mt-5 tableHeader"
                    >
                    <div class="body"><b>Looking For</b></div>
                    <div class="caption primary--text mt-5">
                      <template
                        v-for="(
                          call_interest, index
                        ) in call.call_interests"
                      >
                        <b> • {{ call_interest.interest.name }}</b>
                      </template>
                    </div>
                    <div class="body mt-5"><b>SDG(s)</b></div>
                    <div class="caption primary--text mt-5">
                      <template
                        v-for="(
                          call_sdg, index
                        ) in call.call_sdgs"
                      >
                        <b> • {{ call_sdg.sdg.name }}</b>
                      </template>
                    </div>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
          
      </v-flex>
    </v-layout>
  </div>
</template>
<style>
.ck-editor__editable {
  min-height: 200px;
}
</style>
<script>
import Vue from "vue";
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";
import VClamp from "vue-clamp";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadAdapter from '@/uploadAdapter.js';

Vue.use(CKEditor);

export default {
  components: {
    VClamp,
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      selection: 1,
      drawer: false,
      dialog: false,
      loading: false,
      confirm: false,
      notFound: false,

      editor: ClassicEditor,
      editorConfig: {
          extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
              return new uploadAdapter(loader);
          } }],
          language: 'en',
      },

      tab: null,
      activeTab: "about",

      myRating: {
        innovation_id: null,
        rating: null,
      },

      ratingLoader: false,

      shareLink: null,

      response: {
        id: null,
        call_id: null,
        challenge_addressed: null,
        one_line_summary: null,
        problem_solved: null,
        solution: null,
        target_audience: null,
        positioning: null,
        application_reason: null,
        innovative: null,
        impact_goals: null,
        impact_goal_measure: null,
        change_theory: null,
        core_technology: null,
        incorporation_approach: null,
        business_model: null,
        financial_stability: null,
        financial_stability_success: null,
        innovation_link: null
      }
    };
  },
  mounted() {
    this.initialize();
    this.createShareLink();
  },
  watch: {
      notFound() {
        this.findCall();
      },
    },
  created() {
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapActions(["filterSearchCalls"]),
    initialize() {
      this.recordView();
      this.assignValues();
    },
    goToInstitution(institution){
      window.scrollTo(0, 0);
      this.$router.push("/institutions/" + institution.slug);
    },
      goToOrganisation(organisation){
        window.scrollTo(0, 0);
        this.$router.push("/organisations/" + organisation.slug);
      },
    findCall(){      
        apiCall({
          url:
            "/api/call?type=findCall&page=1&slug=" +
            this.$route.params.slug,
          method: "GET",
        })
          .then((resp) => {
            this.filterSearchCalls(resp);
            // this.recordView()
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      },
    assignValues(){
      if(this.call.my_response != null){
        this.response.id = this.call.my_response.id
        this.response.call_id = this.call.my_response.call_id
        this.response.challenge_addressed = this.call.my_response.challenge_addressed
        this.response.one_line_summary = this.call.my_response.one_line_summary
        this.response.problem_solved = this.call.my_response.problem_solved
        this.response.solution = this.call.my_response.solution
        this.response.target_audience = this.call.my_response.target_audience
        this.response.positioning = this.call.my_response.positioning
        this.response.application_reason = this.call.my_response.application_reason
        this.response.innovative = this.call.my_response.innovative
        this.response.impact_goals = this.call.my_response.impact_goals
        this.response.impact_goal_measure = this.call.my_response.impact_goal_measure
        this.response.change_theory = this.call.my_response.change_theory
        this.response.core_technology = this.call.my_response.core_technology
        this.response.incorporation_approach = this.call.my_response.incorporation_approach
        this.response.business_model = this.call.my_response.business_model
        this.response.financial_stability = this.call.my_response.financial_stability
        this.response.financial_stability_success = this.call.my_response.financial_stability_success
        this.response.innovation_link = this.call.my_response.innovation_link
      }
    },
    createShareLink() {
      this.shareLink =
        this.path + "/#/call-for-proposals/" + this.$route.params.slug;
    },
    recordView() {
      this.myRating.innovation_id = this.call.id;
      apiCall({
        url: "/api/call-view",
        data: this.myRating,
        method: "POST",
      })
        .then((resp) => {})
        .catch((error) => {});
    },
    save(){
      if (this.$refs.form.validate()) {
        if (this.response.id != null) {
          confirm("Are you sure you want to update your response?") && (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/innovation/" + this.newInnovation.id,
              data: this.newInnovation,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Response Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.dialog = false;
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm("Are you sure you want to create innovation?") && (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            this.response.call_id = this.call.id
            apiCall({
              url: "/api/call-response",
              data: this.response,
              method: "POST",
            })
              .then((resp) => {
                this.loading = false;
                this.dialog = false;
                this.color = "success";
                this.message = "Response saved Successfully";
                this.snackbar = true;
                this.confirm = false
              })
              .catch((error) => {
                this.loading = false;
                this.color = "error";
                this.message = "An error occurred";
                this.snackbar = true;
              });
          }
        }
      }
         
    }
  },
  computed: {
    ...mapGetters(["publicCalls"]),

    call() {
      if (this.$store.getters.publicCalls.find((call) => call.slug == this.$route.params.slug)) {
        return this.$store.getters.publicCalls.find(
          (call) => call.slug == this.$route.params.slug
        );
      } else if ( this.$store.getters.searchCalls.find((call) => call.slug == this.$route.params.slug)) {
        return this.$store.getters.searchCalls.find(
          (call) => call.slug == this.$route.params.slug
        );
      } else {
        console.log("hi")
        this.notFound = true
      }
    },
  },
};
</script>
