<template>
    <div class="asks" v-if="$can('contact_us_view')">
      <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
        <span>{{ message }}</span>
      </v-snackbar>
      <v-container>
        <v-card outlined class="mt-5" v-if="view == 'open' || view == 'closed'">
          <v-tabs show-arrows v-model="viewTab">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab @click="changeView('open')">
              <div class="text-none text--text">Unread Messages ({{ openContactPagination.total }})</div>
            </v-tab>
            <v-tab @click="changeView('closed')">
              <div class="text-none text--text">Read Messages ({{ closedContactPagination.total }})</div>
            </v-tab>
  
          </v-tabs>
        </v-card>
  
        <div v-if="view == 'open'">
          <v-layout column>
            <v-card outlined class="mt-2">
              <v-progress-linear v-if="openContactLoader" height="1" indeterminate color="primary"></v-progress-linear>
              <v-flex xs12 class="mt-10 mb-2">
                <div class="mx-5">
                  <v-layout row wrap>
                    <v-flex xs12 md4>
                      <h1> </h1>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-1">
                        <v-text-field class="text_field header" outlined dense label="Search"
                          append-icon="mdi-undo-variant" @click:append="resetOpenAskSearch()"
                          v-on:keyup.enter="searchOpenAsk" v-model="openAskSearchTerm"
                          @input="enableOpenAskSearch()"></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md2>
                      <div class="pa-1">
                        <v-btn class="secondary primary--text text-none" depressed block @click="searchOpenAsk"
                          :loading="searchOpenAskLoader" :disabled="openAskSearchButtonDisabled">
                          Search
                          <v-icon right>mdi-magnify</v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                    <v-flex xs12 md2>
                      
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>
              <v-divider class="mt-9 mx-5"></v-divider>
              <v-flex xs12 class="mt-5">
                <div v-if="openContacts.length == 0">
                  <v-card elevation="0">
                    <v-layout row wrap>
                      <v-flex xs12 md1>
                        <v-container fill-height fluid>
                          <v-row align="center" justify="center">
                            <v-col class="text-center">
                              <v-icon large class="text--text">
                                mdi-alert-circle-outline
                              </v-icon>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-flex>
                      <v-flex xs12 md11>
                        <v-container fill-height fluid>
                          <v-row align="center" justify="center">
                            <v-col class="text-center"> No New Messages </v-col>
                          </v-row>
                        </v-container>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </div>
                <div v-else>
                  <div class="hidden-sm-and-down">
                    <v-card elevation="0" class="pa-2">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead class="header">
                            <tr class="tableHeader">
                              <th class="text-left text--text">User</th>
                              <th class="text-left text--text">Subject</th>
                              <th class="text-left text--text">Message</th>
                              <th class="text-left text--text">Creation Date</th>
                              <th class="text-right text--text">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in openContacts" :key="item.id">
                              <td>
                                {{ item.user.f_name }} {{ item.user.m_name }} {{ item.user.l_name }}
                              </td>
                              <td>
                                <v-clamp
                                    autoresize
                                    :max-lines="2"
                                    >
                                    {{ item.subject }}
                                </v-clamp>
                              </td>
                              <td>
                                <v-clamp
                                    autoresize
                                    :max-lines="2"
                                    >
                                    {{ item.message }}
                                </v-clamp>
                              </td>
                              <td>
                                {{
                                  item.created_at | moment("DD/MM/YYYY - hh:mm a")
                                }}
                              </td>
                              <td>
                                <div align="right">
                                  <v-tooltip top v-if="$can('contact_us_view')">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon class="button mr-1 universal--text" @click="showMessage(item)">
                                        <v-icon small> mdi-eye </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>View Message</span>
                                  </v-tooltip>
  
                                  <v-tooltip top v-if="$can('contact_us_archive')">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon class="button mr-1 error--text" @click="deleteMessage(item)"
                                        :loading="loading && messageIndex == item.id" v-bind="attrs" v-on="on">
                                        <v-icon small> mdi-delete </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>delete</span>
                                  </v-tooltip>
  
                                  <v-tooltip top v-if="
                                    $can('contact_us_mark_read') && item.read == 0
                                  ">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon class="button mr-1 red--text" @click="markAsRead(item)" :loading="
                                        activateLoading && messageIndex == item.id
                                      " v-bind="attrs" v-on="on">
                                        <v-icon small>
                                          mdi-toggle-switch-off-outline
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Mark as Read</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="
                                    $can('contact_us_mark_unread') && item.read == 1
                                  ">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon class="button mr-1 green--text" @click="markAsUnread(item)" :loading="
                                        deactivateLoading &&
                                        messageIndex == item.id
                                      " v-bind="attrs" v-on="on">
                                        <v-icon small> mdi-toggle-switch </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Mark as Unread</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="$can('log_view')">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        class="button mr-1 purple--text"
                                        @click="viewLog(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon small> mdi-script-text </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Log</span>
                                  </v-tooltip>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </div>
                  <div class="hidden-md-and-up">
                    <v-layout column>
                      <template v-for="(openContact, index) in openContacts">
                        <div :key="index">
                          <v-flex xs12 class="mb-2">
                            <v-card elevation="0">
                              <div class="pa-5">
                                <v-layout column>
                                  <v-flex xs12>
                                    <v-layout row wrap>
                                      <v-flex xs11>
                                        <div class="title text--text ml-3">
                                          <b>{{ openContact.subject }}</b>
                                        </div>
                                      </v-flex>
                                      <v-flex xs1>
                                        <v-btn depressed class="red--text" :loading="
                                          loading && messageIndex == openContact.id
                                        " icon @click="deleteMessage(openContact)" v-if="$can('contact_us_archive')">
                                          <v-icon> mdi-delete </v-icon>
                                        </v-btn>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-divider class="mt-2"></v-divider>
                                  <v-flex xs12 class="mt-1">
                                    <v-layout column>
                                      <v-flex xs12 class="mt-1">
                                        <div>
                                          <b>Message</b>
                                        </div>
                                        <div>
                                            <v-clamp
                                                autoresize
                                                :max-lines="1"
                                                v-html="openContact.message"
                                                >
                                            </v-clamp>
                                        </div>
                                      </v-flex>
                                      <v-flex xs12 class="mt-1">
                                        <div>
                                          <b>Creation Date</b>
                                        </div>
                                        <div>
                                          {{
                                            openContact.created_at | moment("DD/MM/YYYY - hh:mm a")
                                          }}
                                        </div>
                                      </v-flex>
  
                                      <v-flex xs12 class="mt-5">
                                        <v-layout row wrap>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn
                                                depressed
                                                class="
                                                  primary
                                                  text-none
                                                  white--text
                                                "
                                                block
                                                @click="showMessage(openContact)"
                                                v-if="$can('contact_us_view')"
                                                >View
                                                <v-icon right> mdi-eye </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn depressed class="green text-none white--text" block
                                                @click="markAsRead(openContact)" v-if="$can('contact_us_mark_read') && openContact.read == 0"
                                                :loading="
                                                  activateLoading && messageIndex == openContact.id"
                                                >Mark as Read
                                                <v-icon right>
                                                  mdi-toggle-switch-off-outline
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn depressed class="red text-none white--text" block
                                                @click="markAsUnread(openContact)" v-if="$can('contact_us_mark_unread') && openContact.read == 1"
                                                :loading="
                                                  deactivateLoading && messageIndex == openContact.id"
                                                >Mark as Unread
                                                <v-icon right>
                                                  mdi-toggle-switch
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn
                                                depressed block
                                                class="purple text-none white--text"
                                                @click="viewLog(openContact)"
                                                v-bind="attrs"
                                                v-on="on"
                                              >Log
                                                <v-icon right> mdi-script-text </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </v-card>
                          </v-flex>
                        </div>
                      </template>
                    </v-layout>
                  </div>
                </div>
              </v-flex>
            </v-card>
  
            <v-layout column class="mt-5">
              <v-flex xs12>
                <div align="center">
                  <v-pagination v-if="openContactLength != 0" :length="openContactLength" total-visible="10"
                    v-model="openContactPagination.current_page" @input="changeOpenAskPage()" circle>
                  </v-pagination>
                </div>
              </v-flex>
              <v-flex xs12>
                <div v-if="length != 0" align="center" class="mt-5">
                  <b class="text--text">Total: </b>{{ openContactPagination.total | formatNumber }}
                </div>
              </v-flex>
            </v-layout>
  
  
  
          </v-layout>
        </div>
        <div v-if="view == 'closed'">
          <v-layout column>
            <v-card outlined class="mt-2">
              <v-progress-linear v-if="closedContactLoader" height="1" indeterminate color="primary"></v-progress-linear>
              <v-flex xs12 class="mt-10 mb-2">
                <div class="mx-5">
                  <v-layout row wrap>
                    <v-flex xs12 md4>
                      <h1> </h1>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-1">
                        <v-text-field class="text_field header" outlined dense label="Search"
                          append-icon="mdi-undo-variant" @click:append="resetClosedAskSearch()"
                          v-on:keyup.enter="searchClosedAsk" v-model="closedAskSearchTerm"
                          @input="enableClosedAskSearch()"></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md2>
                      <div class="pa-1">
                        <v-btn class="secondary primary--text text-none" depressed block @click="searchClosedAsk"
                          :loading="searchClosedAskLoader" :disabled="closedAskSearchButtonDisabled">
                          Search
                          <v-icon right>mdi-magnify</v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                    <v-flex xs12 md2>
                      
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>
              <v-divider class="mt-9 mx-5"></v-divider>
              <v-flex xs12 class="mt-5">
                <div v-if="closedContacts.length == 0">
                  <v-card elevation="0">
                    <v-layout row wrap>
                      <v-flex xs12 md1>
                        <v-container fill-height fluid>
                          <v-row align="center" justify="center">
                            <v-col class="text-center">
                              <v-icon large class="text--text">
                                mdi-alert-circle-outline
                              </v-icon>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-flex>
                      <v-flex xs12 md11>
                        <v-container fill-height fluid>
                          <v-row align="center" justify="center">
                            <v-col class="text-center"> No New Messages </v-col>
                          </v-row>
                        </v-container>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </div>
                <div v-else>
                  <div class="hidden-sm-and-down">
                    <v-card elevation="0" class="pa-2">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead class="header">
                            <tr class="tableHeader">
                              <th class="text-left text--text">User</th>
                              <th class="text-left text--text">Subject</th>
                              <th class="text-left text--text">Message</th>
                              <th class="text-left text--text">Creation Date</th>
                              <th class="text-right text--text">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in closedContacts" :key="item.id">
                                <td>
                                {{ item.user.f_name }} {{ item.user.m_name }} {{ item.user.l_name }}
                              </td>
                              <td>
                                <v-clamp
                                    autoresize
                                    :max-lines="2"
                                    >
                                    {{ item.subject }}
                                </v-clamp>
                              </td>
                              <td>
                                <v-clamp
                                    autoresize
                                    :max-lines="2"
                                    >
                                    {{ item.message }}
                                </v-clamp>
                              </td>
                              <td>
                                {{
                                  item.created_at | moment("DD/MM/YYYY - hh:mm a")
                                }}
                              </td>
                              <td>
                                <div align="right">
                                  <v-tooltip top v-if="$can('contact_us_view')">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon class="button mr-1 universal--text" @click="showMessage(item)">
                                        <v-icon small> mdi-eye </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>View Message</span>
                                  </v-tooltip>
  
                                  <v-tooltip top v-if="$can('contact_us_archive')">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon class="button mr-1 error--text" @click="deleteMessage(item)"
                                        :loading="loading && messageIndex == item.id" v-bind="attrs" v-on="on">
                                        <v-icon small> mdi-delete </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>delete</span>
                                  </v-tooltip>
  
                                  <v-tooltip top v-if="
                                    $can('contact_us_mark_read') && item.read == 0
                                  ">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon class="button mr-1 red--text" @click="markAsRead(item)" :loading="
                                        activateLoading && messageIndex == item.id
                                      " v-bind="attrs" v-on="on">
                                        <v-icon small>
                                          mdi-toggle-switch-off-outline
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Mark as Read</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="
                                    $can('contact_us_mark_unread') && item.read == 1
                                  ">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon class="button mr-1 green--text" @click="markAsUnread(item)" :loading="
                                        deactivateLoading &&
                                        messageIndex == item.id
                                      " v-bind="attrs" v-on="on">
                                        <v-icon small> mdi-toggle-switch </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Mark as Unread</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="$can('log_view')">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        class="button mr-1 purple--text"
                                        @click="viewLog(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon small> mdi-script-text </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Log</span>
                                  </v-tooltip>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </div>
                  <div class="hidden-md-and-up">
                    <v-layout column>
                      <template v-for="(closedContact, index) in closedContacts">
                        <div :key="index">
                          <v-flex xs12 class="mb-2">
                            <v-card elevation="0">
                              <div class="pa-5">
                                <v-layout column>
                                  <v-flex xs12>
                                    <v-layout row wrap>
                                      <v-flex xs11>
                                        <div class="title text--text ml-3">
                                          <b>{{ closedContact.title }}</b>
                                        </div>
                                      </v-flex>
                                      <v-flex xs1>
                                        <v-btn depressed class="red--text" :loading="
                                          loading && messageIndex == closedContact.id
                                        " icon @click="deleteMessage(closedContact)" v-if="$can('contact_us_archive')">
                                          <v-icon> mdi-delete </v-icon>
                                        </v-btn>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-divider class="mt-2"></v-divider>
                                  <v-flex xs12 class="mt-1">
                                    <v-layout column>
                                      <v-flex xs12 class="mt-1">
                                        <div>
                                          <b>Message</b>
                                        </div>
                                        <div>
                                            <v-clamp
                                                autoresize
                                                :max-lines="1"
                                                v-html="closedContact.message"
                                                >
                                            </v-clamp>
                                        </div>
                                      </v-flex>
                                      <v-flex xs12 class="mt-1">
                                        <div>
                                          <b>Creation Date</b>
                                        </div>
                                        <div>
                                          {{
                                            closedContact.created_at | moment("DD/MM/YYYY - hh:mm a")
                                          }}
                                        </div>
                                      </v-flex>
  
                                      <v-flex xs12 class="mt-5">
                                        <v-layout row wrap>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn
                                                depressed
                                                class="
                                                  primary
                                                  text-none
                                                  white--text
                                                "
                                                block
                                                @click="showMessage(closedContact)"
                                                v-if="$can('contact_us_view')"
                                                >View
                                                <v-icon right> mdi-eye </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn depressed class="green text-none white--text" block
                                                @click="markAsRead(closedContact)" v-if="$can('contact_us_mark_read') && closedContact.read == 0"
                                                :loading="
                                                  activateLoading && messageIndex == closedContact.id"
                                                >Mark as Read
                                                <v-icon right>
                                                  mdi-toggle-switch-off-outline
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn depressed class="red text-none white--text" block
                                                @click="markAsUnread(closedContact)" v-if="$can('contact_us_mark_unread') && closedContact.read == 1"
                                                :loading="
                                                  deactivateLoading && messageIndex == closedContact.id"
                                                >Mark as Unread
                                                <v-icon right>
                                                  mdi-toggle-switch
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn
                                                depressed block
                                                class="purple text-none white--text"
                                                @click="viewLog(closedContact)"
                                                v-bind="attrs"
                                                v-on="on"
                                              >Log
                                                <v-icon right> mdi-script-text </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </v-card>
                          </v-flex>
                        </div>
                      </template>
                    </v-layout>
                  </div>
                </div>
              </v-flex>
            </v-card>
            <v-layout column class="mt-5">
              <v-flex xs12>
                <div align="center">
                  <v-pagination v-if="closedContactLength != 0" :length="closedContactLength" total-visible="10"
                    v-model="closedContactPagination.current_page" @input="changeClosedAskPage()" circle>
                  </v-pagination>
                </div>
              </v-flex>
              <v-flex xs12>
                <div v-if="length != 0" align="center" class="mt-5">
                  <b class="text--text">Total: </b>{{ closedContactPagination.total | formatNumber }}
                </div>
              </v-flex>
            </v-layout>
          </v-layout>
        </div>
        <div v-if="view == 'show'">
          <v-card outlined class="mt-2">
            <v-card-title class="tableHeader text--text">
              {{ assignedAsk.subject }}
              <v-spacer></v-spacer>
              <v-btn icon @click="changeView('open')">
                <v-icon class="text--text"> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div class="pa-3">
                <div class="mx-3 mt-2">
                  <v-layout row wrap>
                        <!-- <v-flex xs12 md3>
                          <div class="mr-8 mt-5">
                            <v-img
                              max-height="150"
                              v-if="assignedAsk.featured_image==null"
                              src="img/logo_primary.png"
                              style="border-radius: 25px;"
                            >
                            </v-img>
                            <v-img
                              max-height="150"
                              v-else
                              :src="path+'/storage/ask_pics/'+assignedAsk.featured_image"
                              style="border-radius: 25px;"
                            >
                            </v-img>
                          </div>
                          
                        </v-flex> -->
                        <v-flex xs12>                  
                              <v-layout column>
                                <v-flex xs12 class="mt-7">
                                  <v-layout row wrap>
                                    <v-flex xs12 md2>
                                      <div><b>Name:</b></div>
                                    </v-flex>
                                    <v-flex xs12 md10>
                                        {{ assignedAsk.user.f_name }} {{ assignedAsk.user.m_name }} {{ assignedAsk.user.l_name }}
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-5">
                                  <v-layout row wrap>
                                    <v-flex xs12 md2>
                                      <div><b>Creation Date:</b></div>
                                    </v-flex>
                                    <v-flex xs12 md10>
                                      {{
                                        assignedAsk.created_at
                                          | moment("DD/MM/YYYY - hh:mm a")
                                      }}
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-10">
                                  <v-layout row wrap>
                                    <v-flex xs12 md2>
                                      <b>Message: </b>
                                    </v-flex>
                                    <v-flex xs12 md10>
                                      <p v-html="assignedAsk.message"></p>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12>
                              </v-flex>
                              </v-layout>
                        </v-flex>
                      </v-layout>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-container>
      <logData v-if="logDataModel" v-model="logDataModel" />
    </div>
  </template>
  <style>
  .ck-editor__editable {
    min-height: 200px;
  }
  </style>
  <script>
  import apiCall from "@/utils/api";
  import { mapGetters, mapActions } from "vuex";
  import logData from "@/views/accessControl/logData";
  import Vue from "vue";
  import CKEditor from "@ckeditor/ckeditor5-vue2";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  import VClamp from "vue-clamp";
  import uploadAdapter from '@/uploadAdapter.js';

  var numeral = require("numeral");
  Vue.filter("formatNumber", function (value) {
    return numeral(value).format("0,0");
  });
  
  Vue.use(require("vue-moment"));
  
  export default {
    components: {
      logData,
      VClamp,
    },
    data() {
      return {
        path: process.env.VUE_APP_API_URL,
        view: "open",
        loading: false,
        activateLoading: false,
        deactivateLoading: false,
        askView: "default",
  
        callTab: null,
  
        editor: ClassicEditor,
        editorConfig: {
            extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                return new uploadAdapter(loader);
            } }],
            language: 'en',
        },
  
        menu1: false,
        allowedStep: (m) => m % 15 === 0,
        valid: true,
        datePicker: false,
        message: "",
        color: "",
        delete: false,
        activate: false,
        confirm: false,
        messageIndex: null,
        snackbar: false,
        inputRules: [(v) => !!v || "Input is required"],
  
        openAskSearchTerm: null,
        closedAskSearchTerm: null,
        askResponseSearchTerm: null,
        searchOpenAskLoader: false,
        searchClosedAskLoader: false,
        openAskSearchButtonDisabled: false,
        closedAskSearchButtonDisabled: false,
        activeAskOwnerTab: 'self',
        filteredOpenAsks: false,
        filteredClosedAsks: false,
  
        askResponseLoader: false,
        askResponseSearchLoader: false,
        filteredAskResponses: false,
        askResponseSearchButtonDisabled: false,
  
        viewTab: null,
        newAsk: {
          id: null,
          amount: null,
          funding_type_id: null,
          tech_readiness_level_id: null,
          title: null,
          brief: null,
          description: null,          
          innovations: [],
        },
        assignedAsk: null,
        assignedResponse: null,
        formData: null,
        months: [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
        ],
        monthNames: [
          { name: "January" },
          { name: "February" },
          { name: "March" },
          { name: "April" },
          { name: "May" },
          { name: "June" },
          { name: "July" },
          { name: "August" },
          { name: "September" },
          { name: "October" },
          { name: "November" },
          { name: "December" },
        ],
        askResponses: [],
        contactResponsePagination: {
          search: " ",
          current_page: 1,
          per_page: 0,
          total: 0,
          visible: 10,
        },
        logData: {
          message: null,
          model: null,
        },
      };
    },
    created() {
      if (this.$can("contact_us_view")) {
        this.startOpenContactLoader();
        this.startClosedContactLoader();
        this.fetchOpenContacts(this.openContactPagination.current_page);
        this.fetchClosedContacts(this.closedContactPagination.current_page);
      }
    },
    methods: {
      ...mapActions([
        "fetchOpenContacts",
        "fetchClosedContacts",
        "startOpenContactLoader",
        "startClosedContactLoader",
        "stopOpenContactLoader",
        "stopClosedContactLoader",
        "filterOpenContacts",
        "filterClosedContacts",

        "filterLogData",
        "openLogDataModel",
      ]),
      enableOpenAskSearch() {
        this.openAskSearchButtonDisabled = false;
      },
      enableClosedAskSearch() {
        this.closedAskSearchButtonDisabled = false;
      },
      closeDate() {
        this.datePicker = false;
      },
      removeInnovation(item) {
        this.newAsk.innovations.splice(this.newAsk.innovations.indexOf(item), 1)
      },
      resetAskResponseSearch() {
        this.askResponseSearchTerm = null;
        this.filteredAskResponses = false;
        this.askResponseLoader = false;
        this.changeAskResponsePage();
      },
      changeAskOwnerTab(tab) {
        if (tab == 'self') {
          this.newAsk.personal = true
        } else {
          this.newAsk.personal = false
        }
        this.newAsk.institutions = [],
          this.newAsk.organisations = [],
  
          this.activeAskOwnerTab = tab
      },
      resetOpenAskSearch() {
        this.openAskSearchTerm = null;
        this.filteredClosedAsks = true;
        this.startOpenContactLoader();
        this.fetchOpenContacts(1);
      },
      resetClosedAskSearch() {
        this.closedAskSearchTerm = null;
        this.filteredClosedAsks = true;
        this.stopClosedContactLoader();
        this.fetchClosedContacts(1);
      },
      showMessage(ask) {
        this.assignedAsk = ask;
        // if (this.$can("ask_reponse_view")) {
        //   this.changeAskResponsePage();
        // }
        this.changeView("show");
      },
      showAskResponse(response){
        this.assignedResponse = response
        this.askView = 'show'
      },
      askResponseSearch() {
        if (this.askResponseSearchTerm == null) {
          this.askResponseSearchButtonDisabled = true;
        } else {
          this.askResponseSearchLoader = true;
          this.askResponseSearchButtonDisabled = false;
          this.startOpenContactLoader();
          this.startClosedContactLoader();
          apiCall({
            url:
            "/api/contact-us-response?call_id=" +
              this.assignedAsk.id +
              "&page=1&type=search&search=" +
              this.askResponseSearchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.askResponses = resp.data;
              this.contactResponsePagination.current_page =
                resp.current_page;
              this.contactResponsePagination.total = resp.total;
              this.contactResponsePagination.per_page = resp.per_page;
  
              this.askResponseSearchLoader = false;
              this.filteredAskResponses = true;
            })
            .catch((error) => {
              this.askResponseSearchLoader = false;
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              console.log(error);
            });
        }
      },
      changeAskResponsePage() {
        this.askResponseLoader = true;
        if (this.filteredAskResponses == true) {
          this.askResponseSearchButtonDisabled = false;
          apiCall({
            url:
              "/api/contact-us-response?call_id=" +
              this.assignedAsk.id +
              "&page=" +
              this.contactResponsePagination.current_page +
              "&type=search&search=" +
              this.askResponseSearchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.askResponseLoader = false;
  
              this.askResponses = resp.data;
              this.contactResponsePagination.current_page =
                resp.current_page;
              this.contactResponsePagination.total = resp.total;
              this.contactResponsePagination.per_page = resp.per_page;
            })
            .catch((error) => {
              console.log(error);
              this.askResponseLoader = false;
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
            });
        } else {
          apiCall({
            url:
              "/api/contact-us-response?call_id=" +
              this.assignedAsk.id +
              "&page=" +
              this.contactResponsePagination.current_page,
            method: "GET",
          })
            .then((resp) => {
              this.askResponseLoader = false;
  
              this.askResponses = resp.data;
              this.contactResponsePagination.current_page =
                resp.current_page;
              this.contactResponsePagination.total = resp.total;
              this.contactResponsePagination.per_page = resp.per_page;
            })
            .catch((error) => {
              console.log(error);
              this.askResponseLoader = false;
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
            });
        }
      },
      editAsk(ask) {
        this.newAsk.id = ask.id;
        this.newAsk.amount = ask.amount
        this.newAsk.funding_type_id = ask.funding_type_id
        this.newAsk.tech_readiness_level_id = ask.tech_readiness_level_id
        this.newAsk.title = ask.title
        this.newAsk.brief = ask.brief
        this.newAsk.description = ask.description

        if(ask.innovation_asks.length != 0){
          for (let i = 0; i < ask.innovation_asks.length; i++) {
            this.newAsk.innovations.push(ask.innovation_asks[i].innovation);
          }
          
        }

        this.changeView("create");
      },
      save() {
        if (this.$refs.form.validate()) {
          if (this.newAsk.id != null) {
            confirm("Are You Sure You Want to Update Ask") &&
              (this.confirm = true);
            if (this.confirm) {
              this.loading = true;
              apiCall({
                url: "/api/contact-us/" + this.newAsk.id,
                data: this.newAsk,
                method: "PUT",
              })
                .then((resp) => {
                  this.message = "Ask Updated Successfully";
                  this.color = "orange";
                  this.loading = false;
                  this.snackbar = true;
                  this.confirm = false;
                  this.changeView("open");
                  this.fetchOpenContacts(1);
                })
                .catch((error) => {
                  this.message = "An Error Occurred";
                  this.color = "error";
                  this.loading = false;
                  this.snackbar = true;
                });
            }
          } else {
            confirm("Are You Sure You Want to Create Ask?") &&
              (this.confirm = true);
            if (this.confirm) {
              this.loading = true;
              apiCall({
                url: "/api/contact-us",
                data: this.newAsk,
                method: "POST",
              })
                .then((resp) => {
                  this.message = "Ask Added Successfully";
                  this.color = "success";
                  this.loading = false;
                  this.snackbar = true;
                  this.changeView("open");
                  this.fetchOpenContacts(1);
                })
                .catch((error) => {
                  this.message = "An Error Occurred";
                  this.color = "error";
                  this.loading = false;
                  this.snackbar = true;
                  this.fetchOpenContacts(1);
                });
            }
          }
        }
      },
      searchOpenAsk() {
        if (this.openAskSearchTerm == null) {
          this.openAskSearchButtonDisabled = true;
        } else {
          this.searchOpenAskLoader = true;
          this.openAskSearchButtonDisabled = false;
          this.stopOpenContactLoader();
          apiCall({
            url: "/api/contact-us?type=open&typeB=search&search=" + this.openAskSearchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.filterOpenContacts(resp);
              this.searchOpenAskLoader = false;
              this.stopOpenContactLoader();
              this.filteredOpenAsks = true;
            })
            .catch((error) => {
              console.log(error.response);
              this.searchOpenAskLoader = false;
              this.stopOpenContactLoader();
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
            });
        }
      },
      searchClosedAsk() {
        if (this.closedAskSearchTerm == null) {
          this.closedAskSearchButtonDisabled = true;
        } else {
          this.searchClosedAskLoader = true;
          this.closedAskSearchButtonDisabled = false;
          this.stopClosedContactLoader();
          apiCall({
            url: "/api/contact-us?type=closed&typeB=search&search=" + this.closedAskSearchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.filterClosedContacts(resp);
              this.searchClosedAskLoader = false;
              this.stopClosedContactLoader();
              this.filteredOpenAsks = true;
            })
            .catch((error) => {
              this.searchClosedAskLoader = false;
              this.stopClosedContactLoader();
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              console.log(error);
            });
        }
      },
      changeView(view) {
        if (view == "open") {
          this.newAsk.id = null;
          this.newAsk.amount = null
          this.newAsk.funding_type_id = null
          this.newAsk.tech_readiness_level_id = null
          this.newAsk.title = null
          this.newAsk.brief = null
          this.newAsk.description = null
          this.newAsk.innovations = []
          
        } else if (view == "show") {
  
        }
        this.view = view;
      },
      changeOpenAskPage() {
        this.startOpenContactLoader();
        if (this.filteredOpenAsks == true) {
          this.openAskSearchButtonDisabled = false;
          apiCall({
            url:
              "/api/contact-us?type=open&page=" +
              this.openContactPagination.current_page +
              "&typeB=search&search=" +
              this.openAskSearchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.filterOpenContacts(resp);
              this.stopOpenContactLoader();
            })
            .catch((error) => {
              this.stopOpenContactLoader();
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              console.log(error);
            });
        } else {
          this.fetchOpenContacts(this.openContactPagination.current_page);
        }
      },
      changeClosedAskPage() {
        this.startClosedContactLoader();
        if (this.filteredClosedAsks == true) {
          this.closedAskSearchButtonDisabled = false;
          apiCall({
            url:
              "/api/contact-us?type=closed&page=" +
              this.closedContactPagination.current_page +
              "&typeB=search&search=" +
              this.closedAskSearchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.filterClosedContacts(resp);
              this.stopClosedContactLoader();
            })
            .catch((error) => {
              this.stopClosedContactLoader();
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              console.log(error);
            });
        } else {
          this.fetchClosedContacts(this.closedContactPagination.current_page);
        }
      },
      deleteMessage(item) {
        confirm("Are You Sure You Want to Delete Ask?") && (this.delete = true);
        if (this.delete) {
          this.loading = true;
          this.messageIndex = item.id;
          apiCall({ url: "/api/contact-us/" + item.id, method: "DELETE" })
            .then((resp) => {
              this.message = "Ask Deleted Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.messageIndex = null;
              this.fetchOpenContacts(this.openContactPagination.current_page);
              this.fetchClosedContacts(this.closedContactPagination.current_page);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.messageIndex = null;
              console.log(error.response);
            });
        }
      },
      markAsRead(item) {
        confirm("Are You Sure You Want to Mark as Read?") &&
          (this.activate = true);
        if (this.activate) {
          this.activateLoading = true;
          this.messageIndex = item.id;
          this.newAsk.id = item.id;
          apiCall({
            url: "/api/contact-us?type=read",
            data: this.newAsk,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Ask Activated Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.activateLoading = false;
              this.activate = false;
              this.messageIndex = null;
              this.fetchOpenContacts(this.openContactPagination.current_page);
              this.fetchClosedContacts(this.closedContactPagination.current_page);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.activateLoading = false;
              this.activate = false;
              this.messageIndex = null;
              console.log(error.response);
            });
        }
      },
      markAsUnread(item) {
        confirm("Are You Sure You Want to Mark as Unread?") &&
          (this.activate = true);
        if (this.activate) {
          this.deactivateLoading = true;
          this.messageIndex = item.id;
          this.newAsk.id = item.id;
          apiCall({
            url: "/api/contact-us?type=unread",
            data: this.newAsk,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Ask Account Deactivated Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.deactivateLoading = false;
              this.activate = false;
              this.messageIndex = null;
              this.fetchOpenContacts(this.openContactPagination.current_page);
              this.fetchClosedContacts(this.closedContactPagination.current_page);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.deactivateLoading = false;
              this.activate = false;
              this.messageIndex = null;
              console.log(error);
            });
        }
      },
      getFormData() {
        apiCall({
          url: "/api/contact-us?type=formData",
          method: "GET",
        })
          .then((resp) => {
            this.formData = resp;
          })
          .catch((error) => {
            this.message = "An Error Occurred; Couldn't Get Form Data";
            this.color = "error";
          });
      },
      viewLog(data) {
        this.logData.message = data.id;
        this.logData.model = "App\\Models\\ContactUs"
        this.filterLogData(this.logData);
        this.openLogDataModel();
      },
    },
    computed: {
      ...mapGetters([
        "openContacts", "openContactPagination", "openContactLoader",
        "closedContacts", "closedContactPagination", "closedContactLoader",
        "logDataModel"
      ]),
  
      openContactLength: function () {
        return Math.ceil(
          this.openContactPagination.total / this.openContactPagination.per_page
        );
      },
      closedContactLength: function () {
        return Math.ceil(
          this.closedContactPagination.total / this.closedContactPagination.per_page
        );
      },
      contactResponseLength: function () {
        return Math.ceil(
          this.contactResponsePagination.total / this.contactResponsePagination.per_page
        );
      },
    },
  };
  </script>
  