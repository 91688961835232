<template>
    <div>
        <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
            <span>{{ message }}</span>
        </v-snackbar>
        <v-dialog v-model="innovationApprovalState" persistent transition="dialog-bottom-transition" max-width="1150">
            <v-card :loading="loading" class="elevation-1" max-width="1150">
                <template slot="progress">
                    <v-progress-linear color="primary" height="2" indeterminate></v-progress-linear>
                </template>
                <v-toolbar flat color="transparent">
                    <div class="title">Innovation Approval</div>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="changeInnovationApprovalCardState">
                        <v-icon large>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-layout row wrap class="mx-1">
                        <v-flex xs12 md6>
                            <v-layout column>
                                <v-flex xs12 class="mt-6">
                                    <v-layout row wrap>
                                        <v-flex xs12 md4>
                                            <b>Innovation Name: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                            <div>{{ innovationData.name }}</div>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-6">
                                    <v-layout row wrap>
                                        <v-flex xs12 md4>
                                            <b>IP Protection Type: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                            <div>
                                                {{
                                                    innovationData.ip_protection_type
                                                        .name
                                                }}
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>

                                <v-flex xs12 class="mt-6">
                                    <v-layout row wrap>
                                        <v-flex xs12 md4>
                                            <b>Stage: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                            <div>
                                                {{ innovationData.stage.name }}
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-6">
                                    <v-layout row wrap>
                                        <v-flex xs12 md4>
                                            <b>County: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                            <div>
                                                {{ innovationData.county.name }}
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-layout column>
                                <v-flex xs12 class="mt-6">
                                    <v-layout row wrap>
                                        <v-flex xs12 md4>
                                            <b>Establishment Year: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                            <div>
                                                {{
                                                    innovationData.establishment_year
                                                }}
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-6">
                                    <v-layout row wrap>
                                        <v-flex xs12 md4>
                                            <b>Date Created: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                            <div>
                                                {{
                                                    innovationData.created_at
                                                    | moment("DD/MM/YYYY - hh:mm a")
                                                }}
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-6">
                                    <v-layout row wrap>
                                        <v-flex xs12 md4>
                                            <b>Status: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                            <div v-if="innovationData.status == 1">
                                                Active
                                            </div>
                                            <div v-else>Inactive</div>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 class="mt-12">
                            <v-layout row wrap>
                                <v-flex xs12 md2>
                                    <b>Tagline: </b>
                                </v-flex>
                                <v-flex xs12 md10>
                                    <div class="ml-1">
                                        {{ innovationData.brief }}
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 class="mt-6">
                            <v-layout row wrap>
                                <v-flex xs12 md2>
                                    <b>Description: </b>
                                </v-flex>
                                <v-flex xs12 md10>
                                    <p class="ml-1" v-html="innovationData.description"></p>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12>
                            <v-form ref="form" v-model="valid" lazy-validation>
                                <div class="mt-3">
                                    <v-layout row wrap>
                                        <v-flex xs12 class="mt-5">
                                            <div>
                                                <b>Message</b><span class="red--text">*</span>
                                            </div>
                                            <div class="black--text">
                                                <ckeditor :editor="editor" v-model="contactData.message"
                                                    :config="editorConfig" :rules="inputRules"></ckeditor>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </div>
                            </v-form>
                        </v-flex>
                    </v-layout>
                </v-card-text>
                <v-card-actions class="mt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="$can('innovation_activate')"
                        depressed
                        class="green white--text text-none"
                        @click="activateInnovation(innovationData)"
                        :loading="
                        activateLoading &&
                        innovationIndex == innovationData.id
                        "
                        v-bind="attrs"
                        v-on="on"
                    >
                    Approve
                        <v-icon right>
                        mdi-check-bold
                        </v-icon>
                    </v-btn>
                    <v-btn 
                        v-if="$can('innovation_deactivate')"
                        depressed class="red white--text text-none" @click="deactivateInnovation(innovationData)" :loading="
                        deactivateLoading &&
                        innovationIndex == innovationData.id
                    " v-bind="attrs" v-on="on">
                        Disallow 
                        <v-icon right> mdi-cancel </v-icon>
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>
<style>
.ck-editor__editable {
    min-height: 200px;
}
</style>
<script>
import Vue from "vue";
import apiCall from "@/utils/api";
import { mapGetters, mapActions } from "vuex";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadAdapter from '@/uploadAdapter.js';

Vue.use(CKEditor);

export default {
    name: "InnovationPopUp",

    data: () => {
        return {
            alertType: "",
            snackbar: false,
            message: "",
            color: "",
            loading: false,
            valid: true,

            innovationIndex: null,

            activateLoading: false,
            deactivateLoading: false,

            editor: ClassicEditor,
            editorConfig: {
                extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                    return new uploadAdapter(loader);
                } }],
                language: 'en',
            },

            inputRules: [(v) => !!v || "Input is Required"],

            contactData: {
                id: null,
                subject: null,
                message: null,
            },
        };
    },
    watch: {
        innovationApprovalState() {

        },
    },
    methods: {
        ...mapActions(["changeInnovationApprovalCardState"]),
        reset() {
            this.$refs.form.reset();
        },
        activateInnovation(innovationData) {
            if(this.contactData.message == null){
                this.message = "Please add a message";
                this.color = "error";
                this.snackbar = true;
            }else{
                confirm("Are You Sure You Want to Approve Innovation?") &&
                    (this.activate = true);
                if (this.activate) {
                    this.activateLoading = true;
                    this.innovationIndex = innovationData.id;
                    this.contactData.id = innovationData.id;
                    apiCall({
                    url: "/api/innovation?type=activate",
                    data: this.contactData,
                    method: "POST",
                    })
                    .then((resp) => {
                        this.message = "Innovation Activated Succesfully";
                        this.color = "success";
                        this.snackbar = true;
                        this.activateLoading = false;
                        this.activate = false;
                        this.innovationIndex = null;
                        this.changeInnovationApprovalCardState();
                    })
                    .catch((error) => {
                        this.message = "An Error Occurred";
                        this.color = "error";
                        this.snackbar = true;
                        this.activateLoading = false;
                        this.activate = false;
                        this.InstitutionIndex = null;
                        console.log(error);
                    });
                }
            }
        },
        deactivateInnovation(innovationData) {
            if(this.contactData.message == null){
                this.message = "Please add a message";
                this.color = "error";
                this.snackbar = true;
            }else{
                confirm("Are You Sure You Want to Reject Innovation?") &&
                (this.activate = true);
            if (this.activate) {
                this.deactivateLoading = true;
                this.innovationIndex = innovationData.id;
                this.contactData.id = innovationData.id;
                apiCall({
                    url: "/api/innovation?type=deactivate",
                    data: this.contactData,
                    method: "POST",
                })
                    .then((resp) => {
                        this.message = "Innovation Rejected; Message sent.";
                        this.color = "success";
                        this.snackbar = true;
                        this.deactivateLoading = false;
                        this.activate = false;
                        this.innovationIndex = null;
                        this.changeInnovationApprovalCardState();
                    })
                    .catch((error) => {
                        this.message = "An Error Occurred";
                        this.color = "error";
                        this.snackbar = true;
                        this.deactivateLoading = false;
                        this.activate = false;
                        this.innovationIndex = null;
                        console.log(error);
                    });
            }
            }
            
        },

    },
    computed: {
        ...mapGetters(["innovationApprovalState", "innovationData"]),
    },
};
</script>
  