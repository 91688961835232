<template>
  <div class="welcome">
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-progress-linear
      v-if="searchInnovationLoader"
      height="1"
      indeterminate
      color="primary"
    >
    </v-progress-linear>
    <v-progress-linear
      v-if="institutionStatisticLoader"
      height="1"
      indeterminate
      color="primary"
    >
    </v-progress-linear>
    <v-container>
      <v-layout row wrap>
        <v-flex xs12 md3>
          <v-card outlined class="mt-5">
            <v-tabs vertical v-model="tab">
              <v-tab key="Innovations" @click="switchTab('Innovations')">
                <v-icon left> mdi-spa-outline </v-icon>
                <div class="text-none">
                  Innovations
                  <b v-if="searchInnovationLoader == false"
                    >({{ searchInnovationPagination.total }})</b
                  >
                  <v-progress-circular
                    v-if="searchInnovationLoader"
                    :width="1"
                    :size="10"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-tab>
              <v-tab key="Institutions" @click="switchTab('Institutions')">
                <v-icon left> mdi-briefcase-outline </v-icon>
                <div class="text-none">
                  Institutions
                  <b v-if="institutionStatisticLoader == false">
                    ({{ searchInstitutionStatisticPagination.total }})</b
                  >
                  <v-progress-circular
                    v-if="institutionStatisticLoader"
                    :width="1"
                    :size="10"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-tab>
              <v-tab key="Organisations" @click="switchTab('Organisations')">
                <v-icon left> mdi-office-building-outline </v-icon>
                <div class="text-none">
                  Organisations
                  <b v-if="organisationStatisticLoader == false">
                    ({{ searchOrganisationStatisticPagination.total }})</b
                  >
                  <v-progress-circular
                    v-if="organisationStatisticLoader"
                    :width="1"
                    :size="10"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-tab>
              <v-tab key="Investment Firms" @click="switchTab('Investment Firms')">
                <v-icon left> mdi-office-building-outline </v-icon>
                <div class="text-none">
                  Investment Firms
                  <b v-if="investmentFirmLoader == false">
                    ({{ searchInvestmentFirmPagination.total }})</b
                  >
                  <v-progress-circular
                    v-if="investmentFirmLoader"
                    :width="1"
                    :size="10"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-tab>
              <v-tab key="Users" @click="switchTab('Users')">
                <v-icon left> mdi-account-multiple </v-icon>
                <div class="text-none">
                  Users
                  <b v-if="userLoader == false">
                    ({{ searchUserPagination.total }})</b
                  >
                  <v-progress-circular
                    v-if="userLoader"
                    :width="1"
                    :size="10"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-tab>
            </v-tabs>
          </v-card>

          <!-- <div class="mt-7"><b>Filter By</b></div>
          <v-card class="mt-2 pa-2" outlined>
            <v-layout column>
              <v-flex xs12>
                <div class="mt-2"><b>County</b></div>
                  <v-select
                    :items="allCounties"
                    item-text="name"
                    item-value="id"
                    v-model="filter.county_id"
                    class="white org_text_field"
                    dense
                    outlined
                    height="10"
                    @input="filterByInstitutionType()"
                  >
                  </v-select>
              </v-flex>
            </v-layout>
          </v-card> -->
        </v-flex>
        <v-flex xs12 md9>
          <v-card
            outlined
            v-if="view == 'Innovations'"
            class="mt-5 mx-5"
            style="border-radius: 25px"
          >
            <div
              class="pa-3"
              style="
                margin: 1px, 1px;
                padding: 1px;
                width: auto;
                height: 80vh;
                overflow-x: hidden;
                overflow-y: scroll;
                text-align: justify;
              "
            >
              <v-layout column>
                <div v-if="searchInnovations.length != 0">
                  <template v-for="(innovation, index) in searchInnovations">
                    <div :key="index">
                      <v-flex xs12 class="mb-3">
                        <a style="text-decoration: none">
                          <v-card
                            outlined
                            class="mb-3"
                            @click="goToInnovation(innovation)"
                            style="border-radius: 25px"
                          >
                            <v-card-text>
                              <div class="pa-2">
                                <v-layout row wrap>
                                  <v-flex xs12 md3>
                                    <div align="center" class="my-5">
                                      <v-avatar size="126">
                                        <v-img
                                          v-if="
                                            innovation.logo == null
                                          "
                                          src="img/innovation.jpg"
                                        >
                                        </v-img>
                                        <v-img
                                          v-else
                                          :src="
                                            path +
                                            '/storage/innovation_pictures/' +
                                            innovation.logo
                                          "
                                        >
                                        </v-img>
                                      </v-avatar>
                                    </div>
                                  </v-flex>
                                  <v-flex xs12 md9>
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div class="mt-5 title mr-5">
                                          {{ innovation.name }}
                                        </div>
                                        <v-row align="center" class="mx-0 mt-1">
                                          <v-rating
                                            :value="innovation.average_rating"
                                            color="amber"
                                            dense
                                            half-increments
                                            readonly
                                            size="14"
                                          ></v-rating>

                                          <div class="grey--text ms-4">
                                            {{ innovation.average_rating }}
                                            ({{ innovation.total_rating }})
                                          </div>
                                        </v-row>

                                        <div class="my-4 text-subtitle-1 mr-1">
                                          <template
                                            v-for="(
                                              innovation_sector, index
                                            ) in innovation.innovation_sectors.slice(
                                              0,
                                              10
                                            )"
                                          >
                                            <v-chip
                                              x-small
                                              :color="
                                                innovation_sector.sector.color
                                              "
                                              class="white--text mr-1"
                                              :key="index"
                                            >
                                              #{{
                                                innovation_sector.sector.name
                                              }}
                                            </v-chip>
                                          </template>
                                          <v-chip
                                            class="white--text"
                                            color="primary"
                                            small
                                            v-if="
                                              innovation.innovation_sectors
                                                .length > 5
                                            "
                                          >
                                            + More
                                          </v-chip>
                                        </div>
                                        <div class="mb-5 mr-5">
                                          <v-clamp
                                            autoresize
                                            :max-lines="3"
                                            class="mb-5 grey--text caption"
                                            >{{ innovation.brief }}
                                          </v-clamp>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </v-card-text>
                          </v-card>
                        </a>
                      </v-flex>
                    </div>
                  </template>
                </div>
                <div v-else>
                  <v-flex xs12 class="mb-10">
                    <a style="text-decoration: none">
                      <v-card
                        elevation="0"
                        router
                        to="/innovation"
                        style="border-radius: 25px"
                      >
                        <v-layout row wrap>
                          <v-flex xs12 md4>
                            <div align="center" class="my-10">
                              <v-avatar color="primary" size="96">
                                <v-icon class="white--text">
                                  mdi-file-document-alert
                                </v-icon>
                              </v-avatar>
                            </div>
                          </v-flex>
                          <v-flex xs12 md8>
                            <v-layout column>
                              <v-flex xs12 class="mt-1">
                                <div class="mt-15 ml-5 title">
                                  No Innovation Found..
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </a>
                  </v-flex>
                </div>
              </v-layout>
            </div>
          </v-card>
          <div v-if="view == 'Innovations'" class="mx-5 my-5">
            <div align="center">
              <v-pagination
                v-if="searchInnovationLength != 0"
                :length="searchInnovationLength"
                total-visible="10"
                v-model="searchInnovationPagination.current_page"
                @input="changeInnovationPage()"
                circle
              >
              </v-pagination>
            </div>
          </div>
          <v-card
            outlined
            v-if="view == 'Institutions'"
            class="mt-5 mx-5"
            style="border-radius: 25px"
          >
            <div
              id="institutionList"
              class="pa-3"
              style="
                margin: 1px, 1px;
                padding: 1px;
                width: auto;
                height: 80vh;
                overflow-x: hidden;
                overflow-y: scroll;
                text-align: justify;
              "
            >
              <v-layout column>
                <div v-if="searchInstitutionStatistics.length != 0">
                  <template
                    v-for="(
                      institutionStatistic, index
                    ) in searchInstitutionStatistics"
                  >
                    <div :key="index">
                      <v-flex xs12 class="mb-4">
                        <v-card
                          outlined
                          class="pa-5"
                          style="border-radius: 25px"
                        >
                          <v-layout row wrap>
                            <v-flex xs4>
                              <div align="center" class="mt-5">
                                <v-avatar size="156">
                                  <v-img
                                    v-if="
                                      institutionStatistic.institution.logo ==
                                      null
                                    "
                                    src="img/institution.png"
                                    style="border-radius: 25px"
                                  >
                                  </v-img>
                                  <v-img
                                    v-else
                                    :src="
                                      path +
                                      '/storage/institution_pictures/' +
                                      institutionStatistic.institution.logo
                                    "
                                    style="border-radius: 25px"
                                  >
                                  </v-img>
                                </v-avatar>
                              </div>
                            </v-flex>
                            <v-flex xs8>
                              <v-layout column>
                                <v-flex xs12>
                                  <div class="mx-5">
                                    <div class="title mt-5">
                                      {{
                                        institutionStatistic.institution.name
                                      }}
                                    </div>
                                    <!-- <v-divider class="white"></v-divider> -->
                                  </div>
                                </v-flex>
                                <v-flex xs12>
                                  <div class="pa-8">
                                    <v-layout column>
                                      <v-flex xs12>
                                        <v-layout row wrap>
                                          <v-flex xs12 md6>
                                            <div><b>Total Innovations</b></div>
                                          </v-flex>
                                          <v-flex xs12 md6>
                                            <div v-if="time == 'All Time'">
                                              {{
                                                institutionStatistic.total_innovations
                                              }}
                                            </div>
                                            <div v-if="time == '24 Hrs'">
                                              {{
                                                institutionStatistic.day_innovations
                                              }}
                                            </div>
                                            <div v-if="time == 'This Week'">
                                              {{
                                                institutionStatistic.week_innovations
                                              }}
                                            </div>
                                            <div v-if="time == 'This Month'">
                                              {{
                                                institutionStatistic.month_innovations
                                              }}
                                            </div>
                                            <div v-if="time == 'This Year'">
                                              {{
                                                institutionStatistic.year_innovations
                                              }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-7">
                                        <v-layout row wrap>
                                          <v-flex xs12 md6>
                                            <div><b>Affiliated Users</b></div>
                                          </v-flex>
                                          <v-flex xs12 md6>
                                            <div v-if="time == 'All Time'">
                                              {{
                                                institutionStatistic.total_users
                                              }}
                                            </div>
                                            <div v-if="time == '24 Hrs'">
                                              {{
                                                institutionStatistic.day_users
                                              }}
                                            </div>
                                            <div v-if="time == 'This Week'">
                                              {{
                                                institutionStatistic.week_users
                                              }}
                                            </div>
                                            <div v-if="time == 'This Month'">
                                              {{
                                                institutionStatistic.month_users
                                              }}
                                            </div>
                                            <div v-if="time == 'This Year'">
                                              {{
                                                institutionStatistic.year_users
                                              }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <!-- <v-flex xs12 class="mt-7">
                                        <v-layout row wrap>
                                          <v-flex xs12 md6>
                                            <div><b>Connections Made</b></div>
                                          </v-flex>
                                          <v-flex xs12 md6>
                                            <div v-if="time == 'All Time'">
                                              {{
                                                institutionStatistic.total_connections
                                              }}
                                            </div>
                                            <div v-if="time == '24 Hrs'">
                                              {{
                                                institutionStatistic.day_connections
                                              }}
                                            </div>
                                            <div v-if="time == 'This Week'">
                                              {{
                                                institutionStatistic.week_connections
                                              }}
                                            </div>
                                            <div v-if="time == 'This Month'">
                                              {{
                                                institutionStatistic.month_connections
                                              }}
                                            </div>
                                            <div v-if="time == 'This Year'">
                                              {{
                                                institutionStatistic.year_connections
                                              }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex> -->
                                    </v-layout>
                                  </div>
                                </v-flex>
                                <v-flex xs12>
                                  <v-flex xs12>
                                    <div align="center" class="mb-5">
                                      <v-btn
                                        v-for="icon in icons"
                                        :key="icon.icon"
                                        class="mx-1 primary--text"
                                        icon
                                      >
                                        <v-icon size="25px" :color="icon.color">
                                          {{ icon.icon }}
                                        </v-icon>
                                      </v-btn>
                                    </div>
                                  </v-flex>
                                </v-flex>
                                <v-flex
                                  xs12
                                  v-if="
                                    institutionStatistic
                                      .institution_sector_stats.length != 0
                                  "
                                >
                                  <div class="mx-5">
                                    <h3 class="mt-5">Top 10 Sectors</h3>
                                  </div>
                                </v-flex>
                                <v-flex xs12>
                                  <div class="pa-3">
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div
                                          v-if="
                                            institutionStatistic
                                              .institution_sector_stats
                                              .length != 0
                                          "
                                        >
                                          <template
                                            v-for="(
                                              institution_sector, index
                                            ) in institutionStatistic.institution_sector_stats.slice(
                                              0,
                                              10
                                            )"
                                          >
                                            <v-chip
                                              x-small
                                              :color="
                                                institution_sector.sector.color
                                              "
                                              class="white--text mr-1"
                                              :key="index"
                                            >
                                              <div>
                                                #{{
                                                  institution_sector.sector.name
                                                }}
                                                <b v-if="time == 'All Time'"
                                                  >({{
                                                    institution_sector.total
                                                  }})</b
                                                >
                                                <b v-if="time == '24 Hrs'"
                                                  >({{
                                                    institution_sector.day
                                                  }})</b
                                                >
                                                <b v-if="time == 'This Week'"
                                                  >({{
                                                    institution_sector.week
                                                  }})</b
                                                >
                                                <b v-if="time == 'This Month'"
                                                  >({{
                                                    institution_sector.month
                                                  }})</b
                                                >
                                                <b v-if="time == 'This Year'"
                                                  >({{
                                                    institution_sector.year
                                                  }})</b
                                                >
                                              </div>
                                            </v-chip>
                                          </template>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </div>
                                </v-flex>
                                <v-flex xs12>
                                  <div align="center" class="mt-5 mb-5">
                                    <v-btn
                                      outlined
                                      class="text-none"
                                      @click="
                                        goToInstitution(
                                          institutionStatistic.institution
                                        )
                                      "
                                      >Visit Page</v-btn
                                    >
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </div>
                  </template>
                </div>
                <div v-else>
                  <v-flex xs12 class="mb-10">
                      <v-card elevation="0">
                        <v-layout row wrap>
                          <v-flex xs12 md4>
                            <div align="center" class="my-10">
                              <v-avatar color="primary" size="96">
                                <v-icon class="white--text">
                                  mdi-alert-circle
                                </v-icon>
                              </v-avatar>
                            </div>
                          </v-flex>
                          <v-flex xs12 md8>
                            <v-layout column>
                              <v-flex xs12 class="mt-1">
                                <div class="mt-15 ml-5 title">
                                  No Institution Found..
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
                  </v-flex>
                </div>
              </v-layout>
            </div>
          </v-card>
          <div v-if="view == 'Institutions'" class="mx-5 mt-5">
            <div align="center">
              <v-pagination
                v-if="searchInstitutionStatisticLength != 0"
                :length="searchInstitutionStatisticLength"
                total-visible="10"
                v-model="searchInstitutionStatisticPagination.current_page"
                @input="changeInstitutionStatisticPage()"
                circle
              >
              </v-pagination>
            </div>
          </div>
          <v-card
            outlined
            v-if="view == 'Organisations'"
            class="mt-5 mx-5"
            style="border-radius: 25px"
          >
            <div
              id="organisationList"
              class="pa-3"
              style="
                margin: 1px, 1px;
                padding: 1px;
                width: auto;
                height: 80vh;
                overflow-x: hidden;
                overflow-y: scroll;
                text-align: justify;
              "
            >
              <v-layout column>
                <div v-if="searchOrganisationStatistics.length != 0">
                  <template
                    v-for="(
                      organisationStatistic, index
                    ) in searchOrganisationStatistics"
                  >
                    <div :key="index">
                      <v-flex xs12 class="mb-4">
                        <v-card
                          outlined
                          class="pa-5"
                          style="border-radius: 25px"
                        >
                          <v-layout row wrap>
                            <v-flex xs4>
                              <div align="center" class="mt-5">
                                <v-avatar size="156">
                                  <v-img
                                    v-if="
                                      organisationStatistic.organisation.logo ==
                                      null
                                    "
                                    src="img/organisation.png"
                                    style="border-radius: 25px"
                                  >
                                  </v-img>
                                  <v-img
                                    v-else
                                    :src="
                                      path +
                                      '/storage/organisation_pictures/' +
                                      organisationStatistic.organisation.logo
                                    "
                                    style="border-radius: 25px"
                                  >
                                  </v-img>
                                </v-avatar>
                              </div>
                            </v-flex>
                            <v-flex xs8>
                              <v-layout column>
                                <v-flex xs12>
                                  <div class="mx-5">
                                    <div class="title mt-5">
                                      {{
                                        organisationStatistic.organisation.name
                                      }}
                                    </div>
                                    <!-- <v-divider class="white"></v-divider> -->
                                  </div>
                                </v-flex>
                                <v-flex xs12>
                                  <div class="pa-8">
                                    <v-layout column>
                                      <v-flex xs12>
                                        <v-layout row wrap>
                                          <v-flex xs12 md6>
                                            <div><b>Total Innovations</b></div>
                                          </v-flex>
                                          <v-flex xs12 md6>
                                            <div v-if="time == 'All Time'">
                                              {{
                                                organisationStatistic.total_innovations
                                              }}
                                            </div>
                                            <div v-if="time == '24 Hrs'">
                                              {{
                                                organisationStatistic.day_innovations
                                              }}
                                            </div>
                                            <div v-if="time == 'This Week'">
                                              {{
                                                organisationStatistic.week_innovations
                                              }}
                                            </div>
                                            <div v-if="time == 'This Month'">
                                              {{
                                                organisationStatistic.month_innovations
                                              }}
                                            </div>
                                            <div v-if="time == 'This Year'">
                                              {{
                                                organisationStatistic.year_innovations
                                              }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-7">
                                        <v-layout row wrap>
                                          <v-flex xs12 md6>
                                            <div><b>Affiliated Users</b></div>
                                          </v-flex>
                                          <v-flex xs12 md6>
                                            <div v-if="time == 'All Time'">
                                              {{
                                                organisationStatistic.total_users
                                              }}
                                            </div>
                                            <div v-if="time == '24 Hrs'">
                                              {{
                                                organisationStatistic.day_users
                                              }}
                                            </div>
                                            <div v-if="time == 'This Week'">
                                              {{
                                                organisationStatistic.week_users
                                              }}
                                            </div>
                                            <div v-if="time == 'This Month'">
                                              {{
                                                organisationStatistic.month_users
                                              }}
                                            </div>
                                            <div v-if="time == 'This Year'">
                                              {{
                                                organisationStatistic.year_users
                                              }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <!-- <v-flex xs12 class="mt-7">
                                        <v-layout row wrap>
                                          <v-flex xs12 md6>
                                            <div><b>Connections Made</b></div>
                                          </v-flex>
                                          <v-flex xs12 md6>
                                            <div v-if="time == 'All Time'">
                                              {{
                                                organisationStatistic.total_connections
                                              }}
                                            </div>
                                            <div v-if="time == '24 Hrs'">
                                              {{
                                                organisationStatistic.day_connections
                                              }}
                                            </div>
                                            <div v-if="time == 'This Week'">
                                              {{
                                                organisationStatistic.week_connections
                                              }}
                                            </div>
                                            <div v-if="time == 'This Month'">
                                              {{
                                                organisationStatistic.month_connections
                                              }}
                                            </div>
                                            <div v-if="time == 'This Year'">
                                              {{
                                                organisationStatistic.year_connections
                                              }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex> -->
                                    </v-layout>
                                  </div>
                                </v-flex>
                                <v-flex xs12>
                                  <v-flex xs12>
                                    <div align="center" class="mb-5">
                                      <v-btn
                                        v-for="icon in icons"
                                        :key="icon.icon"
                                        class="mx-1 primary--text"
                                        icon
                                      >
                                        <v-icon size="25px" :color="icon.color">
                                          {{ icon.icon }}
                                        </v-icon>
                                      </v-btn>
                                    </div>
                                  </v-flex>
                                </v-flex>
                                <v-flex
                                  xs12
                                  v-if="
                                    organisationStatistic
                                      .organisation_sector_stats.length != 0
                                  "
                                >
                                  <div class="mx-5">
                                    <h3 class="mt-5">Top 10 Sectors</h3>
                                  </div>
                                </v-flex>
                                <v-flex xs12>
                                  <div class="pa-3">
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div
                                          v-if="
                                            organisationStatistic
                                              .organisation_sector_stats
                                              .length != 0
                                          "
                                        >
                                          <template
                                            v-for="(
                                              organisation_sector, index
                                            ) in organisationStatistic.organisation_sector_stats.slice(
                                              0,
                                              10
                                            )"
                                          >
                                            <v-chip
                                              x-small
                                              :color="
                                                organisation_sector.sector.color
                                              "
                                              class="white--text mr-1"
                                              :key="index"
                                            >
                                              <div>
                                                #{{
                                                  organisation_sector.sector.name
                                                }}
                                                <b v-if="time == 'All Time'"
                                                  >({{
                                                    organisation_sector.total
                                                  }})</b
                                                >
                                                <b v-if="time == '24 Hrs'"
                                                  >({{
                                                    organisation_sector.day
                                                  }})</b
                                                >
                                                <b v-if="time == 'This Week'"
                                                  >({{
                                                    organisation_sector.week
                                                  }})</b
                                                >
                                                <b v-if="time == 'This Month'"
                                                  >({{
                                                    organisation_sector.month
                                                  }})</b
                                                >
                                                <b v-if="time == 'This Year'"
                                                  >({{
                                                    organisation_sector.year
                                                  }})</b
                                                >
                                              </div>
                                            </v-chip>
                                          </template>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </div>
                                </v-flex>
                                <v-flex xs12>
                                  <div align="center" class="mt-5 mb-5">
                                    <v-btn
                                      outlined
                                      class="text-none"
                                      @click="
                                        goToOrganisation(
                                          organisationStatistic.organisation
                                        )
                                      "
                                      >Visit Page</v-btn
                                    >
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </div>
                  </template>
                </div>
                <div v-else>
                  <v-flex xs12 class="mb-10">
                    <v-card elevation="0">
                      <v-layout row wrap>
                        <v-flex xs12 md4>
                          <div align="center" class="my-10">
                            <v-avatar color="primary" size="96">
                              <v-icon class="white--text">
                                mdi-alert-circle
                              </v-icon>
                            </v-avatar>
                          </div>
                        </v-flex>
                        <v-flex xs12 md8>
                          <v-layout column>
                            <v-flex xs12 class="mt-1">
                              <div class="mt-15 ml-5 title">
                                No Organisation Found..
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </div>
              </v-layout>
            </div>
          </v-card>
          <div v-if="view == 'Organisations'" class="mx-5 mt-5">
            <div align="center">
              <v-pagination
                v-if="searchOrganisationStatisticLength != 0"
                :length="searchOrganisationStatisticLength"
                total-visible="10"
                v-model="searchOrganisationStatisticPagination.current_page"
                @input="changeOrganisationStatisticPage()"
                circle
              >
              </v-pagination>
            </div>
          </div>
          <v-card
            outlined
            v-if="view == 'Investment Firms'"
            class="mt-5 mx-5"
            style="border-radius: 25px"
          >
            <div
              id="investmentFirmList"
              class="pa-3"
              style="
                margin: 1px, 1px;
                padding: 1px;
                width: auto;
                height: 80vh;
                overflow-x: hidden;
                overflow-y: scroll;
                text-align: justify;
              "
            >
              <v-layout column>
                <div v-if="searchInvestmentFirms.length != 0">
                  <template
                    v-for="(
                      investmentFirm, index
                    ) in searchInvestmentFirms"
                  >
                    <div :key="index">
                      <v-flex xs12 class="mb-4">
                        <v-card
                          outlined
                          class="pa-5"
                          style="border-radius: 25px"
                        >
                          <v-layout row wrap>
                            <v-flex xs4>
                              <div align="center" class="mt-5">
                                <v-avatar size="156">
                                  <v-img
                                    v-if="
                                      investmentFirm.logo ==
                                      null
                                    "
                                    src="img/investmentFirm.png"
                                    style="border-radius: 25px"
                                  >
                                  </v-img>
                                  <v-img
                                    v-else
                                    :src="
                                      path +
                                      '/storage/investment_firm_pictures/' +
                                      investmentFirm.logo
                                    "
                                    style="border-radius: 25px"
                                  >
                                  </v-img>
                                </v-avatar>
                              </div>
                            </v-flex>
                            <v-flex xs8>
                              <v-layout column>
                                <v-flex xs12>
                                  <div class="mx-5">
                                    <div class="title mt-5">
                                      {{
                                        investmentFirm.name
                                      }}
                                    </div>
                                    <div class="caption">
                                        <i>{{ investmentFirm.investor_type.name }}</i> •
                                        <i v-if="investmentFirm.county_id != null">{{
                                          investmentFirm.county.name
                                        }}</i>
                                      </div>
                                    <!-- <v-divider class="white"></v-divider> -->
                                  </div>
                                </v-flex>
                                
                            
                                <v-flex xs12>
                                  <div align="center" class="mt-5 mb-5">
                                    <v-btn
                                      outlined
                                      class="text-none"
                                      @click="
                                        goToFirm(
                                          investmentFirm
                                        )
                                      "
                                      >Visit Page</v-btn
                                    >
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </div>
                  </template>
                </div>
                <div v-else>
                  <v-flex xs12 class="mb-10">
                      <v-card elevation="0">
                        <v-layout row wrap>
                          <v-flex xs12 md4>
                            <div align="center" class="my-10">
                              <v-avatar color="primary" size="96">
                                <v-icon class="white--text">
                                  mdi-alert-circle
                                </v-icon>
                              </v-avatar>
                            </div>
                          </v-flex>
                          <v-flex xs12 md8>
                            <v-layout column>
                              <v-flex xs12 class="mt-1">
                                <div class="mt-15 ml-5 title">
                                  No Investment Firm Found..
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
                  </v-flex>
                </div>
              </v-layout>
            </div>
          </v-card>
          <div v-if="view == 'Investment Firms'" class="mx-5 mt-5">
            <div align="center">
              <v-pagination
                v-if="searchInvestmentFirmLength != 0"
                :length="searchInvestmentFirmLength"
                total-visible="10"
                v-model="searchInvestmentFirmPagination.current_page"
                @input="changeInvestmentFirmPage()"
                circle
              >
              </v-pagination>
            </div>
          </div>


          <v-card
            outlined
            v-if="view == 'Users'"
            class="mt-5 mx-5"
            style="border-radius: 25px"
          >
            <div
              id="userList"
              class="pa-3"
              style="
                margin: 1px, 1px;
                padding: 1px;
                width: auto;
                height: 80vh;
                overflow-x: hidden;
                overflow-y: scroll;
                text-align: justify;
              "
            >
              <v-layout column>
                <div v-if="searchUsers.length != 0">
                  <template
                    v-for="(
                      user, index
                    ) in searchUsers"
                  >
                    <div :key="index">
                      <v-flex xs12 class="mb-4">
                        <v-card
                          outlined
                          class="pa-5"
                          style="border-radius: 25px"
                        >
                          <v-layout row wrap>
                            <v-flex xs4>
                              <div align="center" class="mt-5">
                                <v-avatar size="156">
                                  <v-img
                                    v-if="
                                      user.display_img ==
                                      null
                                    "
                                    src="img/avatar.jpg"
                                    style="border-radius: 25px"
                                  >
                                  </v-img>
                                  <v-img
                                    v-else
                                    :src="
                                      path +
                                      '/storage/profile_pics/' +
                                      user.display_img
                                    "
                                    style="border-radius: 25px"
                                  >
                                  </v-img>
                                </v-avatar>
                              </div>
                            </v-flex>
                            <v-flex xs8>
                              <v-layout column>
                                <v-flex xs12>
                                  <div class="mx-5">
                                    <div class="title mt-5">
                                      {{ user.f_name }} {{ user.m_name }} {{ user.l_name }} #{{ user.code }}
                                    </div>
                                    <div class="caption">
                                        <i v-html="user.bio"></i>
                                      </div>
                                    <!-- <v-divider class="white"></v-divider> -->
                                  </div>
                                </v-flex>
                                
                            
                                <v-flex xs12>
                                  <div align="center" class="mt-5 mb-5">
                                    <v-btn
                                      v-if="user.connected == false"
                                      color="primary"
                                      small
                                      class="text-none my-3 white--text"
                                      depressed
                                      @click="addFriend(user.code, index)"
                                      :loading="connectLoading && connectIndex == index"
                                    >
                                      Connect
                                      <v-icon right small class="white--text"> mdi-handshake </v-icon>
                                    </v-btn>
                                    <v-btn
                                      v-if="user.connected == true"
                                      color="green"
                                      small
                                      class="text-none my-3 white--text"
                                      depressed
                                    >
                                      Connected
                                      <v-icon right small class="white--text"> mdi-check-decagram </v-icon>
                                    </v-btn>
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </div>
                  </template>
                </div>
                <div v-else>
                  <v-flex xs12 class="mb-10">
                      <v-card elevation="0">
                        <v-layout row wrap>
                          <v-flex xs12 md4>
                            <div align="center" class="my-10">
                              <v-avatar color="primary" size="96">
                                <v-icon class="white--text">
                                  mdi-alert-circle
                                </v-icon>
                              </v-avatar>
                            </div>
                          </v-flex>
                          <v-flex xs12 md8>
                            <v-layout column>
                              <v-flex xs12 class="mt-1">
                                <div class="mt-15 ml-5 title">
                                  No User Found..
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
                  </v-flex>
                </div>
              </v-layout>
            </div>
          </v-card>
          <div v-if="view == 'Users'" class="mx-5 mt-5">
            <div align="center">
              <v-pagination
                v-if="searchUserLength != 0"
                :length="searchUserLength"
                total-visible="10"
                v-model="searchUserPagination.current_page"
                @input="changeUserPage()"
                circle
              >
              </v-pagination>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import VClamp from "vue-clamp";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    VClamp,
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      loading: false,
      selection: 1,
      drawer: false,
      dialog: false,
      connectLoading: false,
      connectIndex: null,
      tab: 'Users',

      view: "Innovations",
      filterView: "Sectors",
      time: "All Time",

      tabs: [
        { name: "Innovations", total: 0 },
        { name: "Users", total: 10 },
        { name: "Reviews", total: 519 },
        { name: "Events", total: 5 },
      ],
      filterTabs: ["Sectors", "Interests", "SDGs"],

      institution: null,

      total_innovations: 0,
      total_users: 0,
      total_connections: 0,

      time_periods: [
        "24 Hrs",
        "This Week",
        "This Month",
        "This Year",
        "All Time",
      ],
      sectors: [],
      interests: [],
      sdgs: [],

      filter: {
        county_id: null,
        institution_id: null,
        search: null,
        time_period: null,
        sectors: [],
        interests: [],
        sdgs: [],
      },
      userFriendData: {
        user_code: null,
      },
    };
  },
  watch: {
    searchInnovations() {
      // if (this.searchInnovations.length == 0) {
      //   this.view = "Institutions";
      // } else if (this.searchInstitutionStatistics.length == 0) {
      //   this.view = "Organisations";
      // } else if (this.searchOrganisationStatistics.length == 0) {
      //   this.view = "Investment Firms";
      // } else if (this.searchInvestmentFirms.length == 0) {
      //   this.view = "Users";
      // } else if (this.searchUsers.length == 0) {
      //   this.view = "Innovations";
      // }
    },
  },
  mounted() {},
  created() {
    window.scrollTo(0, 0);
    this.fetchAllCounties();
  },
  methods: {
    ...mapActions([
      "fetchAllCounties",

      "startSearchInnovationLoader",
      "stopSearchInnovationLoader",
      "filterSearchInnovations",

      "startInstitutionStatisticLoader",
      "stopInstitutionStatisticLoader",
      "filterSearchInstitutionStatistics",

      "startOrganisationStatisticLoader",
      "stopOrganisationStatisticLoader",
      "filterSearchOrganisationStatistics",

      "startInvestmentFirmLoader",
      "stopInvestmentFirmLoader",
      "filterSearchInvestmentFirms",

      "startUserLoader",
      "stopUserLoader",
      "filterSearchUsers",
    ]),
    changeInnovationPage() {
      this.startSearchInnovationLoader();
      apiCall({
        url:
          "/api/innovation?type=search&page=" +
          this.searchInnovationPagination.current_page +
          "&term=" +
          this.searchInnovationPagination.search,
        method: "GET",
      })
        .then((resp) => {
          this.filterSearchInnovations(resp);
          this.stopSearchInnovationLoader();
        })
        .catch((error) => {
          console.log(error.response);
          this.stopSearchInnovationLoader();
          this.message = "anErrorOccurredMsg";
          this.color = "error";
          this.snackbar = true;
        });
    },
    changeInstitutionStatisticPage() {
      this.startInstitutionStatisticLoader();
      apiCall({
        url:
          "/api/institution-statistic?type=search&page=" +
          this.searchInstitutionStatisticPagination.current_page +
          "&term=" +
          this.searchInnovationPagination.search,
        method: "GET",
      })
        .then((resp) => {
          this.filterSearchInstitutionStatistics(resp);
          this.stopInstitutionStatisticLoader();
        })
        .catch((error) => {
          console.log(error.response);
          this.stopInstitutionStatisticLoader();
          this.message = "anErrorOccurredMsg";
          this.color = "error";
          this.snackbar = true;
        });
    },
    changeOrganisationStatisticPage() {
      this.startOrganisationStatisticLoader();
      apiCall({
        url:
          "/api/organisation-statistic?type=search&page=" +
          this.searchOrganisationStatisticPagination.current_page +
          "&term=" +
          this.searchInnovationPagination.search,
        method: "GET",
      })
        .then((resp) => {
          this.filterSearchOrganisationStatistics(resp);
          this.stopOrganisationStatisticLoader();
        })
        .catch((error) => {
          console.log(error.response);
          this.stopOrganisationStatisticLoader();
          this.message = "anErrorOccurredMsg";
          this.color = "error";
          this.snackbar = true;
        });
    },
    changeInvestmentFirmPage(){
      this.startInvestmentFirmLoader();
      apiCall({
        url:
          "/api/investment-firm?type=filter&page=" +
          this.searchInvestmentFirmPagination.current_page,
        data: this.searchInnovationPagination,
        method: "POST",
      })
        .then((resp) => {
          this.filterSearchInvestmentFirms(resp);
          this.stopInvestmentFirmLoader();
        })
        .catch((error) => {
          console.log(error.response);
          this.stopInvestmentFirmLoader();
          this.message = "anErrorOccurredMsg";
          this.color = "error";
          this.snackbar = true;
        });
    },
    changeUserPage(){
      this.startUserLoader();
      apiCall({
        url:
          "/api/user?type=publicSearch&page=" +
          this.searchUserPagination.current_page + "&term=" +
          this.searchInnovationPagination.search,
        method: "GET",
      })
        .then((resp) => {
          this.filterSearchUsers(resp);
          this.stopUserLoader();
        })
        .catch((error) => {
          console.log(error.response);
          this.stopUserLoader();
          this.message = "anErrorOccurredMsg";
          this.color = "error";
          this.snackbar = true;
        });
    },
    switchTab(tab) {
      this.tab = tab
      this.view = tab;
    },
    goToInnovation(innovation) {
      this.$router.push("/innovations/" + innovation.slug);
    },
    goToInstitution(institution) {
      this.$router.push("/institutions/" + institution.slug);
    },
    goToOrganisation(organisation) {
      this.$router.push("/organisations/" + organisation.slug);
    },
    goToFirm(firm) {
      this.$router.push("/investors/" + firm.slug);
    },
    addFriend(user_code, index) {
      this.connectLoading = true;
      this.connectIndex = index;
      this.userFriendData.user_code = user_code;
      apiCall({
        url: "/api/add-friend",
        data: this.userFriendData,
        method: "POST",
      })
        .then((resp) => {
          this.connectLoading = false;
          this.dialog = false;
          this.color = "success";
          this.message = "Connection Request Sent Successfully";
          this.snackbar = true;
        })
        .catch((error) => {
          this.connectLoading = false;
          this.color = "success";
          this.message = "Connection Request Sent Successfully";
          this.snackbar = true;
        });
    },
  },
  computed: {
    ...mapGetters([
      "allCounties",

      "searchInnovations",
      "searchInnovationLoader",
      "searchInnovationPagination",

      "searchInstitutionStatistics",
      "institutionStatisticLoader",
      "searchInstitutionStatisticPagination",

      "searchOrganisationStatistics",
      "organisationStatisticLoader",
      "searchOrganisationStatisticPagination",

      "searchInvestmentFirms",
      "investmentFirmLoader",
      "searchInvestmentFirmPagination",

      "searchUsers",
      "userLoader",
      "searchUserPagination",
    ]),

    searchInnovationLength: function () {
      return Math.ceil(
        this.searchInnovationPagination.total /
          this.searchInnovationPagination.per_page
      );
    },
    searchInstitutionStatisticLength: function () {
      return Math.ceil(
        this.searchInstitutionStatisticPagination.total /
          this.searchInstitutionStatisticPagination.per_page
      );
    },
    searchOrganisationStatisticLength: function () {
      return Math.ceil(
        this.searchOrganisationStatisticPagination.total /
          this.searchOrganisationStatisticPagination.per_page
      );
    },
    searchInvestmentFirmLength: function () {
      return Math.ceil(
        this.searchInvestmentFirmPagination.total /
          this.searchInvestmentFirmPagination.per_page
      );
    },
    searchUserLength: function () {
      return Math.ceil(
        this.searchUserPagination.total /
          this.searchUserPagination.per_page
      );
    },
  },
};
</script>
