import apiCall from '@/utils/api'

const state = {
	organisationStatistics: [],
	searchOrganisationStatistics: [],
	allOrganisationStatistics: [],
    organisationStatisticPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	searchOrganisationStatisticPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    organisationStatisticLoader: false
};

const getters = {
	organisationStatistics: (state) => state.organisationStatistics,
	searchOrganisationStatistics: (state) => state.searchOrganisationStatistics,
	allOrganisationStatistics: (state) => state.allOrganisationStatistics,
    organisationStatisticLoader: (state) => state.organisationStatisticLoader,
    organisationStatisticPagination: (state) => state.organisationStatisticPagination,
	searchOrganisationStatisticPagination: (state) => state.searchOrganisationStatisticPagination,
}

const actions = {
	async fetchAllOrganisationStatistics({commit}, page) {
		const response = await apiCall({url: `/api/organisation-statistic?type=all`, method: 'GET' });
		commit('setAllOrganisationStatistics', response)
        commit('stopOrganisationStatisticLoader', response)
	},
    async fetchOrganisationStatistics({commit}, page) {
		const response = await apiCall({url: `/api/organisation-statistic?page=${page}`, method: 'GET' });
		commit('setOrganisationStatistics', response)
        commit('stopOrganisationStatisticLoader', response)
	},
    async filterOrganisationStatistics({commit, state},resp){
		commit('setOrganisationStatistics', resp)
	},
	async filterSearchOrganisationStatistics({commit, state},resp){
		commit('setSearchOrganisationStatistics', resp)
	},
	async startOrganisationStatisticLoader({commit, state},resp){
		commit('startOrganisationStatisticLoader', resp)
	},
	async stopOrganisationStatisticLoader({commit, state},resp){
		commit('stopOrganisationStatisticLoader', resp)
	},
};

const mutations = {
	setOrganisationStatistics: (state, organisationStatistics) => {
		state.organisationStatistics = organisationStatistics.data
		state.organisationStatisticPagination.current_page = organisationStatistics.current_page
		state.organisationStatisticPagination.total = organisationStatistics.total
		state.organisationStatisticPagination.per_page = organisationStatistics.per_page
	},
	setSearchOrganisationStatistics: (state, organisationStatistics) => {
		state.searchOrganisationStatistics = organisationStatistics.data
		state.searchOrganisationStatisticPagination.current_page = organisationStatistics.current_page
		state.searchOrganisationStatisticPagination.total = organisationStatistics.total
		state.searchOrganisationStatisticPagination.per_page = organisationStatistics.per_page
	},
	setAllOrganisationStatistics: (state, organisationStatistics) => {
		state.allOrganisationStatistics = organisationStatistics
	},
    startOrganisationStatisticLoader: (state) => state.organisationStatisticLoader = true,
	stopOrganisationStatisticLoader: (state) => state.organisationStatisticLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
