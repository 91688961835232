import apiCall from '@/utils/api'

const state = {
	broadcasts: [],
    publicBroadcasts: [],

    broadcastPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    publicBroadcastPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},

    broadcastLoader: false,
    publicBroadcastLoader: false
};

const getters = {
	broadcasts: (state) => state.broadcasts,
    publicBroadcasts: (state) => state.publicBroadcasts,

    broadcastLoader: (state) => state.broadcastLoader,
    publicBroadcastLoader: (state) => state.publicBroadcastLoader,

    broadcastPagination: (state) => state.broadcastPagination,
    publicBroadcastPagination: (state) => state.publicBroadcastPagination,
}

const actions = {
    async fetchBroadcasts({commit}, page) {
		const response = await apiCall({url: `/api/broadcast?page=${page}`, method: 'GET' });
		commit('setBroadcasts', response)
        commit('stopLoader', response)
	},
    async fetchPublicBroadcasts({commit}, page) {
		const response = await apiCall({url: `/api/broadcast?type=public&page=${page}`, method: 'GET' });
		commit('setPublicBroadcasts', response)
        commit('stopLoader', response)
	},

    async filterBroadcasts({commit, state},resp){
		commit('setBroadcasts', resp)
	},
    async filterPublicBroadcasts({commit, state},resp){
		commit('setPublicBroadcasts', resp)
	},

	async startBroadcastLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopBroadcastLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setBroadcasts: (state, broadcasts) => {
		state.broadcasts = broadcasts.data
		state.broadcastPagination.current_page = broadcasts.current_page
		state.broadcastPagination.total = broadcasts.total
		state.broadcastPagination.per_page = broadcasts.per_page
	},
    startLoader: (state) => state.broadcastLoader = true,
	stopLoader: (state) => state.broadcastLoader = false,

    setPublicBroadcasts: (state, broadcasts) => {
		state.publicBroadcasts = broadcasts.data
		state.publicBroadcastPagination.current_page = broadcasts.current_page
		state.publicBroadcastPagination.total = broadcasts.total
		state.publicBroadcastPagination.per_page = broadcasts.per_page
	},
};

export default {
	state,
	getters,
	actions,
	mutations
}
