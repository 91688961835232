import apiCall from '@/utils/api'

const state = {
	chat: {},
	chats: [],
    chatPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    chatLoader: false,
	unreadMessages: 0
};

const getters = {
    chatLoader: (state) => state.chatLoader,
	chats: (state) => state.chats,
	chat: (state) => state.chat,
    chatPagination: (state) => state.chatPagination,
	unreadMessages: (state) => state.unreadMessages
}

const actions = {
    async fetchChats({commit}) {
		const response = await apiCall({url: `/api/chat`, method: 'GET' });
		commit('setChats', response)
        commit('stopLoader', response)
	},
    async fetchChat({commit}, itemId) {
		const response = await apiCall({url: `/api/chat/${itemId}`, method: 'GET' });
		commit('setChat', response)
        commit('stopLoader', response)
	},
	async fetchUnreadMessages({commit}) {
		const response = await apiCall({url: `/api/chat?type=unreadMessages`, method: 'GET' });
		commit('setUnreadMessages', response)
	},
    async filterChats({commit, state},resp){
		commit('setChats', resp)
	},
	async filterChat({commit, state},resp){
		commit('setChat', resp)
	},
	async startChatLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopChatLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setChats: (state, chats) => {
		state.chats = chats.data
		state.chatPagination.current_page = chats.current_page
		state.chatPagination.total = chats.total
		state.chatPagination.per_page = chats.per_page
	},
	setChat: (state, chat) => {
		state.chat = chat
	},
	setUnreadMessages: (state, unread) => {
		state.unreadMessages = unread
	},
	startLoader: (state) => state.chatLoader = true,
	stopLoader: (state) => state.chatLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
