<template>
  <div class="institution">
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-container>
      <v-layout row wrap>
        <v-flex xs12 md3>
          <v-card class="mx-auto mt-5" outlined style="border-radius: 25px;">
            <v-img v-if="institution.cover_image == null" src="img/institution.png" height="200px" style="z-index: 2;">                              
              <v-avatar
                size="86"
                style="position: absolute; top: 110px; left: 3px; z-index: 10"
              >
                <v-img
                  v-if="institution.logo==null"
                  src="img/institution.png"
                >
                </v-img>
                <v-img
                  v-else
                  :src="path+'/storage/institution_pictures/'+institution.logo"
                >
                </v-img>
              </v-avatar>
            </v-img>
            <v-img v-else :src="path+'/storage/institution_pictures/'+institution.cover_image" height="200px" style="z-index: 2; border-radius: 25px;">                              
              <v-avatar
                size="86"
                style="position: absolute; top: 110px; left: 3px; z-index: 10"
              >
                <v-img
                  v-if="institution.logo==null"
                  src="img/institution.png"
                >
                </v-img>
                <v-img
                  v-else
                  :src="path+'/storage/institution_pictures/'+institution.logo"
                >
                </v-img>
              </v-avatar>
            </v-img>
            <v-card-text>
              

              <div class="title font-weight-bold">
                {{ institution.name }}
              </div>
              <!-- <v-row align="center" class="mx-1 mt-1 mb-1">
                <v-rating
                  :value="4.5"
                  color="amber"
                  dense
                  half-increments
                  readonly
                  size="14"
                ></v-rating>

                <div class="grey--text ms-4">4.5 (519)</div>
              </v-row> -->
              
            </v-card-text>
            <v-card-subtitle>
                {{ institution.brief }}
              </v-card-subtitle>

              <v-card-subtitle>
                <p  v-html="institution.description"></p>
              </v-card-subtitle>
          </v-card>

          <v-card class="mx-auto mt-5" outlined style="border-radius: 25px;">
            <v-card-title> Stats </v-card-title>

            <v-card-text>
              <div class="pa-3">
                <v-layout column>
                  <v-flex xs12>
                    <v-layout row wrap>
                      <v-flex xs12 md6>
                        <div><b>Total Innovations</b></div>
                      </v-flex>
                      <v-flex xs12 md6>
                        <div>{{ total_innovations }}</div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 class="mt-5">
                    <v-layout row wrap>
                      <v-flex xs12 md6>
                        <div><b>Affiliated Users</b></div>
                      </v-flex>
                      <v-flex xs12 md6>
                        <div>{{ total_users }}</div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <!-- <v-flex xs12 class="mt-5">
                    <v-layout row wrap>
                      <v-flex xs12 md6>
                        <div><b>Connections Made</b></div>
                      </v-flex>
                      <v-flex xs12 md6>
                        <div>{{ total_connections }}</div>
                      </v-flex>
                    </v-layout>
                  </v-flex> -->
                </v-layout>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 md6>
          <div class="mx-5 mt-5">
            <v-progress-linear
              v-if="loading"
              height="1"
              indeterminate
              color="primary"
            >
            </v-progress-linear>
            <v-card outlined>
              <v-tabs grow>
                <v-tabs-slider color="text"></v-tabs-slider>
                <template v-for="(tab, index) in tabs">
                  <v-tab
                    class="text-none text--text"
                    @click="activeTab(tab.name)"
                    :key="index"
                  >
                    {{ tab.name }} ({{ tab.total }})
                  </v-tab>
                </template>
              </v-tabs>
            </v-card>
          </div>
          <v-card outlined v-if="view == 'Innovations'" class="mt-5 mx-5 " style="border-radius: 25px;">
            <div
              class="pa-3"
              id="institutionList"
              
              style="
                margin: 1px, 1px;
                padding: 1px;
                width: auto;
                height: 77vh;
                overflow-x: hidden;
                overflow-y: scroll;
                text-align: justify;
              "
            >
              <v-layout column>
                <div v-if="innovations.length != 0">
                  <template v-for="(innovation, index) in innovations">
                    <div :key="index">
                      <v-flex xs12>
                        <a style="text-decoration: none">
                          <v-card
                            outlined
                            class="mb-3"
                            @click="goToInnovation(innovation)"
                            style="border-radius: 25px;"
                          >
                            <v-card-text>
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <div align="center" class="mt-5">
                                    <v-avatar size="156">
                                      <v-img
                                        v-if="innovation.featured_image==null"
                                        src="img/innovation.jpg"
                                      >
                                      </v-img>
                                      <v-img
                                        v-else
                                        :src="path+'/storage/innovation_pictures/'+innovation.featured_image"
                                      >
                                      </v-img>
                                    </v-avatar>
                                  </div>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <v-layout column>
                                    <v-flex xs12>
                                      <div class="mt-5 title mr-5">
                                        {{ innovation.name }}
                                      </div>
                                      <v-row align="center" class="mx-0 mt-1">
                                        <v-rating
                                          :value="innovation.average_rating"
                                          color="amber"
                                          dense
                                          half-increments
                                          readonly
                                          size="14"
                                        ></v-rating>

                                        <div class="grey--text ms-4">
                                          {{ innovation.average_rating }} ({{
                                            innovation.total_rating
                                          }})
                                        </div>
                                      </v-row>

                                      <div class="my-4 text-subtitle-1 mr-1">
                                        <template
                                          v-for="(
                                            innovation_sector, index
                                          ) in innovation.innovation_sectors.slice(
                                            0,
                                            10
                                          )"
                                        >
                                          <v-chip
                                            x-small
                                            :color="innovation_sector.sector.color"
                                            class="white--text mr-1"
                                            :key="index"
                                          >
                                            #{{ innovation_sector.sector.name }}
                                          </v-chip>
                                        </template>
                                        <v-chip
                                          class="white--text"
                                          color="primary"
                                          small
                                          v-if="
                                            innovation.innovation_sectors.length > 5
                                          "
                                        >
                                          + More
                                        </v-chip>
                                      </div>
                                      <div class="mb-5 mr-5">
                                        <v-clamp
                                          autoresize
                                          :max-lines="3"
                                          class="mb-5 grey--text caption"
                                          >{{ innovation.brief }}
                                        </v-clamp>
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card-text>
                              
                          </v-card>
                        </a>
                      </v-flex>
                    </div>
                  </template>
                </div>
                <div v-else>
                  <v-flex xs12 class="mb-10">
                    <a style="text-decoration: none">
                      <v-card outlined router style="border-radius: 25px;">
                        <v-card-text>
                          <v-layout row wrap>
                          <v-flex xs12 md4>
                            <div align="center" class="my-10">
                              <v-avatar color="primary" size="96">
                                <v-icon class="white--text">
                                  mdi-file-document-alert
                                </v-icon>
                              </v-avatar>
                            </div>
                          </v-flex>
                          <v-flex xs12 md8>
                            <v-layout column>
                              <v-flex xs12 class="mt-1">
                                <div class="mt-15 ml-5 title">
                                  No Innovation Found..
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                        </v-card-text>
                        
                      </v-card>
                    </a>
                  </v-flex>
                </div>
              </v-layout>
            </div>
          </v-card>
          <div v-if="view == 'Users'" class="mx-5">
            <v-btn block class="primary mt-3 text-none" :loading="userAffLoader" @click="applyForInstAff()">Apply for Affiliation</v-btn>
          </div>          
          <v-card outlined v-if="view == 'Users'" class="mt-3 mx-5" style="border-radius: 25px;">            
            <div
              id="usersList"
              class="pa-3"
              style="
                margin: 1px, 1px;
                padding: 1px;
                width: auto;
                height: 74vh;
                overflow-x: hidden;
                overflow-y: scroll;
                text-align: justify;
              "
            >
              <v-layout column>
                  <div v-if="users.length != 0">
                    <template v-for="(user, index) in users">
                      <div :key="index">
                        <v-flex xs12>
                          <a style="text-decoration: none">
                            <v-card outlined class="mb-3" style="border-radius: 25px;">
                              <v-card-text>
                                <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <div align="center" class="my-5">
                                    <v-avatar size="126" class="primary display-3 white--text" v-if="user.display_img != null">
                                      <img
                                        :src="path+'/storage/profile_pics/'+user.display_img"
                                        contain
                                        alt="pic"
                                      />
                                    </v-avatar>
                                    <v-avatar size="126" class="primary display-3 white--text" v-else>
                                      {{ user.f_name[0] }}
                                    </v-avatar>
                                  </div>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <v-layout column>
                                    <v-flex xs12>
                                      <div class="mt-5 title mr-5">{{user.f_name}} {{user.m_name}} {{user.l_name}}</div>

                                      <div class="mb-5 mr-5" v-if="user.bio!=null">
                                        <v-clamp
                                          autoresize
                                          :max-lines="3"
                                          class="mb-5 grey--text caption"
                                          v-html="user.bio"
                                          >
                                        </v-clamp>
                                      </div>
                                      <div class="mb-5 mr-5" v-if="user.bio==null">
                                        <i>No Bio..</i>
                                      </div>
                                      <div align="center">
                                        <v-btn
                                          color="primary"
                                          small
                                          class="text-none my-3"
                                          depressed
                                          @click="addFriend(user.code)"
                                          :loading="connectLoading && code==user.code"
                                        >
                                          Connect
                                          <v-icon right small class="white--text"> mdi-handshake </v-icon>
                                        </v-btn>
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                              </v-card-text>
                              
                            </v-card>
                          </a>
                        </v-flex>
                      </div>
                    </template>
                  </div>
                  <div v-else>
                  <v-flex xs12 class="mb-10">
                    <a style="text-decoration: none">
                      <v-card outlined router style="border-radius: 25px;">
                        <v-card-text>
                          <v-layout row wrap>
                            <v-flex xs12 md4>
                              <div align="center" class="my-10">
                                <v-avatar color="primary" size="96">
                                  <v-icon class="white--text">
                                    mdi-file-document-alert
                                  </v-icon>
                                </v-avatar>
                              </div>
                            </v-flex>
                            <v-flex xs12 md8>
                              <v-layout column>
                                <v-flex xs12 class="mt-1">
                                  <div class="mt-15 ml-5 title">
                                    No User(s) Found..
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card-text>
                        
                      </v-card>
                    </a>
                  </v-flex>
                </div>       
              </v-layout>
            </div>
          </v-card>
          


          <v-card outlined v-if="view == 'Affiliated Institutions'" class="mt-5 mx-5 " style="border-radius: 25px;">
            <div
              class="pa-3"
              id="affInstitutionsList"
              
              style="
                margin: 1px, 1px;
                padding: 1px;
                width: auto;
                height: 77vh;
                overflow-x: hidden;
                overflow-y: scroll;
                text-align: justify;
              "
            >
              <v-layout column>
                <div v-if="affiliatedInstitutions.length != 0">
                  <template v-for="(institution, index) in affiliatedInstitutions">
                    <div :key="index">
                      <v-flex xs12>
                        <a style="text-decoration: none">
                          <v-card
                            outlined
                            class="mb-3"
                            @click="goToInstitution(institution)"
                            style="border-radius: 25px;"
                          >
                            <v-card-text>
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <div align="center" class="mt-5">
                                    <v-avatar size="156">
                                      <v-img
                                        v-if="institution.logo==null"
                                        src="img/institution.png"
                                      >
                                      </v-img>
                                      <v-img
                                        v-else
                                        :src="path+'/storage/institution_pictures/'+institution.featured_image"
                                      >
                                      </v-img>
                                    </v-avatar>
                                  </div>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <v-layout column>
                                    <v-flex xs12>
                                      <div class="mt-5 title mr-5">
                                        {{ institution.name }}
                                      </div>
                                      <div class="caption mr-5">
                                        {{ institution.institution_type.name }} • {{ institution.county.name }}
                                      </div>
                
                                      <div class="mb-5 mr-5">
                                        <v-clamp
                                          autoresize
                                          :max-lines="3"
                                          class="mb-5 grey--text caption"
                                          >{{ institution.brief }}
                                        </v-clamp>
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card-text>
                              
                          </v-card>
                        </a>
                      </v-flex>
                    </div>
                  </template>
                </div>
                <div v-else>
                  <v-flex xs12 class="mb-10">
                    <a style="text-decoration: none">
                      <v-card outlined router style="border-radius: 25px;">
                        <v-card-text>
                          <v-layout row wrap>
                          <v-flex xs12 md4>
                            <div align="center" class="my-10">
                              <v-avatar color="primary" size="96">
                                <v-icon class="white--text">
                                  mdi-file-document-alert
                                </v-icon>
                              </v-avatar>
                            </div>
                          </v-flex>
                          <v-flex xs12 md8>
                            <v-layout column>
                              <v-flex xs12 class="mt-1">
                                <div class="mt-15 ml-5 title">
                                  No Affiliated Institution(s)                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                        </v-card-text>
                        
                      </v-card>
                    </a>
                  </v-flex>
                </div>
              </v-layout>
            </div>
          </v-card>


          <v-card outlined v-if="view == 'Affiliated Organisations'" class="mt-5 mx-5 " style="border-radius: 25px;">
            <div
              class="pa-3"
              id="affOrganisationsList"
              
              style="
                margin: 1px, 1px;
                padding: 1px;
                width: auto;
                height: 77vh;
                overflow-x: hidden;
                overflow-y: scroll;
                text-align: justify;
              "
            >
              <v-layout column>
                <div v-if="affiliatedOrganisations.length != 0">
                  <template v-for="(organisation, index) in affiliatedOrganisations">
                    <div :key="index">
                      <v-flex xs12>
                        <a style="text-decoration: none">
                          <v-card
                            outlined
                            class="mb-3"
                            @click="goToOrganisation(organisation)"
                            style="border-radius: 25px;"
                          >
                            <v-card-text>
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <div align="center" class="mt-5">
                                    <v-avatar size="156">
                                      <v-img
                                        v-if="organisation.logo==null"
                                        src="img/organisation.png"
                                      >
                                      </v-img>
                                      <v-img
                                        v-else
                                        :src="path+'/storage/organisation_pictures/'+organisation.featured_image"
                                      >
                                      </v-img>
                                    </v-avatar>
                                  </div>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <v-layout column>
                                    <v-flex xs12>
                                      <div class="mt-5 title mr-5">
                                        {{ organisation.name }}
                                      </div>
                                      <div class="caption mr-5" v-if="organisation.organisation_type != null">
                                        {{ organisation.organisation_type.name }}
                                      </div>
                                      <div class="caption mr-5" v-if="organisation.county != null">
                                        {{ organisation.county.name }}
                                      </div>
                
                                      <div class="mb-5 mr-5">
                                        <v-clamp
                                          autoresize
                                          :max-lines="3"
                                          class="mb-5 grey--text caption"
                                          >{{ organisation.brief }}
                                        </v-clamp>
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card-text>
                              
                          </v-card>
                        </a>
                      </v-flex>
                    </div>
                  </template>
                </div>
                <div v-else>
                  <v-flex xs12 class="mb-10">
                    <a style="text-decoration: none">
                      <v-card outlined router style="border-radius: 25px;">
                        <v-card-text>
                          <v-layout row wrap>
                          <v-flex xs12 md4>
                            <div align="center" class="my-10">
                              <v-avatar color="primary" size="96">
                                <v-icon class="white--text">
                                  mdi-file-document-alert
                                </v-icon>
                              </v-avatar>
                            </div>
                          </v-flex>
                          <v-flex xs12 md8>
                            <v-layout column>
                              <v-flex xs12 class="mt-1">
                                <div class="mt-15 ml-5 title">
                                  No Affiliated Organisation(s)                                
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                        </v-card-text>
                        
                      </v-card>
                    </a>
                  </v-flex>
                </div>
              </v-layout>
            </div>
          </v-card>




          <v-card outlined v-if="view == 'Reviews'" class="mt-3 mx-5 " style="border-radius: 25px;"> 
            <div>
            <v-card outlined class="pa-10 mt-3 mx-3" style="border-radius: 25px;">
              <v-layout column>
                <v-flex xs12>
                  <v-layout row wrap>
                    <v-flex xs4>
                      <div class="mt-1"><b>Overall Rating:</b></div>
                    </v-flex>
                    <v-flex xs8>
                      <v-row align="center" class="mx-3 mt-1 mb-1">
                        <v-rating
                          :value="4.5"
                          color="amber"
                          dense
                          half-increments
                          readonly
                          size="14"
                        ></v-rating>

                        <div class="grey--text ms-4">4.5 (519)</div>
                      </v-row>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 class="mt-8">
                  <v-layout row wrap>
                    <v-flex xs4>
                      <div class="mt-1">Space:</div>
                    </v-flex>
                    <v-flex xs8>
                      <v-row align="center" class="mx-3 mt-1 mb-1">
                        <v-rating
                          :value="3.8"
                          color="amber"
                          dense
                          half-increments
                          readonly
                          size="14"
                        ></v-rating>

                        <div class="grey--text ms-4">3.8 (212)</div>
                      </v-row>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 class="mt-5">
                  <v-layout row wrap>
                    <v-flex xs4>
                      <div class="mt-1">Funding:</div>
                    </v-flex>
                    <v-flex xs8>
                      <v-row align="center" class="mx-3 mt-1 mb-1">
                        <v-rating
                          :value="2.5"
                          color="amber"
                          dense
                          half-increments
                          readonly
                          size="14"
                        ></v-rating>

                        <div class="grey--text ms-4">2.5 (50)</div>
                      </v-row>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 class="mt-5">
                  <v-layout row wrap>
                    <v-flex xs4>
                      <div class="mt-1">Technical Support:</div>
                    </v-flex>
                    <v-flex xs8>
                      <v-row align="center" class="mx-3 mt-1 mb-1">
                        <v-rating
                          :value="4.8"
                          color="amber"
                          dense
                          half-increments
                          readonly
                          size="14"
                        ></v-rating>

                        <div class="grey--text ms-4">4.8 (270)</div>
                      </v-row>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </div>
          <div
            id="reviewsList"
            class="pa-3 mt-3"
            style="
              margin: 1px, 1px;
              padding: 1px;
              width: auto;
              height: 59vh;
              overflow-x: hidden;
              overflow-y: scroll;
              text-align: justify;
            "
          >
            <v-layout column>
              <v-flex xs12 class="mb-3">
                <a style="text-decoration: none">
                  <v-card outlined style="border-radius: 25px;">
                    <v-layout row wrap>
                      <v-flex xs12 md4>
                        <div align="center" class="mt-5">
                          <v-avatar size="156">
                            <img src="img/139107.jpg" alt="John" />
                          </v-avatar>
                        </div>
                      </v-flex>
                      <v-flex xs12 md8>
                        <v-layout column>
                          <v-flex xs12>
                            <div class="mt-5 title mr-5 ml-2">Bono</div>

                            <div class="mb-5 mr-5">
                              <v-flex xs8>
                                <v-row align="center" class="ml-1">
                                  <v-rating
                                    :value="4"
                                    color="amber"
                                    dense
                                    half-increments
                                    readonly
                                    size="14"
                                  ></v-rating>
                                </v-row>
                              </v-flex>
                            </div>
                            <div class="mb-5 mr-5">
                              <div
                                class="mb-5 grey--text caption font-italic ml-2"
                              >
                                <v-icon class="primary--text">
                                  mdi-format-quote-open
                                </v-icon>
                                Id diam vel quam elementum pulvinar etiam non
                                quam. Arcu non sodales neque sodales ut. Aliquam
                                vestibulum morbi blandit cursus risus at. Dictum
                                at tempor commodo ullamcorper a lacus vestibulum
                                sed. Amet est placerat in egestas.
                                <v-icon class="primary--text">
                                  mdi-format-quote-close
                                </v-icon>
                              </div>
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </a>
              </v-flex>
              <v-flex xs12 class="mb-3">
                <a style="text-decoration: none">
                  <v-card outlined style="border-radius: 25px;">
                    <v-layout row wrap>
                      <v-flex xs12 md4>
                        <div align="center" class="mt-5">
                          <v-avatar size="156">
                            <img src="img/f1_2022_nl_wil_lg.png" alt="John" />
                          </v-avatar>
                        </div>
                      </v-flex>
                      <v-flex xs12 md8>
                        <v-layout column>
                          <v-flex xs12>
                            <div class="mt-5 title mr-5 ml-2">Goatifi</div>

                            <div class="mb-5 mr-5">
                              <v-flex xs8>
                                <v-row align="center" class="ml-1">
                                  <v-rating
                                    :value="2"
                                    color="amber"
                                    dense
                                    half-increments
                                    readonly
                                    size="14"
                                  ></v-rating>
                                </v-row>
                              </v-flex>
                            </div>
                            <div class="mb-5 mr-5">
                              <div
                                class="mb-5 grey--text caption font-italic ml-2"
                              >
                                <v-icon class="primary--text">
                                  mdi-format-quote-open
                                </v-icon>
                                Id diam vel quam elementum pulvinar etiam non
                                quam. Arcu non sodales neque sodales ut. Aliquam
                                vestibulum morbi blandit cursus risus at. Dictum
                                at tempor commodo ullamcorper a lacus vestibulum
                                sed. Amet est placerat in egestas.
                                <v-icon class="primary--text">
                                  mdi-format-quote-close
                                </v-icon>
                              </div>
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </a>
              </v-flex>
              <v-flex xs12 class="mb-3">
                <a style="text-decoration: none">
                  <v-card outlined style="border-radius: 25px;">
                    <v-layout row wrap>
                      <v-flex xs12 md4>
                        <div align="center" class="mt-5">
                          <v-avatar size="156">
                            <img
                              src="img/pexels-justin-shaifer-1222271.jpg"
                              alt="John"
                            />
                          </v-avatar>
                        </div>
                      </v-flex>
                      <v-flex xs12 md8>
                        <v-layout column>
                          <v-flex xs12>
                            <div class="mt-5 title mr-5 ml-2">John Doe</div>

                            <div class="mb-5 mr-5">
                              <v-flex xs8>
                                <v-row align="center" class="ml-1">
                                  <v-rating
                                    :value="1"
                                    color="amber"
                                    dense
                                    half-increments
                                    readonly
                                    size="14"
                                  ></v-rating>
                                </v-row>
                              </v-flex>
                            </div>
                            <div class="mb-5 mr-5">
                              <div
                                class="mb-5 grey--text caption font-italic ml-2"
                              >
                                <v-icon class="primary--text">
                                  mdi-format-quote-open
                                </v-icon>
                                Id diam vel quam elementum pulvinar etiam non
                                quam. Arcu non sodales neque sodales ut. Aliquam
                                vestibulum morbi blandit cursus risus at. Dictum
                                at tempor commodo ullamcorper a lacus vestibulum
                                sed. Amet est placerat in egestas.
                                <v-icon class="primary--text">
                                  mdi-format-quote-close
                                </v-icon>
                              </div>
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </a>
              </v-flex>
              <v-flex xs12 class="mb-3">
                <a style="text-decoration: none">
                  <v-card outlined style="border-radius: 25px;">
                    <v-layout row wrap>
                      <v-flex xs12 md4>
                        <div align="center" class="mt-5">
                          <v-avatar size="156">
                            <img
                              src="img/pexels-joshua-mcknight-1191488.jpg"
                              alt="John"
                            />
                          </v-avatar>
                        </div>
                      </v-flex>
                      <v-flex xs12 md8>
                        <v-layout column>
                          <v-flex xs12>
                            <div class="mt-5 title mr-5 ml-2">Jane Doe</div>

                            <div class="mb-5 mr-5">
                              <v-flex xs8>
                                <v-row align="center" class="ml-1">
                                  <v-rating
                                    :value="5"
                                    color="amber"
                                    dense
                                    half-increments
                                    readonly
                                    size="14"
                                  ></v-rating>
                                </v-row>
                              </v-flex>
                            </div>
                            <div class="mb-5 mr-5">
                              <div
                                class="mb-5 grey--text caption font-italic ml-2"
                              >
                                <v-icon class="primary--text">
                                  mdi-format-quote-open
                                </v-icon>
                                Id diam vel quam elementum pulvinar etiam non
                                quam. Arcu non sodales neque sodales ut. Aliquam
                                vestibulum morbi blandit cursus risus at. Dictum
                                at tempor commodo ullamcorper a lacus vestibulum
                                sed. Amet est placerat in egestas.
                                <v-icon class="primary--text">
                                  mdi-format-quote-close
                                </v-icon>
                              </div>
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </a>
              </v-flex>
              <v-flex xs12 class="mb-3">
                <a style="text-decoration: none">
                  <v-card outlined style="border-radius: 25px;">
                    <v-layout row wrap>
                      <v-flex xs12 md4>
                        <div align="center" class="mt-5">
                          <v-avatar size="156">
                            <img src="img/f1_2022_lh_mer_lg.png" alt="John" />
                          </v-avatar>
                        </div>
                      </v-flex>
                      <v-flex xs12 md8>
                        <v-layout column>
                          <v-flex xs12>
                            <div class="mt-5 title mr-5 ml-2">Lewis Ham</div>

                            <div class="mb-5 mr-5">
                              <v-flex xs8>
                                <v-row align="center" class="ml-1">
                                  <v-rating
                                    :value="5"
                                    color="amber"
                                    dense
                                    half-increments
                                    readonly
                                    size="14"
                                  ></v-rating>
                                </v-row>
                              </v-flex>
                            </div>
                            <div class="mb-5 mr-5">
                              <div
                                class="mb-5 grey--text caption font-italic ml-2"
                              >
                                <v-icon class="primary--text">
                                  mdi-format-quote-open
                                </v-icon>
                                Id diam vel quam elementum pulvinar etiam non
                                quam. Arcu non sodales neque sodales ut. Aliquam
                                vestibulum morbi blandit cursus risus at. Dictum
                                at tempor commodo ullamcorper a lacus vestibulum
                                sed. Amet est placerat in egestas.
                                <v-icon class="primary--text">
                                  mdi-format-quote-close
                                </v-icon>
                              </div>
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </a>
              </v-flex>
            </v-layout>
          </div>
          </v-card>
          
          <div v-if="view == 'Innovations'">
            <v-container>
                <div align="center">
                  <v-pagination
                    v-if="innovationLength != 0"
                    :length="innovationLength"
                    total-visible="10"
                    v-model="innovationPagination.current_page"
                    @input="search()"
                    circle
                  >
                  </v-pagination>
                </div>
            </v-container>
          </div>
          <div v-if="view == 'Users'">
            <v-container>
                <div align="center">
                  <v-pagination
                    v-if="userLength != 0"
                    :length="userLength"
                    total-visible="10"
                    v-model="userPagination.current_page"
                    @input="search()"
                    circle
                  >
                  </v-pagination>
                </div>
            </v-container>
          </div>
          <div v-if="view == 'Affiliated Institutions'">
            <v-container>
                <div align="center">
                  <v-pagination
                    v-if="affiliatedInstitutionLength != 0"
                    :length="affiliatedInstitutionLength"
                    total-visible="10"
                    v-model="affiliatedInstitutionPagination.current_page"
                    @input="search()"
                    circle
                  >
                  </v-pagination>
                </div>
            </v-container>
          </div>
          <div v-if="view == 'Affiliated Organisations'">
            <v-container>
                <div align="center">
                  <v-pagination
                    v-if="affiliatedOrganisationLength != 0"
                    :length="affiliatedOrganisationLength"
                    total-visible="10"
                    v-model="affiliatedOrganisationPagination.current_page"
                    @input="search()"
                    circle
                  >
                  </v-pagination>
                </div>
            </v-container>
          </div>
          <v-card outlined v-if="view == 'Events'" class="mt-5 mx-5 " style="border-radius: 25px;">    
            <div
              id="eventsList"
              class="pa-3"
              style="
                margin: 1px, 1px;
                padding: 1px;
                width: auto;
                height: 77vh;
                overflow-x: hidden;
                overflow-y: scroll;
                text-align: justify;
              "
            >
            <v-layout column>
                <div v-if="events.length != 0">
                  <template v-for="(event, index) in events">
                    <div :key="index">
                      <v-flex xs12>
                        <a style="text-decoration: none">
                          <v-card
                            outlined
                            class="mb-3"
                            @click="goToEvent(event)"
                            style="border-radius: 25px;"
                          >
                            <v-card-text>
                              <v-layout row wrap>
                                <v-flex xs3>
                                  <v-layout column>
                                    <v-flex xs12>
                                      <div
                                        align="center"
                                        class="text--text display-1 font-weight-black mt-3"
                                      >
                                      {{ event.start_time | moment("Do") }}
                                      </div>
                                    </v-flex>
                                    <v-flex xs12>
                                      <div align="center" class="text--text mb-3">
                                        {{
                                            event.start_time
                                              | moment("MMM YYYY")
                                          }}
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs9>
                                  <div class="mt-6 ml-3 mb-6">
                            <v-layout column>
                              <v-flex xs12>
                                <v-layout row wrap>
                                  <v-flex xs4>
                                    <b>Name:</b>
                                  </v-flex>
                                  <v-flex xs8>{{ event.title }} </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs12 class="mt-6">
                                <v-layout row wrap>
                                  <v-flex xs4>
                                    <b>Event Type:</b>
                                  </v-flex>
                                  <v-flex xs8> {{ event.event_type.name }} </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs12 class="mt-6">
                                <v-layout row wrap>
                                  <v-flex xs4>
                                    <b>Venue:</b>
                                  </v-flex>
                                  <v-flex xs8> {{ event.location }} </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs12 class="mt-6">
                                <v-layout row wrap>
                                  <v-flex xs4>
                                    <b>Start Time:</b>
                                  </v-flex>
                                  <v-flex xs8> {{ event.start_time | moment("DD/MM/YYYY - h:mm a") }} </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs12 class="mt-6">
                                <v-layout row wrap>
                                  <v-flex xs4>
                                    <b>Stop Time:</b>
                                  </v-flex>
                                  <v-flex xs8> {{ event.end_time | moment("DD/MM/YYYY - h:mm a") }} </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs12 class="mt-6">
                                <v-layout row wrap>
                                  <v-flex xs4>
                                    <b>Entry Cost:</b>
                                  </v-flex>
                                  <v-flex xs8> Kshs {{ event.entry_cost | formatNumber }} </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </div>
                          <!-- <div class="mr-5 mb-7">
                            <v-btn
                              block
                              depressed
                              class="secondary text-none primary--text"
                            >
                              Reserve Slot
                              <v-icon right> mdi-account-plus </v-icon>
                            </v-btn>
                          </div> -->
                                </v-flex>
                              </v-layout>
                            </v-card-text>
                              
                          </v-card>
                        </a>
                      </v-flex>
                    </div>
                  </template>
                </div>
                <div v-else>
                  <v-flex xs12 class="mb-10">
                    <a style="text-decoration: none">
                      <v-card outlined router style="border-radius: 25px;">
                        <v-layout row wrap>
                          <v-flex xs12 md4>
                            <div align="center" class="my-10">
                              <v-avatar color="primary" size="96">
                                <v-icon class="white--text">
                                  mdi-file-document-alert
                                </v-icon>
                              </v-avatar>
                            </div>
                          </v-flex>
                          <v-flex xs12 md8>
                            <v-layout column>
                              <v-flex xs12 class="mt-1">
                                <div class="mt-15 ml-5 title">
                                  No Upcoming Events
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </a>
                  </v-flex>
                </div>
              </v-layout>
            </div>
          </v-card>
          <div v-if="view == 'Events'">
            <v-container>
                <div align="center">
                  <v-pagination
                    v-if="eventLength != 0"
                    :length="eventLength"
                    total-visible="10"
                    v-model="eventPagination.current_page"
                    @input="search()"
                    circle
                  >
                  </v-pagination>
                </div>
            </v-container>
          </div>
            
        </v-flex>
        <v-flex xs12 md3>
          <v-card class="mx-auto pa-1 mt-5" outlined>
            <v-layout column>
              <v-flex xs12>
                <div class="mt-3 ml-1"><b>Search</b></div>
              </v-flex>
              <v-flex xs12>
                <div class="pa-3 my-2">
                  <v-layout row wrap>
                    <v-flex xs9>
                      <v-text-field
                        v-model="filter.search"
                        class="mx-1 search_field background"
                        v-on:keyup.enter="search"
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <div align="right">
                        <v-btn
                          depressed
                          class="primary white--text mx-1"
                          @click="search()"
                          :loading="searchLoading"
                        >
                          <v-icon> mdi-magnify </v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>
            </v-layout>
          </v-card>
          <div class="mt-3 ml-1" v-if="view != 'Affiliated Institutions'"><b>Filters</b></div>
          <v-card class="mx-auto pa-1 mt-3" outlined v-if="view != 'Affiliated Institutions'">
            <v-layout column>
              <v-flex xs12>
                <div class="mt-3 ml-1"><b>Time Periods</b></div>
              </v-flex>
              <v-flex xs12>
                <v-chip-group
                  column
                  active-class="text--text"
                  v-model="filter.time_period"
                >
                  <v-chip
                    v-for="time_period in time_periods"
                    :key="time_period"
                    :value="time_period"
                    @input="search()"
                  >
                    {{ time_period }}
                  </v-chip>
                </v-chip-group>
              </v-flex>
            </v-layout>
          </v-card>
          <v-card
            class="mx-auto pa-1 mt-5"
            outlined
            v-if="view == 'Innovations'"
          >
            <v-layout column>
              <v-flex xs12>
                <v-tabs grow>
                  <v-tabs-slider color="primary"></v-tabs-slider>
                  <template v-for="(tab, index) in filterTabs">
                    <v-tab
                      class="text-none"
                      @click="activeFilterTab(tab)"
                      :key="index"
                    >
                      {{ tab }}
                    </v-tab>
                  </template>
                </v-tabs>
              </v-flex>
              <v-flex xs12>
                <div v-if="filterView == 'Sectors'">
                  <v-chip-group
                    v-model="filter.sectors"
                    multiple
                    column
                    active-class="primary--text"
                  >
                    <v-chip
                      v-for="sector in sectors"
                      :key="sector.sector.id"
                      :value="sector.sector.id"
                      @input="search()"
                    >
                      {{ sector.sector.name }} ({{ sector.total }})
                    </v-chip>
                  </v-chip-group>
                </div>
                <div v-if="filterView == 'Interests'">
                  <v-chip-group
                    v-model="filter.interests"
                    multiple
                    column
                    active-class="primary--text"
                  >
                    <v-chip
                      v-for="interest in interests"
                      :key="interest.interest.id"
                      :value="interest.interest.id"
                      @input="search()"
                    >
                      {{ interest.interest.name }} ({{ interest.total }})
                    </v-chip>
                  </v-chip-group>
                </div>
                <div v-if="filterView == 'SDGs'">
                  <v-chip-group
                    v-model="filter.sdgs"
                    multiple
                    column
                    active-class="primary--text"
                  >
                    <v-chip
                      v-for="sdg in sdgs"
                      :key="sdg.sdg.id"
                      :value="sdg.sdg.id"
                      @input="search()"
                    >
                      {{ sdg.sdg.name }} ({{ sdg.total }})
                    </v-chip>
                  </v-chip-group>
                </div>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";
import VClamp from "vue-clamp";

export default {
  components: {
    VClamp,
  },
  data() {
    return {
      pageUrl: null,
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      loading: false,
      searchLoading: false,
      selection: 1,
      apply: false,
      drawer: false,
      dialog: false,
      userAffLoader: false,
      notFound: false,

      view: "Innovations",
      filterView: "Sectors",

      tabs: [
        { name: "Innovations", total: 0 },
        { name: "Users", total: 0 },
        { name: "Affiliated Institutions", total: 0 },
        { name: "Affiliated Organisations", total: 0 },
        // { name: "Reviews", total: 519 },
        { name: "Events", total: 0 },
      ],
      filterTabs: ["Sectors", "Interests", "SDGs"],

      institution: null,
      innovations: [],
      innovationPagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },

      users: [],
      userPagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },

      affiliatedInstitutions: [],
      affiliatedInstitutionPagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },

      affiliatedOrganisations: [],
      affiliatedOrganisationPagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },

      events: [],
      eventPagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },

      total_innovations: 0,
      total_users: 0,
      total_connections: 0,

      time_periods: [
        "24 Hrs",
        "This Week",
        "This Month",
        "This Year",
        "All Time",
      ],
      sectors: [],
      interests: [],
      sdgs: [],

      filter: {
        institution_id: null,
        search: null,
        time_period: null,
        sectors: [],
        interests: [],
        sdgs: [],
      },

      connectLoading: false,
      code: null,
      userFriendData: {
        user_code: null,
        innovation_id: null,
      },
    };
  },
  mounted() {},
  watch: {
    notFound() {
      this.findInstitution();
    },
    institutionStatistic(oldVal, newVal){
      this.assignValues();
      this.fetchInnovations();
      this.fetchUsers();
      this.fetchInstitutions();
      this.fetchOrganisations();
      this.fetchEvents();
    },
    pageUrl(){ 
      // this.findInstitution();
    }
  },
  created() {
    window.scrollTo(0, 0);
    this.assignValues();
    this.fetchInnovations();
    this.fetchUsers();
    this.fetchInstitutions();
    this.fetchOrganisations();
    this.fetchEvents();
    this.pageUrl = this.$route.params.slug
  },
  methods: {
    ...mapActions([
      "filterSearchInnovations",
      "filterSearchInstitutionStatistics"
    ]),
    activeTab(tab) {
      this.view = tab;
    },
    activeFilterTab(tab) {
      this.filterView = tab;
    },
    assignValues() {
      console.log("this.institutionStatistic", this.institutionStatistic)

      this.sectors = this.institutionStatistic.institution_sector_stats;
      this.interests = this.institutionStatistic.institution_interests_stats;
      this.sdgs = this.institutionStatistic.institution_sdg_stats;
      this.innovations = this.institutionStatistic.institution.innovations;
      this.events = this.institutionStatistic.institution.events;
      this.users = this.institutionStatistic.institution.users;
      this.affiliatedInstitutions = this.institutionStatistic.institution.affiliated_institutions;
      this.affiliatedOrganisations = this.institutionStatistic.institution.affiliated_organisations;

      this.filter.institution_id = this.institutionStatistic.institution.id;

      this.institution = this.institutionStatistic.institution;
      this.total_innovations = this.institutionStatistic.total_innovations;
      this.total_users = this.institutionStatistic.total_users;
      this.total_connections = this.institutionStatistic.total_connections;
    },
    findInstitution(){
      this.loading = true;
      apiCall({
        url:
          "/api/institution-statistic?type=findInstitution&page=1&slug=" +
          this.$route.params.slug,
        method: "GET",
      })
        .then((resp) => {
          this.loading = false;
          this.activeTab("Innovations")
          this.filterSearchInstitutionStatistics(resp);
        })
        .catch((error) => {
          this.loading = false;
          this.message = "An Error Occurred";
          this.color = "error";
          this.snackbar = true;
        });
    },
    fetchInnovations() {
      this.loading = true;
      apiCall({
        url:
          "/api/innovation?type=institution&institution_id=" +
          this.institution.id,
        method: "GET",
      })
        .then((resp) => {
          this.innovations = resp.data;
          this.innovationPagination.current_page = resp.current_page;
          this.innovationPagination.total = resp.total;
          this.innovationPagination.per_page = resp.per_page;
          this.loading = false;
          this.tabs[0].total = resp.total;
          // this.filterSearchInnovations(resp);
        })
        .catch((error) => {
          this.message =
            "An Error Occurred; Couldn't fetch Innovations. Please Try Again";
          this.color = "error";
          this.loading = false;
          this.snackbar = true;
        });
    },
    fetchInstitutions(){
      this.loading = true;
      apiCall({
        url:
          "/api/institution?type=public&institution_id=" +
          this.institution.id,
        method: "GET",
      })
        .then((resp) => {
          this.affiliatedInstitutions = resp.data;
          this.affiliatedInstitutionPagination.current_page = resp.current_page;
          this.affiliatedInstitutionPagination.total = resp.total;
          this.affiliatedInstitutionPagination.per_page = resp.per_page;
          this.loading = false;
          this.tabs[2].total = resp.total;
          // this.filterSearchInnovations(resp);
        })
        .catch((error) => {
          this.message =
            "An Error Occurred; Couldn't fetch Innovations. Please Try Again";
          this.color = "error";
          this.loading = false;
          this.snackbar = true;
        });
    },
    fetchOrganisations(){
      this.loading = true;
      apiCall({
        url:
          "/api/institution?type=publicOrg&institution_id=" +
          this.institution.id,
        method: "GET",
      })
        .then((resp) => {
          this.affiliatedOrganisations = resp.data;
          this.affiliatedOrganisationPagination.current_page = resp.current_page;
          this.affiliatedOrganisationPagination.total = resp.total;
          this.affiliatedOrganisationPagination.per_page = resp.per_page;
          this.loading = false;
          this.tabs[3].total = resp.total;
          // this.filterSearchInnovations(resp);
        })
        .catch((error) => {
          this.message =
            "An Error Occurred; Couldn't fetch Innovations. Please Try Again";
          this.color = "error";
          this.loading = false;
          this.snackbar = true;
        });
    },
    fetchEvents(){
      this.loading = true;
      apiCall({
        url:
          "/api/event?type=institution&institution_id=" +
          this.institution.id,
        method: "GET",
      })
        .then((resp) => {
          this.events = resp.data;
          this.eventPagination.current_page = resp.current_page;
          this.eventPagination.total = resp.total;
          this.eventPagination.per_page = resp.per_page;
          this.loading = false;
          this.tabs[4].total = resp.total;
          // this.filterSearchInnovations(resp);
        })
        .catch((error) => {
          this.message =
            "An Error Occurred; Couldn't fetch Innovations. Please Try Again";
          this.color = "error";
          this.loading = false;
          this.snackbar = true;
        });
    },
    addFriend(user_code) {
      this.code = user_code
      this.connectLoading = true;
      this.userFriendData.user_code = user_code;
      apiCall({
        url: "/api/add-friend",
        data: this.userFriendData,
        method: "POST",
      })
        .then((resp) => {
          this.connectLoading = false;
          this.dialog = false;
          this.color = "success";
          this.message = "Connection Request Sent Successfully";
          this.snackbar = true;
        })
        .catch((error) => {
          this.connectLoading = false;
          this.color = "success";
          this.message = "Connection Request Sent Successfully";
          this.snackbar = true;
        });
    },
    fetchUsers() {
      this.loading = true;
      apiCall({
        url:
          "/api/user?type=institution&institution_id=" +
          this.institution.id,
        method: "GET",
      })
        .then((resp) => {
          this.users = resp.data;
          this.userPagination.current_page = resp.current_page;
          this.userPagination.total = resp.total;
          this.userPagination.per_page = resp.per_page;
          this.loading = false;
          this.tabs[1].total = resp.total;
          // this.filterSearchInnovations(resp);
        })
        .catch((error) => {
          this.message =
            "An Error Occurred; Couldn't fetch Innovations. Please Try Again";
          this.color = "error";
          this.loading = false;
          this.snackbar = true;
        });
    },
    applyForInstAff(){
      confirm("Are You Sure You Want to Apply for Institution Affiliation?") &&
        (this.apply = true);
        if (this.apply) {
          this.userAffLoader = true;
            apiCall({
              url:
                "/api/institution-user",
              data: this.filter,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Application Successful. You will be notified after review.";
                this.color = "success";
                this.userAffLoader = false;
                this.snackbar = true;
                this.apply = false
              })
              .catch((error) => {
                this.message = "An Error Occurred; Please Try Again";
                this.color = "error";
                this.userAffLoader = false;
                this.snackbar = true;
                this.apply = false
              });
        }
    },
    search() {
      this.loading = true;
      this.searchLoading = true;

      if(this.view == 'Innovations'){
        apiCall({
          url:
            "/api/innovation?type=filter&page=" +
            this.innovationPagination.current_page,
          data: this.filter,
          method: "POST",
        })
          .then((resp) => {
            this.loading = false;
            this.innovations = resp.data;
            this.innovationPagination.current_page = resp.current_page;
            this.innovationPagination.total = resp.total;
            this.innovationPagination.per_page = resp.per_page;
            this.loading = false;
            this.searchLoading = false;
            this.tabs[0].total = resp.total;
            this.filterSearchInnovations(resp);
          })
          .catch((error) => {
            this.message = "An Error Occurred; Please Try Again";
            this.color = "error";
            this.loading = false;
            this.searchLoading = false;
            this.snackbar = true;
          });
      } else if(this.view == 'Users'){
        apiCall({
          url:
            "/api/user?type=institution&page=" +
            this.userPagination.current_page,
          data: this.filter,
          method: "POST",
        })
          .then((resp) => {
            this.loading = false;
            this.users = resp.data;
            this.userPagination.current_page = resp.current_page;
            this.userPagination.total = resp.total;
            this.userPagination.per_page = resp.per_page;
            this.searchLoading = false;
            this.tabs[1].total = resp.total;
          })
          .catch((error) => {
            this.message = "An Error Occurred; Please Try Again";
            this.color = "error";
            this.loading = false;
            this.searchLoading = false;
            this.snackbar = true;
          });
      } else if (this.view == 'Affiliated Institutions') {
        apiCall({
          url:
            "/api/institution?type=public&page=" +
            this.affiliatedInstitutionPagination.current_page,
          data: this.filter,
          method: "POST",
        })
          .then((resp) => {
            this.loading = false;
            this.affiliatedInstitutions = resp.data;
            this.affiliatedInstitutionPagination.current_page = resp.current_page;
            this.affiliatedInstitutionPagination.total = resp.total;
            this.affiliatedInstitutionPagination.per_page = resp.per_page;
            this.searchLoading = false;
            this.tabs[2].total = resp.total;
          })
          .catch((error) => {
            this.message = "An Error Occurred; Please Try Again";
            this.color = "error";
            this.loading = false;
            this.searchLoading = false;
            this.snackbar = true;
          });
      } else if (this.view == 'Affiliated Organisations') {
        apiCall({
          url:
            "/api/institution?type=publicOrg&page=" +
            this.affiliatedOrganisationPagination.current_page,
          data: this.filter,
          method: "POST",
        })
          .then((resp) => {
            this.loading = false;
            this.affiliatedOrganisations = resp.data;
            this.affiliatedOrganisationPagination.current_page = resp.current_page;
            this.affiliatedOrganisationPagination.total = resp.total;
            this.affiliatedOrganisationPagination.per_page = resp.per_page;
            this.searchLoading = false;
            this.tabs[3].total = resp.total;
          })
          .catch((error) => {
            this.message = "An Error Occurred; Please Try Again";
            this.color = "error";
            this.loading = false;
            this.searchLoading = false;
            this.snackbar = true;
          });
      } else if (this.view == 'Events') {
        apiCall({
          url:
            "/api/event?type=institution&page=" +
            this.eventPagination.current_page,
          data: this.filter,
          method: "POST",
        })
          .then((resp) => {
            this.loading = false;
            this.events = resp.data;
            this.eventPagination.current_page = resp.current_page;
            this.eventPagination.total = resp.total;
            this.eventPagination.per_page = resp.per_page;
            this.searchLoading = false;
            this.tabs[4].total = resp.total;
          })
          .catch((error) => {
            this.message = "An Error Occurred; Please Try Again";
            this.color = "error";
            this.loading = false;
            this.searchLoading = false;
            this.snackbar = true;
          });
      }
      
    },
    goToInnovation(innovation) {
      this.$router.push("/innovations/" + innovation.slug);
    },
    goToInstitution(institution){
      this.$router.push("/institutions/" + institution.slug);
      this.findInstitution()
    },
    goToOrganisation(organisation){
      this.$router.push("/organisations/" + organisation.slug);
      this.findOrganisation()
    },
    goToEvent(event) {
      this.$router.push("/events/" + event.slug);
    }
  },
  computed: {
    ...mapGetters([
      "institutionStatistics"
    ]),

    institutionStatistic() {
      if(this.$store.getters.institutionStatistics.find(
        (institutionStatistic) =>
          institutionStatistic.institution.slug == this.$route.params.slug
      )){
        return this.$store.getters.institutionStatistics.find(
          (institutionStatistic) =>
            institutionStatistic.institution.slug == this.$route.params.slug
        );
      } else if(this.$store.getters.searchInstitutionStatistics.find(
        (institutionStatistic) =>
          institutionStatistic.institution.slug == this.$route.params.slug
      )){
        return this.$store.getters.searchInstitutionStatistics.find(
          (institutionStatistic) =>
            institutionStatistic.institution.slug == this.$route.params.slug
        );
      } else{
        this.notFound = true
      }

      
    },
    innovationLength: function () {
      return Math.ceil(
        this.innovationPagination.total / this.innovationPagination.per_page
      );
    },
    userLength: function () {
      return Math.ceil(
        this.userPagination.total / this.userPagination.per_page
      ); 
    },
    affiliatedInstitutionLength: function () {
      return Math.ceil(
        this.affiliatedInstitutionPagination.total / this.affiliatedInstitutionPagination.per_page
      ); 
    },
    affiliatedOrganisationLength: function () {
      return Math.ceil(
        this.affiliatedOrganisationPagination.total / this.affiliatedOrganisationPagination.per_page
      ); 
    },
    eventLength: function () {
      return Math.ceil(
        this.eventPagination.total / this.eventPagination.per_page
      ); 
    },
  },
};
</script>
