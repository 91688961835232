import Vue from 'vue'
import Vuex from 'vuex'

import language from './modules/language'

import notifications from './modules/notifications'
import connection from './modules/connection'

import statistics from './modules/statistics'
import role from './modules/role'
import users from './modules/users'
import permission from './modules/permission'
import user from './modules/user'
import auth from './modules/auth'
import log from './modules/log'
import logData from './modules/logData'

import abilities from './modules/abilities'
import loginCard from './modules/loginCard'
import innovationCard from './modules/innovationCard'
import darkMode from './modules/darkMode'

import county from './modules/county'
import interest from './modules/interest'
import sector from './modules/sector'
import sdg from './modules/sdg'

import ask from './modules/ask'
import call from './modules/call'
import event from './modules/event'
import contactUs from './modules/contactUs'
import broadcast from './modules/broadcast'
import investment from './modules/investment'

import innovation from './modules/innovation'

import investmentFirm from './modules/investmentFirm'
import investorType from './modules/investorType'

import institution from './modules/institution'
import institutionType from './modules/institutionType'
import institutionStatistic from './modules/institutionStatistic'

import organisation from './modules/organisation'
import organisationType from './modules/organisationType'
import organisationStatistic from './modules/organisationStatistic'

import eso from './modules/eso'
import esoType from './modules/esoType'

import posts from './modules/posts'
import chat from './modules/chat'

import adminRequest from './modules/adminRequest'

import feedback from './modules/feedback'

import VuexPersist from 'vuex-persist'
import { abilityPlugin, ability as appAbility } from './ability'

Vue.use(Vuex)

export const ability = appAbility

const vuexLocalStorage = new VuexPersist({
  key: 'innovation-bridge', // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  filter: mutation => (true)
})

export default new Vuex.Store({
  plugins: [
    vuexLocalStorage.plugin,
    abilityPlugin
  ],

  modules: {
    language,
    notifications,
    connection,

    statistics,
    role,
    users,
    user,
    auth,
    permission,
    log,
    logData,
    abilities,
    loginCard,
    innovationCard,
    darkMode,

    county,

    interest,
    sector,
    sdg,

    ask,
    call,
    event,
    contactUs,
    broadcast,
    investment,
    innovation,

    investmentFirm,
    investorType,

    institution,
    institutionType,
    institutionStatistic,

    organisation,
    organisationType,
    organisationStatistic,

    eso,
    esoType,

    posts,
    chat,

    adminRequest,

    feedback
  },
  strict: false,
})