<template>
  <div class="welcome">
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-dialog
      v-model="offerDialog"
      v-if="formData != null"
      width="500"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          Make an Offer
          <v-spacer></v-spacer>
          <v-btn icon @click="offerDialog = false">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <div class="mx-3">
              <v-layout row wrap>
                <v-flex xs12>
                  <div class="mt-5">
                    <b>Investment Type</b>
                  </div>
                  <v-select
                    :items="formData.funding_types"
                    item-text="name"
                    item-value="id"
                    :rules="inputRules"
                    outlined
                    dense
                    v-model="investment.funding_type_id"
                    class="background login_field"
                  ></v-select>
                </v-flex>
              </v-layout>
            </div>
            <div class="mx-3">
              <v-layout row wrap>
                <v-flex xs12>
                  <div class="mt-9">
                    <b>Amount</b>
                  </div>
                  <v-text-field
                    outlined
                    dense
                    v-model="investment.amount"
                    :rules="inputRules"
                    class="background login_field mb-5"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </div>
            <div class="mt-5" v-if="formData.individual_investor == true && formData.investment_firm_administrators.length != 0">
              <v-checkbox v-model="investment.individual_investor"
              label="Invest as an Individual"
              >
              </v-checkbox>
            </div>
            <div class="mt-5" v-if="formData.investment_firm_administrators.lenght != 0 && investment.individual_investor == false">
              <b>Investment Firm(s)</b>
            </div>
            <div v-if="formData.investment_firm_administrators.lenght != 0 && investment.individual_investor == false">
                <v-radio-group v-model="investment.investment_firm">
                  <v-radio
                  v-for="(
                    investment_firm_administrator, index
                  ) in formData.investment_firm_administrators"
                    :value="investment_firm_administrator.investment_firm.id"
                    :label="investment_firm_administrator.investment_firm.name"
                  ></v-radio>
                </v-radio-group>
            </div>
            <v-divider></v-divider>
            <div class="mt-8">
              <v-checkbox
                v-model="investment.public"
                label="Publicly Visible"
              ></v-checkbox>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="text-none my-3"
            depressed
            @click="save()"
            :loading="offerLoading"
          >
            Submit
            <v-icon right small class="white--text"> mdi-upload </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout column>
      <v-flex xs12 class="mt-5 mb-5">
        <v-container>
          <v-card outlined style="border-radius: 25px;">
            <v-card-text>
              <v-layout column>
                <v-flex xs12>
                  <v-layout row wrap class="my-5">
                    <v-flex xs12 md3>
                      <div align="center" class="mt-2">
                        <v-img
                          v-if="ask.featured_image==null" 
                          style="border-radius: 25px;"
                          max-width="250"
                          height="150"
                          src="img/innovation.jpg"
                        >
                        </v-img>
                        <v-img
                          v-else
                          style="border-radius: 25px;"
                          max-width="250"
                          height="150"
                          :src="path+'/storage/ask_pics/'+ask.featured_image"
                        >
                        </v-img>
                      </div>
                    </v-flex>
                    <v-flex xs12 md5>
                      <v-layout column>
                        <v-flex xs12>
                          <div class="title" style="font-family: 'poppins' !important;">{{ ask.title }}</div>
                        </v-flex>
                        <v-flex xs12>
                          <div>
                            <i><b>Created:</b>
                              {{ ask.created_at | moment("DD/MM/YYYY") }}
                            </i>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div class="mb-3">
                            <i class="green--text"
                              ><b>Amount Requested:</b> Kshs
                              {{ ask.amount | formatNumber }}</i
                            >
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div>{{ ask.brief }}</div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="title" style="font-family: 'poppins' !important;">Tags</div>
                      <template
                        v-for="(innovation_ask, index) in ask.innovation_asks"
                      >
                        <template
                          v-for="(sector, index) in innovation_ask.innovation
                            .innovation_sectors"
                        >
                          <v-chip
                            x-small
                            :color="sector.sector.color"
                            class="white--text mr-1"
                            :key="index"
                          >
                            #{{ sector.sector.name }}
                          </v-chip>
                        </template>
                      </template>
                      <div class="mt-5" align="center" v-if="$can('investment_create')">
                        <v-btn
                          v-if="
                            formData != null &&
                            (formData.individual_investor == true ||
                              formData.investment_firm_administrators.length !=
                                0)
                          "
                          depressed
                          class="primary white--text text-none"
                          @click="offerDialog = true"
                        >
                          Make an Offer
                          <v-icon right> mdi-offer </v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 class="my-3">
                  <div class="title" style="font-family: 'poppins' !important;">Share Via</div>
                  <div class="mt-1">
                    <v-btn
                      small
                      rounded
                      depressed
                      class="text-none white--text mr-1 mb-1"
                      color="#3b5998"
                    >
                      <v-icon>mdi-facebook</v-icon>
                      <ShareNetwork
                        style="text-decoration: none"
                        class="white--text ml-2"
                        network="facebook"
                        :url="shareLink"
                        :title="ask.title"
                        :description="ask.brief"
                        hashtags="InnovationBridge"
                      >
                        <span>Facebook</span>
                      </ShareNetwork>
                    </v-btn>
                    <v-btn
                      small
                      rounded
                      depressed
                      class="text-none white--text mr-1 mb-1"
                      color="#1DA1F2"
                    >
                      <v-icon>mdi-twitter</v-icon>
                      <ShareNetwork
                        style="text-decoration: none"
                        class="white--text ml-2"
                        network="twitter"
                        :url="shareLink"
                        :title="ask.title"
                        :description="ask.brief"
                        hashtags="InnovationBridge"
                      >
                        <span>Twitter</span>
                      </ShareNetwork>
                    </v-btn>

                    <v-btn
                      small
                      rounded
                      depressed
                      class="text-none white--text mr-1 mb-1"
                      color="#0e76a8"
                    >
                      <v-icon>mdi-linkedin</v-icon>
                      <ShareNetwork
                        style="text-decoration: none"
                        class="white--text ml-2"
                        network="linkedin"
                        :url="shareLink"
                        :title="ask.title"
                        :description="ask.brief"
                        hashtags="InnovationBridge"
                      >
                        <span>LinkedIn</span>
                      </ShareNetwork>
                    </v-btn>
                    <v-btn
                      small
                      rounded
                      depressed
                      class="text-none white--text mr-1 mb-1"
                      color="#075e54"
                    >
                      <v-icon>mdi-whatsapp</v-icon>
                      <ShareNetwork
                        style="text-decoration: none"
                        class="white--text ml-2"
                        network="whatsapp"
                        :url="shareLink"
                        :title="ask.title"
                        :description="ask.brief"
                        hashtags="InnovationBridge"
                      >
                        <span>Whatsapp</span>
                      </ShareNetwork>
                    </v-btn>
                    <v-btn
                      small
                      rounded
                      depressed
                      class="text-none white--text mr-1 mb-1"
                      color="#0088cc"
                    >
                      <v-icon>mdi-send mdi-rotate-315</v-icon>
                      <ShareNetwork
                        style="text-decoration: none"
                        class="white--text ml-2"
                        network="telegram"
                        :url="shareLink"
                        :title="ask.title"
                        :description="ask.brief"
                        hashtags="InnovationBridge"
                      >
                        <span>Telegram</span>
                      </ShareNetwork>
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-container>
      </v-flex>
      <v-flex xs12 class="mt-2 mb-15">
        <v-progress-linear
          v-if="ratingLoader"
          height="1"
          indeterminate
          color="primary"
        >
        </v-progress-linear>
        <v-card outlined>
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 md8>
                <v-tabs show-arrows v-model="tab" class="mt-6">
                  <v-tabs-slider color="primary"></v-tabs-slider>
                  <v-tab @click="activeTab = 'about'">
                    <div class="text-none text--text">About</div>
                  </v-tab>
                  <v-tab
                    @click="activeTab = 'innovations'"
                    v-if="ask.innovation_asks.length != 0"
                  >
                    <div class="text-none text--text">
                      Innovations ({{ ask.innovation_asks.length }})
                    </div>
                  </v-tab>
                </v-tabs>
                <div v-if="activeTab == 'about'">
                  <p class="caption mx-3 mt-6" v-html="ask.description"></p>
                </div>
                <div v-if="activeTab == 'innovations'">
                  <v-layout column class="mt-5">
                    <template
                      v-for="(innovation_ask, index) in ask.innovation_asks"
                    >
                      <div :key="index">
                        <v-flex xs12 class="mb-4">
                          <a style="text-decoration: none">
                            <v-card
                              outlined
                              @click="goToInnovation(innovation_ask.innovation)"
                              style="border-radius: 25px;"
                            >
                              <v-card-text>
                                <v-layout row wrap>
                                  <v-flex xs12 md4>
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div align="center" class="mt-5">
                                          <v-img
                                              v-if="innovation_ask.innovation.featured_image==null" 
                                              style="border-radius: 25px;"
                                              max-width="250"
                                              height="150"
                                              src="img/innovation.jpg"
                                            >
                                            </v-img>
                                            <v-img
                                              v-else
                                              style="border-radius: 25px;"
                                              max-width="250"
                                              height="150"
                                              :src="path+'/storage/innovation_pictures/'+innovation_ask.innovation.featured_image"
                                            >
                                            </v-img>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 md8>
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div class="mt-7 title">
                                          {{ innovation_ask.innovation.name }}
                                        </div>
                                        <div class="mb-2 text-subtitle-1">
                                          <template
                                            v-for="(
                                              sector, index
                                            ) in innovation_ask.innovation.innovation_sectors.slice(
                                              0,
                                              5
                                            )"
                                          >
                                            <v-chip
                                              x-small
                                              :color="sector.sector.color"
                                              class="white--text mr-1"
                                              :key="index"
                                            >
                                              #{{ sector.sector.name }}
                                            </v-chip>
                                          </template>
                                          <v-chip
                                            class="white--text"
                                            color="primary"
                                            small
                                            v-if="
                                              innovation_ask.innovation
                                                .innovation_sectors.length > 5
                                            "
                                          >
                                            + More
                                          </v-chip>
                                        </div>
                                        <v-clamp
                                          autoresize
                                          :max-lines="4"
                                          class="mb-5 mr-5 caption"
                                          >{{ innovation_ask.innovation.brief }}
                                        </v-clamp>
                                        <!-- <div class="subtitle-2"><b>Created:</b> </div> -->
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-card-text>
                            </v-card>
                          </a>
                        </v-flex>
                      </div>
                    </template>
                  </v-layout>
                </div>
              </v-flex>
              <v-flex xs12 md4>
                <v-layout column>
                  <v-flex xs12 class="mt-7">
                    <v-card
                      outlined
                      elevation="0"
                      class="pa-5 ma-3 mt-16 tableHeader"
                    >
                      <div class="body"><b>Funding Type</b></div>
                      <div class="caption text--text">
                        <b>{{ ask.funding_type.name }}</b>
                      </div>
                      <div class="body mt-5">
                        <b>Technology Readiness Level</b>
                      </div>
                      <div class="caption text--text">
                        <b>{{ ask.tech_readiness_level.name }}</b>
                      </div>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
          </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<style>
.ck-editor__editable {
  min-height: 200px;
}
</style>
<script>
import Vue from "vue";
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";
import VClamp from "vue-clamp";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadAdapter from '@/uploadAdapter.js';

Vue.use(CKEditor);

export default {
  components: {
    VClamp,
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      selection: 1,
      drawer: false,
      dialog: false,
      offerDialog: false,
      loading: false,
      confirm: false,
      offerLoading: false,

      formData: null,

      editor: ClassicEditor,
      editorConfig: {
          extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
              return new uploadAdapter(loader);
          } }],
          language: 'en',
      },

      tab: null,
      activeTab: "about",

      myRating: {
        innovation_id: null,
        rating: null,
      },

      ratingLoader: false,

      shareLink: null,

      investment: {
        id: null,
        amount: null,
        innovations: [],
        funding_type_id: null,
        investment_firm: null,
        individual_investor: false,
        public: true,
      },
    };
  },
  mounted() {
    this.initialize();
    this.createShareLink();
    
  },
  created() {
    window.scrollTo(0, 0);
    this.getFormData()
  },
  methods: {
    ...mapActions(["fetchInterestInnovations"]),
    initialize() {
      this.recordView();
      this.assignValues();
    },
    getFormData(){
      apiCall({
        url: "/api/investment?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
          if(resp.individual_investor == true && resp.investment_firm_administrators.length == 0){
            this.investment.individual_investor = true
          }
        })
        .catch((error) => {
          this.message = "An Error Occurred; Couldn't Get Form Data";
          this.color = "error";
        });
    },  
    goToInnovation(innovation) {
      this.$router.push("/innovations/" + innovation.slug);
    },
      assignValues(){        
          this.investment.amount = this.ask.amount
          this.investment.funding_type_id = this.ask.funding_type_id

          for (var i = 0; i < this.ask.innovation_asks.length; i++) {
            console.log(this.ask.innovation_asks[i])
            this.investment.innovations.push(this.ask.innovation_asks[i].innovation.id);
          }
        
      },
    createShareLink() {
      this.shareLink = this.path + "/#/asks/" + this.$route.params.slug;
    },
    recordView() {
      this.myRating.innovation_id = this.ask.id;
      apiCall({
        url: "/api/ask-view",
        data: this.myRating,
        method: "POST",
      })
        .then((resp) => {})
        .catch((error) => {});
    },
    save(){
      if(this.investment.individual_investor == false &&
        this.investment.investment_firm == null
        ){
          this.message = "Please pick a Firm or indicate whether you are investing Privately";
          this.color = "success";
          this.snackbar = true;
      }else{        
        if (this.$refs.form.validate()) {
            confirm("Submit this offer?") &&
              (this.confirm = true);
            if (this.confirm) {
              this.offerLoading = true
              apiCall({
                url: "/api/investment?type=ask",
                data: this.investment,
                method: "POST",
              })
                .then((resp) => {
                  this.message = "Offer Submitted Successfully";
                  this.color = "success";
                  this.snackbar = true;
                  this.offerLoading = false
                  this.offerDialog = false

                  this.investment.funding_type_id = null
                  this.investment.amount = null
                })
                .catch((error) => {
                  this.message = "An Error Occurred";
                  this.color = "error";
                  this.snackbar = true;
                  this.offerLoading = false
                });
            }
          
        }
      }
    },
  },
  computed: {
    ...mapGetters(["publicAsks"]),

    ask() {
      if (
        this.$store.getters.publicAsks.find(
          (ask) => ask.slug == this.$route.params.slug
        )
      ) {
        return this.$store.getters.publicAsks.find(
          (ask) => ask.slug == this.$route.params.slug
        );
      } else {
      }
    },
  },
};
</script>
