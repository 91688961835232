<template>
    <div class="PRIVACYPOLICY">
        <v-container>
            <v-card elevation="0" class="pa-16 my-10">
                <div class="headline">
                <b>PRIVACY POLICY</b>
            </div>
            <div class="mt-5">
                <b>Last updated January 01, 2023</b>
            </div>
            <div class="mt-10">
                <p class="body-2">
                    This privacy notice for Kenya National Innovation Agency ('<b>Company</b>', '<b>we</b>', '<b>us</b>', or
                    '<b>our</b>'), describes how and why we might collect, store, use, and/or share ('<b>process</b>') your
                    information when you use our services ('<b>Services</b>'), such as when you:
                <ul>
                    <li class="mt-5">Visit our website at <a
                            href="https://bridge.innovationagency.go.ke/">https://bridge.innovationagency.go.ke/</a>, or any
                        website of ours that links to this privacy notice</li>
                    <li class="mt-5">Engage with us in other related ways, including any sales, marketing, or events</li>
                </ul>
                </p>
                <p class="mt-5 body-2">
                    <b>Questions or concerns?</b> Reading this privacy notice will help you understand your privacy rights
                    and choices. If you do not agree with our policies and practices, please do not use our Services. If you
                    still have any questions or concerns, please contact us at info@innovationagency.go.ke.
                </p>
            </div>
            <div class="title mt-10">
                <b>SUMMARY OF KEY POINTS</b>
            </div>
            <div class="mt-5">
                <p class="body-2 mt-5"><b><i class="subtitle-2">This summary provides key points from our privacy notice, but you can find out more details about
                            any of these topics by clicking the link following each key point or by using our table of
                            contents below to find the section you are looking for.</i></b></p>

                <p class="body-2 mt-5"><b>What personal information do we process?</b> When you visit, use, or navigate our Services, we may
                    process personal information depending on how you interact with Kenya National Innovation Agency and the
                    Services, the choices you make, and the products and features you use. Learn more about personal
                    information you disclose to us.</p>

                <p class="body-2 mt-5"><b>Do we process any sensitive personal information?</b> We do not process sensitive personal
                    information.</p>

                <p class="body-2 mt-5"><b>Do we receive any information from third parties?</b> We do not receive any information from third
                    parties.</p>

                <p class="body-2 mt-5"><b>How do we process your information? We process your information to provide, improve, and administer
                        our Services, communicate with you, for security and fraud prevention, and to comply with law. We
                        may also process your information for other purposes with your consent. We process your information
                        only when we have a valid legal reason to do so. Learn more about how we process your
                        information.</b></p>

                <p class="body-2 mt-5"><b>In what situations and with which types of parties do we share personal information?</b> We may share
                    information in specific situations and with specific categories of third parties. Learn more about when
                    and with whom we share your personal information.</p>

                <p class="body-2 mt-5"><b>How do we keep your information safe?</b> We have organisational and technical processes and
                    procedures in place to protect your personal information. However, no electronic transmission over the
                    internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or
                    guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat
                    our security and improperly collect, access, steal, or modify your information. Learn more about how we
                    keep your information safe.</p>

                <p class="body-2 mt-5"><b>What are your rights?</b> Depending on where you are located geographically, the applicable privacy
                    law may mean you have certain rights regarding your personal information. Learn more about your privacy
                    rights.</p>

                <p class="body-2 mt-5"><b>How do you exercise your rights?</b> The easiest way to exercise your rights is by visiting
                    info@innovationagency.go.ke, or by contacting us. We will consider and act upon any request in
                    accordance with applicable data protection laws.</p>

            <p class="body-2 mt-5">Want to learn more about what Kenya National Innovation Agency does with any information we collect?
                Review the privacy notice in full.</p>
        </div>
        <div class="title mt-10">
            <b>TABLE OF CONTENTS</b>
        </div>
        <div class="body-2 mt-5">
            <ol>
                <li> <a @click="scrollMeTo('section-1')">WHAT INFORMATION DO WE COLLECT?</a></li>
                <li> <a @click="scrollMeTo('section-2')">HOW DO WE PROCESS YOUR INFORMATION?</a></li>
                <li> <a @click="scrollMeTo('section-3')">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></li>
                <li> <a @click="scrollMeTo('section-4')">WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</a></li>
                <li> <a @click="scrollMeTo('section-5')">HOW LONG DO WE KEEP YOUR INFORMATION?</a></li>
                <li> <a @click="scrollMeTo('section-6')">HOW DO WE KEEP YOUR INFORMATION SAFE?</a></li>
                <li> <a @click="scrollMeTo('section-7')">DO WE COLLECT INFORMATION FROM MINORS?</a></li>
                <li> <a @click="scrollMeTo('section-8')">WHAT ARE YOUR PRIVACY RIGHTS?</a></li>
                <li> <a @click="scrollMeTo('section-9')">CONTROLS FOR DO-NOT-TRACK FEATURES</a></li>
                <li> <a @click="scrollMeTo('section-10')">DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a></li>
                <li> <a @click="scrollMeTo('section-11')">DO WE MAKE UPDATES TO THIS NOTICE?</a></li>
                <li> <a @click="scrollMeTo('section-12')">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></li>
                <li> <a @click="scrollMeTo('section-13')">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a></li>
            </ol>
        </div>
        <div class="title mt-10" ref="section-1">
            <b>1. WHAT INFORMATION DO WE COLLECT?</b>
        </div>
        <div>
            <p class="body-2 mt-5"><b>Personal information you disclose to us</b></p>
            <p class="body-2 mt-5"><b>In Short:</b> We collect personal information that you provide to us.</p>
            <p class="body-2 mt-5">We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>
            <p class="body-2 mt-5"><b>Personal Information Provided by You.</b> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
            <ul class="body-2 mt-5">
                <li>
                    names
                </li>
                <li class="mt-5">
                    phone numbers
                </li>
                <li class="mt-5">
                    email addresses
                </li>
                <li class="mt-5">
                    passwords
                </li>
                <li class="mt-5">
                    contact preferences
                </li>
            </ul>
            <p class="body-2 mt-5">
                <b>Sensitive Information.</b> We do not process sensitive information.
            </p>
            <p class="body-2 mt-5">
                All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
            </p>
            <p class="body-2 mt-5"><b>Information automatically collected</b></p>
            <p class="body-2 mt-5"><b>In Short:</b> Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</p>
            <p class="body-2 mt-5">We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>
            <p class="body-2 mt-5">The information we collect includes:</p>
            <p class="body-2 mt-5">
                <ul>
                    <li>
                        Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps'), and hardware settings).
                    </li>
                    <li class="mt-5">
                        Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.
                    </li>
                </ul>
            </p>
        </div>
        <div class="title mt-10" ref="section-2">
            <b>2. HOW DO WE PROCESS YOUR INFORMATION?</b>
        </div>
        <div>
            <p class="body-2 mt-5"><b>In Short:</b> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</p>
            <p class="body-2 mt-5"><b>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</b></p>
            <p class="body-2 mt-5">
                <ul>
                    <li><b>To facilitate account creation and authentication and otherwise manage user accounts.</b> We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>
                    <li class="mt-5"><b>To deliver and facilitate delivery of services to the user.</b> We may process your information to provide you with the requested service.</li>
                    <li class="mt-5"><b>To respond to user inquiries/offer support to users.</b> We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</li>
                    <li class="mt-5"><b>To send administrative information to you.</b> We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</li>
                    <li class="mt-5"><b>To enable user-to-user communications.</b> We may process your information if you choose to use any of our offerings that allow for communication with another user.</li>
                    <li class="mt-5"><b>To send you marketing and promotional communications.</b> We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see 'WHAT ARE YOUR PRIVACY RIGHTS?' below).</li>
                    <li class="mt-5"><b>To deliver targeted advertising to you.</b> We may process your information to develop and display personalised content and advertising tailored to your interests, location, and more.</li>
                    <li class="mt-5"><b>To evaluate and improve our Services, products, marketing, and your experience.</b> We may process your information when we believe it is necessary to identify usage trends, determine the effectiveness of our promotional campaigns, and to evaluate and improve our Services, products, marketing, and your experience.</li>
                    <li class="mt-5"><b>To identify usage trends.</b> We may process information about how you use our Services to better understand how they are being used so we can improve them.</li>
                </ul>
            </p>
        </div>
        <div class="title mt-10" ref="section-3">
            <b>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</b>
        </div>
        <div>
            <p class="body-2 mt-5">
                <b>In Short:</b> We may share information in specific situations described in this section and/or with the following categories of <b>third parties</b>.
            </p>
            <p class="body-2 mt-5">
                <b>Vendors, Consultants, and Other Third-Party Service Providers.</b> We may share your data with third-party vendors, service providers, contractors, or agents ('third parties') who perform services for us or on our behalf and require access to such information to do that work. We have contracts in place with our third parties, which are designed to help safeguard your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will also not share your personal information with any organisation apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct. The categories of third parties we may share personal information with are as follows:
            </p>
            <p class="body-2 mt-5">
                <ul>
                    <li>Data Analytics Services</li>
                    <li class="mt-5">Government Entities</li>
                </ul>
            </p>
            <p class="body-2 mt-10">
                We also may need to share your personal information in the following situations:

            </p>
            <p class="body-2 mt-5">
                <ul>
                    <li>
                        <b>Business Transfers.</b> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                    </li>
                    <li class="mt-5">
                        <b>Business Partners.</b> We may share your information with our business partners to offer you certain products, services, or promotions.
                    </li>
                    <li class="mt-5">
                        <b>Other Users.</b> When you share personal information (for example, by posting comments, contributions, or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.
                    </li>
                </ul>
            </p>
        </div>
        <div class="title mt-10" ref="section-4">
            <b>4. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</b>
        </div>
        <div>
            <p class="body-2 mt-5">
                <b>In Short:</b> We are not responsible for the safety of any information that you share with third parties that we may link to or who advertise on our Services, but are not affiliated with, our Services.
            </p>
            <p class="body-2 mt-5">
                The Services may link to third-party websites, online services, or mobile applications and/or contain advertisements from third parties that are not affiliated with us and which may link to other websites, services, or applications. Accordingly, we do not make any guarantee regarding any such third parties, and we will not be liable for any loss or damage caused by the use of such third-party websites, services, or applications. The inclusion of a link towards a third-party website, service, or application does not imply an endorsement by us. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy notice. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services, or applications that may be linked to or from the Services. You should review the policies of such third parties and contact them directly to respond to your questions.
            </p>
        </div>
        <div class="title mt-10" ref="section-5">
            <b>5. HOW LONG DO WE KEEP YOUR INFORMATION?</b>
        </div>
        <div>
            <p class="body-2 mt-5">
                <b>In Short:</b> We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.
            </p>
            <p class="body-2 mt-5">
                We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.
            </p>
            <p class="body-2 mt-5">
                When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
            </p>
        </div>
        <div class="title mt-10" ref="section-6">
            <b>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</b>
        </div>
        <div>
            <p class="body-2 mt-5">
                <b>In Short:</b> We aim to protect your personal information through a system of organisational and technical security measures.
            </p>
            <p class="body-2 mt-5">
                We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
            </p>
        </div>
        <div class="title mt-10" ref="section-7">
            <b>7. DO WE COLLECT INFORMATION FROM MINORS?</b>
        </div>
        <div>
            <p class="body-2 mt-5"><b>In Short:</b> We do not knowingly collect data from or market to children under 18 years of age.</p>
            <p class="body-2 mt-5">We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at info@innovationagency.go.ke.</p>
        </div>
        <div class="title mt-10" ref="section-8">
            <b>8. WHAT ARE YOUR PRIVACY RIGHTS?</b>
        </div>
        <div>
            <p class="body-2 mt-5">
                <b>In Short:</b>  You may review, change, or terminate your account at any time.
            </p>
            <p class="body-2 mt-5">
                If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your <a href="https://ec.europa.eu/newsroom/article29/items/612080" target="_blank">Member State data protection authority</a> or <a href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/" target="_blank">UK data protection authority</a>.
            </p>
            <p class="body-2 mt-5">
                If you are located in Switzerland, you may contact the <a href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank">Federal Data Protection and Information Commissioner</a>.
            </p>
            <p class="body-2 mt-5">
                <b><u>Withdrawing your consent:</u></b> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.
            </p>
            <p class="body-2 mt-5">
                However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
            </p>
            <p class="body-2 mt-5">
                <b><u>Opting out of marketing and promotional communications:</u></b> You can unsubscribe from our marketing and promotional communications at any time by or by contacting us using the details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.
            </p>
            <p class="body-2 mt-5"><b>Account Information</b></p>
            <p class="body-2 mt-5">
                <ul>
                    <li class="mt-5">Log in to your account settings and update your user account.</li>
                </ul>
            </p>
            <p class="body-2 mt-5">Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</p>
            <p class="body-2 mt-5">If you have questions or comments about your privacy rights, you may email us at info@innovationagency.go.ke.</p>
        </div>
        <div class="title mt-10" ref="section-9">
            <b>9. CONTROLS FOR DO-NOT-TRACK FEATURES</b>
        </div>
        <div>
            <p class="body-2 mt-5">
                Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
            </p>
        </div>
        <div class="title mt-10" ref="section-10">
            <b>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</b>
        </div>
        <div>
            <p class="body-2 mt-5"><b>In Short:</b> Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</p>
            <p class="body-2 mt-5">
                California Civil Code Section 1798.83, also known as the 'Shine The Light' law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
            </p>
            <p class="body-2 mt-5">
                If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).
            </p>
        </div>
        <div class="title mt-10" ref="section-11">
            <b>11. DO WE MAKE UPDATES TO THIS NOTICE?</b>
        </div>
        <div>
            <p class="body-2 mt-5"><b>In Short:</b> Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
            <p class="body-2 mt-5">
                We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised' date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
            </p>
        </div>
        <div class="title mt-10" ref="section-12">
            <b>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</b>
        </div>
        <div>
            <p class="body-2 mt-5">
                If you have questions or comments about this notice, you may email us at info@innovationagency.go.ke or by post to:
            </p>
            <p class="body-2 mt-5">
                <div class="body-2">Kenya National Innovation Agency</div>
                <div class="body-2">Ground Floor, NACOSTI Building</div>
                <div class="body-2">Off Waiyaki Way</div>
                <div class="body-2">P.O. Box 22269-00100</div>
                <div class="body-2">Nairobi</div>
                <div class="body-2">Kenya</div>
            </p>
        </div>
        <div class="title mt-10" ref="section-13">
            <b>13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</b>
        </div>
        <div>
            <p class="body-2 mt-5">
                Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please visit: info@innovationagency.go.ke.
            </p>
        </div>
            </v-card>
            

    </v-container>
</div>
</template>
<script>
export default {
    methods: {
    scrollMeTo(refName) {
        var element = this.$refs[refName];
        var top = element.offsetTop;

        window.scrollTo(0, top);
    }
    }
}
</script>
