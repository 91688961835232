<template>
  <div class="welcome">
    <v-progress-linear
          v-if="investmentFirmLoader"
          height="1"
          indeterminate
          color="primary"
        >
        </v-progress-linear>
    <v-container>
      <v-layout column>
        <v-flex xs12>
          <v-layout row wrap>
            <v-flex xs12 md9 class="mt-5">
              <v-card outlined class="pa-5" style="border-radius: 25px;">
                <div v-if="investmentFirmPagination.total != 0">
                <v-layout column>
                <v-flex xs12>
                  <v-layout row wrap>
                    <v-flex xs12 md4>
                      <v-layout column class="mx-1">
                        <template
                          v-for="(firm, index) in investmentFirmArray[0]"
                        >
                          <v-flex xs12>
                            <v-card
                              :loading="loading"
                              class="mt-2 d-flex flex-column pa-3"
                              style="border-radius: 25px"
                              outlined
                            >
                              <v-layout column>
                                <v-flex xs12>
                                  <v-layout row wrap>
                                    <v-flex xs3>
                                      <div class="pa-2">
                                        <v-avatar
                                          size="48"
                                          v-if="firm.logo == null"
                                        >
                                          <v-img
                                            max-height="150"
                                            src="img/investmentFirm.png"
                                          >
                                          </v-img>
                                        </v-avatar>
                                        <v-avatar size="48" v-else>
                                          <v-img
                                            max-height="150"
                                            :src="
                                              path +
                                              '/storage/investment_firm_pictures/' +
                                              firm.logo
                                            "
                                          >
                                          </v-img>
                                        </v-avatar>
                                      </div>
                                    </v-flex>
                                    <v-flex xs9>
                                      <div class="mt-2">
                                        <a
                                          class="text--text"
                                          @click="goToFirm(firm)"
                                          >{{ firm.name }}</a
                                        >
                                      </div>
                                      <div class="caption">
                                        <i>{{ firm.investor_type.name }}</i> •
                                        <i v-if="firm.county_id != null">{{
                                          firm.county.name
                                        }}</i>
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12>
                                  <div class="mx-3 mt-3">
                                    <v-divider></v-divider>
                                  </div>
                                </v-flex>
                                <v-flex xs12>
                                  <p
                                    class="caption mt-2"
                                    style="font-family: 'poppins' !important"
                                  >
                                    {{ firm.brief }}
                                  </p>
                                </v-flex>
                                <v-flex xs12 v-if="firm.min_investment != null">
                                  <div>
                                    <b><i>Deal Size Range (min-max)</i></b>
                                  </div>
                                  <div
                                    class="caption"
                                    style="font-family: 'poppins' !important"
                                  >
                                    Kshs {{ firm.min_investment }} -
                                    {{ firm.max_investment }}
                                  </div>
                                </v-flex>
                                <v-flex xs12 v-if="firm.total_investments != 0">
                                  <div>
                                    <b><i>Total Investments</i></b>
                                  </div>
                                  <div
                                    class="caption"
                                    style="font-family: 'poppins' !important"
                                  >
                                    {{ firm.total_investments }}
                                  </div>
                                </v-flex>
                                <v-flex xs12 v-if="firm.sum_investments != 0">
                                  <div>
                                    <b><i>Total Investment(s) Value</i></b>
                                  </div>
                                  <div
                                    class="caption"
                                    style="font-family: 'poppins' !important"
                                  >
                                    Kshs {{ firm.sum_investments }}
                                  </div>
                                </v-flex>
                                <v-flex xs12>
                                  <div class="mx-3 mt-3">
                                    <v-divider></v-divider>
                                  </div>
                                </v-flex>
                                <v-flex
                                  xs12
                                  class="mt-2"
                                  v-if="firm.last_investment != null"
                                >
                                  <div>
                                    <b><i>Last Investment</i></b>
                                  </div>
                                </v-flex>
                                <v-flex
                                  xs12
                                  v-if="firm.last_investment != null"
                                  class="mt-1"
                                >
                                  <v-layout row wrap>
                                    <v-flex xs3>
                                      <div class="pa-2 mt-1">
                                        <v-avatar
                                          size="48"
                                          v-if="
                                            firm.last_investment.innovation
                                              .featured_image == null
                                          "
                                        >
                                          <v-img
                                            max-height="150"
                                            src="img/innovation.jpg"
                                          >
                                          </v-img>
                                        </v-avatar>
                                        <v-avatar size="48" v-else>
                                          <v-img
                                            max-height="150"
                                            :src="
                                              path +
                                              '/storage/innovation_pictures/' +
                                              firm.last_investment.innovation
                                                .featured_image
                                            "
                                          >
                                          </v-img>
                                        </v-avatar>
                                      </div>
                                    </v-flex>
                                    <v-flex xs9>
                                      <div class="mt-2">
                                        <a
                                          class="text--text"
                                          @click="
                                            goToInnovation(
                                              firm.last_investment.innovation
                                            )
                                          "
                                          >{{
                                            firm.last_investment.innovation.name
                                          }}</a
                                        >
                                      </div>
                                      <div class="caption">
                                        <i
                                          >Kshs
                                          {{
                                            intToString(
                                              firm.last_investment.amount
                                            )
                                          }}
                                          •
                                          {{
                                            firm.last_investment.funding_type
                                              .name
                                          }}</i
                                        >
                                      </div>
                                      <div
                                        class="caption mb-5 mr-3"
                                        style="
                                          font-family: 'poppins' !important;
                                        "
                                      >
                                        {{
                                          firm.last_investment.innovation.brief
                                        }}
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </v-flex>
                        </template>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-layout column class="mx-1">
                        <template
                          v-for="(firm, index) in investmentFirmArray[1]"
                        >
                          <v-flex xs12>
                            <v-card
                              :loading="loading"
                              class="mt-2 d-flex flex-column pa-3"
                              style="border-radius: 25px"
                              outlined
                            >
                              <v-layout column>
                                <v-flex xs12>
                                  <v-layout row wrap>
                                    <v-flex xs3>
                                      <div class="pa-2">
                                        <v-avatar
                                          size="48"
                                          v-if="firm.logo == null"
                                        >
                                          <v-img
                                            max-height="150"
                                            src="img/investmentFirm.png"
                                          >
                                          </v-img>
                                        </v-avatar>
                                        <v-avatar size="48" v-else>
                                          <v-img
                                            max-height="150"
                                            :src="
                                              path +
                                              '/storage/investment_firm_pictures/' +
                                              firm.logo
                                            "
                                          >
                                          </v-img>
                                        </v-avatar>
                                      </div>
                                    </v-flex>
                                    <v-flex xs9>
                                      <div class="mt-2">
                                        <a
                                          class="text--text"
                                          @click="goToFirm(firm)"
                                          >{{ firm.name }}</a
                                        >
                                      </div>
                                      <div class="caption">
                                        <i>{{ firm.investor_type.name }}</i> •
                                        <i v-if="firm.county_id != null">{{
                                          firm.county.name
                                        }}</i>
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12>
                                  <div class="mx-3 mt-3">
                                    <v-divider></v-divider>
                                  </div>
                                </v-flex>
                                <v-flex xs12>
                                  <p
                                    class="caption mt-2"
                                    style="font-family: 'poppins' !important"
                                  >
                                    {{ firm.brief }}
                                  </p>
                                </v-flex>
                                <v-flex xs12 v-if="firm.min_investment != null">
                                  <div>
                                    <b><i>Deal Size Range (min-max)</i></b>
                                  </div>
                                  <div
                                    class="caption"
                                    style="font-family: 'poppins' !important"
                                  >
                                    Kshs {{ firm.min_investment }} -
                                    {{ firm.max_investment }}
                                  </div>
                                </v-flex>
                                <v-flex xs12 v-if="firm.total_investments != 0">
                                  <div>
                                    <b><i>Total Investments</i></b>
                                  </div>
                                  <div
                                    class="caption"
                                    style="font-family: 'poppins' !important"
                                  >
                                    {{ firm.total_investments }}
                                  </div>
                                </v-flex>
                                <v-flex xs12 v-if="firm.sum_investments != 0">
                                  <div>
                                    <b><i>Total Investment(s) Value</i></b>
                                  </div>
                                  <div
                                    class="caption"
                                    style="font-family: 'poppins' !important"
                                  >
                                    Kshs {{ firm.sum_investments }}
                                  </div>
                                </v-flex>
                                <v-flex xs12>
                                  <div class="mx-3 mt-3">
                                    <v-divider></v-divider>
                                  </div>
                                </v-flex>
                                <v-flex
                                  xs12
                                  class="mt-2"
                                  v-if="firm.last_investment != null"
                                >
                                  <div>
                                    <b><i>Last Investment</i></b>
                                  </div>
                                </v-flex>
                                <v-flex
                                  xs12
                                  v-if="firm.last_investment != null"
                                  class="mt-1"
                                >
                                  <v-layout row wrap>
                                    <v-flex xs3>
                                      <div class="pa-2 mt-1">
                                        <v-avatar
                                          size="48"
                                          v-if="
                                            firm.last_investment.innovation
                                              .featured_image == null
                                          "
                                        >
                                          <v-img
                                            max-height="150"
                                            src="img/investmentFirm.png"
                                          >
                                          </v-img>
                                        </v-avatar>
                                        <v-avatar size="48" v-else>
                                          <v-img
                                            max-height="150"
                                            :src="
                                              path +
                                              '/storage/innovation_pictures/' +
                                              firm.last_investment.innovation
                                                .featured_image
                                            "
                                          >
                                          </v-img>
                                        </v-avatar>
                                      </div>
                                    </v-flex>
                                    <v-flex xs9>
                                      <div class="mt-2">
                                        <a
                                          class="text--text"
                                          @click="
                                            goToInnovation(
                                              firm.last_investment.innovation
                                            )
                                          "
                                          >{{
                                            firm.last_investment.innovation.name
                                          }}</a
                                        >
                                      </div>
                                      <div class="caption">
                                        <i
                                          >Kshs
                                          {{
                                            intToString(
                                              firm.last_investment.amount
                                            )
                                          }}
                                          •
                                          {{
                                            firm.last_investment.funding_type
                                              .name
                                          }}</i
                                        >
                                      </div>
                                      <div
                                        class="caption mb-5 mr-3"
                                        style="
                                          font-family: 'poppins' !important;
                                        "
                                      >
                                        {{
                                          firm.last_investment.innovation.brief
                                        }}
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </v-flex>
                        </template>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-layout column class="mx-1">
                        <template
                          v-for="(firm, index) in investmentFirmArray[2]"
                        >
                          <v-flex xs12>
                            <v-card
                              :loading="loading"
                              class="mt-2 d-flex flex-column pa-3"
                              style="border-radius: 25px"
                              outlined
                            >
                              <v-layout column>
                                <v-flex xs12>
                                  <v-layout row wrap>
                                    <v-flex xs3>
                                      <div class="pa-2">
                                        <v-avatar
                                          size="48"
                                          v-if="firm.logo == null"
                                        >
                                          <v-img
                                            max-height="150"
                                            src="img/investmentFirm.png"
                                          >
                                          </v-img>
                                        </v-avatar>
                                        <v-avatar size="48" v-else>
                                          <v-img
                                            max-height="150"
                                            :src="
                                              path +
                                              '/storage/investment_firm_pictures/' +
                                              firm.logo
                                            "
                                          >
                                          </v-img>
                                        </v-avatar>
                                      </div>
                                    </v-flex>
                                    <v-flex xs9>
                                      <div class="mt-2">
                                        <a
                                          class="text--text"
                                          @click="goToFirm(firm)"
                                          >{{ firm.name }}</a
                                        >
                                      </div>
                                      <div class="caption">
                                        <i>{{ firm.investor_type.name }}</i> •
                                        <i v-if="firm.county_id != null">{{
                                          firm.county.name
                                        }}</i>
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12>
                                  <div class="mx-3 mt-3">
                                    <v-divider></v-divider>
                                  </div>
                                </v-flex>
                                <v-flex xs12>
                                  <p
                                    class="caption mt-2"
                                    style="font-family: 'poppins' !important"
                                  >
                                    {{ firm.brief }}
                                  </p>
                                </v-flex>
                                <v-flex xs12 v-if="firm.min_investment != null">
                                  <div>
                                    <b><i>Deal Size Range (min-max)</i></b>
                                  </div>
                                  <div
                                    class="caption"
                                    style="font-family: 'poppins' !important"
                                  >
                                    Kshs {{ firm.min_investment }} -
                                    {{ firm.max_investment }}
                                  </div>
                                </v-flex>
                                <v-flex xs12 v-if="firm.total_investments != 0">
                                  <div>
                                    <b><i>Total Investments</i></b>
                                  </div>
                                  <div
                                    class="caption"
                                    style="font-family: 'poppins' !important"
                                  >
                                    {{ firm.total_investments }}
                                  </div>
                                </v-flex>
                                <v-flex xs12 v-if="firm.sum_investments != 0">
                                  <div>
                                    <b><i>Total Investment(s) Value</i></b>
                                  </div>
                                  <div
                                    class="caption"
                                    style="font-family: 'poppins' !important"
                                  >
                                    Kshs {{ firm.sum_investments }}
                                  </div>
                                </v-flex>
                                <v-flex xs12>
                                  <div class="mx-3 mt-3">
                                    <v-divider></v-divider>
                                  </div>
                                </v-flex>
                                <v-flex
                                  xs12
                                  class="mt-2"
                                  v-if="firm.last_investment != null"
                                >
                                  <div>
                                    <b><i>Last Investment</i></b>
                                  </div>
                                </v-flex>
                                <v-flex
                                  xs12
                                  v-if="firm.last_investment != null"
                                  class="mt-1"
                                >
                                  <v-layout row wrap>
                                    <v-flex xs3>
                                      <div class="pa-2 mt-1">
                                        <v-avatar
                                          size="48"
                                          v-if="
                                            firm.last_investment.innovation
                                              .featured_image == null
                                          "
                                        >
                                          <v-img
                                            max-height="150"
                                            src="img/investmentFirm.png"
                                          >
                                          </v-img>
                                        </v-avatar>
                                        <v-avatar size="48" v-else>
                                          <v-img
                                            max-height="150"
                                            :src="
                                              path +
                                              '/storage/innovation_pictures/' +
                                              firm.last_investment.innovation
                                                .featured_image
                                            "
                                          >
                                          </v-img>
                                        </v-avatar>
                                      </div>
                                    </v-flex>
                                    <v-flex xs9>
                                      <div class="mt-2">
                                        <a
                                          class="text--text"
                                          @click="
                                            goToInnovation(
                                              firm.last_investment.innovation
                                            )
                                          "
                                          >{{
                                            firm.last_investment.innovation.name
                                          }}</a
                                        >
                                      </div>
                                      <div class="caption">
                                        <i
                                          >Kshs
                                          {{
                                            intToString(
                                              firm.last_investment.amount
                                            )
                                          }}
                                          •
                                          {{
                                            firm.last_investment.funding_type
                                              .name
                                          }}</i
                                        >
                                      </div>
                                      <div
                                        class="caption mb-5 mr-3"
                                        style="
                                          font-family: 'poppins' !important;
                                        "
                                      >
                                        {{
                                          firm.last_investment.innovation.brief
                                        }}
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </v-flex>
                        </template>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                
              </v-layout>
              </div>
              <div v-else class="mx-1 mt-1">
                    <v-card outlined style="border-radius: 25px;">
                      <v-layout row wrap>
                        <v-flex xs12 md4>
                          <div align="center" class="my-10">
                            <v-avatar color="text" size="96">
                              <v-icon class="white--text">
                                mdi-file-document-alert
                              </v-icon>
                            </v-avatar>
                          </div>
                        </v-flex>
                        <v-flex xs12 md8>
                          <v-layout column>
                            <v-flex xs12 class="mt-1">
                              <div align="center" class="mt-15 ml-5 title">
                                No Investment Firm Found..
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </div>
              </v-card>
                  <div align="center" class="my-7" v-if="investmentFirmPagination.total != 0">
                    <v-pagination
                      v-if="investmentFirmLength != 0"
                      :length="investmentFirmLength"
                      total-visible="10"
                      v-model="investmentFirmPagination.current_page"
                      @input="changePage()"
                      circle
                    >
                    </v-pagination>
                  </div>


              
            </v-flex>
            <v-flex xs12 md3>
              <div class="ml-3 mt-6">
                <v-card class="mx-auto pa-1" outlined>
                  <v-layout column>
                    <v-flex xs12>
                      <div class="mt-3 ml-1"><b>Search</b></div>
                    </v-flex>
                    <v-flex xs12>
                      <div class="pa-3 my-2">
                        <v-layout row wrap>
                          <v-flex xs9>
                            <v-text-field
                              v-model="filter.search"
                              class="mx-1 search_field background"
                              outlined
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs2>
                            <div align="right">
                              <v-btn
                                depressed
                                class="primary white--text mx-1"
                                @click="search()"
                                :loading="searchLoading"
                              >
                                <v-icon> mdi-magnify </v-icon>
                              </v-btn>
                            </div>
                          </v-flex>
                        </v-layout>
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <div class="pa-1 mt-2">
                        <div class="mb-2"><b>Investor Type</b></div>
                        <v-select
                          :items="allInvestorTypes"
                          item-text="name"
                          item-value="id"
                          v-model="filter.investor_type_id"
                          class="search_field background"
                          dense
                          outlined
                          height="10"
                          @input="search()"
                        >
                        </v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <div class="pa-1 mt-2">
                        <div class="mb-2"><b>County</b></div>
                        <v-select
                          :items="allCounties"
                          item-text="name"
                          item-value="id"
                          v-model="filter.county_id"
                          class="search_field background"
                          dense
                          outlined
                          height="10"
                          @input="search()"
                        >
                        </v-select>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>

                <v-btn
                  depressed
                  block
                  class="primary text-none mt-5"
                  @click="reset()"
                  :loading="resetLoading"
                  >Reset 
                    <v-icon right>mdi-undo</v-icon>
                  </v-btn
                >
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      loading: false,
      searchLoading: false,
      resetLoading: false,
      filteredInvestmentFirms: false,

      investmentFirmArray: [],
      inputRules: [(v) => !!v || "Input is required"],

      filter: {
        search: null,
        investor_type_id: null,
        county_id: null,
      },
    };
  },
  watch: {
    // investmentFirms() {
    //   this.chunkInvestmentFirms();
    // },
  },
  mounted() {},
  created() {
    window.scrollTo(0, 0);
    this.initialize();
  },
  methods: {
    ...mapActions([
      "fetchInvestmentFirms",
      "filterInvestmentFirms",
      "fetchAllInvestorTypes",
      "fetchAllCounties",
      "startInvestmentFirmLoader",
      "stopInvestmentFirmLoader",
    ]),
    initialize() {
      // this.fetchInvestmentFirms(this.investmentFirmPagination.current_page);
      this.changePage()
      this.fetchAllInvestorTypes();
      this.fetchAllCounties();
      this.startInvestmentFirmLoader();
      this.chunkInvestmentFirms();
    },
    goToInnovation(innovation) {
      this.$router.push("/innovations/" + innovation.slug);
    },
    goToFirm(firm) {
      this.$router.push("/investors/" + firm.slug);
    },
    intToString(value) {
      var suffixes = ["", "k", "M", "B", "T"];
      var suffixNum = Math.floor(("" + value).length / 3);
      var shortValue = parseFloat(
        (suffixNum != 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(2)
      );
      if (shortValue % 1 != 0) {
        shortValue = shortValue.toFixed(1);
      }
      return shortValue + suffixes[suffixNum];
    },
    reset() {
      this.resetLoading = true;
      apiCall({
        url: "/api/investment-firm?type=public&page=1",
        method: "GET",
      })
        .then((resp) => {
          this.filter.search = null;
          this.filter.investor_type_id = null;
          this.filter.county_id = null;

          this.loading = false;
          this.resetLoading = false;
          this.filteredInvestmentFirms = true;
          this.filterInvestmentFirms(resp);
          this.chunkInvestmentFirms();
        })
        .catch((error) => {
          this.message = "An Error Occurred; Please Try Again";
          this.color = "error";
          this.loading = false;
          this.resetLoading = false;
          this.snackbar = true;
        });
    },
    search() {
      this.searchLoading = true;
      apiCall({
        url: "/api/investment-firm?type=filter&page=1",
        data: this.filter,
        method: "POST",
      })
        .then((resp) => {
          this.loading = false;
          this.searchLoading = false;
          this.filteredInvestmentFirms = true;
          this.filterInvestmentFirms(resp);
          this.chunkInvestmentFirms();
        })
        .catch((error) => {
          this.message = "An Error Occurred; Please Try Again";
          this.color = "error";
          this.loading = false;
          this.searchLoading = false;
          this.snackbar = true;
        });
    },
    chunkInvestmentFirms() {
      this.investmentFirmArray = [];

      var originalArr = [];
      for (var i = 0; i < this.investmentFirms.length; i++) {
        originalArr.push(this.investmentFirms[i])
      }

      let result = [];
      for (let i = 3; i > 0; i--) {
        result.push(originalArr.splice(0, Math.ceil(originalArr.length / i)));
      }
      this.investmentFirmArray = result;
    },

    changePage() {
      window.scrollTo(0, 0);
      this.startInvestmentFirmLoader();
      if (this.filteredInvestmentFirms == true) {
        apiCall({
          url:
            "/api/investment-firm?type=filter&page=" +
            this.investmentFirmPagination.current_page,
          data: this.filter,
          method: "POST",
        })
          .then((resp) => {
            this.loading = false;
            this.searchLoading = false;
            this.filteredInvestmentFirms = true;
            this.filterInvestmentFirms(resp);
            this.chunkInvestmentFirms();
            this.stopInvestmentFirmLoader()
          })
          .catch((error) => {
            this.message = "An Error Occurred; Please Try Again";
            this.color = "error";
            this.loading = false;
            this.searchLoading = false;
            this.snackbar = true;
          });
      } else {
        // this.fetchInvestmentFirms(this.investmentFirmPagination.current_page);
        // this.chunkInvestmentFirms();
        apiCall({
          url:
            "/api/investment-firm?type=public&page=" +
            this.investmentFirmPagination.current_page,
          method: "GET",
        })
          .then((resp) => {
            this.loading = false;
            this.searchLoading = false;
            this.filterInvestmentFirms(resp);
            this.chunkInvestmentFirms();
            this.stopInvestmentFirmLoader()
          })
          .catch((error) => {
            this.message = "An Error Occurred; Please Try Again";
            this.color = "error";
            this.loading = false;
            this.searchLoading = false;
            this.snackbar = true;
          });
      }
    },
    goToInstitution(institution) {
      this.$router.push("/institutions/" + institution.slug);
    },
  },
  computed: {
    ...mapGetters([
      "allInvestorTypes",
      "allCounties",

      "investmentFirms",
      "investmentFirmLoader",
      "investmentFirmPagination",
    ]),
    investmentFirmLength: function () {
      return Math.ceil(
        this.investmentFirmPagination.total /
          this.investmentFirmPagination.per_page
      );
    },
  },
};
</script>
