<template>
  <div class="welcome">
    <v-layout column>
      <v-flex xs12>
        <v-container class="mt-2">
          <v-layout row wrap>
            <v-flex xs12 md8 class="my-10">
              <v-card class="pa-16 mx-2" elevation="0">
                <div class="font-weight-black display-1 mb-3">
                  Kenya Innovation Bridge
                </div>
                <p class="grey--text mt-5">
                  Kenya Innovation Bridge helps in the discoverability of
                  innovations in Kenya. The platform is a marketplace that
                  enables innovators, inventors, researchers and startups to
                  introduce their solutions to partners, funders, customers and
                  users. The goal is to get as many innovations scale by
                  attracting appropriate funding and partnerships.
                </p>
                <v-btn
                  v-if="!isAuthenticated"
                  depressed
                  class="secondary primary--text mt-4 text-none"
                  @click="changeLoginCardState"
                >
                  Get Started
                </v-btn>
              </v-card>
            </v-flex>
            <v-flex xs12 md4 class="my-10">
              <div class="pa-3">
                <v-layout row wrap>
                  <v-flex xs12 md6>
                    <v-card elevation="0" class="primary mx-2 mb-4">
                      <v-layout column>
                        <v-flex xs12>
                          <div align="center">
                            <v-icon large class="white--text mt-7"
                              >mdi-spa-outline</v-icon
                            >
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="white--text my-5">
                            Innovations
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="white--text mb-7">
                            <b>{{
                              quickStats["total_innovations"] | formatNumber
                            }}</b>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-card elevation="0" class="mx-2 mb-4">
                      <v-layout column>
                        <v-flex xs12>
                          <div align="center">
                            <v-icon large class="text--text mt-7"
                              >mdi-office-building-outline</v-icon
                            >
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="my-5">Institutions</div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="text--text mb-7">
                            <b>{{
                              quickStats["total_institutions"] | formatNumber
                            }}</b>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>

                  <v-flex xs12 md6>
                    <v-card elevation="0" class="mx-2 mb-4">
                      <v-layout column>
                        <v-flex xs12>
                          <div align="center">
                            <v-icon large class="text--text mt-7"
                              >mdi-briefcase-outline</v-icon
                            >
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="my-5">Organisations</div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="text--text mb-7">
                            <b>{{
                              quickStats["total_organisations"] | formatNumber
                            }}</b>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-card elevation="0" class="mx-2 mb-4">
                      <v-layout column>
                        <v-flex xs12>
                          <div align="center">
                            <v-icon large class="text--text mt-7"
                              >mdi-account-multiple-outline</v-icon
                            >
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="my-5">Users</div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="text--text mb-7">
                            <b>{{
                              quickStats["total_users"] | formatNumber
                            }}</b>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
      <v-flex xs12>
        <v-card elevation="0">
          <v-container>
            <v-layout column>
              <v-flex xs12>
                <v-layout row wrap>
              <v-flex xs12 md8>
                <div class="mt-14 mx-3">
                  <highcharts
                    v-if="showMap"
                    :constructorType="'mapChart'"
                    class="hc grey"
                    :options="chartOptions"
                    ref="chart"
                    @click="onUpdate"
                  ></highcharts>
                </div>
              </v-flex>
              <v-flex xs12 md4>
                <div class="mx-2">
                  <v-layout column>
                  <v-flex xs12 class="mt-14">
                    <div v-if="countyStats.length != 0">
                      <v-card outlined>
                        <v-card-title class="background">
                          <b>{{ innovationLocation }}</b>
                        </v-card-title>
                        <v-card-text>
                          <div class="pa-3 mt-5">
                            <v-layout row wrap>
                              <v-flex xs6>
                                <div>No. of Innovations</div>
                                <div class="title">
                                  {{ countyStats.innovation_count }}
                                </div>
                              </v-flex>
                              <v-flex xs6>
                                <div>No. of Institutions</div>
                                <div class="title">
                                  {{ countyStats.institution_count }}
                                </div>
                              </v-flex>
                              <v-flex xs6>
                                <div class="mt-3">No. of Organisations</div>
                                <div class="title">
                                  {{ countyStats.organisation_count }}
                                </div>
                              </v-flex>
                              <v-flex xs6>
                                <div class="mt-3">No. of Investment Firms</div>
                                <div class="title">
                                  {{ countyStats.investment_firm_count }}
                                </div>
                              </v-flex>
                              <!-- <v-flex xs6>
                                <div class="mt-3">
                                  No. of Open Funding Opportunities
                                </div>
                                <div class="title">
                                  {{ countyStats.ask_count }}
                                </div>
                              </v-flex>
                              <v-flex xs6>
                                <div class="mt-3">
                                  No. of Open Funding Requests
                                </div>
                                <div class="title">
                                  {{ countyStats.ask_count }}
                                </div>
                              </v-flex> -->
                              <v-flex xs12>
                                <div class="mx-5">
                                  <v-divider class="my-4"></v-divider>
                                </div>
                              </v-flex>
                              <v-flex xs12>
                                <v-tabs grow v-model="tab">
                                  <v-tab
                                    key="Innovations"
                                    @click="switchTab('Innovations')"
                                  >
                                    <v-icon left> mdi-spa-outline </v-icon>
                                    <div class="text-none">Innovations</div>
                                  </v-tab>
                                  <v-tab
                                    key="Institutions"
                                    @click="switchTab('Institutions')"
                                  >
                                    <v-icon left>
                                      mdi-office-building-outline
                                    </v-icon>
                                    <div class="text-none">Institutions</div>
                                  </v-tab>
                                  <v-tab
                                    key="Organisations"
                                    @click="switchTab('Organisations')"
                                  >
                                    <v-icon left>
                                      mdi-briefcase-outline
                                    </v-icon>
                                    <div class="text-none">Organisations</div>
                                  </v-tab>
                                </v-tabs>
                              </v-flex>
                              
                              <v-flex xs12 class="mt-2">
                                <div v-if="view == 'Innovations'">
                                  <div
                                    v-if="countyStats.innovations.length != 0"
                                    class="mt-5"
                                  >
                                    <template
                                      v-for="(
                                        innovation, index
                                      ) in countyStats.innovations"
                                    >
                                      <div :key="index">
                                        <v-flex xs12 class="mb-4">
                                          <a v-if="!isAuthenticated" @click="changeLoginCardState" class="text--text" style="text-decoration: none;">
                                           <v-layout row wrap>
                                            <v-flex xs2>
                                              <div align="center"> 
                                                <v-avatar size="48" v-if="innovation.logo==null">
                                                  <v-img
                                                    max-height="150"
                                                    
                                                    src="img/innovation.jpg"
                                                  >
                                                  </v-img>
                                                </v-avatar>
                                                <v-avatar size="48" v-else>
                                                  <v-img
                                                      max-height="150"
                                                      :src="path+'/storage/innovation_pictures/'+innovation.logo"
                                                    >
                                                    </v-img>
                                                </v-avatar>
                                              </div>
                                            </v-flex>
                                            <v-flex xs10>
                                                <v-layout column>
                                                  <v-flex xs12>
                                                    <div class="mt-3">
                                                      {{
                                                        innovation.name
                                                      }}
                                                    </div>
                                                    
                                                  </v-flex>
                                                </v-layout>
                                            </v-flex>
                                           </v-layout>
                                          </a>  
                                          <router-link v-if="isAuthenticated" router :to="'/innovations/' + innovation.slug"  class="text--text" style="text-decoration: none;">
                                           <v-layout row wrap>
                                            <v-flex xs2>
                                              <div align="center"> 
                                                <v-avatar size="48" v-if="innovation.logo==null">
                                                  <v-img
                                                    max-height="150"
                                                    
                                                    src="img/innovation.jpg"
                                                  >
                                                  </v-img>
                                                </v-avatar>
                                                <v-avatar size="48" v-else>
                                                  <v-img
                                                      max-height="150"
                                                      :src="path+'/storage/innovation_pictures/'+innovation.logo"
                                                    >
                                                    </v-img>
                                                </v-avatar>
                                              </div>
                                            </v-flex>
                                            <v-flex xs10>
                                                <v-layout column>
                                                  <v-flex xs12>
                                                    <div class="mt-3">
                                                      {{
                                                        innovation.name
                                                      }}
                                                    </div>
                                                    
                                                  </v-flex>
                                                </v-layout>
                                            </v-flex>
                                           </v-layout>
                                          </router-link>  
                                        </v-flex>
                                      </div>
                                    </template>
                                  </div>
                                  <div v-else class="pa-5">
                                    <v-flex xs12>
                                      <v-card
                                        elevation="0"
                                        router
                                        style="border-radius: 25px"
                                      >
                                        <v-card-text>
                                          <v-layout row wrap>
                                            <v-flex xs2>
                                              <div align="center" class="my-2">
                                                <v-avatar
                                                  color="primary"
                                                  size="50"
                                                >
                                                  <v-icon class="white--text">
                                                    mdi-file-document-alert
                                                  </v-icon>
                                                </v-avatar>
                                              </div>
                                            </v-flex>
                                            <v-flex xs10>
                                              <v-layout column>
                                                <v-flex xs12 class="mt-1">
                                                  <div class="mt-4" align="center">
                                                    No Innovations from
                                                    {{ innovationLocation }}
                                                    just yet..
                                                  </div>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                          </v-layout>
                                        </v-card-text>
                                      </v-card>
                                    </v-flex>
                                  </div>
                                </div>
                                <div v-if="view == 'Institutions'">
                                  <div
                                    v-if="countyStats.institutions.length != 0"
                                    class="mt-5"
                                  >
                                    <template
                                      v-for="(
                                        institution, index
                                      ) in countyStats.institutions"
                                    >
                                      <div :key="index">
                                        <v-flex xs12 class="mb-4">
                                          <a v-if="!isAuthenticated" @click="changeLoginCardState" class="text--text" style="text-decoration: none;">
                                           <v-layout row wrap>
                                            <v-flex xs2>
                                              <div align="center">
                                <v-avatar size="48" v-if="institution.logo==null">
                                  <v-img
                                    max-height="150"
                                    
                                    src="img/institution.png"
                                  >
                                  </v-img>
                                </v-avatar>
                                <v-avatar size="48" v-else>
                                  <v-img
                                      max-height="150"
                                      :src="path+'/storage/institution_pictures/'+institution.logo"
                                    >
                                    </v-img>
                                </v-avatar>
                                              </div>
                                            </v-flex>
                                            <v-flex xs10>
                                                <v-layout column>
                                                  <v-flex xs12>
                                                    <div class="mt-3">
                                                      {{
                                                        institution.name
                                                      }}
                                                    </div>
                                                    
                                                  </v-flex>
                                                </v-layout>
                                            </v-flex>
                                           </v-layout>
                                          </a>
                                          <router-link v-if="isAuthenticated" router :to="'/institutions/' + institution.slug" class="text--text" style="text-decoration: none;">
                                           <v-layout row wrap>
                                            <v-flex xs2>
                                              <div align="center">
                                <v-avatar size="48" v-if="institution.logo==null">
                                  <v-img
                                    max-height="150"
                                    
                                    src="img/institution.png"
                                  >
                                  </v-img>
                                </v-avatar>
                                <v-avatar size="48" v-else>
                                  <v-img
                                      max-height="150"
                                      :src="path+'/storage/institution_pictures/'+institution.logo"
                                    >
                                    </v-img>
                                </v-avatar>
                                              </div>
                                            </v-flex>
                                            <v-flex xs10>
                                                <v-layout column>
                                                  <v-flex xs12>
                                                    <div class="mt-3">
                                                      {{
                                                        institution.name
                                                      }}
                                                    </div>
                                                    
                                                  </v-flex>
                                                </v-layout>
                                            </v-flex>
                                           </v-layout>
                                          </router-link>
                                        </v-flex>
                                      </div>
                                    </template>
                                  </div>
                                  <div v-else class="pa-5">
                                    <v-flex xs12>
                                      <v-card
                                        elevation="0"
                                        router
                                        style="border-radius: 25px"
                                      >
                                        <v-card-text>
                                          <v-layout row wrap>
                                            <v-flex xs2>
                                              <div align="center" class="my-2">
                                                <v-avatar
                                                  color="primary"
                                                  size="50"
                                                >
                                                  <v-icon class="white--text">
                                                    mdi-file-document-alert
                                                  </v-icon>
                                                </v-avatar>
                                              </div>
                                            </v-flex>
                                            <v-flex xs10>
                                              <v-layout column>
                                                <v-flex xs12 class="mt-1">
                                                  <div class="mt-1">
                                                    No Institutions from
                                                    {{ innovationLocation }}
                                                    just yet..
                                                  </div>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                          </v-layout>
                                        </v-card-text>
                                      </v-card>
                                    </v-flex>
                                  </div>
                                </div>
                                <div v-if="view == 'Organisations'">
                                  <div
                                    v-if="countyStats.organisations.length != 0"
                                    class="mt-5"
                                  >
                                    <template
                                      v-for="(
                                        organisation, index
                                      ) in countyStats.organisations"
                                    >
                                      <div :key="index">
                                        <v-flex xs12 class="mb-4">
                                          <a v-if="!isAuthenticated" @click="changeLoginCardState" class="text--text" style="text-decoration: none;">
                                            <v-layout row wrap>
                                            <v-flex xs2>
                                              <div align="center">
                                                <v-avatar size="48" v-if="organisation.logo==null">
                                                  <v-img
                                                    max-height="150"
                                                    
                                                    src="img/organisation.png"
                                                  >
                                                  </v-img>
                                                </v-avatar>
                                                <v-avatar size="48" v-else>
                                                  <v-img
                                                      max-height="150"
                                                      :src="path+'/storage/organisation_pictures/'+organisation.logo"
                                                    >
                                                    </v-img>
                                                </v-avatar>
                                              </div>
                                            </v-flex>
                                            <v-flex xs10>
                                                <v-layout column>
                                                  <v-flex xs12>
                                                    <div class="mt-3">
                                                      {{
                                                        organisation.name
                                                      }}
                                                    </div>
                                                    
                                                  </v-flex>
                                                </v-layout>
                                            </v-flex>
                                           </v-layout>
                                          </a>
                                          <router-link v-if="isAuthenticated" router :to="'/organisations/' + organisation.slug" class="text--text" style="text-decoration: none;">
                                            <v-layout row wrap>
                                            <v-flex xs2>
                                              <div align="center">
                                                <v-avatar size="48" v-if="organisation.logo==null">
                                                  <v-img
                                                    max-height="150"
                                                    
                                                    src="img/organisation.png"
                                                  >
                                                  </v-img>
                                                </v-avatar>
                                                <v-avatar size="48" v-else>
                                                  <v-img
                                                      max-height="150"
                                                      :src="path+'/storage/organisation_pictures/'+organisation.logo"
                                                    >
                                                    </v-img>
                                                </v-avatar>
                                              </div>
                                            </v-flex>
                                            <v-flex xs10>
                                                <v-layout column>
                                                  <v-flex xs12>
                                                    <div class="mt-3">
                                                      {{
                                                        organisation.name
                                                      }}
                                                    </div>
                                                    
                                                  </v-flex>
                                                </v-layout>
                                            </v-flex>
                                           </v-layout>
                                          </router-link>
                                              
                                        </v-flex>
                                      </div>
                                    </template>
                                  </div>
                                  <div v-else class="pa-5">
                                    <v-flex xs12>
                                      <v-card
                                        elevation="0"
                                        router
                                        style="border-radius: 25px"
                                      >
                                        <v-card-text>
                                          <v-layout row wrap>
                                            <v-flex xs2>
                                              <div align="center" class="my-2">
                                                <v-avatar
                                                  color="primary"
                                                  size="50"
                                                >
                                                  <v-icon class="white--text">
                                                    mdi-file-document-alert
                                                  </v-icon>
                                                </v-avatar>
                                              </div>
                                            </v-flex>
                                            <v-flex xs10>
                                              <v-layout column>
                                                <v-flex xs12 class="mt-1">
                                                  <div class="mt-4" align="center">
                                                    No Organisations from
                                                    {{ innovationLocation }}
                                                    just yet..
                                                  </div>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                          </v-layout>
                                        </v-card-text>
                                      </v-card>
                                    </v-flex>
                                  </div>
                                </div>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-flex>
                </v-layout>
                </div>
                
              </v-flex>
            </v-layout>
              </v-flex>
              <v-flex xs12 class="mt-10">
                <v-container>
                  <v-carousel hide-delimiters>
                    <v-carousel-item>
                      <v-img
                        src="img/KIB_1.jpg"
                      >
                      </v-img>
                    </v-carousel-item>
                    <v-carousel-item>
                      <v-img
                        src="img/KIB_2.jpg"
                      >
                      </v-img>
                    </v-carousel-item>
                  </v-carousel>
                </v-container>
              </v-flex>
            </v-layout>           
          </v-container>
        </v-card>
      </v-flex>
      
      <!-- <v-flex xs12 class="primary">
        <v-container>
          <div
            align="center"
            class="my-5 secondary--text title font-italic ml-2"
          >
            <v-icon class="white--text"> mdi-format-quote-open </v-icon>
            Tagline
            <v-icon class="white--text"> mdi-format-quote-close </v-icon>
          </div>
        </v-container>
      </v-flex> -->
      
      <!-- <v-flex xs12 class="background">
        <v-layout row wrap>
            <v-flex xs12 md4>

            </v-flex>
            <v-flex xs12 md4>
                
            </v-flex>
            <v-flex xs12 md4>
                <div class="my-15">
                    <a class="twitter-timeline" data-width="400" data-height="500" data-theme="light" href="https://twitter.com/KEinnovbridge?ref_src=twsrc%5Etfw">Tweets by KEinnovbridge</a>
                </div>
            </v-flex>
        </v-layout>
      </v-flex> -->

      <v-flex xs12 class="background">
        <v-card elevation="0" class="background">
          <v-layout column>
            <v-flex xs12>
              <div align="center" class="display-1 my-5">
                <b>Partners</b>
              </div>
              <div align="center">
                <v-layout row wrap>
                  <v-flex xs12>
                    <div align="center" class="mb-10">
                      <v-img max-width="200" src="img/logo_ukaid.jpg"> </v-img>
                    </div>
                  </v-flex>
                  <!-- <v-flex xs6>
                                            
                                        </v-flex> -->
                </v-layout>
              </div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <AuthPopUp v-model="loginCardState" />
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import AuthPopUp from "@/components/auth/AuthPopUp";
import { mapState, mapGetters, mapActions } from "vuex";
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import Maps from "highcharts/modules/map";
import boost from "highcharts/modules/boost";
import stockInit from "highcharts/modules/stock";
import Vue from "vue";
import kenyaMap from "@/assets/kenya.geo.json";
import VClamp from "vue-clamp";

Maps(Highcharts);
Highcharts.setOptions({
  global: {
    useUTC: false,
  },
});
stockInit(Highcharts);
boost(Highcharts);
Vue.use(HighchartsVue, {
  highcharts: Highcharts,
});

export default {
  components: {
    AuthPopUp,
    VClamp,
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      drawer: false,
      showMap: true,
      view: "Innovations",

      quickStats: {},

      chartOptions: {
        chart: {
          height: 850,
          backgroundColor: "#F5F5F5",
          map: kenyaMap,
        },
        title: {
          text: "County Innovation Distribution",
        },
        subtitle: {
          //   text: 'Source map: <a href="http://code.highcharts.com/mapdata/custom/world.js">World, Miller projection, medium resolution</a>'
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            alignTo: "spacingBox",
          },
        },
        colorAxis: {
          min: 0,
          minColor: "#F7F7F7",
          maxColor: "#880000",
        },
        testData: {},
        series: [
          {
            events: {
              click: (e) => {
                this.onUpdate(e);
              },
              //   click: function (e) {
              //     console.log("EEEE", e.point.COUNTY);
              //     point = "e.point.COUNTY";
              //   },
            },
            joinBy: "COUNTY",
            keys: ["COUNTY", "value"],
            name: "Number of Innovations",
            states: {
              hover: {
                color: "#880000",
              },
            },
            // dataLabels: {
            //   enabled: true,
            //   format: "{point.COUNTY}",
            // },
            data: [],
          },
        ],
        mapNavigation: {
          enableMouseWheelZoom: false,
        },
      },
      countyStats: [],
      innovationLocation: "Nairobi",
    };
  },
  watch: {
    darkState() {
      this.handledarkmode();
    },
  },
  mounted() {
    const something = (HighmapsEventObject) => {
      console.log("hi");
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.getHomeData();
    this.handledarkmode()
  },
  methods: {
    ...mapActions(["changeLoginCardState"]),
    handledarkmode() {
      if (this.darkState == true) {
        this.chartOptions.chart.backgroundColor = "#121212";
      } else {
        this.chartOptions.chart.backgroundColor = "#F5F5F5";
      }
    },
    onUpdate(location) {
      console.log("location", location.point.COUNTY);
      this.innovationLocation = location.point.COUNTY;

      for (var i = 0; i < this.quickStats.county_stats.length; i++) {
        if (this.quickStats.county_stats[i][0] == this.innovationLocation) {
          this.countyStats = this.quickStats.county_stats[i][1];
        }
      }
    },
    getHomeData() {
      apiCall({
        url: "/api/home",
        method: "GET",
      })
        .then((resp) => {
          this.quickStats = resp;
          this.chartOptions.series[0].data = resp.mapData;
          this.showMap = true;

          for (var i = 0; i < resp.county_stats.length; i++) {
            if (resp.county_stats[i][0] == "Nairobi") {
              this.countyStats = resp.county_stats[i][1];
            }
          }
          console.log("this.countyStats", this.countyStats);
        })
        .catch((error) => {});
    },
    switchTab(tab) {
      this.view = tab;
    },
  },
  computed: {
    ...mapGetters(["loginCardState", "darkState", "isAuthenticated"]),
  },
};
</script>
