import apiCall from '@/utils/api'

const state = {
	connections: [],
    pendingConnections: [],
    deniedConnections: [],
    blockedConnections: [],
	awaitingConnections: [],

    connectionPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    pendingConnectionPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    deniedConnectionPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    blockedConnectionPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	awaitingConnectionPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},

    connectionLoader: false,
    pendingConnectionLoader: false,
    deniedConnectionLoader: false,
    blockedConnectionLoader: false,
	awaitingConnectionLoader: false,
};

const getters = {
	connections: (state) => state.connections,
	connectionLoader: (state) => state.connectionLoader,	
    connectionPagination: (state) => state.connectionPagination,

	pendingConnections: (state) => state.pendingConnections,
	pendingConnectionLoader: (state) => state.pendingConnectionLoader,
	pendingConnectionPagination: (state) => state.pendingConnectionPagination,

	deniedConnections: (state) => state.deniedConnections,
	deniedConnectionLoader: (state) => state.deniedConnectionLoader,
	deniedConnectionPagination: (state) => state.deniedConnectionPagination,

	blockedConnections: (state) => state.blockedConnections,
	blockedConnectionLoader: (state) => state.blockedConnectionLoader,
	blockedConnectionPagination: (state) => state.blockedConnectionPagination,   
	
	awaitingConnections: (state) => state.awaitingConnections,
	awaitingConnectionLoader: (state) => state.awaitingConnectionLoader,
	awaitingConnectionPagination: (state) => state.awaitingConnectionPagination,   
}

const actions = {
    async fetchConnections({commit}, page) {
		const response = await apiCall({url: `/api/get-friends?page=${page}`, method: 'GET' });
		commit('setConnections', response)
        commit('stopConnectionLoader', response)
	},
    async fetchPendingConnections({commit}, page) {
		const response = await apiCall({url: `/api/get-pending-friendships?page=${page}`, method: 'GET' });
		commit('setPendingConnections', response)
        commit('stopPendingConnectionLoader', response)
	},
    async fetchDeniedConnections({commit}, page) {
		const response = await apiCall({url: `/api/get-denied-friendships?page=${page}`, method: 'GET' });
		commit('setDeniedConnections', response)
        commit('stopDeniedConnectionLoader', response)
	},
    async fetchBlockedConnections({commit}, page) {
		const response = await apiCall({url: `/api/get-blocked-friendships?page=${page}`, method: 'GET' });
		commit('setBlockedConnections', response)
        commit('stopBlockedConnectionLoader', response)
	},
	async fetchAwaitingConnections({commit}, page) {
		const response = await apiCall({url: `/api/get-awaiting-friendships?page=${page}`, method: 'GET' });
		commit('setAwaitingConnections', response)
        commit('stopAwaitingConnectionLoader', response)
	},

    async filterConnections({commit, state},resp){
		commit('setConnections', resp)
	},
    async filterPendingConnections({commit, state},resp){
		commit('setPendingConnections', resp)
	},
    async filterDeniedConnections({commit, state},resp){
		commit('setDeniedConnections', resp)
	},
    async filterBlockedConnections({commit, state},resp){
		commit('setBlockedConnections', resp)
	},
	async filterAwaitingConnections({commit, state},resp){
		commit('setAwaitingConnections', resp)
	},

	async startConnectionLoader({commit, state},resp){
		commit('startConnectionLoader', resp)
	},
	async stopConnectionLoader({commit, state},resp){
		commit('stopConnectionLoader', resp)
	},
    async startPendingConnectionLoader({commit, state},resp){
		commit('startPendingConnectionLoader', resp)
	},
	async stopPendingConnectionLoader({commit, state},resp){
		commit('stopPendingConnectionLoader', resp)
	},
    async startDeniedConnectionLoader({commit, state},resp){
		commit('startDeniedConnectionLoader', resp)
	},
	async stopDeniedConnectionLoader({commit, state},resp){
		commit('stopDeniedConnectionLoader', resp)
	},
    async startBlockedConnectionLoader({commit, state},resp){
		commit('startBlockedConnectionLoader', resp)
	},
	async stopBlockedConnectionLoader({commit, state},resp){
		commit('stopBlockedConnectionLoader', resp)
	},
	async startAwaitingConnectionLoader({commit, state},resp){
		commit('startAwaitingConnectionLoader', resp)
	},
	async stopAwaitingConnectionLoader({commit, state},resp){
		commit('stopAwaitingConnectionLoader', resp)
	},
};

const mutations = {
	setConnections: (state, connections) => {
		state.connections = connections.data
		state.connectionPagination.current_page = connections.current_page
		state.connectionPagination.total = connections.total
		state.connectionPagination.per_page = connections.per_page
	},
    startConnectionLoader: (state) => state.connectionLoader = true,
	stopConnectionLoader: (state) => state.connectionLoader = false,

    setPendingConnections: (state, connections) => {
		state.pendingConnections = connections.data
		state.pendingConnectionPagination.current_page = connections.current_page
		state.pendingConnectionPagination.total = connections.total
		state.pendingConnectionPagination.per_page = connections.per_page
	},
    startPendingConnectionLoader: (state) => state.pendingConnectionLoader = true,
	stopPendingConnectionLoader: (state) => state.pendingConnectionLoader = false,

    setDeniedConnections: (state, connections) => {
		state.deniedConnections = connections.data
		state.deniedConnectionPagination.current_page = connections.current_page
		state.deniedConnectionPagination.total = connections.total
		state.deniedConnectionPagination.per_page = connections.per_page
	},
    startDeniedConnectionLoader: (state) => state.deniedConnectionLoader = true,
	stopDeniedConnectionLoader: (state) => state.deniedConnectionLoader = false,

    setBlockedConnections: (state, connections) => {
		state.blockedConnections = connections.data
		state.blockedConnectionPagination.current_page = connections.current_page
		state.blockedConnectionPagination.total = connections.total
		state.blockedConnectionPagination.per_page = connections.per_page
	},
    startBlockedConnectionLoader: (state) => state.blockedConnectionLoader = true,
	stopBlockedConnectionLoader: (state) => state.blockedConnectionLoader = false,

	setAwaitingConnections: (state, connections) => {
		state.awaitingConnections = connections.data
		state.awaitingConnectionPagination.current_page = connections.current_page
		state.awaitingConnectionPagination.total = connections.total
		state.awaitingConnectionPagination.per_page = connections.per_page
	},
    startAwaitingConnectionLoader: (state) => state.awaitingConnectionLoader = true,
	stopAwaitingConnectionLoader: (state) => state.awaitingConnectionLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
