import apiCall from '@/utils/api'

const state = {
	interests: [],
	allInterests: [],
    interestPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    interestLoader: false
};

const getters = {
	interests: (state) => state.interests,
	allInterests: (state) => state.allInterests,
    interestLoader: (state) => state.interestLoader,
    interestPagination: (state) => state.interestPagination
}

const actions = {
	async fetchAllInterests({commit}, page) {
		const response = await apiCall({url: `/api/interest?type=all`, method: 'GET' });
		commit('setAllInterests', response)
        commit('stopLoader', response)
	},
    async fetchInterests({commit}, page) {
		const response = await apiCall({url: `/api/interest?page=${page}`, method: 'GET' });
		commit('setInterests', response)
        commit('stopLoader', response)
	},
    async filterInterests({commit, state},resp){
		commit('setInterests', resp)
	},
	async startInterestLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopInterestLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setInterests: (state, interests) => {
		state.interests = interests.data
		state.interestPagination.current_page = interests.current_page
		state.interestPagination.total = interests.total
		state.interestPagination.per_page = interests.per_page
	},
	setAllInterests: (state, interests) => {
		state.allInterests = interests
	},
    startLoader: (state) => state.interestLoader = true,
	stopLoader: (state) => state.interestLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
