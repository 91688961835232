import apiCall from '@/utils/api'

const state = {
	investments: [],
    investmentPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    investmentLoader: false
};

const getters = {
	investments: (state) => state.investments,
    investmentLoader: (state) => state.investmentLoader,
    investmentPagination: (state) => state.investmentPagination
}

const actions = {
    async fetchInvestments({commit}, page) {
		const response = await apiCall({url: `/api/investment?type=userInvestments&page=${page}`, method: 'GET' });
		commit('setInvestments', response)
        commit('stopLoader', response)
	},
    async filterInvestments({commit, state},resp){
		commit('setInvestments', resp)
	},
	async startInvestmentLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopInvestmentLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setInvestments: (state, investments) => {
		state.investments = investments.data
		state.investmentPagination.current_page = investments.current_page
		state.investmentPagination.total = investments.total
		state.investmentPagination.per_page = investments.per_page
	},
    startLoader: (state) => state.investmentLoader = true,
	stopLoader: (state) => state.investmentLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
