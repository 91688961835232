<template>
    <div class="welcome">
      <v-progress-linear
        v-if="esoLoader"
        height="1"
        indeterminate
        color="primary"
      >
      </v-progress-linear>
      <v-container>
        <v-layout column>
          <v-flex xs12>
            <v-layout row wrap>
              <v-flex xs12 md9 class="mt-5">
                <v-card class="pa-5" outlined style="border-radius: 25px">
                  <div v-if="publicEsoPagination.total != 0">
                    <v-layout column>
                      <v-flex xs12>
                        <v-layout row wrap>
                          <v-flex xs12 md4>
                            <v-layout column>
                              <template
                                v-for="(
                                  eso, index
                                ) in esoArray[0]"
                              >
                                <v-flex xs12>
                                  <v-card
                                    :loading="loading"
                                    class="mx-1 mt-2 d-flex flex-column"
                                    style="border-radius: 25px"
                                    outlined
                                  >
                                    <template slot="progress">
                                      <v-progress-linear
                                        color="deep-purple"
                                        height="10"
                                        indeterminate
                                      ></v-progress-linear>
                                    </template>
  
                                    <v-img
                                      max-height="150"
                                      v-if="eso.featured_image == null"
                                      src="img/eso.jpg"
                                      style="border-radius: 25px"
                                    >
                                    </v-img>
                                    <v-img
                                      max-height="150"
                                      v-else
                                      :src="
                                        path +
                                        '/storage/eso_pictures/' +
                                        eso.featured_image
                                      "
                                      style="border-radius: 25px"
                                    >
                                    </v-img>
  
                                    <div class="title pa-3">
                                      <v-clamp autoresize :max-lines="2"
                                        >{{ eso.name }}
                                      </v-clamp>
                                      <div class="caption"><i>{{ eso.county.name }} • {{ eso.eso_type.name }}</i></div>
                                    </div>
  
                                    <v-card-text>
                                        
                                      <v-clamp autoresize :max-lines="10"
                                        >{{ eso.brief }}
                                      </v-clamp>
                                    </v-card-text>
                                    <v-spacer></v-spacer>
                                    <div
                                      align="center"
                                      v-if="eso.verified == 1"
                                    >
                                      <v-chip
                                        class="ma-2"
                                        color="blue"
                                        label
                                        text-color="white"
                                      >
                                        <v-icon left> mdi-check-decagram </v-icon>
                                        IB Verified
                                      </v-chip>
                                    </div>
  
                                    <v-divider class="mx-4"></v-divider>
  
                                    <v-card-actions>
                                      <v-btn
                                        class="text-none"
                                        color="primary"
                                        block
                                        depressed
                                        rounded
                                        @click="goToEso(eso)"
                                      >
                                        View
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-flex>
                              </template>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 md4>
                            <v-layout column>
                              <template
                                v-for="(
                                  eso, index
                                ) in esoArray[1]"
                              >
                                <v-flex xs12>
                                  <v-card
                                    :loading="loading"
                                    class="mx-1 mt-2 d-flex flex-column"
                                    style="border-radius: 25px"
                                    outlined
                                  >
                                    <template slot="progress">
                                      <v-progress-linear
                                        color="deep-purple"
                                        height="10"
                                        indeterminate
                                      ></v-progress-linear>
                                    </template>
  
                                    <v-img
                                      max-height="150"
                                      v-if="eso.featured_image == null"
                                      src="img/eso.jpg"
                                      style="border-radius: 25px"
                                    >
                                    </v-img>
                                    <v-img
                                      max-height="150"
                                      v-else
                                      :src="
                                        path +
                                        '/storage/eso_pictures/' +
                                        eso.featured_image
                                      "
                                      style="border-radius: 25px"
                                    >
                                    </v-img>
  
                                    <div class="title pa-3">
                                      <v-clamp autoresize :max-lines="2"
                                        >{{ eso.name }}
                                      </v-clamp>
                                    </div>
  
                                    <v-card-text>
                                      <v-row
                                        align="center"
                                        class="mx-0"
                                        v-if="
                                          $can('rating_view') &&
                                          eso.rating_public == 1
                                        "
                                      >
                                        <v-rating
                                          :value="eso.average_rating"
                                          color="amber"
                                          dense
                                          half-increments
                                          readonly
                                          size="14"
                                        ></v-rating>
  
                                        <div class="grey--text ms-4">
                                          {{ eso.average_rating }} ({{
                                            eso.total_rating
                                          }})
                                        </div>
                                      </v-row>
  
                                      <div class="my-4 text-subtitle-1">
                                        <template
                                          v-for="(
                                            eso_sector, index
                                          ) in eso.eso_sectors.slice(
                                            0,
                                            5
                                          )"
                                        >
                                          <v-chip
                                            x-small
                                            :color="
                                              eso_sector.sector.color
                                            "
                                            class="white--text mr-1"
                                            :key="index"
                                          >
                                            #{{ eso_sector.sector.name }}
                                          </v-chip>
                                        </template>
                                        <v-chip
                                          class="white--text"
                                          color="primary"
                                          small
                                          v-if="
                                            eso.eso_sectors.length > 5
                                          "
                                        >
                                          + More
                                        </v-chip>
                                      </div>
                                      <v-clamp autoresize :max-lines="10"
                                        >{{ eso.brief }}
                                      </v-clamp>
                                    </v-card-text>
                                    <v-spacer></v-spacer>
                                    <div
                                      align="center"
                                      v-if="eso.verified == 1"
                                    >
                                      <v-chip
                                        class="ma-2"
                                        color="blue"
                                        label
                                        text-color="white"
                                      >
                                        <v-icon left> mdi-check-decagram </v-icon>
                                        IB Verified
                                      </v-chip>
                                    </div>
  
                                    <v-divider class="mx-4"></v-divider>
  
                                    <v-card-actions>
                                      <v-btn
                                        class="text-none"
                                        color="primary"
                                        block
                                        depressed
                                        rounded
                                        @click="goToEso(eso)"
                                      >
                                        View
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-flex>
                              </template>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 md4>
                            <v-layout column>
                              <template
                                v-for="(
                                  eso, index
                                ) in esoArray[2]"
                              >
                                <v-flex xs12>
                                  <v-card
                                    :loading="loading"
                                    class="mx-1 mt-2 d-flex flex-column"
                                    style="border-radius: 25px"
                                    outlined
                                  >
                                    <template slot="progress">
                                      <v-progress-linear
                                        color="deep-purple"
                                        height="10"
                                        indeterminate
                                      ></v-progress-linear>
                                    </template>
  
                                    <v-img
                                      max-height="150"
                                      v-if="eso.featured_image == null"
                                      src="img/eso.jpg"
                                      style="border-radius: 25px"
                                    >
                                    </v-img>
                                    <v-img
                                      max-height="150"
                                      v-else
                                      :src="
                                        path +
                                        '/storage/eso_pictures/' +
                                        eso.featured_image
                                      "
                                      style="border-radius: 25px"
                                    >
                                    </v-img>
  
                                    <div class="title pa-3">
                                      <v-clamp autoresize :max-lines="2"
                                        >{{ eso.name }}
                                      </v-clamp>
                                    </div>
  
                                    <v-card-text>
                                      <v-clamp autoresize :max-lines="10"
                                        >{{ eso.brief }}
                                      </v-clamp>
                                    </v-card-text>
                                    <v-spacer></v-spacer>
                                    <div
                                      align="center"
                                      v-if="eso.verified == 1"
                                    >
                                      <v-chip
                                        class="ma-2"
                                        color="blue"
                                        label
                                        text-color="white"
                                      >
                                        <v-icon left> mdi-check-decagram </v-icon>
                                        IB Verified
                                      </v-chip>
                                    </div>
  
                                    <v-divider class="mx-4"></v-divider>
  
                                    <v-card-actions>
                                      <v-btn
                                        class="text-none"
                                        color="primary"
                                        block
                                        depressed
                                        rounded
                                        @click="goToEso(eso)"
                                      >
                                        View
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-flex>
                              </template>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      
                    </v-layout>
                  </div>
                  <div v-else class="mx-1 mt-1">
                    <v-card outlined style="border-radius: 25px">
                      <v-layout row wrap>
                        <v-flex xs12 md4>
                          <div align="center" class="my-10">
                            <v-avatar color="text" size="96">
                              <v-icon class="white--text">
                                mdi-file-document-alert
                              </v-icon>
                            </v-avatar>
                          </div>
                        </v-flex>
                        <v-flex xs12 md8>
                          <v-layout column>
                            <v-flex xs12 class="mt-1">
                              <div align="center" class="mt-15 ml-5 title">
                                No Esos Found..
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </div>
                </v-card>
              <div align="center" class="my-7" v-if="publicEsoPagination.total != 0">
              <v-pagination
                  v-if="esoEsoLength != 0"
                  :length="esoEsoLength"
                  total-visible="10"
                  v-model="publicEsoPagination.current_page"
                  @input="changePage()"
                  circle
              >
              </v-pagination>
              </div>
              </v-flex>
              <v-flex xs12 md3>
              <div class="ml-3 mt-6">
                <v-card class="mx-auto pa-1" outlined>
                  <v-layout column>
                    
                   
                   
                      <v-flex xs12>
                        <div class="pa-1 mt-2">
                          <div class="mb-2"><b>County</b></div>
                          <v-select
                            :items="allCounties"
                            item-text="name"
                            item-value="id"
                            v-model="filter.county_id"
                            class="search_field background"
                            dense
                            outlined
                            height="10"
                            @input="search()"
                          >
                          </v-select>
                        </div>
                      </v-flex>
                      <div v-if="$can('investment_view') && formData!=null">
                        <v-flex xs12>
                          <div class="pa-1 mt-2">
                            <div class="mb-2"><b>ESO Type</b></div>
                            <v-select
                              :items="formData.eso_types"
                              item-text="name"
                              item-value="id"
                              v-model="filter.eso_type_id"
                              class="search_field background"
                              dense
                              outlined
                              height="10"
                              @input="search()"
                            >
                            </v-select>
                          </div>
                        </v-flex>
                      </div>
                  </v-layout>
                </v-card>

                <v-btn
                  depressed
                  block
                  class="primary text-none mt-5"
                  @click="reset()"
                  :loading="resetLoading"
                  >Reset</v-btn
                >
              </div>
            </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </template>
  <script>
  import apiCall from "@/utils/api";
  import { mapState, mapGetters, mapActions } from "vuex";
  
  export default {
    components: {},
    data() {
      return {
        path: process.env.VUE_APP_API_URL,
        color: "",
        message: "",
        snackbar: false,
        loading: false,
        searchLoading: false,
        resetLoading: false,
        filteredEsos: false,
  
        esoArray: [],
        inputRules: [(v) => !!v || "Input is required"],
  
        filter: {
          search: null,
          eso_type_id: null,
          county_id: null,
        },

        formData: null,
  
        originalArray: []
      };
    },
    watch: {
      publicEsos() {
        this.chunkEsos();
      },
    },
    mounted() {},
    created() {
      window.scrollTo(0, 0);
      this.initialize();
      this.getFormData();
    },
    methods: {
      ...mapActions([
        "fetchPublicEsos",
        "filterPublicEsos",
        "fetchAllCounties",
        "startEsoLoader",
        "stopEsoLoader",
      ]),
      initialize() {
        this.fetchPublicEsos(
          this.publicEsoPagination.current_page
        );
        this.fetchAllCounties();
        this.startEsoLoader();
        this.chunkEsos();
      },
      getFormData() {
        apiCall({
          url: "/api/eso?type=formData",
          method: "GET",
        })
          .then((resp) => {
            this.formData = resp;
          })
          .catch((error) => {
            this.message = "An Error Occurred; Couldn't Get Form Data";
            this.color = "error";
          });
      },
      goToEso(eso) {
        this.$router.push("/esos/" + eso.slug);
      },
      intToString(value) {
        var suffixes = ["", "k", "M", "B", "T"];
        var suffixNum = Math.floor(("" + value).length / 3);
        var shortValue = parseFloat(
          (suffixNum != 0
            ? value / Math.pow(1000, suffixNum)
            : value
          ).toPrecision(2)
        );
        if (shortValue % 1 != 0) {
          shortValue = shortValue.toFixed(1);
        }
        return shortValue + suffixes[suffixNum];
      },
      reset() {
        this.resetLoading = true;
        this.startEsoLoader();
        apiCall({
          url: "/api/eso?type=public&page=1",
          method: "GET",
        })
          .then((resp) => {
            this.filter.search = null;
            this.filter.eso_type_id = null;
            this.filter.county_id = null;
  
            this.loading = false;
            this.resetLoading = false;
            this.filteredEsos = true;
            this.filterPublicEsos(resp);
            this.chunkEsos();
            this.stopEsoLoader();
          })
          .catch((error) => {
            this.message = "An Error Occurred; Please Try Again";
            this.color = "error";
            this.loading = false;
            this.resetLoading = false;
            this.snackbar = true;
            this.stopEsoLoader();
          });
      },
      search() {
        this.searchLoading = true;
        this.startEsoLoader();
        apiCall({
          url: "/api/eso?type=filter&page=1",
          data: this.filter,
          method: "POST",
        })
          .then((resp) => {
            this.loading = false;
            this.searchLoading = false;
            this.filteredEsos = true;
            this.filterPublicEsos(resp);
            this.chunkEsos();
            this.stopEsoLoader();
          })
          .catch((error) => {
            this.message = "An Error Occurred; Please Try Again";
            this.color = "error";
            this.loading = false;
            this.searchLoading = false;
            this.snackbar = true;
            this.stopEsoLoader();
          });
      },
      chunkEsos() {
        this.esoArray = [];
  
        for(var i = 0; i < this.publicEsos.length; i++){
          this.originalArray.push(this.publicEsos[i])
        }
  
        let result = [];
        for (let i = 3; i > 0; i--) {
          result.push(this.originalArray.splice(0, Math.ceil(this.originalArray.length / i)));
        }
        this.esoArray = result;
      },
  
      changePage() {
        window.scrollTo(0, 0);
        this.startEsoLoader();
        if (this.filteredEsos == true) {
          apiCall({
            url:
              "/api/eso?type=filter&page=" +
              this.publicEsoPagination.current_page,
            data: this.filter,
            method: "POST",
          })
            .then((resp) => {
              this.loading = false;
              this.searchLoading = false;
              this.filteredEsos = true;
              this.filterPublicEsos(resp);
              this.stopEsoLoader();
            })
            .catch((error) => {
              this.message = "An Error Occurred; Please Try Again";
              this.color = "error";
              this.loading = false;
              this.searchLoading = false;
              this.snackbar = true;
              this.stopEsoLoader();
            });
        } else {
          this.fetchPublicEsos(
            this.publicEsoPagination.current_page
          );
        }
      },
      goToInstitution(institution) {
        this.$router.push("/institutions/" + institution.slug);
      },
    },
    computed: {
      ...mapGetters([
        "allInvestorTypes",
        "allCounties",
  
        "publicEsos",
        "esoLoader",
        "publicEsoPagination",
      ]),
      esoEsoLength: function () {
        return Math.ceil(
          this.publicEsoPagination.total /
            this.publicEsoPagination.per_page
        );
      },
    },
  };
  </script>
  