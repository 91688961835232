<template>
  <div class="investor-dashboard">
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-container class="mt-5">
      <v-layout row wrap>
        <v-flex xs12 md4>
          <v-layout column>
            <v-flex xs12>
                <v-card outlined class="mx-2 my-2" style="border-radius: 25px">
                    <v-card-title> Recommendations </v-card-title>
                    <v-card-text>
                          <v-layout column>
                            <div v-if="investorRecommendedInnovations.length != 0">
                              <template
                                v-for="(
                                  investorRecommendedInnovation, index
                                ) in investorRecommendedInnovations"
                              >
                                <div :key="index">
                                  <v-flex xs12 class="my-5">
                                    <v-layout row wrap>
                                      <v-flex xs1>
                                        <div align="center" class="mt-2">
                                          {{ index+1 }}.
                                        </div>
                                      </v-flex>
                                      <v-flex xs2>
                                        <div align="center" class="mt-2 mx-2">
                                          <v-img
                                              max-height="170"
                                              v-if="investorRecommendedInnovation.innovation.featured_image==null"
                                              src="img/innovation.jpg"
                                              style="border-radius: 25px;"
                                            >
                                            </v-img>
                                            <v-img
                                              max-height="170"
                                              v-else
                                              :src="path+'/storage/innovation_pictures/'+investorRecommendedInnovation.innovation.featured_image"
                                              style="border-radius: 25px;"
                                            >
                                          </v-img>
                                        </div>
                                      </v-flex>
                                      <v-flex xs6>
                                        <v-layout column>
                                          <v-flex xs12>
                                            <div class="mt-2">
                                              <div>
                                                <a style="text-decoration: none;" class="text--text" @click="goToInnovation(investorRecommendedInnovation.innovation)">
                                                  {{ investorRecommendedInnovation.innovation.name }}
                                                </a>  
                                              </div>
                                              <div>
                                                <v-clamp autoresize :max-lines="2" class="caption"
                                                  >{{ investorRecommendedInnovation.innovation.brief }}
                                                </v-clamp>
                                              </div>
                                            </div>                                  
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs3>
                                        <div class="mt-4 mx-2 green--text title" align="center">
                                          <v-progress-linear :value="investorRecommendedInnovation.score" color="blue"></v-progress-linear>
                                          {{ investorRecommendedInnovation.score}}%
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                    
                                  </v-flex>
                                  <v-divider class="mx-2"></v-divider>
                                </div>
                              </template>
                            </div>
                            <div v-else>
                              <v-layout row wrap>
                                <v-flex xs4>
                                  <v-container fill-height fluid>
                                        <v-row align="center" justify="center">
                                            <v-col class="text-center">
                                                <v-icon large class="text--text">
                                                    mdi-alert-circle-outline
                                                </v-icon>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-flex>
                                <v-flex xs8>
                                <div align="center" class="my-4">
                                  No Recommendations at the moment. Kindly check back later
                                </div> 
                                </v-flex>
                              </v-layout>
                            </div>
                          </v-layout>
                        </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs12>
              <v-card outlined class="mx-2 my-2" style="border-radius: 25px">
                <v-card-title> Top Ranked Innovations </v-card-title>
                <v-card-text>
                  <v-layout column>
                    <div v-if="investorTopRankedInnovations.length != 0">
                      <template
                        v-for="(
                          investorTopRankedInnovation, index
                        ) in investorTopRankedInnovations"
                      >
                        <div :key="index">
                          <v-flex xs12 class="my-5">
                            <v-layout row wrap>
                              <v-flex xs1>
                                <div align="center" class="mt-2">
                                  {{ index+1 }}.
                                </div>
                              </v-flex>
                              <v-flex xs2>
                                <div align="center" class="mt-2 mx-2">
                                  <v-img
                                      max-height="170"
                                      v-if="investorTopRankedInnovation.featured_image==null"
                                      src="img/innovation.jpg"
                                      style="border-radius: 25px;"
                                    >
                                    </v-img>
                                    <v-img
                                      max-height="170"
                                      v-else
                                      :src="path+'/storage/innovation_pictures/'+investorTopRankedInnovation.featured_image"
                                      style="border-radius: 25px;"
                                    >
                                  </v-img>
                                </div>
                              </v-flex>
                              <v-flex xs6>
                                <v-layout column>
                                  <v-flex xs12>
                                    <div class="mt-2">
                                      <div>
                                        <a style="text-decoration: none;" class="text--text" @click="goToInnovation(investorTopRankedInnovation)">
                                          {{ investorTopRankedInnovation.name }}
                                        </a>  
                                      </div>
                                      <div>
                                        <v-clamp autoresize :max-lines="2" class="caption"
                                          >{{ investorTopRankedInnovation.brief }}
                                        </v-clamp>
                                      </div>
                                    </div>                                  
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs3>
                                <v-row align="center" class="mx-0 my-1">
                                  <v-rating
                                    :value="investorTopRankedInnovation.average_rating"
                                    color="amber"
                                    dense
                                    half-increments
                                    readonly
                                    size="14"
                                  ></v-rating>

                                  <div class="grey--text ms-4" align="center">
                                    {{ investorTopRankedInnovation.average_rating }} ({{
                                      investorTopRankedInnovation.total_rating
                                    }})
                                  </div>
                                </v-row>
                              </v-flex>
                            </v-layout>
                            
                          </v-flex>
                          <v-divider class="mx-2"></v-divider>
                        </div>
                      </template>
                    </div>
                    <div v-else>
                      <v-layout row wrap>
                        <v-flex xs4>
                          <v-container fill-height fluid>
                            <v-row align="center" justify="center">
                              <v-col class="text-center">
                                <v-icon large class="text--text">
                                  mdi-alert-circle-outline
                                </v-icon>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-flex>
                        <v-flex xs8>
                         <div align="center">
                          No Top Ranked Innovation in your Interests. Kindly check back later
                         </div> 
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
            <!-- <v-flex xs12>
              <v-card outlined class="mx-2 my-2" style="border-radius: 25px">
                <v-card-title> Rising Innovations </v-card-title>
                <v-card-text> </v-card-text>
              </v-card>
            </v-flex> -->
            
            
          </v-layout>
        </v-flex>
        
        <v-flex xs12 md4>
            <v-layout column>
                
                <v-flex xs12>
                    <v-card outlined class="mx-2 my-2" style="border-radius: 25px">
                        <v-card-title> Saved Innovations </v-card-title>
                        <v-card-text>
                  <v-layout column>
                    <div v-if="investorSavedInnovations.length != 0">
                      <template
                        v-for="(
                          investorSavedInnovation, index
                        ) in investorSavedInnovations"
                      >
                        <div :key="index">
                          <v-flex xs12 class="my-5">
                            <v-layout row wrap>
                              <v-flex xs1>
                                <div align="center" class="mt-2">
                                  {{ index+1 }}.
                                </div>
                              </v-flex>
                              <v-flex xs2>
                                <div align="center" class="mt-2 mx-2">
                                  <v-img
                                      max-height="170"
                                      v-if="investorSavedInnovation.featured_image==null"
                                      src="img/innovation.jpg"
                                      style="border-radius: 25px;"
                                    >
                                    </v-img>
                                    <v-img
                                      max-height="170"
                                      v-else
                                      :src="path+'/storage/innovation_pictures/'+investorSavedInnovation.featured_image"
                                      style="border-radius: 25px;"
                                    >
                                  </v-img>
                                </div>
                              </v-flex>
                              <v-flex xs6>
                                <v-layout column>
                                  <v-flex xs12>
                                    <div class="mt-2">
                                      <div>
                                        <a style="text-decoration: none;" class="text--text" @click="goToInnovation(investorSavedInnovation)">
                                          {{ investorSavedInnovation.name }}
                                        </a>  
                                      </div>
                                      <div>
                                        <v-clamp autoresize :max-lines="2" class="caption"
                                          >{{ investorSavedInnovation.brief }}
                                        </v-clamp>
                                      </div>
                                    </div>                                  
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs3>
                                <v-row align="center" class="mx-0 my-1">
                                  <v-rating
                                    :value="investorSavedInnovation.average_rating"
                                    color="amber"
                                    dense
                                    half-increments
                                    readonly
                                    size="14"
                                  ></v-rating>

                                  <div class="grey--text ms-4" align="center">
                                    {{ investorSavedInnovation.average_rating }} ({{
                                      investorSavedInnovation.total_rating
                                    }})
                                  </div>
                                </v-row>
                              </v-flex>
                            </v-layout>
                            
                          </v-flex>
                          <v-divider class="mx-2"></v-divider>
                        </div>
                      </template>
                    </div>
                    <div v-else>
                      <v-layout row wrap>
                        <v-flex xs4>
                          <v-container fill-height fluid>
                              <v-row align="center" justify="center">
                                  <v-col class="text-center">
                                      <v-icon large class="text--text">
                                          mdi-alert-circle-outline
                                      </v-icon>
                                  </v-col>
                              </v-row>
                          </v-container>
                        </v-flex>
                        <v-flex xs8>
                         <div align="center" class="my-4">
                          No Saved Innovations Found
                         </div> 
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-layout>
                </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12>
                  
                    <v-card outlined class="mx-2 my-2" style="border-radius: 25px">
                      <div class="mx-5">
                        <v-progress-linear
                      v-if="investorRecentlyViewedInnovationLoader"
                      height="1"
                      indeterminate
                      color="primary"
                      >
                      </v-progress-linear>
                      </div>
                        <v-card-title> Recently Viewed </v-card-title>
                        <v-card-text>
                          <v-layout column>
                            <div v-if="investorRecentlyViewedInnovations.length != 0">
                              <template
                                v-for="(
                                  investorRecentlyViewedInnovation, index
                                ) in investorRecentlyViewedInnovations"
                              >
                                <div :key="index">
                                  <v-flex xs12 class="my-5">
                                    <v-layout row wrap>
                                      <v-flex xs1>
                                        <div align="center" class="mt-2">
                                          {{ index+1 }}.
                                        </div>
                                      </v-flex>
                                      <v-flex xs2>
                                        <div align="center" class="mt-2 mx-2">
                                          <v-img
                                              max-height="170"
                                              v-if="investorRecentlyViewedInnovation.innovation.featured_image==null"
                                              src="img/innovation.jpg"
                                              style="border-radius: 25px;"
                                            >
                                            </v-img>
                                            <v-img
                                              max-height="170"
                                              v-else
                                              :src="path+'/storage/innovation_pictures/'+investorRecentlyViewedInnovation.innovation.featured_image"
                                              style="border-radius: 25px;"
                                            >
                                          </v-img>
                                        </div>
                                      </v-flex>
                                      <v-flex xs6>
                                        <v-layout column>
                                          <v-flex xs12>
                                            <div class="mt-2">
                                              <div>
                                                <a style="text-decoration: none;" class="text--text" @click="goToInnovation(investorRecentlyViewedInnovation.innovation)">
                                                  {{ investorRecentlyViewedInnovation.innovation.name }}
                                                </a>  
                                              </div>
                                              <div>
                                                <v-clamp autoresize :max-lines="2" class="caption"
                                                  >{{ investorRecentlyViewedInnovation.innovation.brief }}
                                                </v-clamp>
                                              </div>
                                            </div>                                  
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs3>
                                        <div class="mt-2 caption">
                                          {{ investorRecentlyViewedInnovation.created_at | moment("from")}}
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                    
                                  </v-flex>
                                  <v-divider class="mx-2"></v-divider>
                                </div>
                              </template>
                            </div>
                            <div v-else>
                              <v-layout row wrap>
                                <v-flex xs4>
                                  <v-container fill-height fluid>
                                        <v-row align="center" justify="center">
                                            <v-col class="text-center">
                                                <v-icon large class="text--text">
                                                    mdi-alert-circle-outline
                                                </v-icon>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-flex>
                                <v-flex xs8>
                                <div align="center" class="my-4">
                                  No Recently viewed Innovations. Kindly check back later
                                </div> 
                                </v-flex>
                              </v-layout>
                            </div>
                          </v-layout>
                        </v-card-text>
                        <div align="center" class="mb-2">
                          <v-pagination
                            v-if="investorRecentlyViewedInnovationLength != 0"
                            :length="investorRecentlyViewedInnovationLength"
                            total-visible="10"
                            v-model="investorRecentlyViewedInnovationPagination.current_page"
                            @input="fetchInvestorRecentlyVieweds(investorRecentlyViewedInnovationPagination.current_page)"
                            circle
                          >
                          </v-pagination>
                        </div>
                    </v-card>
                </v-flex>
            </v-layout>
          
        </v-flex>
        <v-flex xs12 md4>
            <v-layout column>
                
                <v-flex xs12>
                    <v-card outlined class="mx-2 my-2" style="border-radius: 25px">
                        <v-card-title> My Notes </v-card-title>
                        <v-card-text>
                          <v-layout column>
                            <div v-if="investorNoteInnovations.length != 0">
                              <template
                                v-for="(
                                  investorNoteInnovation, index
                                ) in investorNoteInnovations"
                              >
                                <div :key="index">
                                  <v-flex xs12 class="my-5">
                                    <v-layout row wrap>
                                      <v-flex xs1>
                                        <div align="center" class="mt-2">
                                          {{ index+1 }}.
                                        </div>
                                      </v-flex>
                                      <v-flex xs11>
                                        <v-layout column>
                                          <v-flex xs12>
                                            <div class="mt-2">
                                              {{ investorNoteInnovation.title }}
                                            </div>
                                            <div class="caption">
                                              <i><b>Created:</b> {{ investorNoteInnovation.created_at | moment("Do MMM YYYY h:mm a") }} </i>
                                            </div>
                                            <div class="mt-2">
                                              <v-clamp autoresize :max-lines="4"
                                                class="mb-5 mr-5 caption">
                                               {{removeTags(investorNoteInnovation.content)}}
                                              </v-clamp>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12 class="mb-5">
                                            <v-layout row wrap>
                                              <v-flex xs2>
                                        <div align="center" class="mt-2 mx-2">
                                          <v-img
                                              max-height="170"
                                              v-if="investorNoteInnovation.innovation.featured_image==null"
                                              src="img/innovation.jpg"
                                              style="border-radius: 25px;"
                                            >
                                            </v-img>
                                            <v-img
                                              max-height="170"
                                              v-else
                                              :src="path+'/storage/innovation_pictures/'+investorNoteInnovation.innovation.featured_image"
                                              style="border-radius: 25px;"
                                            >
                                          </v-img>
                                        </div>
                                      </v-flex>
                                      <v-flex xs9>
                                        <v-layout column>
                                          <v-flex xs12>
                                            <div class="mt-2">
                                              <div>
                                                <a style="text-decoration: none;" class="text--text" @click="goToInnovation(investorNoteInnovation.innovation)">
                                                  {{ investorNoteInnovation.innovation.name }}
                                                </a>  
                                              </div>
                                              <div>
                                                <v-clamp autoresize :max-lines="2" class="caption"
                                                  >{{ investorNoteInnovation.innovation.brief }}
                                                </v-clamp>
                                              </div>
                                            </div>                                  
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      
                                    </v-layout>
                                    
                                  </v-flex>
                                  <v-divider class="mx-2 mt-5"></v-divider>
                                </div>
                              </template>
                            </div>
                            <div v-else>
                              <v-layout row wrap>
                                <v-flex xs4>
                                  <v-container fill-height fluid>
                                        <v-row align="center" justify="center">
                                            <v-col class="text-center">
                                                <v-icon large class="text--text">
                                                    mdi-alert-circle-outline
                                                </v-icon>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-flex>
                                <v-flex xs8>
                                <div align="center" class="my-4">
                                  You haven't added any notes yet..
                                </div> 
                                </v-flex>
                              </v-layout>
                            </div>
                          </v-layout>
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12>
                  <v-card outlined class="mx-2 my-2" style="border-radius: 25px">
                    <v-card-title> Recent Deals </v-card-title>
                    <v-card-text>
                          <v-layout column>
                            <div v-if="investorDealInnovations.length != 0">
                              <template
                                v-for="(
                                  investorDealInnovation, index
                                ) in investorDealInnovations"
                              >
                                <div :key="index">
                                  <v-flex xs12 class="my-5">
                                    <v-layout row wrap>
                                      <v-flex xs1>
                                        <div align="center" class="mt-2">
                                          {{ index+1 }}.
                                        </div>
                                      </v-flex>
                                      <v-flex xs11>
                                        <v-layout column>
                                          
                                          <v-flex xs12 class="mb-5 mt-3">
                                            <v-layout row wrap>
                                              <v-flex xs2>
                                                <div align="center" class="mt-2 mx-2">
                                                  <v-img
                                                      max-height="170"
                                                      v-if="investorDealInnovation.innovation.featured_image==null"
                                                      src="img/innovation.jpg"
                                                      style="border-radius: 25px;"
                                                    >
                                                    </v-img>
                                                    <v-img
                                                      max-height="170"
                                                      v-else
                                                      :src="path+'/storage/innovation_pictures/'+investorDealInnovation.innovation.featured_image"
                                                      style="border-radius: 25px;"
                                                    >
                                                  </v-img>
                                                </div>
                                              </v-flex>
                                              <v-flex xs9>
                                                <v-layout column>
                                                  <v-flex xs12>
                                                    <div class="mt-2">
                                                      <div>
                                                        <a style="text-decoration: none;" class="text--text" @click="goToInnovation(investorDealInnovation.innovation)">
                                                          {{ investorDealInnovation.innovation.name }}
                                                        </a>  
                                                      </div>
                                                      <div>
                                                        <v-clamp autoresize :max-lines="2" class="caption"
                                                          >{{ investorDealInnovation.innovation.brief }}
                                                        </v-clamp>
                                                      </div>
                                                    </div>                                  
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="mt-3">
                                              <v-layout row wrap>
                                                <v-flex xs4>
                                                  <b>Amount:</b>
                                                </v-flex>
                                                <v-flex xs8>
                                                  Kshs {{ investorDealInnovation.amount }}
                                                </v-flex>
                                              </v-layout>
                                              
                                            </div>
                                            <div class="mt-3">
                                              <v-layout row wrap>
                                                <v-flex xs4>
                                                  <b>Funding Type:</b>
                                                </v-flex>
                                                <v-flex xs8>
                                                  {{ investorDealInnovation.funding_type.name }}
                                                </v-flex>
                                              </v-layout>
                                              
                                            </div>
                                            <div class="mt-3 mb-6">
                                              <v-layout row wrap>
                                                <v-flex xs4>
                                                  <b>Deal Date:</b>
                                                </v-flex>
                                                <v-flex xs8>
                                                  {{ investorDealInnovation.created_at | moment("Do MMM YYYY h:mm a") }}
                                                </v-flex>
                                              </v-layout>
                                            </div>
                                            <div class="mt-3">
                                              <v-layout row wrap>
                                                <v-flex xs4>
                                                  <b><i>Investor(s)</i></b>
                                                </v-flex>
                                                <v-flex xs8>

                                                </v-flex>
                                              </v-layout>
                                              
                                            </div>

                                            <div class="mt-7">
                                              <v-layout column>
                                              <template v-for="(
                                                  investment_firm_investment, index
                                              ) in investorDealInnovation.investment_firm_investments">
                                                <div :key="index">
                                                  <v-flex xs12 class="mb-4">
                                                    <v-layout row wrap>
                                                      <v-flex xs3>
                                                        <div align="center">
                                                          <v-avatar size="46" v-if="investment_firm_investment.investment_firm.logo==null">
                                                            <v-img
                                                              src="img/investmentFirm.png"
                                                              style="border-radius: 25px"
                                                            >
                                                            </v-img>
                                                          </v-avatar>
                                                          <v-avatar size="46" v-else>
                                                            <v-img
                                                                class="elevation-6"
                                                                :src="
                                                                  path +
                                                                  '/storage/investment_firm_pictures/' +
                                                                  investment_firm_investment.investment_firm.logo
                                                                "
                                                              >
                                                              </v-img>
                                                          </v-avatar>
                                                        </div>
                                                      </v-flex>
                                                      <v-flex xs8>
                                                        <v-layout column>
                                                          <v-flex xs12 class="mt-5">
                                                            <v-layout row wrap>
                                                              <v-flex xs2>
                                                                Name:
                                                              </v-flex>
                                                              <v-flex xs10>
                                                                {{ investment_firm_investment.investment_firm.name }}
                                                              </v-flex>
                                                            </v-layout>
                                                          </v-flex>
                                                          <v-flex xs12 class="mt-5">
                                                            <v-layout row wrap>
                                                              <v-flex xs2>
                                                                Brief:
                                                              </v-flex>
                                                              <v-flex xs10>
                                                                {{ investment_firm_investment.investment_firm.brief }}
                                                              </v-flex>
                                                            </v-layout>
                                                          </v-flex>
                                                        </v-layout>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                </div>
                                              </template>
                                            </v-layout>
                                            <v-layout column>                                            
                                              <div v-if="investorDealInnovation.individual_investor_investment!=null">
                                                <v-flex xs12 class="mb-4">
                                                  <v-layout row wrap>
                                                    <v-flex xs12 md3>
                                                      <div align="center">
                                                        <v-avatar class="primary white--text" size="46" v-if="investorDealInnovation.individual_investor_investment.user.display_img==null">
                                                          {{ investorDealInnovation.individual_investor_investment.user.f_name[0] }}
                                                        </v-avatar>
                                                        <v-avatar size="46" v-else>
                                                          <v-img
                                                              class="elevation-6"
                                                              :src="
                                                                path +
                                                                '/storage/profile_pics/' +
                                                                investorDealInnovation.individual_investor_investment.user.display_img
                                                              "
                                                            >
                                                            </v-img>
                                                        </v-avatar>
                                                      </div>
                                                    </v-flex>
                                                    <v-flex xs12 md9>
                                                      <v-layout column>
                                                        <v-flex
                                                          xs12
                                                        >
                                                          <div><b>Name</b></div>
                                                          <div>
                                                            {{
                                                                investorDealInnovation.individual_investor_investment.user.f_name
                                                              }}
                                                              {{
                                                                investorDealInnovation.individual_investor_investment.user.m_name
                                                              }}
                                                              {{
                                                                investorDealInnovation.individual_investor_investment.user.l_name
                                                              }}
                                                          </div>
                                                        </v-flex>
                                                        <v-flex
                                                          xs12
                                                          class="mt-2"
                                                        >
                                                        <div><b>Investor Type</b></div>
                                                        <div>{{
                                                                investorDealInnovation.individual_investor_investment.user.individual_investor.investor_type.name
                                                              }}
                                                        </div>
                                                        </v-flex>
                                                      </v-layout>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                              </div>
                                              
                                          </v-layout>
                                            </div>
                                            
                                            
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      
                                    </v-layout>
                                    
                                  </v-flex>
                                  <v-divider class="mx-2 mt-5"></v-divider>
                                </div>
                              </template>
                            </div>
                            <div v-else>
                              <v-layout row wrap>
                                <v-flex xs4>
                                  <v-container fill-height fluid>
                                        <v-row align="center" justify="center">
                                            <v-col class="text-center">
                                                <v-icon large class="text--text">
                                                    mdi-alert-circle-outline
                                                </v-icon>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-flex>
                                <v-flex xs8>
                                <div align="center" class="my-4">
                                  There are no new deals on the platform..
                                </div> 
                                </v-flex>
                              </v-layout>
                            </div>
                          </v-layout>
                        </v-card-text>
                  </v-card>
                </v-flex>
            </v-layout>
          
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<style>
.ck-editor__editable {
  min-height: 200px;
}
</style>
<script>
import Vue from "vue";
import apiCall from "@/utils/api";
import { USER_REQUEST } from "@/store/actions/user";
import { mapState, mapGetters, mapActions } from "vuex";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import VClamp from "vue-clamp";
import uploadAdapter from '@/uploadAdapter.js';

Vue.use(CKEditor);

export default {
  components: {
    VClamp,
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      valid: true,
      color: "",
      message: "",
      snackbar: false,
      drawer: false,
      loading: false,
      confirm: false,
      inputRules: [(v) => !!v || "Input is required"],

      editor: ClassicEditor,
      editorConfig: {
          extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
              return new uploadAdapter(loader);
          } }],
          language: 'en',
      },

      tab: null,
      activeTab: "existing",

      view: "default",

      newOrganisation: {
        id: null,
        name: null,
        organisation_type_id: null,
        county_id: null,
        brief: null,
        year: null,
        physical_address: null,
      },
    };
  },
  mounted() {},
  created() {
    this.initialize();
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapActions([
      "fetchInvestorTopRankedInnovations",
      "fetchInvestorRecentlyVieweds",
      "fetchInvestorSavedInnovations",
      "fetchInvestorNoteInnovations",
      "fetchInvestorDealInnovations",
      "fetchInvestorRecommendedInnovations"
    ]),
    initialize() {
      this.fetchInvestorTopRankedInnovations(1);
      this.fetchInvestorRecentlyVieweds(1);
      this.fetchInvestorSavedInnovations(1);
      this.fetchInvestorNoteInnovations(1)
      this.fetchInvestorDealInnovations(1)
      this.fetchInvestorRecommendedInnovations(1)
    },
    goToInnovation(innovation) {
      this.$router.push("/innovations/" + innovation.slug);
    },
    save() {
      if (this.$refs.orgForm.validate()) {
        confirm("Are you sure you want to create organisation?") &&
          (this.confirm = true);
        if (this.confirm) {
          this.loading = true;
          apiCall({
            url: "/api/organisation",
            data: this.newOrganisation,
            method: "POST",
          })
            .then((resp) => {
              this.newAdministrator.organisation_id = resp.id;
              this.newAdministrator.user_id = this.getProfile.id;
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.loading = false;
              this.snackbar = true;
            });
        }
      }
    },

    getFormData() {
      apiCall({
        url: "/api/organisation?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },

    removeTags(str) {
      if ((str===null) || (str===''))
      return false;
      else
      str = str.toString();
      return str.replace( /(<([^>]+)>)/ig, '');
   }
  },
  computed: {
    ...mapGetters([
      "investorTopRankedInnovations", 
      "investorRecentlyViewedInnovations",
      "investorRecommendedInnovations",
      "investorSavedInnovations",
      "investorNoteInnovations",
      "investorDealInnovations",

      "investorTopRankedInnovationPagination",
      "investorRecentlyViewedInnovationPagination",
      "investorRecommendedInnovationPagination",
      "investorSavedInnovationPagination",
      "investorNoteInnovationPagination",
      "investorDealInnovationPagination",

      "investorTopRankedInnovationLoader",
      "investorRecentlyViewedInnovationLoader",
      "investorRecommendedInnovationLoader",
      "investorSavedInnovationLoader",
      "investorNoteInnovationLoader",
      "investorDealInnovationLoader",

    ]),

    investorRecentlyViewedInnovationLength: function () {
      return Math.ceil(
        this.investorRecentlyViewedInnovationPagination.total /
          this.investorRecentlyViewedInnovationPagination.per_page
      );
    },
  },
};
</script>
