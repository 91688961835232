<template>
    <v-footer
      inset
      relative 
      dark
      padless
      width="auto"
      color="primary"
      height="auto"
    >
    <v-layout column>
        
        <v-flex xs12 class="primary">
          <v-divider class="primary--text"></v-divider>
            <v-container class="my-5">
              <v-layout row wrap>
                  <v-flex xs12 md6>
                  <v-btn depressed router to="/privacy-policy" class="primary white--text text-none">Privacy Policy</v-btn>
                      •
                  <v-btn depressed router to="/terms-of-use" class="primary white--text text-none">Terms of Use</v-btn>
                  </v-flex>
                  <v-flex xs12 md6>
                  <div align="right" class="mt-1">
                      © {{ new Date().getFullYear() }} • <i>Powered By</i> <a href="http://www.ilabafrica.ac.ke/" target="_blank" style="text-decoration: none;" class="white--text">@iLabAfrica</a>
                  </div>
                  </v-flex>
              </v-layout>
            </v-container>
        </v-flex>
    </v-layout>
    
          
    </v-footer>
  </template>
  <script>
    export default {
      data: () => ({
        socials: [
          {icon: 'mdi-facebook', link: 'https://www.facebook.com/iLabAfricaStrathmore/?fref=ts'},
          {icon: 'mdi-twitter', link: 'https://twitter.com/ilabafrica'},
          {icon: 'mdi-linkedin', link: 'https://www.linkedin.com/company/2596334?trk=tyah&trkInfo=clickedVertical%3Acompany%2CclickedEntityId%3A2596334%2Cidx%3A2-1-2%2CtarId%3A1465459099320%2Ctas%3AILABAFRICA'},
          {icon: 'mdi-youtube', link: 'https://www.youtube.com/channel/UCeo0kj1SRiVkje78cyxnAzQ'},
          {icon: 'mdi-instagram', link: 'https://www.instagram.com/ilabafrica/?hl=en'},
        ],
      }),
    }
  </script>