import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store/index'

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/redirector')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const routes = [
  {
    path: '/roles',
    name: 'Roles',
    component: require('@/views/accessControl/role.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/users',
    name: 'Users',
    component: require('@/views/accessControl/user.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/logs',
    name: 'Log',
    component: require('@/views/accessControl/log.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reports',
    name: 'Report',
    component: require('@/views/accessControl/report.vue').default,
    beforeEnter: ifAuthenticated,
  },

  {
    path: '/calls-admin',
    name: 'callsAdmin',
    component: require('@/views/admin/callsAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/asks-admin',
    name: 'asksAdmin',
    component: require('@/views/admin/asksAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/events-admin',
    name: 'eventsAdmin',
    component: require('@/views/admin/eventsAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/institutions-admin',
    name: 'institutionsAdmin',
    component: require('@/views/admin/institutionsAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/organisations-admin',
    name: 'organisationsAdmin',
    component: require('@/views/admin/organisationsAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/esos-admin',
    name: 'EsosAdmin',
    component: require('@/views/admin/EsosAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/esos',
    name: 'Esos',
    component: require('@/views/Esos.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/esos/:slug",
    name: "Eso",
    component: require("@/views/Eso.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/investment-firms-admin',
    name: 'investmentFirmsAdmin',
    component: require('@/views/admin/investmentFirmsAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/innovations-admin',
    name: 'innovationsAdmin',
    component: require('@/views/admin/innovationsAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/contact-us-admin',
    name: 'contactUsAdmin',
    component: require('@/views/admin/contactUsAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/broadcast-admin',
    name: 'broadcastAdmin',
    component: require('@/views/admin/broadcastAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: require('@/views/Profile.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/messages',
    name: 'Message',
    component: require('@/views/Message.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/notifications',
    name: 'Notification',
    component: require('@/views/Notification.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/',
    name: 'Dashboard',
    component: require("@/views/Dashboard.vue").default,
    // beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/dashboard',
    name: 'AdminDashboard',
    component: require("@/views/admin/dashboard.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/redirector",
    name: "Redirector",
    component: require("@/views/Redirector.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/interests",
    name: "Interest",
    component: require("@/views/Interest.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/home",
    name: "Home",
    component: require("@/views/Home.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/innovations",
    name: "Innovations",
    component: require("@/views/Innovations.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/innovations/list/interests",
    name: "InterestInnovations",
    component: require("@/views/InterestInnovations.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/innovations/list/popular",
    name: "PopularInnovations",
    component: require("@/views/PopularInnovations.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/innovations/list/recommended",
    name: "RecommendedInnovations",
    component: require("@/views/RecommendedInnovations.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/innovations/list/recent",
    name: "RecentInnovations",
    component: require("@/views/RecentInnovations.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/innovations/:slug",
    name: "Innovation",
    component: require("@/views/Innovation.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/investors",
    name: "Investors",
    component: require("@/views/Investors.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/investors/:slug",
    name: "Investor",
    component: require("@/views/Investor.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/institutions",
    name: "Institutions",
    component: require("@/views/Institutions.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/institutions/:slug",
    name: "Institution",
    component: require("@/views/Institution.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/organisations",
    name: "Organisations",
    component: require("@/views/Organisations.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/organisations/:slug",
    name: "Organisation",
    component: require("@/views/Organisation.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/call-for-proposals",
    name: "CallForProposals",
    component: require("@/views/CallForProposals.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/call-for-proposals/:slug",
    name: "CallForProposal",
    component: require("@/views/CallForProposal.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/asks",
    name: "Asks",
    component: require("@/views/Asks.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/asks/:slug",
    name: "Ask",
    component: require("@/views/Ask.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/broadcasts",
    name: "Broadcasts",
    component: require("@/views/Broadcasts.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/broadcasts/:slug",
    name: "Broadcast",
    component: require("@/views/Broadcast.vue").default,
    beforeEnter: ifAuthenticated,
  },

  {
    path: "/events",
    name: "Events",
    component: require("@/views/Events.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/events/:slug",
    name: "Event",
    component: require("@/views/Event.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/administration-requests",
    name: "administrationRequests",
    component: require("@/views/administrationRequests.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/search",
    name: "Search",
    component: require("@/views/Search.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/connections",
    name: "Connection",
    component: require("@/views/Connection.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/investments',
    name: 'Investment',
    component: require('@/views/investment.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/investor-dashboard",
    name: "InvestorDashboard",
    component: require("@/views/InvestorDashboard.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPass',
    component: require("@/components/auth/resetPass.vue").default,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: require("@/views/PrivacyPolicy.vue").default,
  },
  {
    path: "/terms-of-use",
    name: "TermsOfUse",
    component: require("@/views/TermsOfUse.vue").default,
  },
  { path: "*", 
    name: 'PageNotFound',
    component: require("@/views/PageNotFound.vue").default,
  }
]

const router = new VueRouter({
  routes
})

export default router
