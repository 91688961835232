<template>
    <div class="welcome" v-if="$can('public_home_view')">
        <v-dialog width="700" transition="dialog-bottom-transition" scrollable v-model="dialog">
            <v-card>
                <v-toolbar flat class="transparent">

                    <v-toolbar-title>New Post</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-form ref="form">
                    <v-layout column>
                        <v-flex xs12>
                            <div class="mx-3 black--text">
                                <ckeditor :editor="editor" v-model="post.content" :config="editorConfig"
                                    :rules="inputRules"></ckeditor>
                            </div>

                        </v-flex>
                        <v-flex xs12>
                            <div class="mx-3 mt-3">
                                <v-file-input :loading="loading" filled dense show-size chips
                                    accept="image/*" v-model="imageFile" label="Add Image"></v-file-input>
                            </div>

                        </v-flex>
                    </v-layout>

                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed class="primary white--text text-none" @click="submit" :loading="loading">
                        Post
                        <v-icon right>mdi-send</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog width="700" transition="dialog-bottom-transition" scrollable v-model="commentDialog">

            <v-card v-if="commentDialog" class="mx-auto ma-1">
                <v-toolbar flat class="transparent">
                    <v-toolbar-title>Comments</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="commentDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-layout column>
                        <v-flex xs12 class="mt-5">
                            <v-layout row wrap>
                                <v-flex xs2>
                                    <div align="center">
                                        <v-avatar color="primary" v-if="postcard.user.display_img != null">
                                            <v-img class="elevation-6"                            
                                                :src="
                                                    path + '/storage/profile_pics/' + postcard.user.display_img
                                                "
                                            >
                                            </v-img>
                                        </v-avatar>
                                        <v-avatar color="primary" class="white--text" v-if="postcard.user.display_img == null">
                                            {{postcard.user.f_name[0]}}
                                        </v-avatar>
                                    </div>
                                </v-flex>
                                <v-flex xs10>
                                    <v-layout column wrap>
                                        <v-flex xs12>
                                            <div class="text-xs-left font-weight-thin grey--text"><b class="font-weight-bold grey--text">{{ postcard.user.f_name }} {{postcard.user.m_name}} {{postcard.user.l_name}}</b> • {{ postcard.created_at
                                                    | moment("from", true)
                                            }}
                                            </div>
                                            <div v-html="postcard.body"></div>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-divider class="mt-7" />
                        <v-flex xs12>
                            <Comments v-if="postId" :commentUrl="postId" />
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog width="700" transition="dialog-bottom-transition" scrollable v-model="votesDialog">

            <v-card v-if="votesDialog" class="mx-auto ma-1">
                <v-toolbar flat>
                    
                    <v-toolbar-title>Votes</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="votesDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-layout column class="mb-5">
                        <v-flex xs12 v-for="(vote, index) in postVotes" :key="vote.id" class="mt-5">
                            <v-layout row wrap>
                                <v-flex xs2>
                                    <v-avatar class="primary">
                                        <!-- <img :src="path + '/pictures/' + vote.user.profile.profile_pic"> -->
                                        <div class="white--text">{{vote.user.f_name[0]}}</div>
                                    </v-avatar>
                                </v-flex>
                                <v-flex xs10>
                                    <v-layout column>
                                        <v-flex xs12>
                                            <div class="text-xs-left"><b>{{ vote.user.f_name }}
                                                    {{ vote.user.m_name }} {{ vote.user.l_name }}</b></div>
                                        </v-flex>
                                        <v-flex xs12>
                                            <div v-if="vote.vote == 'up'" align="left"
                                                class="font-weight-thin green--text">Up Vote</div>
                                            <div v-else align="left" class="font-weight-thin red--text">Down Vote</div>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-container>
            <v-layout row wrap>
                <v-flex xs12 md3>
                    <v-card outlined class="mt-5" style="border-radius: 25px;">

                        <v-card-text>
                            <v-layout column>

                                <v-flex xs12>
                                    <div align="center">
                                        <v-avatar size="156" class="primary white--text title">
                                            <!-- <img src="img/pexels-justin-shaifer-1222271.jpg" alt="John"> -->
                                            {{getProfile.f_name[0]}}
                                        </v-avatar>
                                    </div>
                                    <div align="center" class="mt-5">
                                        <b>{{getProfile.f_name}} {{getProfile.l_name}}</b>
                                    </div>
                                    <div align="center" class="caption mt-5" v-if="getProfile.bio!=null" v-html="getProfile.bio">
                                       
                                    </div>
                                    <div align="center" class="caption mt-5" v-if="getProfile.bio==null">
                                        <i>No Bio..</i>
                                    </div>

                                </v-flex>
                                <v-flex xs12>
                                    <v-layout row wrap class="tableHeader mt-5 mb-2">
                                        <v-flex xs4>
                                            <v-layout column>
                                                <v-flex xs12 class="my-3">
                                                    <div align="center">
                                                        <v-icon class="text--text">
                                                            mdi-spa-outline
                                                        </v-icon>
                                                    </div>
                                                    <div align="center" class="text--text">
                                                        20
                                                    </div>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs4>
                                            <v-layout column>
                                                <v-flex xs12 class="my-3">
                                                    <div align="center">
                                                        <v-icon class="text--text">
                                                            mdi-handshake
                                                        </v-icon>
                                                    </div>
                                                    <div align="center" class="text--text">
                                                        3
                                                    </div>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs4>
                                            <v-layout column>
                                                <v-flex xs12 class="my-3">
                                                    <div align="center">
                                                        <v-icon class="text--text">
                                                            mdi-briefcase-outline
                                                        </v-icon>
                                                    </div>
                                                    <div align="center" class="text--text">
                                                        8
                                                    </div>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>

                        </v-card-text>


                    </v-card>
                </v-flex>
                <v-flex xs12 md6>
                    <div class="pa-5">
                        <v-card class="mx-auto pa-3" outlined>
                            <v-layout row wrap>
                                <v-flex xs6 class="pa-3">
                                    {{getHoursCondition}} {{getProfile.f_name}}
                                </v-flex>
                                <v-flex xs6>
                                    <div align="right" class="pa-3">
                                        <v-btn small outlined class="text--text" @click="(dialog = true)">
                                            Compose
                                            <v-icon right>
                                                mdi-fountain-pen-tip
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </v-flex>
                            </v-layout>

                        </v-card>
                        <v-card outlined class="mt-5" style="border-radius: 25px;">
                            <div id="chatList" class="pa-3"
                                style="margin:1px, 1px; padding:1px; width: auto; height: 80vh; overflow-x: hidden; overflow-y: scroll; text-align:justify;">
                                <vue-scroll-progress-bar @complete="nextPage" height="0.3rem" />
                                <v-layout column>
                                    <v-flex xs12>
                                        <div v-if="(allPosts.length != 0)">
                                            <template v-for="(post, index) in allPosts">
                                                <div :key="index">
                                                    <v-card outlined class="mx-auto mb-3" style="border-radius: 25px;">
                                                        <v-card-title>
                                                            <v-list-item-avatar color="primary">
                                                                <v-img class="elevation-6" 
                                                                    v-if="post.user.display_img != null"
                                                                    :src="
                                                                        path + '/storage/profile_pics/' + post.user.display_img
                                                                    "
                                                                >
                                                                </v-img>
                                                                <v-icon class="white--text" v-if="post.user.display_img == null">
                                                                    mdi-account
                                                                </v-icon>
                                                                <!-- <div align="center" class="white--text">{{post.user.f_name[0]}}</div> -->
                                                            </v-list-item-avatar>

                                                            <v-list-item-content>
                                                                <v-list-item-title class="text--text ml-1">{{post.user.f_name}} {{post.user.m_name}} {{post.user.l_name}}
                                                                    </v-list-item-title>
                                                                <v-row align="center" class="caption ml-1 mt-0">

                                                                    {{post.created_at | moment("from", true)}}
                                                                </v-row>
                                                            </v-list-item-content>
                                                        </v-card-title>
                                                            <v-img
                                                                
                                                                :src="path+'/storage/social_pics/'+post.pic"
                                                                style="border-radius: 25px;"
                                                                >
                                                            </v-img>
                                                        <v-card-text>
                                                            <p  v-html="post.body">

                                                            </p>
                                                        </v-card-text>
                                                        <v-card-actions>
                                                            <v-list-item class="grow">
                                                                <v-btn text class="text-none text--text" @click="loadComments(post)">
                                                                    Comment ({{post.comments.length}})
                                                                    <v-icon right>
                                                                        mdi-forum
                                                                    </v-icon>
                                                                </v-btn>
                                                                <v-btn text class="text-none text--text" @click="showVotes(post)">
                                                                    Votes
                                                                    <v-icon right>
                                                                        mdi-poll
                                                                    </v-icon>
                                                                </v-btn>

                                                                <v-row align="center" justify="end">
                                                                    <v-btn icon v-if="post.hasVoted == true">
                                                                        <v-icon class="grey--text">
                                                                            mdi-arrow-up-thick
                                                                        </v-icon>
                                                                    </v-btn>
                                                                    <v-btn icon v-else
                                                                        :loading="likeLoader && likeIndex == index"
                                                                        @click="votePost(post.id,index,'up')"
                                                                    >
                                                                        <v-icon class=" green--text">
                                                                            mdi-arrow-up-thick
                                                                        </v-icon>
                                                                    </v-btn>
                                                                    
                                                                    <span class="mr-1">·</span>
                                                                    <v-btn icon v-if="post.hasVoted == true">
                                                                        <v-icon class="grey--text">
                                                                            mdi-arrow-down-thick
                                                                        </v-icon>
                                                                    </v-btn>
                                                                    <v-btn icon v-else
                                                                        :loading="dislikeLoader && dislikeIndex == index"
                                                                        @click="votePost(post.id,index,'down')"
                                                                    >
                                                                        <v-icon class="red--text">
                                                                            mdi-arrow-down-thick
                                                                        </v-icon>
                                                                    </v-btn>
                                                                    ({{post.votes}})
                                                                </v-row>
                                                            </v-list-item>
                                                        </v-card-actions>
                                                    </v-card>
                                                </div>
                                            </template>
                                        </div>
                                        <div v-else>
                                            <v-card outlined style="border-radius: 25px;">
                                                <v-card-text>
                                                    <v-layout row wrap>
                                                    <v-flex xs12 md4>
                                                        <div align="center" class="my-10">
                                                            <v-avatar color="primary" size="96">
                                                                <v-icon class="white--text"> mdi-alert-circle
                                                                </v-icon>
                                                            </v-avatar>
                                                        </div>
                                                    </v-flex>
                                                    <v-flex xs12 md8>
                                                        <v-layout column>
                                                            <v-flex xs12 class="mt-1">
                                                                <div align="center" class="mt-15 ml-5 title">No Posts Found..</div>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-flex>
                                                </v-layout>
                                                </v-card-text>
                                                
                                            </v-card>
                                        </div>
                                    </v-flex>
                                    
                                </v-layout>


                            </div>
                        </v-card>
                            
                    </div>
                </v-flex>
                <v-flex xs12 md3>
                    <v-card class="mx-auto pa-3 mt-5" outlined v-if="institutionStatistics.length != 0">
                        <v-card-title>
                            Top Institutions
                        </v-card-title>
                        <v-card-text>
                            <v-layout column>
                                <v-flex xs12>
                                    <v-layout row wrap>
                                        <v-flex xs2>
                                            <v-img width="100" height="40" src="img/institution.png" v-if="institutionStatistics[0].institution.logo==null">
                                            </v-img>
                                            <v-img width="100" height="40" :src="path+'/storage/institution_pictures/'+institutionStatistics[0].institution.logo" v-else>
                                            </v-img>
                                        </v-flex>
                                        <v-flex xs10>
                                            <div class="mt-2 ml-2">{{ institutionStatistics[0].institution.name }}</div>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-10">
                                    <v-layout row wrap>
                                        <v-flex xs2>
                                            <v-img width="100" height="40" src="img/institution.png" v-if="institutionStatistics[1].institution.logo==null">
                                            </v-img>
                                            <v-img width="100" height="40" :src="path+'/storage/institution_pictures/'+institutionStatistics[1].institution.logo" v-else>
                                            </v-img>
                                        </v-flex>
                                        <v-flex xs10>
                                            <div class="mt-2 ml-2">{{ institutionStatistics[1].institution.name }}</div>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-10">
                                    <v-layout row wrap>
                                        <v-flex xs2>
                                            <v-img width="100" height="40" src="img/institution.png" v-if="institutionStatistics[2].institution.logo==null">
                                            </v-img>
                                            <v-img width="100" height="40" :src="path+'/storage/institution_pictures/'+institutionStatistics[2].institution.logo" v-else>
                                            </v-img>
                                        </v-flex>
                                        <v-flex xs10>
                                            <div class="mt-2 ml-2">{{ institutionStatistics[2].institution.name }}</div>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </v-card-text>

                    </v-card>
                    <v-card class="mx-auto pa-3 mt-5" outlined v-if="profileProgress != 100">
                        <v-card-title>
                            Profile Completion
                        </v-card-title>
                        <v-card-text>
                            <v-layout column>
                                <v-flex xs12>
                                    <div align="center">
                                        <v-progress-circular
                                            :rotate="270"
                                            :size="150"
                                            :width="25"
                                            :value="profileProgress"
                                            color="primary"
                                            >
                                            {{ profileProgress }}
                                            </v-progress-circular>
                                    </div>
                                </v-flex>
                                <v-flex xs12 class="mt-6">
                                    <v-layout row wrap>
                                        <v-flex xs10>
                                            Bio
                                        </v-flex>
                                        <v-flex xs2>
                                            <v-flex xs2>
                                                <div v-if="getProfile.bio==null">
                                                    <v-icon class="red--text">mdi-close-circle</v-icon>
                                                </div>
                                                <div v-if="getProfile.bio!=null && getProfile.bio==null">
                                                    <v-icon class="red--text">mdi-close-circle</v-icon>
                                                </div>
                                                <div v-if="getProfile.bio!=null && getProfile.bio!=null">
                                                    <v-icon class="green--text">mdi-check-circle</v-icon>
                                                </div>
                                                
                                            </v-flex>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-6">
                                    <v-layout row wrap>
                                        <v-flex xs10>
                                            County
                                        </v-flex>
                                        <v-flex xs2>
                                            <v-flex xs2>
                                                <div v-if="getProfile.user_profile==null">
                                                    <v-icon class="red--text">mdi-close-circle</v-icon>
                                                </div>
                                                <div v-if="getProfile.user_profile!=null && getProfile.user_profile.county_id==null">
                                                    <v-icon class="red--text">mdi-close-circle</v-icon>
                                                </div>
                                                <div v-if="getProfile.user_profile!=null && getProfile.user_profile.county_id!=null">
                                                    <v-icon class="green--text">mdi-check-circle</v-icon>
                                                </div>
                                                
                                            </v-flex>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-6">
                                    <v-layout row wrap>
                                        <v-flex xs10>
                                            Gender
                                        </v-flex>
                                        <v-flex xs2>
                                            <div v-if="getProfile.user_profile==null">
                                                <v-icon class="red--text">mdi-close-circle</v-icon>
                                            </div>
                                            <div v-if="getProfile.user_profile!=null && getProfile.user_profile.gender_id==null">
                                                <v-icon class="red--text">mdi-close-circle</v-icon>
                                            </div>
                                            <div v-if="getProfile.user_profile!=null && getProfile.user_profile.gender_id!=null">
                                                <v-icon class="green--text">mdi-check-circle</v-icon>
                                            </div>
                                            
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-6">
                                    <v-layout row wrap>
                                        <v-flex xs10>
                                            Date of Birth
                                        </v-flex>
                                        <v-flex xs2>
                                            <div v-if="getProfile.user_profile==null">
                                                <v-icon class="red--text">mdi-close-circle</v-icon>
                                            </div>
                                            <div v-if="getProfile.user_profile!=null && getProfile.user_profile.dob==null">
                                                <v-icon class="red--text">mdi-close-circle</v-icon>
                                            </div>
                                            <div v-if="getProfile.user_profile!=null && getProfile.user_profile.dob!=null">
                                                <v-icon class="green--text">mdi-check-circle</v-icon>
                                            </div>
                                            
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                    </v-card>

                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>
<style>
/* width */
::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
<script>
import Vue from 'vue'
import Comments from "@/components/system/Comments.vue"
import VueScrollProgressBar from '@guillaumebriday/vue-scroll-progress-bar'
Vue.use(VueScrollProgressBar)

import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadAdapter from '@/uploadAdapter.js';

Vue.use(CKEditor);

export default {
    components: {
        Comments
    },
    data() {
        return {
            path: process.env.VUE_APP_API_URL,
            color: "",
            message: "",
            snackbar: false,
            loading: false,
            selection: 1,
            drawer: false,
            dialog: false,

            editor: ClassicEditor,
            editorConfig: {
                extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                    return new uploadAdapter(loader);
                } }],
                language: 'en',
            },

            profileProgress: 0,

            likeLoader: false,
            likeIndex: -1,
            dislikeLoader: false,
            dislikeIndex: -1,
            postMessage: false,
            votesDialog: false,
            postId: null,
            cordova: Vue.cordova,
            snackbar: false,
            message: '',
            post: {
                content: '',
            },
            postVotes: [],
            postcard: {},
            commentDialog: false,
            loading: false,
            dialog: false,
            imageLink: '',
            imageName: "",
            imageUrl: "",
            imageFile: "",
            inputRules: [
                v => v.length >= 3 || 'Minimum length is 3 characters'
            ],
            fileRules: [(v) => !!v || "Kindly Select a File"],
            imageDialog: false,

        };
    },
    mounted() {

    },
    created() {
        window.scrollTo(0, 0);
        this.fetchPosts(1);
        this.calculateProfile()
        this.fetchInstitutionStatistics(1)
    },
    methods: {
        ...mapActions(['fetchPosts', 'updatePost', 'appendPost', "fetchInstitutionStatistics",]),
        goToInnovation() {
            this.$router.push("/innovation");
        },
        calculateProfile(){
            if(this.getProfile.user_profile!=null && this.getProfile.user_profile.dob!=null){
                this.profileProgress = this.profileProgress + 25
            }
            if(this.getProfile.user_profile!=null && this.getProfile.user_profile.gender_id!=null){
                this.profileProgress = this.profileProgress + 25
            }
            if(this.getProfile.user_profile!=null && this.getProfile.user_profile.county_id!=null){
                this.profileProgress = this.profileProgress + 25
            }
            if(this.getProfile.bio!=null){
                this.profileProgress = this.profileProgress + 25
            }
            
        },
        loadComments(post) {
            this.postId = post.id
            this.postcard = post
            this.commentDialog = true
        },
        votePost(postId, index, voteType) {
            if (voteType == 'up') {
                this.likeIndex = index
                this.likeLoader = true
            } else if (voteType == 'down') {
                this.dislikeIndex = index
                this.dislikeLoader = true
            }
            apiCall({
                url: '/api/post/' + postId + '/vote', data:
                {
                    vote: voteType
                }
                , method: 'POST'
            })
                .then(res => {
                    if (res) {
                        if (voteType == 'up') {
                            this.allPosts[index].votes++;
                            this.allPosts[index].hasVoted = true;
                            this.allPosts[index].post_votes = this.allPosts[index].post_votes.concat(res)
                            this.likeIndex = -1
                            this.likeLoader = false
                        } else if (voteType == 'down') {
                            this.allPosts[index].votes--;
                            this.allPosts[index].hasVoted = true;
                            this.allPosts[index].post_votes = this.allPosts[index].post_votes.concat(res)
                            this.dislikeIndex = -1
                            this.dislikeLoader = false
                        }
                    }
                });
        },
        showVotes(post) {
            this.postVotes = post.post_votes
            this.votesDialog = true
        },
        showImage(post) {
            this.imageLink = post.pic
            this.imageDialog = true
        },
        pickFile() {
            this.$refs.image.click();
        },
        takePhoto() {
            navigator.camera.getPicture(this.onSuccess, this.onFail, {
                quality: 50,
                sourceType: Camera.PictureSourceType.CAMERA,
                destinationType: navigator.camera.DestinationType.DATA_URL
            });
        },

        onSuccess(imageData) {
            this.imageName = "camerapic"
            //this.imageFile = imageData;
            this.imageFile = "data:image/jpeg;base64," + imageData;
        },
        onFail(message) {
            this.message = 'Failed because: ' + message;
            this.snackbar = true;
        },
        nextPage() {
            if (this.postPagination.total == this.allPosts.length) {
                this.postMessage = true
            } else {
                this.postPagination.current_page = this.postPagination.current_page + 1
                this.updatePost(this.postPagination.current_page)
            }

            //window.scrollTo(0,0);
        },
        submit() {
            if (this.$refs.form.validate()) {
                this.loading = true
                let formData = new FormData();
                formData.append("content", this.post.content);
                formData.append("name", this.imageName);
                formData.append("file", this.imageFile);
                const config = {
                    headers: { "content-type": "multipart/form-data" }
                };
                console.log(formData);

                apiCall({
                    url: "/api/post",
                    data: formData,
                    config,
                    method: "POST"
                }).then(resp => {

                    this.message = 'Status updated Succesfully';
                    this.loading = false
                    this.snackbar = true;
                    this.dialog = false
                    this.appendPost(resp)
                }).catch(error => {
                    this.loading = false
                });
            }
        }

    },
    computed: {
        ...mapGetters(['allPosts', 'postPagination', 'getProfile', 'institutionStatistics']),
        getHoursCondition: function() {
      var hours = new Date().getHours();
      if (hours >= 0 && hours <= 11) {
        const myArray = [
          "Good morning",
          "Habari za asubuhi"
          // "Buongiorno",
          // "Dobré ráno",
          // "Guten Morgen",
          // "Bom Dia",
          // "Buenos dias",
          // "Bonjour",
          // "Доброе утро",
          // "शुभ प्रभात",
          // "صباح الخير",
          // "早上好",
          // "おはようございます",
        ];
        const randomElement =
          myArray[Math.floor(Math.random() * myArray.length)];
        return randomElement;
      } else if (hours >= 12 && hours <= 16) {
        const myArray = [
          "Good afternoon",
          "Habari za mchana"
          // "Buon pomeriggio",
          // "Dobré odpoledne",
          // "guten Tag",
          // "Boa tarde",
          // "Buenas tardes",
          // "Bonne après-midi",
          // "Добрый день",
          // "नमस्कार",
          // "مساء الخير",
          // "下午好",
          // "こんにちは",
        ];
        const randomElement =
          myArray[Math.floor(Math.random() * myArray.length)];
        return randomElement;
      } else if (hours >= 17 && hours <= 24) {
        const myArray = [
          "Good evening",
          "Habari za jioni"
          // "Buona serata",
          // "Dobrý večer",
          // "Guten Abend",
          // "Boa noite",
          // "Buenas noches",
          // "Bonne soirée",
          // "Добрый вечер",
          // "सुसंध्या",
          // "مساء الخير",
          // "晚上好",
          // "こんばんは",
        ];
        const randomElement =
          myArray[Math.floor(Math.random() * myArray.length)];
        return randomElement;
      } /*else {
            this.getHoursCondition = 'something';
            }*/
    }
    },
};
</script>
  