<template>
    <div class="broadcasts" v-if="$can('broadcast_view')">
        <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
            <span>{{ message }}</span>
        </v-snackbar>
        <v-container>
            <v-layout column>
                <div v-if="view == 'default'">
                    <v-card outlined class="mt-5">
                        <v-progress-linear v-if="broadcastLoader" height="1" indeterminate color="primary">
                        </v-progress-linear>
                        <v-flex xs12 class="mt-10 mb-2">
                            <div class="mx-5">
                                <v-layout row wrap>
                                    <v-flex xs12 md4>
                                        <h1>Broadcasts</h1>
                                    </v-flex>
                                    <v-flex xs12 md4>
                                        <div class="pa-1">
                                            <v-text-field class="text_field header" outlined dense label="Search"
                                                append-icon="mdi-undo-variant" @click:append="resetSearch()"
                                                v-on:keyup.enter="search" v-model="searchTerm"
                                                @input="enableSearch()"></v-text-field>
                                        </div>
                                    </v-flex>
                                    <v-flex xs12 md2>
                                        <div class="pa-1">
                                            <v-btn class="secondary primary--text text-none" depressed block @click="search"
                                                :loading="searchLoader" :disabled="searchButtonDisabled">
                                                Search
                                                <v-icon right>mdi-magnify</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-flex>
                                    <v-flex xs12 md2>
                                        <div class="pa-1">
                                            <v-btn block depressed class="primary text-none" @click="changeView('create')"
                                                v-if="$can('broadcast_create')">
                                                New Broadcast
                                                <v-icon right>mdi-plus-circle-outline</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </v-flex>
                        <v-divider class="mt-9 mx-5"></v-divider>
                        <v-flex xs12 class="mt-5">
                            <div v-if="broadcasts.length == 0">
                                <v-card elevation="0">
                                    <v-layout row wrap>
                                        <v-flex xs12 md1>
                                            <v-container fill-height fluid>
                                                <v-row align="center" justify="center">
                                                    <v-col class="text-center">
                                                        <v-icon large class="primary--text">
                                                            mdi-alert-circle-outline
                                                        </v-icon>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-flex>
                                        <v-flex xs12 md11>
                                            <v-container fill-height fluid>
                                                <v-row align="center" justify="center">
                                                    <v-col class="text-center">
                                                        No Broadcast Found
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </div>
                            <div v-else>
                                <div class="hidden-sm-and-down">
                                    <v-card elevation="0" class="pa-2">
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <thead class="header">
                                                    <tr class="tableHeader">
                                                        <th class="text-left text--text">Subject</th>
                                                        <th class="text-left text--text">Message</th>
                                                        <th class="text-left text--text">Sender</th>
                                                        <th class="text-left text--text">Date Created</th>
                                                        <th class="text-right text--text">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="item in broadcasts" :key="item.id">
                                                        <td>{{ item.subject }}</td>
                                                        <td>
                                                            <v-clamp autoresize :max-lines="2"
                                                                >{{item.message}}
                                                            </v-clamp>
                                                        </td>
                                                        <td>{{ item.user.f_name }} {{ item.user.m_name }} {{ item.user.l_name }}</td>
                                                        <td>
                                                            {{
                                                                item.created_at | moment("DD/MM/YYYY - hh:mm a")
                                                            }}
                                                        </td>
                                                        <td>
                                                            <div align="right">
                                                                <v-btn icon v-if="$can('broadcast_view')"
                                                                    @click="showBroadcast(item)"
                                                                    class="button mr-1 universal--text">
                                                                    <v-icon small> mdi-eye </v-icon>
                                                                </v-btn>
                                                                <v-btn v-if="$can('broadcast_archive')" icon class="button mr-1 error--text" @click="deleteBroadcast(item)" :loading="loading && broadcastIndex == item.id
                ">
                                                                    <v-icon small> mdi-delete </v-icon>
                                                                </v-btn>
                                                                <v-tooltip top v-if="$can('log_view')">
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn icon class="button mr-1 purple--text"
                                                                            @click="viewLog(item)" v-bind="attrs" v-on="on">
                                                                            <v-icon small> mdi-script-text </v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Log</span>
                                                                </v-tooltip>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card>
                                </div>
                                <div class="hidden-md-and-up">
                                    <div>
                                        <v-layout column>
                                            <template v-for="(broadcast, index) in broadcasts">
                                                <div :key="index">
                                                    <v-flex xs12 class="mb-2">
                                                        <v-card elevation="0">
                                                            <div class="pa-5">
                                                                <v-layout column>
                                                                    <v-flex xs12>
                                                                        <v-layout row wrap>
                                                                            <v-flex xs11>
                                                                                <div class="title text--text ml-3">
                                                                                    <b>{{ broadcast.subject }}</b>
                                                                                </div>
                                                                            </v-flex>
                                                                            <v-flex xs1>
                                                                                <v-btn depressed class="red--text" :loading="loading &&
                                                                                    broadcastIndex ==
                                                                                    broadcast.id
                                                                                    " icon @click="deleteBroadcast(broadcast)
        " v-if="$can('broadcast_archive')">
                                                                                    <v-icon> mdi-delete </v-icon>
                                                                                </v-btn>
                                                                            </v-flex>
                                                                        </v-layout>
                                                                    </v-flex>
                                                                    <v-divider class="mt-2"></v-divider>
                                                                    <v-flex xs12 class="mt-1">
                                                                        <v-layout column>
                                                                            <v-flex xs12 class="mt-1">
                                                                                <div>
                                                                                    <b>User</b>
                                                                                </div>
                                                                                <div>
                                                                                    {{ broadcast.user_id }}
                                                                                </div>
                                                                            </v-flex>
                                                                            <v-flex xs12 class="mt-1">
                                                                                <div>
                                                                                    <b>message</b>
                                                                                </div>
                                                                                <div>
                                                                                    {{ broadcast.message }}
                                                                                </div>
                                                                            </v-flex>
                                                                            <v-flex xs12 class="mt-1">
                                                                                <div>
                                                                                    <b>Date Created</b>
                                                                                </div>
                                                                                <div>
                                                                                    {{
                                                                                        broadcast.created_at
                                                                                        | moment("DD/MM/YYYY - hh:mm a")
                                                                                    }}
                                                                                </div>
                                                                            </v-flex>

                                                                            <v-flex xs12 class="mt-5">
                                                                                <v-layout row wrap>
                                                                                    <v-flex xs12>
                                                                                        <div class="pa-1">
                                                                                            <v-btn depressed
                                                                                                class="primary text-none white--text mt-3"
                                                                                                block @click="showBroadcast(
                                                                                                            broadcast
                                                                                                        )
                                                                                                        " v-if="$can('broadcast_view')
                ">View
                                                                                                <v-icon right> mdi-eye
                                                                                                </v-icon>
                                                                                            </v-btn>
                                                                                        </div>
                                                                                    </v-flex>

                                                                                    <v-flex xs12>
                                                                                        <div class="pa-1">
                                                                                            <v-btn v-if="$can('log_view')"
                                                                                                block depressed
                                                                                                class="purple text-none white--text"
                                                                                                @click="viewLog(event)"
                                                                                                v-bind="attrs" v-on="on">
                                                                                                Log
                                                                                                <v-icon small>
                                                                                                    mdi-script-text
                                                                                                </v-icon>
                                                                                            </v-btn>
                                                                                        </div>
                                                                                    </v-flex>
                                                                                </v-layout>
                                                                            </v-flex>
                                                                        </v-layout>
                                                                    </v-flex>
                                                                </v-layout>
                                                            </div>
                                                        </v-card>
                                                    </v-flex>
                                                </div>
                                            </template>
                                        </v-layout>
                                    </div>
                                </div>
                            </div>
                        </v-flex>
                    </v-card>
                    <v-layout column class="mt-5">
                        <v-flex xs12>
                            <div align="left">
                                <v-pagination v-if="length != 0" :length="length" total-visible="10"
                                    v-model="broadcastPagination.current_page" @input="changePage()" circle>
                                </v-pagination>
                            </div>
                        </v-flex>
                        <v-flex xs12>
                            <div v-if="length != 0" align="center" class="mt-5">
                                <b class="primary--text">Total: </b>{{ broadcastPagination.total | formatNumber }}
                            </div>
                        </v-flex>
                    </v-layout>
                </div>
                <div v-if="view == 'create'">
                    <v-flex class="mt-5">
                        <v-card outlined>
                            <v-card-title class="tableHeader text--text">
                                <v-spacer></v-spacer>
                                <div v-if="newBroadcast.id == null">
                                    Add Broadcast
                                </div>
                                <div v-else>Edit Broadcast</div>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="changeView('default')">
                                    <v-icon class="text--text"> mdi-close </v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-card-text>
                                <v-form v-model="valid" ref="form" lazy-validation>
                                    <v-layout row wrap class="mt-5">
                                        <v-flex xs12>
                                            <div class="mx-3">
                                                <div>Subject<span class="error--text">*</span></div>
                                                <v-text-field class="text_field background" outlined dense
                                                    v-model="newBroadcast.subject" :rules="inputRules"></v-text-field>
                                            </div>
                                        </v-flex>
                                        <v-flex xs12>
                                            <div class="mx-3 mt-2">
                                                <div>Description<span class="error--text">*</span></div>
                                                <div class="black--text">
                                                    <ckeditor :editor="editor" v-model="newBroadcast.message"
                                                        :config="editorConfig" :rules="inputRules"></ckeditor>
                                                </div>
                                            </div>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-card-title class="tableHeader text--text mx-3 mt-5">
                                                <div align="centre">
                                                    Role(s)
                                                </div>
                                            </v-card-title>
                                        </v-flex>

                                        <v-flex xs12 class="mt-5 mx-6">
                                            <v-layout row wrap>
                                                <template v-for="(role, index) in formData.roles">
                                                    <v-flex xs12 md3 :key="index">
                                                        <v-checkbox v-model="newBroadcast.roles" :value="role.id"
                                                            :label="role.name"></v-checkbox>
                                                    </v-flex>
                                                </template>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-card-title class="tableHeader text--text mx-3 mt-5">
                                                <div align="centre">
                                                    Interest(s)
                                                </div>
                                            </v-card-title>
                                        </v-flex>

                                        <v-flex xs12 class="mt-5 mx-6">
                                            <v-layout row wrap>
                                                <template v-for="(interest, index) in formData.interests">
                                                    <v-flex xs12 md3 :key="index">
                                                        <v-checkbox v-model="newBroadcast.interests" :value="interest.id"
                                                            :label="interest.name"></v-checkbox>
                                                    </v-flex>
                                                </template>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-card-title class="tableHeader text--text mx-3 mt-5">
                                                <div align="center">
                                                    Sector(s)
                                                </div>
                                            </v-card-title>
                                        </v-flex>

                                        <v-flex xs12 class="mt-5 mx-6">
                                            <v-layout row wrap>
                                                <template v-for="(sector, index) in formData.sectors">
                                                    <v-flex xs12 md3 :key="index">
                                                        <v-checkbox v-model="newBroadcast.sectors" :value="sector.id"
                                                            :label="sector.name"></v-checkbox>
                                                    </v-flex>
                                                </template>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-card-title class="tableHeader text--text mx-3 mt-5">
                                                <div align="center">
                                                    SDG(s)
                                                </div>
                                            </v-card-title>
                                        </v-flex>

                                        <v-flex xs12 class="mt-5 mx-6">
                                            <v-layout row wrap>
                                                <template v-for="(sdg, index) in formData.sdgs">
                                                    <v-flex xs12 md3 :key="index">
                                                        <v-checkbox v-model="newBroadcast.sdgs" :value="sdg.id"
                                                            :label="sdg.name"></v-checkbox>
                                                    </v-flex>
                                                </template>
                                            </v-layout>
                                        </v-flex>


                                    </v-layout>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn depressed class="primary text-none mt-3" @click="save" :disabled="!valid"
                                    :loading="loading">
                                    Save
                                    <v-icon right> mdi-content-save </v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-flex>
                </div>
                <div v-if="view == 'show'">
                    <v-card outlined class="mt-5">
                        <v-progress-linear v-if="broadcastLoader" height="1" indeterminate color="primary">
                        </v-progress-linear>
                        <v-card-title class="tableHeader">
                            <v-spacer></v-spacer>
                            <div class="text--text">View Broadcast</div>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="changeView('default')">
                                <v-icon class="text--text"> mdi-close </v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <div class="pa-1">
                                <v-tabs show-arrows v-model="investmentFirmTab">
                                    <v-tabs-slider color="primary"></v-tabs-slider>
                                    <v-tab @click="activeTab = 'details'">
                                        <div class="text-none text--text">Details</div>
                                    </v-tab>
                                    <v-tab @click="activeTab = 'reach'">
                                        <div class="text-none text--text">Reach
                                            ({{
                                                userPagination.total
                                            }})

                                        </div>
                                    </v-tab>
                                </v-tabs>
                                <div v-if="activeTab == 'details'">
                                    <v-card-text>
                                        <div class="pa-3">                                            
                                            <v-layout column class="mt-3">
                                                <v-flex xs12 class="mt-2">
                                                    <v-layout row wrap>
                                                        <v-flex xs12 md2>
                                                            <b>Subject: </b>
                                                        </v-flex>
                                                        <v-flex xs12 md10>
                                                            <div>
                                                                {{ assignedBroadcast.subject }}
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap>
                                                        <v-flex xs12 md2>
                                                            <b>Date Created: </b>
                                                        </v-flex>
                                                        <v-flex xs12 md10>
                                                            <div>
                                                                {{
                                                                    assignedBroadcast.created_at
                                                                    | moment("DD/MM/YYYY - hh:mm a")
                                                                }}
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-6">
                                                    <v-layout row wrap>
                                                        <v-flex xs12 md2>
                                                            <b>Sender: </b>
                                                        </v-flex>
                                                        <v-flex xs12 md10>
                                                            <div>
                                                                {{ assignedBroadcast.user.f_name }} {{ assignedBroadcast.user.m_name }} {{ assignedBroadcast.user.l_name }}
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-10">
                                                    <v-layout row wrap>
                                                        <v-flex xs12 md2>
                                                            <b>Message: </b>
                                                        </v-flex>
                                                        <v-flex xs12 md10>
                                                            <div v-html="assignedBroadcast.message">
                                                               
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                                
                                            </v-layout>                                               
                                        </div>
                                    </v-card-text>
                                </div>
                                <div v-if="activeTab == 'reach'">
                                    <v-card-text>
                                        <v-card elevation="0" class="mt-5">
                      <v-flex xs12 class="mt-10 mb-2">
                        <div class="mx-5">
                          <v-layout row wrap>
                            <v-flex xs12 md4> </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <v-text-field
                                  class="text_field header"
                                  outlined
                                  dense
                                  label="Search"
                                  append-icon="mdi-undo-variant"
                                  @click:append="
                                    resetInstitutionAdministratorSearch()
                                  "
                                  v-on:keyup.enter="
                                    userSearch
                                  "
                                  v-model="
                                    userSearchTerm
                                  "
                                  @input="
                                    enableInstitutionAdministratorSearch()
                                  "
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md2>
                              <div class="pa-1">
                                <v-btn
                                  class="secondary primary--text text-none"
                                  depressed
                                  block
                                  @click="userSearch"
                                  :loading="
                                    userSearchLoader
                                  "
                                  :disabled="
                                    userSearchButtonDisabled
                                  "
                                >
                                  Search
                                  <v-icon right>mdi-magnify</v-icon>
                                </v-btn>
                              </div>
                            </v-flex>
                            <v-flex xs12 md2>
                              <div class="pa-1">
                                
                              </div>
                            </v-flex>
                          </v-layout>
                        </div>
                      </v-flex>
                      <v-divider class="mt-9 mx-5"></v-divider>
                      <v-flex xs12 class="mt-5">
                        <div v-if="users.length == 0">
                          <v-card elevation="0">
                            <v-layout row wrap>
                              <v-flex xs12 md1>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      <v-icon large class="text--text">
                                        mdi-alert-circle-outline
                                      </v-icon>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                              <v-flex xs12 md11>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      No User(s) Found
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </div>
                        <div v-else>
                          <div class="hidden-sm-and-down">
                            <v-card elevation="0" class="pa-2">
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead class="header">
                                    <tr class="tableHeader">
                                      <th class="text-left text--text">Pic</th>
                                      <th class="text-left text--text">Name</th>
                                      <th class="text-left text--text">
                                        Email Address
                                      </th>
                                      <th class="text-left text--text">
                                        Phone #
                                      </th>
                                      <th class="text-left text--text">Code</th>
                                      
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="item in users"
                                      :key="item.id"
                                    >
                                      <td>
                                        <div class="my-2">
                                          <v-avatar
                                            size="48"
                                            v-if="item.user.display_img == null"
                                          >
                                            <v-img
                                              max-height="150"
                                              src="img/logo_fav.png"
                                            >
                                            </v-img>
                                          </v-avatar>
                                          <v-avatar size="48" v-else>
                                            <v-img
                                              max-height="150"
                                              :src="
                                                path +
                                                '/storage/profile_pics/' +
                                                item.user.display_img
                                              "
                                            >
                                            </v-img>
                                          </v-avatar>
                                        </div>
                                      </td>
                                      <td>
                                        {{ item.user.f_name }}
                                        {{ item.user.m_name }}
                                        {{ item.user.l_name }}
                                      </td>
                                      <td>{{ item.user.email }}</td>
                                      <td>{{ item.user.phone }}</td>
                                      <td>{{ item.user.code }}</td>
                                      
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card>
                          </div>
                          <div class="hidden-md-and-up">
                            <div>
                              <v-layout column>
                                <template
                                  v-for="(
                                    administrator, index
                                  ) in users"
                                >
                                  <div :key="index">
                                    <v-flex xs12 class="mb-2">
                                      <v-card elevation="0">
                                        <div class="pa-5">
                                          <v-layout column>
                                            <v-flex xs12>
                                              <v-layout row wrap>
                                                <v-flex xs12>
                                                  <div class="title text--text">
                                                    <b
                                                      >{{
                                                        administrator.user
                                                          .f_name
                                                      }}
                                                      {{
                                                        administrator.user
                                                          .m_name
                                                      }}
                                                      {{
                                                        administrator.user
                                                          .l_name
                                                      }}</b
                                                    >
                                                  </div>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-divider class="mt-2"></v-divider>
                                            <v-flex xs12 class="mt-1">
                                              <v-layout column>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Email Address</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{
                                                        administrator.user.email
                                                      }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Phone #</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{
                                                        administrator.user.phone
                                                      }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Code</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{
                                                        administrator.user.code
                                                      }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                
                                              </v-layout>
                                            </v-flex>
                                          </v-layout>
                                        </div>
                                      </v-card>
                                    </v-flex>
                                  </div>
                                </template>
                              </v-layout>
                            </div>
                          </div>
                        </div>
                      </v-flex>
                    </v-card>
                    <v-layout column class="mt-5">
                      <v-flex xs12>
                        <div align="left">
                          <v-pagination
                            v-if="length != 0"
                            :length="userLength"
                            total-visible="10"
                            v-model="
                              userPagination.current_page
                            "
                            @input="changeUserPage()"
                            circle
                          >
                          </v-pagination>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div
                          v-if="userLength != 0"
                          align="center"
                          class="mt-5"
                        >
                          <b class="text--text">Total: </b
                          >{{
                            userPagination.total
                              | formatNumber
                          }}
                        </div>
                      </v-flex>
                    </v-layout>
                                    </v-card-text>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </div>
            </v-layout>
        </v-container>
        <logData v-if="logDataModel" v-model="logDataModel" />
    </div>
</template>
<style>
.ck-editor__editable {
    min-height: 200px;
}
</style>
<script>
import Vue from "vue";
import apiCall from "@/utils/api";
import { mapGetters, mapState, mapActions } from "vuex";
import logData from "@/views/accessControl/logData";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import VClamp from "vue-clamp";
import uploadAdapter from '@/uploadAdapter.js';

Vue.use(CKEditor);

export default {
    components: {
        logData,
        VClamp,
    },
    data() {
        return {
            path: process.env.VUE_APP_API_URL,

            editor: ClassicEditor,
            editorConfig: {
                extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                    return new uploadAdapter(loader);
                } }],
                language: 'en',
            },

            activeTab: "details",
            view: "default",

            loading: false,

            activateLoading: false,
            deactivateLoading: false,

            valid: true,
            message: "",
            color: "",
            delete: false,
            activate: false,
            confirm: false,
            tab: null,
            investmentFirmTab: null,
            broadcastIndex: null,

            snackbar: false,
            inputRules: [(v) => !!v || "Input is required"],

            searchUsers: null,

            searchTerm: null,
            userSearchTerm: null,

            searchLoader: false,
            searchButtonDisabled: false,

            filteredBroadcasts: false,
            filteredUsers: false,
            userLoader: false,

            newBroadcast: {
                id: null,
                subject: null,
                message: null,
                interests: [],
                sectors: [],
                sdgs: [],
                roles: []
            },

            assignedBroadcast: null,
            formData: null,

            searchTerm: null,
            searchLoader: false,
            userSearchLoader: false,

            searchButtonDisabled: false,
            userSearchButtonDisabled: false,

            filteredBroadcasts: [],

            logData: {
                message: null,
                model: null,
            },

            users: [],
            userPagination: {
                search: " ",
                current_page: 1,
                per_page: 0,
                total: 0,
                visible: 10,
            },
        };
    },

    created() {
        if (this.$can("broadcast_view")) {
            this.startBroadcastLoader();
            this.fetchBroadcasts(this.broadcastPagination.current_page);
            this.getFormData();
        }
    },
    methods: {
        ...mapActions([
            "fetchBroadcasts",
            "startBroadcastLoader",
            "stopBroadcastLoader",
            "filterBroadcasts",

            "filterLogData",
            "openLogDataModel",
        ]),

        enableInstitutionAdministratorSearch() {
          this.userSearchButtonDisabled = false;
        },

        resetInstitutionAdministratorSearch() {
            this.userSearchTerm = null;
            this.filteredUsers = false;
            this.userLoader = false;
            this.changeUserPage();
        },

        changeUserPage() {
            this.userLoader = true;
            if (this.filteredUsers == true) {
                this.userSearchButtonDisabled = false;
                apiCall({
                url:
                    "/api/broadcast-user?broadcast_id=" +
                    this.assignedBroadcast.id +
                    "&page=" +
                    this.userPagination.current_page +
                    "&type=search&search=" +
                    this.userSearchTerm,
                method: "GET",
                })
                .then((resp) => {
                    this.userLoader = false;

                    this.users = resp.data;
                    this.userPagination.current_page =
                    resp.current_page;
                    this.userPagination.total = resp.total;
                    this.userPagination.per_page = resp.per_page;
                })
                .catch((error) => {
                    console.log(error);
                    this.userLoader = false;
                    this.message = "An Error Occurred";
                    this.color = "error";
                    this.snackbar = true;
                });
            } else {
                apiCall({
                url:
                    "/api/broadcast-user?broadcast_id=" +
                    this.assignedBroadcast.id +
                    "&page=" +
                    this.userPagination.current_page,
                method: "GET",
                })
                .then((resp) => {
                    this.userLoader = false;

                    this.users = resp.data;
                    this.userPagination.current_page =
                    resp.current_page;
                    this.userPagination.total = resp.total;
                    this.userPagination.per_page = resp.per_page;
                })
                .catch((error) => {
                    console.log(error);
                    this.userLoader = false;
                    this.message = "An Error Occurred";
                    this.color = "error";
                    this.snackbar = true;
                });
            }
        },

        userSearch() {
            if (this.userSearchTerm == null) {
                this.userSearchButtonDisabled = true;
            } else {
                this.userSearchLoader = true;
                this.userSearchButtonDisabled = false;
                this.startBroadcastLoader();
                apiCall({
                url:
                    "/api/broadcast-user?type=search&search=" +
                    this.userSearchTerm +
                    "&broadcast_id=" +
                    this.assignedBroadcast.id,
                method: "GET",
                })
                .then((resp) => {
                    this.users = resp.data;
                    this.userPagination.current_page =
                    resp.current_page;
                    this.userPagination.total = resp.total;
                    this.userPagination.per_page = resp.per_page;

                    this.userSearchLoader = false;
                    this.filteredUsers = true;

                    this.stopBroadcastLoader();
                })
                .catch((error) => {
                    this.userSearchLoader = false;
                    this.message = "An Error Occurred";
                    this.color = "error";
                    this.snackbar = true;
                    console.log(error);

                    this.stopBroadcastLoader();
                });
            }
            },

        enableSearch() {
            this.searchButtonDisabled = false;
        },
        resetSearch() {
            this.searchTerm = null;
            this.filteredBroadcasts = true;
            this.startBroadcastLoader();
            this.fetchBroadcasts(1);
        },

        showBroadcast(investmentFirm) {
            this.assignedBroadcast = investmentFirm;
            this.changeUserPage()

            this.changeView("show");
        },
        save() {
            if (this.$refs.form.validate()) {
                if (this.newBroadcast.id != null) {
                    confirm("Are you sure you want to update broadcast?") &&
                        (this.confirm = true);
                    if (this.confirm) {
                        this.loading = true;
                        apiCall({
                            url: "/api/broadcast/" + this.newBroadcast.id,
                            data: this.newBroadcast,
                            method: "PUT",
                        })
                            .then((resp) => {
                                this.message = "Broadcast Updated Successfully";
                                this.color = "orange";
                                this.loading = false;
                                this.snackbar = true;
                                this.confirm = false;
                                this.changeView("default");
                                this.fetchBroadcasts(1);
                            })
                            .catch((error) => {
                                this.message = "An Error Occurred";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                            });
                    }
                } else {
                    confirm("Are you sure you want to create Broadcast?") &&
                        (this.confirm = true);
                    if (this.confirm) {
                        this.loading = true;
                        apiCall({
                            url: "/api/broadcast",
                            data: this.newBroadcast,
                            method: "POST",
                        })
                            .then((resp) => {
                                this.message = "Broadcast Added Successfully";
                                this.color = "success";
                                this.loading = false;
                                this.snackbar = true;
                                this.confirm = false;
                                this.changeView("default");
                                this.fetchBroadcasts(1);
                            })
                            .catch((error) => {
                                this.message = "An Error Occurred";
                                this.color = "error";
                                this.loading = false;
                                this.snackbar = true;
                                this.changeView("default");
                                this.fetchBroadcasts(1);
                            });
                    }
                }
            }
        },

        search() {
            if (this.searchTerm == null) {
                this.searchButtonDisabled = true;
            } else {
                this.searchLoader = true;
                this.searchButtonDisabled = false;
                this.startBroadcastLoader();
                apiCall({
                    url: "/api/broadcast?type=search&search=" + this.searchTerm,
                    method: "GET",
                })
                    .then((resp) => {
                        this.filterBroadcasts(resp);
                        this.searchLoader = false;
                        this.stopBroadcastLoader();
                        this.filteredBroadcasts = true;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.searchLoader = false;
                        this.stopBroadcastLoader();
                        this.message = "An Error Occurred";
                        this.color = "error";
                        this.snackbar = true;
                    });
            }
        },
        changeView(view) {
            if (view == "default") {
                this.newBroadcast.id = null;
                this.newBroadcast.subject = null;
                this.newBroadcast.message = null;
                this.newBroadcast.interests = [];
                this.newBroadcast.sectors = [];
                this.newBroadcast.sdgs = [];
                this.newBroadcast.roles = [];
            } else if (view == "create") {
                this.getFormData();
            } else if (view == "show") {
            }
            this.view = view;
        },
        changePage() {
            this.startBroadcastLoader();
            if (this.filteredBroadcasts == true) {
                this.searchButtonDisabled = false;
                apiCall({
                    url:
                        "/api/broadcast?page=" +
                        this.broadcastPagination.current_page +
                        "&type=search&search=" +
                        this.searchTerm,
                    method: "GET",
                })
                    .then((resp) => {
                        this.filterBroadcasts(resp);
                        this.stopBroadcastLoader();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.stopBroadcastLoader();
                        this.message = "An Error Occurred";
                        this.color = "error";
                        this.snackbar = true;
                    });
            } else {
                this.fetchBroadcasts(
                    this.broadcastPagination.current_page
                );
            }
        },
        deleteBroadcast(item) {
            confirm("Are You Sure You Want to Delete Broadcast") &&
                (this.delete = true);
            if (this.delete) {
                this.loading = true;
                this.broadcastIndex = item.id;
                apiCall({ url: "/api/broadcast/" + item.id, method: "DELETE" })
                    .then((resp) => {
                        this.message = "Broadcast Deleted Succesfully";
                        this.color = "success";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.broadcastIndex = null;
                        this.fetchBroadcasts(
                            this.broadcastPagination.current_page
                        );
                    })
                    .catch((error) => {
                        this.message = "An Error Occurred";
                        this.color = "error";
                        this.snackbar = true;
                        this.loading = false;
                        this.delete = false;
                        this.broadcastIndex = null;
                        console.log(error);
                    });
            }
        },
        
        getFormData() {
            apiCall({
                url: "/api/broadcast?type=formData",
                method: "GET",
            })
                .then((resp) => {
                    this.formData = resp;
                })
                .catch((error) => {
                    this.message = "anErrorOccurredCouldntGetFormDataMsg";
                    this.color = "error";
                });
        },
        viewLog(data) {
            this.logData.message = data.id;
            this.logData.model = "App\\Models\\Broadcast";
            this.filterLogData(this.logData);
            this.openLogDataModel();
        },
    },
    computed: {
        ...mapGetters([
            "broadcasts",
            "broadcastPagination",
            "broadcastLoader",
            "logDataModel",
        ]),

        length: function () {
            return Math.ceil(
                this.broadcastPagination.total /
                this.broadcastPagination.per_page
            );
        },
        userLength: function () {
            return Math.ceil(
                this.userPagination.total /
                this.userPagination.per_page
            );
            },
    },
};
</script>
  