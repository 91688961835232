<template>
  <div class="Logs" v-if="$can('log_view')">
    <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
      <span>{{ message }}</span>
    </v-snackbar>

    <v-dialog
      v-model="userDialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="1500"
    >
      <v-card class="elevation-1">
        <v-progress-linear
          v-if="userLoader"
          height="1"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="userDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-layout column>
            <v-flex xs12 class="mt-5 mb-2">
              <div class="mx-5">
                <v-layout row wrap>
                  <v-flex xs12 md4><h1 class="mt-3">Find User</h1> </v-flex>
                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-text-field
                        class="text_field header"
                        outlined
                        dense
                        label="Search"
                        append-icon="mdi-undo-variant"
                        @click:append="resetUserSearch()"
                        v-on:keyup.enter="searchUser"
                        v-model="userSearchTerm"
                        @input="enableUserSearch()"
                      ></v-text-field>
                    </div>
                  </v-flex>
                  <v-flex xs12 md2>
                    <div class="pa-1">
                      <v-btn
                        class="primary white--text text-none"
                        depressed
                        block
                        @click="searchUser"
                        :loading="userSearchLoader"
                        :disabled="userSearchButtonDisabled"
                      >
                        Search
                        <v-icon right>mdi-magnify</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs12 md2 class="hidden-sm-and-down"> </v-flex>
                </v-layout>
              </div>
            </v-flex>
            <v-divider class="mt-5 mx-5"></v-divider>
            <v-flex xs12 class="mt-5">
              <div v-if="users.length == 0">
                <v-card elevation="0">
                  <v-layout row wrap>
                    <v-flex xs12 md1>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center">
                            <v-icon large class="primary--text">
                              mdi-alert-circle-outline
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                    <v-flex xs12 md11>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center"> User(s) not Found </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                  </v-layout>
                </v-card>
              </div>
              <div v-else>
                <div class="hidden-sm-and-down">
                  <v-card elevation="0" class="pa-2">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead class="header">
                          <tr class="tableHeader">
                            <th class="text-left text--text">Name</th>
                            <th class="text-left text--text">Email Address</th>
                            <th class="text-left text--text">Date Added</th>
                            <th class="text-left text--text">Role(s)</th>
                            <th class="text-left text--text">Account Active</th>
                            <th class="text-right text--text">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in users" :key="item.id">
                            <td>
                              <div
                                v-if="userHighLightId == item.id"
                                class="green--text"
                              >
                                {{ item.f_name }} {{ item.m_name }}
                                {{ item.l_name }}
                              </div>
                              <div v-else>
                                {{ item.f_name }} {{ item.m_name }}
                                {{ item.l_name }}
                              </div>
                            </td>
                            <td>
                              <div
                                v-if="userHighLightId == item.id"
                                class="green--text"
                              >
                                {{ item.email }}
                              </div>
                              <div v-else>
                                {{ item.email }}
                              </div>
                            </td>
                            <td>
                              <div
                                v-if="userHighLightId == item.id"
                                class="green--text"
                              >
                                {{
                                  item.created_at
                                    | moment("DD/MM/YYYY - hh:mm a")
                                }}
                              </div>
                              <div v-else>
                                {{
                                  item.created_at
                                    | moment("DD/MM/YYYY - hh:mm a")
                                }}
                              </div>
                            </td>
                            <td>
                              <div
                                v-if="userHighLightId == item.id"
                                class="green--text"
                              >
                                <div v-if="item.roles.length != 0">
                                  <div
                                    v-for="item in item.roles"
                                    :key="item.id"
                                  >
                                    {{ item.name }}
                                  </div>
                                </div>
                                <div v-else>None</div>
                              </div>
                              <div v-else>
                                <div v-if="item.roles.length != 0">
                                  <div
                                    v-for="item in item.roles"
                                    :key="item.id"
                                  >
                                    {{ item.name }}
                                  </div>
                                </div>
                                <div v-else>None</div>
                              </div>
                            </td>
                            <td>
                              <div
                                v-if="userHighLightId == item.id"
                                class="green--text"
                              >
                                <div v-if="item.active == 1">Yes</div>
                                <div v-else>No</div>
                              </div>
                              <div v-else>
                                <div v-if="item.active == 1">Yes</div>
                                <div v-else>No</div>
                              </div>
                            </td>
                            <td>
                              <div align="right">
                                <v-btn
                                  depressed
                                  class="grey lighten-3 mr-1 primary--text text-none"
                                  @click="selectUser(item)"
                                >
                                  Select
                                  <v-icon small right>
                                    mdi-checkbox-marked-outline
                                  </v-icon>
                                </v-btn>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </div>
                <div class="hidden-md-and-up">
                  <v-layout column>
                    <template v-for="(user, index) in users">
                      <div :key="index">
                        <v-flex xs12 class="mb-2">
                          <v-card elevation="0">
                            <div class="my-5">
                              <v-layout column>
                                <v-flex xs12>
                                  <div class="title primary--text">
                                    <b
                                      >{{ user.f_name }} {{ user.m_name }}
                                      {{ user.l_name }}</b
                                    >
                                  </div>
                                </v-flex>
                                <v-divider class="mt-2"></v-divider>
                                <v-flex xs12 class="mt-1">
                                  <v-layout column>
                                    <v-flex xs12 class="mt-1">
                                      <div><b>Email Address</b></div>
                                      <div>{{ user.email }}</div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div><b>Date Added</b></div>
                                      <div>
                                        {{
                                          user.created_at
                                            | moment("DD/MM/YYYY - hh:mm a")
                                        }}
                                      </div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div><b>Role(s)</b></div>
                                      <div>
                                        <div v-if="user.roles.length != 0">
                                          <div
                                            v-for="user in user.roles"
                                            :key="user.id"
                                          >
                                            {{ user.name }}
                                          </div>
                                        </div>
                                        <div v-else>None</div>
                                      </div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div><b>Account Active</b></div>
                                      <div>
                                        <div v-if="user.active == 1">Yes</div>
                                        <div v-else>No</div>
                                      </div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1"> </v-flex>
                                    <v-flex xs12 class="mt-5">
                                      <v-layout row wrap>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn
                                              block
                                              depressed
                                              class="grey lighten-3 mr-1 primary--text text-none"
                                              @click="selectUser(user)"
                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                              Select
                                              <v-icon small right>
                                                mdi-checkbox-marked-outline
                                              </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </div>
                          </v-card>
                        </v-flex>
                      </div>
                    </template>
                  </v-layout>
                </div>
              </div>
            </v-flex>
            <v-flex xs12 class="my-5">
              <v-layout row wrap>
                <v-flex xs12>
                  <div align="center">
                    <v-pagination
                      v-if="userLength != 0"
                      :length="userLength"
                      total-visible="5"
                      v-model="userPagination.current_page"
                      @input="changeUserPage()"
                      circle
                    >
                    </v-pagination>
                  </div>
                </v-flex>
                <v-flex xs12>
                  <div align="center" v-if="userLength != 0" class="mt-5">
                    <b class="text--text">Total: </b>{{ userPagination.total }}
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-container>
      <v-layout column>
        <div v-if="view == 'default'">
          <v-card outlined class="mt-5">
            <v-progress-linear
              v-if="logLoader"
              height="1"
              indeterminate
              color="primary"
            ></v-progress-linear>
            <v-flex xs12 class="mt-10 mb-2">
              <div class="mx-5">
                <v-layout row wrap>
                  <v-flex xs12 class="mb-5 ml-1">
                    <h1>Logs</h1>
                  </v-flex>

                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-text-field
                        v-model="searchData.userName"
                        label="User"
                        class="text_field header"
                        outlined
                        dense
                        @change="enableSearch()"
                        @click="openUserDialog()"
                        append-icon="mdi-undo-variant"
                        @click:append="resetSearch()"
                      ></v-text-field>
                    </div>
                  </v-flex>
                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-select
                        :items="systemModels.models"
                        v-model="searchData.model"
                        label="Model"
                        class="text_field header"
                        item-value="model"
                        item-text="model"
                        outlined
                        dense
                        @change="enableSearch()"
                      ></v-select>
                    </div>
                  </v-flex>
                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-select
                        :items="systemModels.actions"
                        v-model="searchData.action"
                        label="Action"
                        class="text_field header"
                        item-value="action"
                        item-text="action"
                        outlined
                        dense
                        @change="enableSearch()"
                      ></v-select>
                    </div>
                  </v-flex>
                  <v-flex xs12 md3>
                    <div class="pa-1">
                      <v-menu
                        :close-on-content-click="false"
                        max-width="290"
                        transition="scale-transition"
                        v-model="dateFromPicker"
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            dense
                            :value="formattedDateFrom"
                            label="Date From"
                            slot="activator"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="text_field header"
                            @change="enableSearch()"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="searchData.date_from"
                          :max="dateToday"
                          @change="closeDateFrom"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </v-flex>
                  <v-flex xs12 md3>
                    <div class="pa-1">
                      <v-menu
                        :close-on-content-click="false"
                        max-width="290"
                        transition="scale-transition"
                        v-model="dateToPicker"
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            dense
                            :value="formattedDateTo"
                            label="Date To"
                            slot="activator"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="text_field header"
                            @change="enableSearch()"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="searchData.date_to"
                          :min="searchData.date_from"
                          :max="dateToday"
                          @change="closeDateTo"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </v-flex>
                  <v-flex xs12 md3>
                    <div class="pa-1">
                      <v-btn
                        class="primary text-none"
                        depressed
                        block
                        @click="search"
                        :loading="searchLoader"
                        :disabled="searchButtonDisabled"
                      >
                        Search
                        <v-icon right>mdi-magnify</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs12 md3>
                    <div class="pa-1">
                      <v-btn
                        class="secondary primary--text text-none"
                        depressed
                        block
                        @click="resetLogs"
                      >
                        Reset
                        <v-icon right>mdi-undo</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
            <v-flex xs12 class="mt-5">
              <div v-if="logs.length == 0">
                <v-card elevation="0">
                  <v-layout row wrap>
                    <v-flex xs12 md1>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center">
                            <v-icon large class="primary--text">
                              mdi-alert-circle-outline
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                    <v-flex xs12 md11>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center"> No Log Found </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                  </v-layout>
                </v-card>
              </div>
              <div v-else>
                <div class="hidden-sm-and-down">
                  <v-card elevation="0" class="pa-2">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead class="header">
                          <tr class="tableHeader">
                            <th class="text-left text--text">User</th>
                            <th class="text-left text--text">Model</th>
                            <th class="text-left text--text">Action</th>
                            <th class="text-left text--text">Date</th>
                            <th class="text-right text--text"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in logs" :key="item.id">
                            <td>
                                <div
                                  v-if="highLightId == item.id"
                                  class="green--text"
                                >
                                  <div v-if="item.modder != null">
                                    {{ item.modder.f_name }}
                                    {{ item.modder.m_name }}
                                    {{ item.modder.l_name }}
                                  </div>
                                  <div v-else>System</div>
                                </div>
                                <div v-else>
                                  <div v-if="item.modder != null">
                                    {{ item.modder.f_name }}
                                    {{ item.modder.m_name }}
                                    {{ item.modder.l_name }}
                                  </div>
                                  <div v-else>System</div>
                                </div>
                            </td>
                            <td>
                              <div
                                v-if="highLightId == item.id"
                                class="green--text"
                              >
                                {{ item.model }}
                              </div>
                              <div v-else>
                                {{ item.model }}
                              </div>
                            </td>
                            <td>
                              <div
                                v-if="highLightId == item.id"
                                class="green--text"
                              >
                                {{ item.action }}
                              </div>
                              <div v-else>
                                {{ item.action }}
                              </div>
                            </td>
                            <td>
                              <div
                                v-if="highLightId == item.id"
                                class="green--text"
                              >
                                {{
                                  item.created_at
                                    | moment("DD/MM/YYYY - hh:mm a")
                                }}
                              </div>
                              <div v-else>
                                {{
                                  item.created_at
                                    | moment("DD/MM/YYYY - hh:mm a")
                                }}
                              </div>
                            </td>
                            <td>
                              <div align="right">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      class="grey lighten-3 mr-1 primary--text"
                                      @click="showLog(item)"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small> mdi-eye </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>view</span>
                                </v-tooltip>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </div>
                <div class="hidden-md-and-up">
                  <v-layout column>
                    <template v-for="(log, index) in logs">
                      <div :key="index">
                        <v-flex xs12 class="mb-2">
                          <v-card elevation="0">
                            <div class="pa-5">
                              <v-layout column>
                                <v-flex xs12>
                                  <v-layout row wrap>
                                    <v-flex xs12>
                                      <div class="title primary--text">
                                        <b>
                                          <div
                                            v-if="log.modder != null"
                                            class="ml-2 text--text"
                                          >
                                            {{ log.modder.f_name }}
                                            {{ log.modder.m_name }}
                                            {{ log.modder.l_name }}
                                          </div>
                                          <div v-else>System</div>
                                        </b>
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-divider class="mt-2"></v-divider>
                                <v-flex xs12 class="mt-1">
                                  <v-layout column>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Model</b>
                                      </div>
                                      <div>{{ log.model }}</div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Action</b>
                                      </div>
                                      <div>{{ log.action }}</div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Date</b>
                                      </div>
                                      <div>
                                        {{
                                          log.created_at
                                            | moment("DD/MM/YYYY - hh:mm a")
                                        }}
                                      </div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1"> </v-flex>
                                    <v-flex xs12 class="mt-5">
                                      <v-layout row wrap>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn
                                              depressed
                                              class="primary text-none white--text"
                                              block
                                              @click="showLog(log)"
                                            >
                                              View
                                              <v-icon right> mdi-eye </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </div>
                          </v-card>
                        </v-flex>
                      </div>
                    </template>
                  </v-layout>
                </div>
              </div>
            </v-flex>
            
          </v-card>
          <v-layout column class="mt-5">
                
                <v-flex xs12>
                  <div align="center">
                    <v-pagination
                      v-if="length != 0 && length != 1"
                      :length="length"
                      total-visible="10"
                      v-model="logPagination.current_page"
                      @input="changePage()"
                      circle
                    >
                    </v-pagination>
                  </div>
                </v-flex>
                <v-flex xs12>
                    <div v-if="length != 0" align="center" class="my-5">
                    <b class="text--text">Total: </b
                    >{{ logPagination.total }}
                  </div>
                </v-flex>
              </v-layout>
        </div>
        <div v-if="view == 'show'">
          <v-card elevation="0" class="mt-4">
            <v-card-title class="tableHeader">
              <v-spacer></v-spacer>
              <v-btn class="text--text" icon @click="view = 'default'"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-card-title>
            <v-card-text>
              <v-layout column>
                <v-flex xs12>
                  <div
                    class="green--text mt-5"
                    v-if="assignedLog.models.changed != null"
                  >
                    <b>Changed</b>
                  </div>
                  <div class="mx-6 mb-5" v-if="assignedLog.models.changed != null">
                    <v-layout row wrap>
                      <template
                        v-for="(item, index) in assignedLog.models.changed"
                      >
                        <v-flex
                          xs12
                          md6
                          :key="index"
                          v-if="
                            index != 'updated_at' &&
                            index != 'created_at' &&
                            index != 'deleted_at'
                          "
                        >
                          <v-layout column>
                            <v-flex xs12 class="mt-5">
                              <v-layout row wrap>
                                <v-flex xs12>
                                  <b>{{ index }}:</b>
                                </v-flex>
                                <v-flex xs12>
                                  {{ item }}
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </template>
                    </v-layout>
                  </div>
                  <div
                    class="blue--text mt-5"
                    v-if="assignedLog.models.new != null"
                  >
                    <b>New</b>
                  </div>
                  <div class="mx-6 mb-5" v-if="assignedLog.models.new != null">
                    <v-layout row wrap>
                      <template v-for="(item, index) in assignedLog.models.new">
                        <v-flex
                          xs12
                          md6
                          :key="index"
                          v-if="
                            index != 'updated_at' &&
                            index != 'created_at' &&
                            index != 'deleted_at'
                          "
                        >
                          <v-layout column>
                            <v-flex xs12 class="mt-5">
                              <v-layout row wrap>
                                <v-flex xs12>
                                  <b>{{ index }}:</b>
                                </v-flex>
                                <v-flex xs12>
                                  {{ item }}
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </template>
                    </v-layout>
                  </div>
                  <div
                    class="red--text mt-5"
                    v-if="assignedLog.models.old != null"
                  >
                    <b>Old</b>
                  </div>
                  <div class="mx-6 mb-5" v-if="assignedLog.models.old != null">
                    <v-layout row wrap>
                      <template v-for="(item, index) in assignedLog.models.old">
                        <v-flex
                          xs12
                          md6
                          :key="index"
                          v-if="
                            index != 'updated_at' &&
                            index != 'created_at' &&
                            index != 'deleted_at'
                          "
                        >
                          <v-layout column>
                            <v-flex xs12 class="mt-5">
                              <v-layout row wrap>
                                <v-flex xs12>
                                  <b>{{ index }}:</b>
                                </v-flex>
                                <v-flex xs12>
                                  {{ item }}
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </template>
                    </v-layout>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </div>
        <div v-if="view == 'map'" style="height: 100%">
          <v-map
            ref="myMapRef"
            style="
              position: absolute;
              width: 100%;
              height: 80%;
              top: 115px;
              z-index: 2;
            "
            :zoom="15"
            :center="[latitude, longitude]"
            :options="tileOptions"
          >
            <v-tilelayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            ></v-tilelayer>
            <v-marker :lat-lng="[latitude, longitude]" :icon="icon"> </v-marker>
          </v-map>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {
      dashBreadcrumb: true,
      showBreadcrumb: false,

      view: "default",
      page: 1,
      loading: false,
      valid: true,
      message: "",
      color: "",
      delete: false,
      snackbar: false,
      searchTerm: null,
      userSearchTerm: null,
      searchLoader: false,
      userSearchLoader: false,
      searchButtonDisabled: false,
      userSearchButtonDisabled: false,
      filteredLogs: false,
      assignedLog: null,
      highLightId: null,
      userHighLightId: null,

      searchData: {
        userName: null,
        userId: null,
        model: null,
        action: null,
        date_from: null,
        date_to: null,
      },

      userDialog: false,
      customerTypeFilter: null,
      filteredUsers: false,

      dateFromPicker: false,
      dateToPicker: false,

      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      monthNames: [
        { name: "January" },
        { name: "February" },
        { name: "March" },
        { name: "April" },
        { name: "May" },
        { name: "June" },
        { name: "July" },
        { name: "August" },
        { name: "September" },
        { name: "October" },
        { name: "November" },
        { name: "December" },
      ],
    };
  },
  created() {
    if (this.$can("log_view")) {
        this.fetchLogs(this.logPagination.current_page);
        this.getFormData();
    }        
  },
  methods: {
    ...mapActions([
      "fetchLogs",
      "startLogLoader",
      "stopLogLoader",
      "filterLogs",
      "filterModels",
      "fetchUsers",
      "filterUsers",
      "startUserLoader",
      "stopUserLoader",
    ]),
    openUserDialog() {
      this.userDialog = true;
    },
    enableSearch() {
      this.searchButtonDisabled = false;
    },
    enableUserSearch() {
      this.userSearchButtonDisabled = false;
    },
    resetUserSearch() {
      this.userSearchTerm = null;
      this.startUserLoader();
      this.fetchUsers(1);
    },
    getFormData() {
      apiCall({
        url: "/api/log?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.filterModels(resp);
        })
        .catch((error) => {
          this.message = "An Error Occurred couldn't Get Form Data";
          this.color = "error";
        });
    },
    resetSearch() {
      (this.searchTerm = null), this.startLogLoader();
      this.fetchLogs(1);
    },
    resetLogs() {
      this.searchData.userName = null;
      this.searchData.userId = null;
      this.searchData.model = null;
      this.searchData.action = null;
      this.searchData.date_from = null;
      this.searchData.date_to = null;

      this.startLogLoader();
      this.fetchLogs(1);
    },
    showLog(log) {
      this.assignedLog = log;
      this.highLightId = log.id;
      this.changeView("show");
    },
    selectUser(user) {
      this.userHighLightId = user.id;
      this.searchData.userName =
        user.f_name + " " + user.m_name + " " + user.l_name;
      this.searchData.userId = user.id;
      this.userDialog = false;
    },
    search() {
      if (
        this.searchData.userId == null &&
        this.searchData.model == null &&
        this.searchData.action == null &&
        this.searchData.date_from == null &&
        this.searchData.date_to == null
      ) {
        this.searchButtonDisabled = true;
      } else {
        this.searchLoader = true;
        this.searchButtonDisabled = false;
        this.startLogLoader();
        apiCall({
          url: "/api/log",
          data: this.searchData,
          method: "POST",
        })
          .then((resp) => {
            this.filterLogs(resp);
            this.searchLoader = false;
            this.stopLogLoader();
            this.filteredLogs = true;
          })
          .catch((error) => {
            console.log(error.response);
            this.searchLoader = false;
            this.stopLogLoader();
            this.message = "An error occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    searchUser() {
      if (this.userSearchTerm == null) {
        this.userSearchButtonDisabled = true;
      } else {
        this.userSearchLoader = true;
        this.userSearchButtonDisabled = false;
        this.startUserLoader();
        apiCall({
          url: "/api/user?type=search&search=" + this.userSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterUsers(resp);
            this.userSearchLoader = false;
            this.stopUserLoader();
            this.filteredUsers = true;
          })
          .catch((error) => {
            console.log(error.response);
            this.userSearchLoader = false;
            this.stopUserLoader();
            this.message = "anErrorOccurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    changeUserPage() {
      this.startUserLoader();
      if (this.filteredUsers == true) {
        this.searchButtonDisabled = false;
        apiCall({
          url:
            "/api/user?page=" +
            this.userPagination.current_page +
            "&type=search&search=" +
            this.userSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterUsers(resp);
            this.stopUserLoader();
          })
          .catch((error) => {
            this.stopUserLoader();
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        this.fetchUsers(this.userPagination.current_page);
      }
    },
    changeView(view) {
      if (view == "default") {
        this.dashBreadcrumb = true;
        this.showBreadcrumb = false;
      } else if (view == "create") {
      } else if (view == "show") {
        this.dashBreadcrumb = false;
        this.showBreadcrumb = true;
      } else if (view == "map") {
        this.dashBreadcrumb = false;
        this.showBreadcrumb = true;
      }
      this.view = view;
    },
    changePage() {
      this.startLogLoader();
      if (this.filteredLogs == true) {
        this.searchButtonDisabled = false;
        apiCall({
          url: "/api/log?page=" + this.logPagination.current_page,
          data: this.searchData,
          method: "POST",
        })
          .then((resp) => {
            this.filterLogs(resp);
            this.stopLogLoader();
          })
          .catch((error) => {
            console.log(error.response);
            this.stopLogLoader();
            this.message = "An error occurred";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        this.fetchLogs(this.logPagination.current_page);
      }
    },
    closeDateFrom() {
      this.searchButtonDisabled = false;
      this.dateFromPicker = false;
    },
    closeDateTo() {
      this.searchButtonDisabled = false;
      this.dateToPicker = false;
    },
  },
  computed: {
    ...mapGetters([
      "logs",
      "logPagination",
      "logLoader",
      "systemModels",

      "users",
      "userLoader",
      "userPagination",
    ]),
    length: function () {
      return Math.ceil(this.logPagination.total / this.logPagination.per_page);
    },
    userLength: function () {
      return Math.ceil(
        this.userPagination.total / this.userPagination.per_page
      );
    },
    formattedDateFrom() {
      if (this.searchData.date_from != null) {
        const d = new Date(this.searchData.date_from);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
    formattedDateTo() {
      if (this.searchData.date_to != null) {
        const d = new Date(this.searchData.date_to);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
    dateToday() {
      var today = new Date();

      var timestamp =
        today.getFullYear() +
        "-" +
        this.months[today.getMonth()] +
        "-" +
        ("0" + today.getDate()).slice(-2);
      return timestamp;
    },
  },
};
</script>
