<template>
    <div class="publicBroadcasts">
        <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
            <span>{{ message }}</span>
        </v-snackbar>
        <v-container>
            <v-layout column>
                <div v-if="view == 'default'">
                    <v-card outlined class="mt-5">
                        <v-progress-linear v-if="broadcastLoader" height="1" indeterminate color="primary">
                        </v-progress-linear>
                        <v-flex xs12 class="mt-10 mb-2">
                            <div class="mx-5">
                                <v-layout row wrap>
                                    <v-flex xs12 md4>
                                        <h1>Broadcasts</h1>
                                    </v-flex>
                                    <v-flex xs12 md4>
                                        <div class="pa-1">
                                            <v-text-field class="text_field header" outlined dense label="Search"
                                                append-icon="mdi-undo-variant" @click:append="resetSearch()"
                                                v-on:keyup.enter="search" v-model="searchTerm"
                                                @input="enableSearch()"></v-text-field>
                                        </div>
                                    </v-flex>
                                    <v-flex xs12 md2>
                                        <div class="pa-1">
                                            <v-btn class="secondary primary--text text-none" depressed block @click="search"
                                                :loading="searchLoader" :disabled="searchButtonDisabled">
                                                Search
                                                <v-icon right>mdi-magnify</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-flex>
                                    <v-flex xs12 md2>
                                        <div class="pa-1">
                                            
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </v-flex>
                        <v-divider class="mt-9 mx-5"></v-divider>
                        <v-flex xs12 class="mt-5">
                            <div v-if="publicBroadcasts.length == 0">
                                <v-card elevation="0">
                                    <v-layout row wrap>
                                        <v-flex xs12 md1>
                                            <v-container fill-height fluid>
                                                <v-row align="center" justify="center">
                                                    <v-col class="text-center">
                                                        <v-icon large class="primary--text">
                                                            mdi-alert-circle-outline
                                                        </v-icon>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-flex>
                                        <v-flex xs12 md11>
                                            <v-container fill-height fluid>
                                                <v-row align="center" justify="center">
                                                    <v-col class="text-center">
                                                        No Broadcast Found
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </div>
                            <div v-else>
                                <div class="hidden-sm-and-down">
                                    <v-card elevation="0" class="pa-2">
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <thead class="header">
                                                    <tr class="tableHeader">
                                                        <th class="text-left text--text">Subject</th>
                                                        <th class="text-left text--text">Message</th>
                                                        <th class="text-left text--text">Date Created</th>
                                                        <th class="text-right text--text">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="item in publicBroadcasts" :key="item.id">
                                                        <td>{{ item.subject }}</td>
                                                        <td>
                                                            <v-clamp autoresize :max-lines="2"
                                                                >{{item.message}}
                                                            </v-clamp>
                                                        </td>
                                                        <td>
                                                            {{
                                                                item.created_at | moment("DD/MM/YYYY - hh:mm a")
                                                            }}
                                                        </td>
                                                        <td>
                                                            <div align="right">
                                                                <v-btn icon
                                                                    @click="showBroadcast(item)"
                                                                    class="button mr-1 universal--text">
                                                                    <v-icon small> mdi-eye </v-icon>
                                                                </v-btn>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card>
                                </div>
                                <div class="hidden-md-and-up">
                                    <div>
                                        <v-layout column>
                                            <template v-for="(broadcast, index) in publicBroadcasts">
                                                <div :key="index">
                                                    <v-flex xs12 class="mb-2">
                                                        <v-card elevation="0">
                                                            <div class="pa-5">
                                                                <v-layout column>
                                                                    <v-flex xs12>
                                                                        <v-layout row wrap>
                                                                            <v-flex xs12>
                                                                                <div class="title text--text ml-3">
                                                                                    <b>{{ broadcast.subject }}</b>
                                                                                </div>
                                                                            </v-flex>
                                                                            
                                                                        </v-layout>
                                                                    </v-flex>
                                                                    <v-divider class="mt-2"></v-divider>
                                                                    <v-flex xs12 class="mt-1">
                                                                        <v-layout column>
                                                                            <v-flex xs12 class="mt-1">
                                                                                <div>
                                                                                    <b>Message</b>
                                                                                </div>
                                                                                <div>
                                                                                    <v-clamp autoresize :max-lines="2"
                                                                                        >{{broadcast.message}}
                                                                                    </v-clamp>
                                                                                </div>
                                                                            </v-flex>
                                                                            <v-flex xs12 class="mt-1">
                                                                                <div>
                                                                                    <b>Date Created</b>
                                                                                </div>
                                                                                <div>
                                                                                    {{
                                                                                        broadcast.created_at
                                                                                        | moment("DD/MM/YYYY - hh:mm a")
                                                                                    }}
                                                                                </div>
                                                                            </v-flex>

                                                                            <v-flex xs12 class="mt-5">
                                                                                <v-layout row wrap>
                                                                                    <v-flex xs12>
                                                                                        <div class="pa-1">
                                                                                            <v-btn depressed
                                                                                                class="primary text-none white--text mt-3"
                                                                                                block @click="showBroadcast(
                                                                                                            broadcast
                                                                                                        )
                                                                                                        ">View
                                                                                                <v-icon right> mdi-eye
                                                                                                </v-icon>
                                                                                            </v-btn>
                                                                                        </div>
                                                                                    </v-flex>
                                                                                </v-layout>
                                                                            </v-flex>
                                                                        </v-layout>
                                                                    </v-flex>
                                                                </v-layout>
                                                            </div>
                                                        </v-card>
                                                    </v-flex>
                                                </div>
                                            </template>
                                        </v-layout>
                                    </div>
                                </div>
                            </div>
                        </v-flex>
                    </v-card>
                    <v-layout column class="mt-5">
                        <v-flex xs12>
                            <div align="left">
                                <v-pagination v-if="length != 0" :length="length" total-visible="10"
                                    v-model="publicBroadcastPagination.current_page" @input="changePage()" circle>
                                </v-pagination>
                            </div>
                        </v-flex>
                        <v-flex xs12>
                            <div v-if="length != 0" align="center" class="mt-5">
                                <b class="primary--text">Total: </b>{{ publicBroadcastPagination.total | formatNumber }}
                            </div>
                        </v-flex>
                    </v-layout>
                </div>
                
                <div v-if="view == 'show'">
                    <v-card outlined class="mt-5">
                        <v-progress-linear v-if="broadcastLoader" height="1" indeterminate color="primary">
                        </v-progress-linear>
                        <v-card-title class="tableHeader">
                            <v-spacer></v-spacer>
                            <div class="text--text">View Broadcast</div>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="changeView('default')">
                                <v-icon class="text--text"> mdi-close </v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <div class="pa-1">
                                <v-card-text>
                                    <div class="pa-3">                                            
                                        <v-layout column class="mt-3">
                                            <v-flex xs12 class="mt-2">
                                                <v-layout row wrap>
                                                    <v-flex xs12 md2>
                                                        <b>Subject: </b>
                                                    </v-flex>
                                                    <v-flex xs12 md10>
                                                        <div>
                                                            {{ assignedBroadcast.subject }}
                                                        </div>
                                                    </v-flex>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs12 class="mt-6">
                                                <v-layout row wrap>
                                                    <v-flex xs12 md2>
                                                        <b>Date Created: </b>
                                                    </v-flex>
                                                    <v-flex xs12 md10>
                                                        <div>
                                                            {{
                                                                assignedBroadcast.created_at
                                                                | moment("DD/MM/YYYY - hh:mm a")
                                                            }}
                                                        </div>
                                                    </v-flex>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs12 class="mt-10">
                                                <v-layout row wrap>
                                                    <v-flex xs12 md2>
                                                        <b>Message: </b>
                                                    </v-flex>
                                                    <v-flex xs12 md10>
                                                        <div v-html="assignedBroadcast.message">
                                                            
                                                        </div>
                                                    </v-flex>
                                                </v-layout>
                                            </v-flex>
                                            
                                        </v-layout>                                               
                                    </div>
                                </v-card-text>
                            </div>
                        </v-card-text>
                    </v-card>
                </div>
            </v-layout>
        </v-container>
    </div>
</template>
<style>
.ck-editor__editable {
    min-height: 200px;
}
</style>
<script>
import Vue from "vue";
import apiCall from "@/utils/api";
import { mapGetters, mapState, mapActions } from "vuex";
import logData from "@/views/accessControl/logData";
import VClamp from "vue-clamp";

export default {
    components: {
        logData,
        VClamp,
    },
    data() {
        return {
            path: process.env.VUE_APP_API_URL,
            view: "default",

            message: "",
            color: "",

            snackbar: false,
            inputRules: [(v) => !!v || "Input is required"],

            searchTerm: null,
            userSearchTerm: null,

            searchLoader: false,
            searchButtonDisabled: false,

            assignedBroadcast: null,

            searchTerm: null,
            searchLoader: false,

            searchButtonDisabled: false,

            filteredBroadcasts: [],

            logData: {
                message: null,
                model: null,
            },
        };
    },

    created() {
        this.startBroadcastLoader();
        this.fetchPublicBroadcasts(this.publicBroadcastPagination.current_page);
        this.getFormData();
    },
    methods: {
        ...mapActions([
            "fetchPublicBroadcasts",
            "startBroadcastLoader",
            "stopBroadcastLoader",
            "filterPublicBroadcasts",

            "filterLogData",
            "openLogDataModel",
        ]),

        enableSearch() {
            this.searchButtonDisabled = false;
        },
        resetSearch() {
            this.searchTerm = null;
            this.filteredBroadcasts = true;
            this.startBroadcastLoader();
            this.fetchPublicBroadcasts(1);
        },

        showBroadcast(broadcast) {
            this.assignedBroadcast = broadcast;

            this.changeView("show");
        },

        search() {
            if (this.searchTerm == null) {
                this.searchButtonDisabled = true;
            } else {
                this.searchLoader = true;
                this.searchButtonDisabled = false;
                this.startBroadcastLoader();
                apiCall({
                    url: "/api/broadcast?type=publicSearch&search=" + this.searchTerm,
                    method: "GET",
                })
                    .then((resp) => {
                        this.filterPublicBroadcasts(resp);
                        this.searchLoader = false;
                        this.stopBroadcastLoader();
                        this.filteredBroadcasts = true;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.searchLoader = false;
                        this.stopBroadcastLoader();
                        this.message = "An Error Occurred";
                        this.color = "error";
                        this.snackbar = true;
                    });
            }
        },
        changeView(view) {
            if (view == "default") {
                
            } else if (view == "create") {
                this.getFormData();
            } else if (view == "show") {
            }
            this.view = view;
        },
        changePage() {
            this.startBroadcastLoader();
            if (this.filteredBroadcasts == true) {
                this.searchButtonDisabled = false;
                apiCall({
                    url:
                        "/api/broadcast?page=" +
                        this.publicBroadcastPagination.current_page +
                        "&type=search&search=" +
                        this.searchTerm,
                    method: "GET",
                })
                    .then((resp) => {
                        this.filterPublicBroadcasts(resp);
                        this.stopBroadcastLoader();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.stopBroadcastLoader();
                        this.message = "An Error Occurred";
                        this.color = "error";
                        this.snackbar = true;
                    });
            } else {
                this.fetchPublicBroadcasts(
                    this.publicBroadcastPagination.current_page
                );
            }
        },

    },
    computed: {
        ...mapGetters([
            "publicBroadcasts",
            "publicBroadcastPagination",
            "broadcastLoader",
        ]),
        length: function () {
            return Math.ceil(
                this.publicBroadcastPagination.total /
                this.publicBroadcastPagination.per_page
            );
        },
    },
};
</script>
  