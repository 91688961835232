<template>
  <div class="Logs" v-if="$can('report_view')">
    <div class="pa-5">
      <v-layout row wrap>
        <v-flex xs12 md2>
          <v-card outlined class="mt-5">
            <v-tabs vertical v-model="tab">
              <v-tab key="Users" @click="switchTab('Users')">
                <v-icon left> mdi-account-group-outline </v-icon>
                <div class="text-none">Users</div>
              </v-tab>
              <v-tab key="Innovations" @click="switchTab('Innovations')">
                <v-icon left> mdi-spa-outline </v-icon>
                <div class="text-none">Innovations</div>
              </v-tab>
              <v-tab key="Computed Reports" @click="switchTab('Computed Reports')">
                <v-icon left> mdi-chart-bell-curve-cumulative </v-icon>
                <div class="text-none">Computed Reports</div>
              </v-tab>
            </v-tabs>
          </v-card>
        </v-flex>
        <v-flex xs12 md10>
          <div class="mx-2">
            <v-card v-if="view == 'Users'" outlined class="mt-5">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-select
                        :items="formData.counties"
                        outlined
                        dense
                        v-model="filterData.county_id"
                        label="County"
                        item-text="name"
                        item-value="id"
                        class="background login_field"
                        @change="enableSearch()"
                      ></v-select>
                    </div>
                  </v-flex>
                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-select
                        :items="formData.genders"
                        outlined
                        dense
                        v-model="filterData.gender_id"
                        label="Gender"
                        item-text="name"
                        item-value="id"
                        class="background login_field"
                        @change="enableSearch()"
                      ></v-select>
                    </div>
                  </v-flex>
                  <v-flex x12 md4>
                    <div class="pa-1">
                      <v-select
                        :items="accountStatuses"
                        outlined
                        dense
                        v-model="filterData.account_status"
                        label="Account Status"
                        item-text="name"
                        item-value="id"
                        class="background login_field"
                        @change="enableSearch()"
                      ></v-select>
                    </div>
                  </v-flex>
                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-menu
                        :close-on-content-click="false"
                        max-width="290"
                        transition="scale-transition"
                        v-model="dateFromPicker"
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            dense
                            :value="formattedDateFrom"
                            label="Date From"
                            slot="activator"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="background login_field"
                            @change="enableSearch()"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="filterData.date_from"
                          :max="dateToday"
                          @change="closeDateFrom"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </v-flex>
                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-menu
                        :close-on-content-click="false"
                        max-width="290"
                        transition="scale-transition"
                        v-model="dateToPicker"
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            dense
                            :value="formattedDateTo"
                            label="Date To"
                            slot="activator"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="background login_field"
                            @change="enableSearch()"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="filterData.date_to"
                          :min="filterData.date_from"
                          :max="dateToday"
                          @change="closeDateTo"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </v-flex>

                  <v-flex x12 md4>
                    <div class="pa-1">
                      <v-select
                        :items="ageFilters"
                        outlined
                        dense
                        v-model="filterData.age_filter"
                        label="Age Filter"
                        item-text="name"
                        item-value="value"
                        class="background login_field"
                        @change="enableSearch()"
                      ></v-select>
                    </div>
                  </v-flex>

                  <v-flex
                    xs12
                    class="mt-3"
                    v-if="filterData.age_filter == true"
                  >
                    <div class="ml-1"><b>Age</b></div>
                  </v-flex>
                  <v-flex xs12 v-if="filterData.age_filter == true">
                    <div class="pa-1">
                      <!-- <div>County</div> -->
                      <v-range-slider
                        v-model="filterData.age_range"
                        :max="max"
                        :min="min"
                        hide-details
                        class="align-center"
                      >
                        <template v-slot:prepend>
                          <v-text-field
                            :value="filterData.age_range[0]"
                            class="text_field background"
                            outlined
                            dense
                            type="number"
                            style="width: 60px"
                            @change="$set(range, 0, $event)"
                          >
                          </v-text-field>
                        </template>
                        <template v-slot:append>
                          <v-text-field
                            :value="filterData.age_range[1]"
                            class="text_field background"
                            outlined
                            dense
                            type="number"
                            style="width: 60px"
                            @change="$set(range, 1, $event)"
                          >
                          </v-text-field>
                        </template>
                      </v-range-slider>
                    </div>
                  </v-flex>
                  <v-flex xs12 class="mt-3">
                    <div class="ml-1"><b>Role(s)</b></div>
                  </v-flex>
                  <v-flex x12>
                    <div class="mx-4">
                      <v-layout row wrap>
                        <template v-for="(role, index) in formData.roles">
                          <v-flex xs12 md4 :key="index">
                            <v-checkbox
                              v-model="filterData.roles"
                              :label="role.name"
                              :value="role.id"
                              hide-details
                            >
                            </v-checkbox>
                          </v-flex>
                        </template>
                      </v-layout>
                    </div>
                  </v-flex>
                  <v-flex xs12 class="mt-3">
                    <div align="right">
                      <v-btn
                        class="primary text-none mt-5"
                        depressed
                        @click="searchUser"
                        :loading="searchLoader"
                        :disabled="searchButtonDisabled"
                      >
                        Search
                        <v-icon right>mdi-magnify</v-icon>
                      </v-btn>
                      <v-btn
                        class="secondary primary--text text-none mt-5 mx-1"
                        depressed
                        @click="resetUser"
                        :loading="resetLoader"
                      >
                        Reset
                        <v-icon right>mdi-undo</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
            <v-card
              outlined
              v-if="view == 'Users'"
              class="mt-5"
              style="border-radius: 25px"
            >
              <div v-if="users.length == 0">
                <v-card elevation="0">
                  <v-layout row wrap>
                    <v-flex xs12 md1>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center">
                            <v-icon large class="text--text">
                              mdi-alert-circle-outline
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                    <v-flex xs12 md11>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center"> No User Found </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                  </v-layout>
                </v-card>
              </div>
              <div v-else class="mb-5">
                <div align="right">
                  <v-btn
                    rounded
                    depressed
                    class="white--text excel text-none mr-3 my-3"
                    :loading="csvLoading"
                    @click="csvUserDownload()"
                  >
                    Download CSV
                    <v-icon right>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </div>
                <div class="hidden-sm-and-down">
                  <v-card elevation="0" class="pa-2">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead class="header">
                          <tr class="tableHeader">
                            <th class="text-left text--text">Name</th>
                            <th class="text-left text--text">Email</th>
                            <th class="text-left text--text">Phone</th>
                            <th class="text-left text--text">Role(s)</th>
                            <th class="text-left text--text">Account Active</th>
                            <th class="text-left text--text">
                              Account Creation Date
                            </th>
                            <th class="text-left text--text">County</th>
                            <th class="text-left text--text">Gender</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in users" :key="item.id">
                            <td>
                              {{ item.f_name }} {{ item.m_name }}
                              {{ item.l_name }}
                            </td>
                            <td>{{ item.email }}</td>
                            <td>{{ item.phone }}</td>
                            <td>
                              <div v-if="item.roles.length != 0">
                                <div v-for="item in item.roles" :key="item.id">
                                  {{ item.name }}
                                </div>
                              </div>
                              <div v-else>None</div>
                            </td>
                            <td>
                              <div v-if="item.active == 1">Yes</div>
                              <div v-else>No</div>
                            </td>
                            <td>
                              {{
                                item.created_at | moment("DD/MM/YYYY - hh:mm a")
                              }}
                            </td>
                            <td>
                              <div v-if="item.user_profile == null">N/A</div>
                              <div v-else>
                                <div v-if="item.user_profile.county_id == null">
                                  N/A
                                </div>
                                <div v-else>
                                  {{ item.user_profile.county.name }}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div v-if="item.user_profile == null">N/A</div>
                              <div v-else>
                                <div v-if="item.user_profile.gender_id == null">
                                  N/A
                                </div>
                                <div v-else>
                                  {{ item.user_profile.gender.name }}
                                </div>
                              </div>
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </div>
                <div class="hidden-md-and-up">
                  <v-layout column>
                    <template v-for="(user, index) in users">
                      <div :key="index">
                        <v-flex xs12 class="mb-2">
                          <v-card elevation="0">
                            <div class="pa-5">
                              <v-layout column>
                                <v-flex xs12>
                                  <v-layout row wrap>
                                    <v-flex xs11>
                                      <div class="title text--text ml-3">
                                        <b
                                          >{{ user.f_name }} {{ user.m_name }}
                                          {{ user.l_name }}</b
                                        >
                                      </div>
                                    </v-flex>
                                    <v-flex xs1> </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-divider class="mt-2"></v-divider>
                                <v-flex xs12 class="mt-1">
                                  <v-layout column>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Email Address</b>
                                      </div>
                                      <div>{{ user.email }}</div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Role(s)</b>
                                      </div>
                                      <div>
                                        <div v-if="user.roles.length != 0">
                                          <div
                                            v-for="user in user.roles"
                                            :key="user.id"
                                          >
                                            {{ user.name }}
                                          </div>
                                        </div>
                                        <div v-else>None</div>
                                      </div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Account Active</b>
                                      </div>
                                      <div>
                                        <div v-if="user.active == 1">Yes</div>
                                        <div v-else>No</div>
                                      </div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Account Creation Date</b>
                                      </div>
                                      <div>
                                        {{
                                          user.created_at
                                            | moment("DD/MM/YYYY - hh:mm a")
                                        }}
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </div>
                          </v-card>
                        </v-flex>
                      </div>
                    </template>
                  </v-layout>
                </div>
              </div>
            </v-card>
            <v-layout column class="mt-5" v-if="view == 'Users'">
              <v-flex xs12>
                <div align="center">
                  <v-pagination
                    v-if="userLength != 0"
                    :length="userLength"
                    total-visible="10"
                    v-model="userPagination.current_page"
                    @input="changeUserPage()"
                    circle
                  >
                  </v-pagination>
                </div>
              </v-flex>
              <v-flex xs12>
                <div v-if="userLength != 0" align="center" class="mt-5">
                  <b class="text--text">Total: </b
                  >{{ userPagination.total | formatNumber }}
                </div>
              </v-flex>
            </v-layout>

            <v-card v-if="view == 'Innovations'" outlined class="mt-5">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-select
                        :items="formData.counties"
                        outlined
                        dense
                        v-model="filterData.county_id"
                        label="County"
                        item-text="name"
                        item-value="id"
                        class="background login_field"
                        @change="enableSearch()"
                      ></v-select>
                    </div>
                  </v-flex>
                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-select
                        :items="formData.stages"
                        outlined
                        dense
                        v-model="filterData.stage_id"
                        label="Stage"
                        item-text="name"
                        item-value="id"
                        class="background login_field"
                        @change="enableSearch()"
                      ></v-select>
                    </div>
                  </v-flex>
                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-select
                        :items="formData.ipProtectionTypes"
                        outlined
                        dense
                        v-model="filterData.ip_protection_type_id"
                        label="IP Protection Type"
                        item-text="name"
                        item-value="id"
                        class="background login_field"
                        @change="enableSearch()"
                      ></v-select>
                    </div>
                  </v-flex>
                  <v-flex x12 md4>
                    <div class="pa-1">
                      <v-select
                        :items="accountStatuses"
                        outlined
                        dense
                        v-model="filterData.account_status"
                        label="Approval Status"
                        item-text="name"
                        item-value="id"
                        class="background login_field"
                        @change="enableSearch()"
                      ></v-select>
                    </div>
                  </v-flex>
                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-menu
                        :close-on-content-click="false"
                        max-width="290"
                        transition="scale-transition"
                        v-model="dateFromPicker"
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            dense
                            :value="formattedDateFrom"
                            label="Date From"
                            slot="activator"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="background login_field"
                            @change="enableSearch()"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="filterData.date_from"
                          :max="dateToday"
                          @change="closeDateFrom"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </v-flex>
                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-menu
                        :close-on-content-click="false"
                        max-width="290"
                        transition="scale-transition"
                        v-model="dateToPicker"
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            dense
                            :value="formattedDateTo"
                            label="Date To"
                            slot="activator"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="background login_field"
                            @change="enableSearch()"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="filterData.date_to"
                          :min="filterData.date_from"
                          :max="dateToday"
                          @change="closeDateTo"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </v-flex>

                  <v-flex x12 md4>
                    <div class="pa-1">
                      <v-select
                        :items="offerFilters"
                        outlined
                        dense
                        v-model="filterData.offer_filter"
                        label="Offers"
                        item-text="name"
                        item-value="value"
                        class="background login_field"
                        @change="enableSearch()"
                      ></v-select>
                    </div>
                  </v-flex>
                  <v-flex xs12 md4 v-if="filterData.offer_filter == true">
                    <div class="pa-1">
                      <v-select
                        :items="formData.fundingTypes"
                        outlined
                        dense
                        v-model="filterData.funding_type_id"
                        label="Funding Type"
                        item-text="name"
                        item-value="id"
                        class="background login_field"
                        @change="enableSearch()"
                      ></v-select>
                    </div>
                  </v-flex>
                  <v-flex xs12 md4 v-if="filterData.offer_filter == true">
                    <div class="pa-1">
                      <v-select
                        :items="offerResponses"
                        outlined
                        dense
                        v-model="filterData.offer_status"
                        label="Offer Responses"
                        item-text="name"
                        item-value="id"
                        class="background login_field"
                        @change="enableSearch()"
                      ></v-select>
                    </div>
                  </v-flex>
                  <v-flex xs12 md4>
                    <div class="mx-1">
                      <v-checkbox
                        v-model="filterData.interest_filter"
                        label="Show more Filters"
                        hide-details
                      >
                      </v-checkbox>
                    </div>
                  </v-flex>



                  <v-flex
                    xs12
                    class="mt-3"
                    v-if="filterData.offer_filter == true"
                  >
                    <div class="ml-1"><b>Amount Range</b></div>
                  </v-flex>
                  <v-flex xs12 v-if="filterData.offer_filter == true">
                    <div class="pa-1">
                      <!-- <div>County</div> -->
                      <v-range-slider
                        v-model="filterData.amount_range"
                        :max="filterData.amount_range[1]"
                        :min="filterData.amount_range[0]"
                        hide-details
                        class="align-center"
                      >
                        <template v-slot:prepend>
                          <v-text-field
                            :value="filterData.amount_range[0]"
                            class="text_field background"
                            outlined
                            dense
                            type="number"
                            style="width: 150px"
                            @change="$set(range, 0, $event)"
                          >
                          </v-text-field>
                        </template>
                        <template v-slot:append>
                          <v-text-field
                            :value="filterData.amount_range[1]"
                            class="text_field background"
                            outlined
                            dense
                            type="number"
                            style="width: 150px"
                            @change="$set(range, 1, $event)"
                          >
                          </v-text-field>
                        </template>
                      </v-range-slider>
                    </div>
                  </v-flex>

                  <v-flex xs12 class="mt-10" v-if="filterData.interest_filter==true">
                      <v-tabs grow>
                      <v-tabs-slider color="primary"></v-tabs-slider>
                        <template v-for="(tab, index) in innovationFilterTabs">
                          <v-tab
                            class="text-none"
                            @click="activeInnovationFilterTab(tab.name)"
                            :key="index"
                          >
                            {{ tab.name }}
                          </v-tab>
                        </template>
                      </v-tabs>
                  </v-flex>
                  <v-flex x12  v-if="filterData.interest_filter==true">
                    <div class="mx-4 my-5" v-if="activeInnovationFilter == 'Interest(s)'">
                      <v-layout row wrap>
                        <template v-for="(interest, index) in formData.interests">
                          <v-flex xs12 md4 :key="index">
                            <v-checkbox
                              v-model="filterData.interests"
                              :label="interest.name"
                              :value="interest.id"
                              hide-details
                            >
                            </v-checkbox>
                          </v-flex>
                        </template>
                      </v-layout>
                    </div>
                    <div class="mx-4 my-5" v-if="activeInnovationFilter == 'Sector(s)'">
                      <v-layout row wrap>
                        <template v-for="(sector, index) in formData.sectors">
                          <v-flex xs12 md4 :key="index">
                            <v-checkbox
                              v-model="filterData.sectors"
                              :label="sector.name"
                              :value="sector.id"
                              hide-details
                            >
                            </v-checkbox>
                          </v-flex>
                        </template>
                      </v-layout>
                    </div>
                    <div class="mx-4 my-5" v-if="activeInnovationFilter == 'SDG(s)'">
                      <v-layout row wrap>
                        <template v-for="(sdg, index) in formData.sdgs">
                          <v-flex xs12 md4 :key="index">
                            <v-checkbox
                              v-model="filterData.sdgs"
                              :label="sdg.name"
                              :value="sdg.id"
                              hide-details
                            >
                            </v-checkbox>
                          </v-flex>
                        </template>
                      </v-layout>
                    </div>
                  </v-flex>

                 
     
                  <v-flex xs12 class="mt-3">
                    <div align="right">
                      <v-btn
                        class="primary text-none mt-5"
                        depressed
                        @click="searchInnovation"
                        :loading="searchLoader"
                        :disabled="searchButtonDisabled"
                      >
                        Search
                        <v-icon right>mdi-magnify</v-icon>
                      </v-btn>
                      <v-btn
                        class="secondary primary--text text-none mt-5 mx-1"
                        depressed
                        @click="resetUser"
                        :loading="resetLoader"
                      >
                        Reset
                        <v-icon right>mdi-undo</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
            <v-card
              outlined
              v-if="view == 'Innovations'"
              class="mt-5"
              style="border-radius: 25px"
            >
              <div v-if="innovations.length == 0">
                <v-card elevation="0">
                  <v-layout row wrap>
                    <v-flex xs12 md1>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center">
                            <v-icon large class="text--text">
                              mdi-alert-circle-outline
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                    <v-flex xs12 md11>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center"> No Innovation(s) Found </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                  </v-layout>
                </v-card>
              </div>
              <div v-else class="mb-5">
                <div align="right">
                  <v-btn
                    rounded
                    depressed
                    class="white--text excel text-none mr-3 my-3"
                    :loading="csvLoading"
                    @click="csvInnovationDownload()"
                  >
                    Download CSV
                    <v-icon right>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </div>
                <div class="hidden-sm-and-down">
                  <v-card elevation="0" class="pa-2">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead class="header">
                          <tr class="tableHeader">
                            <th class="text-left text--text">Logo</th>
                            <th class="text-left text--text">Name</th>
                            <th class="text-left text--text">IP Protection Type</th>
                            <th class="text-left text--text">Stage</th>
                            <th class="text-left text--text">County</th>
                            <th class="text-left text--text">Status</th>
                            <th class="text-left text--text">Date Created</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in innovations" :key="item.id">
                            <td>
                              <div class="my-2">
                                <v-avatar size="48" v-if="item.featured_image==null">
                                  <v-img
                                    max-height="150"
                                    
                                    src="img/logo_fav.png"
                                  >
                                  </v-img>
                                </v-avatar>
                                <v-avatar size="48" v-else>
                                  <v-img
                                      max-height="150"
                                      :src="path+'/storage/innovation_pictures/'+item.featured_image"
                                    >
                                    </v-img>
                                </v-avatar>
                              </div>
                            </td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.ip_protection_type.name }}</td>
                            <td>{{ item.stage.name }}</td>
                            <td>{{ item.county.name }}</td>
                            <td>
                              <div v-if="item.status == 1">Active</div>
                              <div v-else>Inactive</div>
                            </td>
                            <td>
                              {{
                                item.created_at | moment("DD/MM/YYYY - hh:mm a")
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </div>
                <div class="hidden-md-and-up">
                  <div>
                    <v-layout column>
                      <template v-for="(innovation, index) in innovations">
                        <div :key="index">
                          <v-flex xs12 class="mb-2">
                            <v-card elevation="0">
                              <div class="pa-5">
                                <v-layout column>
                                  <v-flex xs12>
                                    <v-layout row wrap>
                                      <v-flex xs11>
                                        <div class="title text--text">
                                          <b>{{ innovation.name }}</b>
                                        </div>
                                      </v-flex>
                                      <v-flex xs1>
                                        <v-btn
                                          depressed
                                          class="red--text"
                                          :loading="
                                            loading &&
                                            innovationIndex == innovation.id
                                          "
                                          icon
                                          @click="deleteInnovation(innovation)"
                                          v-if="$can('innovation_archive') && innovation.permission=='write'""
                                        >
                                          <v-icon> mdi-delete </v-icon>
                                        </v-btn>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-divider class="mt-2"></v-divider>
                                  <v-flex xs12 class="mt-1">
                                    <v-layout column>
                                      <v-flex xs12 class="mt-5" v-if="innovation.ip_protection_type != null">
                                        <v-layout row wrap>
                                          <v-flex xs4>
                                            <b>IP Protection</b>
                                          </v-flex>
                                          <v-flex xs8>
                                            {{ innovation.ip_protection_type.name }}
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-5">
                                        <v-layout row wrap>
                                          <v-flex xs4>
                                            <b>Stage</b>
                                          </v-flex>
                                          <v-flex xs8>
                                            {{ innovation.stage.name }}
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-5">
                                        <v-layout row wrap>
                                          <v-flex xs4>
                                            <b>County</b>
                                          </v-flex>
                                          <v-flex xs8>
                                            {{ innovation.county.name }}
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-5">
                                        <v-layout row wrap>
                                          <v-flex xs4>
                                            <b>Status</b>
                                          </v-flex>
                                          <v-flex xs8>
                                            <div v-if="innovation.status == 1">Active</div>
                                            <div v-else>Inactive</div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-5">
                                        <v-layout row wrap>
                                          <v-flex xs4>
                                            <b>Date Created</b>
                                          </v-flex>
                                          <v-flex xs8>
                                            {{
                                              innovation.created_at
                                                | moment("DD/MM/YYYY - hh:mm a")
                                            }}
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </v-card>
                          </v-flex>
                        </div>
                      </template>
                    </v-layout>
                  </div>
                </div>
              </div>
            </v-card>
            <v-layout column class="mt-5" v-if="view == 'Innovations'">
              <v-flex xs12>
                <div align="center">
                  <v-pagination
                    v-if="innovationLength != 0"
                    :length="innovationLength"
                    total-visible="10"
                    v-model="innovationPagination.current_page"
                    @input="changeInnovationPage()"
                    circle
                  >
                  </v-pagination>
                </div>
              </v-flex>
              <v-flex xs12>
                <div v-if="innovationLength != 0" align="center" class="mt-5">
                  <b class="text--text">Total: </b
                  >{{ innovationPagination.total | formatNumber }}
                </div>
              </v-flex>
            </v-layout>

            <v-card v-if="view == 'Computed Reports'" outlined class="mt-5">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-select
                        :items="formData.counties"
                        outlined
                        dense
                        v-model="filterData.county_id"
                        label="County"
                        item-text="name"
                        item-value="id"
                        class="background login_field"
                        @change="enableSearch()"
                      ></v-select>
                    </div>
                  </v-flex>
                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-menu
                        :close-on-content-click="false"
                        max-width="290"
                        transition="scale-transition"
                        v-model="dateFromPicker"
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            dense
                            :value="formattedDateFrom"
                            label="Date From"
                            slot="activator"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="background login_field"
                            @change="enableSearch()"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="filterData.date_from"
                          :max="dateToday"
                          @change="closeDateFrom"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </v-flex>
                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-menu
                        :close-on-content-click="false"
                        max-width="290"
                        transition="scale-transition"
                        v-model="dateToPicker"
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            dense
                            :value="formattedDateTo"
                            label="Date To"
                            slot="activator"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="background login_field"
                            @change="enableSearch()"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="filterData.date_to"
                          :min="filterData.date_from"
                          :max="dateToday"
                          @change="closeDateTo"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </v-flex>

                  <v-flex xs12 class="mt-3">
                    <div align="right">
                      <v-btn
                        class="primary text-none mt-5"
                        depressed
                        @click="searchComputed"
                        :loading="searchLoader"
                        :disabled="searchButtonDisabled"
                      >
                        Search
                        <v-icon right>mdi-magnify</v-icon>
                      </v-btn>
                      <v-btn
                        class="secondary primary--text text-none mt-5 mx-1"
                        depressed
                        @click="resetUser"
                        :loading="resetLoader"
                      >
                        Reset
                        <v-icon right>mdi-undo</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
            <v-card
              outlined
              v-if="view == 'Computed Reports'"
              class="mt-5 pa-5"
              style="border-radius: 25px"
            >
            <v-layout column>
              <v-flex xs12 class="my-5">
                <v-tabs grow>
                  <v-tabs-slider color="text"></v-tabs-slider>
                  <template v-for="(tab, index) in tabs">
                    <v-tab
                      class="text-none text--text"
                      @click="activeTab(tab.name)"
                      :key="index"
                    >
                      {{ tab.name }}
                    </v-tab>
                  </template>
                </v-tabs>
              </v-flex>
              <v-flex xs12>
                <v-layout row wrap>
                  <v-flex xs12 md12 v-if="computedTab == 'Trends'">
                    <apexchart class="my-15" ref="trends" width="100%" height="505" type="bar" :options="chartOptions"
                      :series="series"></apexchart>
                  </v-flex>
                  <v-flex xs12 md12 v-if="computedTab == 'Connections'">
                    <apexchart class="my-15" ref="connections" width="100%" height="505" type="bar" :options="connectionChartOptions"
                      :series="connectionSeries"></apexchart>
                  </v-flex>
                  <v-flex xs12 md12 v-if="computedTab == 'Interests'">
                    <v-layout column>
                      <v-flex xs12>
                        <div class="mt-15">User(Role) Interests</div>
                        <apexchart class="mb-15" ref="interests" width="100%" height="505" type="bar" :options="interestChartOptions"
                        :series="interestSeries"></apexchart>
                      </v-flex>
                      <v-flex xs12>
                        <div class="mt-15">User(Role) Sectors</div>
                        <apexchart class="mb-15" ref="sectors" width="100%" height="505" type="bar" :options="sectorChartOptions"
                        :series="sectorSeries"></apexchart>
                      </v-flex>
                      <v-flex xs12>
                        <div class="mt-15">User(Role) Sdgs</div>
                        <apexchart class="mb-15" ref="sdgs" width="100%" height="505" type="bar" :options="sdgChartOptions"
                        :series="sdgSeries"></apexchart>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            
            
            </v-card>
          </div>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import apiCall from "@/utils/api";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

export default {
  components: {
    
  },
  data() {
    return {
      view: "Users",
      activeInnovationFilter: "Interest(s)",
      computedTab: "Trends",
      searchLoader: false,
      resetLoader: false,
      searchButtonDisabled: false,
      csvLoading: false,

      filterData: {
        county_id: null,
        gender_id: null,
        date_from: null,
        date_to: null,
        age_filter: false,
        age_range: [0, 100],
        roles: [],
        account_status: null,

        stage_id: null,
        ip_protection_type_id: null,
        offer_filter: false,
        funding_type_id: null,
        offer_status: null,
        amount_range: [null, null],
        interests: [],
        sectors: [],
        sdgs: [],
        interest_filter: false,
      },

      users: [],
      userPagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },

      innovations: [],
      innovationPagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },

      min: 0,
      max: 100,

      inputRules: [(v) => !!v || "Input is Required"],

      formData: null,

      dateFromPicker: false,
      dateToPicker: false,

      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      monthNames: [
        { name: "January" },
        { name: "February" },
        { name: "March" },
        { name: "April" },
        { name: "May" },
        { name: "June" },
        { name: "July" },
        { name: "August" },
        { name: "September" },
        { name: "October" },
        { name: "November" },
        { name: "December" },
      ],
      accountStatuses: [
        { id: true, name: "Active" },
        { id: false, name: "Inactive" },
      ],
      ageFilters: [
        { value: true, name: "Limit by age" },
        { value: false, name: "Do not limit by age" },
      ],
      offerFilters: [
        { value: true, name: "Limit by innovations with offers" },
        { value: false, name: "Do not limit by offers" },
      ],
      offerResponses: [
        { id: 0, name: "Pending" },
        { id: 1, name: "Accepted" },
        { id: 2, name: "Rejected" },
      ],
      tabs: [
        { name: "Trends" },
        { name: "Connections" },
        { name: "Interests" },
      ],
      innovationFilterTabs: [
        { name: "Interest(s)" },
        { name: "Sector(s)" },
        { name: "SDG(s)" },
      ],

      chartOptions: {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        grid: {
          row: {
            colors: ['#FFEDED', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
      },
      chartOptions2: {
        chart: {
          id: "attendance-ratio",
          width: "100%",
          type: "pie",
        },
        labels: [],
        fill: {
          opacity: 1,
        },
        stroke: {
          width: 1,
          colors: undefined,
        },
        yaxis: {
          show: true,
        },
        legend: {
          position: "bottom",
        },
        plotOptions: {
          polarArea: {
            rings: {
              strokeWidth: 0,
            },
            spokes: {
              strokeWidth: 0,
            },
          },
        },
      },
      series2: [10, 20, 600, 200, 300],
      series: [
        
      ],

      connectionChartOptions: {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        grid: {
          row: {
            colors: ['#FFEDED', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
      },

      connectionSeries: [
        
      ],

      interestChartOptions: {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        grid: {
          row: {
            colors: ['#FFEDED', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
      },

      interestSeries: [],

      sectorChartOptions: {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        grid: {
          row: {
            colors: ['#FFEDED', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
      },

      sectorSeries: [],

      sdgChartOptions: {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        grid: {
          row: {
            colors: ['#FFEDED', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
      },

      sdgSeries: [],
    };
  },
  beforeMount() {
    if (this.$can("report_view")) {
      this.getFormData();
    }
  },
  methods: {
    enableSearch() {
      this.searchButtonDisabled = false;
    },

    closeDateFrom() {
      this.searchButtonDisabled = false;
      this.dateFromPicker = false;
    },
    closeDateTo() {
      this.searchButtonDisabled = false;
      this.dateToPicker = false;
    },
    switchTab(tab) {
      this.view = tab;
    },

    activeTab(tab){
      this.computedTab = tab
    },

    getFormData() {
      apiCall({
        url: "/api/report?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;

          this.filterData.amount_range[0] = resp.minInvestment;
          this.filterData.amount_range[1] = resp.maxInvestment;

          this.searchUser()
          this.searchInnovation()
        })
        .catch((error) => {
          this.message = "An Error Occurred couldn't Get Form Data";
          this.color = "error";
        });
    },
    changeUserPage() {
      apiCall({
        url: "/api/report?type=user&page=" + this.userPagination.current_page,
        data: this.filterData,
        method: "POST",
      })
        .then((resp) => {
          this.searchLoader = false;
          this.filteredLogs = true;
          this.users = resp.data;
          this.userPagination.current_page = resp.current_page;
          this.userPagination.total = resp.total;
          this.userPagination.per_page = resp.per_page;
        })
        .catch((error) => {
          this.searchLoader = false;
          this.message = "An error occurred";
          this.color = "error";
          this.snackbar = true;
        });
    },
    changeInnovationPage() {
      apiCall({
        url: "/api/report?type=innovation&page=" + this.innovationPagination.current_page,
        data: this.filterData,
        method: "POST",
      })
        .then((resp) => {
          this.searchLoader = false;
          this.filteredLogs = true;
          this.innovations = resp.data;
          this.innovationPagination.current_page = resp.current_page;
          this.innovationPagination.total = resp.total;
          this.innovationPagination.per_page = resp.per_page;
        })
        .catch((error) => {
          this.searchLoader = false;
          this.message = "An error occurred";
          this.color = "error";
          this.snackbar = true;
        });
    },
    resetUser() {
      this.filterData.county_id = null;
      this.filterData.gender_id = null;
      this.filterData.date_from = null;
      this.filterData.date_to = null;
      this.filterData.age_filter = false;
      this.filterData.age_range[0] = 0;
      this.filterData.age_range[1] = 100;
      this.filterData.roles = [];
      this.filterData.account_status = null;

      this.resetLoader = true;
      apiCall({
        url: "/api/report?type=user",
        data: this.filterData,
        method: "POST",
      })
        .then((resp) => {
          this.resetLoader = false;
          this.filteredLogs = true;
          this.users = resp.data;
          this.userPagination.current_page = resp.current_page;
          this.userPagination.total = resp.total;
          this.userPagination.per_page = resp.per_page;
        })
        .catch((error) => {
          this.resetLoader = false;
          this.message = "An error occurred";
          this.color = "error";
          this.snackbar = true;
        });
    },
    searchUser() {
      // if (
      //   this.filterData.county_id == null &&
      //   this.filterData.gender_id == null &&
      //   this.filterData.date_from == null &&
      //   this.filterData.date_from == null &&
      //   this.filterData.date_to == null &&
      //   this.filterData.age_filter == false &&
      //   this.filterData.account_status == null
      // ) {
      //   this.searchButtonDisabled = true;
      // } else {
      this.searchLoader = true;
      // this.searchButtonDisabled = false;
      apiCall({
        url: "/api/report?type=user",
        data: this.filterData,
        method: "POST",
      })
        .then((resp) => {
          this.searchLoader = false;
          this.filteredLogs = true;
          this.users = resp.data;
          this.userPagination.current_page = resp.current_page;
          this.userPagination.total = resp.total;
          this.userPagination.per_page = resp.per_page;
        })
        .catch((error) => {
          this.searchLoader = false;
          this.message = "An error occurred";
          this.color = "error";
          this.snackbar = true;
        });
      // }
    },
    searchComputed() {
      this.searchLoader = true;
      apiCall({
        url: "/api/report?type=computed",
        data: this.filterData,
        method: "POST",
      })
        .then((resp) => {
          this.searchLoader = false;

          for(var i = 0; i < resp.trend_dates.length; i++){
            this.chartOptions.xaxis.categories.push(resp.trend_dates[i])
          }

          for(var x = 0; x < resp.connection_dates.length; x++){
            this.connectionChartOptions.xaxis.categories.push(resp.connection_dates[x])
          }

          for(var x = 0; x < resp.user_interest_groups.length; x++){
            this.interestChartOptions.xaxis.categories.push(resp.user_interest_groups[x])
          }

          for(var x = 0; x < resp.user_sector_groups.length; x++){
            this.sectorChartOptions.xaxis.categories.push(resp.user_sector_groups[x])
          }

          for(var x = 0; x < resp.user_sdg_groups.length; x++){
            this.sdgChartOptions.xaxis.categories.push(resp.user_sdg_groups[x])
          }
          
          // this.chartOptions.xaxis.categories = resp.trend_dates;
          this.series = resp.trends;
          this.connectionSeries = resp.connections;
          this.interestSeries = resp.user_interests;
          this.sectorSeries = resp.user_sectors;
          this.sdgSeries = resp.user_sdgs;

          console.log("this.chartOptions.xaxis.categories", this.chartOptions.xaxis.categories)
        })
        .catch((error) => {
          this.searchLoader = false;
          this.message = "An error occurred";
          this.color = "error";
          this.snackbar = true;
        });
    },
    csvUserDownload() {
      this.csvLoading = true;
      apiCall({
        url: "/api/report?type=userCSV",
        data: this.filterData,
        method: "POST",
      })
        .then((resp) => {
          this.csvLoading = false;
          this.downloadFile(resp, "user");
        })
        .catch((error) => {
          console.log(error.response);
          this.csvLoading = false;
          this.message = "anErrorOccurredMsg";
          this.color = "error";
          this.snackbar = true;
        });
    },
    csvInnovationDownload() {
      this.csvLoading = true;
      apiCall({
        url: "/api/report?type=innovationCSV",
        data: this.filterData,
        method: "POST",
      })
        .then((resp) => {
          this.csvLoading = false;
          this.downloadFile(resp, "innovation");
        })
        .catch((error) => {
          console.log(error.response);
          this.csvLoading = false;
          this.message = "anErrorOccurredMsg";
          this.color = "error";
          this.snackbar = true;
        });
    },
    searchInnovation() {
      // if (
      //   this.filterData.county_id == null &&
      //   this.filterData.gender_id == null &&
      //   this.filterData.date_from == null &&
      //   this.filterData.date_from == null &&
      //   this.filterData.date_to == null &&
      //   this.filterData.age_filter == false &&
      //   this.filterData.account_status == null
      // ) {
      //   this.searchButtonDisabled = true;
      // } else {
      this.searchLoader = true;
      // this.searchButtonDisabled = false;
      apiCall({
        url: "/api/report?type=innovation",
        data: this.filterData,
        method: "POST",
      })
        .then((resp) => {
          this.searchLoader = false;
          this.filteredLogs = true;
          this.innovations = resp.data;
          this.innovationPagination.current_page = resp.current_page;
          this.innovationPagination.total = resp.total;
          this.innovationPagination.per_page = resp.per_page;
        })
        .catch((error) => {
          this.searchLoader = false;
          this.message = "An error occurred";
          this.color = "error";
          this.snackbar = true;
        });
      // }
    },
    activeInnovationFilterTab(tab){
      this.activeInnovationFilter = tab
    },
    downloadFile(response, type) {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      var newBlob = new Blob([response], { type: "application/csv" });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      if (type == "user") {
        link.download = "User Report - " + this.dateToday + ".csv";
      }
      if (type == "innovation") {
        link.download = "Innovation Report - " + this.dateToday + ".csv";
      }
      link.click();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    },
    generateDayWiseTimeSeries: function(baseval, count, yrange) {
      var i = 0;
      var series = [];
      while (i < count) {
        var x = baseval;
        var y =
          Math.floor(Math.random() * (yrange.max - yrange.min + 1)) +
          yrange.min;

        series.push([x, y]);
        baseval += 86400000;
        i++;
      }

      return series;
    }
  },
  computed: {
    formattedDateFrom() {
      if (this.filterData.date_from != null) {
        const d = new Date(this.filterData.date_from);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
    formattedDateTo() {
      if (this.filterData.date_to != null) {
        const d = new Date(this.filterData.date_to);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
    dateToday() {
      var today = new Date();

      var timestamp =
        today.getFullYear() +
        "-" +
        this.months[today.getMonth()] +
        "-" +
        ("0" + today.getDate()).slice(-2);
      return timestamp;
    },
    userLength: function () {
      return Math.ceil(
        this.userPagination.total / this.userPagination.per_page
      );
    },

    innovationLength: function () {
      return Math.ceil(
        this.innovationPagination.total / this.innovationPagination.per_page
      );
    },
  },
};
</script>
