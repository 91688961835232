<template>
  <div>
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-dialog
      v-model="wipoPopUp"
      persistent
      transition="dialog-bottom-transition"
      max-width="1150"
    >
    <v-card class="elevation-1" max-width="1150">
      <v-toolbar flat color="transparent">
          <div class="title">WIPO - IP Diagnostic Tool</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="wipoPopUp = false">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <iframe style="border:none;" width="100%" height="800" src="https://www.wipo.int/ipdiagnostics-assessment/global/en" title="WIPO - IP Diagnostic Tool"></iframe>
        </v-card-text>
    </v-card>
        
    </v-dialog>
    <v-dialog
      v-model="innovationCardState"
      persistent
      transition="dialog-bottom-transition"
      max-width="1150"
    >
      <v-card :loading="loading" class="elevation-1" max-width="1150">
        <template slot="progress">
          <v-progress-linear
            color="primary"
            height="2"
            indeterminate
          ></v-progress-linear>
        </template>
        <v-toolbar flat color="transparent">
          <div class="title">Add your Innovation Details</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="changeInnovationCardState">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <div class="mx-3">
              <v-layout row wrap>
                <v-flex xs12 md5>
                  <div class="mt-5 ml-1">
                    <b>Title of the Innovation</b
                    ><span class="red--text">*</span>
                  </div>
                  <v-text-field
                    outlined
                    dense
                    v-model="innovationData.title"
                    :rules="inputRules"
                    prepend-inner-icon="mdi-format-title"
                    class="background login_field mx-1"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md5>
                  <div class="mt-5 ml-1"><b>Tagline</b></div>
                  <v-text-field
                    outlined
                    dense
                    v-model="innovationData.tagline"
                    prepend-inner-icon="mdi-subtitles"
                    class="background login_field mx-1"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md2>
                  <div class="mt-5 ml-1">
                    <b>Year</b><span class="red--text">*</span>
                  </div>
                  <v-select
                    :items="years"
                    :rules="inputRules"
                    outlined
                    dense
                    v-model="innovationData.year"
                    prepend-inner-icon="mdi-calendar"
                    class="background login_field mx-1"
                  ></v-select>
                </v-flex>
                <v-flex xs12 v-if="formData != null">
                  <div class="mt-5 ml-1">
                    <b>IP Protection Type</b><span class="red--text">*</span> 
                    <v-btn depressed class="green white--text ml-5 text-none" x-small @click="wipoPopUp=true">Help<v-icon right x-small>mdi-help</v-icon></v-btn>
                  </div>
                  <v-radio-group
                    v-model="innovationData.ip_protection_type_id"
                    row
                    :rules="inputRules"
                  >
                    <v-radio
                      v-for="(
                        ip_protection_type, index
                      ) in formData.ip_protection_types"
                      :label="ip_protection_type.name"
                      :value="ip_protection_type.id"
                    ></v-radio>
                  </v-radio-group>
                </v-flex>
                <v-flex xs12 md6 v-if="formData != null">
                  <div class="mt-5 ml-1">
                    <b>Stage</b><span class="red--text">*</span>
                  </div>
                  <v-select
                    :items="formData.stages"
                    :rules="inputRules"
                    outlined
                    dense
                    v-model="innovationData.stage_id"
                    item-text="name"
                    item-value="id"
                    prepend-inner-icon="mdi-signal-cellular-2"
                    class="background login_field mx-1"
                  ></v-select>
                </v-flex>
                <v-flex xs12 md6 v-if="formData != null">
                  <div class="mt-5 ml-1">
                    <b>County HQ</b><span class="red--text">*</span>
                  </div>
                  <v-select
                    :items="formData.counties"
                    :rules="inputRules"
                    outlined
                    dense
                    v-model="innovationData.county_id"
                    item-text="name"
                    item-value="id"
                    prepend-inner-icon="mdi-map-marker"
                    class="background login_field mx-1"
                  ></v-select>
                </v-flex>
                <v-flex xs12 class="mt-5">
                  <div class="ml-1">
                    <b>Description</b><span class="red--text">*</span>
                  </div>
                  <div class="black--text">
                    <ckeditor
                      :editor="editor"
                      v-model="innovationData.description"
                      :config="editorConfig"
                      :rules="inputRules"
                    ></ckeditor>
                  </div>
                </v-flex>
                <v-flex xs12 v-if="formData != null" class="mt-5">
                  <div class="ml-1">
                    <b>What are you looking for on Innovation Bridge?</b
                    ><span class="red--text">*</span>
                  </div>
                  <div class="mx-3">
                    <v-layout row wrap>
                      <template v-for="(interest, index) in formData.interests">
                        <v-flex xs12 md3 :key="index">
                          <v-checkbox
                            v-model="innovationData.interests"
                            :label="interest.name"
                            :color="interest.color"
                            :value="interest.id"
                            hide-details
                          >
                          </v-checkbox>
                        </v-flex>
                      </template>
                    </v-layout>
                  </div>
                </v-flex>
                <v-flex xs12 v-if="formData != null" class="mt-9">
                  <div class="ml-1">
                    <b>What sector(s) does your innovation explore?</b
                    ><span class="red--text">*</span>
                  </div>
                  <div class="mx-3">
                    <v-layout row wrap>
                      <template v-for="(interest, index) in formData.sectors">
                        <v-flex xs12 md3 :key="index">
                          <v-checkbox
                            v-model="innovationData.sectors"
                            :label="interest.name"
                            :color="interest.color"
                            :value="interest.id"
                            hide-details
                          >
                          </v-checkbox>
                        </v-flex>
                      </template>
                    </v-layout>
                  </div>
                </v-flex>
                <v-flex xs12 v-if="formData != null" class="mt-9">
                  <div class="ml-1">
                    <b>What SDG(s) does your innovation address?</b
                    ><span class="red--text">*</span>
                  </div>
                  <div class="mx-3">
                    <v-layout row wrap>
                      <template v-for="(interest, index) in formData.sdgs">
                        <v-flex xs12 md3 :key="index">
                          <v-checkbox
                            v-model="innovationData.sdgs"
                            :label="interest.name"
                            :color="interest.color"
                            :value="interest.id"
                            hide-details
                          >
                          </v-checkbox>
                        </v-flex>
                      </template>
                    </v-layout>
                  </div>
                </v-flex>
                <v-flex xs12 md6 v-if="formData != null" class="mt-9">
                  <div class="ml-1"><b>Affiliated Institution(s)</b></div>
                  <v-combobox
                    v-model="innovationData.institutions"
                    :items="formData.institutions"
                    item-text="name"
                    item-value="id"
                    chips
                    clearable
                    multiple
                    prepend-inner-icon="mdi-office-building-outline"
                    outlined
                    class="mx-1"
                  >
                    <template
                      v-slot:selection="{ attrs, item, select, selected }"
                    >
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="removeInstitution(item)"
                      >
                        <strong>{{ item.name }}</strong>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-flex>
                <v-flex xs12 md6 v-if="formData != null" class="mt-9">
                  <div class="ml-1"><b>Affiliated Organisation(s)</b></div>
                  <v-combobox
                    v-model="innovationData.organisations"
                    :items="formData.organisations"
                    item-text="name"
                    item-value="id"
                    chips
                    clearable
                    multiple
                    prepend-inner-icon="mdi-briefcase-outline"
                    outlined
                    class="mx-1"
                  >
                    <template
                      v-slot:selection="{ attrs, item, select, selected }"
                    >
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="removeInstitution(item)"
                      >
                        <strong>{{ item.name }}</strong>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-flex>
              </v-layout>

              <v-btn
                depressed
                block
                large
                color="primary"
                :disabled="!valid"
                @click="create"
                :loading="loading"
                class="text-none mt-7"
              >
                Create
                <v-icon dark right>mdi-briefcase-outline</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<style>
.ck-editor__editable {
  min-height: 200px;
}
</style>
<script>
import Vue from "vue";
import apiCall from "@/utils/api";
import { mapGetters, mapActions } from "vuex";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadAdapter from '@/uploadAdapter.js';

Vue.use(CKEditor);

export default {
  name: "InnovationPopUp",

  data: () => {
    return {
      alertType: "",
      snackbar: false,
      message: "",
      color: "",
      loading: false,
      valid: true,
      wipoPopUp: false,

      editor: ClassicEditor,
      editorConfig: {
          extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
              return new uploadAdapter(loader);
          } }],
          language: 'en',
      },

      inputRules: [(v) => !!v || "Input is Required"],

      formData: null,
      years: [],
      innovationData: {
        title: null,
        tagline: null,
        year: null,
        ip_protection_type_id: null,
        stage_id: null,
        county_id: null,
        interests: [],
        sectors: [],
        sdgs: [],
        institutions: [],
        organisations: [],
        description: null,
      },
    };
  },
  watch: {
    innovationCardState() {
      this.initialize();
      this.populateYears();
    },
  },
  methods: {
    ...mapActions(["changeInnovationCardState"]),
    initialize() {
      apiCall({
        url: "/api/innovation?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
        })
        .catch((error) => {
          this.message = "Error obtaining form data; Kindly try again.";
          this.loading = false;
          this.snackbar = true;
          this.alertType = "error";
        });
    },
    reset() {
      this.$refs.form.reset();
    },
    create() {
      if (this.$refs.form.validate()) {
        if (this.innovationData.interests.length == 0) {
          this.message = "Please pick at least one Interest";
          this.color = "error";
          this.snackbar = true;
        } else if (this.innovationData.sectors.length == 0) {
          this.message = "Please pick at least one Sector";
          this.color = "error";
          this.snackbar = true;
        } else if (this.innovationData.sdgs.length == 0) {
          this.message = "Please pick at least one SDG";
          this.color = "error";
          this.snackbar = true;
        } else if (this.innovationData.description == null) {
          this.message = "Please add a description";
          this.color = "error";
          this.snackbar = true;
        } else {
          this.loading = true;
          apiCall({
            url: "/api/innovation",
            data: this.innovationData,
            method: "POST",
          })
            .then((resp) => {
              this.message =
                "Innovation Created. We'll notify you once it goes live.";
              this.color = "success";
              this.loading = false;
              this.snackbar = true;

              this.innovationData.title = null;
              (this.innovationData.tagline = null),
                (this.innovationData.year = null),
                (this.innovationData.ip_protection_type_id = null),
                (this.innovationData.stage_id = null),
                (this.innovationData.county_id = null),
                (this.innovationData.interests = []),
                (this.innovationData.sectors = []),
                (this.innovationData.sdgs = []),
                (this.innovationData.institutions = []),
                (this.innovationData.organisations = []),
                (this.innovationData.description = null),
                this.changeInnovationCardState();
            })
            .catch((error) => {
              this.message = "An Error Occurred. Please try again..";
              this.loading = false;
              this.snackbar = true;
              this.alertType = "error";
            });
        }
      }
    },
    populateYears() {
      var max = new Date().getFullYear();
      var min = max - 100;
      var years = [];

      for (var i = max; i >= min; i--) {
        years.push(i);
      }
      this.years = years;
    },
    removeInstitution(item) {
      this.innovationData.institutions.splice(
        this.innovationData.institutions.indexOf(item),
        1
      );
    },
  },
  computed: {
    ...mapGetters(["innovationCardState"]),
  },
};
</script>
