<template>
  <div class="welcome" v-if="$can('dashboard_view')">
    <v-layout column>
      <v-flex xs12>
        <v-container class="mt-5">
          <v-layout column>
            <v-flex xs12>
              <v-layout row wrap class="mx-1">
                <v-flex xs12 md3>
                  <a v-if="view == 'innovations'">
                    <v-card outlined class="primary mx-2 mb-4">
                      <v-layout column>
                        <v-flex xs12>
                          <div align="center">
                            <v-icon large class="white--text my-5"
                              >mdi-spa-outline</v-icon
                            >
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="white--text">
                            Innovations
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="white--text my-4">
                            <b>{{
                              quickStats["total_innovations"] | formatNumber
                            }}</b>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </a>
                  <a v-else>
                    <v-card
                      outlined
                      class="mx-2 mb-4"
                      @click="view = 'innovations'"
                    >
                      <v-layout column>
                        <v-flex xs12>
                          <div align="center">
                            <v-icon large class="text--text my-5"
                              >mdi-spa-outline</v-icon
                            >
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center">Innovations</div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="text--text my-4">
                            <b>{{
                              quickStats["total_innovations"] | formatNumber
                            }}</b>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </a>
                </v-flex>
                <v-flex xs12 md3>
                  <a v-if="view == 'users'">
                    <v-card outlined class="primary mx-2 mb-4">
                      <v-layout column>
                        <v-flex xs12>
                          <div align="center">
                            <v-icon large class="white--text my-5"
                              >mdi-account-multiple-outline</v-icon
                            >
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="white--text">Users</div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="white--text my-4">
                            <b>{{
                              quickStats["total_users"] | formatNumber
                            }}</b>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </a>
                  <a v-else>
                    <v-card outlined class="mx-2 mb-4" @click="view = 'users'">
                      <v-layout column>
                        <v-flex xs12>
                          <div align="center">
                            <v-icon large class="text--text my-5"
                              >mdi-account-multiple-outline</v-icon
                            >
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center">Users</div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="text--text my-4">
                            <b>{{
                              quickStats["total_users"] | formatNumber
                            }}</b>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </a>
                </v-flex>
                <v-flex xs12 md3>
                  <a v-if="view == 'institutions'">
                    <v-card outlined class="primary mx-2 mb-4">
                      <v-layout column>
                        <v-flex xs12>
                          <div align="center">
                            <v-icon large class="white--text my-5"
                              >mdi-office-building-outline</v-icon
                            >
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="white--text">
                            Institutions
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="white--text my-4">
                            <b>{{
                              quickStats["total_institutions"] | formatNumber
                            }}</b>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </a>
                  <a v-else>
                    <v-card
                      outlined
                      class="mx-2 mb-4"
                      @click="view = 'institutions'"
                    >
                      <v-layout column>
                        <v-flex xs12>
                          <div align="center">
                            <v-icon large class="text--text my-5"
                              >mdi-office-building-outline</v-icon
                            >
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center">Institutions</div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="text--text my-4">
                            <b>{{
                              quickStats["total_institutions"] | formatNumber
                            }}</b>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </a>
                </v-flex>
                <v-flex xs12 md3>
                  <a v-if="view == 'organisations'">
                    <v-card outlined class="primary mx-2 mb-4">
                      <v-layout column>
                        <v-flex xs12>
                          <div align="center">
                            <v-icon large class="white--text my-5"
                              >mdi-briefcase-outline</v-icon
                            >
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="white--text">
                            Organisations
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="white--text my-4">
                            <b>{{
                              quickStats["total_organisations"] | formatNumber
                            }}</b>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </a>
                  <a v-else>
                    <v-card
                      outlined
                      class="mx-2 mb-4"
                      @click="view = 'organisations'"
                    >
                      <v-layout column>
                        <v-flex xs12>
                          <div align="center">
                            <v-icon large class="text--text my-5"
                              >mdi-briefcase-outline</v-icon
                            >
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center">Organisations</div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="text--text my-4">
                            <b>{{
                              quickStats["total_organisations"] | formatNumber
                            }}</b>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </a>
                </v-flex>
                <v-flex xs12 md3>
                  <a v-if="view == 'investment firms'">
                    <v-card outlined class="primary mx-2 mb-4">
                      <v-layout column>
                        <v-flex xs12>
                          <div align="center">
                            <v-icon large class="white--text my-5"
                              >mdi-tie</v-icon
                            >
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="white--text">
                            Investment Firms
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="white--text my-4">
                            <b>{{
                              quickStats["total_investment_firms"]
                                | formatNumber
                            }}</b>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </a>
                  <a v-else>
                    <v-card
                      outlined
                      class="mx-2 mb-4"
                      @click="view = 'investment firms'"
                    >
                      <v-layout column>
                        <v-flex xs12>
                          <div align="center">
                            <v-icon large class="text--text my-5"
                              >mdi-tie</v-icon
                            >
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center">Investment Firms</div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="text--text my-4">
                            <b>{{
                              quickStats["total_investment_firms"]
                                | formatNumber
                            }}</b>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </a>
                </v-flex>
                <v-flex xs12 md3>
                  <a v-if="view == 'events'">
                    <v-card outlined class="primary mx-2 mb-4">
                      <v-layout column>
                        <v-flex xs12>
                          <div align="center">
                            <v-icon large class="white--text my-5"
                              >mdi-calendar</v-icon
                            >
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="white--text">Events</div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="white--text my-4">
                            <b>{{
                              quickStats["total_events"] | formatNumber
                            }}</b>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </a>
                  <a v-else>
                    <v-card outlined class="mx-2 mb-4" @click="view = 'events'">
                      <v-layout column>
                        <v-flex xs12>
                          <div align="center">
                            <v-icon large class="text--text my-5"
                              >mdi-calendar</v-icon
                            >
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center">Events</div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="text--text my-4">
                            <b>{{
                              quickStats["total_events"] | formatNumber
                            }}</b>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </a>
                </v-flex>

                <v-flex xs12 md3>
                  <a v-if="view == 'calls'">
                    <v-card outlined class="primary mx-2 mb-4">
                      <v-layout column>
                        <v-flex xs12>
                          <div align="center">
                            <v-icon large class="white--text my-5"
                              >mdi-folder-open</v-icon
                            >
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="white--text">Calls</div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="white--text my-4">
                            <b>{{
                              quickStats["total_calls"] | formatNumber
                            }}</b>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </a>
                  <a v-else>
                    <v-card outlined class="mx-2 mb-4" @click="view = 'calls'">
                      <v-layout column>
                        <v-flex xs12>
                          <div align="center">
                            <v-icon large class="text--text my-5"
                              >mdi-folder-open</v-icon
                            >
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center">Calls</div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="text--text my-4">
                            <b>{{
                              quickStats["total_calls"] | formatNumber
                            }}</b>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </a>
                </v-flex>
                <v-flex xs12 md3>
                  <a v-if="view == 'asks'">
                    <v-card outlined class="primary mx-2 mb-4">
                      <v-layout column>
                        <v-flex xs12>
                          <div align="center">
                            <v-icon large class="white--text my-5"
                              >mdi-cash-multiple</v-icon
                            >
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="white--text">
                            Request for Funding
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="white--text my-4">
                            <b>{{ quickStats["total_asks"] | formatNumber }}</b>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </a>
                  <a v-else>
                    <v-card outlined class="mx-2 mb-4" @click="view = 'asks'">
                      <v-layout column>
                        <v-flex xs12>
                          <div align="center">
                            <v-icon large class="text--text my-5"
                              >mdi-cash-multiple</v-icon
                            >
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center">Request for Funding</div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="center" class="text--text my-4">
                            <b>{{ quickStats["total_asks"] | formatNumber }}</b>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </a>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
      <v-flex xs12>
        <v-card outlined>
          <v-progress-linear
            color="primary"
            height="2"
            indeterminate
            v-if="quickStatsLoader"
          ></v-progress-linear>

          <v-container>
            <v-layout column>
              <v-flex xs12 class="my-5">
                <div v-if="view == 'innovations'">
                  <v-layout row wrap>
                    <v-flex xs12 md6>
                      <div align="center" class="ml-7">
                        Innovation County Distribution
                      </div>
                      <div class="mx-3">
                        <apexchart
                          v-if="showChart"
                          ref="innovationChart"
                          width="100%"
                          height="405"
                          type="bar"
                          :options="innovationCountyChartOptions"
                          :series="innovationCountySeries"
                        ></apexchart>
                      </div>
                    </v-flex>
                    <v-flex xs12 md3>
                      <div align="center" class="ml-7">Innovation Stages</div>
                      <div class="mx-3">
                        <apexchart
                          width="100%"
                          height="450"
                          type="pie"
                          :options="innovationStageChartOptions"
                          :series="innovationStageSeries"
                        >
                        </apexchart>
                      </div>
                    </v-flex>
                    <v-flex xs12 md3>
                      <div align="center" class="ml-7">
                        Innovation IP Protection
                      </div>
                      <div class="mx-3">
                        <apexchart
                          width="100%"
                          height="450"
                          type="pie"
                          :options="innovationIpProtectionChartOptions"
                          :series="innovationIpProtectionSeries"
                        >
                        </apexchart>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>

                <div v-if="view == 'users'">
                  <v-layout row wrap>
                    <v-flex xs12 md6>
                      <div align="center" class="ml-7">
                        User County Distribution
                      </div>
                      <div class="mx-3">
                        <apexchart
                          v-if="showChart"
                          ref="userChart"
                          width="100%"
                          height="405"
                          type="bar"
                          :options="userCountyChartOptions"
                          :series="innovationUserSeries"
                        ></apexchart>
                      </div>
                    </v-flex>
                    <v-flex xs12 md3>
                      <div align="center" class="ml-7">User Roles</div>
                      <div class="mx-3">
                        <apexchart
                          width="100%"
                          height="450"
                          type="pie"
                          :options="userRoleChartOptions"
                          :series="userRoleSeries"
                        >
                        </apexchart>
                      </div>
                    </v-flex>
                    <v-flex xs12 md3>
                      <div align="center" class="ml-7">User Genders</div>
                      <div class="mx-3">
                        <apexchart
                          width="100%"
                          height="450"
                          type="pie"
                          :options="userGenderChartOptions"
                          :series="userGenderSeries"
                        >
                        </apexchart>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>

                <div v-if="view == 'institutions'">
                  <v-layout row wrap>
                    <v-flex xs12 md8>
                      <div align="center" class="ml-7">
                        Institution County Distribution
                      </div>
                      <div class="mx-3">
                        <apexchart
                          v-if="showChart"
                          ref="institutionChart"
                          width="100%"
                          height="605"
                          type="bar"
                          :options="institutionCountyChartOptions"
                          :series="institutionCountySeries"
                        ></apexchart>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div align="center" class="ml-7">Institution Types</div>
                      <div class="mx-3">
                        <apexchart
                          width="100%"
                          height="450"
                          type="pie"
                          :options="institutionTypeChartOptions"
                          :series="institutionTypeSeries"
                        >
                        </apexchart>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
                <div v-if="view == 'organisations'">
                  <v-layout row wrap>
                    <v-flex xs12 md8>
                      <div align="center" class="ml-7">
                        Organisation County Distribution
                      </div>
                      <div class="mx-3">
                        <apexchart
                          v-if="showChart"
                          ref="organisationChart"
                          width="100%"
                          height="605"
                          type="bar"
                          :options="organisationCountyChartOptions"
                          :series="organisationCountySeries"
                        ></apexchart>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div align="center" class="ml-7">Organisation Types</div>
                      <div class="mx-3">
                        <apexchart
                          width="100%"
                          height="450"
                          type="pie"
                          :options="organisationTypeChartOptions"
                          :series="organisationTypeSeries"
                        >
                        </apexchart>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>


                <div v-if="view == 'calls'">
                  <v-layout row wrap>
                    <v-flex xs12>
                      <div align="center" class="ml-7">
                        Call County Distribution
                      </div>
                      <div class="mx-3">
                        <apexchart
                          v-if="showChart"
                          ref="callChart"
                          width="100%"
                          height="605"
                          type="bar"
                          :options="callCountyChartOptions"
                          :series="callCountySeries"
                        ></apexchart>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>


                <div v-if="view == 'investment firms'">
                  <v-layout row wrap>
                    <v-flex xs12 md8>
                      <div align="center" class="ml-7">
                        Investment Firm County Distribution
                      </div>
                      <div class="mx-3">
                        <apexchart
                          v-if="showChart"
                          ref="investmentFirmChart"
                          width="100%"
                          height="605"
                          type="bar"
                          :options="investmentFirmCountyChartOptions"
                          :series="investmentFirmCountySeries"
                        ></apexchart>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div align="center" class="ml-7">Investor Types</div>
                      <div class="mx-3">
                        <apexchart
                          width="100%"
                          height="450"
                          type="pie"
                          :options="investmentFirmTypeChartOptions"
                          :series="investmentFirmTypeSeries"
                        >
                        </apexchart>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>


                <div v-if="view == 'events'">
                  <v-layout row wrap>
                    <v-flex xs12 md8>
                      <div align="center" class="ml-7">
                        Event County Distribution
                      </div>
                      <div class="mx-3">
                        <apexchart
                          v-if="showChart"
                          ref="eventChart"
                          width="100%"
                          height="605"
                          type="bar"
                          :options="eventCountyChartOptions"
                          :series="eventCountySeries"
                        ></apexchart>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div align="center" class="ml-7">Event Types</div>
                      <div class="mx-3">
                        <apexchart
                          width="100%"
                          height="450"
                          type="pie"
                          :options="eventTypeChartOptions"
                          :series="eventTypeSeries"
                        >
                        </apexchart>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>


                <div v-if="view == 'asks'">
                  <v-layout row wrap>
                    <v-flex xs12 md8>
                      <div align="center" class="ml-7">
                        Event County Distribution
                      </div>
                      <div class="mx-3">
                        <apexchart
                          v-if="showChart"
                          ref="askChart"
                          width="100%"
                          height="605"
                          type="bar"
                          :options="askCountyChartOptions"
                          :series="askCountySeries"
                        ></apexchart>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div align="center" class="ml-7">Event Types</div>
                      <div class="mx-3">
                        <apexchart
                          width="100%"
                          height="450"
                          type="pie"
                          :options="askTypeChartOptions"
                          :series="askTypeSeries"
                        >
                        </apexchart>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

export default {
  components: {
    
  },
  data() {
    return {
      showChart: true,
      path: process.env.VUE_APP_API_URL,
      view: "innovations",
      color: "",
      message: "",
      snackbar: false,

      innovationCountyChartOptions: {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        grid: {
          row: {
            colors: ['#FFEDED', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
      },
      innovationCountySeries: [],

      innovationStageChartOptions: {
        chart: {
          id: "attendance-ratio",
          width: "100%",
          type: "pie",
        },
        labels: [],
        fill: {
          opacity: 1,
        },
        stroke: {
          width: 1,
          colors: undefined,
        },
        yaxis: {
          show: true,
        },
        legend: {
          position: "bottom",
        },
        plotOptions: {
          polarArea: {
            rings: {
              strokeWidth: 0,
            },
            spokes: {
              strokeWidth: 0,
            },
          },
        },
      },

      innovationStageSeries: [0, 0],

      innovationIpProtectionChartOptions: {
        chart: {
          id: "attendance-ratio",
          width: "100%",
          type: "pie",
        },
        labels: [],
        fill: {
          opacity: 1,
        },
        stroke: {
          width: 1,
          colors: undefined,
        },
        yaxis: {
          show: true,
        },
        legend: {
          position: "bottom",
        },
        plotOptions: {
          polarArea: {
            rings: {
              strokeWidth: 0,
            },
            spokes: {
              strokeWidth: 0,
            },
          },
        },
      },

      innovationIpProtectionSeries: [0, 0],

      userCountyChartOptions: {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      innovationUserSeries: [],

      userRoleChartOptions: {
        chart: {
          id: "attendance-ratio",
          width: "100%",
          type: "pie",
        },
        labels: [],
        fill: {
          opacity: 1,
        },
        stroke: {
          width: 1,
          colors: undefined,
        },
        yaxis: {
          show: true,
        },
        legend: {
          position: "bottom",
        },
        plotOptions: {
          polarArea: {
            rings: {
              strokeWidth: 0,
            },
            spokes: {
              strokeWidth: 0,
            },
          },
        },
      },

      userRoleSeries: [0, 0],

      userGenderChartOptions: {
        chart: {
          id: "attendance-ratio",
          width: "100%",
          type: "pie",
        },
        labels: [],
        fill: {
          opacity: 1,
        },
        stroke: {
          width: 1,
          colors: undefined,
        },
        yaxis: {
          show: true,
        },
        legend: {
          position: "bottom",
        },
        plotOptions: {
          polarArea: {
            rings: {
              strokeWidth: 0,
            },
            spokes: {
              strokeWidth: 0,
            },
          },
        },
      },

      userGenderSeries: [0, 0],

      institutionCountyChartOptions: {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        grid: {
          row: {
            colors: ['#FFEDED', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
      },
      institutionCountySeries: [],

      institutionTypeChartOptions: {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      institutionTypeSeries: [],

      organisationCountyChartOptions: {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        grid: {
          row: {
            colors: ['#FFEDED', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
      },
      organisationCountySeries: [],

      organisationTypeChartOptions: {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      organisationTypeSeries: [],


      investmentFirmCountyChartOptions: {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        grid: {
          row: {
            colors: ['#FFEDED', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
      },
      investmentFirmCountySeries: [],

      investmentFirmTypeChartOptions: {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      investmentFirmTypeSeries: [],



      callCountyChartOptions: {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        grid: {
          row: {
            colors: ['#FFEDED', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
      },
      callCountySeries: [],




      eventCountyChartOptions: {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        grid: {
          row: {
            colors: ['#FFEDED', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
      },
      eventCountySeries: [],

      eventTypeChartOptions: {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      eventTypeSeries: [],

      askCountyChartOptions: {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        grid: {
          row: {
            colors: ['#FFEDED', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
      },
      askCountySeries: [],

      askTypeChartOptions: {
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      askTypeSeries: [],
    };
  },
  mounted() {},
  created() {
    if (this.$can("dashboard_view")) {
      window.scrollTo(0, 0);
      this.fetchQuickStats();
      this.assignData();
    }
      
  },
  methods: {
    ...mapActions([
      "updateQuickStats",
      "startQuickStatsLoader",
      "stopQuickStatsLoader",
    ]),
    fetchQuickStats() {
      apiCall({
        url: "/api/dashboard?type=quickStats",
        method: "GET",
      })
        .then((resp) => {
          this.updateQuickStats(resp);
          this.showChart = true;
          this.assignData();
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
    assignData() {
      this.innovationCountyChartOptions.xaxis.categories =
        this.quickStats.innovation_county_xaxis;
      this.innovationCountySeries =
        this.quickStats.innovation_county_statistics;

      this.innovationStageChartOptions.labels =
        this.quickStats.innovation_stages.labels;
      this.innovationStageSeries = this.quickStats.innovation_stages.series;

      this.innovationIpProtectionChartOptions.labels =
        this.quickStats.innovation_ip_protection_types.labels;
      this.innovationIpProtectionSeries =
        this.quickStats.innovation_ip_protection_types.series;

      this.userCountyChartOptions.xaxis.categories =
        this.quickStats.user_county_xaxis;
      this.innovationUserSeries = this.quickStats.user_county_statistics;

      this.userRoleChartOptions.labels = this.quickStats.user_roles.labels;
      this.userRoleSeries = this.quickStats.user_roles.series;

      this.userGenderChartOptions.labels = this.quickStats.user_genders.labels;
      this.userGenderSeries = this.quickStats.user_genders.series;

      this.institutionCountyChartOptions.xaxis.categories =
        this.quickStats.institution_county_xaxis;
      this.institutionCountySeries =
        this.quickStats.institution_county_statistics;

      this.institutionTypeChartOptions.labels =
        this.quickStats.institution_types.labels;
      this.institutionTypeSeries = this.quickStats.institution_types.series;

      this.callCountyChartOptions.xaxis.categories =
        this.quickStats.call_county_xaxis;
      this.callCountySeries =
        this.quickStats.call_county_statistics;

      this.organisationCountyChartOptions.xaxis.categories =
        this.quickStats.organisation_county_xaxis;
      this.organisationCountySeries =
        this.quickStats.organisation_county_statistics;

      this.organisationTypeChartOptions.labels =
        this.quickStats.organisation_types.labels;
      this.organisationTypeSeries = this.quickStats.organisation_types.series;

      this.investmentFirmCountyChartOptions.xaxis.categories =
        this.quickStats.investment_firm_county_xaxis;
      this.investmentFirmCountySeries =
        this.quickStats.investment_firm_county_statistics;

      this.investmentFirmTypeChartOptions.labels =
        this.quickStats.investment_firm_types.labels;
      this.investmentFirmTypeSeries = this.quickStats.investment_firm_types.series;

      this.eventCountyChartOptions.xaxis.categories =
        this.quickStats.event_county_xaxis;
      this.eventCountySeries =
        this.quickStats.event_county_statistics;

      this.eventTypeChartOptions.labels =
        this.quickStats.event_types.labels;
      this.eventTypeSeries = this.quickStats.event_types.series;

      this.askCountyChartOptions.xaxis.categories =
        this.quickStats.ask_county_xaxis;
      this.askCountySeries =
        this.quickStats.ask_county_statistics;

      this.askTypeChartOptions.labels =
        this.quickStats.ask_types.labels;
      this.askTypeSeries = this.quickStats.ask_types.series;
    },
  },
  computed: {
    ...mapGetters(["quickStats", "quickStatsLoader"]),
  },
};
</script>
