import apiCall from '@/utils/api'

const state = {
	institutionStatistics: [],
	searchInstitutionStatistics: [],
	allInstitutionStatistics: [],
    institutionStatisticPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	searchInstitutionStatisticPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    institutionStatisticLoader: false
};

const getters = {
	institutionStatistics: (state) => state.institutionStatistics,
	searchInstitutionStatistics: (state) => state.searchInstitutionStatistics,
	allInstitutionStatistics: (state) => state.allInstitutionStatistics,
    institutionStatisticLoader: (state) => state.institutionStatisticLoader,
    institutionStatisticPagination: (state) => state.institutionStatisticPagination,
	searchInstitutionStatisticPagination: (state) => state.searchInstitutionStatisticPagination,
}

const actions = {
	async fetchAllInstitutionStatistics({commit}, page) {
		const response = await apiCall({url: `/api/institution-statistic?type=all`, method: 'GET' });
		commit('setAllInstitutionStatistics', response)
        commit('stopInstitutionStatisticLoader', response)
	},
    async fetchInstitutionStatistics({commit}, page) {
		const response = await apiCall({url: `/api/institution-statistic?page=${page}`, method: 'GET' });
		commit('setInstitutionStatistics', response)
        commit('stopInstitutionStatisticLoader', response)
	},
    async filterSearchInstitutionStatistics({commit, state},resp){
		commit('setSearchInstitutionStatistics', resp)
	},
	async fetchSearchInstitutionStatistics({commit}, page) {
		const response = await apiCall({url: `/api/institution-statistic?page=${page}`, method: 'GET' });
		commit('setSearchInstitutionStatistics', response)
        commit('stopInstitutionStatisticLoader', response)
	},
    async filterInstitutionStatistics({commit, state},resp){
		commit('setInstitutionStatistics', resp)
	},
	async startInstitutionStatisticLoader({commit, state},resp){
		commit('startInstitutionStatisticLoader', resp)
	},
	async stopInstitutionStatisticLoader({commit, state},resp){
		commit('stopInstitutionStatisticLoader', resp)
	},
};

const mutations = {
	setInstitutionStatistics: (state, institutionStatistics) => {
		state.institutionStatistics = institutionStatistics.data
		state.institutionStatisticPagination.current_page = institutionStatistics.current_page
		state.institutionStatisticPagination.total = institutionStatistics.total
		state.institutionStatisticPagination.per_page = institutionStatistics.per_page
	},
	setSearchInstitutionStatistics: (state, institutionStatistics) => {
		state.searchInstitutionStatistics = institutionStatistics.data
		state.searchInstitutionStatisticPagination.current_page = institutionStatistics.current_page
		state.searchInstitutionStatisticPagination.total = institutionStatistics.total
		state.searchInstitutionStatisticPagination.per_page = institutionStatistics.per_page
	},
	setAllInstitutionStatistics: (state, institutionStatistics) => {
		state.allInstitutionStatistics = institutionStatistics
	},
    startInstitutionStatisticLoader: (state) => state.institutionStatisticLoader = true,
	stopInstitutionStatisticLoader: (state) => state.institutionStatisticLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
