<template>
  <div class="Profile">
        <v-dialog width="700" transition="dialog-bottom-transition" scrollable v-model="commentDialog">

<v-card v-if="commentDialog" class="mx-auto ma-1">
    <v-toolbar flat class="transparent">
        <v-toolbar-title>Comments</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="commentDialog = false">
            <v-icon>mdi-close</v-icon>
        </v-btn>
    </v-toolbar>
    <v-card-text>
        <v-layout column>
            <v-flex xs12 class="mt-5">
                <v-layout row wrap>
                    <v-flex xs2>
                        <div align="center">
                            <v-avatar color="primary" v-if="postcard.user.display_img != null">
                                <v-img class="elevation-6"                            
                                    :src="
                                        path + '/storage/profile_pics/' + postcard.user.display_img
                                    "
                                >
                                </v-img>
                            </v-avatar>
                            <v-avatar color="primary" class="white--text" v-if="postcard.user.display_img == null">
                                {{postcard.user.f_name[0]}}
                            </v-avatar>
                        </div>
                    </v-flex>
                    <v-flex xs10>
                        <v-layout column wrap>
                            <v-flex xs12>
                                <div class="text-xs-left font-weight-thin grey--text"><b class="font-weight-bold grey--text">{{ postcard.user.f_name }} {{postcard.user.m_name}} {{postcard.user.l_name}}</b> • {{ postcard.created_at
                                        | moment("from", true)
                                }}
                                </div>
                                <div v-html="postcard.body"></div>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-divider class="mt-7" />
            <v-flex xs12>
                <Comments v-if="postId" :commentUrl="postId" />
            </v-flex>
        </v-layout>
    </v-card-text>
</v-card>
</v-dialog>
<v-dialog width="700" transition="dialog-bottom-transition" scrollable v-model="votesDialog">

<v-card v-if="votesDialog" class="mx-auto ma-1">
    <v-toolbar flat>
        
        <v-toolbar-title>Votes</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="votesDialog = false">
            <v-icon>mdi-close</v-icon>
        </v-btn>
    </v-toolbar>
    <v-card-text>
        <v-layout column class="mb-5">
            <v-flex xs12 v-for="(vote, index) in postVotes" :key="vote.id" class="mt-5">
                <v-layout row wrap>
                    <v-flex xs2>
                        <v-avatar class="primary">
                            <!-- <img :src="path + '/pictures/' + vote.user.profile.profile_pic"> -->
                            <div class="white--text">{{vote.user.f_name[0]}}</div>
                        </v-avatar>
                    </v-flex>
                    <v-flex xs10>
                        <v-layout column>
                            <v-flex xs12>
                                <div class="text-xs-left"><b>{{ vote.user.f_name }}
                                        {{ vote.user.m_name }} {{ vote.user.l_name }}</b></div>
                            </v-flex>
                            <v-flex xs12>
                                <div v-if="vote.vote == 'up'" align="left"
                                    class="font-weight-thin green--text">Up Vote</div>
                                <div v-else align="left" class="font-weight-thin red--text">Down Vote</div>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-card-text>
</v-card>
</v-dialog>
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="imageDialog"
    >
      <v-card max-width="600">
        <v-toolbar flat>
          Upload Profile Picture
          <v-spacer></v-spacer>
          <v-btn icon @click="imageDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form ref="imageForm" v-model="imageValid" lazy-validation>
          <v-layout column>
            <v-flex xs12>
              <v-file-input
                class="mx-2 mt-3"
                :loading="imageLoading"
                :rules="fileRules"
                filled
                dense
                show-size
                chips
                accept="image/*"
                v-model="imageFile"
                label="Select Image"
              ></v-file-input>
            </v-flex>
            <v-flex xs12>
              <div align="right" class="mb-3 mx-3">
                <v-btn
                  depressed
                  block
                  class="primary text-none"
                  @click="uploadImage"
                  :loading="imageLoading"
                  :disabled="!imageValid"
                >
                  Submit <v-icon right dark>mdi-upload</v-icon>
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="1200"
      v-model="profileDialog"
      v-if="formData!=null"
    >
      <v-card max-width="1200">
        <v-toolbar flat>
          Update Profile
          <v-spacer></v-spacer>
          <v-btn icon @click="profileDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="profileForm" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <v-flex xs12 md4>
              <div class="mx-2 mt-5">
                <div>First Name<span class="red--text">*</span></div>
                <v-text-field
                  outlined
                  dense
                  v-model="user.f_name"
                  :rules="inputRules"
                  class="text_field background"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex xs12 md4>
              <div class="mx-2 mt-5">
                <div>Middle Name</div>
                <v-text-field
                  outlined
                  dense
                  v-model="user.m_name"
                  class="text_field background"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex xs12 md4>
              <div class="mx-2 mt-5">
                <div>Last Name<span class="red--text">*</span></div>
                <v-text-field
                  outlined
                  dense
                  v-model="user.l_name"
                  :rules="inputRules"
                  class="text_field background"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex xs12>
              <div class="mx-2 mt-5">
                <div>Bio</div>
                <div class="black--text">
                    <ckeditor
                      :editor="editor"
                      v-model="user.bio"
                      :config="editorConfig"
                      :rules="inputRules"
                    ></ckeditor>
                  </div>
              </div>
            </v-flex>
            <v-flex xs12 md4 >
              <div class="mx-2 mt-5">
                <div><b>Gender</b></div>
              <v-select
                :items="formData.genders"
                item-text="name"
                item-value="id"
                v-model="user.profile.gender_id"
                class="text_field background"
                dense
                outlined
              >
              </v-select>
              </div>
              
            </v-flex>
            <v-flex xs12 md4>
              <div class="mx-2 mt-5">
                <div><b>County</b></div>
              <v-select
                :items="formData.counties"
                item-text="name"
                item-value="id"
                v-model="user.profile.county_id"
                class="text_field background"
                dense
                outlined
              >
              </v-select>
              </div>
              
            </v-flex>
            <v-flex xs12 md4 >
              <div class="mx-2 mt-5">
                <div><b>Date of Birth</b></div>
              <v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
                v-model="dateOfBirth" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined dense :value="formatDateOfBirth" slot="activator" readonly v-bind="attrs"
                    v-on="on" class="text_field background" append-icon="mdi-calendar"></v-text-field>
                </template>
                <v-date-picker v-model="user.profile.dob" :max="dateToday" @change="closeDateOfBirth">
                </v-date-picker>
              </v-menu>
                </div>
              
            </v-flex>
            <v-flex xs12>
              <div align="right" class="mx-2">
                <v-btn
                  depressed
                  class="primary text-none mb-3 mt-5"
                  @click="updateProfile"
                  :loading="loading"
                >
                  Update <v-icon right dark>mdi-upload</v-icon>
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-form>
        </v-card-text>
        
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="passwordDialog"
    >
      <v-card max-width="600">
        <v-toolbar flat>
          Change Account Password
          <v-spacer></v-spacer>
          <v-btn icon @click="passwordDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form ref="passwordForm" v-model="valid" lazy-validation>
          <v-layout column>
            <v-flex xs12>
              <div class="mx-4 mt-5">
                <div>Current Password*</div>
                <v-text-field
                  filled
                  dense
                  v-model="passwordData.current_password"
                  :rules="inputRules"
                  type="password"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex xs12>
              <div class="mx-4">
                <div>New Password*</div>
                <v-text-field
                  filled
                  dense
                  v-model="passwordData.new_password"
                  :rules="passwordRules"
                  type="password"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex xs12>
              <div class="mx-4">
                <div>Confirm Password*</div>
                <v-text-field
                  filled
                  dense
                  v-model="confirmpassword"
                  :rules="inputRules"
                  type="password"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex xs12>
              <div align="right" class="mx-2">
                <v-btn
                  depressed
                  class="primary text-none mb-3 mx-2"
                  @click="savePassword"
                  :loading="loading"
                  :disabled="!valid"
                >
                  Update <v-icon right dark>mdi-upload</v-icon>
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card>
    </v-dialog>

    <v-layout column>
      <v-flex xs12>
        <v-container>
          <v-layout column>
            <v-flex xs12 class="mt-4">
              <v-card outlined class="pa-3" style="border-radius: 25px;">
                <v-card-text>
                  <v-layout row wrap>
                    <v-flex xs12 md2>
                      <v-avatar size="156" class="primary title white--text" v-if="getProfile.display_img==null">
                        {{ getProfile.f_name[0] }}
                      </v-avatar>
                      <v-avatar size="156" class="primary title" v-else>
                        <img :src="path+'/storage/profile_pics/'+getProfile.display_img" alt="prof_pic">
                      </v-avatar>
                      <v-btn depressed class="text-none mt-5 secondary primary--text" @click="imageDialog = true">
                        Upload Profile Pic
                      </v-btn>
                    </v-flex>
                    <v-flex xs12 md10>
                      <v-layout column>
                        <v-flex xs12>
                          <b class="title" style="font-family: 'poppins' !important;"
                            >{{ getProfile.f_name }} {{ getProfile.m_name }}
                            {{ getProfile.l_name }}</b
                          >
                          <v-btn icon @click="profileDialog = true"><v-icon>mdi-pen</v-icon></v-btn>
                        </v-flex>
                        <v-flex xs12>
                          <div>
                            <b>Role(s):</b>
                            <template v-for="(role, index) in getProfile.roles">
                              {{ role.name }},
                            </template>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div class="mx-3 mt-5">
                            <v-layout row wrap>
                              <v-flex xs2>
                                <b>Code:</b>
                              </v-flex>
                              <v-flex xs10>
                                {{ getProfile.code }}
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div class="mx-3 mt-5">
                            <v-layout row wrap>
                              <v-flex xs2>
                                <b>Email:</b>
                              </v-flex>
                              <v-flex xs10>
                                {{ getProfile.email }}
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div class="mx-3 mt-5">
                            <v-layout row wrap>
                              <v-flex xs2>
                                <b>Phone No:</b>
                              </v-flex>
                              <v-flex xs10>
                                {{ getProfile.phone }}
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div class="mx-3 mt-5">
                            <v-layout row wrap>
                              <v-flex xs2>
                                <b>Gender:</b>
                              </v-flex>
                              <v-flex xs10>
                                <div v-if="getProfile.user_profile != null && getProfile.user_profile.gender_id!=null">
                                  {{ getProfile.user_profile.gender.name }}
                                </div>
                                <div v-else>
                                  <i>Pending</i>
                                </div>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div class="mx-3 mt-5">
                            <v-layout row wrap>
                              <v-flex xs2>
                                <b>County:</b>
                              </v-flex>
                              <v-flex xs10>
                                <div v-if="getProfile.user_profile != null && getProfile.user_profile.county_id!=null">
                                  {{ getProfile.user_profile.county.name }}
                                </div>
                                <div v-else>
                                  <i>Pending</i>
                                </div>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div class="mx-3 mt-5">
                            <v-layout row wrap>
                              <v-flex xs2>
                                <b>Date of Birth:</b>
                              </v-flex>
                              <v-flex xs10>
                                <div v-if="getProfile.user_profile != null && getProfile.user_profile.dob!=null"">
                                  {{ getProfile.user_profile.dob }}
                                </div>
                                <div v-else>
                                  <i>Pending</i>
                                </div>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
      <v-flex xs12 class="mt-6">
        <v-card outlined>
          <v-progress-linear
            color="primary"
            height="2"
            indeterminate
            v-if="loading"
          ></v-progress-linear>
          <v-container>
          <v-layout column>
            <v-flex xs12 class="mt-5">
              <v-tabs>
                <v-tab class="text-none text--text" @click="view = 'posts'">Social Posts</v-tab>
                <v-tab class="text-none text--text" @click="view = 'settings'">Settings</v-tab>
              </v-tabs>
            </v-flex>
            <v-flex xs12 class="mt-5" v-if="view == 'posts'">
              <v-layout column>
                <v-flex xs12>
                  <div id="chatList" class="pa-3"
                    style="margin:1px, 1px; padding:1px; width: auto; height: 80vh; overflow-x: hidden; overflow-y: scroll; text-align:justify;">
                    
                    <v-layout column>
                        <v-flex xs12>
                            <div v-if="(userPosts.length != 0)">
                                <template v-for="(post, index) in userPosts">
                                    <div :key="index">
                                        <v-card outlined class="mx-auto mb-3" style="border-radius: 25px;">
                                            <v-card-title>
                                                <v-list-item-avatar color="primary">
                                                    <v-img class="elevation-6" 
                                                        v-if="post.user.display_img != null"
                                                        :src="
                                                            path + '/storage/profile_pics/' + post.user.display_img
                                                        "
                                                    >
                                                    </v-img>
                                                    <v-icon class="white--text" v-if="post.user.display_img == null">
                                                        mdi-account
                                                    </v-icon>
                                                    <!-- <div align="center" class="white--text">{{post.user.f_name[0]}}</div> -->
                                                </v-list-item-avatar>

                                                <v-list-item-content>
                                                    <v-list-item-title class="text--text ml-1">{{post.user.f_name}} {{post.user.m_name}} {{post.user.l_name}}
                                                        </v-list-item-title>
                                                    <v-row align="center" class="caption ml-1 mt-0">

                                                        {{post.created_at | moment("from", true)}}
                                                    </v-row>
                                                </v-list-item-content>
                                            </v-card-title>
                                                <v-img
                                                    
                                                    :src="path+'/storage/social_pics/'+post.pic"
                                                    style="border-radius: 25px;"
                                                    >
                                                </v-img>
                                            <v-card-text>
                                                <p  v-html="post.body">

                                                </p>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-list-item class="grow">
                                                    <v-btn text class="text-none text--text" @click="loadComments(post)">
                                                        Comment ({{post.comments.length}})
                                                        <v-icon right>
                                                            mdi-forum
                                                        </v-icon>
                                                    </v-btn>
                                                    <v-btn text class="text-none text--text" @click="showVotes(post)">
                                                        Votes
                                                        <v-icon right>
                                                            mdi-poll
                                                        </v-icon>
                                                    </v-btn>

                                                    <v-row align="center" justify="end">
                                                        <v-btn icon v-if="post.hasVoted == true">
                                                            <v-icon class="grey--text">
                                                                mdi-arrow-up-thick
                                                            </v-icon>
                                                        </v-btn>
                                                        <v-btn icon v-else
                                                            :loading="likeLoader && likeIndex == index"
                                                            @click="votePost(post.id,index,'up')"
                                                        >
                                                            <v-icon class=" green--text">
                                                                mdi-arrow-up-thick
                                                            </v-icon>
                                                        </v-btn>
                                                        
                                                        <span class="mr-1">·</span>
                                                        <v-btn icon v-if="post.hasVoted == true">
                                                            <v-icon class="grey--text">
                                                                mdi-arrow-down-thick
                                                            </v-icon>
                                                        </v-btn>
                                                        <v-btn icon v-else
                                                            :loading="dislikeLoader && dislikeIndex == index"
                                                            @click="votePost(post.id,index,'down')"
                                                        >
                                                            <v-icon class="red--text">
                                                                mdi-arrow-down-thick
                                                            </v-icon>
                                                        </v-btn>
                                                        ({{post.votes}})
                                                        <span class="mx-1">·</span>
                                                        <v-btn icon
                                                            :loading="deleteLoader && deleteIndex == index"
                                                            @click="deletePost(post.id,index)"
                                                        >
                                                            <v-icon class="red--text">
                                                                mdi-delete
                                                            </v-icon>
                                                        </v-btn>
                                                    </v-row>
                                                </v-list-item>
                                            </v-card-actions>
                                        </v-card>
                                    </div>
                                </template>
                            </div>
                            <div v-else>
                                <v-card outlined style="border-radius: 25px;">
                                    <v-card-text>
                                        <v-layout row wrap>
                                        <v-flex xs12 md4>
                                            <div align="center" class="my-10">
                                                <v-avatar color="primary" size="96">
                                                    <v-icon class="white--text"> mdi-alert-circle
                                                    </v-icon>
                                                </v-avatar>
                                            </div>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                            <v-layout column>
                                                <v-flex xs12 class="mt-1">
                                                    <div align="center" class="mt-15 ml-5 title">No Posts Found..</div>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                    </v-card-text>
                                    
                                </v-card>
                            </div>
                        </v-flex>
                        
                    </v-layout>


                </div>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 class="mt-5" v-if="view == 'settings'">
              <v-layout column>
                <v-flex xs12>
                  <v-layout row wrap>
                    <v-flex xs2>
                      <div class="mt-5 ml-3">
                        Public Profile
                      </div>
                    </v-flex>
                    <v-flex xs10>
                      <v-switch
                        class="ml-3"
                        v-model="user.public"
                        inset
                        @change="updateProfileSwitch()"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-layout row wrap>
                    <v-flex xs2>
                      <div class="mt-5 ml-3">
                        Newsletter
                      </div>
                    </v-flex>
                    <v-flex xs10>
                      <v-switch
                        class="ml-3"
                        v-model="user.profile.newsletter"
                        inset
                        @change="updateProfileSwitch()"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-btn depressed class="text-none mt-5 secondary primary--text" @click="passwordDialog = true">
                    Change Password
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
          </v-card>
        
      </v-flex>
    </v-layout>
  </div>
</template>
<style>
.ck-editor__editable {
  min-height: 200px;
}
</style>
<script>
import Vue from 'vue'
import apiCall from "@/utils/api";
import Comments from "@/components/system/Comments.vue"
import { mapGetters, mapActions } from "vuex";
import { USER_REQUEST } from "@/store/actions/user";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadAdapter from '@/uploadAdapter.js';

export default {
  components: {
    Comments
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,

      editor: ClassicEditor,
      editorConfig: {
          extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
              return new uploadAdapter(loader);
          } }],
          language: 'en',
      },

      dashBreadcrumb: true,
      showBreadcrumb: false,

      view: "posts",
      loading: false,
      valid: true,
      message: "",
      color: "",
      confirm: false,
      snackbar: false,
      inputRules: [(v) => !!v || "Input is required"],

      formData: null,

      profileDialog: false,

      imageDialog: false,
      passwordDialog: false,
      imageLoading: false,
      fileRules: [(v) => !!v || "Kindly Select a File"],
      imageFile: null,
      imageValid: true,

      confirmpassword: "",
      passwordRules: [
        (v) => !!v || "Password is Required",
        (v) => (v && v.length >= 8) || "Minimum 8 Characters",
        (v) => /(?=.*[A-Z])/.test(v) || "Must Have at Least One Uppercase Character",
        (v) => /(?=.*\d)/.test(v) || "Must Have at Least One Number",
        (v) => /([!@$%.])/.test(v) || "Must Have at Least One Special Character",
      ],

      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      monthNames: [
        { name: "January" },
        { name: "February" },
        { name: "March" },
        { name: "April" },
        { name: "May" },
        { name: "June" },
        { name: "July" },
        { name: "August" },
        { name: "September" },
        { name: "October" },
        { name: "November" },
        { name: "December" },
      ],

      user: {
        id: null,
        f_name: null,
        m_name: null,
        l_name: null,
        email: null,
        phone: null,
        bio: null,
        public: null,

        profile: {
          county_id: null,
          gender_id: null,
          dob: null,
          newsletter: 1,          
        }
      },

      passwordData: {
        current_password: null,
        new_password: null,
      },

      dateOfBirth: false,

      dialog: false,
      likeLoader: false,
      likeIndex: -1,
      dislikeLoader: false,
      dislikeIndex: -1,
      deleteLoader: false,
      deleteIndex: -1,
      postMessage: false,
      votesDialog: false,
      postId: null,
      cordova: Vue.cordova,
      snackbar: false,
      message: '',
      post: {
          content: '',
      },
      postVotes: [],
      postcard: {},
      commentDialog: false,
      loading: false,
      dialog: false,
      imageLink: '',
      imageName: "",
      imageUrl: "",
      imageFile: "",
      inputRules: [
          v => v.length >= 3 || 'Minimum length is 3 characters'
      ],
      fileRules: [(v) => !!v || "Kindly Select a File"],
      imageDialog: false,
    };
  },
  watch: {
    darkState(oldval, newval) {
      this.handledarkmode();
    },
  },
  created() {
    this.assignProfile()
    this.getFormData();
    this.fetchUserPosts(1);
  },
  methods: {
    ...mapActions([
      "changeDarkState",
      "fetchUserPosts"
    ]),
    getFormData() {
      apiCall({
        url: "/api/user?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
        })
        .catch((error) => {
          this.message = "an error occurred";
          this.color = "error";
        });
    },
    assignProfile(){
      this.user.id = this.getProfile.id
      this.user.f_name = this.getProfile.f_name
      this.user.m_name = this.getProfile.m_name
      this.user.l_name = this.getProfile.l_name
      this.user.email = this.getProfile.email
      this.user.phone = this.getProfile.phone
      this.user.bio = this.getProfile.bio
      if(this.getProfile.public == 0){
        this.user.public = false
      }else{
        this.user.public = true
      }
      

      if(this.getProfile.user_profile!=null){
        this.user.profile.county_id = this.getProfile.user_profile.county_id
        this.user.profile.gender_id = this.getProfile.user_profile.gender_id
        this.user.profile.dob = this.getProfile.user_profile.dob
        this.user.profile.newsletter = this.getProfile.user_profile.newsletter
        if(this.getProfile.user_profile.newsletter == 0){
          this.user.profile.newsletter = false
        }else{
          this.user.profile.newsletter = true
        }
      }
    },
    handledarkmode() {
      if (this.darkState == true) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    },
    changeView(view) {
      if (view == "default") {
        this.dashBreadcrumb = true;
        this.showBreadcrumb = false;
      } else if (view == "create") {
      } else if (view == "show") {
        this.dashBreadcrumb = false;
        this.showBreadcrumb = true;
      }
      this.view = view;
    },
    closeDateOfBirth() {
      this.dateOfBirth = false;
    },
    savePassword() {
      if (this.$refs.passwordForm.validate()) {
        if (this.passwordData.new_password != this.confirmpassword) {
          this.message = "New Password and Confirm Password do not Match";
          this.color = "error";
          this.snackbar = true;
        } else {
          confirm("Are you sure you want to change your account password") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/user?type=password",
              data: this.passwordData,
              method: "POST",
            })
              .then((resp) => {
                this.snackbar = true;
                this.message = "Password Updated Successfully";
                this.color = "success";
                this.confirm = false;
                this.loading = false;
                this.passwordDialog = false;
              })
              .catch((error) => {
                this.snackbar = true;
                this.confirm = false;
                this.message =
                  "Current Password does not match with our records";
                this.color = "error";
                this.loading = false;
              });
          }
        }
      }
    },
    updateProfileSwitch(){
      this.loading = true;
        apiCall({
          url: "/api/user/" + this.user.id + "?type=user",
          data: this.user,
          method: "PUT",
        })
          .then((resp) => {
            this.message = "Profile Updated Successfully";
            this.color = "orange";
            this.loading = false;
            this.snackbar = true;
            this.confirm = false;
            this.profileDialog = false;
            this.$store.dispatch(USER_REQUEST);
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.loading = false;
            this.snackbar = true;
          });
    },
    updateProfile(){
      if (this.$refs.profileForm.validate()) {
        confirm("Are you sure you want to update your profile?") && (this.confirm = true);
        if (this.confirm) {
          this.loading = true;
          apiCall({
            url: "/api/user/" + this.user.id + "?type=user",
            data: this.user,
            method: "PUT",
          })
            .then((resp) => {
              this.message = "Profile Updated Successfully";
              this.color = "orange";
              this.loading = false;
              this.snackbar = true;
              this.confirm = false;
              this.profileDialog = false;
              this.$store.dispatch(USER_REQUEST);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.loading = false;
              this.snackbar = true;
            });
        }
      }
    },
    uploadImage() {
      if (this.$refs.imageForm.validate()) {
        this.imageLoading = true;
        let formData = new FormData();

        // files

        formData.append("files", this.imageFile, this.imageFile.name);

        apiCall({
          url: "/api/user?type=image",
          data: formData,
          method: "POST",
        })
          .then((resp) => {
            this.snackbar = true;
            this.message = "profile Picture Uploaded Successfully";
            this.color = "success";
            this.$store.dispatch(USER_REQUEST);
            this.imageLoading = false;
            this.imageDialog = false;
            this.imageFile = null
          })
          .catch((error) => {
            this.imageLoading = false;
          });
      }
    },

    loadComments(post) {
            this.postId = post.id
            this.postcard = post
            this.commentDialog = true
        },
        votePost(postId, index, voteType) {
            if (voteType == 'up') {
                this.likeIndex = index
                this.likeLoader = true
            } else if (voteType == 'down') {
                this.dislikeIndex = index
                this.dislikeLoader = true
            }
            apiCall({
                url: '/api/post/' + postId + '/vote', data:
                {
                    vote: voteType
                }
                , method: 'POST'
            })
                .then(res => {
                    if (res) {
                        if (voteType == 'up') {
                            this.userPosts[index].votes++;
                            this.userPosts[index].hasVoted = true;
                            this.userPosts[index].post_votes = this.userPosts[index].post_votes.concat(res)
                            this.likeIndex = -1
                            this.likeLoader = false
                        } else if (voteType == 'down') {
                            this.userPosts[index].votes--;
                            this.userPosts[index].hasVoted = true;
                            this.userPosts[index].post_votes = this.userPosts[index].post_votes.concat(res)
                            this.dislikeIndex = -1
                            this.dislikeLoader = false
                        }
                    }
                });
        },
        deletePost(postId, index) {
          this.deleteLoader = true
          this.deleteIndex = index
            apiCall({
                url: '/api/post/' + postId,
                method: 'DELETE'
            })
                .then(res => {
                  this.deleteLoader = false
                  this.fetchUserPosts(this.userPostPagination.current_page);
                });
        },
        showVotes(post) {
            this.postVotes = post.post_votes
            this.votesDialog = true
        },
        showImage(post) {
            this.imageLink = post.pic
            this.imageDialog = true
        },


  },
  computed: {
    ...mapGetters([
      "getProfile", 
      "darkState", 
      "userPosts",
      "userPostPagination"
    
    ]),
    formatDateOfBirth() {
      if (this.user.profile.dob != null) {
        const d = new Date(this.user.profile.dob);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
    dateToday() {
      var today = new Date();

      var timestamp =
        today.getFullYear() +
        "-" +
        this.months[today.getMonth()] +
        "-" +
        ("0" + today.getDate()).slice(-2);
      return timestamp;
    },
  },
};
</script>
