<template>
    <div class="welcome">
      <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
        <span>{{ message }}</span>
      </v-snackbar>
      <v-progress-linear
        v-if="innovationAdminInviteLoader"
        height="1"
        indeterminate
        color="primary"
      >
      </v-progress-linear>
      <v-progress-linear
        v-if="pendingConnectionLoader"
        height="1"
        indeterminate
        color="primary"
      >
      </v-progress-linear>
      <v-container>
        <v-layout row wrap>
          <v-flex xs12 md3>
            <v-card outlined class="mt-5">
              <v-tabs vertical>
                <v-tab @click="switchTab('innovationAdminInvites')">
                  <v-icon left> mdi-handshake </v-icon>
                  <div class="text-none">
                    Innovation Admin Invites
                    <b v-if="innovationAdminInviteLoader == false"
                      >({{ innovationAdminInvitePagination.total }})</b
                    >
                    <v-progress-circular
                      v-if="innovationAdminInviteLoader"
                      :width="1"
                      :size="10"
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </v-tab>
              </v-tabs>
            </v-card>
          </v-flex>
          <v-flex xs12 md9>
            <v-card
              outlined
              v-if="view == 'innovationAdminInvites'"
              class="mt-5 mx-5"
              style="border-radius: 25px"
            >
              <div
                id="innovationAdminInvitesList"
                class="pa-3"
                style="
                  margin: 1px, 1px;
                  padding: 1px;
                  width: auto;
                  height: 80vh;
                  overflow-x: hidden;
                  overflow-y: scroll;
                  text-align: justify;
                "
              >
                <v-layout column>
                  <div v-if="innovationAdminInvites.length != 0">
                    <template v-for="(innovationAdminInvite, index) in innovationAdminInvites">
                      <div :key="index">
                        <v-flex xs12 class="mt-3 mx-3">
                          <a style="text-decoration: none">
                            <v-card
                              outlined
                              class="mb-3"
                              style="border-radius: 25px"
                            >
                              <v-card-text>
                                <div class="pa-2">
                                  <v-layout row wrap>
                                    <v-flex xs12 md4>
                                      <div align="center" class="my-5">
                                        <v-img
                                            max-height="150"
                                            v-if="innovationAdminInvite.innovation.featured_image == null"
                                            src="img/logo_primary.png"
                                            style="border-radius: 25px"
                                            >
                                            </v-img>
                                            <v-img
                                            max-height="150"
                                            v-else
                                            :src="
                                                path +
                                                '/storage/innovation_pictures/' +
                                                innovationAdminInvite.innovation.featured_image
                                            "
                                            style="border-radius: 25px"
                                            >
                                            </v-img>
                                      </div>
                                    </v-flex>
                                    <v-flex xs12 md8>
                                      <v-layout column>
                                        <v-flex xs12>
                                          <div class="mx-2">
                                            <div class="mt-5 title mr-5">
                                              {{ innovationAdminInvite.innovation.name }}
                                            </div>
                                            <p
                                              class="mt-5 grey--text caption font-italic mr-5"
                                            >
                                              {{innovationAdminInvite.innovation.brief}}
                                            </p>
                                          </div>
                                        </v-flex>
                                        <v-flex xs12 class="mt-7 mb-6">
                                          <div class="mx-2">
                                            <v-layout row wrap>                                              
                                              <v-flex xs12 md6>
                                                <div class="mx-1">
                                                  <v-btn
                                                    class="green white--text text-none my-2"
                                                    depressed
                                                    block
                                                    :loading="
                                                      acceptLoading &&
                                                      acceptIndex ==
                                                        innovationAdminInvite.id
                                                    "
                                                    @click="
                                                      acceptInnovation(innovationAdminInvite.id)
                                                    "
                                                  >
                                                    Accept Invitation
                                                    <v-icon right
                                                      >mdi-account-remove</v-icon
                                                    >
                                                  </v-btn>
                                                </div>
                                              </v-flex>
                                              <v-flex xs12 md6>
                                                <div class="mx-1">
                                                  <v-btn
                                                    class="red white--text text-none my-2"
                                                    depressed
                                                    block
                                                    :loading="
                                                      rejectLoading &&
                                                      rejectIndex ==
                                                        innovationAdminInvite.id
                                                    "
                                                    @click="
                                                      rejectInnovation(innovationAdminInvite.id)
                                                    "
                                                  >
                                                  Reject Invitation
                                                    <v-icon right
                                                      >mdi-account-cancel</v-icon
                                                    >
                                                  </v-btn>
                                                </div>
                                              </v-flex>
                                            </v-layout>
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </div>
                              </v-card-text>
                            </v-card>
                          </a>
                        </v-flex>
                      </div>
                    </template>
                  </div>
                  <div v-else>
                    <v-flex xs12 class="mb-10">
                      <v-card outlined style="border-radius: 25px">
                        <v-layout row wrap>
                          <v-flex xs12 md4>
                            <div align="center" class="my-10">
                              <v-avatar color="primary" size="96">
                                <v-icon class="white--text">
                                  mdi-alert-circle
                                </v-icon>
                              </v-avatar>
                            </div>
                          </v-flex>
                          <v-flex xs12 md8>
                            <v-layout column>
                              <v-flex xs12 class="mt-1">
                                <div class="mt-15 ml-5 title">
                                  No Innovation Admin Invites..
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </div>
                </v-layout>
              </div>
            </v-card>
            <div v-if="view == 'innovationAdminInvites'" class="mx-5 my-5">
              <v-pagination
                v-if="innovationAdminInviteLength != 0"
                :length="innovationAdminInviteLength"
                total-visible="10"
                v-model="innovationAdminInvitePagination.current_page"
                @input="changeInnovationAdminPage()"
                circle
              >
              </v-pagination>
            </div>
  
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </template>
  <style>
  .ck-editor__editable {
    min-height: 200px;
  }
  </style>
  <script>
  import Vue from "vue";
  import apiCall from "@/utils/api";
  import VClamp from "vue-clamp";
  import { mapState, mapGetters, mapActions } from "vuex";
  
  export default {
    components: {
      VClamp,
    },
    data() {
      return {
        path: process.env.VUE_APP_API_URL,
        color: "",
        message: "",
        snackbar: false,
        loading: false,
        selection: 1,
        drawer: false,
        dialog: false,
        messageDialogue: false,
  
        acceptLoading: false,
        rejectLoading: false,
        acceptIndex: 0,
        rejectIndex: 0,
  
        view: "innovationAdminInvites",
  
        filter: {
          institution_id: null,
          search: null,
          time_period: null,
          sectors: [],
          interests: [],
          sdgs: [],
        },
        innovationData: {
          id: null,
        },
        userData: null,
        newMessage: {
          recipient_id: null,
          body: null,
        },
      };
    },
    mounted() {},
    created() {
      window.scrollTo(0, 0);
      this.fetchInnovationAdminInvites(1);
    },
    methods: {
      ...mapActions([
        "fetchInnovationAdminInvites",
      ]),
      switchTab(tab) {
        if (tab == "innovationAdminInvites") {
          this.fetchInnovationAdminInvites(this.innovationAdminInvitePagination.current_page);
        }
        this.view = tab;
      }, 
      rejectInnovation(id) {
        this.rejectLoading = true;
        this.rejectIndex = id;
        this.innovationData.id = id;
        apiCall({
          url: "/api/innovation-administrator?type=rejectInvite",
          data: this.innovationData,
          method: "POST",
        })
          .then((resp) => {
            this.rejectLoading = false;
            this.rejectIndex = null;
            this.fetchInnovationAdminInvites(this.innovationAdminInvitePagination.current_page);
          })
          .catch((error) => {
            this.message =
              "An Error Occurred; Couldn't Complete Request. Please Try Again";
            this.color = "error";
            this.rejectLoading = false;
            this.snackbar = true;
          });
      },
      acceptInnovation(id) {
        this.acceptLoading = true;
        this.acceptIndex = id;
        this.innovationData.id = id;
        apiCall({
          url: "/api/innovation-administrator?type=acceptInvite",
          data: this.innovationData,
          method: "POST",
        })
          .then((resp) => {
            this.acceptLoading = false;
            this.acceptIndex = null;
            this.fetchInnovationAdminInvites(this.innovationAdminInvitePagination.current_page);
          })
          .catch((error) => {
            this.message =
              "An Error Occurred; Couldn't Complete Request. Please Try Again";
            this.color = "error";
            this.acceptLoading = false;
            this.snackbar = true;
          });
      },
      changeInnovationAdminPage() {
          this.fetchInnovationAdminInvites(
              this.innovationAdminInvitePagination.current_page
          );
      },
    },
    computed: {
      ...mapGetters([
        "innovationAdminInvites",
        "innovationAdminInviteLoader",
        "innovationAdminInvitePagination",
      ]),
  
      innovationAdminInviteLength: function () {
        return Math.ceil(
          this.innovationAdminInvitePagination.total / this.innovationAdminInvitePagination.per_page
        );
      },
    },
  };
  </script>
  