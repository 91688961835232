import apiCall from '@/utils/api'

const state = {
	openAsks: [],
	closedAsks: [],
	publicAsks: [],
    openAskPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	closedAskPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	publicAskPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    openAskLoader: false,
	closedAskLoader: false,
	publicAskLoader: false
};

const getters = {
	openAsks: (state) => state.openAsks,
	closedAsks: (state) => state.closedAsks,
	publicAsks: (state) => state.publicAsks,
    openAskLoader: (state) => state.openAskLoader,
	closedAskLoader: (state) => state.closedAskLoader,
	publicAskLoader: (state) => state.publicAskLoader,
    openAskPagination: (state) => state.openAskPagination,
	closedAskPagination: (state) => state.closedAskPagination,
	publicAskPagination: (state) => state.publicAskPagination,
}

const actions = {
    async fetchOpenAsks({commit}, page) {
		const response = await apiCall({url: `/api/ask?type=open&page=${page}`, method: 'GET' });
		commit('setOpenAsks', response)
        commit('stopOpenAskLoader', response)
	},
	async fetchClosedAsks({commit}, page) {
		const response = await apiCall({url: `/api/ask?type=closed&page=${page}`, method: 'GET' });
		commit('setClosedAsks', response)
        commit('stopClosedAskLoader', response)
	},
	async fetchPublicAsks({commit}, page) {
		const response = await apiCall({url: `/api/ask?type=public&page=${page}`, method: 'GET' });
		commit('setPublicAsks', response)
        commit('stopPublicAskLoader', response)
	},
    async filterOpenAsks({commit, state},resp){
		commit('setOpenAsks', resp)
	},
	async filterClosedAsks({commit, state},resp){
		commit('setClosedAsks', resp)
	},
	async filterPublicAsks({commit, state},resp){
		commit('setPublicAsks', resp)
	},
	async startOpenAskLoader({commit, state},resp){
		commit('startOpenAskLoader', resp)
	},
	async startClosedAskLoader({commit, state},resp){
		commit('startClosedAskLoader', resp)
	},
	async startPublicAskLoader({commit, state},resp){
		commit('startPublicAskLoader', resp)
	},
	async stopOpenAskLoader({commit, state},resp){
		commit('stopOpenAskLoader', resp)
	},
	async stopClosedAskLoader({commit, state},resp){
		commit('stopClosedAskLoader', resp)
	},
	async stopPublicAskLoader({commit, state},resp){
		commit('stopPublicAskLoader', resp)
	},
};

const mutations = {
	setOpenAsks: (state, openAsks) => {
		state.openAsks = openAsks.data
		state.openAskPagination.current_page = openAsks.current_page
		state.openAskPagination.total = openAsks.total
		state.openAskPagination.per_page = openAsks.per_page
	},
	setClosedAsks: (state, closedAsks) => {
		state.closedAsks = closedAsks.data
		state.closedAskPagination.current_page = closedAsks.current_page
		state.closedAskPagination.total = closedAsks.total
		state.closedAskPagination.per_page = closedAsks.per_page
	},
	setPublicAsks: (state, publicAsks) => {
		state.publicAsks = publicAsks.data
		state.publicAskPagination.current_page = publicAsks.current_page
		state.publicAskPagination.total = publicAsks.total
		state.publicAskPagination.per_page = publicAsks.per_page
	},
    startOpenAskLoader: (state) => state.openAskLoader = true,
	stopOpenAskLoader: (state) => state.openAskLoader = false,
	startClosedAskLoader: (state) => state.closedAskLoader = true,
	stopClosedAskLoader: (state) => state.closedAskLoader = false,
	startPublicAskLoader: (state) => state.publicAskLoader = true,
	stopPublicAskLoader: (state) => state.publicAskLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
