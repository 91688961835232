<template>
    <div class="investments">
        <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
            <span>{{ message }}</span>
        </v-snackbar>
        <v-container>
            <v-layout column>
                <div v-if="view == 'default'">
                    <v-card outlined class="mt-5">
                        <v-progress-linear v-if="investmentLoader" height="1" indeterminate color="primary">
                        </v-progress-linear>
                        <v-flex xs12 class="mt-10 mb-2">
                            <div class="mx-5">
                                <v-layout row wrap>
                                    <v-flex xs12 md4>
                                        <h1>Investments</h1>
                                    </v-flex>
                                    <v-flex xs12 md4>
                                        <div class="pa-1">
                                            <v-text-field class="text_field header" outlined dense label="Search"
                                                append-icon="mdi-undo-variant" @click:append="resetSearch()"
                                                v-on:keyup.enter="search" v-model="searchTerm"
                                                @input="enableSearch()"></v-text-field>
                                        </div>
                                    </v-flex>
                                    <v-flex xs12 md2>
                                        <div class="pa-1">
                                            <v-btn class="secondary primary--text text-none" depressed block @click="search"
                                                :loading="searchLoader" :disabled="searchButtonDisabled">
                                                Search
                                                <v-icon right>mdi-magnify</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-flex>
                                    <v-flex xs12 md2>
                                        <div class="pa-1">
                                            
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </v-flex>
                        <v-divider class="mt-9 mx-5"></v-divider>
                        <v-flex xs12 class="mt-5">
                            <div v-if="investments.length == 0">
                                <v-card elevation="0">
                                    <v-layout row wrap>
                                        <v-flex xs12 md1>
                                            <v-container fill-height fluid>
                                                <v-row align="center" justify="center">
                                                    <v-col class="text-center">
                                                        <v-icon large class="primary--text">
                                                            mdi-alert-circle-outline
                                                        </v-icon>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-flex>
                                        <v-flex xs12 md11>
                                            <v-container fill-height fluid>
                                                <v-row align="center" justify="center">
                                                    <v-col class="text-center">
                                                        No Investment Found
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </div>
                            <div v-else>
                                <div class="hidden-sm-and-down">
                                    <v-card elevation="0" class="pa-2">
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <thead class="header">
                                                    <tr class="tableHeader">
                                                        <th class="text-left text--text">Date</th>
                                                        <th class="text-left text--text">Innovation</th>
                                                        <th class="text-right text--text">Amount (Kshs)</th>
                                                        <!-- <th class="text-left text--text">Establishment Year</th> -->
                                                        <th class="text-left text--text">
                                                        Funding Type
                                                        </th>
                                                        <th class="text-left text--text">
                                                        Public
                                                        </th>
                                                        <th class="text-left text--text">
                                                        Status
                                                        </th>
                                                        <th class="text-left text--text">
                                                        Actions
                                                        </th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="item in investments"
                                                        :key="item.id"
                                                    >
                                                        <td>{{ item.created_at | moment("Do MMMM YYYY") }}</td>
                                                        <td>{{ item.innovation.name }}</td>
                                                        <td>
                                                        <div align="right">
                                                            {{ intToString(item.amount) }}
                                                        </div>
                                                        </td>
                                                        <td>{{ item.funding_type.name }}</td>
                                                        <td>
                                                        <div v-if="item.public==1">
                                                            Yes  
                                                        </div>
                                                        <div v-else>
                                                            No
                                                        </div>
                                                        </td>
                                                        <td>
                                                        <div v-if="(item.status==0)">
                                                            Pending
                                                        </div>
                                                        <div v-if="(item.status==1)">
                                                            Accepted
                                                        </div>
                                                        <div v-if="(item.status==2)">
                                                            Rejected
                                                        </div>
                                                        </td>
                                                        <td>
                                                            <v-btn
                                                                class="text-none"
                                                                color="primary"
                                                                block
                                                                depressed
                                                                rounded
                                                                @click="goToInnovation(item.innovation)"
                                                            >
                                                                View
                                                            </v-btn>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card>
                                </div>
                                <div class="hidden-md-and-up">
                                    <div>
                                        <v-layout column>
                                            <template v-for="(investment, index) in investments">
                                                <div :key="index">
                                                    <v-flex xs12 class="mb-2">
                                                        <v-card elevation="0">
                                                        <div class="pa-5">
                                                            <v-layout column>
                                                            <v-flex xs12>
                                                                <v-layout row wrap>
                                                                <v-flex xs11>
                                                                    <div
                                                                    class="title primary--text"
                                                                    >
                                                                    <b>{{ investment.created_at | moment("Do MMMM YYYY") }}</b>
                                                                    </div>
                                                                </v-flex>
                                                                <v-flex xs1> </v-flex>
                                                                </v-layout>
                                                            </v-flex>
                                                            <v-divider class="mt-2"></v-divider>
                                                            <v-flex xs12 class="mt-1">
                                                                <v-layout column>
                                                                <v-flex xs12 class="mt-5">
                                                                    <v-layout row wrap>
                                                                    <v-flex xs4>
                                                                        <b>Innovation</b>
                                                                    </v-flex>
                                                                    <v-flex xs8>
                                                                        {{
                                                                        investment.innovation.name
                                                                        }}
                                                                    </v-flex>
                                                                    </v-layout>
                                                                </v-flex>
                                                                <v-flex xs12 class="mt-5">
                                                                    <v-layout row wrap>
                                                                    <v-flex xs4>
                                                                        <b>Amount (Kshs)</b>
                                                                    </v-flex>
                                                                    <v-flex xs8>
                                                                        {{ intToString(investment.amount) }}
                                                                    </v-flex>
                                                                    </v-layout>
                                                                </v-flex>
                                                                <v-flex xs12 class="mt-5">
                                                                    <v-layout row wrap>
                                                                    <v-flex xs4>
                                                                        <b>Funding Type</b>
                                                                    </v-flex>
                                                                    <v-flex xs8>
                                                                        {{
                                                                        investment.funding_type.name
                                                                        }}
                                                                    </v-flex>
                                                                    </v-layout>
                                                                </v-flex>
                                                                <v-flex xs12 class="mt-5">
                                                                    <v-layout row wrap>
                                                                    <v-flex xs4>
                                                                        <b>Public</b>
                                                                    </v-flex>
                                                                    <v-flex xs8>
                                                                        <div v-if="investment.public==1">
                                                                            Yes  
                                                                        </div>
                                                                        <div v-else>
                                                                            No
                                                                        </div>
                                                                    </v-flex>
                                                                    </v-layout>
                                                                </v-flex>
                                                                <v-flex xs12 class="mt-5">
                                                                    <v-layout row wrap>
                                                                    <v-flex xs4>
                                                                        <b>Status</b>
                                                                    </v-flex>
                                                                    <v-flex xs8>
                                                                        <div v-if="(investment.status==0)">
                                                                            Pending
                                                                        </div>
                                                                        <div v-if="(investment.status==1)">
                                                                            Accepted
                                                                        </div>
                                                                        <div v-if="(investment.status==2)">
                                                                            Rejected
                                                                        </div>
                                                                    </v-flex>
                                                                    </v-layout>
                                                                </v-flex>
                                                                </v-layout>
                                                            </v-flex>
                                                            </v-layout>
                                                        </div>
                                                        </v-card>
                                                    </v-flex>
                                                    </div>
                                            </template>
                                        </v-layout>
                                    </div>
                                </div>
                            </div>
                        </v-flex>
                    </v-card>
                    <v-layout column class="mt-5">
                        <v-flex xs12>
                            <div align="left">
                                <v-pagination v-if="length != 0" :length="length" total-visible="10"
                                    v-model="investmentPagination.current_page" @input="changePage()" circle>
                                </v-pagination>
                            </div>
                        </v-flex>
                        <v-flex xs12>
                            <div v-if="length != 0" align="center" class="mt-5">
                                <b class="primary--text">Total: </b>{{ investmentPagination.total | formatNumber }}
                            </div>
                        </v-flex>
                    </v-layout>
                </div>
                
                <div v-if="view == 'show'">
                    <v-card outlined class="mt-5">
                        <v-progress-linear v-if="investmentLoader" height="1" indeterminate color="primary">
                        </v-progress-linear>
                        <v-card-title class="tableHeader">
                            <v-spacer></v-spacer>
                            <div class="text--text">View Investment</div>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="changeView('default')">
                                <v-icon class="text--text"> mdi-close </v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <div class="pa-1">
                                <v-card-text>
                                    <div class="pa-3">                                            
                                        <v-layout column class="mt-3">
                                            <v-flex xs12 class="mt-2">
                                                <v-layout row wrap>
                                                    <v-flex xs12 md2>
                                                        <b>Subject: </b>
                                                    </v-flex>
                                                    <v-flex xs12 md10>
                                                        <div>
                                                            {{ assignedBroadcast.subject }}
                                                        </div>
                                                    </v-flex>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs12 class="mt-6">
                                                <v-layout row wrap>
                                                    <v-flex xs12 md2>
                                                        <b>Date Created: </b>
                                                    </v-flex>
                                                    <v-flex xs12 md10>
                                                        <div>
                                                            {{
                                                                assignedBroadcast.created_at
                                                                | moment("DD/MM/YYYY - hh:mm a")
                                                            }}
                                                        </div>
                                                    </v-flex>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs12 class="mt-10">
                                                <v-layout row wrap>
                                                    <v-flex xs12 md2>
                                                        <b>Message: </b>
                                                    </v-flex>
                                                    <v-flex xs12 md10>
                                                        <div v-html="assignedBroadcast.message">
                                                            
                                                        </div>
                                                    </v-flex>
                                                </v-layout>
                                            </v-flex>
                                            
                                        </v-layout>                                               
                                    </div>
                                </v-card-text>
                            </div>
                        </v-card-text>
                    </v-card>
                </div>
            </v-layout>
        </v-container>
    </div>
</template>
<style>
.ck-editor__editable {
    min-height: 200px;
}
</style>
<script>
import Vue from "vue";
import apiCall from "@/utils/api";
import { mapGetters, mapState, mapActions } from "vuex";
import logData from "@/views/accessControl/logData";
import VClamp from "vue-clamp";

export default {
    components: {
        logData,
        VClamp,
    },
    data() {
        return {
            path: process.env.VUE_APP_API_URL,
            view: "default",

            message: "",
            color: "",

            snackbar: false,
            inputRules: [(v) => !!v || "Input is required"],

            searchTerm: null,
            userSearchTerm: null,

            searchLoader: false,
            searchButtonDisabled: false,

            assignedBroadcast: null,

            searchTerm: null,
            searchLoader: false,

            searchButtonDisabled: false,

            filteredBroadcasts: [],

            logData: {
                message: null,
                model: null,
            },
        };
    },

    created() {
        this.startInvestmentLoader();
        this.fetchInvestments(this.investmentPagination.current_page);
    },
    methods: {
        ...mapActions([
            "fetchInvestments",
            "startInvestmentLoader",
            "stopInvestmentLoader",
            "filterInvestments",
        ]),

        intToString(value) {
            var suffixes = ["", "k", "M", "B", "T"];
            var suffixNum = Math.floor(("" + value).length / 3);
            var shortValue = parseFloat(
                (suffixNum != 0
                ? value / Math.pow(1000, suffixNum)
                : value
                ).toPrecision(2)
            );
            if (shortValue % 1 != 0) {
                shortValue = shortValue.toFixed(1);
            }
            return shortValue + suffixes[suffixNum];
            },

        enableSearch() {
            this.searchButtonDisabled = false;
        },
        resetSearch() {
            this.searchTerm = null;
            this.filteredBroadcasts = true;
            this.startInvestmentLoader();
            this.fetchInvestments(1);
        },

        showBroadcast(investment) {
            this.assignedBroadcast = investment;

            this.changeView("show");
        },

        search() {
            if (this.searchTerm == null) {
                this.searchButtonDisabled = true;
            } else {
                this.searchLoader = true;
                this.searchButtonDisabled = false;
                this.startInvestmentLoader();
                apiCall({
                    url: "/api/investment?type=userInvestments&typeB=search&search=" + this.searchTerm,
                    method: "GET",
                })
                    .then((resp) => {
                        this.filterInvestments(resp);
                        this.searchLoader = false;
                        this.stopInvestmentLoader();
                        this.filteredBroadcasts = true;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.searchLoader = false;
                        this.stopInvestmentLoader();
                        this.message = "An Error Occurred";
                        this.color = "error";
                        this.snackbar = true;
                    });
            }
        },
        changeView(view) {
            if (view == "default") {
                
            } else if (view == "create") {
                
            } else if (view == "show") {
            }
            this.view = view;
        },
        changePage() {
            this.startInvestmentLoader();
            if (this.filteredBroadcasts == true) {
                this.searchButtonDisabled = false;
                apiCall({
                    url:
                        "/api/investment?type=userInvestments&page=" +
                        this.investmentPagination.current_page +
                        "&typeB=search&search=" +
                        this.searchTerm,
                    method: "GET",
                })
                    .then((resp) => {
                        this.filterInvestments(resp);
                        this.stopInvestmentLoader();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.stopInvestmentLoader();
                        this.message = "An Error Occurred";
                        this.color = "error";
                        this.snackbar = true;
                    });
            } else {
                this.fetchInvestments(
                    this.investmentPagination.current_page
                );
            }
        },
        goToInnovation(innovation) {
            this.$router.push("/innovations/" + innovation.slug);
        },

    },
    computed: {
        ...mapGetters([
            "investments",
            "investmentPagination",
            "investmentLoader",
        ]),
        length: function () {
            return Math.ceil(
                this.investmentPagination.total /
                this.investmentPagination.per_page
            );
        },
    },
};
</script>
  