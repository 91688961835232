import apiCall from '@/utils/api'

const state = {
	posts: [],
	userPosts: [],
	postPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	userPostsPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	}
};

const getters = {
	allPosts: (state) => state.posts,
	userPosts: (state) => state.userPosts,
	postPagination: (state) => state.postPagination,
	userPostPagination: (state) => state.userPostsPagination,
};

const actions = {
	async fetchPosts({commit, state}, page) {
		const response = await apiCall({url: `/api/post?page=${page}&type=timeline`, method: 'GET' });
		commit('setPosts', response)
	},
	async fetchUserPosts({commit}, page) {
		const response = await apiCall({url: `/api/post?page=${page}&type=user-posts`, method: 'GET' });
		commit('setUserPosts', response)
	},
	async updatePost({commit}, page) {
		try {
			const response = await apiCall({url: `/api/post?page=${page}&type=timeline`, method: 'GET' });
			commit('setPost', response);
		} catch (error) {
			console.log(error.response)
		}
	},
	async appendPost({commit}, post) {
		commit('setNewPost', post);
	},
};

const mutations = {
	setPosts: (state, posts) => {
		state.posts = posts.data,
		state.postPagination.current_page = posts.current_page
		state.postPagination.total = posts.total
		state.postPagination.per_page = posts.per_page
	},
	setUserPosts: (state, posts) => {
		state.userPosts = posts.data,
		state.userPostsPagination.current_page = posts.current_page
		state.userPostsPagination.total = posts.total
		state.userPostsPagination.per_page = posts.per_page
	},
	setPost: (state, post) => {
		if(post.data.length != 0){
			state.posts = state.posts.concat(post.data),
			state.postPagination.current_page = post.current_page
			state.postPagination.total = post.total
			state.postPagination.per_page = post.per_page
		}
	},
	setNewPost: (state, post) => {
		state.posts = state.posts.concat(post)
	}
};

export default {
	state,
	getters,
	actions,
	mutations
}