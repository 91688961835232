<template>
  <div>
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-app-bar elevate-on-scroll color="header" app>
      <v-container>
        <v-row align="center">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="hidden-md-and-up primary--text">
          </v-app-bar-nav-icon>
          <a @click="goToHome()">
            <v-img max-width="124" class="mt-1" src="img/logo_primary.png">
            </v-img>
          </a>
          <v-spacer class="hidden-md-and-up"></v-spacer>
          <v-divider vertical class="mt-7 mb-3 ml-2"></v-divider>
          <v-spacer class="hidden-md-and-up"></v-spacer>
          <a href="https://www.innovationagency.go.ke/" target="_blank">
            <v-img max-width="80" class="ml-2" src="img/logo_kenia.png"> </v-img>
          </a>
          

          <v-spacer></v-spacer>
          <v-text-field v-if="isAuthenticated" class="search_field background hidden-sm-and-down mr-5" outlined dense
            v-model="searchInnovationPagination.search" append-icon="mdi-magnify" required @click:append="search()"
            v-on:keyup.enter="search()"></v-text-field>
          <v-spacer></v-spacer>
          <v-btn small depressed class="primary white--text mr-2 mt-4 hidden-sm-and-down text-none"
            @click="changeLoginCardState" v-if="!isAuthenticated">Get Started
          </v-btn>

          <v-menu left offset-y v-if="isAuthenticated">
            <template v-slot:activator="{ on, attrs }">
              <div v-if="notifications.length == 0" class="hidden-sm-and-down">
                <v-btn small outlined class="mr-2" v-bind="attrs" v-on="on">
                 <v-icon> mdi-bell </v-icon>
                </v-btn>
              </div>
              <div v-else class="hidden-sm-and-down">
                <v-badge overlap color="red" :content="notifications.length" class="mr-2">
                  <v-btn small outlined v-bind="attrs" v-on="on">
                    <v-icon> mdi-bell </v-icon>
                  </v-btn>
                </v-badge>
              </div>
            </template>
            <v-list two-line>
              <template v-for="notification in notifications.slice(0, 5)">
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\FriendRequest'
                ">
                  <v-list-item-content>
                    <v-list-item-title>{{ notification.data.requester.f_name }}
                      {{
                        notification.data.requester.l_name
                      }}</v-list-item-title>
                    <v-list-item-subtitle>Connection Request -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\InnovationAdminNotif'
                ">
                  <v-list-item-content>
                    <v-list-item-title>{{ notification.data.data.innovation.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Innovation Administration -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\EventBroadcast'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Event: {{ notification.data.event.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Connection Request -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\CallBroadcast'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Call: {{ notification.data.call.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Connection Request -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\ContactUsMessage'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Contact Us - {{ notification.data.contact.subject }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.contact.user.f_name }} {{ notification.data.contact.user.l_name }} -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\NewInnovation'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Innovation: {{ notification.data.innovation.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.innovation.owner.f_name }} {{ notification.data.innovation.owner.l_name }} -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\RejectInnovation'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Innovation Not Approved
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.innovation.name }} -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\ApproveInnovation'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Innovation Approved
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.innovation.name }} -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\VerifyInnovation'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Innovation Verified
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.innovation.name }} -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\UnVerifyInnovation'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Innovation Unverified
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.innovation.name }} -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\NewInstitutionUser'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Affiliation Request: {{ notification.data.institution.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.user.f_name }} {{ notification.data.user.m_name }} {{ notification.data.user.l_name }} -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\InstitutionUserApproved'
                ">
                  <v-list-item-content>
                    <v-list-item-title>{{ notification.data.institution.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Institution Affiliation Approved -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\NewInstitutionInnovation'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Affiliation Request: {{ notification.data.institution.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle> {{ notification.data.innovation.name }} -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\InstitutionInnovationApproved'
                ">
                  <v-list-item-content>
                    <v-list-item-title>{{ notification.data.institution.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Innovation Affiliation Approved -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\IndividualInvestmentOffer'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Investment Offer - {{ notification.data.data.innovation.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.data.user.f_name }} {{ notification.data.data.user.m_name }} {{ notification.data.data.user.l_name }} -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\FirmInvestmentOffer'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Investment Offer - {{ notification.data.data.innovation.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.data.firm.name }} -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\IndividualOfferRejected'
                ">
                  <v-list-item-content>
                    <v-list-item-title>{{ notification.data.data.innovation.name }} - {{ notification.data.data.investment.amount }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Offer Rejected
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\IndividualOfferAccepted'
                ">
                  <v-list-item-content>
                    <v-list-item-title>{{ notification.data.data.innovation.name }} - {{ notification.data.data.investment.amount }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Offer Accepted
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\FirmOfferAccepted'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Offer Accepted - {{ notification.data.data.innovation.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.data.investmentFirm.name }} - 
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\FirmOfferRejected'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Offer Rejected - {{ notification.data.data.innovation.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.data.investmentFirm.name }} - 
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\InnovationAdminAccepted'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Innovation Admin Accepted - {{ notification.data.data.innovation.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.data.user.f_name }} {{ notification.data.data.user.m_name }} {{ notification.data.data.user.l_name }} - 
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\BroadcastMessage'
                ">
                  <v-list-item-content>
                    <v-list-item-title>{{ notification.data.data.broadcast.subject }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Broadcast Message - 
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-if="notifications.length == 0">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>No New Notification</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-divider></v-divider>
              <template>
                <v-list-item to="/notifications">
                  <v-list-item-content>
                    <v-list-item-title>All Notifications</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-menu> 
          
          <div v-if="unreadMessages == 0 && isAuthenticated" class="hidden-sm-and-down">
            <v-btn small outlined class="mr-2" to="/messages">
              <v-icon> mdi-message </v-icon>
            </v-btn>
          </div>
          <div v-if="unreadMessages != 0 && isAuthenticated" class="hidden-sm-and-down">
            <v-badge overlap color="red" :content="unreadMessages" class="mr-2">
              <v-btn small outlined class="mr-2" to="/messages">
                <v-icon> mdi-message </v-icon>
              </v-btn>
            </v-badge>
          </div>

          
          <v-menu left offset-y v-if="isAuthenticated">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small outlined v-bind="attrs" v-on="on" class="text-none hidden-sm-and-down">
                <v-avatar size="36" v-if="getProfile.profile_pic != null">
                  <img :src="path + '/profile_pics/' + getProfile.profile_pic" alt="PPIC" />
                </v-avatar>
                <v-avatar v-else>
                  <v-icon> mdi-account-circle </v-icon>
                </v-avatar>
                <!-- <div class="ml-2">{{ getProfile.f_name }}</div>
                <v-icon>mdi-chevron-down</v-icon> -->
                <v-icon small right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-avatar size="46" class="primary white--text" v-if="getProfile.display_img==null">
                    {{ getProfile.f_name[0] }}
                  </v-avatar>
                  <v-avatar size="46" class="primary white--text" v-else>
                    <img :src="path+'/storage/profile_pics/'+getProfile.display_img" alt="prof_pic">
                  </v-avatar>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ getProfile.f_name }}
                    {{ getProfile.l_name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item router to="/profile">
                <v-list-item-title class="body-2">Profile</v-list-item-title>
              </v-list-item>
              <v-list-item router to="/interests">
                <v-list-item-title class="body-2">Interests</v-list-item-title>
              </v-list-item>
              <v-list-item router to="/connections">
                <v-list-item-title class="body-2">Connections</v-list-item-title>
              </v-list-item>
              <v-list-item router to="/investments" v-if="$can('investment_view')">
                <v-list-item-title class="body-2">Investments</v-list-item-title>
              </v-list-item>
              <v-list-item router to="/broadcasts">
                <v-list-item-title class="body-2">Broadcasts</v-list-item-title>
              </v-list-item>
              <v-list-item @click="signOut">
                <v-list-item-title class="body-2">Sign Out</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-tooltip v-if="!$vuetify.theme.dark" bottom>
                  <template v-slot:activator="{ on }">
                    
                      <v-btn block small deprsessed v-on="on" color="info" @click="changeDarkState">
                        <v-icon>mdi-moon-waxing-crescent</v-icon>
                      </v-btn>
                    
                  </template>
                  <span>Dark Mode</span>
                </v-tooltip>

                <v-tooltip v-else bottom>
                  <template v-slot:activator="{ on }">
                    
                      <v-btn block small deprsessed v-on="on" color="info" @click="changeDarkState">
                        <v-icon color="yellow">mdi-white-balance-sunny</v-icon>
                      </v-btn>
                    
                  </template>
                  <span>Light Mode</span>
                </v-tooltip>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- <v-btn small depressed class="secondary primary--text mt-4 hidden-sm-and-down text-none"
            @click="changeLoginCardState">Login
          </v-btn> -->
        </v-row>
      </v-container>

      <template v-slot:extension v-if="isAuthenticated">
        <v-btn v-if="$can('innovation_create')" small depressed
          class="secondary primary--text hidden-md-and-up text-none" @click="changeInnovationCardState">Create
          Innovation
        </v-btn>
        <v-spacer class="hidden-md-and-up"></v-spacer>
        <v-menu left offset-y v-if="isAuthenticated" class="hidden-md-and-up">
            <template v-slot:activator="{ on, attrs }">
              <div v-if="notifications.length == 0" class="hidden-md-and-up">
                <v-btn icon class="mr-2" v-bind="attrs" v-on="on">
                  <v-icon> mdi-bell </v-icon>
                </v-btn>
              </div>
              <div v-else class="hidden-md-and-up">
                <v-badge overlap color="red" :content="notifications.length" class="mr-2"
                  offset-x="23"
                  offset-y="24"
                >
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon> mdi-bell </v-icon>
                  </v-btn>
                </v-badge>
              </div>
            </template>
            <v-list two-line>
              <template v-for="notification in notifications.slice(0, 5)">
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\FriendRequest'
                ">
                  <v-list-item-content>
                    <v-list-item-title>{{ notification.data.requester.f_name }}
                      {{
                        notification.data.requester.l_name
                      }}</v-list-item-title>
                    <v-list-item-subtitle>Connection Request -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\EventBroadcast'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Event: {{ notification.data.event.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Connection Request -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\CallBroadcast'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Call: {{ notification.data.call.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Connection Request -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\ContactUsMessage'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Contact Us: {{ notification.data.contact.subject }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.contact.user.f_name }} {{ notification.data.contact.user.l_name }} -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\NewInnovation'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Innovation: {{ notification.data.innovation.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.innovation.owner.f_name }} {{ notification.data.innovation.owner.l_name }} -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\RejectInnovation'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Innovation Not Approved
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.innovation.name }} -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\ApproveInnovation'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Innovation Approved
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.innovation.name }} -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\VerifyInnovation'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Innovation Verified
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.innovation.name }} -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\UnVerifyInnovation'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Innovation Unverified
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.innovation.name }} -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\NewInstitutionUser'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Affiliation Request: {{ notification.data.institution.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.user.f_name }} {{ notification.data.user.m_name }} {{ notification.data.user.l_name }} -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\InstitutionUserApproved'
                ">
                  <v-list-item-content>
                    <v-list-item-title>{{ notification.data.institution.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Institution Affiliation Approved -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\NewInstitutionInnovation'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Affiliation Request: {{ notification.data.institution.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle> {{ notification.data.innovation.name }} -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\InstitutionInnovationApproved'
                ">
                  <v-list-item-content>
                    <v-list-item-title>{{ notification.data.institution.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Innovation Affiliation Approved -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\IndividualInvestmentOffer'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Investment Offer - {{ notification.data.data.innovation.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.data.user.f_name }} {{ notification.data.data.user.m_name }} {{ notification.data.data.user.l_name }} -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\FirmInvestmentOffer'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Investment Offer - {{ notification.data.data.innovation.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.data.firm.name }} -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\IndividualOfferRejected'
                ">
                  <v-list-item-content>
                    <v-list-item-title>{{ notification.data.data.innovation.name }} - {{ notification.data.data.investment.amount }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Offer Rejected -
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\IndividualOfferAccepted'
                ">
                  <v-list-item-content>
                    <v-list-item-title>{{ notification.data.data.innovation.name }} - {{ notification.data.data.investment.amount }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Offer Accepted
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\FirmOfferAccepted'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Offer Accepted - {{ notification.data.data.innovation.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.data.investmentFirm.name }} - 
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\FirmOfferRejected'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Offer Rejected - {{ notification.data.data.innovation.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.data.investmentFirm.name }} - 
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\InnovationAdminAccepted'
                ">
                  <v-list-item-content>
                    <v-list-item-title>Innovation Admin Accepted - {{ notification.data.data.innovation.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ notification.data.data.user.f_name }} {{ notification.data.data.user.m_name }} {{ notification.data.data.user.l_name }} - 
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :key="notification.id" avatar @click="readNotification(notification)" v-if="
                  notification.type == 'App\\Notifications\\BroadcastMessage'
                ">
                  <v-list-item-content>
                    <v-list-item-title>{{ notification.data.data.broadcast.subject }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Broadcast Message - 
                      {{
                        notification.created_at | moment("ddd, h:mm a")
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-if="notifications.length == 0">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>No New Notification</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-divider></v-divider>
              <template>
                <v-list-item to="/notifications">
                  <v-list-item-content>
                    <v-list-item-title>All Notifications</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
          <div v-if="unreadMessages == 0 && isAuthenticated" class="hidden-md-and-up">
            <v-btn icon class="mr-2" to="/messages">
              <v-icon> mdi-message </v-icon>
            </v-btn>
          </div>
          <div v-if="unreadMessages != 0 && isAuthenticated" class="hidden-md-and-up">
            <v-badge overlap color="red" :content="unreadMessages" class="mr-2"
              offset-x="29"
              offset-y="22"
            >
              <v-btn icon class="mr-2" to="/messages">
                <v-icon> mdi-message </v-icon>
              </v-btn>
            </v-badge>
          </div>



        <v-menu left offset-y v-if="isAuthenticated" class="text-none hidden-md-and-up">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="text-none hidden-md-and-up">
              <v-avatar size="36" v-if="getProfile.profile_pic != null">
                <img :src="path + '/profile_pics/' + getProfile.profile_pic" alt="PPIC" />
              </v-avatar>
              <v-avatar v-else>
                <v-icon> mdi-account-circle </v-icon>
              </v-avatar>
              <!-- <div class="ml-2">{{ getProfile.f_name }}</div>
              <v-icon>mdi-chevron-down</v-icon> -->
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-icon>
                <v-avatar size="46" class="primary white--text">
                  {{ getProfile.f_name[0] }}
                </v-avatar>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ getProfile.f_name }}
                  {{ getProfile.l_name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item router to="/profile">
              <v-list-item-title class="body-2">Profile</v-list-item-title>
            </v-list-item>
            <v-list-item router to="/interests">
              <v-list-item-title class="body-2">Interests</v-list-item-title>
            </v-list-item>
            <v-list-item router to="/connections">
              <v-list-item-title class="body-2">Connections</v-list-item-title>
            </v-list-item>
            <v-list-item router to="/broadcasts">
              <v-list-item-title class="body-2">Broadcasts</v-list-item-title>
            </v-list-item>
            <v-list-item @click="signOut">
              <v-list-item-title class="body-2">Sign Out</v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-tooltip v-if="!$vuetify.theme.dark" bottom>
                  <template v-slot:activator="{ on }">
                    
                      <v-btn block small deprsessed v-on="on" color="info" @click="changeDarkState">
                        <v-icon>mdi-moon-waxing-crescent</v-icon>
                      </v-btn>
                    
                  </template>
                  <span>Dark Mode</span>
                </v-tooltip>

                <v-tooltip v-else bottom>
                  <template v-slot:activator="{ on }">
                    
                      <v-btn block small deprsessed v-on="on" color="info" @click="changeDarkState">
                        <v-icon color="yellow">mdi-white-balance-sunny</v-icon>
                      </v-btn>
                    
                  </template>
                  <span>Light Mode</span>
                </v-tooltip>
              </v-list-item>
          </v-list>
        </v-menu>
        <v-container class="hidden-sm-and-down">
          <v-layout row wrap>
            <v-flex xs10>
              <v-menu offset-y
                v-if="$can('log_view') || $can('report_view') || $can('role_view') || $can('user_view') || $can('call_view') || $can('ask_view') || $can('event_view') || $can('investment_firm_view') || $can('institution_view') || $can('organisation_view') || $can('eso_view') || $can('innovation_view')">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small text v-bind="attrs" v-on="on" class="text-none mr-1">
                    <v-icon small left>mdi-security</v-icon>
                    Administration
                    <v-icon small right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item router to="/dashboard" v-if="$can('dashboard_view')">
                    <v-list-item-icon>
                      <v-icon small>mdi-view-dashboard-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="caption">Dashboard</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item router to="/logs" v-if="$can('log_view')">
                    <v-list-item-icon>
                      <v-icon small>mdi-notebook-edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="caption">Logs</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item router to="/reports" v-if="$can('report_view')">
                    <v-list-item-icon>
                      <v-icon small>mdi-chart-box</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="caption">Reports</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item router to="/roles" v-if="$can('role_view')">
                    <v-list-item-icon>
                      <v-icon small>mdi-account-hard-hat</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="caption">Roles</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item router to="/users" v-if="$can('user_view')">
                    <v-list-item-icon>
                      <v-icon small>mdi-account-multiple</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="caption">Users</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item router to="/calls-admin" v-if="$can('call_view')">
                    <v-list-item-icon>
                      <v-icon small>mdi-folder-open</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="caption">Calls</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item router to="/asks-admin" v-if="$can('ask_view')">
                    <v-list-item-icon>
                      <v-icon small>mdi-cash-multiple</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="caption">Request for Funding</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item router to="/events-admin" v-if="$can('event_view')">
                    <v-list-item-icon>
                      <v-icon small>mdi-calendar</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="caption">Events</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item router to="/investment-firms-admin" v-if="$can('investment_firm_view')">
                    <v-list-item-icon>
                      <v-icon small>mdi-tie</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="caption">Investment Firms</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item router to="/institutions-admin" v-if="$can('institution_view')">
                    <v-list-item-icon>
                      <v-icon small>mdi-office-building-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="caption">Institutions</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item router to="/organisations-admin" v-if="$can('organisation_view')">
                    <v-list-item-icon>
                      <v-icon small>mdi-briefcase-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="caption">Organisations</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item router to="/esos-admin" v-if="$can('eso_view')">
                    <v-list-item-icon>
                      <v-icon small>mdi-lifebuoy</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="caption">ESOs</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item router to="/innovations-admin" v-if="$can('innovation_view')">
                    <v-list-item-icon>
                      <v-icon small>mdi-spa-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="caption">Innovations</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item router to="/contact-us-admin" v-if="$can('contact_us_view')">
                    <v-list-item-icon>
                      <v-icon small>mdi-forum</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="caption">Contact Us</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item router to="/broadcast-admin" v-if="$can('broadcast_view')">
                    <v-list-item-icon>
                      <v-icon small>mdi-broadcast</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="caption">Broadcasts</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
              <!-- <v-btn small text class="text-none mr-1" router to="/dashboard" v-if="$can('dashboard_view')">
                <v-icon small left>mdi-view-dashboard-outline</v-icon>
                Dashboard
              </v-btn> -->
              <v-btn small text class="text-none mr-1" router to="/home" v-if="$can('public_home_view')">
                <v-icon small left>mdi-home-outline</v-icon>
                Home
              </v-btn>
              <v-btn small text class="text-none mr-1" router to="/investor-dashboard" v-if="$can('public_investor_hub_view')">
                <v-icon small left>mdi-hubspot</v-icon>
                Investor Hub
              </v-btn>
              <v-btn small text class="text-none mr-1" router to="/innovations" v-if="$can('public_innovation_view')">
                <v-icon small left>mdi-spa-outline</v-icon>
                Innovations
              </v-btn>
              <v-btn small text class="text-none mr-1" router to="/investors" v-if="$can('public_investor_view')">
                <v-icon small left>mdi-tie</v-icon>
                Investors
              </v-btn>
              <v-btn v-if="$can('public_organisation_view')" small text class="text-none mr-1" router
                to="/organisations">
                <v-icon small left>mdi-briefcase-outline</v-icon>
                Organisations
              </v-btn>
              <v-btn v-if="$can('public_institution_view')" small text class="text-none mr-1" router to="/institutions">
                <v-icon small left>mdi-office-building-outline</v-icon>
                Institutions
              </v-btn>
              <v-menu offset-y
                v-if="$can('public_call_view') || $can('public_ask_view') || $can('public_event_view')">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small text v-bind="attrs" v-on="on" class="text-none mr-1">
                    <v-icon small left>mdi-cash-multiple</v-icon>
                    Funding/Support
                    <v-icon small right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item router to="/call-for-proposals" v-if="$can('public_call_view')">
                    <v-list-item-icon>
                      <v-icon small>mdi-folder-open</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="caption">Funding Opportunities</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list>
                  <v-list-item router to="/asks" v-if="$can('public_ask_view')">
                    <v-list-item-icon>
                      <v-icon small>mdi-cash-multiple</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="caption">Request for Funding</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list>
                  <v-list-item router to="/events" v-if="$can('public_event_view')">
                    <v-list-item-icon>
                      <v-icon small>mdi-calendar</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="caption">Events</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list>
                  <v-list-item router to="/esos" v-if="$can('public_eso_view')">
                    <v-list-item-icon>
                      <v-icon small>mdi-lifebuoy</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="caption">Enterprise Support Organisations</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
              <!-- <v-btn small text class="text-none mr-1">
                <v-icon small left>mdi-lifebuoy</v-icon>
                Support Services
              </v-btn> -->
            </v-flex>
            <v-flex xs2>
              <div align="right">
                <v-btn v-if="$can('innovation_create')" small depressed
                  class="secondary primary--text hidden-sm-and-down text-none" @click="changeInnovationCardState">Create
                  Innovation
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </v-app-bar>
    <v-navigation-drawer disable-resize-watcher app v-model="drawer">
      <v-text-field v-if="isAuthenticated" class="search_field background mx-2 my-2" outlined dense
            v-model="searchInnovationPagination.search" append-icon="mdi-magnify" required @click:append="search()"
            v-on:keyup.enter="search()"></v-text-field>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-group
          no-action
          sub-group
          class="text--text"
          v-if="isAuthenticated && ($can('role_view') || $can('user_view') || $can('call_view') || $can('ask_view') || $can('event_view') || $can('institution_view') || $can('innovation_view'))"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Administration</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item router to="/dashboard" v-if="$can('dashboard_view')">
                    <v-list-item-content>
                      <v-list-item-title>Dashboard</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon small>mdi-view-dashboard-outline</v-icon>
                    </v-list-item-icon>
                    
                  </v-list-item>        
          <v-list-item router to="/logs" v-if="$can('log_view')">
                    <v-list-item-content>
                      <v-list-item-title>Logs</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon small>mdi-notebook-edit</v-icon>
                    </v-list-item-icon>
                    
                  </v-list-item>
                  <v-list-item router to="/reports" v-if="$can('report_view')">
                    <v-list-item-content>
                      <v-list-item-title>Reports</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon small>mdi-chart-box</v-icon>
                    </v-list-item-icon>
                    
                  </v-list-item>

                  <v-list-item router to="/roles" v-if="$can('role_view')">
                    <v-list-item-content>
                      <v-list-item-title>Roles</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon small>mdi-account-hard-hat</v-icon>
                    </v-list-item-icon>
                    
                  </v-list-item>
                  <v-list-item router to="/users" v-if="$can('user_view')">                    
                    <v-list-item-content>
                      <v-list-item-title>Users</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon small>mdi-account-multiple</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-item router to="/calls-admin" v-if="$can('call_view')">
                    
                    <v-list-item-content>
                      <v-list-item-title>Calls</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon small>mdi-folder-open</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-item router to="/asks-admin" v-if="$can('ask_view')">
                    
                    <v-list-item-content>
                      <v-list-item-title>Request for Funding</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon small>mdi-cash-multiple</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-item router to="/events-admin" v-if="$can('event_view')">                    
                    <v-list-item-content>
                      <v-list-item-title>Events</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon small>mdi-calendar</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-item router to="/investment-firms-admin" v-if="$can('investment_firm_view')">                    
                    <v-list-item-content>
                      <v-list-item-title>Investment Firms</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon small>mdi-tie</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-item router to="/institutions-admin" v-if="$can('institution_view')">                    
                    <v-list-item-content>
                      <v-list-item-title>Institutions</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon small>mdi-office-building-outline</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-item router to="/organisations-admin" v-if="$can('organisation_view')">                    
                    <v-list-item-content>
                      <v-list-item-title>Organisations</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon small>mdi-briefcase-outline</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-item router to="/esos-admin" v-if="$can('eso_view')">
                    <v-list-item-icon>
                      <v-icon small>mdi-lifebuoy</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="caption">ESOs</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item router to="/innovations-admin" v-if="$can('innovation_view')">
                    
                    <v-list-item-content>
                      <v-list-item-title>Innovations</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon small>mdi-spa-outline</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
        </v-list-group>
        <v-list-item v-for="item in items" :key="item.title" v-if="isAuthenticated && $can(item.permission)" :to="item.route" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="changeLoginCardState" v-if="!isAuthenticated" link>
          <v-list-item-icon>
            <v-icon>mdi-account-lock-open</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Get Started</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          no-action
          sub-group
          class="text--text"
          v-if="isAuthenticated && ($can('public_call_view') || $can('public_ask_view') || $can('public_event_view'))"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Funding</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            link
            to="/call-for-proposals"
            v-if="$can('public_call_view')"
          >
            <v-list-item-title>Funding Opportunities</v-list-item-title>

            <v-list-item-icon>
              <v-icon>mdi-folder-open</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item
            link
            to="/asks"
            v-if="$can('public_ask_view')"
          >
            <v-list-item-title>Request for Funding</v-list-item-title>

            <v-list-item-icon>
              <v-icon>mdi-cash-multiple</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item
            link
            to="/events"
            v-if="$can('public_event_view')"
          >
            <v-list-item-title>Events</v-list-item-title>

            <v-list-item-icon>
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list-group>
      </v-list>
      <!-- <div class="mb-5 mt-5 hidden-md-and-up ma-5">
          <v-layout row wrap>
            <v-flex xs2>
              <div align="right">
                <v-img v-if="$i18n.locale == 'en'" max-width="25" src="/img/flags/english.png" class="mt-2 mr-2"></v-img>
                <v-img v-if="$i18n.locale == 'fr'" max-width="25" src="/img/flags/french.ico" class="mt-2 mr-2"></v-img>
              </div>
            </v-flex>
            <v-flex xs10>
              <v-select outlined dense :items="languages" item-text="title" item-value="language" v-model="languageChoice"
                class="language_field background" @input="changeLocale()"></v-select>
            </v-flex>
          </v-layout>
        </div> -->
      <!-- <v-divider class="mx-5 mb-3 white"></v-divider>
            <v-list dense shaped> -->
      <!-- <v-list-group no-action v-if="$can('role_view') || $can('user_view')" class="white--text" color="white"
                    dark>
                    <template v-slot:activator>
                        <v-list-item-icon>
                            <v-icon class="white--text">mdi-shield-half-full</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="white--text">{{
                                    $t("accessControlMsg")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <template v-for="(item, index) in accessControlPages">
                        <v-list-item :key="index" class="white" router :to="item.route"
                            v-if="item.route == $route.path && $can(item.permission)">
                            <v-list-item-icon>
                                <v-icon class="universal--text">{{ item.icon }}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title class="universal--text">
                                    {{ $t(item.title) }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item :key="index" router :to="item.route"
                            v-if="item.route != $route.path && $can(item.permission)">
                            <v-list-item-icon>
                                <v-icon class="white--text">{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="white--text">{{
                                        $t(item.title)
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list-group> -->
      <!-- </v-list> -->
    </v-navigation-drawer>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import Vue from "vue";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import { USER_REQUEST } from "@/store/actions/user";
import { mapGetters, mapActions } from "vuex";
// import i18n from "@/plugins/i18n";

export default {
  components: {},
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      drawer: false,
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard-outline", route: "/dashboard", permission: "dashboard_view"},
        { title: "Home", icon: "mdi-home-outline", route: "/home", permission: "public_home_view"},
        { title: "Investor Hub", icon: "mdi-hubspot", route: "/investor-dashboard", permission: "public_investor_hub_view"},
        { title: "Innovations", icon: "mdi-spa-outline", route: '/innovations', permission: "public_innovation_view" },
        { title: "Investors", icon: "mdi-tie", route: '/investors', permission: "public_investor_view" },
        { title: "Organisations", icon: "mdi-briefcase-outline", route: "/organisations", permission: "public_organisation_view" },
        { title: "Institutions", icon: "mdi-office-building-outline", route: "/institutions", permission: "public_institution_view"  },
        { title: "Contact Us", icon: "mdi-forum", route: "/contact-us-admin", permission: "contact_us_view"  },
      ],
      accessControlPages: [
        {
          title: "rolesMsg",
          icon: "mdi-account-hard-hat",
          route: "/roles",
          permission: "role_view",
        },
        {
          title: "usersMsg",
          icon: "mdi-account-multiple",
          route: "/users",
          permission: "user_view",
        },
      ],
      number: 5,
      number2: 3,
      languageChoice: "",
      languages: [
        { flag: "us", language: "en", title: "English" },
        { flag: "fr", language: "fr", title: "Français" },
      ],
    };
  },
  watch: {
    darkState() {
      this.handledarkmode();
    },
  },
  created() {
    this.initialize();
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$store.dispatch(USER_REQUEST);
    }
  },
  methods: {
    ...mapActions([
      "changeDarkState",
      "changeLanguage",
      "changeLoginCardState",
      "changeInnovationCardState",

      "fetchUnreadMessages",

      "startSearchInnovationLoader",
      "stopSearchInnovationLoader",
      "filterSearchInnovations",

      "startInstitutionStatisticLoader",
      "stopInstitutionStatisticLoader",
      "filterSearchInstitutionStatistics",

      "startOrganisationStatisticLoader",
      "stopOrganisationStatisticLoader",
      "filterSearchOrganisationStatistics",

      "startInvestmentFirmLoader",
      "stopInvestmentFirmLoader",
      "filterSearchInvestmentFirms",

      "startUserLoader",
      "stopUserLoader",
      "filterSearchUsers",

      "commitNotification",
      "commitNotifications",

      "assignInnovationData",
      "changeInnovationApprovalCardState"
    ]),
    signOut: function () {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push("/"));
    },
    goToHome(){
      this.$router.push("/");
    },
    initialize() {
      // this.languageChoice = this.language;
      Echo.private(`App.Models.User.${this.getProfile.id}`).notification(
        (notification) => {
          this.commitNotification(notification)
          if (notification.type == "App\\Notifications\\ApplicationAccepted") {
            this.message =
              "Eligible for " + notification.data.event.title;
            this.color = "success";
            this.$store.dispatch("fetchApplications");
          } else if ( notification.type == "App\\Notifications\\EventBroadcast" ) {
            this.message = "Event: " + notification.data.event.title;
            this.color = "success";
            this.snackbar = true;
            this.fetchNotifications();
            // this.$store.dispatch("fetchApplications");
          } else if ( notification.type == "App\\Notifications\\CallBroadcast" ) {
            this.message = "Call: " + notification.data.call.title;
            this.color = "success";
            this.snackbar = true;
            this.fetchNotifications();
            // this.$store.dispatch("fetchApplications");
          } else if ( notification.type == "App\\Notifications\\NewMessage" ) {
            
            this.$store.dispatch("fetchChats");
            this.$store.dispatch("fetchUnreadMessages");
          }

          
          //this.notifications = this.notifications.concat(notification);
        }
      );
      this.fetchNotifications();
      // this.fetchUnreadMessages()
    },
    fetchNotifications() {
      apiCall({ url: "/api/notifications", method: "GET" })
        .then((resp) => {
          this.commitNotifications(resp);
          // var i = 0
          // for(i; i<=resp.length; i++){
          // 	if(resp[i].type == 'App\\Notifications\\AmbulanceDispatched'){
          // 		this.addDispatchData(resp[i])
          // 	}
          // }
        })
        .catch((error) => { });
    },
    readNotification(item) {
      const index = this.notifications.indexOf(item);
      this.notifications.splice(index, 1);
      apiCall({ url: "/api/notificationRead/" + item.id, method: "GET" })
        .then((resp) => {
          this.fetchNotifications();
        })
        .catch((error) => {
          console.log(error.response);
        });
      if (item.type == "App\\Notifications\\FriendRequest") {
        this.$router.push("/connections");
      } else if (item.type == "App\\Notifications\\EventBroadcast") {
        this.$router.push("/events/"+item.data.event.slug);
      } else if (item.type == "App\\Notifications\\CallBroadcast") {
        this.$router.push("/call-for-proposals/"+item.data.call.slug);
      } else if (item.type == "App\\Notifications\\InnovationAdminNotif") {
        this.$router.push("/administration-requests");
      } else if (item.type == "App\\Notifications\\ContactUsMessage") {
        this.$router.push("/contact-us-admin");
      } else if (item.type == "App\\Notifications\\NewInnovation") {
        this.assignInnovationData(item.data.innovation)
        this.changeInnovationApprovalCardState();
      } else if (item.type == "App\\Notifications\\RejectInnovation") {
        this.$router.push("/innovations-admin");
      } else if (item.type == "App\\Notifications\\ApproveInnovation") {
        this.$router.push("/innovations-admin");
      } else if (item.type == "App\\Notifications\\VerifyInnovation") {
        this.$router.push("/innovations-admin");
      } else if (item.type == "App\\Notifications\\UnverifyInnovation") {
        this.$router.push("/innovations-admin");
      } else if (item.type == "App\\Notifications\\NewInstitutionUser") {
        this.$router.push("/institutions-admin");
      } else if (item.type == "App\\Notifications\\InstitutionUserApproved") {
        this.$router.push("/institutions/" + item.data.institution.slug);
      } else if (item.type == "App\\Notifications\\NewInstitutionInnovation") {
        this.$router.push("/institutions/" + item.data.institution.slug);
      } else if (item.type == "App\\Notifications\\InstitutionInnovationApproved") {
        this.$router.push("/institutions/" + item.data.institution.slug);
      } else if (item.type == "App\\Notifications\\IndividualInvestmentOffer") {
        this.$router.push("/innovations-admin");
      }  else if (item.type == "App\\Notifications\\FirmInvestmentOffer") {
        this.$router.push("/innovations-admin");
      } else if (item.type == "App\\Notifications\\IndividualOfferRejected") {
        this.$router.push("/investments" + item.data.data.innovation.slug);
      } else if (item.type == "App\\Notifications\\IndividualOfferAccepted") {
        this.$router.push("/innovations/" + item.data.data.innovation.slug);
      } else if (item.type == "App\\Notifications\\InnovationAdminAccepted") {
        this.$router.push("/innovations/" + item.data.data.innovation.slug);
      } else if (item.type == "App\\Notifications\\FirmOfferAccepted") {
        this.$router.push("/innovations/" + item.data.data.innovation.slug);
      } else if (item.type == "App\\Notifications\\FirmOfferRejected") {
        this.$router.push("/innovations/" + item.data.data.innovation.slug);
      } else if (item.type == "App\\Notifications\\BroadcastMessage") {
        this.$router.push("/broadcasts");
      }
    },
    handledarkmode() {
      if (this.darkState == true) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    },
    changeLocale() {
      // i18n.locale = this.languageChoice;
      this.changeLanguage(this.languageChoice);
    },
    search() {
      if (this.$route.name != "Search") {
        this.$router.push("/search");
      }
      this.startSearchInnovationLoader();
      apiCall({
        url:
          "/api/innovation?type=search&page=1&term=" +
          this.searchInnovationPagination.search,
        method: "GET",
      })
        .then((resp) => {
          this.filterSearchInnovations(resp);
          // this.filterInterestInnovations(resp);
          this.stopSearchInnovationLoader();
        })
        .catch((error) => {
          console.log(error.response);
          this.stopSearchInnovationLoader();
          this.message = "An Error Occurred";
          this.color = "error";
          this.snackbar = true;
        });

      this.startInstitutionStatisticLoader();
      apiCall({
        url:
          "/api/institution-statistic?type=search&page=1&term=" +
          this.searchInnovationPagination.search,
        method: "GET",
      })
        .then((resp) => {
          this.filterSearchInstitutionStatistics(resp);
          this.stopInstitutionStatisticLoader();
        })
        .catch((error) => {
          console.log(error.response);
          this.stopInstitutionStatisticLoader();
          this.message = "An Error Occurred";
          this.color = "error";
          this.snackbar = true;
        });

      this.startOrganisationStatisticLoader();
      apiCall({
        url:
          "/api/organisation-statistic?type=search&page=1&term=" +
          this.searchInnovationPagination.search,
        method: "GET",
      })
        .then((resp) => {
          this.filterSearchOrganisationStatistics(resp);
          this.stopOrganisationStatisticLoader();
        })
        .catch((error) => {
          console.log(error.response);
          this.stopOrganisationStatisticLoader();
          this.message = "An Error Occurred";
          this.color = "error";
          this.snackbar = true;
        });

      this.startInvestmentFirmLoader();
      apiCall({
        url:
          "/api/investment-firm?type=filter&page=1",
        data: this.searchInnovationPagination,
        method: "POST",
      })
        .then((resp) => {
          this.filterSearchInvestmentFirms(resp);
          this.stopInvestmentFirmLoader();
        })
        .catch((error) => {
          console.log(error.response);
          this.stopInvestmentFirmLoader();
          this.message = "An Error Occurred";
          this.color = "error";
          this.snackbar = true;
        });

      this.startUserLoader();
      apiCall({
        url:
          "/api/user?type=publicSearch&page=1&term=" +
          this.searchInnovationPagination.search,
        method: "GET",
      })
        .then((resp) => {
          this.filterSearchUsers(resp);
          this.stopUserLoader();
        })
        .catch((error) => {
          console.log(error.response);
          this.stopUserLoader();
          this.message = "An Error Occurred";
          this.color = "error";
          this.snackbar = true;
        });
    },
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "darkState",
      "language",
      "isAuthenticated",
      "searchInnovationPagination",
      "institutionPagination",
      "notifications",
      "unreadMessages"
    ]),
  },
};
</script>
