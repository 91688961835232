<template>
  <div class="institutions" v-if="$can('institution_view')">
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="imageDialog"
      >
        <v-card max-width="600">
          <v-toolbar flat>
            Upload Logo
            <v-spacer></v-spacer>
            <v-btn icon @click="imageDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
  
          <v-form ref="imageForm" v-model="imageValid" lazy-validation>
            <v-layout column>
              <v-flex xs12>
                <v-file-input
                  class="mx-2 mt-3"
                  :loading="imageLoading"
                  :rules="fileRules"
                  filled
                  dense
                  show-size
                  chips
                  accept="image/*"
                  v-model="imageFile"
                  label="Select Image"
                ></v-file-input>
              </v-flex>
              <v-flex xs12>
                <div class="mx-3 mb-5">
                  <v-btn
                    depressed
                    block
                    class="primary text-none"
                    @click="uploadImage"
                    :loading="imageLoading"
                    :disabled="!imageValid"
                  >
                    Submit <v-icon right dark>mdi-upload</v-icon>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="coverImageDialog"
      >
        <v-card max-width="600">
          <v-toolbar flat>
            Upload Cover Photo
            <v-spacer></v-spacer>
            <v-btn icon @click="coverImageDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
  
          <v-form ref="imageForm" v-model="imageValid" lazy-validation>
            <v-layout column>
              <v-flex xs12>
                <v-file-input
                  class="mx-2 mt-3"
                  :loading="imageLoading"
                  :rules="fileRules"
                  filled
                  dense
                  show-size
                  chips
                  accept="image/*"
                  v-model="imageFile"
                  label="Select Image"
                ></v-file-input>
              </v-flex>
              <v-flex xs12>
                <div class="mx-3 mb-5">
                  <v-btn
                    depressed
                    block
                    class="primary text-none"
                    @click="uploadCoverImage"
                    :loading="imageLoading"
                    :disabled="!imageValid"
                  >
                    Submit <v-icon right dark>mdi-upload</v-icon>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card>
      </v-dialog>
    <v-container>
      <v-layout column>
        <div v-if="view == 'default'">
          <v-card outlined class="mt-5">
            <v-progress-linear
              v-if="institutionLoader"
              height="1"
              indeterminate
              color="primary"
            >
            </v-progress-linear>
            <v-flex xs12 class="mt-10 mb-2">
              <div class="mx-5">
                <v-layout row wrap>
                  <v-flex xs12 md4> <h1>Institutions</h1> </v-flex>
                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-text-field
                        class="text_field header"
                        outlined
                        dense
                        label="Search"
                        append-icon="mdi-undo-variant"
                        @click:append="resetSearch()"
                        v-on:keyup.enter="search"
                        v-model="searchTerm"
                        @input="enableSearch()"
                      ></v-text-field>
                    </div>
                  </v-flex>
                  <v-flex xs12 md2>
                    <div class="pa-1">
                      <v-btn
                        class="secondary primary--text text-none"
                        depressed
                        block
                        @click="search"
                        :loading="searchLoader"
                        :disabled="searchButtonDisabled"
                      >
                        Search
                        <v-icon right>mdi-magnify</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs12 md2>
                    <div class="pa-1">
                      <v-btn
                        block
                        depressed
                        class="primary text-none"
                        @click="changeView('create')"
                        v-if="$can('institution_create')"
                      >
                        Add Institution
                        <v-icon right>mdi-plus-circle-outline</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
            <v-divider class="mt-9 mx-5"></v-divider>
            <v-flex xs12 class="mt-5">
              <div v-if="institutions.length == 0">
                <v-card elevation="0">
                  <v-layout row wrap>
                    <v-flex xs12 md1>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center">
                            <v-icon large class="primary--text">
                              mdi-alert-circle-outline
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                    <v-flex xs12 md11>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center">
                            No Institution Found
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                  </v-layout>
                </v-card>
              </div>
              <div v-else>
                <div class="hidden-sm-and-down">
                  <v-card elevation="0" class="pa-2">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead class="header">
                          <tr class="tableHeader">
                            <th class="text-left text--text">Logo</th>
                            <th class="text-left text--text">Name</th>
                            <th class="text-left text--text">County</th>
                            <th class="text-left text--text">Status</th>
                            <th class="text-left text--text">Date Created</th>
                            <th class="text-right text--text">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in institutions" :key="item.id">
                            <td>
                              <div class="my-2">
                                <v-avatar size="48" v-if="item.logo==null">
                                  <v-img
                                    max-height="150"
                                    
                                    src="img/logo_fav.png"
                                  >
                                  </v-img>
                                </v-avatar>
                                <v-avatar size="48" v-else>
                                  <v-img
                                      max-height="150"
                                      :src="path+'/storage/institution_pictures/'+item.logo"
                                    >
                                    </v-img>
                                </v-avatar>
                              </div>
                            </td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.county.name }}</td>
                            <td>
                              <div v-if="item.status == 1">Active</div>
                              <div v-else>Inactive</div>
                            </td>
                            <td>
                              {{
                                item.created_at | moment("DD/MM/YYYY - hh:mm a")
                              }}
                            </td>
                            <td>
                              <div align="right">
                                <v-btn
                                  icon
                                  v-if="$can('institution_view')"
                                  @click="showInstitution(item)"
                                  class="button mr-1 universal--text"
                                >
                                  <v-icon small> mdi-eye </v-icon>
                                </v-btn>
                                <v-btn
                                  v-if="$can('institution_edit')"
                                  icon
                                  class="button mr-1 success--text"
                                  @click="editInstitution(item)"
                                >
                                  <v-icon small> mdi-pencil </v-icon>
                                </v-btn>
                                <v-btn
                                  v-if="$can('institution_archive')"
                                  icon
                                  class="button mr-1 error--text"
                                  @click="deleteInstitution(item)"
                                  :loading="
                                    loading && institutionIndex == item.id
                                  "
                                >
                                  <v-icon small> mdi-delete </v-icon>
                                </v-btn>
                                <v-tooltip
                                  top
                                  v-if="
                                    $can('institution_activate') &&
                                    item.status == 0
                                  "
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      class="button mr-1 red--text"
                                      @click="activateInstitution(item)"
                                      :loading="
                                        activateLoading &&
                                        institutionIndex == item.id
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small>
                                        mdi-toggle-switch-off-outline
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Activate Institution</span>
                                </v-tooltip>
                                <v-tooltip
                                  top
                                  v-if="
                                    $can('institution_deactivate') &&
                                    item.status == 1
                                  "
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      class="button mr-1 green--text"
                                      @click="deactivateInstitution(item)"
                                      :loading="
                                        deactivateLoading &&
                                        institutionIndex == item.id
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small> mdi-toggle-switch </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Deactivate Institution</span>
                                </v-tooltip>
                                <v-tooltip top v-if="$can('log_view')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      class="button mr-1 purple--text"
                                      @click="viewLog(item)"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small> mdi-script-text </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Log</span>
                                </v-tooltip>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </div>
                <div class="hidden-md-and-up">
                  <div>
                    <v-layout column>
                      <template v-for="(institution, index) in institutions">
                        <div :key="index">
                          <v-flex xs12 class="mb-2">
                            <v-card elevation="0">
                              <div class="pa-5">
                                <v-layout column>
                                  <v-flex xs12>
                                    <v-layout row wrap>
                                      <v-flex xs11>
                                        <div class="title text--text">
                                          <b>{{ institution.name }}</b>
                                        </div>
                                      </v-flex>
                                      <v-flex xs1>
                                        <v-btn
                                          depressed
                                          class="red--text"
                                          :loading="
                                            loading &&
                                            institutionIndex == institution.id
                                          "
                                          icon
                                          @click="
                                            deleteInstitution(institution)
                                          "
                                          v-if="$can('institution_archive')"
                                        >
                                          <v-icon> mdi-delete </v-icon>
                                        </v-btn>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-divider class="mt-2"></v-divider>
                                  <v-flex xs12 class="mt-1">
                                    <v-layout column>
                                      <v-flex xs12 class="mt-5">
                                        <v-layout row wrap>
                                          <v-flex xs4>
                                            <b>County</b>
                                          </v-flex>
                                          <v-flex xs8>
                                            {{ institution.county.name }}
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-5">
                                        <v-layout row wrap>
                                          <v-flex xs4>
                                            <b>Status</b>
                                          </v-flex>
                                          <v-flex xs8>
                                            <div v-if="institution.status == 1">Active</div>
                                            <div v-else>Inactive</div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-5">
                                        <v-layout row wrap>
                                          <v-flex xs4>
                                            <b>Date Created</b>
                                          </v-flex>
                                          <v-flex xs8>
                                            {{
                                              institution.created_at
                                                | moment("DD/MM/YYYY - hh:mm a")
                                            }}
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-5">
                                        <v-layout row wrap>
                                          <v-flex xs12>
                                            <div>
                                              <v-btn
                                                depressed
                                                class="primary text-none white--text mt-3"
                                                block
                                                @click="
                                                  showInstitution(institution)
                                                "
                                                v-if="$can('institution_view')"
                                                >View
                                                <v-icon right> mdi-eye </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div>
                                              <v-btn
                                                depressed
                                                class="blue text-none white--text mt-1"
                                                block
                                                @click="
                                                  editInstitution(institution)
                                                "
                                                v-if="$can('institution_edit')"
                                                >Edit
                                                <v-icon right>
                                                  mdi-border-color
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div>
                                              <v-btn
                                                depressed
                                                class="green text-none white--text mt-1"
                                                block
                                                @click="
                                                  activateInstitution(institution)
                                                "
                                                :loading="
                                                  activateLoading &&
                                                  institutionIndex == institution.id
                                                "
                                                v-if="
                                                  $can('institution_activate') &&
                                                  institution.status == 0
                                                "
                                                >Activate Institution
                                                <v-icon right>
                                                  mdi-toggle-switch-off-outline
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div>
                                              <v-btn
                                                depressed
                                                class="red text-none white--text mt-1"
                                                block
                                                @click="
                                                  deactivateInstitution(institution)
                                                "
                                                :loading="
                                                  deactivateLoading &&
                                                  institutionIndex == institution.id
                                                "
                                                v-if="
                                                  $can('institution_deactivate') &&
                                                  institution.status == 1
                                                "
                                                >Deactivate Institution
                                                <v-icon right>
                                                  mdi-toggle-switch
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="mt-1">
                                              <v-btn
                                                v-if="$can('log_view')" 
                                                block
                                                depressed
                                                class="purple text-none white--text"
                                                @click="viewLog(institution)"
                                                v-bind="attrs"
                                                v-on="on"
                                              > Log
                                                <v-icon small> mdi-script-text </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </v-card>
                          </v-flex>
                        </div>
                      </template>
                    </v-layout>
                  </div>
                </div>
              </div>
            </v-flex>
          </v-card>
          <v-layout column class="mt-5">
            <v-flex xs12>
              <div align="left">
                <v-pagination
                  v-if="length != 0"
                  :length="length"
                  total-visible="10"
                  v-model="institutionPagination.current_page"
                  @input="changePage()"
                  circle
                >
                </v-pagination>
              </div>
            </v-flex>
            <v-flex xs12>
              <div v-if="length != 0" align="center" class="mt-5">
                <b class="primary--text">Total: </b
                >{{ institutionPagination.total | formatNumber }}
              </div>
            </v-flex>
          </v-layout>
        </div>
        <div v-if="view == 'create'">
          <v-flex class="mt-5">
            <v-card outlined>
              <v-card-title class="tableHeader text--text">
                <v-img
                  v-if="newInstitution.id != null"
                  src="img/badge.png"
                  max-width="40"
                ></v-img>
                <v-spacer></v-spacer>
                <div v-if="newInstitution.id == null">Add Institution</div>
                <div v-else>Edit Institution</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeView('default')">
                  <v-icon class="text--text"> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-layout row wrap class="mt-5">
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>Name<span class="error--text">*</span></div>
                        <v-text-field
                          class="text_field background my-2"
                          outlined
                          dense
                          v-model="newInstitution.name"
                          :rules="inputRules"
                        ></v-text-field>
                      </div>
                    </v-flex>

                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>
                          Institution Type<span class="error--text">*</span>
                        </div>
                        <v-select
                          :items="formData.institution_types"
                          v-model="newInstitution.institution_type_id"
                          class="text_field background my-2"
                          item-value="id"
                          item-text="name"
                          outlined
                          dense
                          :rules="inputRules"
                        ></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-3">
                        <div>County<span class="error--text">*</span></div>
                        <v-select
                          :items="formData.counties"
                          v-model="newInstitution.county_id"
                          class="text_field background my-2"
                          item-value="id"
                          item-text="name"
                          outlined
                          dense
                          :rules="inputRules"
                        ></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <div class="pa-3">
                        <div>Brief<span class="error--text">*</span></div>
                        <v-text-field
                          class="text_field background my-2"
                          outlined
                          dense
                          v-model="newInstitution.brief"
                          :rules="inputRules"
                        ></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <div class="pa-3">
                        <div>Description<span class="error--text">*</span></div>
                        <div class="black--text">
                          <ckeditor
                            :editor="editor"
                            v-model="newInstitution.description"
                            :config="editorConfig"
                            :rules="inputRules"
                          ></ckeditor>
                        </div>
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <div class="pa-3">
                        <div>
                          Physical Address<span class="error--text">*</span>
                        </div>
                        <v-text-field
                          class="text_field background my-2"
                          outlined
                          dense
                          v-model="newInstitution.physical_address"
                          :rules="inputRules"
                        ></v-text-field>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  class="primary text-none mt-3"
                  @click="save"
                  :disabled="!valid"
                  :loading="loading"
                >
                  Save
                  <v-icon right> mdi-content-save </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </div>
        <div v-if="view == 'show'">
          <v-card outlined class="mt-5">
            <v-progress-linear
              v-if="childInstitutionAffiliationLoader"
              height="1"
              indeterminate
              color="primary"
            >
            </v-progress-linear>
            <v-progress-linear
              v-if="institutionInnovationLoader"
              height="1"
              indeterminate
              color="primary"
            >
            </v-progress-linear>
            <v-card-title class="tableHeader">
              <v-spacer></v-spacer>
              <div class="text--text">View Institution</div>
              <v-spacer></v-spacer>
              <v-btn icon @click="changeView('default')">
                <v-icon class="text--text"> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div class="pa-1">
                <v-tabs show-arrows v-model="institutionTab">
                  <v-tabs-slider color="primary"></v-tabs-slider>

                  <v-tab @click="activeTab = 'details'">
                    <div class="text-none text--text">Details</div>
                  </v-tab>
                  <v-tab
                    @click="activeTab = 'institutionInnovations'"
                    v-if="$can('institution_innovation_view')"
                  >
                    <div class="text-none text--text">
                      Innovations ({{ institutionInnovationPagination.total }})
                    </div>
                  </v-tab>
                  <v-tab
                    @click="activeTab = 'institutionUsers'"
                    v-if="$can('institution_user_view')"
                  >
                    <div class="text-none text--text">
                      Users ({{ institutionUserPagination.total }})
                    </div>
                  </v-tab>
                  <v-tab
                    @click="activeTab = 'institutionAdministrators'"
                    v-if="$can('institution_administrator_view')"
                  >
                    <div class="text-none text--text">
                      Administrators ({{
                        institutionAdministratorPagination.total
                      }})
                    </div>
                  </v-tab>
                  <v-tab
                    @click="activeTab = 'institutionAffiliations'"
                    v-if="$can('institution_affiliation_view')"
                  >
                    <div class="text-none text--text">Institution Affiliations</div>
                  </v-tab>
                  <v-tab
                    @click="activeTab = 'organisationAffiliations'"
                    v-if="$can('organisation_affiliation_view')"
                  >
                    <div class="text-none text--text">Organisation Affiliations</div>
                  </v-tab>
                </v-tabs>
                <div v-if="activeTab == 'details'">
                  <v-card-text>
                    <div class="pa-3">
                      <v-layout row wrap>
                        <v-flex xs12 md3>
                          <div class="mr-8 mt-15">
                            <v-img v-if="assignedInstitution.cover_image == null" src="img/logo_primary.png" height="200px" style="z-index: 2;border-radius: 25px;">
                              <div align="right" class="mt-2 mr-2">
                                <v-btn
                                  class="white"
                                  icon
                                  small
                                  @click="coverImageDialog = true"
                                  v-if="$can('institution_image_upload')"
                                  ><v-icon class="primary--text" small>mdi-pen</v-icon></v-btn
                                >
                              </div>
                              
                              <v-avatar
                                size="86"
                                style="position: absolute; top: 110px; left: 3px; z-index: 10"
                              >
                                <v-img
                                  v-if="assignedInstitution.logo==null"
                                  src="img/logo_fav.png"
                                >
                                </v-img>
                                <v-img
                                  v-else
                                  :src="path+'/storage/institution_pictures/'+assignedInstitution.logo"
                                >
                                </v-img>
                              </v-avatar>
                            </v-img>
                            <v-img v-else :src="path+'/storage/institution_pictures/'+assignedInstitution.cover_image" height="200px" style="z-index: 2;border-radius: 25px;">
                              <div align="right" class="mt-2 mr-2">
                                <v-btn
                                  class="white"
                                  icon
                                  small
                                  @click="coverImageDialog = true"
                                  v-if="$can('institution_image_upload')"
                                  ><v-icon class="primary--text" small>mdi-pen</v-icon></v-btn
                                >
                              </div>
                              
                              <v-avatar
                                size="86"
                                style="position: absolute; top: 110px; left: 3px; z-index: 10"
                              >
                                <v-img
                                  v-if="assignedInstitution.logo==null"
                                  src="img/logo_fav.png"
                                >
                                </v-img>
                                <v-img
                                  v-else
                                  :src="path+'/storage/institution_pictures/'+assignedInstitution.logo"
                                >
                                </v-img>
                              </v-avatar>
                            </v-img>
                            
                          </div>
                          <div align="center" class="mt-5">
                            <v-btn class="primary text-none" depressed @click="imageDialog = true" v-if="$can('institution_image_upload')">
                              Upload Logo
                              <v-icon right>mdi-file-upload</v-icon>
                            </v-btn>
                          </div>
                          
                        </v-flex>
                        <v-flex xs12 md9>
                          <v-layout column class="mt-3">
                        <v-flex xs12>
                          <v-layout row wrap>
                            <v-flex xs12 md6>
                              <v-layout column>
                                <v-flex xs12 class="mt-6">
                                  <v-layout row wrap>
                                    <v-flex xs12 md4>
                                      <b>Institution Name: </b>
                                    </v-flex>
                                    <v-flex xs12 md8>
                                      <div>{{ assignedInstitution.name }}</div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-6">
                                  <v-layout row wrap>
                                    <v-flex xs12 md4>
                                      <b>County: </b>
                                    </v-flex>
                                    <v-flex xs12 md8>
                                      <div>
                                        {{ assignedInstitution.county.name }}
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 md6>
                              <v-layout column>
                                <v-flex xs12 class="mt-6">
                                  <v-layout row wrap>
                                    <v-flex xs12 md4>
                                      <b>Status: </b>
                                    </v-flex>
                                    <v-flex xs12 md8>
                                      <div
                                        v-if="assignedInstitution.status == 1"
                                      >
                                        Active
                                      </div>
                                      <div v-else>Inactive</div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-6">
                                  <v-layout row wrap>
                                    <v-flex xs12 md4>
                                      <b>Date Created: </b>
                                    </v-flex>
                                    <v-flex xs12 md8>
                                      <div>
                                        {{
                                          assignedInstitution.created_at
                                            | moment("DD/MM/YYYY - hh:mm a")
                                        }}
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                        </v-flex>
                        </v-layout>



                      
                    </div>
                  </v-card-text>
                  <v-card-title class="tableHeader text--text mt-5">
                    <v-spacer></v-spacer>
                    <div>Institution Contact Details</div>
                    <v-spacer></v-spacer>
                    <v-btn
                      icon
                      @click="changeContactView('create')"
                      v-if="
                        $can('institution_contact_create') &&
                        contactView == 'default' &&
                        assignedInstitution.institution_contact == null
                      "
                    >
                      <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn>

                    <v-btn
                      icon
                      @click="changeContactView('default')"
                      v-if="contactView != 'default'"
                    >
                      <v-icon> mdi-close </v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <div v-if="contactView == 'default'">
                      <div class="pa-3 mt-5">
                        <div
                          v-if="assignedInstitution.institution_contact == null"
                        >
                          <v-layout row wrap>
                            <v-flex xs12 md1>
                              <v-container fill-height fluid>
                                <v-row align="center" justify="center">
                                  <v-col class="text-center">
                                    <v-icon large class="primary--text">
                                      mdi-alert-circle-outline
                                    </v-icon>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md11>
                              <v-container fill-height fluid>
                                <v-row align="center" justify="center">
                                  <v-col class="text-center">
                                    Contacts Not Added
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-flex>
                          </v-layout>
                        </div>
                        <div v-else>
                          <v-layout column>
                            <v-flex xs12>
                              <div class="ml-3">
                                <v-layout row wrap>
                                  <v-flex xs11>
                                    <v-layout row wrap>
                                      <v-flex xs12 md6>
                                        <v-layout column>
                                          <v-flex xs12 class="mt-6">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Contact Person: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div
                                                  v-if="
                                                    assignedInstitution
                                                      .institution_contact
                                                      .contact_person != null
                                                  "
                                                >
                                                  {{
                                                    assignedInstitution
                                                      .institution_contact
                                                      .contact_person
                                                  }}
                                                </div>
                                                <div v-else>N/A</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-6">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Primary Email Address: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div
                                                  v-if="
                                                    assignedInstitution
                                                      .institution_contact
                                                      .email != null
                                                  "
                                                >
                                                  {{
                                                    assignedInstitution
                                                      .institution_contact.email
                                                  }}
                                                </div>
                                                <div v-else>N/A</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-6">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Secondary Email Address: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div
                                                  v-if="
                                                    assignedInstitution
                                                      .institution_contact
                                                      .email_2 != null
                                                  "
                                                >
                                                  {{
                                                    assignedInstitution
                                                      .institution_contact
                                                      .email_2
                                                  }}
                                                </div>
                                                <div v-else>N/A</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-6">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Website URL: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div
                                                  v-if="
                                                    assignedInstitution
                                                      .institution_contact
                                                      .web_url != null
                                                  "
                                                >
                                                  <a
                                                    :href="
                                                      assignedInstitution
                                                        .institution_contact
                                                        .web_url
                                                    "
                                                    target="_blank"
                                                    style="
                                                      text-decoration: none;
                                                    "
                                                    >{{
                                                      assignedInstitution
                                                        .institution_contact
                                                        .web_url
                                                    }}</a
                                                  >
                                                </div>
                                                <div v-else>N/A</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-6">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Twitter URL: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div
                                                  v-if="
                                                    assignedInstitution
                                                      .institution_contact
                                                      .twitter_url != null
                                                  "
                                                >
                                                  <a
                                                    :href="
                                                      assignedInstitution
                                                        .institution_contact
                                                        .twitter_url
                                                    "
                                                    target="_blank"
                                                    style="
                                                      text-decoration: none;
                                                    "
                                                    >{{
                                                      assignedInstitution
                                                        .institution_contact
                                                        .twitter_url
                                                    }}</a
                                                  >
                                                </div>
                                                <div v-else>N/A</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 md6>
                                        <v-layout column>
                                          <v-flex xs12 class="mt-6">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Primary Phone #: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div
                                                  v-if="
                                                    assignedInstitution
                                                      .institution_contact
                                                      .phone != null
                                                  "
                                                >
                                                  {{
                                                    assignedInstitution
                                                      .institution_contact.phone
                                                  }}
                                                </div>
                                                <div v-else>N/A</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-6">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Secondary Phone #: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div
                                                  v-if="
                                                    assignedInstitution
                                                      .institution_contact
                                                      .phone_2 != null
                                                  "
                                                >
                                                  {{
                                                    assignedInstitution
                                                      .institution_contact
                                                      .phone_2
                                                  }}
                                                </div>
                                                <div v-else>N/A</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-6">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Facebook URL: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div
                                                  v-if="
                                                    assignedInstitution
                                                      .institution_contact
                                                      .fb_url != null
                                                  "
                                                >
                                                  <a
                                                    :href="
                                                      assignedInstitution
                                                        .institution_contact
                                                        .fb_url
                                                    "
                                                    target="_blank"
                                                    style="
                                                      text-decoration: none;
                                                    "
                                                    >{{
                                                      assignedInstitution
                                                        .institution_contact
                                                        .fb_url
                                                    }}</a
                                                  >
                                                </div>
                                                <div v-else>N/A</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-6">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Instagram URL: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div
                                                  v-if="
                                                    assignedInstitution
                                                      .institution_contact
                                                      .insta_url != null
                                                  "
                                                >
                                                  <a
                                                    :href="
                                                      assignedInstitution
                                                        .institution_contact
                                                        .insta_url
                                                    "
                                                    target="_blank"
                                                    style="
                                                      text-decoration: none;
                                                    "
                                                    >{{
                                                      assignedInstitution
                                                        .institution_contact
                                                        .insta_url
                                                    }}</a
                                                  >
                                                </div>
                                                <div v-else>N/A</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-6">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Visibility: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div
                                                  v-if="
                                                    assignedInstitution
                                                      .institution_contact
                                                      .visible == 1
                                                  "
                                                >
                                                  Public
                                                </div>
                                                <div v-else>Hidden</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs1>
                                    <v-btn
                                      icon
                                      @click="
                                        editContact(
                                          assignedInstitution.institution_contact
                                        )
                                      "
                                      v-if="$can('institution_contact_edit')"
                                    >
                                      <v-icon small class="green--text"
                                        >mdi-pencil</v-icon
                                      >
                                    </v-btn>
                                    <v-btn
                                      icon
                                      @click="
                                        deleteContact(
                                          assignedInstitution.institution_contact
                                        )
                                      "
                                      v-if="$can('institution_contact_archive')"
                                      :loading="loading"
                                    >
                                      <v-icon small class="red--text"
                                        >mdi-delete</v-icon
                                      >
                                    </v-btn>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </v-flex>
                          </v-layout>
                        </div>
                      </div>
                    </div>
                    <div v-if="contactView == 'create'" class="mt-5">
                      <v-form v-model="valid" ref="contactForm" lazy-validation>
                        <v-layout row wrap>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Contact Person</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newContact.contact_person"
                              >
                              </v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Primary Email</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newContact.email"
                                :rules="emailRules"
                              ></v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Secondary Email</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newContact.email_2"
                                :rules="emailRules"
                              ></v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Primary Phone Number</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newContact.phone"
                              ></v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Secondary Phone Number</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newContact.phone_2"
                              ></v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Website URL</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newContact.web_url"
                                :rules="websiteRules"
                              ></v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Facebook Page URL</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newContact.fb_url"
                              ></v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Instagram Account URL</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newContact.insta_url"
                              ></v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Twitter Account URL</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newContact.twitter_url"
                              ></v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="mt-4">
                              <v-checkbox
                                class="text_field"
                                outlined
                                dense
                                v-model="newContact.visible"
                                label="Visible"
                              ></v-checkbox>
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div align="right">
                              <v-btn
                                depressed
                                class="primary text-none mt-3"
                                @click="saveContact"
                                :disabled="!valid"
                                :loading="loading"
                              >
                                Save
                                <v-icon right> mdi-content-save </v-icon>
                              </v-btn>
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-form>
                    </div>
                  </v-card-text>
                </div>
                <div v-if="activeTab == 'institutionAffiliations'">
                  <v-tabs show-arrows v-model="institutionAffTab">
                    <v-tabs-slider color="primary"></v-tabs-slider>

                    <v-tab @click="activeInstitutionTab = 'parent'">
                      <div class="text--text text-none">
                        Parent Institutions ({{
                          parentInstitutionAffiliationPagination.total
                        }})
                      </div>
                    </v-tab>
                    <v-tab @click="activeInstitutionTab = 'child'">
                      <div class="text--text text-none">
                        Child Institutions ({{
                          childInstitutionAffiliationPagination.total
                        }})
                      </div>
                    </v-tab>
                  </v-tabs>

                  <div v-if="activeInstitutionTab == 'child'">
                    <div v-if="childInstitutionAffiliationView == 'default'">
                      <v-card elevation="0" class="mt-5">
                        <v-flex xs12 class="mt-10 mb-2">
                          <div class="mx-5">
                            <v-layout row wrap>
                              <v-flex xs12 md4> </v-flex>
                              <v-flex xs12 md4>
                                <div class="pa-1">
                                  <v-text-field
                                    class="text_field header"
                                    outlined
                                    dense
                                    label="Search"
                                    append-icon="mdi-undo-variant"
                                    @click:append="
                                      resetChildInstitutionAffiliationSearch()
                                    "
                                    v-on:keyup.enter="
                                      childInstitutionAffiliationSearch
                                    "
                                    v-model="
                                      childInstitutionAffiliationSearchTerm
                                    "
                                    @input="
                                      enableChildInstitutionAffiliationSearch()
                                    "
                                  ></v-text-field>
                                </div>
                              </v-flex>
                              <v-flex xs12 md2>
                                <div class="pa-1">
                                  <v-btn
                                    class="secondary primary--text text-none"
                                    depressed
                                    block
                                    @click="childInstitutionAffiliationSearch"
                                    :loading="
                                      childInstitutionAffiliationSearchLoader
                                    "
                                    :disabled="
                                      childInstitutionAffiliationSearchButtonDisabled
                                    "
                                  >
                                    Search
                                    <v-icon right>mdi-magnify</v-icon>
                                  </v-btn>
                                </div>
                              </v-flex>
                              <v-flex xs12 md2>
                                <div class="pa-1">
                                  <v-btn
                                    block
                                    depressed
                                    class="primary text-none"
                                    @click="
                                      changeChildInstitutionAffiliationView(
                                        'create'
                                      )
                                    "
                                    v-if="
                                      $can('institution_affiliation_create')
                                    "
                                  >
                                    Add Affiliation
                                    <v-icon right
                                      >mdi-plus-circle-outline</v-icon
                                    >
                                  </v-btn>
                                </div>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-flex>
                        <v-divider class="mt-9 mx-5"></v-divider>
                        <v-flex xs12 class="mt-5">
                          <div v-if="childInstitutionAffiliations.length == 0">
                            <v-card elevation="0">
                              <v-layout row wrap>
                                <v-flex xs12 md1>
                                  <v-container fill-height fluid>
                                    <v-row align="center" justify="center">
                                      <v-col class="text-center">
                                        <v-icon large class="text--text">
                                          mdi-alert-circle-outline
                                        </v-icon>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-flex>
                                <v-flex xs12 md11>
                                  <v-container fill-height fluid>
                                    <v-row align="center" justify="center">
                                      <v-col class="text-center">
                                        No Child Institution Affiliations Found
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </div>
                          <div v-else>
                            <div class="hidden-sm-and-down">
                              <v-card elevation="0" class="pa-2">
                                <v-simple-table>
                                  <template v-slot:default>
                                    <thead class="header">
                                      <tr class="tableHeader">
                                        <th class="text-left text--text">Logo</th>
                                        <th class="text-left text--text">Name</th>
                                        <th class="text-left text--text">County</th>
                                        <th class="text-left text--text">Status</th>
                                        <th></th>
                                        <th class="text-right text--text">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="item in childInstitutionAffiliations"
                                        :key="item.id"
                                      >
                                        <td>
                                          <div class="my-2">
                                            <v-avatar size="48" v-if="item.affiliated_institution.logo==null">
                                                <v-img
                                                  max-height="150"
                                                  
                                                  src="img/logo_fav.png"
                                                >
                                                </v-img>
                                              </v-avatar>
                                              <v-avatar size="48" v-else>
                                                <v-img
                                                    max-height="150"
                                                    :src="path+'/storage/institution_pictures/'+item.affiliated_institution.logo"
                                                  >
                                                  </v-img>
                                              </v-avatar>
                                          </div>
                                        </td>
                                        <td>
                                          {{ item.affiliated_institution.name }}
                                        </td>
                                        <td>
                                          {{
                                            item.affiliated_institution.county
                                              .name
                                          }}
                                        </td>
                                        <td>
                                          <div v-if="item.status == 1">
                                            Active
                                          </div>
                                          <div v-else>Inactive</div>
                                        </td>
                                        <td>
                                          <div
                                            v-if="
                                              item.requesting_institution_id ==
                                              item.institution_id
                                            "
                                            class="green--text"
                                          >
                                            Sent
                                          </div>
                                          <div
                                            v-if="
                                              item.requesting_institution_id !=
                                              item.institution_id
                                            "
                                            class="red--text"
                                          >
                                            Received
                                          </div>
                                        </td>
                                        <td>
                                          <div align="right">
                                            <v-btn
                                              icon
                                              @click="
                                                showInstitutionAffiliation(
                                                  item.affiliated_institution
                                                )
                                              "
                                              class="button mr-1 universal--text"
                                            >
                                              <v-icon small> mdi-eye </v-icon>
                                            </v-btn>
                                            <v-btn
                                              v-if="
                                                $can(
                                                  'institution_affiliation_archive'
                                                )
                                              "
                                              icon
                                              class="button mr-1 error--text"
                                              @click="
                                                deleteInstitutionAffiliation(
                                                  item
                                                )
                                              "
                                              :loading="
                                                loading &&
                                                institutionAffiliationIndex ==
                                                  item.id
                                              "
                                            >
                                              <v-icon small>
                                                mdi-delete
                                              </v-icon>
                                            </v-btn>
                                            <v-tooltip
                                              top
                                              v-if="
                                                $can(
                                                  'institution_affiliation_activate'
                                                ) &&
                                                item.status == 0 &&
                                                item.requesting_institution_id !=
                                                  item.institution_id
                                              "
                                            >
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-btn
                                                  icon
                                                  class="button mr-1 red--text"
                                                  @click="
                                                    activateInstitutionAffiliation(
                                                      item
                                                    )
                                                  "
                                                  :loading="
                                                    activateLoading &&
                                                    institutionAffiliationIndex ==
                                                      item.id
                                                  "
                                                  v-bind="attrs"
                                                  v-on="on"
                                                >
                                                  <v-icon small>
                                                    mdi-toggle-switch-off-outline
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span>Approve Affiliation</span>
                                            </v-tooltip>
                                            <v-tooltip
                                              top
                                              v-if="
                                                $can(
                                                  'institution_affiliation_deactivate'
                                                ) &&
                                                item.status == 1 &&
                                                item.requesting_institution_id !=
                                                  item.institution_id
                                              "
                                            >
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-btn
                                                  icon
                                                  class="button mr-1 green--text"
                                                  @click="
                                                    deactivateInstitutionAffiliation(
                                                      item
                                                    )
                                                  "
                                                  :loading="
                                                    deactivateLoading &&
                                                    institutionAffiliationIndex ==
                                                      item.id
                                                  "
                                                  v-bind="attrs"
                                                  v-on="on"
                                                >
                                                  <v-icon small>
                                                    mdi-toggle-switch
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span>Reject Affiliation</span>
                                            </v-tooltip>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </v-card>
                            </div>
                            <div class="hidden-md-and-up">
                              <div>
                                <v-layout column>
                                  <template
                                    v-for="(
                                      institution, index
                                    ) in childInstitutionAffiliations"
                                  >
                                    <div :key="index">
                                      <v-flex xs12 class="mb-2">
                                        <v-card elevation="0">
                                          <div class="pa-2 mt-2">
                                            <v-layout column>
                                              <v-flex xs12>
                                                <v-layout row wrap>
                                                  <v-flex xs11>
                                                    <div
                                                      class="title text--text"
                                                    >
                                                      <b>{{
                                                        institution
                                                          .affiliated_institution
                                                          .name
                                                      }}</b>
                                                    </div>
                                                  </v-flex>
                                                  <v-flex xs1>
                                                    <v-btn
                                                      depressed
                                                      class="red--text"
                                                      :loading="
                                                        loading &&
                                                        institutionAffiliationIndex ==
                                                          institution.id
                                                      "
                                                      icon
                                                      @click="
                                                        deleteInstitutionAffiliation(
                                                          institution
                                                        )
                                                      "
                                                      v-if="
                                                        $can(
                                                          'institution_affiliation_archive'
                                                        )
                                                      "
                                                    >
                                                      <v-icon>
                                                        mdi-delete
                                                      </v-icon>
                                                    </v-btn>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                              <v-divider
                                                class="mt-2"
                                              ></v-divider>
                                              <v-flex xs12 class="mt-1">
                                                <v-layout column>
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs4>
                                                        <b>County</b>
                                                      </v-flex>
                                                      <v-flex xs8>
                                                        {{
                                                          institution
                                                            .affiliated_institution
                                                            .county.name
                                                        }}
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs4>
                                                        <b>Status</b>
                                                      </v-flex>
                                                      <v-flex xs8>
                                                        <div
                                                          v-if="
                                                            institution
                                                              .status == 1
                                                          "
                                                        >
                                                          Active
                                                        </div>
                                                        <div v-else>
                                                          Inactive
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs4>
                                                        <b>Request Type</b>
                                                      </v-flex>
                                                      <v-flex xs8>
                                                        <div
                                                          v-if="
                                                            institution.requesting_institution_id ==
                                                            institution.institution_id
                                                          "
                                                          class="green--text"
                                                        >
                                                          Sent
                                                        </div>
                                                        <div
                                                          v-if="
                                                            institution.requesting_institution_id !=
                                                            institution.institution_id
                                                          "
                                                          class="red--text"
                                                        >
                                                          Received
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>

                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs12>
                                                        <div>
                                                          <v-btn
                                                            depressed
                                                            class="primary text-none white--text mt-3"
                                                            block
                                                            @click="
                                                              showInstitutionAffiliation(
                                                                institution.affiliated_institution
                                                              )
                                                            "
                                                            >View
                                                            <v-icon right>
                                                              mdi-eye
                                                            </v-icon>
                                                          </v-btn>
                                                        </div>
                                                      </v-flex>
                                                      <v-flex xs12>
                                                        <div>
                                                          <v-btn
                                                            depressed
                                                            class="green darken-1 text-none white--text mt-1"
                                                            block
                                                            :loading="
                                                              activateLoading &&
                                                              institutionAffiliationIndex ==
                                                                institution.id
                                                            "
                                                            @click="
                                                              activateInstitutionAffiliation(
                                                                institution
                                                              ) 
                                                            "
                                                            v-if="
                                                              $can(
                                                                'institution_affiliation_activate'
                                                              ) &&
                                                              institution.status == 0 &&
                                                              institution.requesting_institution_id !=
                                                              institution.institution_id
                                                            "
                                                            >Approve Affiliation
                                                            <v-icon right>
                                                              mdi-toggle-switch
                                                            </v-icon>
                                                          </v-btn>
                                                        </div>
                                                      </v-flex>
                                                      <v-flex xs12>
                                                        <div>
                                                          <v-btn
                                                            depressed
                                                            class="red darken-1 text-none white--text mt-1"
                                                            block
                                                            :loading="
                                                              activateLoading &&
                                                              institutionAffiliationIndex ==
                                                                institution.id
                                                            "
                                                            @click="
                                                              deactivateInstitutionAffiliation(
                                                                institution
                                                              )
                                                            "
                                                            v-if="
                                                              $can(
                                                                'institution_affiliation_deactivate'
                                                              ) &&
                                                                institution.status == 1 &&
                                                                institution.requesting_institution_id !=
                                                                  institution.institution_id
                                                            "
                                                            >Reject Affiliation
                                                            <v-icon right>
                                                              mdi-toggle-switch-off-outline
                                                            </v-icon>
                                                          </v-btn>
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                            </v-layout>
                                          </div>
                                        </v-card>
                                      </v-flex>
                                    </div>
                                  </template>
                                </v-layout>
                              </div>
                            </div>
                          </div>
                        </v-flex>
                      </v-card>
                      <v-layout column class="mt-5">
                        <v-flex xs12>
                          <div align="left">
                            <v-pagination
                              v-if="length != 0"
                              :length="childInstitutionAffiliationLength"
                              total-visible="10"
                              v-model="
                                childInstitutionAffiliationPagination.current_page
                              "
                              @input="changeChildInstitutionAffiliationPage()"
                              circle
                            >
                            </v-pagination>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div
                            v-if="childInstitutionAffiliationLength != 0"
                            align="center"
                            class="mt-5"
                          >
                            <b class="text--text">Total: </b
                            >{{
                              childInstitutionAffiliationPagination.total
                                | formatNumber
                            }}
                          </div>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div v-if="childInstitutionAffiliationView == 'create'">
                      <v-flex class="mt-5">
                        <v-card-title class="tableHeader text--text">
                          <v-img
                            v-if="newInstitutionAffiliation.id != null"
                            src="img/badge.png"
                            max-width="40"
                          ></v-img>
                          <v-spacer></v-spacer>
                          <div v-if="newInstitutionAffiliation.id == null">
                            Add Institution Affiliation
                          </div>
                          <div v-else>Edit Institution Affiliation</div>
                          <v-spacer></v-spacer>
                          <v-btn
                            icon
                            @click="
                              changeChildInstitutionAffiliationView('default')
                            "
                          >
                            <v-icon class="text--text"> mdi-close </v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-form
                            v-model="valid"
                            ref="institutionAffiliationForm"
                            lazy-validation
                          >
                            <v-layout row wrap class="mt-5">
                              <v-flex xs12 md4>
                                <div class="pa-3">
                                  <div>
                                    Institution<span class="error--text"
                                      >*</span
                                    >
                                  </div>
                                  <v-select
                                    :items="formData.all_institutions"
                                    v-model="newInstitutionAffiliation.aff_institution_id"
                                    class="text_field background my-2"
                                    item-value="id"
                                    item-text="name"
                                    outlined
                                    dense
                                    :rules="inputRules"
                                  ></v-select>
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-form>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            depressed
                            class="primary text-none mt-3"
                            @click="saveChildAffliliatedInstitution"
                            :disabled="!valid"
                            :loading="loading"
                          >
                            Save
                            <v-icon right> mdi-content-save </v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-flex>
                    </div>
                  </div>

                  <div v-if="activeInstitutionTab == 'parent'">
                    <div v-if="parentInstitutionAffiliationView == 'default'">
                      <v-card elevation="0" class="mt-5">
                        <v-flex xs12 class="mt-10 mb-2">
                          <div class="mx-5">
                            <v-layout row wrap>
                              <v-flex xs12 md4> </v-flex>
                              <v-flex xs12 md4>
                                <div class="pa-1">
                                  <v-text-field
                                    class="text_field header"
                                    outlined
                                    dense
                                    label="Search"
                                    append-icon="mdi-undo-variant"
                                    @click:append="
                                      resetParentInstitutionAffiliationSearch()
                                    "
                                    v-on:keyup.enter="
                                      parentInstitutionAffiliationSearch
                                    "
                                    v-model="
                                      parentInstitutionAffiliationSearchTerm
                                    "
                                    @input="
                                      enableParentInstitutionAffiliationSearch()
                                    "
                                  ></v-text-field>
                                </div>
                              </v-flex>
                              <v-flex xs12 md2>
                                <div class="pa-1">
                                  <v-btn
                                    class="secondary primary--text text-none"
                                    depressed
                                    block
                                    @click="parentInstitutionAffiliationSearch"
                                    :loading="
                                      parentInstitutionAffiliationSearchLoader
                                    "
                                    :disabled="
                                      parentInstitutionAffiliationSearchButtonDisabled
                                    "
                                  >
                                    Search
                                    <v-icon right>mdi-magnify</v-icon>
                                  </v-btn>
                                </div>
                              </v-flex>
                              <v-flex xs12 md2>
                                <div class="pa-1">
                                  <v-btn
                                    block
                                    depressed
                                    class="primary text-none"
                                    @click="
                                      changeParentInstitutionAffiliationView(
                                        'create'
                                      )
                                    "
                                    v-if="
                                      $can('institution_affiliation_create')
                                    "
                                  >
                                    Add Affiliation
                                    <v-icon right
                                      >mdi-plus-circle-outline</v-icon
                                    >
                                  </v-btn>
                                </div>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-flex>
                        <v-divider class="mt-9 mx-5"></v-divider>
                        <v-flex xs12 class="mt-5">
                          <div v-if="parentInstitutionAffiliations.length == 0">
                            <v-card elevation="0">
                              <v-layout row wrap>
                                <v-flex xs12 md1>
                                  <v-container fill-height fluid>
                                    <v-row align="center" justify="center">
                                      <v-col class="text-center">
                                        <v-icon large class="text--text">
                                          mdi-alert-circle-outline
                                        </v-icon>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-flex>
                                <v-flex xs12 md11>
                                  <v-container fill-height fluid>
                                    <v-row align="center" justify="center">
                                      <v-col class="text-center">
                                        No Parent Institution Affiliations Found
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </div>
                          <div v-else>
                            <div class="hidden-sm-and-down">
                              <v-card elevation="0" class="pa-2">
                                <v-simple-table>
                                  <template v-slot:default>
                                    <thead class="header">
                                      <tr class="tableHeader">
                                        <th class="text-left text--text">Logo</th>
                                        <th class="text-left text--text">Name</th>
                                        <th class="text-left text--text">County</th>
                                        <th class="text-left text--text">Status</th>
                                        <th></th>
                                        <th class="text-right text--text">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="item in parentInstitutionAffiliations"
                                        :key="item.id"
                                      >
                                        <td>
                                          <div class="my-2">
                                            <v-avatar size="48" v-if="item.institution.logo==null">
                                                <v-img
                                                  max-height="150"
                                                  
                                                  src="img/logo_fav.png"
                                                >
                                                </v-img>
                                              </v-avatar>
                                              <v-avatar size="48" v-else>
                                                <v-img
                                                    max-height="150"
                                                    :src="path+'/storage/institution_pictures/'+item.institution.logo"
                                                  >
                                                  </v-img>
                                              </v-avatar>
                                          </div>
                                        </td>
                                        <td>{{ item.institution.name }}</td>
                                        <td>
                                          {{ item.institution.county.name }}
                                        </td>
                                        <td>
                                          <div v-if="item.status == 1">
                                            Active
                                          </div>
                                          <div v-else>Inactive</div>
                                        </td>
                                        <td>
                                          <div
                                            v-if="
                                              item.requesting_institution_id ==
                                              item.aff_institution_id
                                            "
                                            class="green--text"
                                          >
                                            Sent
                                          </div>
                                          <div
                                            v-if="
                                              item.requesting_institution_id !=
                                              item.aff_institution_id
                                            "
                                            class="red--text"
                                          >
                                            Received
                                          </div>
                                        </td>
                                        <td>
                                          <div align="right">
                                            <v-btn
                                              icon
                                              @click="
                                                showInstitutionAffiliation(
                                                  item.institution
                                                )
                                              "
                                              class="button mr-1 universal--text"
                                            >
                                              <v-icon small> mdi-eye </v-icon>
                                            </v-btn>
                                            <v-btn
                                              v-if="
                                                $can(
                                                  'institution_affiliation_archive'
                                                )
                                              "
                                              icon
                                              class="button mr-1 error--text"
                                              @click="
                                                deleteInstitutionAffiliation(
                                                  item
                                                )
                                              "
                                              :loading="
                                                loading &&
                                                institutionAffiliationIndex ==
                                                  item.id
                                              "
                                            >
                                              <v-icon small>
                                                mdi-delete
                                              </v-icon>
                                            </v-btn>
                                            <v-tooltip
                                              top
                                              v-if="
                                                $can(
                                                  'institution_affiliation_activate'
                                                ) &&
                                                item.status == 0 &&
                                                item.requesting_institution_id !=
                                                item.aff_institution_id
                                              "
                                            >
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-btn
                                                  icon
                                                  class="button mr-1 red--text"
                                                  @click="
                                                    activateInstitutionAffiliation(
                                                      item
                                                    )
                                                  "
                                                  :loading="
                                                    activateLoading &&
                                                    institutionAffiliationIndex ==
                                                      item.id
                                                  "
                                                  v-bind="attrs"
                                                  v-on="on"
                                                >
                                                  <v-icon small>
                                                    mdi-toggle-switch-off-outline
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span>Approve Affiliation</span>
                                            </v-tooltip>
                                            <v-tooltip
                                              top
                                              v-if="
                                                $can(
                                                  'institution_affiliation_deactivate'
                                                ) &&
                                                item.status == 1 &&
                                                item.requesting_institution_id !=
                                                  item.aff_institution_id
                                              "
                                            >
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-btn
                                                  icon
                                                  class="button mr-1 green--text"
                                                  @click="
                                                    deactivateInstitutionAffiliation(
                                                      item
                                                    )
                                                  "
                                                  :loading="
                                                    deactivateLoading &&
                                                    institutionAffiliationIndex ==
                                                      item.id
                                                  "
                                                  v-bind="attrs"
                                                  v-on="on"
                                                >
                                                  <v-icon small>
                                                    mdi-toggle-switch
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span>Reject Affiliation</span>
                                            </v-tooltip>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </v-card>
                            </div>
                            <div class="hidden-md-and-up">
                              <div>
                                <v-layout column>
                                  <template
                                    v-for="(
                                      institution, index
                                    ) in parentInstitutionAffiliations"
                                  >
                                    <div :key="index">
                                      <v-flex xs12 class="mb-2">
                                        <v-card elevation="0">
                                          <div class="pa-2 mt-2">
                                            <v-layout column>
                                              <v-flex xs12>
                                                <v-layout row wrap>
                                                  <v-flex xs11>
                                                    <div
                                                      class="title text--text"
                                                    >
                                                      <b>{{
                                                        institution.institution
                                                          .name
                                                      }}</b>
                                                    </div>
                                                  </v-flex>
                                                  <v-flex xs1>
                                                    <v-btn
                                                      depressed
                                                      class="red--text"
                                                      :loading="
                                                        loading &&
                                                        institutionAffiliationIndex ==
                                                          institution.id
                                                      "
                                                      icon
                                                      @click="
                                                        deleteInstitutionAffiliation(
                                                          institution
                                                        )
                                                      "
                                                      v-if="
                                                        $can(
                                                          'institution_affiliation_archive'
                                                        )
                                                      "
                                                    >
                                                      <v-icon>
                                                        mdi-delete
                                                      </v-icon>
                                                    </v-btn>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                              <v-divider
                                                class="mt-2"
                                              ></v-divider>
                                              <v-flex xs12 class="mt-1">
                                                <v-layout column>
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs4>
                                                        <b>County</b>
                                                      </v-flex>
                                                      <v-flex xs8>
                                                        {{
                                                          institution
                                                            .institution.county
                                                            .name
                                                        }}
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs4>
                                                        <b>Status</b>
                                                      </v-flex>
                                                      <v-flex xs8>
                                                        <div
                                                          v-if="
                                                            institution.status == 1
                                                          "
                                                        >
                                                          Active
                                                        </div>
                                                        <div v-else>
                                                          Inactive
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>

                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs4>
                                                        <b>Request Type</b>
                                                      </v-flex>
                                                      <v-flex xs8>
                                                        <div
                                                          v-if="
                                                            institution.requesting_institution_id ==
                                                            institution.aff_institution_id
                                                          "
                                                          class="green--text"
                                                        >
                                                          Sent
                                                        </div>
                                                        <div
                                                          v-if="
                                                            institution.requesting_institution_id !=
                                                            institution.aff_institution_id
                                                          "
                                                          class="red--text"
                                                        >
                                                          Received
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>

                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs12>
                                                        <div>
                                                          <v-btn
                                                            depressed
                                                            class="primary text-none white--text mt-3"
                                                            block
                                                            @click="
                                                              showInstitutionAffiliation(
                                                                institution.institution
                                                              )
                                                            "
                                                            >View
                                                            <v-icon right>
                                                              mdi-eye
                                                            </v-icon>
                                                          </v-btn>
                                                        </div>
                                                      </v-flex>
                                                      <v-flex xs12>
                                                        <div>
                                                          <v-btn
                                                            depressed
                                                            class="green darken-1 text-none white--text mt-1"
                                                            block
                                                            :loading="
                                                              activateLoading &&
                                                              institutionAffiliationIndex ==
                                                                institution.id
                                                            "
                                                            @click="
                                                              activateInstitutionAffiliation(
                                                                institution
                                                              )
                                                            "
                                                            v-if="
                                                              $can(
                                                                'institution_affiliation_activate'
                                                              ) &&
                                                                institution.status == 0 &&
                                                                institution.requesting_institution_id !=
                                                                institution.aff_institution_id
                                                            "
                                                            >Approve Affiliation
                                                            <v-icon right>
                                                              mdi-toggle-switch
                                                            </v-icon>
                                                          </v-btn>
                                                        </div>
                                                      </v-flex>
                                                      <v-flex xs12>
                                                        <div>
                                                          <v-btn
                                                            depressed
                                                            class="red darken-1 text-none white--text mt-1"
                                                            block
                                                            :loading="
                                                              activateLoading &&
                                                              institutionAffiliationIndex ==
                                                                institution.id
                                                            "
                                                            @click="
                                                              deactivateInstitutionAffiliation(
                                                                institution
                                                              )
                                                            "
                                                            v-if="
                                                              $can(
                                                                'institution_affiliation_deactivate'
                                                              ) &&
                                                                institution.status == 1 &&
                                                                institution.requesting_institution_id !=
                                                                institution.aff_institution_id
                                                            "
                                                            >Reject Affiliation
                                                            <v-icon right>
                                                              mdi-toggle-switch-off-outline
                                                            </v-icon>
                                                          </v-btn>
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                            </v-layout>
                                          </div>
                                        </v-card>
                                      </v-flex>
                                    </div>
                                  </template>
                                </v-layout>
                              </div>
                            </div>
                          </div>
                        </v-flex>
                      </v-card>
                      <v-layout column class="mt-5">
                        <v-flex xs12>
                          <div align="left">
                            <v-pagination
                              v-if="length != 0"
                              :length="parentInstitutionAffiliationLength"
                              total-visible="10"
                              v-model="
                                parentInstitutionAffiliationPagination.current_page
                              "
                              @input="changeParentInstitutionAffiliationPage()"
                              circle
                            >
                            </v-pagination>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div
                            v-if="parentInstitutionAffiliationLength != 0"
                            align="center"
                            class="mt-5"
                          >
                            <b class="text--text">Total: </b
                            >{{
                              parentInstitutionAffiliationPagination.total
                                | formatNumber
                            }}
                          </div>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div v-if="parentInstitutionAffiliationView == 'create'">
                      <v-flex class="mt-5">
                        <v-card-title class="tableHeader text--text">
                          <v-img
                            v-if="newInstitutionAffiliation.id != null"
                            src="img/badge.png"
                            max-width="40"
                          ></v-img>
                          <v-spacer></v-spacer>
                          <div v-if="newInstitutionAffiliation.id == null">
                            Add Institution Affiliation
                          </div>
                          <div v-else>Edit Institution Affiliation</div>
                          <v-spacer></v-spacer>
                          <v-btn
                            icon
                            @click="
                              changeParentInstitutionAffiliationView('default')
                            "
                          >
                            <v-icon class="text--text"> mdi-close </v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-form
                            v-model="valid"
                            ref="institutionAffiliationForm"
                            lazy-validation
                          >
                            <v-layout row wrap class="mt-5">
                              <v-flex xs12 md4>
                                <div class="pa-3">
                                  <div>
                                    Institution<span class="error--text"
                                      >*</span
                                    >
                                  </div>
                                  <v-select
                                    :items="formData.all_institutions"
                                    v-model="newInstitutionAffiliation.institution_id"
                                    class="text_field background my-2"
                                    item-value="id"
                                    item-text="name"
                                    outlined
                                    dense
                                    :rules="inputRules"
                                  ></v-select>
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-form>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            depressed
                            class="primary text-none mt-3"
                            @click="saveParentAffliliatedInstitution"
                            :disabled="!valid"
                            :loading="loading"
                          >
                            Save
                            <v-icon right> mdi-content-save </v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-flex>
                    </div>
                  </div>
                </div>
                <div v-if="activeTab == 'organisationAffiliations'">
                  <v-tabs show-arrows v-model="organisationAffTab">
                    <v-tabs-slider color="primary"></v-tabs-slider>

                    <v-tab @click="activeOrganisationTab = 'parent'">
                      <div class="text--text text-none">
                        Parent Organisations ({{
                          parentOrganisationAffiliationPagination.total
                        }})
                      </div>
                    </v-tab>
                    <v-tab @click="activeOrganisationTab = 'child'">
                      <div class="text--text text-none">
                        Child Organisations ({{
                          childOrganisationAffiliationPagination.total
                        }})
                      </div>
                    </v-tab>
                  </v-tabs>

                  <div v-if="activeOrganisationTab == 'child'">
                    <div v-if="childOrganisationAffiliationView == 'default'">
                      <v-card elevation="0" class="mt-5">
                        <v-flex xs12 class="mt-10 mb-2">
                          <div class="mx-5">
                            <v-layout row wrap>
                              <v-flex xs12 md4> </v-flex>
                              <v-flex xs12 md4>
                                <div class="pa-1">
                                  <v-text-field
                                    class="text_field header"
                                    outlined
                                    dense
                                    label="Search"
                                    append-icon="mdi-undo-variant"
                                    @click:append="
                                      resetChildOrganisationAffiliationSearch()
                                    "
                                    v-on:keyup.enter="
                                      childOrganisationAffiliationSearch
                                    "
                                    v-model="
                                      childOrganisationAffiliationSearchTerm
                                    "
                                    @input="
                                      enableChildOrganisationAffiliationSearch()
                                    "
                                  ></v-text-field>
                                </div>
                              </v-flex>
                              <v-flex xs12 md2>
                                <div class="pa-1">
                                  <v-btn
                                    class="secondary primary--text text-none"
                                    depressed
                                    block
                                    @click="childOrganisationAffiliationSearch"
                                    :loading="
                                      childOrganisationAffiliationSearchLoader
                                    "
                                    :disabled="
                                      childOrganisationAffiliationSearchButtonDisabled
                                    "
                                  >
                                    Search
                                    <v-icon right>mdi-magnify</v-icon>
                                  </v-btn>
                                </div>
                              </v-flex>
                              <v-flex xs12 md2>
                                <div class="pa-1">
                                  <v-btn
                                    block
                                    depressed
                                    class="primary text-none"
                                    @click="
                                      changeChildOrganisationAffiliationView(
                                        'create'
                                      )
                                    "
                                    v-if="
                                      $can('institution_affiliation_create')
                                    "
                                  >
                                    Add Affiliation
                                    <v-icon right
                                      >mdi-plus-circle-outline</v-icon
                                    >
                                  </v-btn>
                                </div>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-flex>
                        <v-divider class="mt-9 mx-5"></v-divider>
                        <v-flex xs12 class="mt-5">
                          <div v-if="childOrganisationAffiliations.length == 0">
                            <v-card elevation="0">
                              <v-layout row wrap>
                                <v-flex xs12 md1>
                                  <v-container fill-height fluid>
                                    <v-row align="center" justify="center">
                                      <v-col class="text-center">
                                        <v-icon large class="text--text">
                                          mdi-alert-circle-outline
                                        </v-icon>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-flex>
                                <v-flex xs12 md11>
                                  <v-container fill-height fluid>
                                    <v-row align="center" justify="center">
                                      <v-col class="text-center">
                                        No Child Organisation Affiliations Found
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </div>
                          <div v-else>
                            <div class="hidden-sm-and-down">
                              <v-card elevation="0" class="pa-2">
                                <v-simple-table>
                                  <template v-slot:default>
                                    <thead class="header">
                                      <tr class="tableHeader">
                                        <th class="text-left text--text">Logo</th>
                                        <th class="text-left text--text">Name</th>
                                        <th class="text-left text--text">County</th>
                                        <th class="text-left text--text">Status</th>
                                        <th></th>
                                        <th class="text-right text--text">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="item in childOrganisationAffiliations"
                                        :key="item.id"
                                      >
                                        <td>
                                          <div class="my-2">
                                            <v-avatar size="48" v-if="item.affiliated_organisation.logo==null">
                                                <v-img
                                                  max-height="150"
                                                  
                                                  src="img/logo_fav.png"
                                                >
                                                </v-img>
                                              </v-avatar>
                                              <v-avatar size="48" v-else>
                                                <v-img
                                                    max-height="150"
                                                    :src="path+'/storage/organisation_pictures/'+item.affiliated_organisation.logo"
                                                  >
                                                  </v-img>
                                              </v-avatar>
                                          </div>
                                        </td>
                                        <td>
                                          {{ item.affiliated_organisation.name }}
                                        </td>
                                        <td>
                                          {{
                                            item.affiliated_organisation.county
                                              .name
                                          }}
                                        </td>
                                        <td>
                                          <div v-if="item.status == 1">
                                            Active
                                          </div>
                                          <div v-else>Inactive</div>
                                        </td>
                                        <td>
                                          <div
                                            v-if="
                                              item.requesting_institution_id ==
                                              item.institution_id
                                            "
                                            class="green--text"
                                          >
                                            Sent
                                          </div>
                                          <div
                                            v-if="
                                              item.requesting_institution_id !=
                                              item.institution_id
                                            "
                                            class="red--text"
                                          >
                                            Received
                                          </div>
                                        </td>
                                        <td>
                                          <div align="right">
                                            <v-btn
                                              icon
                                              @click="
                                                showOrganisationAffiliation(
                                                  item.affiliated_organisation
                                                )
                                              "
                                              class="button mr-1 universal--text"
                                            >
                                              <v-icon small> mdi-eye </v-icon>
                                            </v-btn>
                                            <v-btn
                                              v-if="
                                                $can(
                                                  'institution_affiliation_archive'
                                                )
                                              "
                                              icon
                                              class="button mr-1 error--text"
                                              @click="
                                                deleteOrganisationAffiliation(
                                                  item
                                                )
                                              "
                                              :loading="
                                                loading &&
                                                organisationAffiliationIndex ==
                                                  item.id
                                              "
                                            >
                                              <v-icon small>
                                                mdi-delete
                                              </v-icon>
                                            </v-btn>
                                            <v-tooltip
                                              top
                                              v-if="
                                                $can(
                                                  'institution_affiliation_activate'
                                                ) &&
                                                item.status == 0 &&
                                                item.requesting_institution_id !=
                                                  item.institution_id
                                              "
                                            >
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-btn
                                                  icon
                                                  class="button mr-1 red--text"
                                                  @click="
                                                    activateChildOrganisationAffiliation(
                                                      item
                                                    )
                                                  "
                                                  :loading="
                                                    activateLoading &&
                                                    organisationAffiliationIndex ==
                                                      item.id
                                                  "
                                                  v-bind="attrs"
                                                  v-on="on"
                                                >
                                                  <v-icon small>
                                                    mdi-toggle-switch-off-outline
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span>Approve Affiliation</span>
                                            </v-tooltip>
                                            <v-tooltip
                                              top
                                              v-if="
                                                $can(
                                                  'institution_affiliation_deactivate'
                                                ) &&
                                                item.status == 1 &&
                                                item.requesting_institution_id !=
                                                  item.institution_id
                                              "
                                            >
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-btn
                                                  icon
                                                  class="button mr-1 green--text"
                                                  @click="
                                                    deactivateOrganisationAffiliation(
                                                      item
                                                    )
                                                  "
                                                  :loading="
                                                    deactivateLoading &&
                                                    organisationAffiliationIndex ==
                                                      item.id
                                                  "
                                                  v-bind="attrs"
                                                  v-on="on"
                                                >
                                                  <v-icon small>
                                                    mdi-toggle-switch
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span>Reject Affiliation</span>
                                            </v-tooltip>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </v-card>
                            </div>
                            <div class="hidden-md-and-up">
                              <div>
                                <v-layout column>
                                  <template
                                    v-for="(
                                      organisation, index
                                    ) in childOrganisationAffiliations"
                                  >
                                    <div :key="index">
                                      <v-flex xs12 class="mb-2">
                                        <v-card elevation="0">
                                          <div class="pa-2 mt-2">
                                            <v-layout column>
                                              <v-flex xs12>
                                                <v-layout row wrap>
                                                  <v-flex xs11>
                                                    <div
                                                      class="title text--text"
                                                    >
                                                      <b>{{
                                                        organisation
                                                          .affiliated_organisation
                                                          .name
                                                      }}</b>
                                                    </div>
                                                  </v-flex>
                                                  <v-flex xs1>
                                                    <v-btn
                                                      depressed
                                                      class="red--text"
                                                      :loading="
                                                        loading &&
                                                        organisationAffiliationIndex ==
                                                          organisation.id
                                                      "
                                                      icon
                                                      @click="
                                                        deleteOrganisationAffiliation(
                                                          organisation
                                                        )
                                                      "
                                                      v-if="
                                                        $can(
                                                          'institution_affiliation_archive'
                                                        )
                                                      "
                                                    >
                                                      <v-icon>
                                                        mdi-delete
                                                      </v-icon>
                                                    </v-btn>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                              <v-divider
                                                class="mt-2"
                                              ></v-divider>
                                              <v-flex xs12 class="mt-1">
                                                <v-layout column>
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs4>
                                                        <b>County</b>
                                                      </v-flex>
                                                      <v-flex xs8>
                                                        {{
                                                          organisation
                                                            .affiliated_organisation
                                                            .county.name
                                                        }}
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs4>
                                                        <b>Status</b>
                                                      </v-flex>
                                                      <v-flex xs8>
                                                        <div
                                                          v-if="
                                                            organisation.status == 1
                                                          "
                                                        >
                                                          Active
                                                        </div>
                                                        <div v-else>
                                                          Inactive
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs4>
                                                        <b>Request Type</b>
                                                      </v-flex>
                                                      <v-flex xs8>
                                                        <div
                                                          v-if="
                                                            organisation.requesting_institution_id ==
                                                            organisation.institution_id
                                                          "
                                                          class="green--text"
                                                        >
                                                          Sent
                                                        </div>
                                                        <div
                                                          v-if="
                                                            organisation.requesting_institution_id !=
                                                            organisation.institution_id
                                                          "
                                                          class="red--text"
                                                        >
                                                          Received
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>

                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs12>
                                                        <div>
                                                          <v-btn
                                                            depressed
                                                            class="primary text-none white--text mt-3"
                                                            block
                                                            @click="
                                                              showOrganisationAffiliation(
                                                                organisation.affiliated_organisation
                                                              )
                                                            "
                                                            >View
                                                            <v-icon right>
                                                              mdi-eye
                                                            </v-icon>
                                                          </v-btn>
                                                        </div>
                                                      </v-flex>
                                                      <v-flex xs12>
                                                        <div>
                                                          <v-btn
                                                            depressed
                                                            class="green darken-1 text-none white--text mt-1"
                                                            block
                                                            :loading="
                                                              activateLoading &&
                                                              organisationAffiliationIndex ==
                                                                organisation.id
                                                            "
                                                            @click="
                                                              activateChildOrganisationAffiliation(
                                                                organisation
                                                              )
                                                            "
                                                            v-if="
                                                              $can(
                                                                'institution_affiliation_activate'
                                                              ) &&
                                                                organisation.status == 0 &&
                                                                organisation.requesting_institution_id !=
                                                                organisation.institution_id
                                                            "
                                                            >Approve Affiliation
                                                            <v-icon right>
                                                              mdi-toggle-switch
                                                            </v-icon>
                                                          </v-btn>
                                                        </div>
                                                      </v-flex>
                                                      <v-flex xs12>
                                                        <div>
                                                          <v-btn
                                                            depressed
                                                            class="red darken-1 text-none white--text mt-1"
                                                            block
                                                            :loading="
                                                              activateLoading &&
                                                              organisationAffiliationIndex ==
                                                                organisation.id
                                                            "
                                                            @click="
                                                              deactivateOrganisationAffiliation(
                                                                organisation
                                                              )
                                                            "
                                                            v-if="
                                                              $can(
                                                                'institution_affiliation_deactivate'
                                                              ) &&
                                                                organisation.status == 1 &&
                                                                organisation.requesting_institution_id !=
                                                                organisation.institution_id
                                                            "
                                                            >Reject Affiliation
                                                            <v-icon right>
                                                              mdi-toggle-switch-off-outline
                                                            </v-icon>
                                                          </v-btn>
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                            </v-layout>
                                          </div>
                                        </v-card>
                                      </v-flex>
                                    </div>
                                  </template>
                                </v-layout>
                              </div>
                            </div>
                          </div>
                        </v-flex>
                      </v-card>
                      <v-layout column class="mt-5">
                        <v-flex xs12>
                          <div align="left">
                            <v-pagination
                              v-if="length != 0"
                              :length="childOrganisationAffiliationLength"
                              total-visible="10"
                              v-model="
                                childOrganisationAffiliationPagination.current_page
                              "
                              @input="changeChildOrganisationAffiliationPage()"
                              circle
                            >
                            </v-pagination>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div
                            v-if="childOrganisationAffiliationLength != 0"
                            align="center"
                            class="mt-5"
                          >
                            <b class="text--text">Total: </b
                            >{{
                              childOrganisationAffiliationPagination.total
                                | formatNumber
                            }}
                          </div>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div v-if="childOrganisationAffiliationView == 'create'">
                      <v-flex class="mt-5">
                        <v-card-title class="tableHeader text--text">
                          <v-img
                            v-if="newOrganisationAffiliation.id != null"
                            src="img/badge.png"
                            max-width="40"
                          ></v-img>
                          <v-spacer></v-spacer>
                          <div v-if="newOrganisationAffiliation.id == null">
                            Add Organisation Affiliation
                          </div>
                          <div v-else>Edit Organisation Affiliation</div>
                          <v-spacer></v-spacer>
                          <v-btn
                            icon
                            @click="
                              changeChildOrganisationAffiliationView('default')
                            "
                          >
                            <v-icon class="text--text"> mdi-close </v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-form
                            v-model="valid"
                            ref="childOrganisationAffiliationForm"
                            lazy-validation
                          >
                            <v-layout row wrap class="mt-5">
                              <v-flex xs12 md4>
                                <div class="pa-3">
                                  <div>
                                    Organisation<span class="error--text"
                                      >*</span
                                    >
                                  </div>
                                  <v-select
                                    :items="formData.all_organisations"
                                    v-model="newOrganisationAffiliation.aff_organisation_id"
                                    class="text_field background my-2"
                                    item-value="id"
                                    item-text="name"
                                    outlined
                                    dense
                                    :rules="inputRules"
                                  ></v-select>
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-form>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            depressed
                            class="primary text-none mt-3"
                            @click="saveChildAffliliatedOrganisation"
                            :disabled="!valid"
                            :loading="loading"
                          >
                            Save
                            <v-icon right> mdi-content-save </v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-flex>
                    </div>
                  </div>

                  <div v-if="activeOrganisationTab == 'parent'">
                    <div v-if="parentOrganisationAffiliationView == 'default'">
                      <v-card elevation="0" class="mt-5">
                        <v-flex xs12 class="mt-10 mb-2">
                          <div class="mx-5">
                            <v-layout row wrap>
                              <v-flex xs12 md4> </v-flex>
                              <v-flex xs12 md4>
                                <div class="pa-1">
                                  <v-text-field
                                    class="text_field header"
                                    outlined
                                    dense
                                    label="Search"
                                    append-icon="mdi-undo-variant"
                                    @click:append="
                                      resetParentOrganisationAffiliationSearch()
                                    "
                                    v-on:keyup.enter="
                                      parentOrganisationAffiliationSearch
                                    "
                                    v-model="
                                      parentOrganisationAffiliationSearchTerm
                                    "
                                    @input="
                                      enableParentOrganisationAffiliationSearch()
                                    "
                                  ></v-text-field>
                                </div>
                              </v-flex>
                              <v-flex xs12 md2>
                                <div class="pa-1">
                                  <v-btn
                                    class="secondary primary--text text-none"
                                    depressed
                                    block
                                    @click="parentOrganisationAffiliationSearch"
                                    :loading="
                                      parentOrganisationAffiliationSearchLoader
                                    "
                                    :disabled="
                                      parentOrganisationAffiliationSearchButtonDisabled
                                    "
                                  >
                                    Search
                                    <v-icon right>mdi-magnify</v-icon>
                                  </v-btn>
                                </div>
                              </v-flex>
                              <v-flex xs12 md2>
                                <div class="pa-1">
                                  <v-btn
                                    block
                                    depressed
                                    class="primary text-none"
                                    @click="
                                      changeParentOrganisationAffiliationView(
                                        'create'
                                      )
                                    "
                                    v-if="
                                      $can('organisation_affiliation_create')
                                    "
                                  >
                                    Add Affiliation
                                    <v-icon right
                                      >mdi-plus-circle-outline</v-icon
                                    >
                                  </v-btn>
                                </div>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-flex>
                        <v-divider class="mt-9 mx-5"></v-divider>
                        <v-flex xs12 class="mt-5">
                          <div v-if="parentOrganisationAffiliations.length == 0">
                            <v-card elevation="0">
                              <v-layout row wrap>
                                <v-flex xs12 md1>
                                  <v-container fill-height fluid>
                                    <v-row align="center" justify="center">
                                      <v-col class="text-center">
                                        <v-icon large class="text--text">
                                          mdi-alert-circle-outline
                                        </v-icon>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-flex>
                                <v-flex xs12 md11>
                                  <v-container fill-height fluid>
                                    <v-row align="center" justify="center">
                                      <v-col class="text-center">
                                        No Parent Organisation Affiliations Found
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </div>
                          <div v-else>
                            <div class="hidden-sm-and-down">
                              <v-card elevation="0" class="pa-2">
                                <v-simple-table>
                                  <template v-slot:default>
                                    <thead class="header">
                                      <tr class="tableHeader">
                                        <th class="text-left text--text">Logo</th>
                                        <th class="text-left text--text">Name</th>
                                        <th class="text-left text--text">County</th>
                                        <th class="text-left text--text">Status</th>
                                        <th></th>
                                        <th class="text-right text--text">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="item in parentOrganisationAffiliations"
                                        :key="item.id"
                                      >
                                        <td>
                                          <div class="my-2">
                                            <v-avatar size="48" v-if="item.organisation.logo==null">
                                                <v-img
                                                  max-height="150"
                                                  
                                                  src="img/logo_fav.png"
                                                >
                                                </v-img>
                                              </v-avatar>
                                              <v-avatar size="48" v-else>
                                                <v-img
                                                    max-height="150"
                                                    :src="path+'/storage/organisation_pictures/'+item.organisation.logo"
                                                  >
                                                  </v-img>
                                              </v-avatar>
                                          </div>
                                        </td>
                                        <td>{{ item.organisation.name }}</td>
                                        <td>
                                          {{ item.organisation.county.name }}
                                        </td>
                                        <td>
                                          <div v-if="item.status == 1">
                                            Active
                                          </div>
                                          <div v-else>Inactive</div>
                                        </td>
                                        <td>
                                          <div
                                            v-if="
                                              item.requesting_organisation_id == null
                                            "
                                            class="green--text"
                                          >
                                            Sent
                                          </div>
                                          <div
                                            v-if="
                                              item.requesting_organisation_id != null
                                            "
                                            class="red--text"
                                          >
                                            Received
                                          </div>
                                        </td>
                                        <td>
                                          <div align="right">
                                            <v-btn
                                              icon
                                              @click="
                                                showOrganisationAffiliation(
                                                  item.organisation
                                                )
                                              "
                                              class="button mr-1 universal--text"
                                            >
                                              <v-icon small> mdi-eye </v-icon>
                                            </v-btn>
                                            <v-btn
                                              v-if="
                                                $can(
                                                  'organisation_affiliation_archive'
                                                )
                                              "
                                              icon
                                              class="button mr-1 error--text"
                                              @click="
                                                deleteOrganisationAffiliation(
                                                  item
                                                )
                                              "
                                              :loading="
                                                loading &&
                                                organisationAffiliationIndex ==
                                                  item.id
                                              "
                                            >
                                              <v-icon small>
                                                mdi-delete
                                              </v-icon>
                                            </v-btn>
                                            <v-tooltip
                                              top
                                              v-if="
                                                $can(
                                                  'organisation_affiliation_activate'
                                                ) &&
                                                item.status == 0 &&
                                                item.requesting_organisation_id != null
                                              "
                                            >
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-btn
                                                  icon
                                                  class="button mr-1 red--text"
                                                  @click="
                                                    activateOrganisationAffiliation(
                                                      item
                                                    )
                                                  "
                                                  :loading="
                                                    activateLoading &&
                                                    organisationAffiliationIndex ==
                                                      item.id
                                                  "
                                                  v-bind="attrs"
                                                  v-on="on"
                                                >
                                                  <v-icon small>
                                                    mdi-toggle-switch-off-outline
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span>Approve Affiliation</span>
                                            </v-tooltip>
                                            <v-tooltip
                                              top
                                              v-if="
                                                $can(
                                                  'organisation_affiliation_deactivate'
                                                ) &&
                                                item.status == 1 &&
                                                item.requesting_organisation_id != null
                                              "
                                            >
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-btn
                                                  icon
                                                  class="button mr-1 green--text"
                                                  @click="
                                                    deactivateParentOrganisationAffiliation(
                                                      item
                                                    )
                                                  "
                                                  :loading="
                                                    deactivateLoading &&
                                                    organisationAffiliationIndex ==
                                                      item.id
                                                  "
                                                  v-bind="attrs"
                                                  v-on="on"
                                                >
                                                  <v-icon small>
                                                    mdi-toggle-switch
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span>Reject Affiliation</span>
                                            </v-tooltip>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </v-card>
                            </div>
                            <div class="hidden-md-and-up">
                              <div>
                                <v-layout column>
                                  <template
                                    v-for="(
                                      organisation, index
                                    ) in parentOrganisationAffiliations"
                                  >
                                    <div :key="index">
                                      <v-flex xs12 class="mb-2">
                                        <v-card elevation="0">
                                          <div class="pa-2 mt-2">
                                            <v-layout column>
                                              <v-flex xs12>
                                                <v-layout row wrap>
                                                  <v-flex xs11>
                                                    <div
                                                      class="title text--text"
                                                    >
                                                      <b>{{
                                                        organisation.organisation
                                                          .name
                                                      }}</b>
                                                    </div>
                                                  </v-flex>
                                                  <v-flex xs1>
                                                    <v-btn
                                                      depressed
                                                      class="red--text"
                                                      :loading="
                                                        loading &&
                                                        organisationAffiliationIndex ==
                                                          organisation.id
                                                      "
                                                      icon
                                                      @click="
                                                        deleteOrganisationAffiliation(
                                                          organisation
                                                        )
                                                      "
                                                      v-if="
                                                        $can(
                                                          'organisation_affiliation_archive'
                                                        )
                                                      "
                                                    >
                                                      <v-icon>
                                                        mdi-delete
                                                      </v-icon>
                                                    </v-btn>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                              <v-divider
                                                class="mt-2"
                                              ></v-divider>
                                              <v-flex xs12 class="mt-1">
                                                <v-layout column>
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs4>
                                                        <b>County</b>
                                                      </v-flex>
                                                      <v-flex xs8>
                                                        {{
                                                          organisation
                                                            .organisation.county
                                                            .name
                                                        }}
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs4>
                                                        <b>Status</b>
                                                      </v-flex>
                                                      <v-flex xs8>
                                                        <div
                                                          v-if="
                                                            organisation.status == 1
                                                          "
                                                        >
                                                          Active
                                                        </div>
                                                        <div v-else>
                                                          Inactive
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs4>
                                                        <b>Request Type</b>
                                                      </v-flex>
                                                      <v-flex xs8>
                                                        <div
                                                          v-if="
                                                            organisation.requesting_organisation_id == null
                                                          "
                                                          class="green--text"
                                                        >
                                                          Sent
                                                        </div>
                                                        <div
                                                          v-if="
                                                            organisation.requesting_organisation_id != null
                                                          "
                                                          class="red--text"
                                                        >
                                                          Received
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>

                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs12>
                                                        <div>
                                                          <v-btn
                                                            depressed
                                                            class="primary text-none white--text mt-3"
                                                            block
                                                            @click="
                                                              showOrganisationAffiliation(
                                                                organisation.organisation
                                                              )
                                                            "
                                                            >View
                                                            <v-icon right>
                                                              mdi-eye
                                                            </v-icon>
                                                          </v-btn>
                                                        </div>
                                                      </v-flex>
                                                      <v-flex xs12>
                                                        <div>
                                                          <v-btn
                                                            depressed
                                                            class="green darken-1 text-none white--text mt-1"
                                                            block
                                                            :loading="
                                                              activateLoading &&
                                                              organisationAffiliationIndex ==
                                                                organisation.id
                                                            "
                                                            @click="
                                                              activateOrganisationAffiliation(
                                                                organisation
                                                              )
                                                            "
                                                            v-if="
                                                              $can(
                                                                'organisation_affiliation_activate'
                                                              ) &&
                                                                organisation.status == 0 &&
                                                                organisation.requesting_organisation_id != null
                                                            "
                                                            >Approve Affiliation
                                                            <v-icon right>
                                                              mdi-toggle-switch
                                                            </v-icon>
                                                          </v-btn>
                                                        </div>
                                                      </v-flex>
                                                      <v-flex xs12>
                                                        <div>
                                                          <v-btn
                                                            depressed
                                                            class="red darken-1 text-none white--text mt-1"
                                                            block
                                                            :loading="
                                                              activateLoading &&
                                                              organisationAffiliationIndex ==
                                                                organisation.id
                                                            "
                                                            @click="
                                                              deactivateParentOrganisationAffiliation(
                                                                organisation
                                                              )
                                                            "
                                                            v-if="
                                                              $can(
                                                                'organisation_affiliation_deactivate'
                                                              ) &&
                                                                organisation.status == 1 &&
                                                                organisation.requesting_organisation_id != null
                                                            "
                                                            >Reject Affiliation
                                                            <v-icon right>
                                                              mdi-toggle-switch-off-outline
                                                            </v-icon>
                                                          </v-btn>
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                            </v-layout>
                                          </div>
                                        </v-card>
                                      </v-flex>
                                    </div>
                                  </template>
                                </v-layout>
                              </div>
                            </div>
                          </div>
                        </v-flex>
                      </v-card>
                      <v-layout column class="mt-5">
                        <v-flex xs12>
                          <div align="left">
                            <v-pagination
                              v-if="length != 0"
                              :length="parentOrganisationAffiliationLength"
                              total-visible="10"
                              v-model="
                                parentOrganisationAffiliationPagination.current_page
                              "
                              @input="changeParentOrganisationAffiliationPage()"
                              circle
                            >
                            </v-pagination>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div
                            v-if="parentOrganisationAffiliationLength != 0"
                            align="center"
                            class="mt-5"
                          >
                            <b class="text--text">Total: </b
                            >{{
                              parentOrganisationAffiliationPagination.total
                                | formatNumber
                            }}
                          </div>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div v-if="parentOrganisationAffiliationView == 'create'">
                      <v-flex class="mt-5">
                        <v-card-title class="tableHeader text--text">
                          <v-img
                            v-if="newOrganisationAffiliation.id != null"
                            src="img/badge.png"
                            max-width="40"
                          ></v-img>
                          <v-spacer></v-spacer>
                          <div v-if="newOrganisationAffiliation.id == null">
                            Add Organisation Affiliation
                          </div>
                          <div v-else>Edit Organisation Affiliation</div>
                          <v-spacer></v-spacer>
                          <v-btn
                            icon
                            @click="
                              changeParentOrganisationAffiliationView('default')
                            "
                          >
                            <v-icon class="text--text"> mdi-close </v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-form
                            v-model="valid"
                            ref="parentOrganisationAffiliationForm"
                            lazy-validation
                          >
                            <v-layout row wrap class="mt-5">
                              <v-flex xs12 md4>
                                <div class="pa-3">
                                  <div>
                                    Organisation<span class="error--text"
                                      >*</span
                                    >
                                  </div>
                                  <v-select
                                    :items="formData.all_organisations"
                                    v-model="newOrganisationAffiliation.organisation_id"
                                    class="text_field background my-2"
                                    item-value="id"
                                    item-text="name"
                                    outlined
                                    dense
                                    :rules="inputRules"
                                  ></v-select>
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-form>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            depressed
                            class="primary text-none mt-3"
                            @click="saveParentAffliliatedOrganisation"
                            :disabled="!valid"
                            :loading="loading"
                          >
                            Save
                            <v-icon right> mdi-content-save </v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-flex>
                    </div>
                  </div>
                </div>
                <div v-if="activeTab == 'institutionAdministrators'">
                  <div v-if="institutionAdministratorView == 'default'">
                    <v-card elevation="0" class="mt-5">
                      <v-flex xs12 class="mt-10 mb-2">
                        <div class="mx-5">
                          <v-layout row wrap>
                            <v-flex xs12 md4> </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <v-text-field
                                  class="text_field header"
                                  outlined
                                  dense
                                  label="Search"
                                  append-icon="mdi-undo-variant"
                                  @click:append="
                                    resetInstitutionAdministratorSearch()
                                  "
                                  v-on:keyup.enter="
                                    institutionAdministratorSearch
                                  "
                                  v-model="institutionAdministratorSearchTerm"
                                  @input="
                                    enableInstitutionAdministratorSearch()
                                  "
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md2>
                              <div class="pa-1">
                                <v-btn
                                  class="secondary primary--text text-none"
                                  depressed
                                  block
                                  @click="institutionAdministratorSearch"
                                  :loading="
                                    institutionAdministratorSearchLoader
                                  "
                                  :disabled="
                                    institutionAdministratorSearchButtonDisabled
                                  "
                                >
                                  Search
                                  <v-icon right>mdi-magnify</v-icon>
                                </v-btn>
                              </div>
                            </v-flex>
                            <v-flex xs12 md2>
                              <div class="pa-1">
                                <v-btn
                                  block
                                  depressed
                                  class="primary text-none"
                                  @click="
                                    changeInstitutionAdministratorView('create')
                                  "
                                  v-if="
                                    $can('institution_administrator_create')
                                  "
                                >
                                  Add Administrator
                                  <v-icon right>mdi-plus-circle-outline</v-icon>
                                </v-btn>
                              </div>
                            </v-flex>
                          </v-layout>
                        </div>
                      </v-flex>
                      <v-divider class="mt-9 mx-5"></v-divider>
                      <v-flex xs12 class="mt-5">
                        <div v-if="institutionAdministrators.length == 0">
                          <v-card elevation="0">
                            <v-layout row wrap>
                              <v-flex xs12 md1>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      <v-icon large class="text--text">
                                        mdi-alert-circle-outline
                                      </v-icon>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                              <v-flex xs12 md11>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      No Institution Administrators Found
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </div>
                        <div v-else>
                          <div class="hidden-sm-and-down">
                            <v-card elevation="0" class="pa-2">
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead class="header">
                                    <tr class="tableHeader">
                                      <th class="text-left text--text">Pic</th>
                                      <th class="text-left text--text">Name</th>
                                      <th class="text-left text--text">Email Address</th>
                                      <th class="text-left text--text">Phone #</th>
                                      <th class="text-left text--text">Code</th>
                                      <th class="text-left text--text">Status</th>
                                      <th class="text-right text--text">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="item in institutionAdministrators"
                                      :key="item.id"
                                    >
                                      <td>
                                        <div class="my-2">
                                          <v-avatar size="48" v-if="item.user.display_img==null">
                                            <v-img
                                              max-height="150"
                                              
                                              src="img/logo_fav.png"
                                            >
                                            </v-img>
                                          </v-avatar>
                                          <v-avatar size="48" v-else>
                                            <v-img
                                                max-height="150"
                                                :src="path+'/storage/profile_pics/'+item.user.display_img"
                                              >
                                              </v-img>
                                          </v-avatar>
                                        </div>
                                      </td>
                                      <td>
                                        {{ item.user.f_name }}
                                        {{ item.user.m_name }}
                                        {{ item.user.l_name }}
                                      </td>
                                      <td>{{ item.user.email }}</td>
                                      <td>{{ item.user.phone }}</td>
                                      <td>{{ item.user.code }}</td>
                                      <td>
                                        <div v-if="item.status == 1">
                                          Active
                                        </div>
                                        <div v-else>Inactive</div>
                                      </td>
                                      <td>
                                        <div align="right">
                                          <v-btn
                                            icon
                                            @click="
                                              showInstitutionAdministrator(
                                                item.user
                                              )
                                            "
                                            class="button mr-1 universal--text"
                                          >
                                            <v-icon small> mdi-eye </v-icon>
                                          </v-btn>
                                          <v-btn
                                            v-if="
                                              $can(
                                                'institution_administrator_archive'
                                              )
                                            "
                                            icon
                                            class="button mr-1 error--text"
                                            @click="
                                              deleteInstitutionAdministrator(
                                                item
                                              )
                                            "
                                            :loading="
                                              loading &&
                                              institutionAdministratorIndex ==
                                                item.id
                                            "
                                          >
                                            <v-icon small> mdi-delete </v-icon>
                                          </v-btn>
                                          <v-tooltip
                                            top
                                            v-if="
                                              $can(
                                                'institution_administrator_activate'
                                              ) && item.status == 0
                                            "
                                          >
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <v-btn
                                                icon
                                                class="button mr-1 red--text"
                                                @click="
                                                  activateInstitutionAdministrator(
                                                    item
                                                  )
                                                "
                                                :loading="
                                                  activateLoading &&
                                                  institutionAdministratorIndex ==
                                                    item.id
                                                "
                                                v-bind="attrs"
                                                v-on="on"
                                              >
                                                <v-icon small>
                                                  mdi-toggle-switch-off-outline
                                                </v-icon>
                                              </v-btn>
                                            </template>
                                            <span>Activate Administrator</span>
                                          </v-tooltip>
                                          <v-tooltip
                                            top
                                            v-if="
                                              $can(
                                                'institution_administrator_deactivate'
                                              ) && item.status == 1
                                            "
                                          >
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <v-btn
                                                icon
                                                class="button mr-1 green--text"
                                                @click="
                                                  deactivateInstitutionAdministrator(
                                                    item
                                                  )
                                                "
                                                :loading="
                                                  deactivateLoading &&
                                                  institutionAdministratorIndex ==
                                                    item.id
                                                "
                                                v-bind="attrs"
                                                v-on="on"
                                              >
                                                <v-icon small>
                                                  mdi-toggle-switch
                                                </v-icon>
                                              </v-btn>
                                            </template>
                                            <span>Deactivate Administrator</span>
                                          </v-tooltip>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card>
                          </div>
                          <div class="hidden-md-and-up">
                            <div>
                              <v-layout column>
                                <template
                                  v-for="(
                                    administrator, index
                                  ) in institutionAdministrators"
                                >
                                  <div :key="index">
                                    <v-flex xs12 class="mb-2">
                                      <v-card elevation="0">
                                        <div class="pa-2 mt-2">
                                          <v-layout column>
                                            <v-flex xs12>
                                              <v-layout row wrap>
                                                <v-flex xs11>
                                                  <div
                                                    class="title text--text"
                                                  >
                                                    <b
                                                      >{{
                                                        administrator.user
                                                          .f_name
                                                      }}
                                                      {{
                                                        administrator.user
                                                          .m_name
                                                      }}
                                                      {{
                                                        administrator.user
                                                          .l_name
                                                      }}</b
                                                    >
                                                  </div>
                                                </v-flex>
                                                <v-flex xs1>
                                                  <v-btn
                                                    depressed
                                                    class="red--text"
                                                    :loading="
                                                      loading &&
                                                      institutionAdministratorIndex ==
                                                        administrator.id
                                                    "
                                                    icon
                                                    @click="
                                                      deleteInstitutionAdministrator(
                                                        administrator
                                                      )
                                                    "
                                                    v-if="
                                                      $can(
                                                        'institution_administrator_archive'
                                                      )
                                                    "
                                                  >
                                                    <v-icon>
                                                      mdi-delete
                                                    </v-icon>
                                                  </v-btn>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-divider class="mt-2"></v-divider>
                                            <v-flex xs12 class="mt-1">
                                              <v-layout column>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Email Address</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{
                                                        administrator.user.email
                                                      }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Phone #</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{
                                                        administrator.user.phone
                                                      }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Code</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{
                                                        administrator.user.code
                                                      }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Status</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      <div
                                                        v-if="
                                                          administrator.status ==
                                                          1
                                                        "
                                                      >
                                                        Active
                                                      </div>
                                                      <div v-else>Inactive</div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>

                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="primary text-none white--text mt-3"
                                                          block
                                                          @click="
                                                            showInstitutionAdministrator(
                                                              administrator.user
                                                            )
                                                          "
                                                          >View
                                                          <v-icon right>
                                                            mdi-eye
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>
                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="green darken-1 text-none white--text mt-1"
                                                          block
                                                          :loading="
                                                            activateLoading &&
                                                            institutionAdministratorIndex ==
                                                              administrator.id
                                                          "
                                                          @click="
                                                            activateInstitutionAdministrator(
                                                              administrator
                                                            )
                                                          "
                                                          v-if="
                                                            $can(
                                                              'institution_administrator_activate'
                                                            ) && administrator.status == 0
                                                          "
                                                          >Activate Administrator
                                                          <v-icon right>
                                                            mdi-toggle-switch-off-outline
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>
                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="red darken-1 text-none white--text mt-1"
                                                          block
                                                          :loading="
                                                            deactivateLoading &&
                                                            institutionAdministratorIndex ==
                                                              administrator.id
                                                          "
                                                          @click="
                                                            deactivateInstitutionAdministrator(
                                                              administrator
                                                            )
                                                          "
                                                          v-if="
                                                            $can(
                                                              'institution_administrator_deactivate'
                                                            ) && administrator.status == 1
                                                          "
                                                          >Deactivate Administrator
                                                          <v-icon right>
                                                            mdi-toggle-switch-off-outline
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                          </v-layout>
                                        </div>
                                      </v-card>
                                    </v-flex>
                                  </div>
                                </template>
                              </v-layout>
                            </div>
                          </div>
                        </div>
                      </v-flex>
                    </v-card>
                    <v-layout column class="mt-5">
                      <v-flex xs12>
                        <div align="left">
                          <v-pagination
                            v-if="length != 0"
                            :length="institutionAdministratorLength"
                            total-visible="10"
                            v-model="
                              institutionAdministratorPagination.current_page
                            "
                            @input="changeInstitutionAdministratorPage()"
                            circle
                          >
                          </v-pagination>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div
                          v-if="institutionAdministratorLength != 0"
                          align="center"
                          class="mt-5"
                        >
                          <b class="text--text">Total: </b
                          >{{
                            institutionAdministratorPagination.total
                              | formatNumber
                          }}
                        </div>
                      </v-flex>
                    </v-layout>
                  </div>
                  <div v-if="institutionAdministratorView == 'create'">
                    <v-flex class="mt-5">
                      <v-card-title class="tableHeader text--text">
                        <v-img
                          v-if="newAdministrator.id != null"
                          src="img/badge.png"
                          max-width="40"
                        ></v-img>
                        <v-spacer></v-spacer>
                        <div v-if="newAdministrator.id == null">
                          Add Institution Administrator
                        </div>
                        <div v-else>Edit Institution Administrator</div>
                        <v-spacer></v-spacer>
                        <v-btn
                          icon
                          @click="changeInstitutionAdministratorView('default')"
                        >
                          <v-icon class="text--text"> mdi-close </v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <v-form
                          v-model="valid"
                          ref="institutionAdministratorForm"
                          lazy-validation
                        >
                          <v-layout row wrap class="mt-5">
                            <v-flex xs12 md4>
                              <div class="pa-3">
                                <div>
                                  User Code<span class="error--text">*</span>
                                </div>
                                <v-autocomplete
                                  v-model="newAdministrator.user_id"
                                  :loading="loading"
                                  :items="items"
                                  :search-input.sync="searchUsers"
                                  item-value="id"
                                  item-text="code"
                                  cache-items
                                  class="text_field background my-2"
                                  flat
                                  hide-no-data
                                  hide-details
                                  outlined
                                  dense
                                  :rules="inputRules"
                                >
                                  <template v-slot:selection="{ item }">
                                    {{ getText(item) }}
                                  </template>
                                  <template v-slot:item="{ item }">
                                    {{ getText(item) }}
                                  </template>
                                </v-autocomplete>
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          depressed
                          class="primary text-none mt-3"
                          @click="saveInstitutionAdministrator"
                          :disabled="!valid"
                          :loading="loading"
                        >
                          Save
                          <v-icon right> mdi-content-save </v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-flex>
                  </div>
                </div>
                <div v-if="activeTab == 'institutionUsers'">
                  <div v-if="institutionUserView == 'default'">
                    <v-card elevation="0" class="mt-5">
                      <v-flex xs12 class="mt-10 mb-2">
                        <div class="mx-5">
                          <v-layout row wrap>
                            <v-flex xs12 md4> </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <v-text-field
                                  class="text_field header"
                                  outlined
                                  dense
                                  label="Search"
                                  append-icon="mdi-undo-variant"
                                  @click:append="resetInstitutionUserSearch()"
                                  v-on:keyup.enter="institutionUserSearch"
                                  v-model="institutionUserSearchTerm"
                                  @input="enableInstitutionUserSearch()"
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md2>
                              <div class="pa-1">
                                <v-btn
                                  class="secondary primary--text text-none"
                                  depressed
                                  block
                                  @click="institutionUserSearch"
                                  :loading="institutionUserSearchLoader"
                                  :disabled="
                                    institutionUserSearchButtonDisabled
                                  "
                                >
                                  Search
                                  <v-icon right>mdi-magnify</v-icon>
                                </v-btn>
                              </div>
                            </v-flex>
                            <v-flex xs12 md2> </v-flex>
                          </v-layout>
                        </div>
                      </v-flex>
                      <v-divider class="mt-9 mx-5"></v-divider>
                      <v-flex xs12 class="mt-5">
                        <div v-if="institutionUsers.length == 0">
                          <v-card elevation="0">
                            <v-layout row wrap>
                              <v-flex xs12 md1>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      <v-icon large class="text--text">
                                        mdi-alert-circle-outline
                                      </v-icon>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                              <v-flex xs12 md11>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      No Institution Users Found
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </div>
                        <div v-else>
                          <div class="hidden-sm-and-down">
                            <v-card elevation="0" class="pa-2">
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead class="header">
                                    <tr class="tableHeader">
                                      <th class="text-left text--text">Pic</th>
                                      <th class="text-left text--text">Name</th>
                                      <th class="text-left text--text">Email Address</th>
                                      <th class="text-left text--text">Phone #</th>
                                      <th class="text-left text--text">Code</th>
                                      <th class="text-left text--text">Status</th>
                                      <th class="text-right text--text">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="item in institutionUsers"
                                      :key="item.id"
                                    >
                                      <td>
                                        <div class="my-2">
                                          <v-avatar size="48" v-if="item.user.display_img==null">
                                            <v-img
                                              max-height="150"
                                              
                                              src="img/logo_fav.png"
                                            >
                                            </v-img>
                                          </v-avatar>
                                          <v-avatar size="48" v-else>
                                            <v-img
                                                max-height="150"
                                                :src="path+'/storage/profile_pics/'+item.user.display_img"
                                              >
                                              </v-img>
                                          </v-avatar>
                                        </div>
                                      </td>
                                      <td>
                                        {{ item.user.f_name }}
                                        {{ item.user.m_name }}
                                        {{ item.user.l_name }}
                                      </td>
                                      <td>{{ item.user.email }}</td>
                                      <td>{{ item.user.phone }}</td>
                                      <td>{{ item.user.code }}</td>
                                      <td>
                                        <div v-if="item.status == 1">
                                          Active
                                        </div>
                                        <div v-else>Inactive</div>
                                      </td>
                                      <td>
                                        <div align="right">
                                          <v-btn
                                            icon
                                            @click="
                                              showInstitutionUser(item.user)
                                            "
                                            class="button mr-1 universal--text"
                                          >
                                            <v-icon small> mdi-eye </v-icon>
                                          </v-btn>
                                          <v-btn
                                            v-if="
                                              $can('institution_user_archive')
                                            "
                                            icon
                                            class="button mr-1 error--text"
                                            @click="deleteInstitutionUser(item)"
                                            :loading="
                                              loading &&
                                              institutionUserIndex == item.id
                                            "
                                          >
                                            <v-icon small> mdi-delete </v-icon>
                                          </v-btn>
                                          <v-tooltip
                                            top
                                            v-if="
                                              $can(
                                                'institution_user_activate'
                                              ) && item.status == 0
                                            "
                                          >
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <v-btn
                                                icon
                                                class="button mr-1 red--text"
                                                @click="
                                                  activateInstitutionUser(item)
                                                "
                                                :loading="
                                                  activateLoading &&
                                                  institutionUserIndex ==
                                                    item.id
                                                "
                                                v-bind="attrs"
                                                v-on="on"
                                              >
                                                <v-icon small>
                                                  mdi-toggle-switch-off-outline
                                                </v-icon>
                                              </v-btn>
                                            </template>
                                            <span>Approve User</span>
                                          </v-tooltip>
                                          <v-tooltip
                                            top
                                            v-if="
                                              $can(
                                                'institution_user_deactivate'
                                              ) && item.status == 1
                                            "
                                          >
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <v-btn
                                                icon
                                                class="button mr-1 green--text"
                                                @click="
                                                  deactivateInstitutionUser(
                                                    item
                                                  )
                                                "
                                                :loading="
                                                  deactivateLoading &&
                                                  institutionUserIndex ==
                                                    item.id
                                                "
                                                v-bind="attrs"
                                                v-on="on"
                                              >
                                                <v-icon small>
                                                  mdi-toggle-switch
                                                </v-icon>
                                              </v-btn>
                                            </template>
                                            <span>Deactivate User</span>
                                          </v-tooltip>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card>
                          </div>
                          <div class="hidden-md-and-up">
                            <div>
                              <v-layout column>
                                <template
                                  v-for="(user, index) in institutionUsers"
                                >
                                  <div :key="index">
                                    <v-flex xs12 class="mb-2">
                                      <v-card elevation="0">
                                        <div class="pa-2 mt-2">
                                          <v-layout column>
                                            <v-flex xs12>
                                              <v-layout row wrap>
                                                <v-flex xs11>
                                                  <div
                                                    class="title text--text"
                                                  >
                                                    <b
                                                      >{{ user.user.f_name }}
                                                      {{ user.user.m_name }}
                                                      {{ user.user.l_name }}</b
                                                    >
                                                  </div>
                                                </v-flex>
                                                <v-flex xs1>
                                                  <v-btn
                                                    depressed
                                                    class="red--text"
                                                    :loading="
                                                      loading &&
                                                      institutionUserIndex ==
                                                        user.id
                                                    "
                                                    icon
                                                    @click="
                                                      deleteInstitutionUser(
                                                        user
                                                      )
                                                    "
                                                    v-if="
                                                      $can(
                                                        'institution_user_archive'
                                                      )
                                                    "
                                                  >
                                                    <v-icon>
                                                      mdi-delete
                                                    </v-icon>
                                                  </v-btn>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-divider class="mt-2"></v-divider>
                                            <v-flex xs12 class="mt-1">
                                              <v-layout column>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Email Address</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{ user.user.email }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Phone #</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{ user.user.phone }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Code</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{ user.user.code }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Status</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      <div
                                                        v-if="user.status == 1"
                                                      >
                                                        Active
                                                      </div>
                                                      <div v-else>Inactive</div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>

                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="primary text-none white--text mt-3"
                                                          block
                                                          @click="
                                                            showInstitutionUser(
                                                              user.user
                                                            )
                                                          "
                                                          >View
                                                          <v-icon right>
                                                            mdi-eye
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>
                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="green darken-1 text-none white--text mt-1"
                                                          block
                                                          :loading="
                                                            activateLoading &&
                                                            institutionUserIndex ==
                                                              user.id
                                                          "
                                                          @click="
                                                            activateInstitutionUser(
                                                              user
                                                            )
                                                          "
                                                          v-if="
                                                            $can(
                                                              'institution_administrator_activate'
                                                            ) && user.status == 0
                                                          "
                                                          >Approve User
                                                          <v-icon right>
                                                            mdi-toggle-switch
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>
                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="red darken-1 text-none white--text mt-1"
                                                          block
                                                          :loading="
                                                            activateLoading &&
                                                            institutionUserIndex ==
                                                              user.id
                                                          "
                                                          @click="
                                                            deactivateInstitutionUser(
                                                              user
                                                            )
                                                          "
                                                          v-if="
                                                            $can(
                                                              'institution_user_deactivate'
                                                            ) && user.status == 1
                                                          "
                                                          >Reject User
                                                          <v-icon right>
                                                            mdi-toggle-switch-off-outline
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                          </v-layout>
                                        </div>
                                      </v-card>
                                    </v-flex>
                                  </div>
                                </template>
                              </v-layout>
                            </div>
                          </div>
                        </div>
                      </v-flex>
                    </v-card>
                    <v-layout column class="mt-5">
                      <v-flex xs12>
                        <div align="left">
                          <v-pagination
                            v-if="length != 0"
                            :length="institutionUserLength"
                            total-visible="10"
                            v-model="institutionUserPagination.current_page"
                            @input="changeInstitutionUserPage()"
                            circle
                          >
                          </v-pagination>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div
                          v-if="institutionUserLength != 0"
                          align="center"
                          class="mt-5"
                        >
                          <b class="text--text">Total: </b
                          >{{ institutionUserPagination.total | formatNumber }}
                        </div>
                      </v-flex>
                    </v-layout>
                  </div>
                </div>
                <div v-if="activeTab == 'institutionInnovations'">
                  <div v-if="institutionInnovationView == 'default'">
                    <v-card elevation="0" class="mt-5">
                      <v-flex xs12 class="mt-10 mb-2">
                        <div class="mx-5">
                          <v-layout row wrap>
                            <v-flex xs12 md4> </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <v-text-field
                                  class="text_field header"
                                  outlined
                                  dense
                                  label="Search"
                                  append-icon="mdi-undo-variant"
                                  @click:append="
                                    resetInstitutionInnovationSearch()
                                  "
                                  v-on:keyup.enter="institutionInnovationSearch"
                                  v-model="institutionInnovationSearchTerm"
                                  @input="enableInstitutionInnovationSearch()"
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md2>
                              <div class="pa-1">
                                <v-btn
                                  class="secondary primary--text text-none"
                                  depressed
                                  block
                                  @click="institutionInnovationSearch"
                                  :loading="institutionInnovationSearchLoader"
                                  :disabled="
                                    institutionInnovationSearchButtonDisabled
                                  "
                                >
                                  Search
                                  <v-icon right>mdi-magnify</v-icon>
                                </v-btn>
                              </div>
                            </v-flex>
                            <v-flex xs12 md2> </v-flex>
                          </v-layout>
                        </div>
                      </v-flex>
                      <v-divider class="mt-9 mx-5"></v-divider>
                      <v-flex xs12 class="mt-5">
                        <div v-if="institutionInnovations.length == 0">
                          <v-card elevation="0">
                            <v-layout row wrap>
                              <v-flex xs12 md1>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      <v-icon large class="text--text">
                                        mdi-alert-circle-outline
                                      </v-icon>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                              <v-flex xs12 md11>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      No Linked Innovations Found
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </div>
                        <div v-else>
                          <div class="hidden-sm-and-down">
                            <v-card elevation="0" class="pa-2">
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead class="header">
                                    <tr class="tableHeader">
                                      <th class="text-left text--text">Logo</th>
                                      <th class="text-left text--text">Name</th>
                                      <th class="text-left text--text">Establishment Year</th>
                                      <th class="text-left text--text">IP Protection</th>
                                      <th class="text-left text--text">Stage</th>
                                      <th class="text-left text--text">County</th>
                                      <th class="text-left text--text">Status</th>
                                      <th class="text-right text--text">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="item in institutionInnovations"
                                      :key="item.id"
                                    >
                                      <td>
                                        <div class="my-2">
                                          <v-avatar size="48" v-if="item.innovation.featured_image==null">
                                            <v-img
                                              max-height="150"
                                              
                                              src="img/logo_fav.png"
                                            >
                                            </v-img>
                                          </v-avatar>
                                          <v-avatar size="48" v-else>
                                            <v-img
                                                max-height="150"
                                                :src="path+'/storage/innovation_pictures/'+item.innovation.featured_image"
                                              >
                                              </v-img>
                                          </v-avatar>
                                        </div>
                                      </td>
                                      <td>{{ item.innovation.name }}</td>
                                      <td>{{ item.innovation.establishment_year }}</td>
                                      <td>
                                        {{
                                          item.innovation.ip_protection_type
                                            .name
                                        }}
                                      </td>
                                      <td>{{ item.innovation.stage.name }}</td>
                                      <td>{{ item.innovation.county.name }}</td>
                                      <td>
                                        <div v-if="item.status == 1">
                                          Active
                                        </div>
                                        <div v-else>Inactive</div>
                                      </td>
                                      <td>
                                        <div align="right">
                                          <v-btn
                                            icon
                                            @click="
                                              showInstitutionInnovation(
                                                item.innovation
                                              )
                                            "
                                            class="button mr-1 universal--text"
                                          >
                                            <v-icon small> mdi-eye </v-icon>
                                          </v-btn>
                                          <v-btn
                                            v-if="
                                              $can(
                                                'institution_innovation_archive'
                                              )
                                            "
                                            icon
                                            class="button mr-1 error--text"
                                            @click="
                                              deleteInstitutionInnovation(item)
                                            "
                                            :loading="
                                              loading &&
                                              institutionInnovationIndex ==
                                                item.id
                                            "
                                          >
                                            <v-icon small> mdi-delete </v-icon>
                                          </v-btn>
                                          <v-tooltip
                                            top
                                            v-if="
                                              $can(
                                                'institution_innovation_activate'
                                              ) && item.status == 0
                                            "
                                          >
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <v-btn
                                                icon
                                                class="button mr-1 red--text"
                                                @click="
                                                  activateInstitutionInnovation(
                                                    item
                                                  )
                                                "
                                                :loading="
                                                  activateLoading &&
                                                  institutionInnovationIndex ==
                                                    item.id
                                                "
                                                v-bind="attrs"
                                                v-on="on"
                                              >
                                                <v-icon small>
                                                  mdi-toggle-switch-off-outline
                                                </v-icon>
                                              </v-btn>
                                            </template>
                                            <span
                                              >Approve Innovation
                                              Affiliation</span
                                            >
                                          </v-tooltip>
                                          <v-tooltip
                                            top
                                            v-if="
                                              $can(
                                                'institution_innovation_deactivate'
                                              ) && item.status == 1
                                            "
                                          >
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <v-btn
                                                icon
                                                class="button mr-1 green--text"
                                                @click="
                                                  deactivateInstitutionInnovation(
                                                    item
                                                  )
                                                "
                                                :loading="
                                                  deactivateLoading &&
                                                  institutionInnovationIndex ==
                                                    item.id
                                                "
                                                v-bind="attrs"
                                                v-on="on"
                                              >
                                                <v-icon small>
                                                  mdi-toggle-switch
                                                </v-icon>
                                              </v-btn>
                                            </template>
                                            <span>Deactivate Innovation
                                              Affiliation</span>
                                          </v-tooltip>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card>
                          </div>
                          <div class="hidden-md-and-up">
                            <div>
                              <v-layout column>
                                <template
                                  v-for="(
                                    innovation, index
                                  ) in institutionInnovations"
                                >
                                  <div :key="index">
                                    <v-flex xs12 class="mb-2">
                                      <v-card elevation="0">
                                        <div class="pa-2 mt-2">
                                          <v-layout column>
                                            <v-flex xs12>
                                              <v-layout row wrap>
                                                <v-flex xs11>
                                                  <div
                                                    class="title text--text"
                                                  >
                                                    <b>{{
                                                      innovation.innovation.name
                                                    }}</b>
                                                  </div>
                                                </v-flex>
                                                <v-flex xs1>
                                                  <v-btn
                                                    depressed
                                                    class="red--text"
                                                    :loading="
                                                      loading &&
                                                      institutionInnovationIndex ==
                                                        innovation.id
                                                    "
                                                    icon
                                                    @click="
                                                      deleteInstitutionInnovation(
                                                        innovation
                                                      )
                                                    "
                                                    v-if="
                                                      $can(
                                                        'institution_innovation_archive'
                                                      )
                                                    "
                                                  >
                                                    <v-icon>
                                                      mdi-delete
                                                    </v-icon>
                                                  </v-btn>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-divider class="mt-2"></v-divider>
                                            <v-flex xs12 class="mt-1">
                                              <v-layout column>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Establishment Year</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{
                                                        innovation.innovation
                                                          .establishment_year
                                                      }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>IP Protection Type</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{
                                                        innovation.innovation
                                                          .ip_protection_type
                                                          .name
                                                      }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Stage</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{
                                                        innovation.innovation
                                                          .stage.name
                                                      }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>County</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{
                                                        innovation.innovation
                                                          .county.name
                                                      }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Status</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      <div
                                                        v-if="
                                                          innovation.status == 1
                                                        "
                                                      >
                                                        Active
                                                      </div>
                                                      <div v-else>Inactive</div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>

                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="primary text-none white--text mt-3"
                                                          block
                                                          @click="
                                                            showInstitutionInnovation(
                                                              innovation.innovation
                                                            )
                                                          "
                                                          >View
                                                          <v-icon right>
                                                            mdi-eye
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>
                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="green darken-1 text-none white--text mt-1"
                                                          block
                                                          :loading="
                                                            activateLoading &&
                                                            institutionInnovationIndex ==
                                                              innovation.id
                                                          "
                                                          @click="
                                                            activateInstitutionInnovation(
                                                              innovation
                                                            )
                                                          "
                                                          v-if="
                                                            $can(
                                                              'institution_innovation_activate'
                                                            ) && innovation.status == 0
                                                          "
                                                          >Approve Innovation
                                                          Affiliation
                                                          <v-icon right>
                                                            mdi-toggle-switch-off-outline
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>
                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="red darken-1 text-none white--text mt-1"
                                                          block
                                                          :loading="
                                                            deactivateLoading &&
                                                            institutionInnovationIndex ==
                                                              innovation.id
                                                          "
                                                          @click="
                                                            deactivateInstitutionInnovation(
                                                              innovation
                                                            )
                                                          "
                                                          v-if="
                                                            $can(
                                                              'institution_innovation_deactivate'
                                                            ) && innovation.status == 1
                                                          "
                                                          >Deactivate Innovation
                                                            Affiliation
                                                          <v-icon right>
                                                            mdi-toggle-switch-off-outline
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                          </v-layout>
                                        </div>
                                      </v-card>
                                    </v-flex>
                                  </div>
                                </template>
                              </v-layout>
                            </div>
                          </div>
                        </div>
                      </v-flex>
                    </v-card>
                    <v-layout column class="mt-5">
                      <v-flex xs12>
                        <div align="left">
                          <v-pagination
                            v-if="length != 0"
                            :length="institutionInnovationLength"
                            total-visible="10"
                            v-model="institutionInnovationPagination.current_page"
                            @input="changeInstitutionInnovationPage()"
                            circle
                          >
                          </v-pagination>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div
                          v-if="institutionInnovationLength != 0"
                          align="center"
                          class="mt-5"
                        >
                          <b class="text--text">Total: </b
                          >{{ institutionInnovationPagination.total | formatNumber }}
                        </div>
                      </v-flex>
                    </v-layout>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-layout>
    </v-container>
    <logData v-if="logDataModel" v-model="logDataModel" />
  </div>
</template>
<style>
.ck-editor__editable {
  min-height: 200px;
}
</style>
<script>
import Vue from "vue";
import apiCall from "@/utils/api";
import { mapGetters, mapState, mapActions } from "vuex";
import logData from "@/views/accessControl/logData";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadAdapter from '@/uploadAdapter.js';

Vue.use(CKEditor);

export default {
  components: {
    logData
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,

      editor: ClassicEditor,
      editorConfig: {
          extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
              return new uploadAdapter(loader);
          } }],
          language: 'en',
      },

      importDialog: false,
      activeTab: "details",
      activeInstitutionTab: "parent",
      activeOrganisationTab: "parent",
      view: "default",
      childInstitutionAffiliationView: "default",
      parentInstitutionAffiliationView: "default",
      institutionAdministratorView: "default",
      institutionUserView: "default",
      institutionInnovationView: "default",

      childOrganisationAffiliationView: "default",
      parentOrganisationAffiliationView: "default",
      organisationAdministratorView: "default",
      organisationUserView: "default",
      organisationInnovationView: "default",

      contactView: "default",
      loading: false,
      childInstitutionAffiliationLoader: false,
      parentInstitutionAffiliationLoader: false,
      institutionAdministratorLoader: false,
      institutionUserLoader: false,
      institutionInnovationLoader: false,

      childOrganisationAffiliationLoader: false,
      parentOrganisationAffiliationLoader: false,
      organisationAdministratorLoader: false,
      organisationUserLoader: false,
      organisationInnovationLoader: false,

      activateLoading: false,
      deactivateLoading: false,

      valid: true,
      message: "",
      color: "",
      delete: false,
      activate: false,
      confirm: false,
      tab: null,
      institutionAffTab: null,
      institutionTab: null,
      institutionAffiliationIndex: null,
      institutionAdministratorIndex: null,
      institutionUserIndex: null,
      institutionInnovationIndex: null,
      institutionIndex: null,

      organisationAffTab: null,
      organisationTab: null,
      organisationAffiliationIndex: null,
      organisationAdministratorIndex: null,
      organisationUserIndex: null,
      organisationInnovationIndex: null,
      organisationIndex: null,

      snackbar: false,
      inputRules: [(v) => !!v || "Input is required"],

      emailRules: [
        (v) =>
          !v ||
          /^[a-zA-Z0-9\.!#$%&'*+/=?^_~-]+@([a-zA-Z0-9]+\.)+[a-zA-Z]{2,3}$/.test(
            v
          ) ||
          "E-mail must be valid",
        (v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
      ],
      websiteRules: [
        (v) =>
          !v ||
          /^[a-zA-Z0-9\.!#:$%&'*+/=?^_~-]+[a-zA-Z]{2,3}$/.test(v) ||
          "URL must be valid",
        (v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
      ],

      items: [],
      searchUsers: null,

      searchTerm: null,
      childInstitutionAffiliationSearchTerm: null,
      parentInstitutionAffiliationSearchTerm: null,
      institutionAdministratorSearchTerm: null,
      institutionUserSearchTerm: null,
      institutionInnovationSearchTerm: null,

      childOrganisationAffiliationSearchTerm: null,
      parentOrganisationAffiliationSearchTerm: null,
      organisationAdministratorSearchTerm: null,
      organisationUserSearchTerm: null,
      organisationInnovationSearchTerm: null,

      searchLoader: false,
      searchButtonDisabled: false,
      childInstitutionAffiliationSearchButtonDisabled: false,
      parentInstitutionAffiliationSearchButtonDisabled: false,
      institutionAdministratorSearchButtonDisabled: false,
      institutionUserSearchButtonDisabled: false,
      institutionInnovationSearchButtonDisabled: false,

      childOrganisationAffiliationSearchButtonDisabled: false,
      parentOrganisationAffiliationSearchButtonDisabled: false,
      organisationAdministratorSearchButtonDisabled: false,
      organisationUserSearchButtonDisabled: false,
      organisationInnovationSearchButtonDisabled: false,

      filteredInstitutions: false,
      filteredChildInstitutionAffiliations: false,
      filteredParentInstitutionAffiliations: false,
      filteredInstitutionAdministrators: false,
      filteredInstitutionUsers: false,
      filteredInstitutionInnovations: false,

      filteredOrganisations: false,
      filteredChildOrganisationAffiliations: false,
      filteredParentOrganisationAffiliations: false,
      filteredOrganisationAdministrators: false,
      filteredOrganisationUsers: false,
      filteredOrganisationInnovations: false,

      newInstitution: {
        id: null,
        name: null,
        institution_type_id: null,
        county_id: null,
        brief: null,
        physical_address: null,
      },
      
      newContact: {
        id: null,
        institution_id: null,
        contact_person: null,
        email: null,
        email_2: null,
        phone: null,
        phone_2: null,
        web_url: null,
        fb_url: null,
        insta_url: null,
        twitter_url: null,
        visible: null,
      },
      newInstitutionAffiliation: {
        id: null,
        organisation_id: null,
        aff_institution_id: null,
        requesting_organisation_id: null,
      },

      newOrganisationAffiliation: {
        id: null,
        institution_id: null,
        aff_organisation_id: null,
        requesting_institution_id: null,

        organisation_id: null,
        aff_institution_id: null,
        sender: null
      },

      newAdministrator: {
        id: null,
        institution_id: null,
        user_id: null,
      },
      newUser: {
        id: null,
        institution_id: null,
        user_id: null,
      },
      newInnovation: {
        id: null,
        institution_id: null,
        innovation_id: null,
      },

      assignedInstitution: null,
      formData: null,

      coverImageDialog: false,
      imageDialog: false,
      imageLoading: false,
      fileRules: [(v) => !!v || "Kindly selecta a File"],
      imageFile: null,
      imageValid: true,

      searchTerm: null,
      searchLoader: false,
      childInstitutionAffiliationSearchLoader: false,
      parentInstitutionAffiliationSearchLoader: false,
      institutionAdministratorSearchLoader: false,
      institutionUserSearchLoader: false,
      institutionInnovationSearchLoader: false,

      childOrganisationAffiliationSearchLoader: false,
      parentOrganisationAffiliationSearchLoader: false,
      organisationAdministratorSearchLoader: false,
      organisationUserSearchLoader: false,
      organisationInnovationSearchLoader: false,

      searchButtonDisabled: false,

      filteredInstitutions: [],
      childInstitutionAffiliations: [],
      parentInstitutionAffiliations: [],
      institutionAdministrators: [],
      institutionUsers: [],
      institutionInnovations: [],

      filteredOrganisations: [],
      childOrganisationAffiliations: [],
      parentOrganisationAffiliations: [],
      organisationAdministrators: [],
      organisationUsers: [],
      organisationInnovations: [],

      childInstitutionAffiliationPagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },
      parentInstitutionAffiliationPagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },

      childOrganisationAffiliationPagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },
      parentOrganisationAffiliationPagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },

      institutionAdministratorPagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },
      institutionUserPagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },
      institutionInnovationPagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },
      logData: {
        message: null,
        model: null,
      },
    };
  },
  watch: {
    searchUsers(val) {
      val && val !== this.newAdministrator.user_id && this.findUser(val);
    },
  },
  created() {
    if (this.$can("institution_view")) {
      this.startInstitutionLoader();
      this.fetchInstitutions(this.institutionPagination.current_page);
      this.getFormData();
    }
  },
  methods: {
    ...mapActions([
      "fetchInstitutions",
      "startInstitutionLoader",
      "stopInstitutionLoader",
      "filterInstitutions",
      "fetchLevels",
      "startLevelLoader",
      "stopLevelLoader",
      "filterLevels",
      "filterLevel",

      "filterLogData",
      "openLogDataModel",
    ]),
    findUser(v) {
      this.loading = true;
      // Simulated ajax query
      setTimeout(() => {
        apiCall({
          url: "/api/user?type=search&search=" + this.searchUsers,
          method: "GET",
        })
          .then((resp) => {
            this.items = resp.data;
          })
          .catch((error) => {
            this.loading = false;
          });

        this.loading = false;
      }, 3000);
    },
    showInstitutionAffiliation(institution) {
      this.$router.push("/institutions/" + institution.slug);
    },

    showOrganisationAffiliation(organisation) {
      this.$router.push("/organisations/" + organisation.slug);
    },

    showInstitutionAdministrator(user) {
      this.$router.push("/user/" + user.code);
    },
    showInstitutionUser(user) {
      this.$router.push("/user/" + user.code);
    },
    showInstitutionInnovation(innovation) {
      this.$router.push("/innovations/" + innovation.slug);
    },
    enableSearch() {
      this.searchButtonDisabled = false;
    },
    enableChildInstitutionAffiliationSearch() {
      this.childInstitutionAffiliationSearchButtonDisabled = false;
    },
    enableParentInstitutionAffiliationSearch() {
      this.parentInstitutionAffiliationSearchButtonDisabled = false;
    },

    enableChildOrganisationAffiliationSearch() {
      this.childOrganisationAffiliationSearchButtonDisabled = false;
    },
    enableParentOrganisationAffiliationSearch() {
      this.parentOrganisationAffiliationSearchButtonDisabled = false;
    },

    enableInstitutionAdministratorSearch() {
      this.institutionAdministratorSearchButtonDisabled = false;
    },
    enableInstitutionUserSearch() {
      this.institutionUserSearchButtonDisabled = false;
    },
    enableInstitutionInnovationSearch() {
      this.institutionInnovationSearchButtonDisabled = false;
    },
    resetSearch() {
      this.searchTerm = null;
      this.filteredInstitutions = true;
      this.startInstitutionLoader();
      this.fetchInstitutions(1);
    },

    resetChildInstitutionAffiliationSearch() {
      this.childInstitutionAffiliationSearchTerm = null;
      this.filteredChildInstitutionAffiliations = false;
      this.childInstitutionAffiliationLoader = false;
      this.changeChildInstitutionAffiliationPage();
    },

    resetParentInstitutionAffiliationSearch() {
      this.parentInstitutionAffiliationSearchTerm = null;
      this.filteredParentInstitutionAffiliations = false;
      this.parentInstitutionAffiliationLoader = false;
      this.changeParentInstitutionAffiliationPage();
    },

    resetChildOrganisationAffiliationSearch() {
      this.childOrganisationAffiliationSearchTerm = null;
      this.filteredChildOrganisationAffiliations = false;
      this.childOrganisationAffiliationLoader = false;
      this.changeChildOrganisationAffiliationPage();
    },

    resetParentOrganisationAffiliationSearch() {
      this.parentOrganisationAffiliationSearchTerm = null;
      this.filteredParentOrganisationAffiliations = false;
      this.parentOrganisationAffiliationLoader = false;
      this.changeParentOrganisationAffiliationPage();
    },

    resetInstitutionAdministratorSearch() {
      this.institutionAdministratorSearchTerm = null;
      this.filteredInstitutionAdministrators = false;
      this.institutionAdministratorLoader = false;
      this.changeInstitutionAdministratorPage();
    },

    resetInstitutionUserSearch() {
      this.institutionUserSearchTerm = null;
      this.filteredInstitutionUsers = false;
      this.institutionUserLoader = false;
      this.changeInstitutionUserPage();
    },

    resetInstitutionInnovationSearch() {
      this.institutionInnovationSearchTerm = null;
      this.filteredInstitutionInnovations = false;
      this.institutionInnovationLoader = false;
      this.changeInstitutionInnovationPage();
    },

    changeChildInstitutionAffiliationPage() {
      this.childInstitutionAffiliationLoader = true;
      if (this.filteredChildInstitutionAffiliations == true) {
        this.childInstitutionAffiliationSearchButtonDisabled = false;
        apiCall({
          url:
            "/api/inst-inst-affiliation?type=childSearch&institution_id=" +
            this.assignedInstitution.id +
            "&page=" +
            this.childInstitutionAffiliationPagination.current_page +
            "&search=" +
            this.childInstitutionAffiliationSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.childInstitutionAffiliationLoader = false;

            this.childInstitutionAffiliations = resp.data;
            this.childInstitutionAffiliationPagination.current_page =
              resp.current_page;
            this.childInstitutionAffiliationPagination.total = resp.total;
            this.childInstitutionAffiliationPagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.childInstitutionAffiliationLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        apiCall({
          url:
            "/api/inst-inst-affiliation?type=child&institution_id=" +
            this.assignedInstitution.id +
            "&page=" +
            this.childInstitutionAffiliationPagination.current_page,
          method: "GET",
        })
          .then((resp) => {
            this.childInstitutionAffiliationLoader = false;

            this.childInstitutionAffiliations = resp.data;
            this.childInstitutionAffiliationPagination.current_page =
              resp.current_page;
            this.childInstitutionAffiliationPagination.total = resp.total;
            this.childInstitutionAffiliationPagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.childInstitutionAffiliationLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },

    changeParentInstitutionAffiliationPage() {
      this.parentInstitutionAffiliationLoader = true;
      if (this.filteredParentInstitutionAffiliations == true) {
        this.parentInstitutionAffiliationSearchButtonDisabled = false;
        apiCall({
          url:
            "/api/inst-inst-affiliation?type=parentSearch&institution_id=" +
            this.assignedInstitution.id +
            "&page=" +
            this.parentInstitutionAffiliationPagination.current_page +
            "&search=" +
            this.parentInstitutionAffiliationSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.parentInstitutionAffiliationLoader = false;

            this.parentInstitutionAffiliations = resp.data;
            this.parentInstitutionAffiliationPagination.current_page =
              resp.current_page;
            this.parentInstitutionAffiliationPagination.total = resp.total;
            this.parentInstitutionAffiliationPagination.per_page =
              resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.parentInstitutionAffiliationLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        apiCall({
          url:
            "/api/inst-inst-affiliation?type=parent&institution_id=" +
            this.assignedInstitution.id +
            "&page=" +
            this.parentInstitutionAffiliationPagination.current_page,
          method: "GET",
        })
          .then((resp) => {
            this.parentInstitutionAffiliationLoader = false;

            this.parentInstitutionAffiliations = resp.data;
            this.parentInstitutionAffiliationPagination.current_page =
              resp.current_page;
            this.parentInstitutionAffiliationPagination.total = resp.total;
            this.parentInstitutionAffiliationPagination.per_page =
              resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.parentInstitutionAffiliationLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },



    changeChildOrganisationAffiliationPage() {
      this.childOrganisationAffiliationLoader = true;
      if (this.filteredChildOrganisationAffiliations == true) {
        this.childOrganisationAffiliationSearchButtonDisabled = false;
        apiCall({
          url:
            "/api/inst-org-affiliation?type=childSearch&institution_id=" +
            this.assignedInstitution.id +
            "&page=" +
            this.childOrganisationAffiliationPagination.current_page +
            "&search=" +
            this.childOrganisationAffiliationSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.childOrganisationAffiliationLoader = false;

            this.childOrganisationAffiliations = resp.data;
            this.childOrganisationAffiliationPagination.current_page =
              resp.current_page;
            this.childOrganisationAffiliationPagination.total = resp.total;
            this.childOrganisationAffiliationPagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.childOrganisationAffiliationLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        apiCall({
          url:
            "/api/inst-org-affiliation?type=child&institution_id=" +
            this.assignedInstitution.id +
            "&page=" +
            this.childOrganisationAffiliationPagination.current_page,
          method: "GET",
        })
          .then((resp) => {
            this.childOrganisationAffiliationLoader = false;

            this.childOrganisationAffiliations = resp.data;
            this.childOrganisationAffiliationPagination.current_page =
              resp.current_page;
            this.childOrganisationAffiliationPagination.total = resp.total;
            this.childOrganisationAffiliationPagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.childOrganisationAffiliationLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },

    changeParentOrganisationAffiliationPage() {
      this.parentOrganisationAffiliationLoader = true;
      if (this.filteredParentOrganisationAffiliations == true) {
        this.parentOrganisationAffiliationSearchButtonDisabled = false;
        apiCall({
          url:
            "/api/org-inst-affiliation?type=parentSearch&institution_id=" +
            this.assignedInstitution.id +
            "&page=" +
            this.parentOrganisationAffiliationPagination.current_page +
            "&search=" +
            this.parentOrganisationAffiliationSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.parentOrganisationAffiliationLoader = false;

            this.parentOrganisationAffiliations = resp.data;
            this.parentOrganisationAffiliationPagination.current_page =
              resp.current_page;
            this.parentOrganisationAffiliationPagination.total = resp.total;
            this.parentOrganisationAffiliationPagination.per_page =
              resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.parentOrganisationAffiliationLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        apiCall({
          url:
            "/api/org-inst-affiliation?type=parent&institution_id=" +
            this.assignedInstitution.id +
            "&page=" +
            this.parentOrganisationAffiliationPagination.current_page,
          method: "GET",
        })
          .then((resp) => {
            this.parentOrganisationAffiliationLoader = false;

            this.parentOrganisationAffiliations = resp.data;
            this.parentOrganisationAffiliationPagination.current_page =
              resp.current_page;
            this.parentOrganisationAffiliationPagination.total = resp.total;
            this.parentOrganisationAffiliationPagination.per_page =
              resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.parentOrganisationAffiliationLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    changeInstitutionAdministratorPage() {
      this.institutionAdministratorLoader = true;
      if (this.filteredInstitutionAdministrators == true) {
        this.institutionAdministratorSearchButtonDisabled = false;
        apiCall({
          url:
            "/api/institution-administrator?institution_id=" +
            this.assignedInstitution.id +
            "&page=" +
            this.institutionAdministratorPagination.current_page +
            "&type=search&search=" +
            this.institutionAdministratorSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.institutionAdministratorLoader = false;

            this.institutionAdministrators = resp.data;
            this.institutionAdministratorPagination.current_page =
              resp.current_page;
            this.institutionAdministratorPagination.total = resp.total;
            this.institutionAdministratorPagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.institutionAdministratorLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        apiCall({
          url:
            "/api/institution-administrator?institution_id=" +
            this.assignedInstitution.id +
            "&page=" +
            this.institutionAdministratorPagination.current_page,
          method: "GET",
        })
          .then((resp) => {
            this.institutionAdministratorLoader = false;

            this.institutionAdministrators = resp.data;
            this.institutionAdministratorPagination.current_page =
              resp.current_page;
            this.institutionAdministratorPagination.total = resp.total;
            this.institutionAdministratorPagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.institutionAdministratorLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    changeInstitutionUserPage() {
      this.institutionUserLoader = true;
      if (this.filteredInstitutionUsers == true) {
        this.institutionUserSearchButtonDisabled = false;
        apiCall({
          url:
            "/api/institution-user?institution_id=" +
            this.assignedInstitution.id +
            "&page=" +
            this.institutionUserPagination.current_page +
            "&type=search&search=" +
            this.institutionUserSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.institutionUserLoader = false;

            this.institutionUsers = resp.data;
            this.institutionUserPagination.current_page = resp.current_page;
            this.institutionUserPagination.total = resp.total;
            this.institutionUserPagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.institutionUserLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        apiCall({
          url:
            "/api/institution-user?institution_id=" +
            this.assignedInstitution.id +
            "&page=" +
            this.institutionUserPagination.current_page,
          method: "GET",
        })
          .then((resp) => {
            this.institutionUserLoader = false;

            this.institutionUsers = resp.data;
            this.institutionUserPagination.current_page = resp.current_page;
            this.institutionUserPagination.total = resp.total;
            this.institutionUserPagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.institutionUserLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    changeInstitutionInnovationPage() {
      this.institutionInnovationLoader = true;
      if (this.filteredInstitutionInnovations == true) {
        this.institutionInnovationSearchButtonDisabled = false;
        apiCall({
          url:
            "/api/innovation-institution?institution_id=" +
            this.assignedInstitution.id +
            "&page=" +
            this.institutionInnovationPagination.current_page +
            "&type=search&search=" +
            this.institutionInnovationSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.institutionInnovationLoader = false;

            this.institutionInnovations = resp.data;
            this.institutionInnovationPagination.current_page =
              resp.current_page;
            this.institutionInnovationPagination.total = resp.total;
            this.institutionInnovationPagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.institutionInnovationLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        apiCall({
          url:
            "/api/innovation-institution?institution_id=" +
            this.assignedInstitution.id +
            "&page=" +
            this.institutionInnovationPagination.current_page,
          method: "GET",
        })
          .then((resp) => {
            this.institutionInnovationLoader = false;

            this.institutionInnovations = resp.data;
            this.institutionInnovationPagination.current_page =
              resp.current_page;
            this.institutionInnovationPagination.total = resp.total;
            this.institutionInnovationPagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.institutionInnovationLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    getText(item) {
      return (
        item.f_name + " " + item.m_name + " " + item.l_name + " #" + item.code
      );
    },
    showInstitution(institution) {
      this.assignedInstitution = institution;
      this.newContact.institution_id = institution.id;

      this.childInstitutionAffiliationLoader = true;
      this.parentInstitutionAffiliationLoader = true;
      if (this.$can("institution_affiliation_view")) {
        this.changeChildInstitutionAffiliationPage();
        this.changeParentInstitutionAffiliationPage();
        this.changeChildOrganisationAffiliationPage();
        this.changeParentOrganisationAffiliationPage();
      }
      if (this.$can("institution_administrator_view")) {
        this.changeInstitutionAdministratorPage();
      }
      if (this.$can("institution_user_view")) {
        this.changeInstitutionUserPage();
      }
      if (this.$can("institution_innovation_view")) {
        this.changeInstitutionInnovationPage();
      }

      this.changeView("show");
    },

    editInstitution(institution) {
      this.newInstitution.id = institution.id;
      this.newInstitution.name = institution.name;
      this.newInstitution.institution_type_id = institution.institution_type_id;
      this.newInstitution.county_id = institution.county_id;
      this.newInstitution.brief = institution.brief;
      this.newInstitution.description = institution.description;
      this.newInstitution.physical_address = institution.physical_address;

      this.changeView("create");
    },
    editContact(contact) {
      this.newContact.id = contact.id;
      this.newContact.contact_person = contact.contact_person;
      this.newContact.email = contact.email;
      this.newContact.email_2 = contact.email_2;
      this.newContact.phone = contact.phone;
      this.newContact.phone_2 = contact.phone_2;
      this.newContact.web_url = contact.web_url;
      this.newContact.fb_url = contact.fb_url;
      this.newContact.insta_url = contact.insta_url;
      this.newContact.twitter_url = contact.twitter_url;
      this.newContact.visible = contact.visible;
      this.contactView = "create";
    },
    uploadImage() {
      if (this.$refs.imageForm.validate()) {
        this.imageLoading = true;
        let formData = new FormData();

        // files

        formData.append("files", this.imageFile, this.imageFile.name);
        formData.append("id", this.assignedInstitution.id);

        apiCall({
          url: "/api/institution?type=image",
          data: formData,
          method: "POST",
        })
          .then((resp) => {
            this.snackbar = true;
            this.message = "Logo Uploaded Successfully";
            this.color = "success";
            this.fetchInstitutions(this.institutionPagination.current_page);
            this.imageLoading = false;
            this.imageDialog = false;
            this.imageFile = null;
            this.assignedInstitution.logo = resp.logo;
            // this.changeView('default')
          })
          .catch((error) => {
            this.imageLoading = false;
          });
      }
    },
    uploadCoverImage() {
      if (this.$refs.imageForm.validate()) {
        this.imageLoading = true;
        let formData = new FormData();

        // files

        formData.append("files", this.imageFile, this.imageFile.name);
        formData.append("id", this.assignedInstitution.id);

        apiCall({
          url: "/api/institution?type=coverImage",
          data: formData,
          method: "POST",
        })
          .then((resp) => {
            this.snackbar = true;
            this.message = "Cover Image Uploaded Successfully";
            this.color = "success";
            this.fetchInstitutions(this.institutionPagination.current_page);
            this.imageLoading = false;
            this.coverImageDialog = false;
            this.imageFile = null;
            this.assignedInstitution.cover_image = resp.cover_image;
            // this.changeView('default')
          })
          .catch((error) => {
            this.imageLoading = false;
          });
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.newInstitution.id != null) {
          confirm("Are you sure you want to update institution?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/institution/" + this.newInstitution.id,
              data: this.newInstitution,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Institution Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeView("default");
                this.fetchInstitutions(1);
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm("Are you sure you want to create institution?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/institution",
              data: this.newInstitution,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Institution Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeView("default");
                this.fetchInstitutions(1);
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
                this.changeView("default");
                this.fetchInstitutions(1);
              });
          }
        }
      }
    },
    saveContact() {
      if (this.$refs.contactForm.validate()) {
        if (this.newContact.id != null) {
          confirm("Are you sure you want to update contact information?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/institution-contact/" + this.newContact.id,
              data: this.newContact,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Contact Information Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.$nextTick(() => {
                  this.assignedInstitution.institution_contact = resp;
                });

                this.changeContactView("default");

                this.fetchInstitutions(1);
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm("Are you sure you want to create contact information?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/institution-contact",
              data: this.newContact,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Contact Information Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeContactView("default");
                this.assignedInstitution.institution_contact = resp;
                this.fetchInstitutions(1);
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
                this.fetchInstitutions(1);
              });
          }
        }
      }
    },
    saveChildAffliliatedInstitution() {
      if (this.$refs.institutionAffiliationForm.validate()) {
        confirm("Are you sure you want to create institution affiliation?") &&
          (this.confirm = true);
        if (this.confirm) {
          this.loading = true;
          this.newInstitutionAffiliation.institution_id = this.assignedInstitution.id;
          this.newInstitutionAffiliation.requesting_institution_id =
            this.assignedInstitution.id;
          apiCall({
            url: "/api/inst-inst-affiliation",
            data: this.newInstitutionAffiliation,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Institution Affiliation Added Successfully";
              this.color = "success";
              this.loading = false;
              this.snackbar = true;
              this.confirm = false;
              this.newInstitutionAffiliation.aff_institution_id = null;
              this.newInstitutionAffiliation.institution_id = null;
              this.changeChildInstitutionAffiliationView("default");
              this.changeChildInstitutionAffiliationPage();
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.loading = false;
              this.snackbar = true;
              this.changeView("default");
              this.fetchInstitutions(1);
            });
        }
      }
    },
    saveParentAffliliatedInstitution() {
      if (this.$refs.institutionAffiliationForm.validate()) {
        confirm("Are you sure you want to create institution affiliation?") &&
          (this.confirm = true);
        if (this.confirm) {
          this.loading = true;
          this.newInstitutionAffiliation.aff_institution_id = this.assignedInstitution.id;
          this.newInstitutionAffiliation.requesting_institution_id =
            this.assignedInstitution.id;
          apiCall({
            url: "/api/inst-inst-affiliation",
            data: this.newInstitutionAffiliation,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Institution Affiliation Added Successfully";
              this.color = "success";
              this.loading = false;
              this.snackbar = true;
              this.confirm = false;
              this.newInstitutionAffiliation.aff_institution_id = null;
              this.newInstitutionAffiliation.institution_id = null;
              this.changeParentInstitutionAffiliationView("default");
              this.changeParentInstitutionAffiliationPage();
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.loading = false;
              this.snackbar = true;
              this.changeView("default");
              this.fetchInstitutions(1);
            });
        }
      }
    },
    saveChildAffliliatedOrganisation() {
      if (this.$refs.childOrganisationAffiliationForm.validate()) {
        confirm("Are you sure you want to create organisation affiliation?") &&
          (this.confirm = true);
        if (this.confirm) {
          this.loading = true;
          this.newOrganisationAffiliation.institution_id = this.assignedInstitution.id;
          this.newOrganisationAffiliation.requesting_institution_id =
            this.assignedInstitution.id;
          apiCall({
            url: "/api/inst-org-affiliation",
            data: this.newOrganisationAffiliation,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Organisation Affiliation Added Successfully";
              this.color = "success";
              this.loading = false;
              this.snackbar = true;
              this.confirm = false;
              this.newOrganisationAffiliation.aff_organisation_id = null;
              this.newOrganisationAffiliation.organisation_id = null;
              this.changeChildOrganisationAffiliationView("default");
              this.changeChildOrganisationAffiliationPage();
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.loading = false;
              this.snackbar = true;
              this.changeView("default");
              this.fetchOrganisations(1);
            });
        }
      }
    },
    saveParentAffliliatedOrganisation() {
      if (this.$refs.parentOrganisationAffiliationForm.validate()) {
        confirm("Are you sure you want to create organisation affiliation?") &&
          (this.confirm = true);
        if (this.confirm) {
          this.loading = true;
          this.newOrganisationAffiliation.aff_institution_id = this.assignedInstitution.id;
          // this.newOrganisationAffiliation.sender = 'Institution'
          apiCall({
            url: "/api/org-inst-affiliation",
            data: this.newOrganisationAffiliation,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Organisation Affiliation Added Successfully";
              this.color = "success";
              this.loading = false;
              this.snackbar = true;
              this.confirm = false;
              this.newOrganisationAffiliation.aff_organisation_id = null;
              this.newOrganisationAffiliation.organisation_id = null;
              this.changeParentOrganisationAffiliationView("default");
              this.changeParentOrganisationAffiliationPage();
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.loading = false;
              this.snackbar = true;
              this.changeView("default");
              this.fetchOrganisations(1);
            });
        }
      }
    },
    saveInstitutionAdministrator() {
      if (this.$refs.institutionAdministratorForm.validate()) {
        confirm("Are you sure you want to add institution administrator?") &&
          (this.confirm = true);
        if (this.confirm) {
          this.loading = true;
          this.newAdministrator.institution_id = this.assignedInstitution.id;
          apiCall({
            url: "/api/institution-administrator",
            data: this.newAdministrator,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Institution Administrator Added Successfully";
              this.color = "success";
              this.loading = false;
              this.snackbar = true;
              this.confirm = false;
              this.newAdministrator.user_id = null;
              this.changeInstitutionAdministratorView("default");
              this.changeInstitutionAdministratorPage();
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.loading = false;
              this.snackbar = true;
            });
        }
      }
    },

    search() {
      if (this.searchTerm == null) {
        this.searchButtonDisabled = true;
      } else {
        this.searchLoader = true;
        this.searchButtonDisabled = false;
        this.startInstitutionLoader();
        apiCall({
          url: "/api/institution?type=search&search=" + this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterInstitutions(resp);
            this.searchLoader = false;
            this.stopInstitutionLoader();
            this.filteredInstitutions = true;
          })
          .catch((error) => {
            console.log(error);
            this.searchLoader = false;
            this.stopInstitutionLoader();
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    childInstitutionAffiliationSearch() {
      if (this.childInstitutionAffiliationSearchTerm == null) {
        this.childInstitutionAffiliationSearchButtonDisabled = true;
      } else {
        this.childInstitutionAffiliationSearchLoader = true;
        this.childInstitutionAffiliationSearchButtonDisabled = false;
        this.startInstitutionLoader();
        apiCall({
          url:
            "/api/inst-inst-affiliation?type=childSearch&search=" +
            this.childInstitutionAffiliationSearchTerm +
            "&institution_id=" +
            this.assignedInstitution.id,
          method: "GET",
        })
          .then((resp) => {
            this.childInstitutionAffiliations = resp.data;
            this.childInstitutionAffiliationPagination.current_page =
              resp.current_page;
            this.childInstitutionAffiliationPagination.total = resp.total;
            this.childInstitutionAffiliationPagination.per_page = resp.per_page;

            this.childInstitutionAffiliationSearchLoader = false;
            this.filteredChildInstitutionAffiliations = true;
          })
          .catch((error) => {
            this.childInstitutionAffiliationSearchLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            console.log(error);
          });
      }
    },
    parentInstitutionAffiliationSearch() {
      if (this.parentInstitutionAffiliationSearchTerm == null) {
        this.parentInstitutionAffiliationSearchButtonDisabled = true;
      } else {
        this.parentInstitutionAffiliationSearchLoader = true;
        this.parentInstitutionAffiliationSearchButtonDisabled = false;
        this.startInstitutionLoader();
        apiCall({
          url:
            "/api/inst-inst-affiliation?type=parentSearch&search=" +
            this.parentInstitutionAffiliationSearchTerm +
            "&institution_id=" +
            this.assignedInstitution.id,
          method: "GET",
        })
          .then((resp) => {
            this.parentInstitutionAffiliations = resp.data;
            this.parentInstitutionAffiliationPagination.current_page =
              resp.current_page;
            this.parentInstitutionAffiliationPagination.total = resp.total;
            this.parentInstitutionAffiliationPagination.per_page =
              resp.per_page;

            this.parentInstitutionAffiliationSearchLoader = false;
            this.filteredParentInstitutionAffiliations = true;
          })
          .catch((error) => {
            this.parentInstitutionAffiliationSearchLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            console.log(error);
          });
      }
    },
    childOrganisationAffiliationSearch() {
      if (this.childOrganisationAffiliationSearchTerm == null) {
        this.childOrganisationAffiliationSearchButtonDisabled = true;
      } else {
        this.childOrganisationAffiliationSearchLoader = true;
        this.childOrganisationAffiliationSearchButtonDisabled = false;
        // this.startOrganisationLoader();
        apiCall({
          url:
            "/api/inst-org-affiliation?type=childSearch&search=" +
            this.childOrganisationAffiliationSearchTerm +
            "&institution_id=" +
            this.assignedInstitution.id,
          method: "GET",
        })
          .then((resp) => {
            this.childOrganisationAffiliations = resp.data;
            this.childOrganisationAffiliationPagination.current_page =
              resp.current_page;
            this.childOrganisationAffiliationPagination.total = resp.total;
            this.childOrganisationAffiliationPagination.per_page = resp.per_page;

            this.childOrganisationAffiliationSearchLoader = false;
            this.filteredChildOrganisationAffiliations = true;
          })
          .catch((error) => {
            this.childOrganisationAffiliationSearchLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            console.log(error);
          });
      }
    },
    parentOrganisationAffiliationSearch() {
      if (this.parentOrganisationAffiliationSearchTerm == null) {
        this.parentOrganisationAffiliationSearchButtonDisabled = true;
      } else {
        this.parentOrganisationAffiliationSearchLoader = true;
        this.parentOrganisationAffiliationSearchButtonDisabled = false;
        // this.startOrganisationLoader();
        apiCall({
          url:
            "/api/org-inst-affiliation?type=parentSearch&search=" +
            this.parentOrganisationAffiliationSearchTerm +
            "&institution_id=" +
            this.assignedInstitution.id,
          method: "GET",
        })
          .then((resp) => {
            this.parentOrganisationAffiliations = resp.data;
            this.parentOrganisationAffiliationPagination.current_page =
              resp.current_page;
            this.parentOrganisationAffiliationPagination.total = resp.total;
            this.parentOrganisationAffiliationPagination.per_page =
              resp.per_page;

            this.parentOrganisationAffiliationSearchLoader = false;
            this.filteredParentOrganisationAffiliations = true;
          })
          .catch((error) => {
            this.parentOrganisationAffiliationSearchLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            console.log(error);
          });
      }
    },
    institutionAdministratorSearch() {
      if (this.institutionAdministratorSearchTerm == null) {
        this.institutionAdministratorSearchButtonDisabled = true;
      } else {
        this.institutionAdministratorSearchLoader = true;
        this.institutionAdministratorSearchButtonDisabled = false;
        this.startInstitutionLoader();
        apiCall({
          url:
            "/api/institution-administrator?type=search&search=" +
            this.institutionAdministratorSearchTerm +
            "&institution_id=" +
            this.assignedInstitution.id,
          method: "GET",
        })
          .then((resp) => {
            this.institutionAdministrators = resp.data;
            this.institutionAdministratorPagination.current_page =
              resp.current_page;
            this.institutionAdministratorPagination.total = resp.total;
            this.institutionAdministratorPagination.per_page = resp.per_page;

            this.institutionAdministratorSearchLoader = false;
            this.filteredInstitutionAdministrators = true;
          })
          .catch((error) => {
            this.institutionAdministratorSearchLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            console.log(error);
          });
      }
    },
    institutionUserSearch() {
      if (this.institutionUserSearchTerm == null) {
        this.institutionUserSearchButtonDisabled = true;
      } else {
        this.institutionUserSearchLoader = true;
        this.institutionUserSearchButtonDisabled = false;
        this.startInstitutionLoader();
        apiCall({
          url:
            "/api/institution-user?type=search&search=" +
            this.institutionUserSearchTerm +
            "&institution_id=" +
            this.assignedInstitution.id,
          method: "GET",
        })
          .then((resp) => {
            this.institutionUsers = resp.data;
            this.institutionUserPagination.current_page = resp.current_page;
            this.institutionUserPagination.total = resp.total;
            this.institutionUserPagination.per_page = resp.per_page;

            this.institutionUserSearchLoader = false;
            this.filteredInstitutionUsers = true;
          })
          .catch((error) => {
            this.institutionUserSearchLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            console.log(error);
          });
      }
    },
    institutionInnovationSearch() {
      if (this.institutionInnovationSearchTerm == null) {
        this.institutionInnovationSearchButtonDisabled = true;
      } else {
        this.institutionInnovationSearchLoader = true;
        this.institutionInnovationSearchButtonDisabled = false;
        this.startInstitutionLoader();
        apiCall({
          url:
            "/api/innovation-institution?type=search&search=" +
            this.institutionInnovationSearchTerm +
            "&institution_id=" +
            this.assignedInstitution.id,
          method: "GET",
        })
          .then((resp) => {
            this.institutionInnovations = resp.data;
            this.institutionInnovationPagination.current_page = resp.current_page;
            this.institutionInnovationPagination.total = resp.total;
            this.institutionInnovationPagination.per_page = resp.per_page;

            this.institutionInnovationSearchLoader = false;
            this.filteredInstitutionInnovations = true;
          })
          .catch((error) => {
            this.institutionInnovationSearchLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            console.log(error);
          });
      }
    },
    activateInstitution(item) {
      confirm("Are You Sure You Want to Activate Institution?") &&
        (this.activate = true);
      if (this.activate) {
        this.activateLoading = true;
        this.institutionIndex = item.id;
        this.newInstitution.id = item.id;
        apiCall({
          url: "/api/institution?type=activate",
          data: this.newInstitution,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Institution Account Activated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.institutionIndex = null;
            this.fetchInstitutions(this.institutionPagination.current_page);
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.institutionIndex = null;
            console.log(error);
          });
      }
    },
    deactivateInstitution(item) {
      confirm("Are You Sure You Want to Deactivate Institution?") &&
        (this.activate = true);
      if (this.activate) {
        this.deactivateLoading = true;
        this.institutionIndex = item.id;
        this.newInstitution.id = item.id;
        apiCall({
          url: "/api/institution?type=deactivate",
          data: this.newInstitution,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Institution Account Deactivated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.institutionIndex = null;
            this.fetchInstitutions(this.institutionPagination.current_page);
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.institutionIndex = null;
            console.log(error);
          });
      }
    },
    activateInstitutionAffiliation(item) {
      confirm("Are You Sure You Want to Activate Institution Affiliation?") &&
        (this.activate = true);
      if (this.activate) {
        this.activateLoading = true;
        this.institutionAffiliationIndex = item.id;
        this.newInstitutionAffiliation.id = item.id;
        this.newInstitutionAffiliation.institution_id = this.assignedInstitution.id;
        apiCall({
          url: "/api/inst-inst-affiliation?type=activate",
          data: this.newInstitutionAffiliation,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Institution Affiliation Activated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.institutionAffiliationIndex = null;
            this.changeChildInstitutionAffiliationPage();
            this.changeParentInstitutionAffiliationPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.InstitutionIndex = null;
            console.log(error);
          });
      }
    },
    activateOrganisationAffiliation(item) {
      confirm("Are You Sure You Want to Activate Organisation Affiliation?") &&
        (this.activate = true);
      if (this.activate) {
        this.activateLoading = true;
        this.organisationAffiliationIndex = item.id;
        this.newOrganisationAffiliation.id = item.id;
        this.newOrganisationAffiliation.institution_id = this.assignedInstitution.id;
        this.newOrganisationAffiliation.sender = 'Institution';
        apiCall({
          url: "/api/org-inst-affiliation?type=activate",
          data: this.newOrganisationAffiliation,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Organisation Affiliation Activated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.organisationAffiliationIndex = null;
            this.changeChildOrganisationAffiliationPage();
            this.changeParentOrganisationAffiliationPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.organisationIndex = null;
            console.log(error);
          });
      }
    },
    activateChildOrganisationAffiliation(item) {
      confirm("Are You Sure You Want to Activate Child Organisation Affiliation?") &&
        (this.activate = true);
      if (this.activate) {
        this.activateLoading = true;
        this.organisationAffiliationIndex = item.id;
        this.newOrganisationAffiliation.id = item.id;
        this.newOrganisationAffiliation.institution_id = this.assignedInstitution.id;
        this.newOrganisationAffiliation.sender = 'Institution';
        apiCall({
          url: "/api/inst-org-affiliation?type=activate",
          data: this.newOrganisationAffiliation,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Child Organisation Affiliation Activated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.organisationAffiliationIndex = null;
            this.changeChildOrganisationAffiliationPage();
            this.changeParentOrganisationAffiliationPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.organisationIndex = null;
            console.log(error);
          });
      }
    },
    activateInstitutionAdministrator(item) {
      confirm("Are You Sure You Want to Activate Institution Administrator?") &&
        (this.activate = true);
      if (this.activate) {
        this.activateLoading = true;
        this.institutionAdministratorIndex = item.id;
        this.newAdministrator.id = item.id;
        apiCall({
          url: "/api/institution-administrator?type=activate",
          data: this.newAdministrator,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Institution Administrator Activated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.institutionAdministratorIndex = null;
            this.changeInstitutionAdministratorPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.institutionAdministratorIndex = null;
            console.log(error);
          });
      }
    },
    activateInstitutionUser(item) {
      confirm("Are You Sure You Want to Activate User Affiliation?") &&
        (this.activate = true);
      if (this.activate) {
        this.activateLoading = true;
        this.institutionUserIndex = item.id;
        this.newUser.id = item.id;
        apiCall({
          url: "/api/institution-user?type=activate",
          data: this.newUser,
          method: "POST",
        })
          .then((resp) => {
            this.message = "User Affiliation Activated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.institutionUserIndex = null;
            this.changeInstitutionUserPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.institutionUserIndex = null;
            console.log(error);
          });
      }
    },
    activateInstitutionInnovation(item) {
      confirm("Are You Sure You Want to Activate Institution Affiliation?") &&
        (this.activate = true);
      if (this.activate) {
        this.activateLoading = true;
        this.institutionInnovationIndex = item.id;
        this.newInnovation.id = item.id;
        apiCall({
          url: "/api/innovation-institution?type=activate",
          data: this.newInnovation,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Innovation Affiliation Activated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.institutionInnovationIndex = null;
            this.changeInstitutionInnovationPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.institutionInnovationIndex = null;
            console.log(error);
          });
      }
    },
    deactivateParentOrganisationAffiliation(item) {
      confirm("Are You Sure You Want to Deactivate Organisation Affiliation?") &&
        (this.deactivate = true);
      if (this.deactivate) {
        this.deactivateLoading = true;
        this.organisationAffiliationIndex = item.id;
        this.newOrganisationAffiliation.id = item.id;
        this.newOrganisationAffiliation.institution_id = this.assignedInstitution.id;
        this.newOrganisationAffiliation.sender = 'Institution';
        apiCall({
          url: "/api/org-inst-affiliation?type=deactivate",
          data: this.newOrganisationAffiliation,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Organisation Affiliation Deactivated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.deactivate = false;
            this.organisationAffiliationIndex = null;
            this.changeChildOrganisationAffiliationPage();
            this.changeParentOrganisationAffiliationPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.deactivate = false;
            this.organisationIndex = null;
            console.log(error);
          });
      }
    },

    deactivateInstitutionAffiliation(item) {
      confirm("Are You Sure You Want to Revoke Institution Affiliation?") &&
        (this.activate = true);
      if (this.activate) {
        this.deactivateLoading = true;
        this.institutionAffiliationIndex = item.id;
        this.newInstitutionAffiliation.id = item.id;
        this.newInstitutionAffiliation.institution_id = this.assignedInstitution.id;
        apiCall({
          url: "/api/inst-inst-affiliation?type=deactivate",
          data: this.newInstitutionAffiliation,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Institution Affiliation Revoked Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.institutionAffiliationIndex = null;
            this.changeChildInstitutionAffiliationPage();
            this.changeParentInstitutionAffiliationPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.InstitutionIndex = null;
            console.log(error);
          });
      }
    },
    deactivateOrganisationAffiliation(item) {
      confirm("Are You Sure You Want to Revoke Organisation Affiliation?") &&
        (this.activate = true);
      if (this.activate) {
        this.deactivateLoading = true;
        this.organisationAffiliationIndex = item.id;
        this.newOrganisationAffiliation.id = item.id;
        this.newOrganisationAffiliation.institution_id = this.assignedInstitution.id;
        apiCall({
          url: "/api/inst-org-affiliation?type=deactivate",
          data: this.newOrganisationAffiliation,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Organisation Affiliation Revoked Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.organisationAffiliationIndex = null;
            this.changeChildOrganisationAffiliationPage();
            this.changeParentOrganisationAffiliationPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.organisationIndex = null;
            console.log(error);
          });
      }
    },



    deactivateInstitutionAdministrator(item) {
      confirm(
        "Are You Sure You Want to Deactivate Institution Administrator?"
      ) && (this.activate = true);
      if (this.activate) {
        this.deactivateLoading = true;
        this.institutionAdministratorIndex = item.id;
        this.newAdministrator.id = item.id;
        apiCall({
          url: "/api/institution-administrator?type=deactivate",
          data: this.newAdministrator,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Institution Administrator Deactivated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.institutionAdministratorIndex = null;
            this.changeInstitutionAdministratorPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.institutionAdministratorIndex = null;
            console.log(error);
          });
      }
    },
    deactivateInstitutionUser(item) {
      confirm("Are You Sure You Want to Deactivate User Affiliation?") &&
        (this.activate = true);
      if (this.activate) {
        this.deactivateLoading = true;
        this.institutionUserIndex = item.id;
        this.newUser.id = item.id;
        apiCall({
          url: "/api/institution-user?type=deactivate",
          data: this.newUser,
          method: "POST",
        })
          .then((resp) => {
            this.message = "User Affiliation Deactivated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.institutionUserIndex = null;
            this.changeInstitutionUserPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.institutionUserIndex = null;
            console.log(error);
          });
      }
    },
    deactivateInstitutionInnovation(item) {
      confirm("Are You Sure You Want to Deactivate Institution Affiliation?") &&
        (this.activate = true);
      if (this.activate) {
        this.deactivateLoading = true;
        this.institutionInnovationIndex = item.id;
        this.newInnovation.id = item.id;
        apiCall({
          url: "/api/innovation-institution?type=deactivate",
          data: this.newInnovation,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Innovation Affiliation Deactivated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.institutionInnovationIndex = null;
            this.changeInstitutionInnovationPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.institutionInnovationIndex = null;
            console.log(error);
          });
      }
    },
    changeView(view) {
      if (view == "default") {
        this.newInstitution.id = null;
        this.newInstitution.name = null;
        this.newInstitution.institution_type_id = null;
        this.newInstitution.county_id = null;
        this.newInstitution.brief = null;
        this.newInstitution.physical_address = null;
      } else if (view == "create") {
        this.getFormData();
      } else if (view == "show") {
      }
      this.view = view;
    },

    changeContactView(view) {
      if (view == "default") {
        this.newContact.id = null;
        this.newContact.telephone = null;
        this.newContact.mobile = null;
        this.newContact.email = null;
        this.newContact.website = null;
      } else if (view == "create") {
        //this.getFormData();
      } else if (view == "show") {
      }
      this.contactView = view;
    },
    changeChildInstitutionAffiliationView(view) {
      if (view == "default") {
        this.newInstitutionAffiliation.id = null;
        this.newInstitutionAffiliation.institution_id = null;
        this.newInstitutionAffiliation.aff_institution_id = null;
      } else if (view == "create") {
      } else if (view == "show") {
      }
      this.childInstitutionAffiliationView = view;
    },
    changeParentInstitutionAffiliationView(view) {
      if (view == "default") {
        this.newInstitutionAffiliation.id = null;
        this.newInstitutionAffiliation.institution_id = null;
        this.newInstitutionAffiliation.aff_institution_id = null;
      } else if (view == "create") {
      } else if (view == "show") {
      }
      this.parentInstitutionAffiliationView = view;
    },


    changeChildOrganisationAffiliationView(view) {
      if (view == "default") {
        this.newOrganisationAffiliation.id = null;
        this.newOrganisationAffiliation.organisation_id = null;
        this.newOrganisationAffiliation.aff_organisation_id = null;
      } else if (view == "create") {
      } else if (view == "show") {
      }
      this.childOrganisationAffiliationView = view;
    },
    changeParentOrganisationAffiliationView(view) {
      if (view == "default") {
        this.newOrganisationAffiliation.id = null;
        this.newOrganisationAffiliation.organisation_id = null;
        this.newOrganisationAffiliation.aff_organisation_id = null;
      } else if (view == "create") {
      } else if (view == "show") {
      }
      this.parentOrganisationAffiliationView = view;
    },



    changeInstitutionAdministratorView(view) {
      if (view == "default") {
        this.newAdministrator.id = null;
        this.newAdministrator.institution_id = null;
        this.newAdministrator.user_id = null;
      } else if (view == "create") {
      } else if (view == "show") {
      }
      this.institutionAdministratorView = view;
    },
    changeInstitutionUserView(view) {
      if (view == "default") {
        this.newUser.id = null;
        this.newUser.institution_id = null;
        this.newUser.user_id = null;
      } else if (view == "create") {
      } else if (view == "show") {
      }
      this.institutionUserView = view;
    },
    changePage() {
      this.startInstitutionLoader();
      if (this.filteredInstitutions == true) {
        this.searchButtonDisabled = false;
        apiCall({
          url:
            "/api/institution?page=" +
            this.institutionPagination.current_page +
            "&type=search&search=" +
            this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterInstitutions(resp);
            this.stopInstitutionLoader();
          })
          .catch((error) => {
            console.log(error);
            this.stopInstitutionLoader();
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        this.fetchInstitutions(this.institutionPagination.current_page);
      }
    },
    deleteInstitution(item) {
      confirm("Are You Sure You Want to Delete Institution") &&
        (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.institutionIndex = item.id;
        apiCall({ url: "/api/institution/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "Institution Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.institutionIndex = null;
            this.fetchInstitutions(this.institutionPagination.current_page);
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.institutionIndex = null;
            console.log(error);
          });
      }
    },
    deleteContact(item) {
      confirm("Are You Sure You Want to Delete Contact Information?") &&
        (this.delete = true);
      if (this.delete) {
        this.loading = true;
        apiCall({
          url: "/api/institution-contact/" + item.id,
          method: "DELETE",
        })
          .then((resp) => {
            this.message = "Contact Information Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.$nextTick(() => {
              this.assignedInstitution.institution_contact = null;
            });
            this.fetchInstitutions(this.institutionPagination.current_page);
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.institutionIndex = null;
            console.log(error);
          });
      }
    },
    deleteInstitutionAffiliation(item) {
      confirm("Are You Sure You Want to Remove Affiliation") &&
        (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.institutionAffiliationIndex = item.id;
        apiCall({
          url: "/api/inst-inst-affiliation/" + item.id,
          method: "DELETE",
        })
          .then((resp) => {
            this.message = "Institution Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.institutionAffiliationIndex = null;
            this.changeChildInstitutionAffiliationPage();
            this.changeParentInstitutionAffiliationPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.institutionAffiliationIndex = null;
            console.log(error);
          });
      }
    },


    deleteOrganisationAffiliation(item) {
      confirm("Are You Sure You Want to Remove Affiliation") &&
        (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.organisationAffiliationIndex = item.id;
        apiCall({
          url: "/api/inst-org-affiliation/" + item.id,
          method: "DELETE",
        })
          .then((resp) => {
            this.message = "Organisation Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.organisationAffiliationIndex = null;
            this.changeChildOrganisationAffiliationPage();
            this.changeParentOrganisationAffiliationPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.organisationAffiliationIndex = null;
            console.log(error);
          });
      }
    },



    deleteInstitutionAdministrator(item) {
      confirm("Are You Sure You Want to Remove Administrator?") &&
        (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.institutionAdministratorIndex = item.id;
        apiCall({
          url: "/api/institution-administrator/" + item.id,
          method: "DELETE",
        })
          .then((resp) => {
            this.message = "Administrator Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.institutionAdministratorIndex = null;
            this.changeInstitutionAdministratorPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.institutionAdministratorIndex = null;
            console.log(error);
          });
      }
    },
    deleteInstitutionUser(item) {
      confirm("Are You Sure You Want to Remove User?") && (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.institutionUserIndex = item.id;
        apiCall({ url: "/api/institution-user/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "User Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.institutionUserIndex = null;
            this.changeInstitutionUserPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.institutionUserIndex = null;
            console.log(error);
          });
      }
    },
    deleteInstitutionInnovation(item) {
      confirm("Are You Sure You Want to Remove Innovation?") &&
        (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.institutionInnovationIndex = item.id;
        apiCall({
          url: "/api/innovation-institution/" + item.id,
          method: "DELETE",
        })
          .then((resp) => {
            this.message = "Innovation Association Removed Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.institutionInnovationIndex = null;
            this.changeInstitutionInnovationPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.institutionInnovationIndex = null;
            console.log(error);
          });
      }
    },
    getFormData() {
      apiCall({
        url: "/api/institution?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
        })
        .catch((error) => {
          this.message = "An error occurred; Couldn't get form data";
          this.color = "error";
        });
    },
    viewLog(data) {
      this.logData.message = data.id;
      this.logData.model = "App\\Models\\Institution";
      this.filterLogData(this.logData);
      this.openLogDataModel();
    },
  },
  computed: {
    ...mapGetters([
      "institutions",
      "institutionPagination",
      "institutionLoader",
      "logDataModel",
    ]),
    length: function () {
      return Math.ceil(
        this.institutionPagination.total / this.institutionPagination.per_page
      );
    },
    childInstitutionAffiliationLength: function () {
      return Math.ceil(
        this.childInstitutionAffiliationPagination.total /
          this.childInstitutionAffiliationPagination.per_page
      );
    },
    parentInstitutionAffiliationLength: function () {
      return Math.ceil(
        this.parentInstitutionAffiliationPagination.total /
          this.parentInstitutionAffiliationPagination.per_page
      );
    },


    childOrganisationAffiliationLength: function () {
      return Math.ceil(
        this.childOrganisationAffiliationPagination.total /
          this.childOrganisationAffiliationPagination.per_page
      );
    },
    parentOrganisationAffiliationLength: function () {
      return Math.ceil(
        this.parentOrganisationAffiliationPagination.total /
          this.parentOrganisationAffiliationPagination.per_page
      );
    },


    institutionAdministratorLength: function () {
      return Math.ceil(
        this.institutionAdministratorPagination.total /
          this.institutionAdministratorPagination.per_page
      );
    },
    institutionUserLength: function () {
      return Math.ceil(
        this.institutionUserPagination.total /
          this.institutionUserPagination.per_page
      );
    },
    institutionInnovationLength: function () {
      return Math.ceil(
        this.institutionInnovationPagination.total /
          this.institutionInnovationPagination.per_page
      );
    },
    dateToday() {
      var today = new Date();

      var timestamp =
        today.getFullYear() +
        "-" +
        this.months[today.getMonth()] +
        "-" +
        ("0" + today.getDate()).slice(-2);
      return timestamp;
    },
  },
};
</script>
