import apiCall from '@/utils/api'

const state = {
	openEvents: [],
	searchEvents: [],
	closedEvents: [],
	publicEvents: [],
    openEventPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	searchEventPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	closedEventPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	publicEventPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},

    openEventLoader: false,
	closedEventLoader: false,
	publicEventLoader: false,
	searchEventLoader: false,
};

const getters = {
	openEvents: (state) => state.openEvents,
	closedEvents: (state) => state.closedEvents,
	publicEvents: (state) => state.publicEvents,

	searchEvents: (state) => state.searchEvents,
	searchEventLoader: (state) => state.searchEventLoader,	
    searchEventPagination: (state) => state.searchEventPagination,

    openEventLoader: (state) => state.openEventLoader,
	closedEventLoader: (state) => state.closedEventLoader,
	publicEventLoader: (state) => state.publicEventLoader,


    openEventPagination: (state) => state.openEventPagination,
	closedEventPagination: (state) => state.closedEventPagination,
	publicEventPagination: (state) => state.publicEventPagination,
}

const actions = {
    async fetchOpenEvents({commit}, page) {
		const response = await apiCall({url: `/api/event?type=open&page=${page}`, method: 'GET' });
		commit('setOpenEvents', response)
        commit('stopOpenEventLoader', response)
	},
	async fetchClosedEvents({commit}, page) {
		const response = await apiCall({url: `/api/event?type=closed&page=${page}`, method: 'GET' });
		commit('setClosedEvents', response)
        commit('stopClosedEventLoader', response)
	},
	async fetchSearchEvents({commit}, page) {
		const response = await apiCall({url: `/api/event?page=${page}`, method: 'GET' });
		commit('setSearchEvents', response)
        commit('stopSearchEventLoader', response)
	},
	async fetchPublicEvents({commit}, page) {
		const response = await apiCall({url: `/api/event?type=public&page=${page}`, method: 'GET' });
		commit('setPublicEvents', response)
        commit('stopPublicEventLoader', response)
	},
    async filterOpenEvents({commit, state},resp){
		commit('setOpenEvents', resp)
	},
	async filterClosedEvents({commit, state},resp){
		commit('setClosedEvents', resp)
	},
	async filterPublicEvents({commit, state},resp){
		commit('setPublicEvents', resp)
	},
	async filterSearchEvents({commit, state},resp){
		commit('setSearchEvents', resp)
	},
	async startOpenEventLoader({commit, state},resp){
		commit('startOpenEventLoader', resp)
	},
	async startClosedEventLoader({commit, state},resp){
		commit('startClosedEventLoader', resp)
	},
	async startPublicEventLoader({commit, state},resp){
		commit('startPublicEventLoader', resp)
	},
	async stopOpenEventLoader({commit, state},resp){
		commit('stopOpenEventLoader', resp)
	},
	async stopClosedEventLoader({commit, state},resp){
		commit('stopClosedEventLoader', resp)
	},
	async stopPublicEventLoader({commit, state},resp){
		commit('stopPublicEventLoader', resp)
	},
	async startSearchEventLoader({commit, state},resp){
		commit('startSearchEventLoader', resp)
	},
	async stopSearchEventLoader({commit, state},resp){
		commit('stopSearchEventLoader', resp)
	},
};

const mutations = {
	setOpenEvents: (state, openEvents) => {
		state.openEvents = openEvents.data
		state.openEventPagination.current_page = openEvents.current_page
		state.openEventPagination.total = openEvents.total
		state.openEventPagination.per_page = openEvents.per_page
	},
	setClosedEvents: (state, closedEvents) => {
		state.closedEvents = closedEvents.data
		state.closedEventPagination.current_page = closedEvents.current_page
		state.closedEventPagination.total = closedEvents.total
		state.closedEventPagination.per_page = closedEvents.per_page
	},
	setPublicEvents: (state, publicEvents) => {
		state.publicEvents = publicEvents.data
		state.publicEventPagination.current_page = publicEvents.current_page
		state.publicEventPagination.total = publicEvents.total
		state.publicEventPagination.per_page = publicEvents.per_page
	},
	setSearchEvents: (state, events) => {
		state.searchEvents = events.data
		state.searchEventPagination.current_page = events.current_page
		state.searchEventPagination.total = events.total
		state.searchEventPagination.per_page = events.per_page
	},
    startSearchEventLoader: (state) => state.searchEventLoader = true,
	stopSearchEventLoader: (state) => state.searchEventLoader = false,

    startOpenEventLoader: (state) => state.openEventLoader = true,
	stopOpenEventLoader: (state) => state.openEventLoader = false,
	startClosedEventLoader: (state) => state.closedEventLoader = true,
	stopClosedEventLoader: (state) => state.closedEventLoader = false,
	startPublicEventLoader: (state) => state.publicEventLoader = true,
	stopPublicEventLoader: (state) => state.publicEventLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
