import apiCall from '@/utils/api'

const state = {
	openContacts: [],
	closedContacts: [],
    openContactPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	closedContactPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    openContactLoader: false,
	closedContactLoader: false,
};

const getters = {
	openContacts: (state) => state.openContacts,
	closedContacts: (state) => state.closedContacts,
    openContactLoader: (state) => state.openContactLoader,
	closedContactLoader: (state) => state.closedContactLoader,
    openContactPagination: (state) => state.openContactPagination,
	closedContactPagination: (state) => state.closedContactPagination,
}

const actions = {
    async fetchOpenContacts({commit}, page) {
		const response = await apiCall({url: `/api/contact-us?type=open&page=${page}`, method: 'GET' });
		commit('setOpenContacts', response)
        commit('stopOpenContactLoader', response)
	},
	async fetchClosedContacts({commit}, page) {
		const response = await apiCall({url: `/api/contact-us?type=closed&page=${page}`, method: 'GET' });
		commit('setClosedContacts', response)
        commit('stopClosedContactLoader', response)
	},
    async filterOpenContacts({commit, state},resp){
		commit('setOpenContacts', resp)
	},
	async filterClosedContacts({commit, state},resp){
		commit('setClosedContacts', resp)
	},
	async startOpenContactLoader({commit, state},resp){
		commit('startOpenContactLoader', resp)
	},
	async startClosedContactLoader({commit, state},resp){
		commit('startClosedContactLoader', resp)
	},
	async stopOpenContactLoader({commit, state},resp){
		commit('stopOpenContactLoader', resp)
	},
	async stopClosedContactLoader({commit, state},resp){
		commit('stopClosedContactLoader', resp)
	},
};

const mutations = {
	setOpenContacts: (state, openContacts) => {
		state.openContacts = openContacts.data
		state.openContactPagination.current_page = openContacts.current_page
		state.openContactPagination.total = openContacts.total
		state.openContactPagination.per_page = openContacts.per_page
	},
	setClosedContacts: (state, closedContacts) => {
		state.closedContacts = closedContacts.data
		state.closedContactPagination.current_page = closedContacts.current_page
		state.closedContactPagination.total = closedContacts.total
		state.closedContactPagination.per_page = closedContacts.per_page
	},
    startOpenContactLoader: (state) => state.openContactLoader = true,
	stopOpenContactLoader: (state) => state.openContactLoader = false,
	startClosedContactLoader: (state) => state.closedContactLoader = true,
	stopClosedContactLoader: (state) => state.closedContactLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
