import apiCall from '../../utils/api'

const state = {
	innovationCardShow: false
};

const getters = {
	innovationCardState: (state) => state.innovationCardShow,
};

const actions = {
	async changeInnovationCardState({commit}) {
		commit('updateInnovationCard')
	}
};

const mutations = {
	updateInnovationCard: (state) => (state.innovationCardShow = !state.innovationCardShow)
};

export default {
	state,
	getters,
	actions,
	mutations
}