<template>
    <div class="welcome">
      <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
        <span>{{ message }}</span>
      </v-snackbar>
      <v-layout column>
        <v-flex xs12 class="mb-5">
          <v-container>
            <v-card outlined style="border-radius: 25px" class="mt-5">
              <v-card-text>
                <v-layout column>
                  <v-flex xs12>
                    <v-layout row wrap class="my-5">
                      <v-flex xs12 md3>
                        <div align="center" class="mt-2 mx-2">
                          <v-img v-if="publicEso.cover_image == null" src="img/publicEso.png" height="200px" style="z-index: 2;">                              
                            <v-avatar
                              size="86"
                              style="position: absolute; top: 110px; left: 3px; z-index: 10"
                            >
                              <v-img
                                v-if="publicEso.logo==null"
                                src="img/publicEso.png"
                              >
                              </v-img>
                              <v-img
                                v-else
                                :src="path+'/storage/eso_pictures/'+publicEso.logo"
                              >
                              </v-img>
                            </v-avatar>
                          </v-img>
                          <v-img v-else :src="path+'/storage/eso_pictures/'+publicEso.cover_image" height="200px" style="z-index: 2; border-radius: 25px;">                              
                            <v-avatar
                              size="86"
                              style="position: absolute; top: 110px; left: 3px; z-index: 10"
                            >
                              <v-img
                                v-if="publicEso.logo==null"
                                src="img/publicEso.png"
                              >
                              </v-img>
                              <v-img
                                v-else
                                :src="path+'/storage/eso_pictures/'+publicEso.logo"
                              >
                              </v-img>
                            </v-avatar>
                          </v-img>
                        </div>
                      </v-flex>
                      <v-flex xs12 md5>
                        <div class="mx-3">
                          <v-layout column>
                            <v-flex xs12>
                              <div class="title" style="font-family: 'poppins' !important;">{{ publicEso.name }}</div>
                            </v-flex>
                            <v-flex xs12>
                              <div> 
                                <b v-if="publicEso.county_id != null">{{ publicEso.county.name }} - </b> {{ publicEso.eso_type.name }}
                              </div>
                              <div>
                                <i>Established {{ publicEso.establishment_year }} • Joined {{ publicEso.created_at | moment("YYYY") }}</i>
                              </div>
                            </v-flex>
                            <v-flex xs12>
                              <div class="mt-5">{{ publicEso.brief }}</div>
                            </v-flex>
                          </v-layout>
                        </div>
                        
                      </v-flex>
                      <v-flex xs12 md4>                        
                        <div
                          class="title mt-2 mx-3"
                          v-if="publicEso.eso_contact != null"
                        >
                          Get in touch
                        </div>
                        
                        <div
                          v-if="publicEso.eso_contact != null"
                          align="center"
                        >
                          <a
                            v-if="publicEso.eso_contact.fb_url != null"
                            :href="publicEso.eso_contact.fb_url"
                            target="_blank"
                            style="text-decoration: none"
                          >
                            <v-btn class="mx-2 text--text" icon>
                              <v-icon size="30px" color="text">
                                mdi-facebook
                              </v-icon>
                            </v-btn>
                          </a>
                          <a
                            v-if="
                              publicEso.eso_contact.twitter_url != null
                            "
                            :href="publicEso.eso_contact.twitter_url"
                            target="_blank"
                            style="text-decoration: none"
                          >
                            <v-btn class="mx-2 text--text" icon>
                              <v-icon size="30px" color="text">
                                mdi-twitter
                              </v-icon>
                            </v-btn>
                          </a>
                          <a
                            v-if="
                              publicEso.eso_contact.linkedin_url != null
                            "
                            :href="publicEso.eso_contact.linkedin_url"
                            target="_blank"
                            style="text-decoration: none"
                          >
                            <v-btn class="mx-2 text--text" icon>
                              <v-icon size="30px" color="text">
                                mdi-linkedin
                              </v-icon>
                            </v-btn>
                          </a>
                          <a
                            v-if="publicEso.eso_contact.insta_url != null"
                            :href="publicEso.eso_contact.insta_url"
                            target="_blank"
                            style="text-decoration: none"
                          >
                            <v-btn class="mx-2 text--text" icon>
                              <v-icon size="30px" color="text">
                                mdi-instagram
                              </v-icon>
                            </v-btn>
                          </a>
                          <a
                            v-if="publicEso.eso_contact.email != null"
                            :href="
                              'mailto:' + publicEso.eso_contact.email
                            "
                            target="_blank"
                            style="text-decoration: none"
                          >
                            <v-btn class="mx-2 text--text" icon>
                              <v-icon size="30px" color="text">
                                mdi-at
                              </v-icon>
                            </v-btn>
                          </a>
                          <a
                            v-if="publicEso.eso_contact.phone != null"
                            :href="'tel:' + publicEso.eso_contact.phone"
                            target="_blank"
                            style="text-decoration: none"
                          >
                            <v-btn class="mx-2 text--text" icon>
                              <v-icon size="30px" color="text">
                                mdi-phone
                              </v-icon>
                            </v-btn>
                          </a>
                          <a
                            v-if="publicEso.eso_contact.web_url != null"
                            :href="publicEso.eso_contact.web_url"
                            target="_blank"
                            style="text-decoration: none"
                          >
                            <v-btn class="mx-2 text--text" icon>
                              <v-icon size="30px" color="text">
                                mdi-web
                              </v-icon>
                            </v-btn>
                          </a>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 class="my-3">
                    <div class="title" style="font-family: 'poppins' !important;">Share Via</div>
                    <div class="mt-1">
                      <v-btn
                        small
                        rounded
                        depressed
                        class="text-none white--text mr-1 mb-1"
                        color="#3b5998"
                      >
                        <v-icon>mdi-facebook</v-icon>
                        <ShareNetwork
                          style="text-decoration: none"
                          class="white--text ml-2"
                          network="facebook"
                          :url="shareLink"
                          :title="publicEso.name"
                          :description="publicEso.brief"
                          hashtags="InnovationBridge"
                        >
                          <span>Facebook</span>
                        </ShareNetwork>
                      </v-btn>
                      <v-btn
                        small
                        rounded
                        depressed
                        class="text-none white--text mr-1 mb-1"
                        color="#1DA1F2"
                      >
                        <v-icon>mdi-twitter</v-icon>
                        <ShareNetwork
                          style="text-decoration: none"
                          class="white--text ml-2"
                          network="twitter"
                          :url="shareLink"
                          :title="publicEso.name"
                          :description="publicEso.brief"
                          hashtags="InnovationBridge"
                        >
                          <span>Twitter</span>
                        </ShareNetwork>
                      </v-btn>
  
                      <v-btn
                        small
                        rounded
                        depressed
                        class="text-none white--text mr-1 mb-1"
                        color="#0e76a8"
                      >
                        <v-icon>mdi-linkedin</v-icon>
                        <ShareNetwork
                          style="text-decoration: none"
                          class="white--text ml-2"
                          network="linkedin"
                          :url="shareLink"
                          :title="publicEso.name"
                          :description="publicEso.brief"
                          hashtags="InnovationBridge"
                        >
                          <span>LinkedIn</span>
                        </ShareNetwork>
                      </v-btn>
                      <v-btn
                        small
                        rounded
                        depressed
                        class="text-none white--text mr-1 mb-1"
                        color="#075e54"
                      >
                        <v-icon>mdi-whatsapp</v-icon>
                        <ShareNetwork
                          style="text-decoration: none"
                          class="white--text ml-2"
                          network="whatsapp"
                          :url="shareLink"
                          :title="publicEso.name"
                          :description="publicEso.brief"
                          hashtags="InnovationBridge"
                        >
                          <span>Whatsapp</span>
                        </ShareNetwork>
                      </v-btn>
                      <v-btn
                        small
                        rounded
                        depressed
                        class="text-none white--text mr-1 mb-1"
                        color="#0088cc"
                      >
                        <v-icon>mdi-send mdi-rotate-315</v-icon>
                        <ShareNetwork
                          style="text-decoration: none"
                          class="white--text ml-2"
                          network="telegram"
                          :url="shareLink"
                          :title="publicEso.name"
                          :description="publicEso.brief"
                          hashtags="InnovationBridge"
                        >
                          <span>Telegram</span>
                        </ShareNetwork>
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-container>
        </v-flex>
  
        <v-flex xs12 class="mt-2">
          <v-progress-linear
            v-if="investmentLoader"
            height="1"
            indeterminate
            color="primary"
          >
          </v-progress-linear>
          <v-card outlined>
            <v-container>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-tabs show-arrows v-model="tab" class="mt-6">
                    <v-tabs-slider color="primary"></v-tabs-slider>
                    <v-tab @click="activeTab = 'about'">
                      <div class="text-none text--text">About</div>
                    </v-tab>
                    <!-- <v-tab
                      @click="activeTab = 'investments'"
                      v-if="$can('investment_view')"
                    >
                      <div class="text-none text--text">
                        Investments ({{ investmentPagination.total }})
                      </div>
                    </v-tab> -->
                  </v-tabs>
                  <div v-if="activeTab == 'about'">
                    <p class="mx-3 mt-6" v-html="publicEso.description"></p>
                  </div>

                </v-flex>
                
              </v-layout>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  import apiCall from "@/utils/api";
  import { mapState, mapGetters, mapActions } from "vuex";
  import VClamp from "vue-clamp";
  import CKEditor from "@ckeditor/ckeditor5-vue2";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  import uploadAdapter from '@/uploadAdapter.js';
  
  export default {
    components: {
      VClamp,
    },
    data() {
      return {
        path: process.env.VUE_APP_API_URL,
        color: "",
        message: "",
        snackbar: false,
        loading: false,
        selection: 1,
        drawer: false,
        dialog: false,
        notFound: false,
        inputRules: [(v) => !!v || "Input is required"],
  
        investmentLoader: false,
  
        formData: null,
        valid: true,
  
        editor: ClassicEditor,
        editorConfig: {
            extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                return new uploadAdapter(loader);
            } }],
            language: 'en',
        },
  
        tab: null,
        activeTab: "about",
  
        shareLink: null,
  
        investments: [],
        investmentPagination: {
          search: " ",
          current_page: 1,
          per_page: 0,
          total: 0,
          visible: 10,
        },
  
      };
    },
    mounted() {
      this.initialize();    
    },
    watch: {
      notFound() {
        this.findPublicEso();
      },
    },
    created() {
      window.scrollTo(0, 0);
      this.createShareLink();
    },
    methods: {
      ...mapActions(["filterPublicEsos"]),
      initialize() {
        
      },
      intToString(value) {
        var suffixes = ["", "k", "M", "B", "T"];
        var suffixNum = Math.floor(("" + value).length / 3);
        var shortValue = parseFloat(
          (suffixNum != 0
            ? value / Math.pow(1000, suffixNum)
            : value
          ).toPrecision(2)
        );
        if (shortValue % 1 != 0) {
          shortValue = shortValue.toFixed(1);
        }
        return shortValue + suffixes[suffixNum];
      },
      findPublicEso() {
        apiCall({
          url:
            "/api/eso?type=findPublicEso&page=1&slug=" +
            this.$route.params.slug,
          method: "GET",
        })
          .then((resp) => {
            this.filterPublicEsos(resp);
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      },
      createShareLink() {
        this.shareLink = this.path + "/#/esos/" + this.$route.params.slug;
      },
      goToInnovation(innovation) {
        this.$router.push("/innovations/" + innovation.slug);
        this.findPublicEso();
      },
    },
    computed: {
      ...mapGetters([
        "publicEsos",
        "publicEsoPagination",
      ]),
  
      publicEso() {
        if (
          this.$store.getters.publicEsos.find(
            (publicEso) => publicEso.slug == this.$route.params.slug
          )
        ) {
          return this.$store.getters.publicEsos.find(
            (publicEso) => publicEso.slug == this.$route.params.slug
          );
        } else {
          this.notFound = true;
        }
      },
      investmentLength: function () {
        return Math.ceil(
          this.investmentPagination.total /
            this.investmentPagination.per_page
        );
      },
    },
  };
  </script>
  