import apiCall from '@/utils/api'

const state = {
	innovationAdminInvites: [],
    innovationAdminInvitePagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    innovationAdminInviteLoader: false,

    institutionAdminInvites: [],
    institutionAdminInvitePagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    institutionAdminInviteLoader: false,
};

const getters = {
	innovationAdminInvites: (state) => state.innovationAdminInvites,
    innovationAdminInviteLoader: (state) => state.innovationAdminInviteLoader,
    innovationAdminInvitePagination: (state) => state.innovationAdminInvitePagination,

    institutionAdminInvites: (state) => state.institutionAdminInvites,
    institutionAdminInviteLoader: (state) => state.institutionAdminInviteLoader,
    institutionAdminInvitePagination: (state) => state.institutionAdminInvitePagination,
}

const actions = {
    async fetchInnovationAdminInvites({commit}, page) {
		const response = await apiCall({url: `/api/innovation-administrator?type=invite&page=${page}`, method: 'GET' });
		commit('setInnovationAdminInvites', response)
		commit('stopInnovationAdminInviteLoader', response)
	},
    async filterInnovationAdminInvites({commit, state},resp){
		commit('setInnovationAdminInvites', resp)
	},
	async startInnovationAdminInviteLoader({commit, state},resp){
		commit('startInnovationAdminLoader', resp)
	},
	async stopInnovationAdminInviteLoader({commit, state},resp){
		commit('stopInnovationAdminLoader', resp)
	},

    async fetchInstitutionAdminInvites({commit}, page) {
		const response = await apiCall({url: `/api/institution-administrator?type=invite&page=${page}`, method: 'GET' });
		commit('setInstitutionAdminInvites', response)
		commit('stopInstitutionAdminInviteLoader', response)
	},
    async filterInstitutionAdminInvites({commit, state},resp){
		commit('setInstitutionAdminInvites', resp)
	},
	async startInstitutionAdminInviteLoader({commit, state},resp){
		commit('startInstitutionAdminLoader', resp)
	},
	async stopInstitutionAdminInviteLoader({commit, state},resp){
		commit('stopInstitutionAdminLoader', resp)
	},
};

const mutations = {
	setInnovationAdminInvites: (state, innovationAdminInvites) => {
		state.innovationAdminInvites = innovationAdminInvites.data
		state.innovationAdminInvitePagination.current_page = innovationAdminInvites.current_page
		state.innovationAdminInvitePagination.total = innovationAdminInvites.total
		state.innovationAdminInvitePagination.per_page = innovationAdminInvites.per_page
	},
    startInnovationAdminLoader: (state) => state.innovationAdminInviteLoader = true,
	stopInnovationAdminLoader: (state) => state.innovationAdminInviteLoader = false,

    setInstitutionAdminInvites: (state, institutionAdminInvites) => {
		state.institutionAdminInvites = institutionAdminInvites.data
		state.institutionAdminInvitePagination.current_page = institutionAdminInvites.current_page
		state.institutionAdminInvitePagination.total = institutionAdminInvites.total
		state.institutionAdminInvitePagination.per_page = institutionAdminInvites.per_page
	},
    startInstitutionAdminLoader: (state) => state.institutionAdminInviteLoader = true,
	stopInstitutionAdminLoader: (state) => state.institutionAdminInviteLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
