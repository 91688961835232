<template>
    <div class="comments-app">
     <!-- From -->
      <v-card
        class="elevation-0"
      >
        <v-card-text>
          <v-layout column>
            <v-flex xs12>
              <div v-if="comments" v-for="(comment,index) in commentsData" class="mt-5 tableHeader pa-7" style="border-radius: 25px;">
                <!-- Comment -->
                <div v-if="!spamComments[index] || !comment.spam" class="comment">
                  <!-- Comment Avatar -->
                  <v-layout row wrap>
                    <v-flex xs2>
                      <v-avatar color="primary" v-if="comment.user.display_img != null">
                        <v-img class="elevation-6"                            
                            :src="
                                path + '/storage/profile_pics/' + comment.user.display_img
                            "
                        >
                        </v-img>
                      </v-avatar>
                      <v-avatar color="primary" class="white--text" v-if="comment.user.display_img == null">
                        {{comment.user.f_name[0]}}
                      </v-avatar>
                    </v-flex>
                    <v-flex xs8>
                      <v-layout column>
                        <v-flex xs12>
                          <div class="text-xs-left"><b>{{comment.user.f_name}} {{comment.user.m_name}} {{comment.user.l_name}}</b> • {{comment.date | moment("from", true)}} </div>
                          <div>{{comment.comment}}</div>
                        </v-flex>
                        <v-flex xs12 class="mt-5">
                          <v-layout row wrap>
                           
                            <v-flex xs6>
                                <div class="ml-3">
                                    <v-btn depressed v-on:click="openComment(index)"  small v-if="comment.replies.length == 0" class="blue white--text text-none">
                                Reply
                              </v-btn>
                              <v-btn depressed v-on:click="openComment(index)"  small v-else class="blue white--text text-none">
                                Reply ({{comment.replies.length}})
                              </v-btn>
                                </div>
                              
                            </v-flex>
                            <v-flex xs6>
                                <div align="right" class="mr-3">
                                    <v-btn
                                        depressed small
                                        class="red white--text text-none"
                                        v-on:click="spamComment(comment.commentid,'directcomment',index,0)"
                                    >
                                        Spam
                                    </v-btn>
                                </div>
                             
                            </v-flex>
                          </v-layout>
                        </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs1>
                  <v-layout column>
                    <v-flex xs12>
                      <v-btn
                        icon
                        flat
                        color="green"
                        small
                        dark
                        v-if="!comment.votedByUser" v-on:click="voteComment(comment.commentid,'directcomment',index,0,'up')"
                      >
                        <v-icon>
                          mdi-arrow-up-thick
                        </v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        flat
                        small
                        v-else
                        disabled
                      >
                        <v-icon>
                          mdi-arrow-up-thick
                        </v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex xs12>
                      <v-btn
                        icon
                        flat
                        color="red"
                        small
                        dark
                        v-if="!comment.votedByUser" v-on:click="voteComment(comment.commentid,'directcomment',index,0,'down')"
                      >
                        <v-icon>
                          mdi-arrow-down-thick
                        </v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        flat
                        small
                        v-else
                        disabled
                      >
                        <v-icon>
                          mdi-arrow-down-thick
                        </v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs1>
                  <div
                    class="mt-5 text-none"
                  >
                    {{comment.votes}}
                  </div>
                </v-flex>
               </v-layout>
  
               <!-- From -->
               <div class="comment-form comment-v mb-4 mt-8" v-if="commentBoxs[index]">
  
                <v-layout row wrap>
                  <v-flex xs1>
  
                  </v-flex>
                  <v-flex xs2>
                    <v-avatar class="primary title white--text" v-if="getProfile.display_img==null">
                        {{ getProfile.f_name[0] }}
                      </v-avatar>
                      <v-avatar class="primary title" v-else>
                        <img :src="path+'/storage/profile_pics/'+getProfile.display_img" alt="prof_pic">
                      </v-avatar>
                  </v-flex>
                  <v-flex xs8>
                    <div class="mt-2">
                        <v-text-field
                          class="ml-4"
                          label="Add a Comment.."
                          v-model="replyMessage"
                          filled
                          dense
                          single-line
                          hide-details
                          v-on:keyup.enter="replyComment(comment.commentid,index)"
                        >
                        </v-text-field>
                    </div>
                    
                    <span class="input" v-if="errorReply" style="color:red">{{errorReply}}</span>
                  </v-flex>
                  <v-flex xs1>
                    <v-btn
                      icon
                      flat
                      class="text-none mt-3"
                      color="primary"
                      v-on:click="replyComment(comment.commentid,index)"
                    >
                    <v-icon>mdi-send</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>                 
               </div>
               <!-- Comment - Reply -->
               <div v-if="comment.replies && commentBoxs[index]" class="mt-5">
                  <div class="comments mt-10" v-for="(replies,index2) in comment.replies">
                    <div v-if="!spamCommentsReply[index2] || !replies.spam" class="comment reply">
                      <v-layout row wrap>
                        <v-flex xs1>
  
                        </v-flex>
                        <v-flex xs2>
                            <v-avatar color="primary" class="white--text">
                        <!-- <img
                          :src="'path+/pictures/'+comment.user.profile.profile_pic"
                          alt="John"
                        > -->
                        {{replies.user.f_name[0]}}
                      </v-avatar>
                        </v-flex>
                        <v-flex xs7>
                          <v-layout column>
                            <v-flex xs12>
                              <div class="text-xs-left"><b>{{replies.user.f_name}} {{replies.user.m_name}} {{replies.user.l_name}} • {{replies.date | moment("from", true)}}</b> 
                              </div>
                              <div>
                                {{replies.comment}}
                              </div>
                            </v-flex>
                            <v-flex xs12 class="mt-3">
                              <v-layout row wrap>
                                <v-flex xs6>
                                  
                                </v-flex>
                                <v-flex xs6>
                                    <div align="right" class="mr-3">
                                        <v-btn depressed small align="right" class="red white--text text-none"
                                  v-on:click="spamComment(replies.commentid,'replycomment',index,index2)"
                                  >
                                    Spam
                                  </v-btn>
                                    </div>
                                  
                                </v-flex>
                                
                                <!-- <v-flex xs4>
                                  <v-btn flat fab color="blue" small dark
                                    v-on:click="replyCommentBox(index2)"
                                  >
                                    <v-icon >
                                      mode_comment
                                    </v-icon>
                                  </v-btn>
                                </v-flex> -->
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs1>
                          <v-layout column>
                            <v-flex xs12>
                              <v-btn icon color="green" small dark
                                v-if="!replies.votedByUser" v-on:click="voteComment(replies.commentid,'replycomment',index,index2,'up')"
                              >
                                <v-icon >
                                    mdi-arrow-up-thick
                                </v-icon>
                              </v-btn>
                              <v-btn icon small
                                v-else
                                disabled
                              >
                                <v-icon >
                                  mdi-arrow-up-thick
                                </v-icon>
                              </v-btn>
                            </v-flex>
                            <v-flex xs12>
                              <v-btn icon color="red" small dark
                                v-if="!replies.votedByUser" v-on:click="voteComment(comment.commentid,'replycomment',index,index2,'down')"
                              >
                                <v-icon >
                                  mdi-arrow-down-thick
                                </v-icon>
                              </v-btn>
                              <v-btn icon small
                                v-else
                                disabled
                              >
                                <v-icon >
                                  mdi-arrow-down-thick
                                </v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs1>
                                  <div align="right" class="mt-5 font-weight-thin grey--text">
                                    {{replies.votes }}
                                  </div>
                                </v-flex>
                      </v-layout>
  
                           <!-- From -->
                           <div style="background: #F9F9F9;" class="comment-form reply" v-if="replyCommentBoxs[index2]">
  
                            <v-layout row wrap>
                              <v-layout row wrap>
                                <v-flex xs2>
                                  <v-avatar color="primary">
                                    <!-- <img
                                      :src="'path+/pictures/'+comment.user.profile.profile_pic"
                                    > -->
                                    <v-icon class="white--text">
                                                mdi-account
                                            </v-icon>
                                  </v-avatar>
                                </v-flex>
                                <v-flex xs8>
                                  <v-text-field
                                    label="Add a Comment.."
                                    v-model="replyMessage"
                                    dense
                                    single-line
                                    hide-details
                                  >
                                  </v-text-field>
                                  <span class="input" v-if="errorReply" style="color:red">{{errorReply}}</span>
                                </v-flex>
                                <v-flex xs2>
                                  <v-btn
                                    icon
                                    flat
                                    class="text-none"
                                    color="primary"
                                    v-on:click="replyComment(comment.commentid,index)"
                                  >
                                  <v-icon>send</v-icon>
                                  </v-btn>
                                </v-flex>
                              </v-layout>
                            </v-layout>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
     </div>
              </v-flex>
              <v-flex xs12 class="mt-5">
                <v-layout row wrap>
          <v-flex xs1>
            <div align="left" class="mt-1">
                <v-avatar color="primary">
              <!-- <img
                :src="'path+/pictures/'+getProfile.profile.profile_pic"
              > -->
              <v-icon class="white--text">
                                                mdi-account
                                            </v-icon>
            </v-avatar>
            </div>
            
          </v-flex>
          <v-flex xs10>
            <div class="mt-2">
                <v-text-field
              label="Add a Comment.."
              v-model="message"
              filled
              dense
              single-line
              hide-details
              v-on:keyup.enter="saveComment"
            >
            </v-text-field>
                </div>
           
            <span class="input" v-if="errorComment" style="color:red">{{errorComment}}</span>
          </v-flex>
          <v-flex xs1>
            <div align="right" class="mt-2">
                <v-btn
              icon
              flat
              class="text-none"
              color="primary"
              large
              @click="saveComment"
            >
            <v-icon>mdi-send</v-icon>
            </v-btn>
            </div>
            
          </v-flex>
        </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
     
  </div>
  </template>
  <script>
  var _ = require('lodash');
  import apiCall from "@/utils/api";
  import {mapGetters, mapActions} from 'vuex'
  import Vue from 'vue'

  export default {
     props: ['commentUrl'],
     data() {
        return {
          path: process.env.VUE_APP_API_URL,
          bottomSheet: false,
          comments: [],
          commentreplies: [],
          comments: 0,
          commentBoxs: [],
          message: null,
          replyMessage: null,
          replyCommentBoxs: [],
          commentsData: [],
          viewcomment: [],
          show: [],
          spamCommentsReply: [],
          spamComments: [],
          errorComment: null,
          errorReply: null,
        }    
     },
     created(){
         
      },
     methods: {
         fetchComments() {
            apiCall({url: '/api/comments/'+this.commentUrl, method: 'GET' })
              .then(res => {
                console.log("response",res)
                 /*this.commentsData = res;*/
                 console.log("Before", this.commentsData)
                 this.commentsData = _.orderBy(res, ['votes'], ['desc']);
                 console.log("after", this.commentsData)
                 this.comments = 1;
                 this.bottomSheet = true
             });
             
         },
         showComments(index) {
             if (!this.viewcomment[index]) {
                 Vue.set(this.show, index, "hide");
                 Vue.set(this.viewcomment, index, 1);
             } else {
                 Vue.set(this.show, index, "view");
                 Vue.set(this.viewcomment, index, 0);
             }
         },
         openComment(index) {
            if (this.commentBoxs[index]) {
                this.bottomSheet = true
                Vue.set(this.commentBoxs, index, 0);
            } else {
                this.bottomSheet = false
                Vue.set(this.commentBoxs, index, 1);
            }
         },
         replyCommentBox(index) {
            if (this.replyCommentBoxs[index]) {
                Vue.set(this.replyCommentBoxs, index, 0);
            } else {
                Vue.set(this.replyCommentBoxs, index, 1);
            }
         },
         saveComment() {
             if (this.message != null && this.message != ' ') {
                 this.errorComment = null;
  
                 apiCall({url: '/api/comments', data: 
                  {post_id: this.commentUrl,
                   comment: this.message,
                   users_id: this.getProfile.id}
                  , method: 'POST' })
  
                  .then(res => {
                     if (res.status) {
                         this.commentsData.push({ "date": new Date(), "user": this.getProfile, "commentid": res.commentId, "name": this.getProfile.name, "comment": this.message, "votes": 0, "reply": 0, "replies": [] });
                         this.message = null;
                     }
                 });
             } else {
                 this.errorComment = "Please enter a comment to save";
             }
         },
         replyComment(commentId, index) {
             if (this.replyMessage != null && this.replyMessage != ' ') {
                 this.errorReply = null;
                 apiCall({url: '/api/comments', data: 
                 {
                  comment: this.replyMessage,
                  users_id: this.getProfile.id,
                  reply_id: commentId
                 }
                  , method: 'POST' })
  
                 .then(res => {
                     if (res.status) {
                         if (!this.commentsData[index].reply) {
                             this.commentsData[index].replies.push({ "date": new Date(), "user": this.getProfile, "commentid": res.commentId, "name": this.getProfile.name, "comment": this.replyMessage, "votes": 0 });
                             this.commentsData[index].reply = 1;
                             Vue.set(this.replyCommentBoxs, index, 0);
                             //Vue.set(this.commentBoxs, index, 0);
                         } else {
                             this.commentsData[index].replies.push({ "date": new Date(), "user": this.getProfile, "commentid": res.commentId, "name": this.getProfile.name, "comment": this.replyMessage, "votes": 0 });
                             Vue.set(this.replyCommentBoxs, index, 0);
                             //Vue.set(this.commentBoxs, index, 0);
                         }
                         this.replyMessage = null;
                     }
                 });
             } else {
                 this.errorReply = "Please enter a comment to save";
             }
         },
         voteComment(commentId, commentType, index, index2, voteType) {
          apiCall({url: '/api/comments/'+ commentId + '/vote', data: 
            {
              users_id: this.getProfile.id,
              vote: voteType
            }
            , method: 'POST' })
              .then(res => {
                  if (res) {
                      if (commentType == 'directcomment') {
                          if (voteType == 'up') {
                              this.commentsData[index].votes++;
                          } else if (voteType == 'down') {
                              this.commentsData[index].votes--;
                          }
                      } else if (commentType == 'replycomment') {
                          if (voteType == 'up') {
                              this.commentsData[index].replies[index2].votes++;
                          } else if (voteType == 'down') {
                              this.commentsData[index].replies[index2].votes--;
                          }
                      }
                  }
              });
         },
         spamComment(commentId, commentType, index, index2) {
            apiCall({url: '/api/comments/' + commentId + '/spam', data: {users_id: this.getProfile.id}, method: 'POST' })
  
              .then(res => {
                  if (commentType == 'directcomment') {
                      Vue.set(this.spamComments, index, 1);
                      Vue.set(this.viewcomment, index, 1);
                  } else if (commentType == 'replycomment') {
                      Vue.set(this.spamCommentsReply, index2, 1);
                  }
              });
         },
     },
     mounted() {
        console.log("mounted");
        this.fetchComments();
     },
     computed: mapGetters(['getProfile']),
  }
  </script>