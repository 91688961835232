import apiCall from '@/utils/api'

const state = {
	organisationTypes: [],
	allOrganisationTypes: [],
    organisationTypePagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    organisationTypeLoader: false
};

const getters = {
	organisationTypes: (state) => state.organisationTypes,
	allOrganisationTypes: (state) => state.allOrganisationTypes,
    organisationTypeLoader: (state) => state.organisationTypeLoader,
    organisationTypePagination: (state) => state.organisationTypePagination
}

const actions = {
	async fetchAllOrganisationTypes({commit}, page) {
		const response = await apiCall({url: `/api/organisation-type?type=all`, method: 'GET' });
		commit('setAllOrganisationTypes', response)
        commit('stopLoader', response)
	},
    async fetchOrganisationTypes({commit}, page) {
		const response = await apiCall({url: `/api/organisation-type?page=${page}`, method: 'GET' });
		commit('setOrganisationTypes', response)
        commit('stopLoader', response)
	},
    async filterOrganisationTypes({commit, state},resp){
		commit('setOrganisationTypes', resp)
	},
	async startOrganisationTypeLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopOrganisationTypeLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setOrganisationTypes: (state, organisationTypes) => {
		state.organisationTypes = organisationTypes.data
		state.organisationTypePagination.current_page = organisationTypes.current_page
		state.organisationTypePagination.total = organisationTypes.total
		state.organisationTypePagination.per_page = organisationTypes.per_page
	},
	setAllOrganisationTypes: (state, organisationTypes) => {
		state.allOrganisationTypes = organisationTypes
	},
    startLoader: (state) => state.organisationTypeLoader = true,
	stopLoader: (state) => state.organisationTypeLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
