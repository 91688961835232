<template>
  <div class="welcome">
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-dialog
      v-if="messageDialogue"
      v-model="messageDialogue"
      width="700"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-text>
          <v-layout column>
            <v-flex xs12>
              <div align="right" class="mt-2">
                <v-btn icon @click="messageDialogue = false">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </div>
            </v-flex>
            <v-flex xs12>
              <v-layout row wrap>
                <v-flex xs4>
                  <div align="center">
                    <v-avatar
                      v-if="userData.display_img == null"
                      size="156"
                      class="primary white--text"
                    >
                      <div
                        class="title"
                        style="font-family: 'poppins' !important"
                      >
                        {{ userData.f_name[0] }}
                      </div>
                    </v-avatar>
                    <v-avatar
                      v-if="userData.display_img != null"
                      size="156"
                      class="primary"
                    >
                      <v-img
                        class="elevation-6"
                        :src="
                          path + '/storage/profile_pics/' + userData.display_img
                        "
                      >
                      </v-img>
                    </v-avatar>
                  </div>
                </v-flex>
                <v-flex xs8>
                  <div align="center" class="my-5">
                    <b
                      >{{ userData.f_name }} {{ userData.m_name }}
                      {{ userData.l_name }}</b
                    >
                  </div>
                  <div
                    align="center"
                    class="caption mt-5"
                    v-if="userData.bio != null"
                  >
                    {{ userData.bio }}
                  </div>
                  <div align="center" class="caption mt-5 font-italic" v-else>
                    No Bio..
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-divider></v-divider>
        <v-form ref="form">
          <div class="pa-2 mx-2">
            <div class="black--text">
              <ckeditor
                :editor="editor"
                v-model="newMessage.body"
                :config="editorConfig"
                :rules="inputRules"
              >
              </ckeditor>
            </div>
          </div>
        </v-form>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            class="text-none my-3"
            depressed
            :loading="loading"
            @click="submit"
          >
            Send
            <v-icon right small class="white--text"> mdi-send </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-progress-linear
      v-if="connectionLoader"
      height="1"
      indeterminate
      color="primary"
    >
    </v-progress-linear>
    <v-progress-linear
      v-if="pendingConnectionLoader"
      height="1"
      indeterminate
      color="primary"
    >
    </v-progress-linear>
    <v-progress-linear
      v-if="deniedConnectionLoader"
      height="1"
      indeterminate
      color="primary"
    >
    </v-progress-linear>
    <v-progress-linear
      v-if="blockedConnectionLoader"
      height="1"
      indeterminate
      color="primary"
    >
    </v-progress-linear>
    <v-progress-linear
      v-if="awaitingConnectionLoader"
      height="1"
      indeterminate
      color="primary"
    >
    </v-progress-linear>
    <v-container>
      <v-layout row wrap>
        <v-flex xs12 md3>
          <v-card outlined class="mt-5">
            <v-tabs vertical>
              <v-tab @click="switchTab('Connections')">
                <v-icon left> mdi-handshake </v-icon>
                <div class="text-none">
                  Connections
                  <b v-if="connectionLoader == false"
                    >({{ connectionPagination.total }})</b
                  >
                  <v-progress-circular
                    v-if="connectionLoader"
                    :width="1"
                    :size="10"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-tab>
              <v-tab @click="switchTab('Pending Connections')">
                <v-icon left> mdi-timer-sand </v-icon>
                <div class="text-none">
                  Pending Connections
                  <b v-if="pendingConnectionLoader == false">
                    ({{ pendingConnectionPagination.total }})</b
                  >
                  <v-progress-circular
                    v-if="pendingConnectionLoader"
                    :width="1"
                    :size="10"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-tab>
              <v-tab @click="switchTab('Denied Connections')">
                <v-icon left> mdi-cancel </v-icon>
                <div class="text-none">
                  Denied Connections
                  <b v-if="deniedConnectionLoader == false">
                    ({{ deniedConnectionPagination.total }})</b
                  >
                  <v-progress-circular
                    v-if="deniedConnectionLoader"
                    :width="1"
                    :size="10"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-tab>
              <v-tab @click="switchTab('Blocked Connections')">
                <v-icon left> mdi-close-octagon </v-icon>
                <div class="text-none">
                  Blocked Connections
                  <b v-if="blockedConnectionLoader == false">
                    ({{ blockedConnectionPagination.total }})</b
                  >
                  <v-progress-circular
                    v-if="blockedConnectionLoader"
                    :width="1"
                    :size="10"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-tab>
              <v-tab @click="switchTab('Awaiting Response')">
                <v-icon left> mdi-close-octagon </v-icon>
                <div class="text-none">
                  Awaiting Response
                  <b v-if="awaitingConnectionLoader == false">
                    ({{ awaitingConnectionPagination.total }})</b
                  >
                  <v-progress-circular
                    v-if="awaitingConnectionLoader"
                    :width="1"
                    :size="10"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-tab>
            </v-tabs>
          </v-card>
        </v-flex>
        <v-flex xs12 md9>
          <v-card
            outlined
            v-if="view == 'Connections'"
            class="mt-5 mx-5"
            style="border-radius: 25px"
          >
            <div
              id="connectionsList"
              class="pa-3"
              style="
                margin: 1px, 1px;
                padding: 1px;
                width: auto;
                height: 80vh;
                overflow-x: hidden;
                overflow-y: scroll;
                text-align: justify;
              "
            >
              <v-layout column>
                <div v-if="connections.length != 0">
                  <template v-for="(connection, index) in connections">
                    <div :key="index">
                      <v-flex xs12 class="mt-3 mx-3">
                        <a style="text-decoration: none">
                          <v-card
                            outlined
                            class="mb-3"
                            style="border-radius: 25px"
                          >
                            <v-card-text>
                              <div class="pa-2">
                                <v-layout row wrap>
                                  <v-flex xs12 md4>
                                    <div align="center" class="my-5">
                                      <v-avatar
                                        v-if="connection.display_img == null"
                                        size="136"
                                        class="primary white--text"
                                      >
                                        <div
                                          class="title"
                                          style="
                                            font-family: 'poppins' !important;
                                          "
                                        >
                                          {{ connection.f_name[0] }}
                                        </div>
                                      </v-avatar>
                                      <v-avatar
                                        v-if="connection.display_img != null"
                                        size="136"
                                        class="primary"
                                      >
                                        <v-img
                                          class="elevation-6"
                                          :src="
                                            path +
                                            '/storage/profile_pics/' +
                                            connection.display_img
                                          "
                                        >
                                        </v-img>
                                      </v-avatar>
                                    </div>
                                  </v-flex>
                                  <v-flex xs12 md8>
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div class="mx-2">
                                          <div class="mt-5 title mr-5">
                                            {{ connection.f_name }}
                                            {{ connection.m_name }}
                                            {{ connection.l_name }}
                                          </div>
                                          <p
                                            class="mt-5 grey--text caption font-italic mr-5"
                                            v-if="connection.bio != null"
                                          >
                                            {{ connection.bio }}
                                          </p>
                                          <p
                                            class="mt-5 grey--text caption font-italic mr-5"
                                            v-else
                                          >
                                            No Bio..
                                          </p>
                                        </div>
                                      </v-flex>
                                      <v-flex xs12 class="mt-7 mb-6">
                                        <div class="mx-2">
                                          <v-layout row wrap>
                                            <v-flex xs12 md4>
                                              <div class="mx-1">
                                                <v-btn
                                                  class="blue white--text text-none my-2"
                                                  depressed
                                                  block
                                                  :loading="
                                                    acceptLoading &&
                                                    connectionIndex ==
                                                      connection.id
                                                  "
                                                  forum
                                                  @click="
                                                    sendMessage(connection)
                                                  "
                                                >
                                                  Message
                                                  <v-icon right
                                                    >mdi-forum</v-icon
                                                  >
                                                </v-btn>
                                              </div>
                                            </v-flex>
                                            <v-flex xs12 md4>
                                              <div class="mx-1">
                                                <v-btn
                                                  class="orange white--text text-none my-2"
                                                  depressed
                                                  block
                                                  :loading="
                                                    removeLoading &&
                                                    connectionIndex ==
                                                      connection.id
                                                  "
                                                  @click="
                                                    removeRequest(connection.id)
                                                  "
                                                >
                                                  Remove
                                                  <v-icon right
                                                    >mdi-account-remove</v-icon
                                                  >
                                                </v-btn>
                                              </div>
                                            </v-flex>
                                            <v-flex xs12 md4>
                                              <div class="mx-1">
                                                <v-btn
                                                  class="red white--text text-none my-2"
                                                  depressed
                                                  block
                                                  :loading="
                                                    blockLoading &&
                                                    connectionIndex ==
                                                      connection.id
                                                  "
                                                  @click="
                                                    blockRequest(connection.id)
                                                  "
                                                >
                                                  Block
                                                  <v-icon right
                                                    >mdi-account-cancel</v-icon
                                                  >
                                                </v-btn>
                                              </div>
                                            </v-flex>
                                          </v-layout>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </v-card-text>
                          </v-card>
                        </a>
                      </v-flex>
                    </div>
                  </template>
                </div>
                <div v-else>
                  <v-flex xs12 class="mb-10">
                    <v-card outlined style="border-radius: 25px">
                      <v-layout row wrap>
                        <v-flex xs12 md4>
                          <div align="center" class="my-10">
                            <v-avatar color="primary" size="96">
                              <v-icon class="white--text">
                                mdi-alert-circle
                              </v-icon>
                            </v-avatar>
                          </div>
                        </v-flex>
                        <v-flex xs12 md8>
                          <v-layout column>
                            <v-flex xs12 class="mt-1">
                              <div class="mt-15 ml-5 title">
                                No Connections..
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </div>
              </v-layout>
            </div>
          </v-card>
          <div v-if="view == 'Connections'" class="mx-5 my-5">
            <v-pagination
              v-if="connectionLength != 0"
              :length="connectionLength"
              total-visible="10"
              v-model="connectionPagination.current_page"
              @input="changeConnectionPage()"
              circle
            >
            </v-pagination>
          </div>

          <v-card
            outlined
            v-if="view == 'Pending Connections'"
            class="mt-5 mx-5"
            style="border-radius: 25px"
          >
            <div
              id="pendingConnectionsList"
              class="pa-3"
              style="
                margin: 1px, 1px;
                padding: 1px;
                width: auto;
                height: 80vh;
                overflow-x: hidden;
                overflow-y: scroll;
                text-align: justify;
              "
            >
              <v-layout column>
                <div v-if="pendingConnections.length != 0">
                  <template
                    v-for="(pendingConnections, index) in pendingConnections"
                  >
                    <div :key="index">
                      <v-flex xs12 class="mt-3 mx-3">
                        <a style="text-decoration: none">
                          <v-card
                            outlined
                            class="mb-3"
                            style="border-radius: 25px"
                          >
                            <v-card-text>
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <div align="center" class="my-5">
                                    <v-avatar
                                      v-if="
                                        pendingConnections.sender.display_img ==
                                        null
                                      "
                                      size="136"
                                      class="primary white--text"
                                    >
                                      <div
                                        class="title"
                                        style="
                                          font-family: 'poppins' !important;
                                        "
                                      >
                                        {{
                                          pendingConnections.sender.f_name[0]
                                        }}
                                      </div>
                                    </v-avatar>
                                    <v-avatar
                                      v-if="
                                        pendingConnections.sender.display_img !=
                                        null
                                      "
                                      size="136"
                                      class="primary"
                                    >
                                      <v-img
                                        class="elevation-6"
                                        :src="
                                          path +
                                          '/storage/profile_pics/' +
                                          pendingConnections.sender.display_img
                                        "
                                      >
                                      </v-img>
                                    </v-avatar>
                                  </div>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <v-layout column>
                                    <v-flex xs12>
                                      <div class="mx-2">
                                        <div class="mt-5 title mr-5">
                                          {{ pendingConnections.sender.f_name }}
                                          {{ pendingConnections.sender.m_name }}
                                          {{ pendingConnections.sender.l_name }}
                                        </div>
                                        <p
                                          class="mt-5 grey--text caption font-italic mr-5"
                                          v-if="
                                            pendingConnections.sender.bio !=
                                            null
                                          "
                                        >
                                          {{ pendingConnections.sender.bio }}
                                        </p>
                                        <p
                                          class="mt-5 grey--text caption font-italic mr-5"
                                          v-else
                                        >
                                          No Bio..
                                        </p>
                                      </div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-7 mb-6">
                                      <div class="mx-2">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <div class="mx-1">
                                              <v-btn
                                                class="green white--text text-none my-1"
                                                depressed
                                                block
                                                :loading="
                                                  acceptLoading &&
                                                  connectionIndex ==
                                                    pendingConnections.sender.id
                                                "
                                                @click="
                                                  acceptRequest(
                                                    pendingConnections.sender.id
                                                  )
                                                "
                                              >
                                                Accept
                                                <v-icon right
                                                  >mdi-account-check</v-icon
                                                >
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12 md4>
                                            <div class="mx-1">
                                              <v-btn
                                                class="orange white--text text-none my-1"
                                                depressed
                                                block
                                                :loading="
                                                  rejectLoading &&
                                                  connectionIndex ==
                                                    pendingConnections.sender.id
                                                "
                                                @click="
                                                  rejectRequest(
                                                    pendingConnections.sender.id
                                                  )
                                                "
                                              >
                                                Reject
                                                <v-icon right
                                                  >mdi-account-remove</v-icon
                                                >
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12 md4>
                                            <div class="mx-1">
                                              <v-btn
                                                class="red white--text text-none my-1"
                                                depressed
                                                block
                                                :loading="
                                                  blockLoading &&
                                                  connectionIndex ==
                                                    pendingConnections.sender.id
                                                "
                                                @click="
                                                  blockRequest(
                                                    pendingConnections.sender.id
                                                  )
                                                "
                                              >
                                                Block
                                                <v-icon right
                                                  >mdi-account-cancel</v-icon
                                                >
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card-text>
                          </v-card>
                        </a>
                      </v-flex>
                    </div>
                  </template>
                </div>
                <div v-else>
                  <v-flex xs12 class="mb-10">
                    <v-card outlined style="border-radius: 25px">
                      <v-layout row wrap>
                        <v-flex xs12 md4>
                          <div align="center" class="my-10">
                            <v-avatar color="primary" size="96">
                              <v-icon class="white--text">
                                mdi-alert-circle
                              </v-icon>
                            </v-avatar>
                          </div>
                        </v-flex>
                        <v-flex xs12 md8>
                          <v-layout column>
                            <v-flex xs12 class="mt-1">
                              <div class="mt-15 ml-5 title">
                                No Pending Connections..
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </div>
              </v-layout>
            </div>
          </v-card>
          <div v-if="view == 'Pending Connections'" class="mx-5 my-5">
            <v-pagination
              v-if="pendingConnectionLength != 0"
              :length="pendingConnectionLength"
              total-visible="10"
              v-model="pendingConnectionPagination.current_page"
              @input="changePendingConnectionPage()"
              circle
            >
            </v-pagination>
          </div>

          <v-card
            outlined
            v-if="view == 'Denied Connections'"
            class="mt-5 mx-5"
            style="border-radius: 25px"
          >
            <div
              id="deniedConnectionsList"
              class="pa-3"
              style="
                margin: 1px, 1px;
                padding: 1px;
                width: auto;
                height: 80vh;
                overflow-x: hidden;
                overflow-y: scroll;
                text-align: justify;
              "
            >
              <v-layout column>
                <div v-if="deniedConnections.length != 0">
                  <template
                    v-for="(deniedConnections, index) in deniedConnections"
                  >
                    <div :key="index">
                      <v-flex xs12 class="mt-3 mx-3">
                        <a style="text-decoration: none">
                          <v-card
                            outlined
                            class="mb-3"
                            style="border-radius: 25px"
                          >
                            <v-card-text>
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <div align="center" class="my-5">
                                    <v-avatar
                                      v-if="
                                        deniedConnections.sender.display_img ==
                                        null
                                      "
                                      size="136"
                                      class="primary white--text"
                                    >
                                      <div
                                        class="title"
                                        style="
                                          font-family: 'poppins' !important;
                                        "
                                      >
                                        {{ deniedConnections.sender.f_name[0] }}
                                      </div>
                                    </v-avatar>
                                    <v-avatar
                                      v-if="
                                        deniedConnections.sender.display_img !=
                                        null
                                      "
                                      size="136"
                                      class="primary"
                                    >
                                      <v-img
                                        class="elevation-6"
                                        :src="
                                          path +
                                          '/storage/profile_pics/' +
                                          deniedConnections.sender.display_img
                                        "
                                      >
                                      </v-img>
                                    </v-avatar>
                                  </div>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <v-layout column>
                                    <v-flex xs12>
                                      <div class="mx-2">
                                        <div class="mt-5 title mr-5">
                                          {{ deniedConnections.sender.f_name }}
                                          {{ deniedConnections.sender.m_name }}
                                          {{ deniedConnections.sender.l_name }}
                                        </div>
                                        <p
                                          class="mt-5 grey--text caption font-italic mr-5"
                                          v-if="
                                            deniedConnections.sender.bio != null
                                          "
                                        >
                                          {{ deniedConnections.sender.bio }}
                                        </p>
                                        <p
                                          class="mt-5 grey--text caption font-italic mr-5"
                                          v-else
                                        >
                                          No Bio..
                                        </p>
                                      </div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-7 mb-6">
                                      <div class="mx-2">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <div class="mx-1">
                                              <v-btn
                                                class="green white--text text-none my-1"
                                                depressed
                                                block
                                                :loading="
                                                  acceptLoading &&
                                                  connectionIndex ==
                                                    deniedConnections.sender.id
                                                "
                                                @click="
                                                  acceptRequest(
                                                    deniedConnections.sender.id
                                                  )
                                                "
                                              >
                                                Accept
                                                <v-icon right
                                                  >mdi-account-check</v-icon
                                                >
                                              </v-btn>
                                            </div>
                                          </v-flex>

                                          <v-flex xs12 md4>
                                            <div class="mx-1">
                                              <v-btn
                                                class="red white--text text-none my-1"
                                                depressed
                                                block
                                                :loading="
                                                  blockLoading &&
                                                  connectionIndex ==
                                                    deniedConnections.sender.id
                                                "
                                                @click="
                                                  blockRequest(
                                                    deniedConnections.sender.id
                                                  )
                                                "
                                              >
                                                Block
                                                <v-icon right
                                                  >mdi-account-cancel</v-icon
                                                >
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card-text>
                          </v-card>
                        </a>
                      </v-flex>
                    </div>
                  </template>
                </div>
                <div v-else>
                  <v-flex xs12 class="mb-10">
                    <v-card outlined style="border-radius: 25px">
                      <v-layout row wrap>
                        <v-flex xs12 md4>
                          <div align="center" class="my-10">
                            <v-avatar color="primary" size="96">
                              <v-icon class="white--text">
                                mdi-alert-circle
                              </v-icon>
                            </v-avatar>
                          </div>
                        </v-flex>
                        <v-flex xs12 md8>
                          <v-layout column>
                            <v-flex xs12 class="mt-1">
                              <div class="mt-15 ml-5 title">
                                No Denied Connections..
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </div>
              </v-layout>
            </div>
          </v-card>
          <div v-if="view == 'Denied Connections'" class="mx-5 my-5">
            <v-pagination
              v-if="deniedConnectionLength != 0"
              :length="deniedConnectionLength"
              total-visible="10"
              v-model="deniedConnectionPagination.current_page"
              @input="changeDeniedConnectionPage()"
              circle
            >
            </v-pagination>
          </div>

          <v-card
            outlined
            v-if="view == 'Blocked Connections'"
            class="mt-5 mx-5"
            style="border-radius: 25px"
          >
            <div
              id="blockedConnectionsList"
              class="pa-3"
              style="
                margin: 1px, 1px;
                padding: 1px;
                width: auto;
                height: 80vh;
                overflow-x: hidden;
                overflow-y: scroll;
                text-align: justify;
              "
            >
              <v-layout column>
                <div v-if="blockedConnections.length != 0">
                  <template
                    v-for="(blockedConnections, index) in blockedConnections"
                  >
                    <div :key="index">
                      <v-flex xs12 class="mt-3 mx-3">
                        <a style="text-decoration: none">
                          <v-card
                            outlined
                            class="mb-3"
                            style="border-radius: 25px"
                          >
                            <v-card-text>
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <div align="center" class="my-5">
                                    <v-avatar
                                      v-if="
                                        blockedConnections.sender.display_img ==
                                        null
                                      "
                                      size="136"
                                      class="primary white--text"
                                    >
                                      <div
                                        class="title"
                                        style="
                                          font-family: 'poppins' !important;
                                        "
                                      >
                                        {{ blockedConnections.sender.f_name[0] }}
                                      </div>
                                    </v-avatar>
                                    <v-avatar
                                      v-if="
                                        blockedConnections.sender.display_img !=
                                        null
                                      "
                                      size="136"
                                      class="primary"
                                    >
                                      <v-img
                                        class="elevation-6"
                                        :src="
                                          path +
                                          '/storage/profile_pics/' +
                                          blockedConnections.sender.display_img
                                        "
                                      >
                                      </v-img>
                                    </v-avatar>
                                  </div>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <v-layout column>
                                    <v-flex xs12>
                                      <div class="mx-2">
                                        <div class="mt-5 title mr-5">
                                          {{ blockedConnections.sender.f_name }}
                                          {{ blockedConnections.sender.m_name }}
                                          {{ blockedConnections.sender.l_name }}
                                        </div>
                                        <p
                                          class="mt-5 grey--text caption font-italic mr-5"
                                          v-if="
                                            blockedConnections.sender.bio != null
                                          "
                                        >
                                          {{ blockedConnections.sender.bio }}
                                        </p>
                                        <p
                                          class="mt-5 grey--text caption font-italic mr-5"
                                          v-else
                                        >
                                          No Bio..
                                        </p>
                                      </div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-7 mb-6">
                                      <div class="mx-2">
                                        <v-layout row wrap>

                                          <v-flex xs12 md4>
                                            <div class="mx-1">
                                              <v-btn
                                                class="orange white--text text-none my-1"
                                                depressed
                                                block
                                                :loading="
                                                  blockLoading &&
                                                  connectionIndex ==
                                                    blockedConnections.sender.id
                                                "
                                                @click="
                                                  unblockRequest(
                                                    blockedConnections.sender.id
                                                  )
                                                "
                                              >
                                                Un-block
                                                <v-icon right
                                                  >mdi-account-convert</v-icon
                                                >
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card-text>
                          </v-card>
                        </a>
                      </v-flex>
                    </div>
                  </template>
                </div>
                <div v-else>
                  <v-flex xs12 class="mb-10">
                    <v-card outlined style="border-radius: 25px">
                      <v-layout row wrap>
                        <v-flex xs12 md4>
                          <div align="center" class="my-10">
                            <v-avatar color="primary" size="96">
                              <v-icon class="white--text">
                                mdi-alert-circle
                              </v-icon>
                            </v-avatar>
                          </div>
                        </v-flex>
                        <v-flex xs12 md8>
                          <v-layout column>
                            <v-flex xs12 class="mt-1">
                              <div class="mt-15 ml-5 title">
                                No Blocked Connections..
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </div>
              </v-layout>
            </div>
          </v-card>
          <div v-if="view == 'Blocked Connections'" class="mx-5 my-5">
            <v-pagination
              v-if="blockedConnectionLength != 0"
              :length="blockedConnectionLength"
              total-visible="10"
              v-model="blockedConnectionPagination.current_page"
              @input="changeBlockedConnectionPage()"
              circle
            >
            </v-pagination>
          </div>

          <v-card
            outlined
            v-if="view == 'Awaiting Response'"
            class="mt-5 mx-5"
            style="border-radius: 25px"
          >
            <div
              id="awaitingConnectionsList"
              class="pa-3"
              style="
                margin: 1px, 1px;
                padding: 1px;
                width: auto;
                height: 80vh;
                overflow-x: hidden;
                overflow-y: scroll;
                text-align: justify;
              "
            >
              <v-layout column>
                <div v-if="awaitingConnections.length != 0">
                  <template
                    v-for="(awaitingConnections, index) in awaitingConnections"
                  >
                    <div :key="index">
                      <v-flex xs12 class="mt-3 mx-3">
                        <a style="text-decoration: none">
                          <v-card
                            outlined
                            class="mb-3"
                            style="border-radius: 25px"
                          >
                            <v-card-text>
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <div align="center" class="my-5">
                                    <v-avatar
                                      v-if="
                                        awaitingConnections.recipient.display_img ==
                                        null
                                      "
                                      size="136"
                                      class="primary white--text"
                                    >
                                      <div
                                        class="title"
                                        style="
                                          font-family: 'poppins' !important;
                                        "
                                      >
                                        {{ awaitingConnections.recipient.f_name[0] }}
                                      </div>
                                    </v-avatar>
                                    <v-avatar
                                      v-if="
                                        awaitingConnections.recipient.display_img !=
                                        null
                                      "
                                      size="136"
                                      class="primary"
                                    >
                                      <v-img
                                        class="elevation-6"
                                        :src="
                                          path +
                                          '/storage/profile_pics/' +
                                          awaitingConnections.recipient.display_img
                                        "
                                      >
                                      </v-img>
                                    </v-avatar>
                                  </div>
                                </v-flex>
                                <v-flex xs12 md8>
                                  <v-layout column>
                                    <v-flex xs12>
                                      <div class="mx-2">
                                        <div class="mt-5 title mr-5">
                                          {{ awaitingConnections.recipient.f_name }}
                                          {{ awaitingConnections.recipient.m_name }}
                                          {{ awaitingConnections.recipient.l_name }}
                                        </div>
                                        <p
                                          class="mt-5 grey--text caption font-italic mr-5"
                                          v-if="
                                            awaitingConnections.recipient.bio != null
                                          "
                                        >
                                          {{ awaitingConnections.recipient.bio }}
                                        </p>
                                        <p
                                          class="mt-5 grey--text caption font-italic mr-5"
                                          v-else
                                        >
                                          No Bio..
                                        </p>
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card-text>
                          </v-card>
                        </a>
                      </v-flex>
                    </div>
                  </template>
                </div>
                <div v-else>
                  <v-flex xs12 class="mb-10">
                    <v-card outlined style="border-radius: 25px">
                      <v-layout row wrap>
                        <v-flex xs12 md4>
                          <div align="center" class="my-10">
                            <v-avatar color="primary" size="96">
                              <v-icon class="white--text">
                                mdi-alert-circle
                              </v-icon>
                            </v-avatar>
                          </div>
                        </v-flex>
                        <v-flex xs12 md8>
                          <v-layout column>
                            <v-flex xs12 class="mt-1">
                              <div class="mt-15 ml-5 title">
                                No Connections awaiting Response..
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </div>
              </v-layout>
            </div>
          </v-card>
          <div v-if="view == 'Awaiting Connections'" class="mx-5 my-5">
            <v-pagination
              v-if="awaitingConnectionLength != 0"
              :length="awaitingConnectionLength"
              total-visible="10"
              v-model="awaitingConnectionPagination.current_page"
              @input="changeAwaitingConnectionPage()"
              circle
            >
            </v-pagination>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<style>
.ck-editor__editable {
  min-height: 200px;
}
</style>
<script>
import Vue from "vue";
import apiCall from "@/utils/api";
import VClamp from "vue-clamp";
import { mapState, mapGetters, mapActions } from "vuex";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadAdapter from '@/uploadAdapter.js';

Vue.use(CKEditor);

export default {
  components: {
    VClamp,
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      loading: false,
      selection: 1,
      drawer: false,
      dialog: false,
      messageDialogue: false,

      inputRules: [(v) => !!v || "Input is required"],

      editor: ClassicEditor,
      editorConfig: {
                extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
              return new uploadAdapter(loader);
          } }],
          language: 'en',
      },

      acceptLoading: false,
      rejectLoading: false,
      blockLoading: false,
      removeLoading: false,
      connectionIndex: 0,

      view: "Connections",
      filterView: "Sectors",
      time: "All Time",

      tabs: [
        { name: "Connections", total: 0 },
        { name: "Users", total: 10 },
        { name: "Reviews", total: 519 },
        { name: "Blocked Connections", total: 5 },
      ],
      filterTabs: ["Sectors", "Interests", "SDGs"],

      institution: null,

      total_innovations: 0,
      total_users: 0,
      total_connections: 0,

      time_periods: [
        "24 Hrs",
        "This Week",
        "This Month",
        "This Year",
        "All Time",
      ],
      sectors: [],
      interests: [],
      sdgs: [],

      filter: {
        institution_id: null,
        search: null,
        time_period: null,
        sectors: [],
        interests: [],
        sdgs: [],
      },
      connectionData: {
        sender_id: null,
      },
      userData: null,
      newMessage: {
        recipient_id: null,
        body: null,
      },
    };
  },
  mounted() {},
  created() {
    window.scrollTo(0, 0);
    this.fetchConnections(1);
    this.fetchPendingConnections(1);
    this.fetchDeniedConnections(1);
    this.fetchBlockedConnections(1);
    this.fetchAwaitingConnections(1);
  },
  methods: {
    ...mapActions([
      "fetchConnections",
      "fetchPendingConnections",
      "fetchDeniedConnections",
      "fetchBlockedConnections",
      "fetchAwaitingConnections",

      "filterConnections",
      "filterPendingConnections",
      "filterDeniedConnections",
      "filterBlockedConnections",
      "filterAwaitingConnections",

      "startConnectionLoader",
      "startPendingConnectionLoader",
      "startDeniedConnectionLoader",
      "startBlockedConnectionLoader",
      "startAwaitingConnectionLoader",

      "stopConnectionLoader",
      "stopPendingConnectionLoader",
      "stopDeniedConnectionLoader",
      "stopBlockedConnectionLoader",
      "stopAwaitingConnectionLoader",
    ]),
    switchTab(tab) {
      if (tab == "Connections") {
        this.fetchConnections(this.connectionPagination.current_page);
      } else if (tab == "Pending Connections") {
        this.fetchPendingConnections(
          this.pendingConnectionPagination.current_page
        );
      } else if (tab == "Denied Connections") {
        this.fetchDeniedConnections(
          this.deniedConnectionPagination.current_page
        );
      } else if (tab == "Blocked Connections") {
        this.fetchBlockedConnections(
          this.blockedConnectionPagination.current_page
        );
      } else if (tab == "Awaiting Response") {
        this.fetchAwaitingConnections(
          this.awaitingConnectionPagination.current_page
        );
      }
      this.view = tab;
    },
    activeFilterTab(tab) {
      this.filterView = tab;
    },
    sendMessage(user) {
      this.messageDialogue = true;
      this.userData = user;
    },
    acceptRequest(sender_id) {
      this.acceptLoading = true;
      this.connectionIndex = sender_id;
      this.connectionData.sender_id = sender_id;
      apiCall({
        url: "/api/accept-friend",
        data: this.connectionData,
        method: "POST",
      })
        .then((resp) => {
          this.acceptLoading = false;
          this.connectionIndex = null;
          this.fetchConnections(this.connectionPagination.current_page);
          this.fetchPendingConnections(
            this.pendingConnectionPagination.current_page
          );
          this.fetchDeniedConnections(
            this.deniedConnectionPagination.current_page
          );
        })
        .catch((error) => {
          this.message =
            "An Error Occurred; Couldn't fetch Connections. Please Try Again";
          this.color = "error";
          this.acceptLoading = false;
          this.snackbar = true;
        });
    },
    rejectRequest(sender_id) {
      this.rejectLoading = true;
      this.connectionIndex = sender_id;
      this.connectionData.sender_id = sender_id;
      apiCall({
        url: "/api/deny-friend",
        data: this.connectionData,
        method: "POST",
      })
        .then((resp) => {
          this.rejectLoading = false;
          this.connectionIndex = null;
          this.fetchPendingConnections(
            this.pendingConnectionPagination.current_page
          );
          this.fetchDeniedConnections(
            this.deniedConnectionPagination.current_page
          );
        })
        .catch((error) => {
          this.message =
            "An Error Occurred; Couldn't Complete Request. Please Try Again";
          this.color = "error";
          this.rejectLoading = false;
          this.snackbar = true;
        });
    },
    blockRequest(sender_id) {
      this.blockLoading = true;
      this.connectionIndex = sender_id;
      this.connectionData.sender_id = sender_id;
      apiCall({
        url: "/api/block-friend",
        data: this.connectionData,
        method: "POST",
      })
        .then((resp) => {
          this.blockLoading = false;
          this.connectionIndex = null;
          this.fetchPendingConnections(
            this.pendingConnectionPagination.current_page
          );
          this.fetchConnections(this.connectionPagination.current_page);
          this.fetchBlockedConnections(
            this.blockedConnectionPagination.current_page
          );
          this.fetchDeniedConnections(
            this.deniedConnectionPagination.current_page
          );
        })
        .catch((error) => {
          this.message =
            "An Error Occurred; Couldn't Complete Request. Please Try Again";
          this.color = "error";
          this.blockLoading = false;
          this.snackbar = true;
        });
    },
    unblockRequest(sender_id) {
      this.blockLoading = true;
      this.connectionIndex = sender_id;
      this.connectionData.sender_id = sender_id;
      apiCall({
        url: "/api/unblock-friend",
        data: this.connectionData,
        method: "POST",
      })
        .then((resp) => {
          this.blockLoading = false;
          this.connectionIndex = null;
          this.fetchPendingConnections(
            this.pendingConnectionPagination.current_page
          );
          this.fetchBlockedConnections(
            this.blockedConnectionPagination.current_page
          );
        })
        .catch((error) => {
          this.message =
            "An Error Occurred; Couldn't Complete Request. Please Try Again";
          this.color = "error";
          this.blockLoading = false;
          this.snackbar = true;
        });
    },
    removeRequest(sender_id) {
      this.removeLoading = true;
      this.connectionIndex = sender_id;
      this.connectionData.sender_id = sender_id;
      apiCall({
        url: "/api/remove-friend",
        data: this.connectionData,
        method: "POST",
      })
        .then((resp) => {
          this.removeLoading = false;
          this.connectionIndex = null;
          this.fetchConnections(this.connectionPagination.current_page);
        })
        .catch((error) => {
          this.message =
            "An Error Occurred; Couldn't Complete Request. Please Try Again";
          this.color = "error";
          this.removeLoading = false;
          this.snackbar = true;
        });
    },
    changeConnectionPage() {
        this.fetchConnections(
            this.connectionPagination.current_page
        );
    },
    changePendingConnectionPage() {
        this.fetchPendingConnections(
            this.pendingConnectionPagination.current_page
        );
    },
    changeDeniedConnectionPage(){
        this.fetchDeniedConnections(
            this.deniedConnectionPagination.current_page
        );
    },
    changeBlockedConnectionPage(){
        this.fetchBlockedConnections(
            this.blockedConnectionPagination.current_page
        );
    },
    changeAwaitingConnectionPage(){
        this.fetchAwaitingConnections(
            this.awaitingConnectionPagination.current_page
        );
    },
    

    submit() {
      if (this.$refs.form.validate()) {
        if (this.newMessage.body == null) {
          this.message = "Please enter your message";
          this.color = "error";
          this.snackbar = true;
        } else {
          this.newMessage.recipient_id = this.userData.id;
          this.loading = true;
          apiCall({
            url: "/api/message?type=new",
            data: this.newMessage,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Message Sent Successfully";
              this.color = "success";
              this.snackbar = true;
              this.loading = false;
              this.messageDialogue = false;
              this.newMessage.body = null;
              this.newMessage.recipient_id = null;
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      "connections",
      "connectionLoader",
      "connectionPagination",

      "pendingConnections",
      "pendingConnectionLoader",
      "pendingConnectionPagination",

      "deniedConnections",
      "deniedConnectionLoader",
      "deniedConnectionPagination",

      "blockedConnections",
      "blockedConnectionLoader",
      "blockedConnectionPagination",

      "awaitingConnections",
      "awaitingConnectionLoader",
      "awaitingConnectionPagination",
    ]),

    connectionLength: function () {
      return Math.ceil(
        this.connectionPagination.total / this.connectionPagination.per_page
      );
    },
    pendingConnectionLength: function () {
      return Math.ceil(
        this.pendingConnectionPagination.total /
          this.pendingConnectionPagination.per_page
      );
    },
    deniedConnectionLength: function () {
      return Math.ceil(
        this.deniedConnectionPagination.total /
          this.deniedConnectionPagination.per_page
      );
    },
    blockedConnectionLength: function () {
      return Math.ceil(
        this.blockedConnectionPagination.total /
          this.blockedConnectionPagination.per_page
      );
    },
    awaitingConnectionLength: function () {
      return Math.ceil(
        this.awaitingConnectionPagination.total /
          this.awaitingConnectionPagination.per_page
      );
    },
  },
};
</script>
