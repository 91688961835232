<template>
	<div>
		<v-snackbar
			v-model="snackbar"
			:color="color"
			:timeout="6000"
			top
		>
			{{ message }}
		</v-snackbar>
        <v-container class="my-5">
            <v-layout column>
                
                <v-flex xs12 class="mt-5">
                    <div class="mx-10">
                        <v-progress-linear
                            v-if="loading"
                            height="1"
                            indeterminate
                            color="primary"
                            >
                            </v-progress-linear>
                        </div>
                    <v-card outlined
                        class="mx-4 pa-3"
                        style="border-radius: 25px;"
                    >

                    <h1 class="my-5">Notifications</h1>
                        <template v-for="(notification, index) in paginatedNotifications">
                            <div :key="index">
                                <v-flex xs12 class="mt-2">
                                    <v-card
                                        outlined
                                        style="border-radius: 25px;"
                                        v-if="notification.read_at == null"
                                    >
                                        <div class="pa-2">
                                            <v-layout row>
                                                <v-flex xs1>
                                                    <div align="center">
                                                        <v-checkbox
                                                            class="ml-3"
                                                            v-model="checked"
                                                            :value="notification.id"
                                                        ></v-checkbox>
                                                    </div>
                                                </v-flex>
                                                <v-flex xs11>
                                                    <v-layout column>
                                                        <v-flex xs12>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\EventBroadcast'" @click="openAuthNotification(notification)"><a><b>Event:</b> {{notification.data.event.title}} ({{notification.data.event.start_time | moment("Do MMM, YYYY - h:mm a")}} - {{notification.data.event.end_time | moment("Do MMM, YYYY - h:mm a")}})</a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\CallBroadcast'" @click="openAuthNotification(notification)"><a><b>Call:</b> {{notification.data.call.title}} - <i>Deadline {{notification.data.call.deadline | moment("Do MMM, YYYY - h:mm a")}}</i></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\ContactUsMessage'" @click="openAuthNotification(notification)"><a><b>Contact Us:</b> {{notification.data.contact.subject}}</a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\NewInnovation'" @click="openAuthNotification(notification)"><a><b>New Innovation:</b> {{notification.data.innovation.name}}</a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\RejectInnovation'" @click="openAuthNotification(notification)"><a><b>Innovation Not Approved</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\ApproveInnovation'" @click="openAuthNotification(notification)"><a><b>Innovation Approved</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\VerifyInnovation'" @click="openAuthNotification(notification)"><a><b>Innovation Verified</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\UnVerifyInnovation'" @click="openAuthNotification(notification)"><a><b>Innovation Unverified</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\NewInstitutionUser'" @click="openAuthNotification(notification)"><a><b>Affiliation Request - {{ notification.data.institution.name }}</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\InstitutionUserApproved'" @click="openAuthNotification(notification)"><a><b>Institution Affiliation Approved</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\NewInstitutionInnovation'" @click="openAuthNotification(notification)"><a><b>Affiliation Request - {{ notification.data.institution.name }}</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\InstitutionInnovationApproved'" @click="openAuthNotification(notification)"><a><b>Institution Affiliation Approved</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\IndividualInvestmentOffer'" @click="openAuthNotification(notification)"><a><b>Investment Offer - {{ notification.data.data.innovation.name }}</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\IndividualOfferRejected'" @click="openAuthNotification(notification)"><a><b>{{ notification.data.data.innovation.name }} - {{ notification.data.data.investment.amount }}</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\IndividualOfferAccepted'" @click="openAuthNotification(notification)"><a><b>{{ notification.data.data.innovation.name }} - {{ notification.data.data.investment.amount }}</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\InnovationAdminNotif'" @click="openAuthNotification(notification)"><a><b>{{ notification.data.data.innovation.name }}</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\InnovationAdminAccepted'" @click="openAuthNotification(notification)"><a><b>Innovation Admin Accepted - {{ notification.data.data.innovation.name }}</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\FirmInvestmentOffer'" @click="openAuthNotification(notification)"><a><b>Investment Offer - {{ notification.data.data.innovation.name }}</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\FirmOfferAccepted'" @click="openAuthNotification(notification)"><a><b>Offer Accepted - {{ notification.data.data.innovation.name }}</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\FirmOfferRejected'" @click="openAuthNotification(notification)"><a><b>Offer Rejected - {{ notification.data.data.innovation.name }}</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\BroadcastMessage'" @click="openAuthNotification(notification)"><a><b>{{ notification.data.data.broadcast.subject }}</b></a></div>
                                                        </v-flex>
                                                        <v-flex xs12>
                                                            <div class="grey--text">{{notification.created_at | moment("MMMM Do YYYY, h:mm a")}}</div>
                                                        </v-flex>
                                                        <v-flex xs12>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\EventBroadcast' ">{{notification.data.event.brief}}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\CallBroadcast' ">{{notification.data.call.brief}}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\ContactUsMessage' ">{{ notification.data.contact.user.f_name }} {{ notification.data.contact.user.l_name }}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\NewInnovation' ">{{ notification.data.innovation.owner.f_name }} {{ notification.data.innovation.owner.l_name }} - {{notification.data.innovation.brief}}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\RejectInnovation' ">{{notification.data.innovation.name}}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\ApproveInnovation' ">{{notification.data.innovation.name}}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\VerifyInnovation' ">{{notification.data.innovation.name}}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\UnVerifyInnovation' ">{{notification.data.innovation.name}}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\NewInstitutionUser' ">{{ notification.data.user.f_name }} {{ notification.data.user.m_name }} {{ notification.data.user.l_name }} - {{ notification.created_at | moment("ddd, h:mm a") }}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\InstitutionUserApproved' ">{{notification.data.institution.name}}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\NewInstitutionInnovation' ">{{notification.data.innovation.name}}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\InstitutionInnovationApproved' ">{{notification.data.institution.name}}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\IndividualInvestmentOffer' ">{{ notification.data.data.user.f_name }} {{ notification.data.data.user.m_name }} {{ notification.data.data.user.l_name }}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\IndividualOfferRejected' ">Offer Rejected</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\IndividualOfferAccepted' ">Offer Accepted</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\InnovationAdminNotif' ">Innovation Administration</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\InnovationAdminAccepted' ">{{ notification.data.data.user.f_name }} {{ notification.data.data.user.m_name }} {{ notification.data.data.user.l_name }}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\FirmInvestmentOffer' ">{{ notification.data.data.firm.name }}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\FirmOfferAccepted' ">{{ notification.data.data.investmentFirm.name }}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\FirmOfferRejected' ">{{ notification.data.data.investmentFirm.name }}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\BroadcastMessage'">Broadcast Message</div>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                            </v-layout>
                                        </div>
                                    </v-card>
                                    
                                    <v-card
                                        class="tableHeader"
                                        v-else
                                        outlined
                                        style="border-radius: 25px;"
                                    >
                                        <div class="pa-2">
                                            <v-layout row>
                                                <v-flex xs1>
                                                    <div align="center">
                                                        <v-checkbox
                                                            class="ml-3"
                                                            v-model="checked"
                                                            :value="notification.id"
                                                        ></v-checkbox>
                                                    </div>
                                                </v-flex>
                                                <v-flex xs11>
                                                    <v-layout column>
                                                        <v-flex xs12>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\EventBroadcast'" @click="openAuthNotification(notification)"><a><b>Event:</b> {{notification.data.event.title}} ({{notification.data.event.start_time | moment("Do MMM, YYYY - h:mm a")}} - {{notification.data.event.end_time | moment("Do MMM, YYYY - h:mm a")}})</a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\CallBroadcast'" @click="openAuthNotification(notification)"><a><b>Call:</b> {{notification.data.call.title}} - <i>Deadline {{notification.data.call.deadline | moment("Do MMM, YYYY - h:mm a")}}</i></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\ContactUsMessage'" @click="openAuthNotification(notification)"><a><b>Contact Us:</b> {{notification.data.contact.subject}}</a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\NewInnovation'" @click="openAuthNotification(notification)"><a><b>New Innovation:</b> {{notification.data.innovation.name}}</a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\RejectInnovation'" @click="openAuthNotification(notification)"><a><b>Innovation Not Approved</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\ApproveInnovation'" @click="openAuthNotification(notification)"><a><b>Innovation Approved</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\VerifyInnovation'" @click="openAuthNotification(notification)"><a><b>Innovation Verified</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\UnVerifyInnovation'" @click="openAuthNotification(notification)"><a><b>Innovation Unverified</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\NewInstitutionUser'" @click="openAuthNotification(notification)"><a><b>Affiliation Request - {{ notification.data.institution.name }}</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\InstitutionUserApproved'" @click="openAuthNotification(notification)"><a><b>Institution Affiliation Approved</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\NewInstitutionInnovation'" @click="openAuthNotification(notification)"><a><b>Affiliation Request - {{ notification.data.institution.name }}</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\InstitutionInnovationApproved'" @click="openAuthNotification(notification)"><a><b>Institution Affiliation Approved</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\IndividualInvestmentOffer'" @click="openAuthNotification(notification)"><a><b>Investment Offer - {{ notification.data.data.innovation.name }}</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\IndividualOfferRejected'" @click="openAuthNotification(notification)"><a><b>{{ notification.data.data.innovation.name }} - {{ notification.data.data.investment.amount }}</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\IndividualOfferAccepted'" @click="openAuthNotification(notification)"><a><b>{{ notification.data.data.innovation.name }} - {{ notification.data.data.investment.amount }}</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\InnovationAdminNotif'" @click="openAuthNotification(notification)"><a><b>{{ notification.data.data.innovation.name }}</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\InnovationAdminAccepted'" @click="openAuthNotification(notification)"><a><b>Innovation Admin Accepted - {{ notification.data.data.innovation.name }}</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\FirmInvestmentOffer'" @click="openAuthNotification(notification)"><a><b>Investment Offer - {{ notification.data.data.innovation.name }}</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\FirmOfferAccepted'" @click="openAuthNotification(notification)"><a><b>Offer Accepted - {{ notification.data.data.innovation.name }}</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\FirmOfferRejected'" @click="openAuthNotification(notification)"><a><b>Offer Rejected - {{ notification.data.data.innovation.name }}</b></a></div>
                                                            <div class="mt-3" v-if="notification.type == 'App\\Notifications\\BroadcastMessage'" @click="openAuthNotification(notification)"><a><b>{{ notification.data.data.broadcast.subject }}</b></a></div>
                                                        </v-flex>
                                                        <v-flex xs12>
                                                            <div class="grey--text">{{notification.created_at | moment("MMMM Do YYYY, h:mm a")}}</div>
                                                        </v-flex>
                                                        <v-flex xs12>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\EventBroadcast' ">{{notification.data.event.brief}}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\CallBroadcast' ">{{notification.data.call.brief}}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\ContactUsMessage' ">{{ notification.data.contact.user.f_name }} {{ notification.data.contact.user.l_name }}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\NewInnovation' ">{{ notification.data.innovation.owner.f_name }} {{ notification.data.innovation.owner.l_name }} - {{notification.data.innovation.brief}}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\RejectInnovation' ">{{notification.data.innovation.name}}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\ApproveInnovation' ">{{notification.data.innovation.name}}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\VerifyInnovation' ">{{notification.data.innovation.name}}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\UnVerifyInnovation' ">{{notification.data.innovation.name}}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\NewInstitutionUser' ">{{ notification.data.user.f_name }} {{ notification.data.user.m_name }} {{ notification.data.user.l_name }} - {{ notification.created_at | moment("ddd, h:mm a") }}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\InstitutionUserApproved' ">{{notification.data.institution.name}}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\NewInstitutionInnovation' ">{{notification.data.innovation.name}}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\InstitutionInnovationApproved' ">{{notification.data.institution.name}}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\IndividualInvestmentOffer' ">{{ notification.data.data.user.f_name }} {{ notification.data.data.user.m_name }} {{ notification.data.data.user.l_name }}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\IndividualOfferRejected' ">Offer Rejected</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\IndividualOfferAccepted' ">Offer Accepted</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\InnovationAdminNotif' ">Innovation Administration</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\InnovationAdminAccepted' ">{{ notification.data.data.user.f_name }} {{ notification.data.data.user.m_name }} {{ notification.data.data.user.l_name }}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\FirmInvestmentOffer' ">{{ notification.data.data.firm.name }}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\FirmOfferAccepted' ">{{ notification.data.data.investmentFirm.name }}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\FirmOfferRejected' ">{{ notification.data.data.investmentFirm.name }}</div>
                                                            <div class="mb-3" v-if="notification.type == 'App\\Notifications\\BroadcastMessage'">Broadcast Message</div>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                            </v-layout>
                                        </div>
                                    </v-card>
                                </v-flex>
                            </div>
                        </template>
                    </v-card>
                </v-flex>
                <v-flex xs12 class="mt-5 mx-5">
                    <v-btn depressed dark class="primary text-none mx-1" @click="markAll" v-if="allMarked == true">Select all <v-icon dark right>mdi-check-all</v-icon></v-btn>
                    <v-btn depressed dark class="orange text-none mx-1" @click="unMarkAll" v-if="allMarked == false">Unselect all <v-icon dark right>mdi-undo</v-icon></v-btn>
                    <v-btn depressed v-if="checked.length != 0" dark class="green text-none mx-1" @click="markNotificationsRead" :loading="readLoading">
                        Mark as Read
                        <v-icon dark right>mdi-email-mark-as-unread</v-icon>
                    </v-btn>
                    <v-btn depressed v-if="checked.length != 0" dark class="red text-none mx-1" @click="deleteNotifications" :loading="deleteLoading">
                            Delete
                            <v-icon dark right>mdi-delete</v-icon>
                        </v-btn>
                </v-flex>
            </v-layout>
            <div v-if="length" class="text-xs-center">
                <v-pagination
                    :length="length"
                    total-visible="5"
                    v-model="notificationPagination.current_page"
                    @input="fetchnotifications"
                    circle>
                </v-pagination>
            </div>
        </v-container>
    </div>
</template>

<script>
  import apiCall from "@/utils/api";
  import Vue from 'vue'
  import { mapGetters, mapState, mapActions } from 'vuex'

  Vue.use(require('vue-moment'));
  export default {
	components: {
		
	},
	data(){
		return{
            allMarked: true,
            readLoading: false,
            deleteLoading: false,
            snackbar: false,

            loading: false,

            message:'',
            y: 'top',
            color: '',

            checked: [],
            emptyArray: [],

            notificationData: {
                notifications: null
            }
        }
    },
    created(){
        this.fetchnotifications()
    },
	methods: {
        ...mapActions([
            'commitPaginatedNotifications',
            'assignInnovationData',
            'changeInnovationApprovalCardState'
        ]),
        markAll(){
            this.allMarked = false
            var i = 0
            for (i; i<= (this.paginatedNotifications.length-1); i++){
                this.checked.push(this.paginatedNotifications[i].id)
            }
        },
        unMarkAll(){
            this.allMarked = true
            var i = 0
            for (i; i<= (this.paginatedNotifications.length-1); i++){
                this.checked.splice(i)
            }
        },
        markNotificationsRead(){
            if(this.checked.length != 0){
                this.notificationData.notifications = this.checked
                
                this.readLoading = true
                apiCall({url: '/api/markAsRead', data: this.notificationData, method: 'POST' })
                .then(resp => {
                    this.color = 'success'
                    this.message = 'Notifications Marked as Read Successfully'
                    this.readLoading = false;
                    this.snackbar = true;
                    this.fetchnotifications()
                })
                .catch(error => {
                    this.color = 'error'
                    this.message = 'An error Occurred'
                    this.readLoading = false;
                    this.snackbar = true;
                })
            } else {
                this.color = 'error'
                this.message = 'Nothing is selected'
                this.snackbar = true;
            }
            
        },
        deleteNotifications(){
            if(this.checked.length != 0){
                this.notificationData.notifications = this.checked
                    
                this.deleteLoading = true
                apiCall({url: '/api/deleteNotification', data: this.notificationData, method: 'POST' })
                .then(resp => {
                    this.color = 'success'
                    this.message = 'Notifications Deleted Successfully'
                    this.deleteLoading = false;
                    this.snackbar = true;
                    this.fetchnotifications()
                })
                .catch(error => {
                    this.color = 'error'
                    this.message = 'An error Occurred'
                    this.deleteLoading = false;
                    this.snackbar = true;
                })
            } else {
                this.color = 'error'
                this.message = 'Nothing is selected'
                this.snackbar = true;
            }
        },
        fetchnotifications(){
            apiCall({url: '/api/notificationsPagination?page='+this.notificationPagination.current_page, method: 'GET' })
            .then(resp => {
                this.commitPaginatedNotifications(resp)
                this.unMarkAll()
            })
            .catch(error => {

            })
        },
        openAuthNotification(item){
            if(item.read_at != null){
                if (item.type == "App\\Notifications\\EventBroadcast") {
                    this.$router.push("/events/"+item.data.event.slug);
                } else if (item.type == "App\\Notifications\\CallBroadcast") {
                    this.$router.push("/call-for-proposals/"+item.data.call.slug);
                } else if (item.type == "App\\Notifications\\InnovationAdminNotif") {
                    this.$router.push("/administration-requests");
                } else if (item.type == "App\\Notifications\\ContactUsMessage") {
                    this.$router.push("/contact-us-admin");
                } else if (item.type == "App\\Notifications\\NewInnovation") {
                    this.assignInnovationData(item.data.innovation)
                    this.changeInnovationApprovalCardState();
                } else if (item.type == "App\\Notifications\\RejectInnovation") {
                    this.$router.push("/innovations-admin");
                } else if (item.type == "App\\Notifications\\ApproveInnovation") {
                    this.$router.push("/innovations-admin");
                } else if (item.type == "App\\Notifications\\VerifyInnovation") {
                    this.$router.push("/innovations-admin");
                } else if (item.type == "App\\Notifications\\UnVerifyInnovation") {
                    this.$router.push("/innovations-admin");
                } else if (item.type == "App\\Notifications\\NewInstitutionUser") {
                    this.$router.push("/institutions-admin");
                } else if (item.type == "App\\Notifications\\InstitutionUserApproved") {
                    this.$router.push("/institutions/"+item.data.institution.slug);
                } else if (item.type == "App\\Notifications\\NewInstitutionInnovation") {
                    this.$router.push("/institutions/" + item.data.institution.slug);
                } else if (item.type == "App\\Notifications\\InstitutionInnovationApproved") {
                    this.$router.push("/institutions/"+item.data.institution.slug);
                }  else if (item.type == "App\\Notifications\\IndividualInvestmentOffer") {
                    this.$router.push("/innovations-admin");
                } else if (item.type == "App\\Notifications\\IndividualOfferRejected") {
                    this.$router.push("/innovations/" + item.data.data.innovation.slug);
                } else if (item.type == "App\\Notifications\\IndividualOfferAccepted") {
                    this.$router.push("/innovations/" + item.data.data.innovation.slug);
                } else if (item.type == "App\\Notifications\\InnovationAdminAccepted") {
                    this.$router.push("/innovations/" + item.data.data.innovation.slug);
                } else if (item.type == "App\\Notifications\\FirmInvestmentOffer") {
                    this.$router.push("/innovations-admin");
                } else if (item.type == "App\\Notifications\\FirmOfferAccepted") {
                    this.$router.push("/innovations/" + item.data.data.innovation.slug);
                } else if (item.type == "App\\Notifications\\FirmOfferRejected") {
                    this.$router.push("/innovations/" + item.data.data.innovation.slug);
                } else if (item.type == "App\\Notifications\\BroadcastMessage") {
                    this.$router.push("/broadcasts");
                }
            }else{
                this.loading = true
                apiCall({url: '/api/notificationRead/'+item.id, method: 'GET' })
                .then(resp => {
                    if (item.type == "App\\Notifications\\EventBroadcast") {
                        this.$router.push("/events/"+item.data.event.slug);
                    } else if (item.type == "App\\Notifications\\CallBroadcast") {
                        this.$router.push("/call-for-proposals/"+item.data.call.slug);
                    } else if (item.type == "App\\Notifications\\InnovationAdminNotif") {
                        this.$router.push("/administration-requests");
                    } else if (item.type == "App\\Notifications\\ContactUsMessage") {
                        this.$router.push("/contact-us-admin");
                    } else if (item.type == "App\\Notifications\\NewInnovation") {
                        this.assignInnovationData(item.data.innovation)
                        this.changeInnovationApprovalCardState();
                    } else if (item.type == "App\\Notifications\\RejectInnovation") {
                        this.$router.push("/innovations-admin");
                    } else if (item.type == "App\\Notifications\\ApproveInnovation") {
                        this.$router.push("/innovations-admin");
                    } else if (item.type == "App\\Notifications\\VerifyInnovation") {
                        this.$router.push("/innovations-admin");
                    } else if (item.type == "App\\Notifications\\UnVerifyInnovation") {
                        this.$router.push("/innovations-admin");
                    } else if (item.type == "App\\Notifications\\NewInstitutionUser") {
                        this.$router.push("/institutions-admin");
                    } else if (item.type == "App\\Notifications\\InstitutionUserApproved") {
                        this.$router.push("/institutions/"+item.data.institution.slug);
                    } else if (item.type == "App\\Notifications\\NewInstitutionInnovation") {
                        this.$router.push("/institutions/" + item.data.institution.slug);
                    } else if (item.type == "App\\Notifications\\InstitutionInnovationApproved") {
                        this.$router.push("/institutions/"+item.data.institution.slug);
                    }  else if (item.type == "App\\Notifications\\IndividualInvestmentOffer") {
                        this.$router.push("/innovations-admin");
                    } else if (item.type == "App\\Notifications\\IndividualOfferRejected") {
                        this.$router.push("/innovations/" + item.data.data.innovation.slug);
                    } else if (item.type == "App\\Notifications\\IndividualOfferAccepted") {
                        this.$router.push("/innovations/" + item.data.data.innovation.slug);
                    } else if (item.type == "App\\Notifications\\InnovationAdminAccepted") {
                        this.$router.push("/innovations/" + item.data.data.innovation.slug);
                    } else if (item.type == "App\\Notifications\\FirmInvestmentOffer") {
                        this.$router.push("/innovations-admin");
                    } else if (item.type == "App\\Notifications\\FirmOfferAccepted") {
                        this.$router.push("/innovations/" + item.data.data.innovation.slug);
                    } else if (item.type == "App\\Notifications\\FirmOfferRejected") {
                        this.$router.push("/innovations/" + item.data.data.innovation.slug);
                    } else if (item.type == "App\\Notifications\\BroadcastMessage") {
                        this.$router.push("/broadcasts");
                    }
                    this.loading = false
                })
                .catch(error => {
                    console.log(error.response)
                })
            }
            
        },
        removeTags(str) {
            if ((str===null) || (str===''))
            return false;
            else
            str = str.toString();
            return str.replace( /(<([^>]+)>)/ig, '');
        },
        text_truncate (str) {
            var length = 200;
            var ending = '...';
            
            if (str.length > length) {
                return str.substring(0, length - ending.length) + ending;
            } else {
                return str;
            }
        }
    },
	computed: {
		...mapGetters([
            'paginatedNotifications',
            'notificationPagination',
            'isAuthenticated'
        ]),
        length: function() {
            return Math.ceil(this.notificationPagination.total / this.notificationPagination.per_page);
        }
        
    },
    watch: {

  },

}
</script>