<template>
  <div class="welcome">
    <v-progress-linear
            v-if="publicEventLoader"
            height="1"
            indeterminate
            color="primary"
            >
            </v-progress-linear>
    <v-container>
      <v-layout row wrap>
        <v-flex xs12 md12>
          <div v-if="publicEvents.length == 0">
            <v-card outlined class="mt-5 mx-5" style="border-radius: 25px;">
              <v-layout row wrap class="my-10">
                <v-flex xs12 md1>
                  <v-container fill-height fluid>
                    <v-row align="center" justify="center">
                      <v-col class="text-center">
                        <v-icon large class="text--text">
                          mdi-alert-circle-outline
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-flex>
                <v-flex xs12 md11>
                  <v-container fill-height fluid>
                    <v-row align="center" justify="center">
                      <v-col class="text-center">
                        No Upcoming Events Found at This Time</v-col
                      >
                    </v-row>
                  </v-container>
                </v-flex>
              </v-layout>
            </v-card>
          </div>
          <div v-else>
            <v-card outlined class="mt-5 mx-5" style="border-radius: 25px;">
              <div
                id="chatList"
                class="pa-3"
                style="
                  margin: 1px, 1px;
                  padding: 1px;
                  width: auto;
                  height: 80vh;
                  overflow-x: hidden;
                  overflow-y: scroll;
                  text-align: justify;
                "
              >
                <v-layout column>
                  <div v-if="publicEvents.length != 0">
                    <template v-for="(event, index) in publicEvents">
                      <div :key="index">
                        <v-flex xs12 class="mb-4">
                          <a style="text-decoration: none">
                            <v-card outlined @click="goToEvent(event)" style="border-radius: 25px;">
                              <v-card-text>
                                <v-layout row wrap>
                                  <v-flex xs12 md4>
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div
                                          align="center"
                                          class="text--text display-2 font-weight-black mt-8"
                                        >
                                          {{ event.start_time | moment("Do") }}
                                        </div>
                                      </v-flex>
                                      <v-flex xs12>
                                        <div
                                          align="center"
                                          class="text--text mb-3"
                                        >
                                          {{
                                            event.start_time
                                              | moment("MMM YYYY")
                                          }}
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 md8>
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div class="mt-7 title">
                                          {{ event.title }}
                                        </div>
                                        <div>
                                        <i><b>Event Type:</b> {{ event.event_type.name }}</i></div>
                                        <div>
                                        <i><b>Venue:</b> {{ event.location }}</i></div>
                                        <div>
                                        <i><b>Cost:</b> Kshs {{ event.entry_cost }}</i></div>
                                        <div class="mb-2 text-subtitle-1">
                                          <template
                                            v-for="(
                                              event_sector, index
                                            ) in event.event_sectors.slice(
                                              0,
                                              5
                                            )"
                                          >
                                            <v-chip
                                              x-small
                                              :color="event_sector.sector.color"
                                              class="white--text mr-1"
                                              :key="index"
                                            >
                                              #{{ event_sector.sector.name }}
                                            </v-chip>
                                          </template>
                                          <v-chip
                                            class="white--text"
                                            color="primary"
                                            small
                                            v-if="
                                              event.event_sectors.length > 5
                                            "
                                          >
                                            + More
                                          </v-chip>
                                        </div>
                                        <v-clamp
                                          autoresize
                                          :max-lines="4"
                                          class="mb-5 mr-5 caption"
                                          >{{ event.brief }}
                                        </v-clamp>
                                        <!-- <div class="subtitle-2"><b>Created:</b> </div> -->
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-card-text>
                            </v-card>
                          </a>
                        </v-flex>
                      </div>
                    </template>
                  </div>
                </v-layout>
              </div>
            </v-card>
            <div align="center" class="my-7" v-if="publicEventPagination.total != 0">
                <v-pagination
                    v-if="publicAskLength != 0"
                    :length="publicAskLength"
                    total-visible="10"
                    v-model="publicEventPagination.current_page"
                    @input="changePage()"
                    circle
                >
                </v-pagination>
            </div>
          </div>
        </v-flex>
        <!-- <v-flex xs12 md3>
          <v-card class="mx-auto pa-1 mt-5" outlined>
            <v-layout column>
              <v-flex xs12>
                <div class="mt-3 ml-1"><b>Search</b></div>
              </v-flex>
              <v-flex xs12>
                <div class="pa-3 mt-2">
                  <v-layout row wrap>
                    <v-flex xs9>
                      <v-text-field class="mx-1" outlined dense></v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <div align="right">
                        <v-btn depressed class="primary white--text mx-1">
                          <v-icon> mdi-magnify </v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>
            </v-layout>
          </v-card>
          <v-card class="mx-auto pa-1 mt-5" outlined>
            <v-layout column>
              <v-flex xs12>
                <div class="mt-3 ml-1"><b>Deadline</b></div>
              </v-flex>
              <v-flex xs12>
                <v-chip-group column active-class="primary--text">
                  <v-chip v-for="tag in tags" :key="tag">
                    {{ tag }}
                  </v-chip>
                </v-chip-group>
              </v-flex>
            </v-layout>
          </v-card>
          <v-card class="mx-auto pa-1 mt-5" outlined>
            <v-layout column>
              <v-flex xs12>
                <div class="mt-3 ml-1"><b>Sectors</b></div>
              </v-flex>
              <v-flex xs12>
                <v-chip-group multiple column active-class="primary--text">
                  <v-chip v-for="tag in sectors" :key="tag">
                    {{ tag }}
                  </v-chip>
                </v-chip-group>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex> -->
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import VClamp from "vue-clamp";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    VClamp,
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      now: Math.trunc(new Date().getTime() / 1000),
      color: "",
      message: "",
      snackbar: false,
      loading: false,
      selection: 1,
      drawer: false,
      dialog: false,
      ex4: [],
      timePeriods: [
        "Today",
        "Past Week",
        "Past 30 Days",
        "Past Year",
        "All Time",
      ],
      tags: ["Today", "In a Week", "In 30 Days", "In 6 Months", "In a Year"],
      sectors: [
        "#Health",
        "#Agriculture",
        "#Food Security",
        "#Business Tech",
        "#Energy",
        "#ICT",
        "#E-Commerce",
      ],
    };
  },
  mounted() {
    window.setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
    }, 1000);
  },
  created() {
    window.scrollTo(0, 0);
    this.initialize();
  },
  methods: {
    ...mapActions(["startPublicEventLoader", "fetchPublicEvents"]),
    goToEvent(event) {
      this.$router.push("/events/" + event.slug);
    },
    initialize() {
      this.startPublicEventLoader();
      this.fetchPublicEvents(1);
    },
    changePage() {
      window.scrollTo(0, 0);
      this.startPublicEventLoader();
      if (this.filteredPublicAsks == true) {
          apiCall({
          url:
              "/api/investment-interest?type=filter&page=" +
              this.publicEventPagination.current_page,
          data: this.filter,
          method: "POST",
          })
          .then((resp) => {
              this.loading = false;
              this.searchLoading = false;
              this.filteredPublicAsks = true;
              this.filterInterestInnovations(resp);
          })
          .catch((error) => {
              this.message = "An Error Occurred; Please Try Again";
              this.color = "error";
              this.loading = false;
              this.searchLoading = false;
              this.snackbar = true;
          });
      } else {
          this.fetchPublicEvents(
          this.publicEventPagination.current_page
          );
      }
      },
    calculateDeadline(value) {
      var milliseconds = Math.trunc(Date.parse(value) / 1000);
      var seconds = (milliseconds - this.now) % 60;
      var minutes = Math.trunc((milliseconds - this.now) / 60) % 60;
      var hours = Math.trunc((milliseconds - this.now) / 60 / 60) % 24;
      var days = Math.trunc((milliseconds - this.now) / 60 / 60 / 24);

      return days + "d " + hours + "h " + minutes + "m " + seconds + "s";
    },
  },
  computed: {
    ...mapGetters([
      "publicEvents",
      "publicEventLoader",
      "publicEventPagination",
    ]),

    publicAskLength: function () {
        return Math.ceil(
            this.publicEventPagination.total /
            this.publicEventPagination.per_page
        );
    },

    dateInMilliseconds() {
      return Math.trunc(Date.parse("2022-12-25 00:00:01") / 1000);
    },
    seconds() {
      return (this.dateInMilliseconds - this.now) % 60;
    },
    minutes() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60) % 60;
    },
    hours() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60) % 24;
    },
    days() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60 / 24);
    },
  },
};
</script>
