<template>
  <div class="welcome">
    <v-layout column>
      <v-flex xs12 class="mt-5 mb-5">
        <v-container>
          <div class="mx-5">
            <v-progress-linear
              v-if="interestInnovationLoader && interestInnovations.length!=0"
              height="1"
              indeterminate
              color="primary"
              
            >
            </v-progress-linear>
          </div>
          <v-card outlined v-if="interestInnovations.length!=0" style="border-radius: 25px;">
            <v-layout column>
              <v-flex xs12 class="pa-2">
                <div class="pa-3 mx-2">
                  <v-layout row wrap>
                    <v-flex xs12 md8>
                      <div class="title">
                        <b>Based on Your Interests</b>
                        <v-icon right>mdi-rocket-launch</v-icon>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div align="right">
                        <v-btn
                          depressed
                          class="text-none secondary primary--text mr-1"
                          @click="refreshInterests()"
                          :loading="interestLoading"
                        >
                          <v-icon>mdi-autorenew</v-icon>
                        </v-btn>
                        <v-btn text class="text-none primary white--text" router to="/innovations/list/interests"
                          >View More</v-btn
                        >
                      </div>
                    </v-flex>
                  </v-layout>
                </div>

                <v-carousel
                  v-model="interestCarousel"
                  height="570"
                  show-arrows-on-hover
                  class="hidden-sm-and-down"
                >
                  <v-carousel-item
                    v-for="(slide, i) in interestInnovationsLength"
                    :key="i"
                  >
                    <v-layout row wrap class="mx-1">
                      <template
                        v-for="(interest, index) in interestArray[
                          interestCarousel
                        ]"
                      >
                        <v-flex xs12 md3>
                          <v-card
                            :loading="loading"
                            class="mx-1 mt-7 d-flex flex-column"                            
                            style="border-radius: 25px;"
                            outlined
                            height="490"
                          >
                            <template slot="progress">
                              <v-progress-linear
                                color="deep-purple"
                                height="10"
                                indeterminate
                              ></v-progress-linear>
                            </template>

                            <v-img
                              max-height="150"
                              v-if="interest.featured_image==null"
                              src="img/innovation.jpg"
                              style="border-radius: 25px;"
                            >
                            </v-img>
                            <v-img
                              max-height="150"
                              v-else
                              :src="path+'/storage/innovation_pictures/'+interest.featured_image"
                              style="border-radius: 25px;"
                            >
                            </v-img>

                            <div class="title pa-3">
                              <v-clamp autoresize :max-lines="2"
                                >{{ interest.name }}
                              </v-clamp>
                            </div>

                            <v-card-text>
                              <v-row align="center" class="mx-0" v-if="$can('rating_view') && interest.rating_public == 1">
                                <v-rating
                                  :value="interest.average_rating"
                                  color="amber"
                                  dense
                                  half-increments
                                  readonly
                                  size="14"
                                ></v-rating>

                                <div class="grey--text ms-4" >
                                  {{ interest.average_rating }} ({{
                                    interest.total_rating
                                  }})
                                </div>
                              </v-row>

                              <div class="my-4 text-subtitle-1">
                                <template
                                  v-for="(
                                    innovation_sector, index
                                  ) in interest.innovation_sectors.slice(0, 5)"
                                >
                                  <v-chip
                                    x-small
                                    :color="innovation_sector.sector.color"
                                    class="white--text mr-1"
                                    :key="index"
                                  >
                                    #{{ innovation_sector.sector.name }}
                                  </v-chip>
                                </template>
                                <v-chip
                                  class="white--text"
                                  color="primary"
                                  small
                                  v-if="interest.innovation_sectors.length > 5"
                                >
                                  + More
                                </v-chip>
                              </div>
                              <v-clamp autoresize :max-lines="2"
                                >{{ interest.brief }}
                              </v-clamp>
                            </v-card-text>
                            <v-spacer></v-spacer>
                            <div align="center" v-if="interest.verified==1">
                              <v-chip
                                class="ma-2"
                                color="blue"
                                label
                                text-color="white"
                              >
                                <v-icon left>
                                  mdi-check-decagram
                                </v-icon>
                                IB Verified
                              </v-chip>
                            </div>
                            
                            <v-divider class="mx-4"></v-divider>

                            <v-card-actions>
                              <v-btn
                                class="text-none"
                                color="primary"
                                block
                                depressed
                                rounded
                                @click="goToInnovation(interest)"
                              >
                                View
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-carousel-item>
                </v-carousel>
                <v-carousel
                  v-model="interestCarousel"
                  height="2130"
                  show-arrows-on-hover
                  class="hidden-md-and-up"
                >
                  <v-carousel-item
                    v-for="(slide, i) in interestInnovationsLength"
                    :key="i"
                  >
                    <v-layout row wrap class="mx-1">
                      <template
                        v-for="(interest, index) in interestArray[
                          interestCarousel
                        ]"
                      >
                        <v-flex xs12 md3>
                          <v-card
                            :loading="loading"
                            class="mx-1 mt-7"
                            style="border-radius: 25px;"                            
                            outlined
                          >
                            <template slot="progress">
                              <v-progress-linear
                                color="deep-purple"
                                height="10"
                                indeterminate
                              ></v-progress-linear>
                            </template>

                            <v-img
                              max-height="150"
                              v-if="interest.featured_image==null"
                              src="img/innovation.jpg"
                              style="border-radius: 25px;"
                            >
                            </v-img>
                            <v-img
                              max-height="150"
                              v-else
                              :src="path+'/storage/innovation_pictures/'+interest.featured_image"
                              style="border-radius: 25px;"
                            >
                            </v-img>

                            <div class="title pa-3">
                              <v-clamp autoresize :max-lines="2"
                                >{{ interest.name }}
                              </v-clamp>
                            </div>

                            <v-card-text>
                              <v-row align="center" class="mx-0">
                                <v-rating
                                  :value="interest.average_rating"
                                  color="amber"
                                  dense
                                  half-increments
                                  readonly
                                  size="14"
                                ></v-rating>

                                <div class="grey--text ms-4" >
                                  {{ interest.average_rating }} ({{
                                    interest.total_rating
                                  }})
                                </div>
                              </v-row>

                              <div class="my-4 text-subtitle-1">
                                <template
                                  v-for="(
                                    innovation_sector, index
                                  ) in interest.innovation_sectors.slice(0, 5)"
                                >
                                  <v-chip
                                    x-small
                                    :color="innovation_sector.sector.color"
                                    class="white--text mr-1"
                                    :key="index"
                                  >
                                    #{{ innovation_sector.sector.name }}
                                  </v-chip>
                                </template>
                                <v-chip
                                  class="white--text"
                                  color="primary"
                                  small
                                  v-if="interest.innovation_sectors.length > 5"
                                >
                                  + More
                                </v-chip>
                              </div>
                              <v-clamp autoresize :max-lines="2"
                                >{{ interest.brief }}
                              </v-clamp>
                            </v-card-text>
                            <v-spacer></v-spacer>
                            <div align="center" v-if="interest.verified==1">
                              <v-chip
                                class="ma-2"
                                color="blue"
                                label
                                text-color="white"
                              >
                                <v-icon left>
                                  mdi-check-decagram
                                </v-icon>
                                IB Verified
                              </v-chip>
                            </div>

                            <v-divider class="mx-4"></v-divider>

                            <v-card-actions>
                              <v-btn
                                class="text-none"
                                color="primary"
                                block
                                depressed
                                rounded
                                @click="goToInnovation(interest)"
                              >
                                View
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-carousel-item>
                </v-carousel>
              </v-flex>
            </v-layout>
          </v-card>

          <v-card outlined class="mt-4" v-if="recommendedInnovations.length!=0" style="border-radius: 25px;">
            <div class="mx-5">
              <v-progress-linear
                v-if="recommendedInnovationLoader"
                height="1"
                indeterminate
                color="primary"
              >
              </v-progress-linear>
            </div>              
            <v-layout column>
              <v-flex xs12 class="pa-2">
                <div class="pa-3 mx-2">
                  <v-layout row wrap>
                    <v-flex xs12 md8>
                      <div class="title">
                        <b>Recommended for You</b>
                        <v-icon right>mdi-handshake</v-icon>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div align="right">
                        <v-btn text class="text-none primary white--text"
                        to="/innovations/list/recommended">View More</v-btn
                        >
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
                <v-carousel
                  v-model="recommendedCarousel"
                  height="570"
                  show-arrows-on-hover
                  class="hidden-sm-and-down"
                >
                  <v-carousel-item
                    v-for="(slide, i) in recommendedInnovationsLength"
                    :key="i"
                  >
                    <v-layout row wrap class="mx-1">
                      <template
                        v-for="(recommended, index) in recommendedArray[
                          recommendedCarousel
                        ]"
                      >
                        <v-flex xs12 md3>
                          <v-card
                            :loading="loading"
                            class="mx-1 mt-7 d-flex flex-column"
                            style="border-radius: 25px;"
                            outlined
                            height="490"
                          >
                            <template slot="progress">
                              <v-progress-linear
                                color="deep-purple"
                                height="10"
                                indeterminate
                              ></v-progress-linear>
                            </template>

                            <v-badge
                              v-if="recommended.score[0] == '-'"
                              bordered
                              color="error"
                              :content="recommended.score + '%'"
                              overlap
                            >
                            </v-badge>
                            <v-badge
                              v-else
                              bordered
                              color="success"
                              class="mr-5"
                              :content="recommended.score + '%'"
                              overlap
                              style="z-index: 3"
                            >
                            </v-badge>
                            <v-img
                              max-height="150"
                              v-if="recommended.innovation.featured_image==null"
                              src="img/innovation.jpg"
                              style="border-radius: 25px;"
                            >
                            </v-img>
                            <v-img
                              max-height="150"
                              v-else
                              :src="path+'/storage/innovation_pictures/'+recommended.innovation.featured_image"
                              style="border-radius: 25px;"
                            >
                            </v-img>

                            <div class="title pa-3">
                              <v-clamp autoresize :max-lines="2"
                                >{{ recommended.innovation.name }}
                              </v-clamp>
                            </div>

                            <v-card-text>
                              <v-row align="center" class="mx-0" v-if="$can('rating_view') && recommended.innovation.rating_public == 1">
                                <v-rating
                                  :value="recommended.innovation.average_rating"
                                  color="amber"
                                  dense
                                  half-increments
                                  readonly
                                  size="14"
                                ></v-rating>

                                <div class="grey--text ms-4">
                                  {{ recommended.innovation.average_rating }} ({{
                                    recommended.innovation.total_rating
                                  }})
                                </div>
                              </v-row>

                              <div class="my-4 text-subtitle-1">
                                <template
                                  v-for="(
                                    innovation_sector, index
                                  ) in recommended.innovation.innovation_sectors.slice(
                                    0,
                                    5
                                  )"
                                >
                                  <v-chip
                                    x-small
                                    :color="innovation_sector.sector.color"
                                    class="white--text mr-1"
                                    :key="index"
                                  >
                                    #{{ innovation_sector.sector.name }}
                                  </v-chip>
                                </template>
                                <v-chip
                                  class="white--text"
                                  color="primary"
                                  small
                                  v-if="
                                    recommended.innovation.innovation_sectors
                                      .length > 5
                                  "
                                >
                                  + More
                                </v-chip>
                              </div>
                              <v-clamp autoresize :max-lines="2"
                                >{{ recommended.innovation.brief }}
                              </v-clamp>
                            </v-card-text>
                            <v-spacer></v-spacer>
                            <div align="center" v-if="recommended.innovation.verified==1">
                              <v-chip
                                class="ma-2"
                                color="blue"
                                label
                                text-color="white"
                              >
                                <v-icon left>
                                  mdi-check-decagram
                                </v-icon>
                                IB Verified
                              </v-chip>
                            </div>
                            <v-divider class="mx-4"></v-divider>

                            <v-card-actions>
                              <v-btn
                                class="text-none"
                                color="primary"
                                block
                                depressed
                                rounded
                                @click="goToInnovation(recommended.innovation)"
                              >
                                View
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-carousel-item>
                </v-carousel>
                <v-carousel
                  v-model="recommendedCarousel"
                  height="2130"
                  show-arrows-on-hover
                  class="hidden-md-and-up"
                >
                  <v-carousel-item
                    v-for="(slide, i) in recommendedInnovationsLength"
                    :key="i"
                  >
                    <v-layout row wrap class="mx-1">
                      <template
                        v-for="(recommended, index) in recommendedArray[
                          recommendedCarousel
                        ]"
                      >
                        <v-flex xs12 md3>
                          <v-card
                            :loading="loading"
                            class="mx-1 mt-7 d-flex flex-column"
                            style="border-radius: 25px;"
                            outlined
                            height="490"
                          >
                            <template slot="progress">
                              <v-progress-linear
                                color="deep-purple"
                                height="10"
                                indeterminate
                              ></v-progress-linear>
                            </template>
                            <v-badge
                              v-if="recommended.score[0] == '-'"
                              bordered
                              color="error"
                              :content="recommended.score + '%'"
                              overlap
                            >
                            </v-badge>
                            <v-badge
                              v-else
                              bordered
                              color="success"
                              class="mr-5"
                              :content="recommended.score + '%'"
                              overlap
                              style="z-index: 3"
                            >
                            </v-badge>
                            <v-img
                              max-height="150"
                              v-if="recommended.innovation.featured_image==null"
                              src="img/innovation.jpg"
                              style="border-radius: 25px;"
                            >
                            </v-img>
                            <v-img
                              max-height="150"
                              v-else
                              :src="path+'/storage/innovation_pictures/'+recommended.innovation.featured_image"
                              style="border-radius: 25px;"
                            >
                            </v-img>
                            <div class="title pa-3">
                              <v-clamp autoresize :max-lines="2"
                                >{{ recommended.innovation.name }}
                              </v-clamp>
                            </div>

                            <v-card-text>
                              <v-row align="center" class="mx-0" v-if="$can('rating_view') && recommended.innovation.rating_public == 1">
                                <v-rating
                                  :value="recommended.innovation.average_rating"
                                  color="amber"
                                  dense
                                  half-increments
                                  readonly
                                  size="14"
                                ></v-rating>

                                <div class="grey--text ms-4">
                                  {{ recommended.innovation.average_rating }} ({{
                                    recommended.innovation.total_rating
                                  }})
                                </div>
                              </v-row>

                              <div class="my-4 text-subtitle-1">
                                <template
                                  v-for="(
                                    innovation_sector, index
                                  ) in recommended.innovation.innovation_sectors.slice(
                                    0,
                                    5
                                  )"
                                >
                                  <v-chip
                                    x-small
                                    :color="innovation_sector.sector.color"
                                    class="white--text mr-1"
                                    :key="index"
                                  >
                                    #{{ innovation_sector.sector.name }}
                                  </v-chip>
                                </template>
                                <v-chip
                                  class="white--text"
                                  color="primary"
                                  small
                                  v-if="
                                    recommended.innovation.innovation_sectors
                                      .length > 5
                                  "
                                >
                                  + More
                                </v-chip>
                              </div>
                              <v-clamp autoresize :max-lines="2"
                                >{{ recommended.innovation.brief }}
                              </v-clamp>
                            </v-card-text>
                            <v-spacer></v-spacer>
                            <div align="center" v-if="recommended.innovation.verified==1">
                              <v-chip
                                class="ma-2"
                                color="blue"
                                label
                                text-color="white"
                              >
                                <v-icon left>
                                  mdi-check-decagram
                                </v-icon>
                                IB Verified
                              </v-chip>
                            </div>
                            <v-divider class="mx-4"></v-divider>

                            <v-card-actions>
                              <v-btn
                                class="text-none"
                                color="primary"
                                block
                                depressed
                                rounded
                                @click="goToInnovation(recommended.innovation)"
                              >
                                View
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-carousel-item>
                </v-carousel>
              </v-flex>
            </v-layout>
          </v-card>
          <v-card outlined class="mt-4" style="border-radius: 25px;">
            <div class="mx-5">
              <v-progress-linear
                v-if="popularInnovationLoader"
                height="1"
                indeterminate
                color="primary"
              >
              </v-progress-linear>
            </div>              
            <v-layout  out column>
              <v-flex xs12 class="pa-2">
                <div class="pa-3 mx-2">
                  <v-layout row wrap>
                    <v-flex xs12 md8>
                      <div class="title">
                        <b>Popular Now</b>
                        <v-icon right>mdi-star-shooting</v-icon>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div align="right">
                        <v-btn text class="text-none primary white--text"
                        router to="/innovations/list/popular">View More</v-btn
                        >
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
                <v-carousel
                  v-model="popularCarousel"
                  height="570"
                  show-arrows-on-hover
                  class="hidden-sm-and-down"
                >
                  <v-carousel-item
                    v-for="(slide, i) in popularInnovationsLength"
                    :key="i"
                  >
                    <v-layout row wrap class="mx-1">
                      <template
                        v-for="(popular, index) in popularArray[
                          popularCarousel
                        ]"
                      >
                        <v-flex xs12 md3>
                          <v-card
                            :loading="loading"
                            class="mx-1 mt-7 d-flex flex-column"                            
                            style="border-radius: 25px;"
                            outlined
                            height="490"
                          >
                            <template slot="progress">
                              <v-progress-linear
                                color="deep-purple"
                                height="10"
                                indeterminate
                              ></v-progress-linear>
                            </template>
                            <v-badge
                              bordered
                              color="success"
                              class="mr-5"
                              :content="popular.views"
                              overlap
                              style="z-index: 3"
                            >
                            </v-badge>
                            <v-img
                              max-height="150"
                              v-if="popular.featured_image==null"
                              src="img/innovation.jpg"
                              style="border-radius: 25px;"
                            >
                            </v-img>
                            <v-img
                              max-height="150"
                              v-else
                              :src="path+'/storage/innovation_pictures/'+popular.featured_image"
                              style="border-radius: 25px;"
                            >
                            </v-img>

                            <div class="title pa-3">
                              <v-clamp autoresize :max-lines="2"
                                >{{ popular.name }}
                              </v-clamp>
                            </div>

                            <v-card-text>
                              <v-row align="center" class="mx-0" v-if="$can('rating_view') && popular.rating_public == 1">
                                <v-rating
                                  :value="popular.average_rating"
                                  color="amber"
                                  dense
                                  half-increments
                                  readonly
                                  size="14"
                                ></v-rating>

                                <div class="grey--text ms-4">
                                  {{ popular.average_rating }} ({{
                                    popular.total_rating
                                  }})
                                </div>
                              </v-row>

                              <div class="my-4 text-subtitle-1">
                                <template
                                  v-for="(
                                    innovation_sector, index
                                  ) in popular.innovation_sectors.slice(0, 5)"
                                >
                                  <v-chip
                                    x-small
                                    :color="innovation_sector.sector.color"
                                    class="white--text mr-1"
                                    :key="index"
                                  >
                                    #{{ innovation_sector.sector.name }}
                                  </v-chip>
                                </template>
                                <v-chip
                                  class="white--text"
                                  color="primary"
                                  small
                                  v-if="popular.innovation_sectors.length > 5"
                                >
                                  + More
                                </v-chip>
                              </div>
                              <v-clamp autoresize :max-lines="2"
                                >{{ popular.brief }}
                              </v-clamp>
                            </v-card-text>
                            <v-spacer></v-spacer>
                            <div align="center" v-if="popular.verified==1">
                              <v-chip
                                class="ma-2"
                                color="blue"
                                label
                                text-color="white"
                              >
                                <v-icon left>
                                  mdi-check-decagram
                                </v-icon>
                                IB Verified
                              </v-chip>
                            </div>
                            <v-divider class="mx-4"></v-divider>

                            <v-card-actions>
                              <v-btn
                                class="text-none"
                                color="primary"
                                block
                                depressed
                                rounded
                                @click="goToInnovation(popular)"
                              >
                                View
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-carousel-item>
                </v-carousel>
                <v-carousel
                  v-model="popularCarousel"
                  height="2130"
                  show-arrows-on-hover
                  class="hidden-md-and-up"
                >
                  <v-carousel-item
                    v-for="(slide, i) in popularInnovationsLength"
                    :key="i"
                  >
                    <v-layout row wrap class="mx-1">
                      <template
                        v-for="(popular, index) in popularArray[
                          popularCarousel
                        ]"
                      >
                        <v-flex xs12 md3>
                          <v-card
                            :loading="loading"
                            class="mx-1 mt-7 d-flex flex-column"
                            style="border-radius: 25px;"
                            outlined
                            height="490"
                          >
                            <template slot="progress">
                              <v-progress-linear
                                color="deep-purple"
                                height="10"
                                indeterminate
                              ></v-progress-linear>
                            </template>
                            <v-badge
                              bordered
                              color="success"
                              class="mr-5"
                              :content="popular.views"
                              overlap
                              style="z-index: 3"
                            >
                            </v-badge>
                            <v-img
                              max-height="150"
                              v-if="popular.featured_image==null"
                              src="img/innovation.jpg"
                              style="border-radius: 25px;"
                            >
                            </v-img>
                            <v-img
                              max-height="150"
                              v-else
                              :src="path+'/storage/innovation_pictures/'+popular.featured_image"
                              style="border-radius: 25px;"
                            >
                            </v-img>
                            <div class="title pa-3">
                              <v-clamp autoresize :max-lines="2"
                                >{{ popular.name }}
                              </v-clamp>
                            </div>

                            <v-card-text>
                              <v-row align="center" class="mx-0" v-if="$can('rating_view') && popular.rating_public == 1">
                                <v-rating
                                  :value="popular.average_rating"
                                  color="amber"
                                  dense
                                  half-increments
                                  readonly
                                  size="14"
                                ></v-rating>

                                <div class="grey--text ms-4">
                                  {{ popular.average_rating }} ({{
                                    popular.total_rating
                                  }})
                                </div>
                              </v-row>

                              <div class="my-4 text-subtitle-1">
                                <template
                                  v-for="(
                                    innovation_sector, index
                                  ) in popular.innovation_sectors.slice(0, 5)"
                                >
                                  <v-chip
                                    x-small
                                    :color="innovation_sector.sector.color"
                                    class="white--text mr-1"
                                    :key="index"
                                  >
                                    #{{ innovation_sector.sector.name }}
                                  </v-chip>
                                </template>
                                <v-chip
                                  class="white--text"
                                  color="primary"
                                  small
                                  v-if="popular.innovation_sectors.length > 5"
                                >
                                  + More
                                </v-chip>
                              </div>
                              <v-clamp autoresize :max-lines="2"
                                >{{ popular.brief }}
                              </v-clamp>
                            </v-card-text>
                            <v-spacer></v-spacer>
                            <div align="center" v-if="popular.verified==1">
                              <v-chip
                                class="ma-2"
                                color="blue"
                                label
                                text-color="white"
                              >
                                <v-icon left>
                                  mdi-check-decagram
                                </v-icon>
                                IB Verified
                              </v-chip>
                            </div>
                            <v-divider class="mx-4"></v-divider>

                            <v-card-actions>
                              <v-btn
                                class="text-none"
                                color="primary"
                                block
                                depressed
                                rounded
                                @click="goToInnovation(popular)"
                              >
                                View
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-carousel-item>
                </v-carousel>
              </v-flex>
            </v-layout>
          </v-card>
          <v-card outlined class="mt-4" style="border-radius: 25px;">
            <div class="mx-5">
              <v-progress-linear
                  v-if="recentInnovationLoader"
                  height="1"
                  indeterminate
                  color="primary"
                >
                </v-progress-linear>
            </div>
            
            <v-layout column>
              <v-flex xs12 class="pa-2">
                <div class="pa-3 mx-2">
                  <v-layout row wrap>
                    <v-flex xs12 md8>
                      <div class="title">
                        <b>Recent Innovations</b>
                        <v-icon right>mdi-history</v-icon>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div align="right">
                        <v-btn text class="text-none primary white--text"
                        router to="/innovations/list/recent">View More</v-btn
                        >
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
                <v-carousel
                  v-model="recentCarousel"
                  height="570"
                  show-arrows-on-hover
                  class="hidden-sm-and-down"
                >
                  <v-carousel-item
                    v-for="(slide, i) in recentInnovationsLength"
                    :key="i"
                  >
                    <v-layout row wrap class="mx-1">
                      <template
                        v-for="(recent, index) in recentArray[recentCarousel]"
                      >
                        <v-flex xs12 md3>
                          <v-card
                            :loading="loading"
                            class="mx-1 mt-7 d-flex flex-column"
                            style="border-radius: 25px;"
                            outlined
                            height="490"
                          >
                            <template slot="progress">
                              <v-progress-linear
                                color="deep-purple"
                                height="10"
                                indeterminate
                              ></v-progress-linear>
                            </template>
                            <v-img
                              max-height="150"
                              v-if="recent.featured_image==null"
                              src="img/innovation.jpg"
                              style="border-radius: 25px;"
                            >
                            </v-img>
                            <v-img
                              max-height="150"
                              v-else
                              :src="path+'/storage/innovation_pictures/'+recent.featured_image"
                              style="border-radius: 25px;"
                            >
                            </v-img>

                            <div class="title pa-3">
                              <v-clamp autoresize :max-lines="2"
                                >{{ recent.name }}
                              </v-clamp>
                            </div>

                            <v-card-text>
                              <v-row align="center" class="mx-0" v-if="$can('rating_view') && recent.rating_public == 1">
                                <v-rating
                                  :value="recent.average_rating"
                                  color="amber"
                                  dense
                                  half-increments
                                  readonly
                                  size="14"
                                ></v-rating>

                                <div class="grey--text ms-4">
                                  {{ recent.average_rating }} ({{
                                    recent.total_rating
                                  }})
                                </div>
                              </v-row>

                              <div class="my-4 text-subtitle-1">
                                <template
                                  v-for="(
                                    innovation_sector, index
                                  ) in recent.innovation_sectors.slice(0, 5)"
                                >
                                  <v-chip
                                    x-small
                                    :color="innovation_sector.sector.color"
                                    class="white--text mr-1"
                                    :key="index"
                                  >
                                    #{{ innovation_sector.sector.name }}
                                  </v-chip>
                                </template>
                                <v-chip
                                  class="white--text"
                                  color="primary"
                                  small
                                  v-if="recent.innovation_sectors.length > 5"
                                >
                                  + More
                                </v-chip>
                              </div>
                              <v-clamp autoresize :max-lines="2"
                                >{{ recent.brief }}
                              </v-clamp>
                            </v-card-text>
                            <v-spacer></v-spacer>
                            <div align="center" v-if="recent.verified==1">
                              <v-chip
                                class="ma-2"
                                color="blue"
                                label
                                text-color="white"
                              >
                                <v-icon left>
                                  mdi-check-decagram
                                </v-icon>
                                IB Verified
                              </v-chip>
                            </div>
                            <v-divider class="mx-4"></v-divider>

                            <v-card-actions>
                              <v-btn
                                class="text-none"
                                color="primary"
                                block
                                depressed
                                rounded
                                @click="goToInnovation(recent)"
                              >
                                View
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-carousel-item>
                </v-carousel>
                <v-carousel
                  v-model="recentCarousel"
                  height="2130"
                  show-arrows-on-hover
                  class="hidden-md-and-up"
                >
                  <v-carousel-item
                    v-for="(slide, i) in recentInnovationsLength"
                    :key="i"
                  >
                    <v-layout row wrap class="mx-1">
                      <template
                        v-for="(recent, index) in recentArray[recentCarousel]"
                      >
                        <v-flex xs12 md3>
                          <v-card
                            :loading="loading"
                            class="mx-1 mt-7 d-flex flex-column"
                            style="border-radius: 25px;"
                            outlined
                            height="490"
                          >
                            <template slot="progress">
                              <v-progress-linear
                                color="deep-purple"
                                height="10"
                                indeterminate
                              ></v-progress-linear>
                            </template>
                            <v-img
                              max-height="150"
                              v-if="recent.featured_image==null"
                              src="img/innovation.jpg"
                              style="border-radius: 25px;"
                            >
                            </v-img>
                            <v-img
                              max-height="150"
                              v-else
                              :src="path+'/storage/innovation_pictures/'+recent.featured_image"
                              style="border-radius: 25px;"
                            >
                            </v-img>
                            <div class="title pa-3">
                              <v-clamp autoresize :max-lines="2"
                                >{{ recent.name }}
                              </v-clamp>
                            </div>

                            <v-card-text>
                              <v-row align="center" class="mx-0" v-if="$can('rating_view') && recent.rating_public == 1">
                                <v-rating
                                  :value="recent.average_rating"
                                  color="amber"
                                  dense
                                  half-increments
                                  readonly
                                  size="14"
                                ></v-rating>

                                <div class="grey--text ms-4">
                                  {{ recent.average_rating }} ({{
                                    recent.total_rating
                                  }})
                                </div>
                              </v-row>

                              <div class="my-4 text-subtitle-1">
                                <template
                                  v-for="(
                                    innovation_sector, index
                                  ) in recent.innovation_sectors.slice(0, 5)"
                                >
                                  <v-chip
                                    x-small
                                    :color="innovation_sector.sector.color"
                                    class="white--text mr-1"
                                    :key="index"
                                  >
                                    #{{ innovation_sector.sector.name }}
                                  </v-chip>
                                </template>
                                <v-chip
                                  class="white--text"
                                  color="primary"
                                  small
                                  v-if="recent.innovation_sectors.length > 5"
                                >
                                  + More
                                </v-chip>
                              </div>
                              <v-clamp autoresize :max-lines="2"
                                >{{ recent.brief }}
                              </v-clamp>
                            </v-card-text>
                            <v-spacer></v-spacer>
                            <div align="center" v-if="recent.verified==1">
                              <v-chip
                                class="ma-2"
                                color="blue"
                                label
                                text-color="white"
                              >
                                <v-icon left>
                                  mdi-check-decagram
                                </v-icon>
                                IB Verified
                              </v-chip>
                            </div>
                            <v-divider class="mx-4"></v-divider>

                            <v-card-actions>
                              <v-btn
                                class="text-none"
                                color="primary"
                                block
                                depressed
                                rounded
                                @click="goToInnovation(recent)"
                              >
                                View
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-carousel-item>
                </v-carousel>
              </v-flex>
            </v-layout>
          </v-card>
        </v-container>
      </v-flex>
    </v-layout>
  </div>
</template>
<style>
/* <-- remove scoped here if you have it*/
.v-carousel__controls {
  background: #ffeded !important;
  border-radius: 25px;
}

.v-carousel__controls__item {
  color: #880000 !important;
}
</style>
<script>
import apiCall from "@/utils/api";
import VClamp from "vue-clamp";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    VClamp,
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      loading: false,
      selection: 1,
      drawer: false,

      interestCarousel: 0,
      interestArray: [],
      interestLoading: false,

      recommendedCarousel: 0,
      recommendedArray: [],
      recommendedLoading: false,

      popularCarousel: 0,
      popularArray: [],
      popularLoading: false,

      recentCarousel: 0,
      recentArray: [],
      recentLoading: false,
    };
  },
  watch: {
    interestInnovations() {
      this.chunkInterestInnovations();
    },
    recommendedInnovations() {
      this.chunkRecommendedInnovations();
    },
    popularInnovations() {
      this.chunkPopularInnovations();
    },
    recentInnovations() {
      this.chunkRecentInnovations();
    },
  },
  mounted() {},
  created() {
    window.scrollTo(0, 0);
    this.initialize();
  },
  methods: {
    ...mapActions([
      "fetchInterestInnovations",
      "filterInterestInnovations",
      "startInterestInnovationLoader",
      "stopInterestInnovationLoader",

      "fetchRecommendedInnovations",
      "filterRecommendedInnovations",
      "startRecommendedInnovationLoader",
      "stopRecommendedInnovationLoader",

      "fetchPopularInnovations",
      "filterPopularInnovations",
      "startPopularInnovationLoader",
      "stopPopularInnovationLoader",

      "fetchRecentInnovations",
      "filterRecentInnovations",
      "startRecentInnovationLoader",
      "stopRecentInnovationLoader",
    ]),
    initialize() {
      this.startInterestInnovationLoader();
      this.fetchInterestInnovations(1);
      this.chunkInterestInnovations();

      this.startRecommendedInnovationLoader();
      this.fetchRecommendedInnovations(1);
      this.chunkRecommendedInnovations();

      this.startPopularInnovationLoader();
      this.fetchPopularInnovations(1);
      this.chunkPopularInnovations();

      this.startRecentInnovationLoader();
      this.fetchRecentInnovations(1);
      this.chunkRecentInnovations();
    },
    chunkInterestInnovations() {
      this.interestArray = [];
      const chunkSize = 4;
      for (let i = 0; i < this.interestInnovations.length; i += chunkSize) {
        const chunk = this.interestInnovations.slice(i, i + chunkSize);

        this.interestArray.push(chunk);
      }
    },
    chunkRecommendedInnovations() {
      this.recommendedArray = [];
      const chunkSize = 4;
      for (let i = 0; i < this.recommendedInnovations.length; i += chunkSize) {
        const chunk = this.recommendedInnovations.slice(i, i + chunkSize);

        this.recommendedArray.push(chunk);
      }
    },
    chunkPopularInnovations() {
      this.popularArray = [];
      const chunkSize = 4;
      for (let i = 0; i < this.popularInnovations.length; i += chunkSize) {
        const chunk = this.popularInnovations.slice(i, i + chunkSize);

        this.popularArray.push(chunk);
      }
    },
    chunkRecentInnovations() {
      this.recentArray = [];
      const chunkSize = 4;
      for (let i = 0; i < this.recentInnovations.length; i += chunkSize) {
        const chunk = this.recentInnovations.slice(i, i + chunkSize);

        this.recentArray.push(chunk);
      }
    },
    refreshInterests() {
      this.interestLoading = true;
      this.startInterestInnovationLoader();
      apiCall({
        url: "/api/innovation?type=interest&page=1",
        method: "GET",
      })
        .then((resp) => {
          this.interestLoading = false;
          this.filterInterestInnovations(resp);
          this.stopInterestInnovationLoader();
        })
        .catch((error) => {
          this.message = "An Error Occurred; Please Try Again";
          this.color = "error";
          this.loading = false;
          this.snackbar = true;
        });
    },
    goToInnovation(innovation) {
      this.$router.push("/innovations/" + innovation.slug);
    },
  },
  computed: {
    ...mapGetters([
      "interestInnovations",
      "interestInnovationLoader",
      "interestInnovationPagination",

      "recommendedInnovations",
      "recommendedInnovationLoader",
      "recommendedInnovationPagination",

      "popularInnovations",
      "popularInnovationLoader",
      "popularInnovationPagination",

      "recentInnovations",
      "recentInnovationLoader",
      "recentInnovationPagination",
    ]),
    interestInnovationsLength: function () {
      return Math.ceil(this.interestInnovations.length / 4);
    },
    recommendedInnovationsLength: function () {
      return Math.ceil(this.recommendedInnovations.length / 4);
    },
    popularInnovationsLength: function () {
      return Math.ceil(this.popularInnovations.length / 4);
    },
    recentInnovationsLength: function () {
      return Math.ceil(this.recentInnovations.length / 4);
    },
  },
};
</script>
