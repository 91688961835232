import apiCall from '@/utils/api'

const state = {
	institutions: [],
	allInstitutions: [],
    institutionPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    institutionLoader: false
};

const getters = {
	institutions: (state) => state.institutions,
	allInstitutions: (state) => state.allInstitutions,
    institutionLoader: (state) => state.institutionLoader,
    institutionPagination: (state) => state.institutionPagination
}

const actions = {
	async fetchAllInstitutions({commit}, page) {
		const response = await apiCall({url: `/api/institution?type=all`, method: 'GET' });
		commit('setAllInstitutions', response)
        commit('stopLoader', response)
	},
    async fetchInstitutions({commit}, page) {
		const response = await apiCall({url: `/api/institution?page=${page}`, method: 'GET' });
		commit('setInstitutions', response)
        commit('stopLoader', response)
	},
    async filterInstitutions({commit, state},resp){
		commit('setInstitutions', resp)
	},
	async startInstitutionLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopInstitutionLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setInstitutions: (state, institutions) => {
		state.institutions = institutions.data
		state.institutionPagination.current_page = institutions.current_page
		state.institutionPagination.total = institutions.total
		state.institutionPagination.per_page = institutions.per_page
	},
	setAllInstitutions: (state, institutions) => {
		state.allInstitutions = institutions
	},
    startLoader: (state) => state.institutionLoader = true,
	stopLoader: (state) => state.institutionLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
