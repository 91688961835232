import apiCall from '@/utils/api'

const state = {
	investmentFirms: [],
	adminInvestmentFirms: [],
	searchInvestmentFirms: [],
	allinvestmentFirms: [],
    investmentFirmPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	adminInvestmentFirmPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	searchInvestmentFirmPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    investmentFirmLoader: false
};

const getters = {
	investmentFirms: (state) => state.investmentFirms,
	adminInvestmentFirms: (state) => state.adminInvestmentFirms,
	searchInvestmentFirms: (state) => state.searchInvestmentFirms,
	allinvestmentFirms: (state) => state.allinvestmentFirms,
    investmentFirmLoader: (state) => state.investmentFirmLoader,
    investmentFirmPagination: (state) => state.investmentFirmPagination,
	adminInvestmentFirmPagination: (state) => state.adminInvestmentFirmPagination,
	searchInvestmentFirmPagination: (state) => state.searchInvestmentFirmPagination
}

const actions = {
	async fetchAllinvestmentFirms({commit}, page) {
		const response = await apiCall({url: `/api/investment-firm?type=all`, method: 'GET' });
		commit('setAllinvestmentFirms', response)
        commit('stopInvestmentFirmLoader', response)
	},
    async fetchInvestmentFirms({commit}, page) {
		const response = await apiCall({url: `/api/investment-firm?type=public&page=${page}`, method: 'GET' });
		commit('setInvestmentFirms', response)
        commit('stopInvestmentFirmLoader', response)
	},
	async fetchAdminInvestmentFirms({commit}, page) {
		const response = await apiCall({url: `/api/investment-firm?page=${page}`, method: 'GET' });
		commit('setAdminInvestmentFirms', response)
        commit('stopInvestmentFirmLoader', response)
	},

    async filterInvestmentFirms({commit, state},resp){
		commit('setInvestmentFirms', resp)
	},
	async filterAdminInvestmentFirms({commit, state},resp){
		commit('setAdminInvestmentFirms', resp)
	},
	async filterSearchInvestmentFirms({commit, state},resp){
		commit('setSearchInvestmentFirms', resp)
	},
	async startInvestmentFirmLoader({commit, state},resp){
		commit('startInvestmentFirmLoader', resp)
	},
	async stopInvestmentFirmLoader({commit, state},resp){
		commit('stopInvestmentFirmLoader', resp)
	},
};

const mutations = {
	setInvestmentFirms: (state, investmentFirms) => {
		state.investmentFirms = investmentFirms.data
		state.investmentFirmPagination.current_page = investmentFirms.current_page
		state.investmentFirmPagination.total = investmentFirms.total
		state.investmentFirmPagination.per_page = investmentFirms.per_page
	},
	setAdminInvestmentFirms: (state, investmentFirms) => {
		state.adminInvestmentFirms = investmentFirms.data
		state.adminInvestmentFirmPagination.current_page = investmentFirms.current_page
		state.adminInvestmentFirmPagination.total = investmentFirms.total
		state.adminInvestmentFirmPagination.per_page = investmentFirms.per_page
	},
	setSearchInvestmentFirms: (state, investmentFirms) => {
		state.searchInvestmentFirms = investmentFirms.data
		state.searchInvestmentFirmPagination.current_page = investmentFirms.current_page
		state.searchInvestmentFirmPagination.total = investmentFirms.total
		state.searchInvestmentFirmPagination.per_page = investmentFirms.per_page
	},
	setAllinvestmentFirms: (state, investmentFirms) => {
		state.allinvestmentFirms = investmentFirms
	},
    startInvestmentFirmLoader: (state) => state.investmentFirmLoader = true,
	stopInvestmentFirmLoader: (state) => state.investmentFirmLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
