<template>
  <div class="welcome">
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-dialog
      v-model="offerDialog"
      v-if="formData != null"
      width="500"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          Make an Offer
          <v-spacer></v-spacer>
          <v-btn icon @click="offerDialog = false">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <div class="mx-3">
              <v-layout row wrap>
                <v-flex xs12>
                  <div class="mt-5">
                    <b>Investment Type</b>
                  </div>
                  <v-select
                    :items="formData.funding_types"
                    item-text="name"
                    item-value="id"
                    :rules="inputRules"
                    outlined
                    dense
                    v-model="investment.funding_type_id"
                    class="background login_field"
                  ></v-select>
                </v-flex>
              </v-layout>
            </div>
            <div class="mx-3">
              <v-layout row wrap>
                <v-flex xs12>
                  <div class="mt-9">
                    <b>Amount</b>
                  </div>
                  <v-text-field
                    outlined
                    dense
                    v-model="investment.amount"
                    :rules="inputRules"
                    class="background login_field mb-5"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </div>
            <div class="mt-5" v-if="formData.individual_investor == true && formData.investment_firm_administrators.length != 0">
              <v-checkbox v-model="investment.individual_investor"
              label="Invest as an Individual"
              >
              </v-checkbox>
            </div>
            <div class="mt-5" v-if="formData.investment_firm_administrators.lenght != 0 && investment.individual_investor == false">
              <b>Investment Firm(s)</b>
            </div>
            <div v-if="formData.investment_firm_administrators.lenght != 0 && investment.individual_investor == false">
                <v-radio-group v-model="investment.investment_firm">
                  <v-radio
                  v-for="(
                    investment_firm_administrator, index
                  ) in formData.investment_firm_administrators"
                    :value="investment_firm_administrator.investment_firm.id"
                    :label="investment_firm_administrator.investment_firm.name"
                  ></v-radio>
                </v-radio-group>
            </div>
            <v-divider></v-divider>
            <div class="mt-8">
              <v-checkbox
                v-model="investment.public"
                label="Publicly Visible"
              ></v-checkbox>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="text-none my-3"
            depressed
            @click="saveOffer()"
            :loading="offerLoading"
          >
            Submit
            <v-icon right small class="white--text"> mdi-upload </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="noteDialog"
      width="700"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          New Note
          <v-spacer></v-spacer>
          <v-btn icon @click="noteDialog = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-layout column>
            <v-flex xs12>
              <div class="my-2">
                <div>Title<span class="error--text">*</span></div>
                <v-text-field
                  class="text_field background"
                  outlined
                  dense
                  v-model="newNote.title"
                  :rules="inputRules"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex xs12>
              <div class="my-2">
                <div>Content<span class="error--text">*</span></div>
                <div class="black--text">
                  <ckeditor
                    :editor="editor"
                    v-model="newNote.content"
                    :config="editorConfig"
                    :rules="inputRules"
                  ></ckeditor>
                </div>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            class="primary text-none"
            @click="saveNote"
            :loading="noteLoading"
          >
            Save
            <v-icon right> mdi-content-save </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="assignedUser != null"
      v-model="dialog"
      width="300"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-text>
          <v-layout column>
            <v-flex xs12>
              <div align="right" class="mt-2">
                <v-btn icon @click="dialog = false">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </div>
            </v-flex>
            <v-flex xs12>
              <div align="center">
                <v-avatar
                  size="156"
                  class="primary display-3 white--text"
                  v-if="assignedUser.display_img != null"
                >
                  <img
                    :src="
                      path + '/storage/profile_pics/' + assignedUser.display_img
                    "
                    contain
                    alt="pic"
                  />
                </v-avatar>
                <v-avatar
                  size="156"
                  class="primary display-3 white--text"
                  v-else
                >
                  {{ assignedUser.name[0] }}
                </v-avatar>
              </div>
              <div align="center" class="mt-5">
                <b>{{ assignedUser.name }}</b>
              </div>
              <div align="center" class="caption grey--text">
                {{ assignedUser.role }}
              </div>
              <div
                align="center"
                class="caption mt-5"
                v-if="assignedUser.bio != null"
              >
                <p v-html="assignedUser.bio"></p>
              </div>
              <div align="center" class="caption mt-5 font-italic" v-else>
                No Bio..
              </div>
            </v-flex>
            <v-flex xs12>
              <v-layout row wrap class="tableHeader mt-5 mb-2">
                <v-flex xs4>
                  <v-layout column>
                    <v-flex xs12 class="my-3">
                      <div align="center">
                        <v-icon class="text--text"> mdi-spa-outline </v-icon>
                      </div>
                      <div align="center" class="text--text">20</div>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs4>
                  <v-layout column>
                    <v-flex xs12 class="my-3">
                      <div align="center">
                        <v-icon class="text--text"> mdi-handshake </v-icon>
                      </div>
                      <div align="center" class="text--text">
                        {{ assignedUser.connections }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs4>
                  <v-layout column>
                    <v-flex xs12 class="my-3">
                      <div align="center">
                        <v-icon class="text--text">
                          mdi-briefcase-outline
                        </v-icon>
                      </div>
                      <div align="center" class="text--text">8</div>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-divider v-if="assignedUser.socials != null"></v-divider>

        <div align="center" v-if="assignedUser.socials != null">
          <template>
            <a
              v-if="assignedUser.socials.fb_url != null"
              :href="assignedUser.socials.fb_url"
              target="_blank"
              style="text-decoration: none"
            >
              <v-btn class="mx-2" icon>
                <v-icon size="15px" color="#3b5998"> mdi-facebook </v-icon>
              </v-btn>
            </a>
            <a
              v-if="assignedUser.socials.twitter_url != null"
              :href="assignedUser.socials.twitter_url"
              target="_blank"
              style="text-decoration: none"
            >
              <v-btn class="mx-2" icon>
                <v-icon size="15px" color="#1DA1F2"> mdi-twitter </v-icon>
              </v-btn>
            </a>
            <a
              v-if="assignedUser.socials.linkedin_url != null"
              :href="assignedUser.socials.linkedin_url"
              target="_blank"
              style="text-decoration: none"
            >
              <v-btn class="mx-2" icon>
                <v-icon size="15px" color="#0e76a8"> mdi-linkedin </v-icon>
              </v-btn>
            </a>
            <a
              v-if="assignedUser.socials.insta_url != null"
              :href="assignedUser.socials.insta_url"
              target="_blank"
              style="text-decoration: none"
            >
              <v-btn class="mx-2" icon>
                <v-icon size="15px" color="#8a3ab9"> mdi-instagram </v-icon>
              </v-btn>
            </a>
            <a
              v-if="assignedUser.socials.web_url != null"
              :href="assignedUser.socials.web_url"
              target="_blank"
              style="text-decoration: none"
            >
              <v-btn class="mx-2" icon>
                <v-icon size="15px" color="#8a3ab9"> mdi-web </v-icon>
              </v-btn>
            </a>
          </template>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="assignedUser.connected == false"
            color="primary"
            small
            class="text-none my-3 white--text"
            depressed
            @click="addFriend(assignedUser.code)"
            :loading="connectLoading"
          >
            Connect
            <v-icon right small class="white--text"> mdi-handshake </v-icon>
          </v-btn>
          <v-btn
            v-if="assignedUser.connected == true"
            color="green"
            small
            class="text-none my-3 white--text"
            depressed
          >
            Connected
            <v-icon right small class="white--text">
              mdi-check-decagram
            </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout column>
      <v-flex xs12 class="mt-2">
        <v-container>
          <div align="right">
            <v-btn
              rounded
              depressed
              class="primary text-none white--text"
              @click="saveInnovation"
              :loading="innovationLoader"
              v-if="$can('innovation_save')"
            >
              Save
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </div>
        </v-container>
      </v-flex>
      <v-flex xs12 class="mb-5">
        <v-container>
          <v-card outlined style="border-radius: 25px">
            <v-card-text>
              <v-layout column>
                <v-flex xs12>
                  <v-layout row wrap class="my-5">
                    <v-flex xs12 md3>
                      <div align="center" class="mt-2 mx-2">
                        <v-img
                          max-height="150"
                          v-if="innovation.featured_image == null"
                          src="img/innovation.jpg"
                          style="border-radius: 25px"
                        >
                        </v-img>
                        <v-img
                          max-height="150"
                          v-else
                          :src="
                            path +
                            '/storage/innovation_pictures/' +
                            innovation.featured_image
                          "
                          style="border-radius: 25px"
                        >
                        </v-img>
                      </div>
                    </v-flex>
                    <v-flex xs12 md5>
                      <div class="mx-3">
                        <v-layout column>
                          <v-flex xs12>
                            <div
                              class="title"
                              style="font-family: 'poppins' !important"
                            >
                              {{ innovation.name }}
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div>
                              <i
                                >Created
                                {{
                                  innovation.created_at | moment("DD/MM/YYYY")
                                }}</i
                              >
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div class="grey--text">{{ innovation.brief }}</div>
                          </v-flex>
                          <v-flex
                            xs12
                            class="mt-3"
                            v-if="
                              $can('rating_view') &&
                              innovation.rating_public == 1
                            "
                          >
                            <v-row align="center" class="mx-0">
                              <v-rating
                                :value="innovation.average_rating"
                                color="amber"
                                dense
                                half-increments
                                readonly
                                size="14"
                              ></v-rating>

                              <div class="grey--text ms-4">
                                {{ innovation.average_rating }} ({{
                                  innovation.total_rating
                                }})
                              </div>
                            </v-row>
                          </v-flex>
                          <v-flex xs12>
                            <div class="mt-7"><b>Connect with the Team</b></div>
                            <div class="mt-2">
                              <a
                                v-for="user in innovation.team"
                                :key="user.code"
                                @click="assignUser(user)"
                              >
                                <v-avatar
                                  size="46"
                                  class="mx-1"
                                  @click="dialog = true"
                                  v-if="user.display_img != null"
                                >
                                  <img
                                    :src="
                                      path +
                                      '/storage/profile_pics/' +
                                      user.display_img
                                    "
                                    alt="pic"
                                  />
                                </v-avatar>
                                <v-avatar
                                  size="46"
                                  class="primary white--text mx-1"
                                  v-if="user.display_img == null"
                                  @click="dialog = true"
                                >
                                  {{ user.name[0] }}
                                </v-avatar>
                              </a>
                            </div>
                          </v-flex>
                        </v-layout>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="mx-3">
                        <div
                        align="center"
                          class="title"
                          style="font-family: 'poppins' !important"
                        >
                          Sectors
                        </div>
                        <div align="center">
                          <template
                            v-for="(
                              innovation_sector, index
                            ) in innovation.innovation_sectors"
                          >
                            <v-chip
                              x-small
                              :color="innovation_sector.sector.color"
                              class="white--text mr-1"
                              :key="index"
                            >
                              #{{ innovation_sector.sector.name }}
                            </v-chip>
                          </template>
                        </div>
                        <div
                          align="center"
                          class="title mt-2"
                          v-if="innovation.innovation_socials != null"
                        >
                          Get in touch
                        </div>
                      </div>

                      <div
                        v-if="innovation.innovation_socials != null"
                        align="center"
                      >
                        <a
                          v-if="innovation.innovation_socials.fb_url != null"
                          :href="innovation.innovation_socials.fb_url"
                          target="_blank"
                          style="text-decoration: none"
                        >
                          <v-btn class="mx-2 text--text" icon>
                            <v-icon size="30px" color="text">
                              mdi-facebook
                            </v-icon>
                          </v-btn>
                        </a>
                        <a
                          v-if="
                            innovation.innovation_socials.twitter_url != null
                          "
                          :href="innovation.innovation_socials.twitter_url"
                          target="_blank"
                          style="text-decoration: none"
                        >
                          <v-btn class="mx-2 text--text" icon>
                            <v-icon size="30px" color="text">
                              mdi-twitter
                            </v-icon>
                          </v-btn>
                        </a>
                        <a
                          v-if="
                            innovation.innovation_socials.linkedin_url != null
                          "
                          :href="innovation.innovation_socials.linkedin_url"
                          target="_blank"
                          style="text-decoration: none"
                        >
                          <v-btn class="mx-2 text--text" icon>
                            <v-icon size="30px" color="text">
                              mdi-linkedin
                            </v-icon>
                          </v-btn>
                        </a>
                        <a
                          v-if="innovation.innovation_socials.insta_url != null"
                          :href="innovation.innovation_socials.insta_url"
                          target="_blank"
                          style="text-decoration: none"
                        >
                          <v-btn class="mx-2 text--text" icon>
                            <v-icon size="30px" color="text">
                              mdi-instagram
                            </v-icon>
                          </v-btn>
                        </a>
                        <a
                          v-if="innovation.innovation_socials.email != null"
                          :href="
                            'mailto:' + innovation.innovation_socials.email
                          "
                          target="_blank"
                          style="text-decoration: none"
                        >
                          <v-btn class="mx-2 text--text" icon>
                            <v-icon size="30px" color="text"> mdi-at </v-icon>
                          </v-btn>
                        </a>
                        <a
                          v-if="innovation.innovation_socials.phone != null"
                          :href="'tel:' + innovation.innovation_socials.phone"
                          target="_blank"
                          style="text-decoration: none"
                        >
                          <v-btn class="mx-2 text--text" icon>
                            <v-icon size="30px" color="text">
                              mdi-phone
                            </v-icon>
                          </v-btn>
                        </a>
                        <a
                          v-if="innovation.innovation_socials.web_url != null"
                          :href="innovation.innovation_socials.web_url"
                          target="_blank"
                          style="text-decoration: none"
                        >
                          <v-btn class="mx-2 text--text" icon>
                            <v-icon size="30px" color="text"> mdi-web </v-icon>
                          </v-btn>
                        </a>
                      </div>
                      <div class="mx-2 mt-7" align="center">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn outlined v-bind="attrs" v-on="on" class="text-none">
                              <v-icon left>mdi-share-variant</v-icon>
                              Share Via
                              <v-icon right>mdi-chevron-down</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item dense>
                              <v-list-item-icon>
                                <v-icon color="#3b5998">mdi-facebook</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>
                                  <ShareNetwork
                                    style="text-decoration: none"
                                    class="text--text"
                                    network="facebook"
                                    :url="shareLink"
                                    :title="innovation.name"
                                    :description="innovation.brief"
                                    hashtags="KIB"
                                  >
                                    <span>Facebook</span>
                                  </ShareNetwork>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item dense>
                              <v-list-item-icon>
                                <v-icon color="#1DA1F2">mdi-twitter</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>
                                  <ShareNetwork
                                    style="text-decoration: none"
                                    class="text--text"
                                    network="twitter"
                                    :url="shareLink"
                                    :title="innovation.name"
                                    :description="innovation.brief"
                                    hashtags="KIB"
                                  >
                                    <span>Twitter</span>
                                  </ShareNetwork>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item dense>
                              <v-list-item-icon>
                                <v-icon color="#0e76a8">mdi-linkedin</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>
                                  <ShareNetwork
                                    style="text-decoration: none"
                                    class="text--text"
                                    network="linkedin"
                                    :url="shareLink"
                                    :title="innovation.name"
                                    :description="innovation.brief"
                                    hashtags="KIB"
                                  >
                                    <span>LinkedIn</span>
                                  </ShareNetwork>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item dense>
                              <v-list-item-icon>
                                <v-icon color="#075e54">mdi-whatsapp</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>
                                  <ShareNetwork
                                    style="text-decoration: none"
                                    class="text--text"
                                    network="whatsapp"
                                    :url="shareLink"
                                    :title="innovation.name"
                                    :description="innovation.brief"
                                    hashtags="KIB"
                                  >
                                    <span>Whatsapp</span>
                                  </ShareNetwork>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item dense>
                              <v-list-item-icon>
                                <v-icon color="#0088cc">mdi-send mdi-rotate-315</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>
                                  <ShareNetwork
                                    style="text-decoration: none"
                                    class="text--text"
                                    network="telegram"
                                    :url="shareLink"
                                    :title="innovation.name"
                                    :description="innovation.brief"
                                    hashtags="KIB"
                                  >
                                    <span>Telegram</span>
                                  </ShareNetwork>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                     
                      <div class="mt-1">
                        
                        

                        
                        
                       
                      </div>
                      <div
                        class="mt-10"
                        align="center"
                        v-if="$can('investment_create')"
                      >
                        <v-btn
                          v-if="
                            formData != null &&
                            (formData.individual_investor == true ||
                              formData.investment_firm_administrators.length !=
                                0)
                          "
                          depressed
                          class="primary white--text text-none"
                          @click="offerDialog = true"
                        >
                          Make an Offer
                          <v-icon right> mdi-offer </v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-divider></v-divider>
                </v-flex>
                <v-flex xs12 class="mt-8 mb-3">
                  <v-layout row wrap>
                    <v-flex xs12 md3>
                      <div align="center" class="grey--text caption">
                        <v-icon class="text--text">mdi-map-marker</v-icon>
                        {{ innovation.county.name }}
                      </div>
                    </v-flex>
                    <v-flex xs12 md3>
                      <div align="center" class="grey--text caption">
                        <v-icon class="text--text">mdi-calendar</v-icon>
                        ESTABLISHED {{ innovation.establishment_year }}
                      </div>
                    </v-flex>
                    <v-flex xs12 md3>
                      <div align="center" class="grey--text caption">
                        <v-icon class="text--text"
                          >mdi-signal-cellular-2</v-icon
                        >
                        STAGE: {{ innovation.stage.name }}
                      </div>
                    </v-flex>
                    <v-flex xs12 md3>
                      <div align="center" class="grey--text caption">
                        <v-icon class="text--text">mdi-umbrella</v-icon> IP
                        PROTECTION: {{ innovation.ip_protection_type.name }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-container>
      </v-flex>

      <v-flex xs12 class="mt-2">
        <v-progress-linear
          v-if="ratingLoader"
          height="1"
          indeterminate
          color="primary"
        >
        </v-progress-linear>
        <v-card outlined>
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 md8>
                <v-tabs show-arrows v-model="tab" class="mt-6">
                  <v-tabs-slider color="primary"></v-tabs-slider>
                  <v-tab @click="activeTab = 'about'">
                    <div class="text-none text--text">About</div>
                  </v-tab>
                  <v-tab
                    @click="activeTab = 'affiliatedInstitutions'"
                    v-if="innovation.innovation_institutions.length != 0"
                  >
                    <div class="text-none text--text">
                      Affiliated Institutions ({{
                        innovation.innovation_institutions.length
                      }})
                    </div>
                  </v-tab>
                  <v-tab
                    @click="activeTab = 'affiliatedOrganisations'"
                    v-if="innovation.innovation_organisations.length != 0"
                  >
                    <div class="text-none text--text">
                      Affiliated Organisations ({{
                        innovation.innovation_organisations.length
                      }})
                    </div>
                  </v-tab>
                  <v-tab
                    @click="activeTab = 'notes'"
                    v-if="$can('innovation_notes_view')"
                  >
                    <div class="text-none text--text">
                      Notes ({{ notePagination.total }})
                    </div>
                  </v-tab>
                  <v-tab
                    @click="activeTab = 'investments'"
                    v-if="$can('investment_view')"
                  >
                    <div class="text-none text--text">
                      Investments ({{ investmentPagination.total }})
                    </div>
                  </v-tab>
                  <v-tab
                    @click="activeTab = 'statistics'"
                    v-if="$can('investment_view') && innovation.innovation_statistics != null"
                  >
                    <div class="text-none text--text">
                      Business Statistics
                    </div>
                  </v-tab>
                </v-tabs>
                <div v-if="activeTab == 'about'">
                  <p class="mx-3 mt-6" v-html="innovation.description"></p>
                </div>
                <div v-if="activeTab == 'affiliatedInstitutions'">
                  <v-layout column class="mt-5">
                    <template
                      v-for="(
                        innovation_institution, index
                      ) in innovation.innovation_institutions"
                    >
                      <div :key="index">
                        <v-flex xs12 class="mb-4">
                          <a style="text-decoration: none">
                            <v-card
                              outlined
                              @click="
                                goToInstitution(
                                  innovation_institution.institution
                                )
                              "
                              style="border-radius: 25px"
                            >
                              <v-card-text>
                                <v-layout row wrap>
                                  <v-flex xs12 md4>
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div align="center" class="my-5 mx-3">
                                          <v-img
                                            max-height="150"
                                            v-if="
                                              innovation_institution.institution
                                                .logo == null
                                            "
                                            src="img/investmentFirm.png"
                                            style="border-radius: 25px"
                                          >
                                          </v-img>
                                          <v-img
                                            max-height="150"
                                            v-else
                                            :src="
                                              path +
                                              '/storage/institution_pictures/' +
                                              innovation_institution.institution
                                                .logo
                                            "
                                            style="border-radius: 25px"
                                          >
                                          </v-img>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 md8>
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div class="mt-7 title">
                                          {{
                                            innovation_institution.institution
                                              .name
                                          }}
                                        </div>
                                        <v-clamp
                                          autoresize
                                          :max-lines="4"
                                          class="mb-5 mr-5 caption"
                                        >
                                          {{
                                            innovation_institution.institution
                                              .brief
                                          }}
                                        </v-clamp>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-card-text>
                            </v-card>
                          </a>
                        </v-flex>
                      </div>
                    </template>
                  </v-layout>
                </div>
                <div v-if="activeTab == 'affiliatedOrganisations'">
                  <v-layout column class="mt-5">
                    <template
                      v-for="(
                        innovation_organisation, index
                      ) in innovation.innovation_organisations"
                    >
                      <div :key="index">
                        <v-flex xs12 class="mb-4">
                          <a style="text-decoration: none">
                            <v-card
                              outlined
                              @click="goToOrganisation(innovation_organisation)"
                              style="border-radius: 25px"
                            >
                              <v-card-text>
                                <v-layout row wrap>
                                  <v-flex xs12 md4>
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div align="center" class="my-5 mx-3">
                                          <v-img
                                            max-height="150"
                                            v-if="
                                              innovation_organisation
                                                .organisation.logo == null
                                            "
                                            src="img/investmentFirm.png"
                                            style="border-radius: 25px"
                                          >
                                          </v-img>
                                          <v-img
                                            max-height="150"
                                            v-else
                                            :src="
                                              path +
                                              '/storage/organisation_pictures/' +
                                              innovation_organisation
                                                .organisation.logo
                                            "
                                            style="border-radius: 25px"
                                          >
                                          </v-img>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 md8>
                                    <v-layout column>
                                      <v-flex xs12>
                                        <div class="mt-7 title">
                                          {{
                                            innovation_organisation.organisation
                                              .name
                                          }}
                                        </div>
                                        <v-clamp
                                          autoresize
                                          :max-lines="4"
                                          class="mb-5 mr-5 caption"
                                          >{{
                                            innovation_organisation.organisation
                                              .brief
                                          }}
                                        </v-clamp>
                                        <!-- <div class="subtitle-2"><b>Created:</b> </div> -->
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-card-text>
                            </v-card>
                          </a>
                        </v-flex>
                      </div>
                    </template>
                  </v-layout>
                </div>
                <div v-if="activeTab == 'notes'">
                  <v-layout column>
                    <v-flex xs12 class="mx-2">
                      <div align="right" class="mr-5 mt-3">
                        <v-btn
                          depressed
                          class="primary white--text text-none"
                          @click="noteDialog = true"
                        >
                          New Note
                          <v-icon right> mdi-note-plus </v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="mx-2">
                      <div v-if="notes.length == 0">
                        <v-card
                          outlined
                          class="my-5 mx-5"
                          style="border-radius: 25px"
                        >
                          <v-layout row wrap class="my-10">
                            <v-flex xs12 md2>
                              <v-container fill-height fluid>
                                <v-row align="center" justify="center">
                                  <v-col class="text-center">
                                    <v-icon large class="text--text">
                                      mdi-alert-circle-outline
                                    </v-icon>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md10>
                              <v-container fill-height fluid>
                                <v-row align="center" justify="center">
                                  <v-col class="text-center">
                                    You haven't added any notes for this
                                    innovation</v-col
                                  >
                                </v-row>
                              </v-container>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </div>
                      <div v-else>
                        <v-layout column class="mt-4">
                          <template v-for="(note, index) in notes">
                            <div :key="index">
                              <v-flex xs12 class="mb-4 mx-5">
                                <a style="text-decoration: none">
                                  <v-card
                                    outlined
                                    @click="openNote(note)"
                                    style="border-radius: 25px"
                                  >
                                    <v-card-text>
                                      <v-layout row wrap>
                                        <v-flex xs2>
                                          <v-layout column>
                                            <v-flex xs12>
                                              <div
                                                align="center"
                                                class="text--text display-2 font-weight-black mt-3"
                                              >
                                                {{
                                                  note.created_at | moment("Do")
                                                }}
                                              </div>
                                            </v-flex>
                                            <v-flex xs12>
                                              <div
                                                align="center"
                                                class="text--text mb-3"
                                              >
                                                {{
                                                  note.created_at
                                                    | moment("MMM YYYY")
                                                }}
                                              </div>
                                            </v-flex>
                                          </v-layout>
                                        </v-flex>
                                        <v-flex xs10>
                                          <v-layout column>
                                            <v-flex xs12>
                                              <div class="mt-3">
                                                {{ note.title }}
                                              </div>
                                            </v-flex>
                                            <v-flex xs12>
                                              <v-clamp
                                                autoresize
                                                :max-lines="3"
                                                class="mb-5 mr-5 caption"
                                                v-html="note.content"
                                              >
                                              </v-clamp>
                                            </v-flex>
                                          </v-layout>
                                        </v-flex>
                                      </v-layout>
                                    </v-card-text>
                                  </v-card>
                                </a>
                              </v-flex>
                            </div>
                          </template>
                        </v-layout>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="mx-2">
                      <div
                        align="center"
                        class="my-7"
                        v-if="notePagination.total != 0"
                      >
                        <v-pagination
                          v-if="noteLength != 0"
                          :length="noteLength"
                          total-visible="10"
                          v-model="notePagination.current_page"
                          @input="fetchNotes()"
                          circle
                        >
                        </v-pagination>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
                <div v-if="activeTab == 'investments'">
                  <v-layout column>
                    <v-flex xs12 class="mx-2">
                      <div v-if="investments.length == 0">
                        <v-card
                          outlined
                          class="my-5 mx-5"
                          style="border-radius: 25px"
                        >
                          <v-layout row wrap class="my-10">
                            <v-flex xs12 md2>
                              <v-container fill-height fluid>
                                <v-row align="center" justify="center">
                                  <v-col class="text-center">
                                    <v-icon large class="text--text">
                                      mdi-alert-circle-outline
                                    </v-icon>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md10>
                              <v-container fill-height fluid>
                                <v-row align="center" justify="center">
                                  <v-col class="text-center">
                                    No Investments on Record</v-col
                                  >
                                </v-row>
                              </v-container>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </div>
                      <div v-else>
                        <v-layout column class="mt-4">
                          <template v-for="(investment, index) in investments">
                            <div :key="index">
                              <v-flex xs12 class="mb-4">
                                <v-card outlined style="border-radius: 25px">
                                  <v-card-text>
                                    <v-layout row wrap>
                                      <v-flex xs2>
                                        <v-layout column>
                                          <v-flex xs12>
                                            <div
                                              align="center"
                                              class="text--text display-2 font-weight-black mt-3"
                                            >
                                              {{
                                                investment.created_at
                                                  | moment("Do")
                                              }}
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div
                                              align="center"
                                              class="text--text mb-3"
                                            >
                                              {{
                                                investment.created_at
                                                  | moment("MMM YYYY")
                                              }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs10>
                                        <div class="pa-5">
                                          <v-layout row wrap>
                                            <v-flex xs2>
                                              <b>Amount:</b>
                                            </v-flex>
                                            <v-flex xs10>
                                              <div>
                                                Kshs
                                                {{
                                                  intToString(investment.amount)
                                                }}
                                              </div>
                                            </v-flex>
                                          </v-layout>
                                        </div>
                                        <div class="mx-5">
                                          <v-layout row wrap>
                                            <v-flex xs2>
                                              <b>Funding Type:</b>
                                            </v-flex>
                                            <v-flex xs10>
                                              <div>
                                                {{
                                                  investment.funding_type.name
                                                }}
                                              </div>
                                            </v-flex>
                                          </v-layout>
                                        </div>
                                        <div class="pa-5 mt-5">
                                          <v-layout column>
                                            <template
                                              v-for="(
                                                investment_firm_investment,
                                                index
                                              ) in investment.investment_firm_investments"
                                            >
                                              <div :key="index">
                                                <v-flex xs12 class="mb-4">
                                                  <v-layout row wrap>
                                                    <v-flex xs12 md3>
                                                      <div align="center">
                                                        <v-avatar size="116">
                                                          <v-img
                                                            v-if="
                                                              investment_firm_investment
                                                                .investment_firm
                                                                .logo == null
                                                            "
                                                            src="img/investmentFirm.png"
                                                          >
                                                          </v-img>
                                                          <v-img
                                                            v-else
                                                            :src="
                                                              path +
                                                              '/storage/investment_firm_pictures/' +
                                                              investment_firm_investment
                                                                .investment_firm
                                                                .logo
                                                            "
                                                          >
                                                          </v-img>
                                                        </v-avatar>
                                                      </div>
                                                    </v-flex>
                                                    <v-flex xs12 md9>
                                                      <v-layout column>
                                                        <v-flex
                                                          xs12
                                                          class="mt-5"
                                                        >
                                                          <v-layout row wrap>
                                                            <v-flex xs2>
                                                              Name:
                                                            </v-flex>
                                                            <v-flex xs10>
                                                              <a
                                                                @click="
                                                                  goToInvestmentFirm(
                                                                    investment_firm_investment.investment_firm
                                                                  )
                                                                "
                                                              >
                                                                {{
                                                                  investment_firm_investment
                                                                    .investment_firm
                                                                    .name
                                                                }}
                                                              </a>
                                                            </v-flex>
                                                          </v-layout>
                                                        </v-flex>
                                                        <v-flex
                                                          xs12
                                                          class="mt-5"
                                                        >
                                                          <v-layout row wrap>
                                                            <v-flex xs2>
                                                              Brief:
                                                            </v-flex>
                                                            <v-flex xs10>
                                                              {{
                                                                investment_firm_investment
                                                                  .investment_firm
                                                                  .brief
                                                              }}
                                                            </v-flex>
                                                          </v-layout>
                                                        </v-flex>
                                                      </v-layout>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                              </div>
                                            </template>
                                          </v-layout>
                                          <v-layout column>
                                            <div
                                              v-if="
                                                investment.individual_investor_investment !=
                                                null
                                              "
                                            >
                                              <v-flex xs12 class="mb-4">
                                                <v-layout row wrap>
                                                  <v-flex xs12 md3>
                                                    <div align="center">
                                                      <v-avatar
                                                        class="primary white--text"
                                                        size="116"
                                                        v-if="
                                                          investment
                                                            .individual_investor_investment
                                                            .user.display_img ==
                                                          null
                                                        "
                                                      >
                                                        {{
                                                          investment
                                                            .individual_investor_investment
                                                            .user.f_name[0]
                                                        }}
                                                      </v-avatar>
                                                      <v-avatar
                                                        size="116"
                                                        v-else
                                                      >
                                                        <v-img
                                                          class="elevation-6"
                                                          :src="
                                                            path +
                                                            '/storage/profile_pics/' +
                                                            investment
                                                              .individual_investor_investment
                                                              .user.display_img
                                                          "
                                                        >
                                                        </v-img>
                                                      </v-avatar>
                                                    </div>
                                                  </v-flex>
                                                  <v-flex xs12 md9>
                                                    <v-layout column>
                                                      <v-flex xs12 class="mt-5">
                                                        <v-layout row wrap>
                                                          <v-flex xs2>
                                                            Name:
                                                          </v-flex>
                                                          <v-flex xs10>
                                                            {{
                                                              investment
                                                                .individual_investor_investment
                                                                .user.f_name
                                                            }}
                                                            {{
                                                              investment
                                                                .individual_investor_investment
                                                                .user.m_name
                                                            }}
                                                            {{
                                                              investment
                                                                .individual_investor_investment
                                                                .user.l_name
                                                            }}
                                                          </v-flex>
                                                        </v-layout>
                                                      </v-flex>
                                                      <v-flex xs12 class="mt-5">
                                                        <v-layout row wrap>
                                                          <v-flex xs2>
                                                            Investor Type:
                                                          </v-flex>
                                                          <v-flex xs10>
                                                            {{
                                                              investment
                                                                .individual_investor_investment
                                                                .user
                                                                .individual_investor
                                                                .investor_type
                                                                .name
                                                            }}
                                                          </v-flex>
                                                        </v-layout>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                            </div>
                                          </v-layout>
                                        </div>
                                      </v-flex>
                                    </v-layout>
                                  </v-card-text>
                                </v-card>
                              </v-flex>
                            </div>
                          </template>
                        </v-layout>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="mx-2">
                      <div
                        align="center"
                        class="my-7"
                        v-if="investmentPagination.total != 0"
                      >
                        <v-pagination
                          v-if="investmentLength != 0"
                          :length="investmentLength"
                          total-visible="10"
                          v-model="investmentPagination.current_page"
                          @input="fetchInvestments()"
                          circle
                        >
                        </v-pagination>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
                <div v-if="activeTab == 'statistics'">
                  <div class="mx-7 mt-5">
                    <v-layout row wrap>
                      <v-flex xs12 md4 class="mt-2">
                        <v-layour row wrap>
                          <v-flex xs12>
                            <b>No of Customers </b>
                          </v-flex>
                          <v-flex xs12>
                            {{ innovation.innovation_statistics.customer_class.name }}
                          </v-flex>
                        </v-layour>
                      </v-flex>
                      <v-flex xs12 md4 class="mt-2">
                        <v-layour row wrap>
                          <v-flex xs12>
                            <b>No of Employees </b>
                          </v-flex>
                          <v-flex xs12>
                            {{ innovation.innovation_statistics.employee_class.name }}
                          </v-flex>
                        </v-layour>
                      </v-flex>
                      <v-flex xs12 md4 class="mt-2">
                        <v-layour row wrap>
                          <v-flex xs12>
                            <b>No of Users </b>
                          </v-flex>
                          <v-flex xs12>
                            {{ innovation.innovation_statistics.user_class.name }}
                          </v-flex>
                        </v-layour>
                      </v-flex>
                      <v-flex xs12 md4 class="mt-2">
                        <v-layour row wrap>
                          <v-flex xs12>
                            <b>Total Revenue (Kshs) </b>
                          </v-flex>
                          <v-flex xs12>
                            {{ innovation.innovation_statistics.revenue_class.name }}
                          </v-flex>
                        </v-layour>
                      </v-flex>
                      <v-flex xs12 md4 class="mt-2">
                        <v-layour row wrap>
                          <v-flex xs12>
                            <b>Total Investments (Kshs) </b>
                          </v-flex>
                          <v-flex xs12>
                            {{ innovation.innovation_statistics.investment_class.name }}
                          </v-flex>
                        </v-layour>
                      </v-flex>
                    </v-layout>
                  </div>
                  
                </div>
              </v-flex>
              <v-flex xs12 md4>
                <v-flex xs12 v-if="$can('rating_create')">
                  <div class="ml-3 mt-10"><b>My Rating</b></div>
                  <v-rating
                    v-model="myRating.rating"
                    color="yellow darken-3"
                    background-color="grey darken-1"
                    empty-icon="$ratingFull"
                    hover
                    @input="rateInnovation()"
                  ></v-rating>
                </v-flex>
                <v-flex xs12>
                  <v-card
                    outlined
                    elevation="0"
                    class="pa-5 ma-3 mt-5 tableHeader"
                  >
                    <div class="body"><b>Looking For</b></div>
                    <div class="caption primary--text mt-5">
                      <template
                        v-for="(
                          innovation_interest, index
                        ) in innovation.innovation_interests"
                      >
                        <b> • {{ innovation_interest.interest.name }}</b>
                      </template>
                    </div>
                    <div class="body mt-5"><b>SDG(s)</b></div>
                    <div class="caption primary--text mt-5">
                      <template
                        v-for="(
                          innovation_sdg, index
                        ) in innovation.innovation_sdgs"
                      >
                        <b> • {{ innovation_sdg.sdg.name }}</b>
                      </template>
                    </div>
                  </v-card>
                </v-flex>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
      <v-flex
        xs12
        class="mt-7"
        v-if="innovation.similar_innovations.length != 0"
      >
        <v-container>
          <v-card outlined style="border-radius: 25px">
            <v-layout column>
              <v-flex xs12 class="pa-2">
                <div class="pa-3 mx-2">
                  <v-layout row wrap>
                    <v-flex xs12>
                      <div
                        class="title"
                        style="font-family: 'poppins' !important"
                      >
                        <b>Similar</b> <v-icon right>mdi-bullseye-arrow</v-icon>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
                <v-carousel
                  v-model="similarCarousel"
                  height="570"
                  show-arrows-on-hover
                  class="hidden-sm-and-down"
                >
                  <v-carousel-item
                    v-for="(slide, i) in similarInnovationsLength"
                    :key="i"
                  >
                    <v-layout row wrap class="mx-1">
                      <template
                        v-for="(similar, index) in similarArray[
                          similarCarousel
                        ]"
                      >
                        <v-flex xs12 md3>
                          <v-card
                            :loading="loading"
                            class="mx-1 mt-7 d-flex flex-column"
                            outlined
                            height="460"
                            style="border-radius: 25px"
                          >
                            <template slot="progress">
                              <v-progress-linear
                                color="deep-purple"
                                height="10"
                                indeterminate
                              ></v-progress-linear>
                            </template>
                            <v-img
                              max-height="150"
                              v-if="similar.innovation.featured_image == null"
                              src="img/innovation.jpg"
                              style="border-radius: 25px"
                            >
                            </v-img>
                            <v-img
                              max-height="150"
                              v-else
                              :src="
                                path +
                                '/storage/innovation_pictures/' +
                                similar.innovation.featured_image
                              "
                              style="border-radius: 25px"
                            >
                            </v-img>

                            <div class="title pa-3">
                              <v-clamp autoresize :max-lines="2"
                                >{{ similar.innovation.name }}
                              </v-clamp>
                            </div>

                            <v-card-text>
                              <v-row align="center" class="mx-0">
                                <v-rating
                                  :value="similar.innovation.average_rating"
                                  color="amber"
                                  dense
                                  half-increments
                                  readonly
                                  size="14"
                                ></v-rating>

                                <div class="grey--text ms-4">
                                  {{ similar.innovation.average_rating }} ({{
                                    similar.innovation.total_rating
                                  }})
                                </div>
                              </v-row>

                              <div class="my-4 text-subtitle-1">
                                <template
                                  v-for="(
                                    innovation_sector, index
                                  ) in similar.innovation.innovation_sectors.slice(
                                    0,
                                    5
                                  )"
                                >
                                  <v-chip
                                    x-small
                                    :color="innovation_sector.sector.color"
                                    class="white--text mr-1"
                                    :key="index"
                                  >
                                    #{{ innovation_sector.sector.name }}
                                  </v-chip>
                                </template>
                                <v-chip
                                  class="white--text"
                                  color="primary"
                                  small
                                  v-if="
                                    similar.innovation.innovation_sectors
                                      .length > 5
                                  "
                                >
                                  + More
                                </v-chip>
                              </div>
                              <v-clamp autoresize :max-lines="2"
                                >{{ similar.innovation.brief }}
                              </v-clamp>
                            </v-card-text>
                            <v-spacer></v-spacer>
                            <div
                              align="center"
                              v-if="similar.innovation.verified == 1"
                            >
                              <v-chip
                                class="ma-2"
                                color="blue"
                                label
                                text-color="white"
                              >
                                <v-icon left> mdi-check-decagram </v-icon>
                                IB Verified
                              </v-chip>
                            </div>
                            <v-divider class="mx-4"></v-divider>

                            <v-card-actions>
                              <v-btn
                                class="text-none"
                                color="primary"
                                block
                                depressed
                                rounded
                                @click="goToInnovation(similar.innovation)"
                              >
                                View
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-carousel-item>
                </v-carousel>
                <v-carousel
                  v-model="similarCarousel"
                  height="2130"
                  show-arrows-on-hover
                  class="hidden-md-and-up"
                >
                  <v-carousel-item
                    v-for="(slide, i) in similarInnovationsLength"
                    :key="i"
                  >
                    <v-layout row wrap class="mx-1">
                      <template
                        v-for="(similar, index) in similarArray[
                          similarCarousel
                        ]"
                      >
                        <v-flex xs12 md3>
                          <v-card
                            :loading="loading"
                            class="mx-1 mt-7 d-flex flex-column"
                            outlined
                            style="border-radius: 25px"
                          >
                            <template slot="progress">
                              <v-progress-linear
                                color="deep-purple"
                                height="10"
                                indeterminate
                              ></v-progress-linear>
                            </template>
                            <v-img
                              max-height="150"
                              v-if="similar.innovation.featured_image == null"
                              src="img/innovation.jpg"
                              style="border-radius: 25px"
                            >
                            </v-img>
                            <v-img
                              max-height="150"
                              v-else
                              :src="
                                path +
                                '/storage/innovation_pictures/' +
                                similar.innovation.featured_image
                              "
                              style="border-radius: 25px"
                            >
                            </v-img>
                            <div class="title pa-3">
                              <v-clamp autoresize :max-lines="2"
                                >{{ similar.innovation.name }}
                              </v-clamp>
                            </div>

                            <v-card-text>
                              <v-row align="center" class="mx-0">
                                <v-rating
                                  :value="similar.innovation.average_rating"
                                  color="amber"
                                  dense
                                  half-increments
                                  readonly
                                  size="14"
                                ></v-rating>

                                <div class="grey--text ms-4">
                                  {{ similar.innovation.average_rating }} ({{
                                    similar.innovation.total_rating
                                  }})
                                </div>
                              </v-row>

                              <div class="my-4 text-subtitle-1">
                                <template
                                  v-for="(
                                    innovation_sector, index
                                  ) in similar.innovation.innovation_sectors.slice(
                                    0,
                                    5
                                  )"
                                >
                                  <v-chip
                                    x-small
                                    :color="innovation_sector.sector.color"
                                    class="white--text mr-1"
                                    :key="index"
                                  >
                                    #{{ innovation_sector.sector.name }}
                                  </v-chip>
                                </template>
                                <v-chip
                                  class="white--text"
                                  color="primary"
                                  small
                                  v-if="
                                    similar.innovation.innovation_sectors
                                      .length > 5
                                  "
                                >
                                  + More
                                </v-chip>
                              </div>
                              <v-clamp autoresize :max-lines="2"
                                >{{ similar.innovation.brief }}
                              </v-clamp>
                            </v-card-text>
                            <v-spacer></v-spacer>
                            <div
                              align="center"
                              v-if="similar.innovation.verified == 1"
                            >
                              <v-chip
                                class="ma-2"
                                color="blue"
                                label
                                text-color="white"
                              >
                                <v-icon left> mdi-check-decagram </v-icon>
                                IB Verified
                              </v-chip>
                            </div>
                            <v-divider class="mx-4"></v-divider>

                            <v-card-actions>
                              <v-btn
                                class="text-none"
                                color="primary"
                                block
                                depressed
                                rounded
                                @click="goToInnovation(similar)"
                              >
                                View
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-carousel-item>
                </v-carousel>
              </v-flex>
            </v-layout>
          </v-card>
        </v-container>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";
import VClamp from "vue-clamp";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadAdapter from '@/uploadAdapter.js';

export default {
  components: {
    VClamp,
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      loading: false,
      offerLoading: false,
      noteLoading: false,
      innovationLoader: false,
      selection: 1,
      drawer: false,
      dialog: false,
      offerDialog: false,
      connectLoading: false,
      notFound: false,
      inputRules: [(v) => !!v || "Input is required"],

      formData: null,
      valid: true,

      editor: ClassicEditor,
      editorConfig: {
          extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
              return new uploadAdapter(loader);
          } }],
          language: 'en',
      },

      tab: null,
      activeTab: "about",

      assignedUser: null,

      myRating: {
        innovation_id: null,
        rating: null,
      },
      userFriendData: {
        user_code: null,
        innovation_id: null,
      },
      newNote: {
        innovation_id: null,
        title: null,
        content: null,
      },

      innovationSource: null,

      rating: 0,
      ratingLoader: false,

      similarCarousel: 0,
      similarArray: [],
      similarLoading: false,

      shareLink: null,

      noteDialog: false,

      notes: [],
      notePagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },

      investments: [],
      investmentPagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },

      investment: {
        id: null,
        amount: null,
        funding_type_id: null,
        investment_firm: null,
        individual_investor: false,
        public: true,
      },
    };
  },
  mounted() {
    this.initialize();
    this.createShareLink();
  },
  watch: {
    top(val) {
      this.bottom = !val;
    },
    right(val) {
      this.left = !val;
    },
    bottom(val) {
      this.top = !val;
    },
    left(val) {
      this.right = !val;
    },

    notFound() {
      this.findInnovation();
    },
  },
  created() {
    window.scrollTo(0, 0);
    if (this.$can("innovation_notes_view")) {
      this.fetchNotes();
    }
    if (this.$can("investment_view")) {
      this.fetchInvestments();
    }
  },
  methods: {
    ...mapActions(["filterSearchInnovations"]),
    initialize() {
      this.myRating.rating = this.innovation.my_rating;
      this.recordView();
      this.chunkSimilarInnovations();
      this.getFormData();
    },
    intToString(value) {
      var suffixes = ["", "k", "M", "B", "T"];
      var suffixNum = Math.floor(("" + value).length / 3);
      var shortValue = parseFloat(
        (suffixNum != 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(2)
      );
      if (shortValue % 1 != 0) {
        shortValue = shortValue.toFixed(1);
      }
      return shortValue + suffixes[suffixNum];
    },
    getFormData() {
      apiCall({
        url: "/api/investment?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
          if(resp.individual_investor == true && resp.investment_firm_administrators.length == 0){
            this.investment.individual_investor = true
          }
        })
        .catch((error) => {
          this.message = "An Error Occurred; Couldn't Get Form Data";
          this.color = "error";
        });
    },
    fetchNotes() {
      this.ratingLoader = true;
      apiCall({
        url:
          "/api/investor-innovation-note?type=innovation&page=" +
          this.notePagination.current_page +
          "&innovation_id=" +
          this.innovation.id,
        method: "GET",
      })
        .then((resp) => {
          this.ratingLoader = false;
          this.notes = resp.data;
          this.notePagination.current_page = resp.current_page;
          this.notePagination.total = resp.total;
          this.notePagination.per_page = resp.per_page;
        })
        .catch((error) => {
          this.ratingLoader = false;
          this.message = "An Error Occurred";
          this.color = "error";
          this.snackbar = true;
        });
    },
    fetchInvestments() {
      this.ratingLoader = true;
      apiCall({
        url:
          "/api/investment?type=innovation&page=" +
          this.investmentPagination.current_page +
          "&innovation_id=" +
          this.innovation.id,
        method: "GET",
      })
        .then((resp) => {
          this.ratingLoader = false;
          this.investments = resp.data;
          this.investmentPagination.current_page = resp.current_page;
          this.investmentPagination.total = resp.total;
          this.investmentPagination.per_page = resp.per_page;
        })
        .catch((error) => {
          this.ratingLoader = false;
          this.message = "An Error Occurred";
          this.color = "error";
          this.snackbar = true;
        });
    },
    findInnovation() {
      apiCall({
        url:
          "/api/innovation?type=findInnovation&page=1&slug=" +
          this.$route.params.slug,
        method: "GET",
      })
        .then((resp) => {
          this.filterSearchInnovations(resp);
          this.chunkSimilarInnovations();
          this.recordView();
        })
        .catch((error) => {
          this.message = "An Error Occurred";
          this.color = "error";
          this.snackbar = true;
        });
    },
    saveInnovation() {
      this.innovationLoader = true;
      this.myRating.innovation_id = this.innovation.id;
      apiCall({
        url: "/api/investor-innovation",
        data: this.myRating,
        method: "POST",
      })
        .then((resp) => {
          this.message = "Innovation Saved Successfully";
          this.color = "success";
          this.snackbar = true;
          this.innovationLoader = false;
        })
        .catch((error) => {
          this.message = "An Error Occurred";
          this.color = "error";
          this.snackbar = true;
          this.innovationLoader = false;
        });
    },
    saveOffer() {
      if (
        this.investment.individual_investor == false &&
        this.investment.investment_firm == null
      ) {
        this.message = "Please pick a Firm or indicate whether you are investing Privately";
        this.color = "success";
        this.snackbar = true;
      } else {
        if (this.$refs.form.validate()) {
          confirm("Submit this offer?") && (this.confirm = true);
          if (this.confirm) {
            this.offerLoading = true;
            this.investment.innovation_id = this.innovation.id;
            apiCall({
              url: "/api/investment",
              data: this.investment,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Offer Submitted Successfully";
                this.color = "success";
                this.snackbar = true;
                this.offerLoading = false;
                this.offerDialog = false;

                this.investment.funding_type_id = null;
                this.investment.amount = null;
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.snackbar = true;
                this.offerLoading = false;
              });
          }
        }
      }
    },
    saveNote() {
      this.noteLoading = true;
      this.newNote.innovation_id = this.innovation.id;
      apiCall({
        url: "/api/investor-innovation-note",
        data: this.newNote,
        method: "POST",
      })
        .then((resp) => {
          this.message = "Note Saved Successfully";
          this.color = "success";
          this.snackbar = true;
          this.noteLoading = false;

          this.noteDialog = false;

          this.newNote.title = null;
          this.newNote.content = null;

          this.fetchNotes();
        })
        .catch((error) => {
          this.message = "An Error Occurred";
          this.color = "error";
          this.snackbar = true;
          this.noteLoading = false;
        });
    },
    createShareLink() {
      this.shareLink = this.path + "/#/innovations/" + this.$route.params.slug;
    },
    chunkSimilarInnovations() {
      this.similarArray = [];
      const chunkSize = 4;
      for (
        let i = 0;
        i < this.innovation.similar_innovations.length;
        i += chunkSize
      ) {
        const chunk = this.innovation.similar_innovations.slice(
          i,
          i + chunkSize
        );

        this.similarArray.push(chunk);
      }
      window.scrollTo(0, 0);
    },
    assignUser(user) {
      this.assignedUser = user;
      this.dialog = true;
    },
    rateInnovation() {
      this.ratingLoader = true;
      this.myRating.innovation_id = this.innovation.id;
      apiCall({
        url: "/api/rating",
        data: this.myRating,
        method: "POST",
      })
        .then((resp) => {
          this.message = "Rated Successfully";
          this.color = "success";
          this.ratingLoader = false;
          this.snackbar = true;
        })
        .catch((error) => {
          this.message = "An Error Occurred; Please Try Again";
          this.color = "error";
          this.ratingLoader = false;
          this.snackbar = true;
        });
    },
    recordView() {
      this.myRating.innovation_id = this.innovation.id;
      apiCall({
        url: "/api/innovation-view",
        data: this.myRating,
        method: "POST",
      })
        .then((resp) => {})
        .catch((error) => {});
    },
    addFriend(user_code) {
      this.connectLoading = true;
      this.userFriendData.innovation_id = this.innovation.id;
      this.userFriendData.user_code = user_code;
      apiCall({
        url: "/api/add-friend",
        data: this.userFriendData,
        method: "POST",
      })
        .then((resp) => {
          this.connectLoading = false;
          this.dialog = false;
          this.color = "success";
          this.message = "Connection Request Sent Successfully";
          this.snackbar = true;
        })
        .catch((error) => {
          this.connectLoading = false;
          this.color = "success";
          this.message = "Connection Request Sent Successfully";
          this.snackbar = true;
        });
    },
    goToInnovation(innovation) {
      window.scrollTo(0, 0);
      this.$router.push("/innovations/" + innovation.slug);
      this.findInnovation();
    },
    goToInvestmentFirm(investmentFirm) {
      window.scrollTo(0, 0);
      this.$router.push("/investors/" + investmentFirm.slug);
    },
    goToInstitution(institution) {
      window.scrollTo(0, 0);
      this.$router.push("/institutions/" + institution.slug);
    },
    goToOrganisation(organisation) {
      window.scrollTo(0, 0);
      this.$router.push("/organisations/" + organisation.slug);
    },
  },
  computed: {
    ...mapGetters([
      "innovationPagination",
      "interestInnovationPagination",
      "recommendedInnovationPagination",
      "popularInnovationPagination",
      "recentInnovationPagination",
    ]),

    innovation() {
      if (
        this.$store.getters.interestInnovations.find(
          (innovation) => innovation.slug == this.$route.params.slug
        )
      ) {
        this.innovationSource = "interest";
        return this.$store.getters.interestInnovations.find(
          (innovation) => innovation.slug == this.$route.params.slug
        );
      } else if (
        this.$store.getters.recommendedInnovations.find(
          (innovation) => innovation.innovation.slug == this.$route.params.slug
        )
      ) {
        this.innovationSource = "recommended";
        var innovation = this.$store.getters.recommendedInnovations.find(
          (innovation) => innovation.innovation.slug == this.$route.params.slug
        );
        return innovation.innovation;
      } else if (
        this.$store.getters.popularInnovations.find(
          (innovation) => innovation.slug == this.$route.params.slug
        )
      ) {
        this.innovationSource = "popular";
        return this.$store.getters.popularInnovations.find(
          (innovation) => innovation.slug == this.$route.params.slug
        );
      } else if (
        this.$store.getters.recentInnovations.find(
          (innovation) => innovation.slug == this.$route.params.slug
        )
      ) {
        this.innovationSource = "recent";
        return this.$store.getters.recentInnovations.find(
          (innovation) => innovation.slug == this.$route.params.slug
        );
      } else if (
        this.$store.getters.searchInnovations.find(
          (innovation) => innovation.slug == this.$route.params.slug
        )
      ) {
        this.innovationSource = "search";
        return this.$store.getters.searchInnovations.find(
          (innovation) => innovation.slug == this.$route.params.slug
        );
      } else {
        this.notFound = true;
      }
    },
    similarInnovationsLength: function () {
      return Math.ceil(this.innovation.similar_innovations.length / 4);
    },

    noteLength: function () {
      return Math.ceil(
        this.notePagination.total / this.notePagination.per_page
      );
    },
    investmentLength: function () {
      return Math.ceil(
        this.investmentPagination.total / this.investmentPagination.per_page
      );
    },
  },
};
</script>
