<template>
  <div class="innovations" v-if="$can('innovation_view')">
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="imageDialog"
      >
        <v-card max-width="600">
          <v-toolbar flat>
            Upload Feature Image
            <v-spacer></v-spacer>
            <v-btn icon @click="imageDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
  
          <v-form ref="imageForm" v-model="imageValid" lazy-validation>
            <v-layout column>
              <v-flex xs12>
                <v-file-input
                  class="mx-2 mt-3"
                  :loading="imageLoading"
                  :rules="fileRules"
                  filled
                  dense
                  show-size
                  chips
                  accept="image/*"
                  v-model="imageFile"
                  label="Select Image"
                ></v-file-input>
              </v-flex>
              <v-flex xs12>
                <div class="mx-3 mb-5">
                  <v-btn
                    depressed
                    block
                    class="primary text-none"
                    @click="uploadImage"
                    :loading="imageLoading"
                    :disabled="!imageValid"
                  >
                    Submit <v-icon right dark>mdi-upload</v-icon>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="logoImageDialog"
      >
        <v-card max-width="600">
          <v-toolbar flat>
            Upload Cover Photo
            <v-spacer></v-spacer>
            <v-btn icon @click="logoImageDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
  
          <v-form ref="imageForm" v-model="imageValid" lazy-validation>
            <v-layout column>
              <v-flex xs12>
                <v-file-input
                  class="mx-2 mt-3"
                  :loading="imageLoading"
                  :rules="fileRules"
                  filled
                  dense
                  show-size
                  chips
                  accept="image/*"
                  v-model="imageFile"
                  label="Select Image"
                ></v-file-input>
              </v-flex>
              <v-flex xs12>
                <div class="mx-3 mb-5">
                  <v-btn
                    depressed
                    block
                    class="primary text-none"
                    @click="uploadLogoImage"
                    :loading="imageLoading"
                    :disabled="!imageValid"
                  >
                    Submit <v-icon right dark>mdi-upload</v-icon>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card>
      </v-dialog>
    <v-container>
      <v-layout column>
        <div v-if="view == 'default'">
          <v-card outlined class="mt-5">
            <v-progress-linear
              v-if="innovationLoader"
              height="1"
              indeterminate
              color="primary"
            >
            </v-progress-linear>
            <v-flex xs12 class="mt-10 mb-2">
              <div class="mx-5">
                <v-layout row wrap>
                  <v-flex xs12 md4> <h1>Innovations</h1> </v-flex>
                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-text-field
                        class="text_field header"
                        outlined
                        dense
                        label="Search"
                        append-icon="mdi-undo-variant"
                        @click:append="resetSearch()"
                        v-on:keyup.enter="search"
                        v-model="searchTerm"
                        @input="enableSearch()"
                      ></v-text-field>
                    </div>
                  </v-flex>
                  <v-flex xs12 md2>
                    <div class="pa-1">
                      <v-btn
                        class="secondary primary--text text-none"
                        depressed
                        block
                        @click="search"
                        :loading="searchLoader"
                        :disabled="searchButtonDisabled"
                      >
                        Search
                        <v-icon right>mdi-magnify</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs12 md2> </v-flex>
                </v-layout>
              </div>
            </v-flex>
            <v-divider class="mt-9 mx-5"></v-divider>
            <v-layout row wrap class="mt-4 mx-5">
              <v-flex xs12 md1>
                <div class="mt-3">
                  <b>Sort By</b>
                </div>
              </v-flex>
             
              <v-flex xs12 md11>
                <v-tabs              
                >
                <v-tab class="text-none" @click="changeInnovationSortOrder('createdDown')">
                    Creation Date
                    <v-icon right>
                      mdi-chevron-down
                    </v-icon>
                  </v-tab>
                  <v-tab class="text-none" @click="changeInnovationSortOrder('createdUp')">
                    Creation Date
                    <v-icon right>
                      mdi-chevron-up
                    </v-icon>
                  </v-tab>
                  <v-tab class="text-none" @click="changeInnovationSortOrder('alphabeticalDown')">
                    Alphabetical
                    <v-icon right>
                      mdi-chevron-down
                    </v-icon>
                  </v-tab>
                  <v-tab class="text-none" @click="changeInnovationSortOrder('alphabeticalUp')">
                    Alphabetical
                    <v-icon right>
                      mdi-chevron-up
                    </v-icon>
                  </v-tab>
                  
                </v-tabs>
              </v-flex>
            </v-layout>
            <v-layout row wrap class="mt-5 mx-5">
              <v-flex xs12 md1>
                <div class="mt-3">
                  <b>Status</b>
                </div>
              </v-flex>
             
              <v-flex xs12 md11>
                  <v-btn v-if="innovationStatus != 'all'" depressed class="mt-1 text-none mr-1" @click="changeInnovationStatus('all')">
                    All
                  </v-btn>
                  <v-btn v-if="innovationStatus == 'all'" depressed class="primary white--text mt-1 text-none mr-1" @click="changeInnovationStatus('all')">
                    All
                  </v-btn>
                  <v-btn v-if="innovationStatus != 'active'" depressed class="mt-1 text-none mx-1"  @click="changeInnovationStatus('active')">
                    Active
                  </v-btn>
                  <v-btn v-if="innovationStatus == 'active'" depressed class="primary white--text mt-1 text-none mx-1" @click="changeInnovationStatus('active')">
                    Active
                  </v-btn>
                  <v-btn v-if="innovationStatus != 'inactive'" depressed class="mt-1 text-none ml-1" @click="changeInnovationStatus('inactive')">
                    Inactive
                  </v-btn>
                  <v-btn v-if="innovationStatus == 'inactive'" depressed class="primary white--text mt-1 text-none ml-1" @click="changeInnovationStatus('inactive')">
                    Inactive
                  </v-btn>
              </v-flex>
            </v-layout>
            <v-flex xs12 class="mt-5">
              <div v-if="innovations.length == 0">
                <v-card elevation="0">
                  <v-layout row wrap>
                    <v-flex xs12 md1>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center">
                            <v-icon large class="primary--text">
                              mdi-alert-circle-outline
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                    <v-flex xs12 md11>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center">
                            No Innovation Found
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                  </v-layout>
                </v-card>
              </div>
              <div v-else>
                <div class="hidden-sm-and-down">
                  <v-card elevation="0" class="pa-2">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead class="header">
                          <tr class="tableHeader">
                            <th class="text-left text--text">Logo</th>
                            <th class="text-left text--text">Name</th>
                            <th class="text-left text--text">IP Protection Type</th>
                            <th class="text-left text--text">Stage</th>
                            <th class="text-left text--text">County</th>
                            <th class="text-left text--text">Status</th>
                            <th class="text-left text--text">Date Created</th>
                            <th class="text-right text--text">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in innovations" :key="item.id">
                            <td>
                              <div class="my-2">
                                <v-avatar size="48" v-if="item.logo==null">
                                  <v-img
                                    max-height="150"
                                    
                                    src="img/innovation.jpg"
                                  >
                                  </v-img>
                                </v-avatar>
                                <v-avatar size="48" v-else>
                                  <v-img
                                      max-height="150"
                                      :src="path+'/storage/innovation_pictures/'+item.logo"
                                    >
                                    </v-img>
                                </v-avatar>
                              </div>
                            </td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.ip_protection_type.name }}</td>
                            <td>{{ item.stage.name }}</td>
                            <td>{{ item.county.name }}</td>
                            <td>
                              <div v-if="item.status == 1">Active</div>
                              <div v-else>Inactive</div>
                            </td>
                            <td>
                              {{
                                item.created_at | moment("DD/MM/YYYY - hh:mm a")
                              }}
                            </td>
                            <td>
                              <div align="right">
                                <v-btn
                                  icon
                                  @click="showInnovation(item)"
                                  v-if="$can('innovation_view')"
                                  class="button mr-1 universal--text"
                                >
                                  <v-icon small> mdi-eye </v-icon>
                                </v-btn>
                                <v-btn
                                  v-if="($can('innovation_edit') && item.permission=='write')"
                                  icon
                                  class="button mr-1 success--text"
                                  @click="editInnovation(item)"
                                >
                                  <v-icon small> mdi-pencil </v-icon>
                                </v-btn>
                                <v-btn
                                  v-if="$can('innovation_archive') && item.permission=='write'"
                                  icon
                                  class="button mr-1 error--text"
                                  @click="deleteInnovation(item)"
                                  :loading="
                                    loading && innovationIndex == item.id
                                  "
                                >
                                  <v-icon small> mdi-delete </v-icon>
                                </v-btn>
                                <v-tooltip
                                  top
                                  v-if="
                                    $can('innovation_activate') &&
                                    item.status == 0 && item.permission=='write'
                                  "
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      class="button mr-1 red--text"
                                      @click="activateInnovation(item)"
                                      :loading="
                                        activateLoading &&
                                        innovationIndex == item.id
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small>
                                        mdi-toggle-switch-off-outline
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Activate Innovation</span>
                                </v-tooltip>
                                <v-tooltip
                                  top
                                  v-if="
                                    $can('innovation_deactivate') &&
                                    item.status == 1 && item.permission=='write'
                                  "
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      class="button mr-1 green--text"
                                      @click="deactivateInnovation(item)"
                                      :loading="
                                        deactivateLoading &&
                                        innovationIndex == item.id
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small> mdi-toggle-switch </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Deactivate Innovation</span>
                                </v-tooltip>
                                <v-tooltip
                                  top
                                  v-if="
                                    ($can('innovation_activate') && item.permission=='write') ||
                                    ($can('innovation_deactivate') && item.permission=='write')

                                  "
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      class="button mr-1 cyan--text"
                                      @click="sendInnovationMessage(item)"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small> mdi-message-alert </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Deactivate/Activate with a Message</span>
                                </v-tooltip>
                                <v-tooltip
                                  top
                                  v-if="
                                    $can('innovation_verify') &&
                                    item.verified == 0 && item.permission=='write'
                                  "
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      class="button mr-1 orange--text text--darken-3"
                                      @click="verifyInnovation(item)"
                                      :loading="
                                        verifyLoading &&
                                        innovationIndex == item.id
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small>
                                        mdi-star
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Verify Innovation</span>
                                </v-tooltip>
                                <v-tooltip
                                  top
                                  v-if="
                                    $can('innovation_unverify') &&
                                    item.verified == 1 && item.permission=='write'
                                  "
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      class="button mr-1 brown--text"
                                      @click="unverifyInnovation(item)"
                                      :loading="
                                        unverifyLoading &&
                                        innovationIndex == item.id
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small>
                                        mdi-star-off
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Unverify Innovation</span>
                                </v-tooltip>
                                <v-tooltip top v-if="$can('log_view')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      class="button mr-1 purple--text"
                                      @click="viewLog(item)"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small> mdi-script-text </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Log</span>
                                </v-tooltip>

                              </div>
                            </td>

                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </div>
                <div class="hidden-md-and-up">
                  <div>
                    <v-layout column>
                      <template v-for="(innovation, index) in innovations">
                        <div :key="index">
                          <v-flex xs12 class="mb-2">
                            <v-card elevation="0">
                              <div class="pa-5">
                                <v-layout column>
                                  <v-flex xs12>
                                    <v-layout row wrap>
                                      <v-flex xs11>
                                        <div class="title text--text">
                                          <b>{{ innovation.name }}</b>
                                        </div>
                                      </v-flex>
                                      <v-flex xs1>
                                        <v-btn
                                          v-if="$can('innovation_archive') && innovation.permission=='write'"
                                          icon
                                          class="mr-2 mb-2 error--text"
                                          @click="deleteInnovation(innovation)"
                                          :loading="
                                            loading && innovationIndex == innovation.id
                                          "
                                        >
                                          <v-icon small> mdi-delete </v-icon>
                                        </v-btn>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-divider class="mt-2"></v-divider>
                                  <v-flex xs12 class="mt-1">
                                    <v-layout column>
                                      <v-flex xs12 class="mt-5" v-if="innovation.ip_protection_type != null">
                                        <v-layout row wrap>
                                          <v-flex xs4>
                                            <b>IP Protection</b>
                                          </v-flex>
                                          <v-flex xs8>
                                            {{ innovation.ip_protection_type.name }}
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-5">
                                        <v-layout row wrap>
                                          <v-flex xs4>
                                            <b>Stage</b>
                                          </v-flex>
                                          <v-flex xs8>
                                            {{ innovation.stage.name }}
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-5">
                                        <v-layout row wrap>
                                          <v-flex xs4>
                                            <b>County</b>
                                          </v-flex>
                                          <v-flex xs8>
                                            {{ innovation.county.name }}
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-5">
                                        <v-layout row wrap>
                                          <v-flex xs4>
                                            <b>Status</b>
                                          </v-flex>
                                          <v-flex xs8>
                                            <div v-if="innovation.status == 1">Active</div>
                                            <div v-else>Inactive</div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-5">
                                        <v-layout row wrap>
                                          <v-flex xs4>
                                            <b>Date Created</b>
                                          </v-flex>
                                          <v-flex xs8>
                                            {{
                                              innovation.created_at
                                                | moment("DD/MM/YYYY - hh:mm a")
                                            }}
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-5">
                                        <v-layout row wrap>
                                          <v-flex xs12>
                                            <div>
                                              <v-btn
                                                depressed
                                                class="primary text-none white--text mt-3"
                                                block
                                                @click="
                                                  showInnovation(innovation)
                                                "
                                                v-if="$can('innovation_view')"
                                                >View
                                                <v-icon right> mdi-eye </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div>
                                              <v-btn
                                                depressed
                                                class="blue text-none white--text mt-1"
                                                block
                                                @click="
                                                  editInnovation(innovation)
                                                "
                                                v-if="($can('innovation_edit') && innovation.permission=='write')"
                                                >Edit
                                                <v-icon right>
                                                  mdi-border-color
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div>
                                              <v-btn
                                                depressed
                                                class="green text-none white--text mt-1"
                                                block
                                                :loading="
                                                  activateLoading &&
                                                  innovationIndex == innovation.id
                                                "
                                                @click="
                                                  activateInnovation(innovation)
                                                "
                                                v-if="($can('innovation_activate') && innovation.status == 0 && innovation.permission=='write')"
                                                >Activate Innovation
                                                <v-icon right>
                                                  mdi-toggle-switch-off-outline
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div>
                                              <v-btn
                                                depressed
                                                class="red text-none white--text mt-1"
                                                block
                                                :loading="
                                                  deactivateLoading &&
                                                  innovationIndex == innovation.id
                                                "
                                                @click="
                                                  deactivateInnovation(innovation)
                                                "
                                                v-if="($can('innovation_deactivate') && innovation.status == 1 && innovation.permission=='write')"
                                                >Deactivate Innovation
                                                <v-icon right>
                                                  mdi-toggle-switch
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div>
                                              <v-btn
                                                depressed
                                                class="orange text-none white--text mt-1"
                                                block
                                                :loading="
                                                  verifyLoading &&
                                                  innovationIndex == innovation.id
                                                "
                                                @click="
                                                  verifyInnovation(innovation)
                                                "
                                                v-if="
                                                  $can('innovation_verify') &&
                                                  innovation.verified == 0 && innovation.permission=='write'
                                                "
                                                >Verify Innovation
                                                <v-icon right>
                                                  mdi-star
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div>
                                              <v-btn
                                                depressed
                                                class="brown text-none white--text mt-1"
                                                block
                                                :loading="
                                                  unverifyLoading &&
                                                  innovationIndex == innovation.id
                                                "
                                                @click="
                                                  unverifyInnovation(innovation)
                                                "
                                                v-if="
                                                  $can('innovation_unverify') &&
                                                  innovation.verified == 1 && innovation.permission=='write'
                                                "
                                                >Unverify Innovation
                                                <v-icon right>
                                                  mdi-star-off
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="mt-1">
                                              <v-btn
                                                v-if="$can('log_view')" 
                                                block
                                                depressed
                                                class="purple text-none white--text"
                                                @click="viewLog(innovation)"
                                                v-bind="attrs"
                                                v-on="on"
                                              > Log
                                                <v-icon small> mdi-script-text </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>

                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </v-card>
                          </v-flex>
                        </div>
                      </template>
                    </v-layout>
                  </div>
                </div>
              </div>
            </v-flex>
          </v-card>
          <v-layout column class="mt-5">
            <v-flex xs12>
              <div align="left">
                <v-pagination
                  v-if="length != 0"
                  :length="length"
                  total-visible="10"
                  v-model="innovationPagination.current_page"
                  @input="changePage()"
                  circle
                >
                </v-pagination>
              </div>
            </v-flex>
            <v-flex xs12>
              <div v-if="length != 0" align="center" class="mt-5">
                <b class="primary--text">Total: </b
                >{{ innovationPagination.total | formatNumber }}
              </div>
            </v-flex>
          </v-layout>
        </div>
        <div v-if="view == 'create'">
          <v-flex class="mt-5">
            <v-card outlined>
              <v-card-title class="secondary primary--text">
                <v-img
                  v-if="newInnovation.id != null"
                  src="img/badge.png"
                  max-width="40"
                ></v-img>
                <v-spacer></v-spacer>
                <div v-if="newInnovation.id == null">Add Innovation</div>
                <div v-else>Edit Innovation</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeView('default')">
                  <v-icon class="primary--text"> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-text>
                    <div class="mx-3">
                      <v-layout row wrap>
                        <v-flex xs12 md5>
                          <div class="mt-5 ml-1">
                            <b>Title of the Innovation</b
                            ><span class="red--text">*</span>
                          </div>
                          <v-text-field
                            outlined
                            dense
                            v-model="newInnovation.title"
                            :rules="inputRules"
                            prepend-inner-icon="mdi-format-title"
                            class="background login_field mx-1"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 md5>
                          <div class="mt-5 ml-1"><b>Tagline</b></div>
                          <v-text-field
                            outlined
                            dense
                            v-model="newInnovation.tagline"
                            prepend-inner-icon="mdi-subtitles"
                            class="background login_field mx-1"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 md2>
                          <div class="mt-5 ml-1">
                            <b>Year</b><span class="red--text">*</span>
                          </div>
                          <v-select
                            :items="years"
                            :rules="inputRules"
                            outlined
                            dense
                            v-model="newInnovation.year"
                            prepend-inner-icon="mdi-calendar"
                            class="background login_field mx-1"
                          ></v-select>
                        </v-flex>
                        <v-flex xs12 v-if="formData != null">
                          <div class="mt-5 ml-1">
                            <b>IP Protection Type</b
                            ><span class="red--text">*</span>
                          </div>
                          <v-radio-group
                            v-model="newInnovation.ip_protection_type_id"
                            row
                            :rules="inputRules"
                          >
                            <v-radio
                              v-for="(
                                ip_protection_type, index
                              ) in formData.ip_protection_types"
                              :label="ip_protection_type.name"
                              :value="ip_protection_type.id"
                            ></v-radio>
                          </v-radio-group>
                        </v-flex>
                        <v-flex xs12 md6 v-if="formData != null">
                          <div class="ml-1">
                            <b>Stage</b><span class="red--text">*</span>
                          </div>
                          <v-select
                            :items="formData.stages"
                            :rules="inputRules"
                            outlined
                            dense
                            v-model="newInnovation.stage_id"
                            item-text="name"
                            item-value="id"
                            prepend-inner-icon="mdi-signal-cellular-2"
                            class="background login_field mx-1"
                          ></v-select>
                        </v-flex>
                        <v-flex xs12 md6 v-if="formData != null">
                          <div class="ml-1">
                            <b>County HQ</b><span class="red--text">*</span>
                          </div>
                          <v-select
                            :items="formData.counties"
                            :rules="inputRules"
                            outlined
                            dense
                            v-model="newInnovation.county_id"
                            item-text="name"
                            item-value="id"
                            prepend-inner-icon="mdi-map-marker"
                            class="background login_field mx-1"
                          ></v-select>
                        </v-flex>
                        <v-flex xs12 class="mt-5">
                          <div class="ml-1">
                            <b>Description</b><span class="red--text">*</span>
                          </div>
                          <ckeditor
                            :editor="editor"
                            v-model="newInnovation.description"
                            :config="editorConfig"
                            :rules="inputRules"
                          ></ckeditor>
                        </v-flex>
                        <v-flex xs12 v-if="formData != null" class="mt-5">
                          <div class="ml-1">
                            <b>What are you looking for on Innovation Bridge?</b
                            ><span class="red--text">*</span>
                          </div>
                          <div class="mx-3">
                            <v-layout row wrap>
                              <template
                                v-for="(interest, index) in formData.interests"
                              >
                                <v-flex xs12 md3 :key="index">
                                  <v-checkbox
                                    v-model="newInnovation.interests"
                                    :label="interest.name"
                                    :color="interest.color"
                                    :value="interest.id"
                                    hide-details
                                  >
                                  </v-checkbox>
                                </v-flex>
                              </template>
                            </v-layout>
                          </div>
                        </v-flex>
                        <v-flex xs12 v-if="formData != null" class="mt-9">
                          <div class="ml-1">
                            <b>What sector(s) does your innovation explore?</b
                            ><span class="red--text">*</span>
                          </div>
                          <div class="mx-3">
                            <v-layout row wrap>
                              <template
                                v-for="(interest, index) in formData.sectors"
                              >
                                <v-flex xs12 md3 :key="index">
                                  <v-checkbox
                                    v-model="newInnovation.sectors"
                                    :label="interest.name"
                                    :color="interest.color"
                                    :value="interest.id"
                                    hide-details
                                  >
                                  </v-checkbox>
                                </v-flex>
                              </template>
                            </v-layout>
                          </div>
                        </v-flex>
                        <v-flex xs12 v-if="formData != null" class="mt-9">
                          <div class="ml-1">
                            <b>What SDG(s) does your innovation address?</b
                            ><span class="red--text">*</span>
                          </div>
                          <div class="mx-3">
                            <v-layout row wrap>
                              <template
                                v-for="(interest, index) in formData.sdgs"
                              >
                                <v-flex xs12 md3 :key="index">
                                  <v-checkbox
                                    v-model="newInnovation.sdgs"
                                    :label="interest.name"
                                    :color="interest.color"
                                    :value="interest.id"
                                    hide-details
                                  >
                                  </v-checkbox>
                                </v-flex>
                              </template>
                            </v-layout>
                          </div>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-card-text>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  class="primary text-none mt-3"
                  @click="save"
                  :disabled="!valid"
                  :loading="loading"
                >
                  Save
                  <v-icon right> mdi-content-save </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </div>
        <div v-if="view == 'show'">
          <v-card outlined class="mt-5">
            <v-progress-linear
              v-if="institutionAffiliationLoader"
              height="1"
              indeterminate
              color="primary"
            >
            </v-progress-linear>
            <v-card-title class="tableHeader text--text">
              <v-spacer></v-spacer>
              <div>View Innovation</div>
              <v-spacer></v-spacer>
              <v-btn icon @click="changeView('default')">
                <v-icon class="text--text"> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div class="pa-1">
                <v-tabs show-arrows v-model="innovationTab">
                  <v-tabs-slider color="primary"></v-tabs-slider>

                  <v-tab @click="activeTab = 'details'">
                    <div class="text-none text--text">Details</div>
                  </v-tab>
                  <v-tab @click="activeTab = 'investments'">
                    <div class="text-none text--text">Investments ({{ investmentPagination.total }})</div>
                  </v-tab>
                  <v-tab
                    @click="activeTab = 'innovationAdministrators'"
                    v-if="$can('innovation_administrator_view')"
                  >
                    <div class="text-none text--text">
                      Team Members ({{
                        innovationAdministratorPagination.total
                      }})
                    </div>
                  </v-tab>
                  <v-tab
                    @click="activeTab = 'institutionAffiliations'"
                    v-if="$can('innovation_institution_affiliation_view')"
                  >
                    <div class="text-none text--text">
                      Institution Affiliations ({{
                        institutionAffiliationPagination.total
                      }})
                    </div>
                  </v-tab>
                  <v-tab
                    @click="activeTab = 'organisationAffiliations'"
                    v-if="$can('innovation_organisation_affiliation_view')"
                  >
                    <div class="text-none text--text">
                      Organisation Affiliations ({{
                        organisationAffiliationPagination.total
                      }})
                    </div>
                  </v-tab>
                  
                </v-tabs>
                <div v-if="activeTab == 'details'">
                  <v-card-text>
                    <div class="pa-3">
                      <v-layout row wrap>
                        <v-flex xs12 md3>
                          <div class="mr-8">
                            <v-img
                              max-height="150"
                              v-if="assignedInnovation.featured_image==null"
                              src="img/innovation.jpg"
                              style="z-index: 2;border-radius: 25px;"
                            >
                              <div align="right" class="mt-2 mr-2">
                                <v-btn
                                  class="white"
                                  icon
                                  small
                                  @click="imageDialog = true"
                                  v-if="$can('innovation_image_upload')"
                                  ><v-icon class="primary--text" small>mdi-pen</v-icon></v-btn
                                >
                              </div>
                              <v-avatar
                                size="56"
                                style="position: absolute; top: 60px; left: 2px; z-index: 10"
                              >
                                <v-img
                                  v-if="assignedInnovation.logo==null"
                                  src="img/innovation.jpg"
                                >
                                </v-img>
                                <v-img
                                  v-else
                                  :src="path+'/storage/innovation_pictures/'+assignedInnovation.logo"
                                >
                                </v-img>
                              </v-avatar>


                            </v-img>
                            <v-img
                              max-height="150"
                              v-else
                              :src="path+'/storage/innovation_pictures/'+assignedInnovation.featured_image"
                              style="z-index: 2;border-radius: 25px;"
                            >
                              <div align="right" class="mt-2 mr-2">
                                  <v-btn
                                    class="white"
                                    icon
                                    small
                                    @click="imageDialog = true"
                                    v-if="$can('innovation_image_upload')"
                                    ><v-icon class="primary--text" small>mdi-pen</v-icon></v-btn
                                  >
                                </div>
                                <v-avatar
                                  size="56"
                                  style="position: absolute; top: 70px; left: 2px; z-index: 10"
                                >
                                  <v-img
                                    v-if="assignedInnovation.logo==null"
                                    src="img/innovation.jpg"
                                  >
                                  </v-img>
                                  <v-img
                                    v-else
                                    :src="path+'/storage/innovation_pictures/'+assignedInnovation.logo"
                                  >
                                  </v-img>
                                </v-avatar>
                            </v-img>
                          </div>
                          <div align="center" class="mt-5">
                            <v-btn class="primary text-none" depressed @click="logoImageDialog = true" v-if="$can('innovation_image_upload')">
                              Upload Logo
                              <v-icon right>mdi-file-upload</v-icon>
                            </v-btn>
                          </div>
                          
                        </v-flex>
                        <v-flex xs12 md9>
                          <v-layout column>
                        <v-flex xs12>
                          <v-layout row wrap>
                            <v-flex xs12 md6>
                              <v-layout column>
                                <v-flex xs12 class="mt-6">
                                  <v-layout row wrap>
                                    <v-flex xs12 md4>
                                      <b>Innovation Name: </b>
                                    </v-flex>
                                    <v-flex xs12 md8>
                                      <div>{{ assignedInnovation.name }}</div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-6">
                                  <v-layout row wrap>
                                    <v-flex xs12 md4>
                                      <b>IP Protection Type: </b>
                                    </v-flex>
                                    <v-flex xs12 md8>
                                      <div>
                                        {{
                                          assignedInnovation.ip_protection_type
                                            .name
                                        }}
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>

                                <v-flex xs12 class="mt-6">
                                  <v-layout row wrap>
                                    <v-flex xs12 md4>
                                      <b>Stage: </b>
                                    </v-flex>
                                    <v-flex xs12 md8>
                                      <div>
                                        {{ assignedInnovation.stage.name }}
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-6">
                                  <v-layout row wrap>
                                    <v-flex xs12 md4>
                                      <b>County: </b>
                                    </v-flex>
                                    <v-flex xs12 md8>
                                      <div>
                                        {{ assignedInnovation.county.name }}
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 md6>
                              <v-layout column>
                                <v-flex xs12 class="mt-6">
                                  <v-layout row wrap>
                                    <v-flex xs12 md4>
                                      <b>Establishment Year: </b>
                                    </v-flex>
                                    <v-flex xs12 md8>
                                      <div>
                                        {{
                                          assignedInnovation.establishment_year
                                        }}
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-6">
                                  <v-layout row wrap>
                                    <v-flex xs12 md4>
                                      <b>Date Created: </b>
                                    </v-flex>
                                    <v-flex xs12 md8>
                                      <div>
                                        {{
                                          assignedInnovation.created_at
                                            | moment("DD/MM/YYYY - hh:mm a")
                                        }}
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-6">
                                  <v-layout row wrap>
                                    <v-flex xs12 md4>
                                      <b>Status: </b>
                                    </v-flex>
                                    <v-flex xs12 md8>
                                      <div
                                        v-if="assignedInnovation.status == 1"
                                      >
                                        Active
                                      </div>
                                      <div v-else>Inactive</div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-flex>

                            <v-flex xs12 class="mt-12">
                              <v-layout row wrap>
                                <v-flex xs12 md2>
                                  <div class="mt-4">
                                    <b>Rating: </b>
                                  </div>
                                </v-flex>
                                <v-flex xs12 md10>
                                  <div>
                                    <v-row align="center" class="mx-0">
                                      <v-rating
                                        :value="assignedInnovation.average_rating"
                                        color="amber"
                                        dense
                                        half-increments
                                        readonly
                                        size="14"
                                      ></v-rating>
                                      <div class="grey--text ms-4">
                                        {{ assignedInnovation.average_rating }} ({{
                                          assignedInnovation.total_rating
                                        }})
                                      </div>
                                      <v-switch
                                        v-if="assignedInnovation.permission=='write'"
                                        class="ml-5"
                                        v-model="newInnovation.rating_public"
                                        :loading="loading"
                                        @change="updateRating(assignedInnovation)"
                                      ></v-switch>
                                      <v-switch
                                        v-if="assignedInnovation.permission=='read'"
                                        class="ml-5"
                                        v-model="newInnovation.rating_public"
                                        :loading="loading"
                                        disabled
                                      ></v-switch>
                                      <div class="grey--text ms-4" v-if="newInnovation.rating_public == 1">
                                        Public
                                      </div>
                                      <div class="grey--text ms-4" v-if="newInnovation.rating_public == 0">
                                        Private
                                      </div>
                                    </v-row>
                                      
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap>
                                <v-flex xs12 md2>
                                  <b>Tagline: </b>
                                </v-flex>
                                <v-flex xs12 md10>
                                  <div class="ml-1">
                                    {{ assignedInnovation.brief }}
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>

                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap>
                                <v-flex xs12 md2>
                                  <b>Description: </b>
                                </v-flex>
                                <v-flex xs12 md10>
                                  <p class="ml-1"
                                    v-html="assignedInnovation.description"
                                  ></p>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-6">
                              <v-layout row wrap>
                                <v-flex xs12 md2>
                                  <div class="mt-2"><b>Actions: </b></div>
                                </v-flex>
                                <v-flex xs12 md10>
                                  <div align="left">
                              
                                <v-btn
                                  v-if="($can('innovation_edit') && assignedInnovation.permission=='write')"
                                  depressed
                                  small
                                  class="success mr-1 white--text text-none"
                                  @click="editInnovation(assignedInnovation)"
                                > 
                                    Edit
                                  <v-icon right small> mdi-pencil </v-icon>
                                </v-btn>
                                <v-btn
                                  v-if="$can('innovation_archive') && assignedInnovation.permission=='write'"
                                  depressed
                                  small
                                  class="error mr-1 white--text text-none"
                                  @click="deleteInnovation(assignedInnovation)"
                                  :loading="
                                    loading && innovationIndex == assignedInnovation.id
                                  "
                                >
                                  Delete
                                  <v-icon right small> mdi-delete </v-icon>
                                </v-btn>
                                <v-tooltip
                                  top
                                  v-if="
                                    $can('innovation_activate') &&
                                    assignedInnovation.status == 0 && assignedInnovation.permission=='write'
                                  "
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      depressed
                                      small
                                      class="red mr-1 white--text text-none"
                                      @click="activateInnovation(assignedInnovation)"
                                      :loading="
                                        activateLoading &&
                                        innovationIndex == assignedInnovation.id
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      Activate
                                      <v-icon right small>
                                        mdi-toggle-switch-off-outline
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Activate Innovation</span>
                                </v-tooltip>
                                <v-tooltip
                                  top
                                  v-if="
                                    $can('innovation_deactivate') &&
                                    assignedInnovation.status == 1 && assignedInnovation.permission=='write'
                                  "
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      depressed
                                      small
                                      class="green mr-1 white--text text-none"
                                      @click="deactivateInnovation(assignedInnovation)"
                                      :loading="
                                        deactivateLoading &&
                                        innovationIndex == assignedInnovation.id
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      Deactivate
                                      <v-icon right small> mdi-toggle-switch </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Deactivate Innovation</span>
                                </v-tooltip>
                                <v-tooltip
                                  top
                                  v-if="
                                    ($can('innovation_activate') && assignedInnovation.permission=='write') ||
                                    ($can('innovation_deactivate') && assignedInnovation.permission=='write')

                                  "
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      depressed
                                      small
                                      class="cyan mr-1 white--text text-none"
                                      @click="sendInnovationMessage(assignedInnovation)"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                        Act/Deact W/ Message 
                                      <v-icon right small> mdi-message-alert </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Deactivate/Activate with a Message</span>
                                </v-tooltip>
                                <v-tooltip
                                  top
                                  v-if="
                                    $can('innovation_verify') &&
                                    assignedInnovation.verified == 0 && assignedInnovation.permission=='write'
                                  "
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      depressed
                                      small
                                      class="orange darken-3 mr-1 white--text text-none"
                                      @click="verifyInnovation(assignedInnovation)"
                                      :loading="
                                        verifyLoading &&
                                        innovationIndex == assignedInnovation.id
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      Verify
                                      <v-icon right small>
                                        mdi-star
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Verify Innovation</span>
                                </v-tooltip>
                                <v-tooltip
                                  top
                                  v-if="
                                    $can('innovation_unverify') &&
                                    assignedInnovation.verified == 1 && assignedInnovation.permission=='write'
                                  "
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      depressed
                                      small
                                      class="brown mr-1 white--text text-none"
                                      @click="unverifyInnovation(assignedInnovation)"
                                      :loading="
                                        unverifyLoading &&
                                        innovationIndex == assignedInnovation.id
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      Unverify
                                      <v-icon right small>
                                        mdi-star-off
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Unverify Innovation</span>
                                </v-tooltip>
                                <v-tooltip top v-if="$can('log_view')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      depressed
                                      small
                                      class="purple mr-1 white--text text-none"
                                      @click="viewLog(assignedInnovation)"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      Logs
                                      <v-icon right small> mdi-script-text </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Logs</span>
                                </v-tooltip>

                              </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                        </v-flex>
                      </v-layout>
                      
                    </div>
                  </v-card-text>



                  <v-card-title class="tableHeader text--text mt-5">
                    <v-spacer></v-spacer>
                    <div>Innovation Statistics</div>
                    <v-spacer></v-spacer>
                    <v-btn
                      icon
                      @click="changeInnovationStatisticView('create')"
                      v-if="
                        innovationStatisticView == 'default' &&
                        assignedInnovation.innovation_statistic == null &&
                        assignedInnovation.permission == 'write'
                      "
                    >
                      <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn>

                    <v-btn
                      icon
                      @click="changeInnovationStatisticView('default')"
                      v-if="innovationStatisticView != 'default'"
                    >
                      <v-icon> mdi-close </v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <div v-if="innovationStatisticView == 'default'">
                      <div class="pa-3 mt-5">
                        <div
                          v-if="assignedInnovation.innovation_statistic == null"
                        >
                          <v-layout row wrap>
                            <v-flex xs12 md1>
                              <v-container fill-height fluid>
                                <v-row align="center" justify="center">
                                  <v-col class="text-center">
                                    <v-icon large class="text--text">
                                      mdi-alert-circle-outline
                                    </v-icon>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md11>
                              <v-container fill-height fluid>
                                <v-row align="center" justify="center">
                                  <v-col class="text-center">
                                    Innovation Statistics Data Not Added
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-flex>
                          </v-layout>
                        </div>
                        <div v-else>
                          <v-layout column>
                            <v-flex xs12>
                              <div class="ml-3">
                                <v-layout row wrap>
                                  <v-flex xs11>
                                    <v-layout row wrap>
                                      <v-flex xs12 md6>
                                        <v-layout column>
                                          
                                          <v-flex xs12 class="mt-6">
                                            <v-layout
                                              row
                                              wrap
                                            >
                                              <v-flex xs12 md4>
                                                <b>No of Customers: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>
                                                  {{
                                                    assignedInnovation.innovation_statistic
                                                      .customer_class.name
                                                  }}
                                                </div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex
                                            xs12
                                            class="mt-6"
                                          >
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>No of Employees: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>
                                                 {{
                                                      assignedInnovation.innovation_statistic
                                                        .employee_class
                                                        .name
                                                    }}
                                                </div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex
                                            xs12
                                            class="mt-6"                                           
                                          >
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>No of Users: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>
                                                 {{
                                                      assignedInnovation.innovation_statistic
                                                        .user_class
                                                        .name
                                                    }}
                                                </div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 md6>
                                        <v-layout column>
                                          <v-flex
                                            xs12
                                            class="mt-6"
                                          >
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Total Revenue: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>
                                                  {{
                                                    assignedInnovation.innovation_statistic
                                                      .revenue_class.name
                                                  }}
                                                </div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex
                                            xs12
                                            class="mt-6"
                                          >
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Total Investments: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>
                                                  {{
                                                      assignedInnovation.innovation_statistic
                                                        .investment_class
                                                        .name
                                                    }}
                                                </div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                         
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs1>
                                    <v-btn
                                      icon
                                      @click="
                                        editInnovationStatistic(
                                          assignedInnovation.innovation_statistic
                                        )
                                      "
                                      v-if="assignedInnovation.permission == 'write'"
                                    >
                                      <v-icon small class="green--text"
                                        >mdi-pencil</v-icon
                                      >
                                    </v-btn>
                                    <v-btn
                                      icon
                                      @click="
                                        deleteInnovationStatistic(
                                          assignedInnovation.innovation_statistic
                                        )
                                      "
                                      v-if="assignedInnovation.permission == 'write'"
                                      :loading="statisticLoading"
                                    >
                                      <v-icon small class="red--text"
                                        >mdi-delete</v-icon
                                      >
                                    </v-btn>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </v-flex>
                          </v-layout>
                        </div>
                      </div>
                    </div>
                    <div v-if="innovationStatisticView == 'create'" class="mt-5">
                      <v-form v-model="valid" ref="innovationStatisticForm" lazy-validation>
                        <v-layout row wrap>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Number of Users</div>
                              <v-select
                                :items="formData.user_classes"
                                :rules="inputRules"
                                outlined
                                dense
                                item-text="name"
                                item-value="id"
                                v-model="newInnovationStatistic.user_class_id"
                                class="background login_field my-2"
                              ></v-select>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Number of Customers</div>
                              <v-select
                                :items="formData.user_classes"
                                :rules="inputRules"
                                outlined
                                dense
                                item-text="name"
                                item-value="id"
                                v-model="newInnovationStatistic.customer_class_id"
                                class="background login_field my-2"
                              ></v-select>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Number of Employees</div>
                              <v-select
                                :items="formData.employee_classes"
                                :rules="inputRules"
                                outlined
                                dense
                                item-text="name"
                                item-value="id"
                                v-model="newInnovationStatistic.employee_class_id"
                                class="background login_field my-2"
                              ></v-select>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Revenue (Kshs)</div>
                              <v-select
                                :items="formData.revenue_classes"
                                :rules="inputRules"
                                outlined
                                dense
                                item-text="name"
                                item-value="id"
                                v-model="newInnovationStatistic.revenue_class_id"
                                class="background login_field my-2"
                              ></v-select>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Investment (Kshs)</div>
                              <v-select
                                :items="formData.investment_classes"
                                :rules="inputRules"
                                outlined
                                dense
                                item-text="name"
                                item-value="id"
                                v-model="newInnovationStatistic.investment_class_id"
                                class="background login_field my-2"
                              ></v-select>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div align="right">
                              <v-btn
                                depressed
                                class="primary text-none mt-9"
                                @click="saveInnovationStatistic"
                                :disabled="!valid"
                                :loading="statisticLoading"
                              >
                                Save
                                <v-icon right> mdi-content-save </v-icon>
                              </v-btn>
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-form>
                    </div>
                  </v-card-text>





                  <v-card-title class="tableHeader text--text mt-5">
                    <v-spacer></v-spacer>
                    <div>Innovation Social Details</div>
                    <v-spacer></v-spacer>
                    <v-btn
                      icon
                      @click="changeSocialView('create')"
                      v-if="
                        $can('innovation_social_create') &&
                        contactView == 'default' &&
                        assignedInnovation.innovation_social == null &&
                        assignedInnovation.permission == 'write'
                      "
                    >
                      <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn>

                    <v-btn
                      icon
                      @click="changeSocialView('default')"
                      v-if="contactView != 'default'"
                    >
                      <v-icon> mdi-close </v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <div v-if="contactView == 'default'">
                      <div class="pa-3 mt-5">
                        <div
                          v-if="assignedInnovation.innovation_social == null"
                        >
                          <v-layout row wrap>
                            <v-flex xs12 md1>
                              <v-container fill-height fluid>
                                <v-row align="center" justify="center">
                                  <v-col class="text-center">
                                    <v-icon large class="text--text">
                                      mdi-alert-circle-outline
                                    </v-icon>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md11>
                              <v-container fill-height fluid>
                                <v-row align="center" justify="center">
                                  <v-col class="text-center">
                                    Socials Not Added
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-flex>
                          </v-layout>
                        </div>
                        <div v-else>
                          <v-layout column>
                            <v-flex xs12>
                              <div class="ml-3">
                                <v-layout row wrap>
                                  <v-flex xs11>
                                    <v-layout row wrap>
                                      <v-flex xs12 md6>
                                        <v-layout column>
                                          
                                          <v-flex xs12 class="mt-6">
                                            <v-layout
                                              row
                                              wrap
                                            >
                                              <v-flex xs12 md4>
                                                <b>Email Address: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>
                                                  {{
                                                    assignedInnovation
                                                      .innovation_social.email
                                                  }}
                                                </div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex
                                            xs12
                                            class="mt-6"
                                          >
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Website URL: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>
                                                  <a
                                                    :href="
                                                      assignedInnovation
                                                        .innovation_social
                                                        .web_url
                                                    "
                                                    target="_blank"
                                                    style="
                                                      text-decoration: none;
                                                    "
                                                    >{{
                                                      assignedInnovation
                                                        .innovation_social
                                                        .web_url
                                                    }}</a
                                                  >
                                                </div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex
                                            xs12
                                            class="mt-6"
                                          >
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Twitter URL: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>
                                                  <a
                                                    :href="
                                                      assignedInnovation
                                                        .innovation_social
                                                        .twitter_url
                                                    "
                                                    target="_blank"
                                                    style="
                                                      text-decoration: none;
                                                    "
                                                    >{{
                                                      assignedInnovation
                                                        .innovation_social
                                                        .twitter_url
                                                    }}</a
                                                  >
                                                </div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 md6>
                                        <v-layout column>
                                          <v-flex
                                            xs12
                                            class="mt-6"
                                          >
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Phone #: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>
                                                  {{
                                                    assignedInnovation
                                                      .innovation_social.phone
                                                  }}
                                                </div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex
                                            xs12
                                            class="mt-6"                                           
                                          >
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Facebook URL: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>
                                                  <a
                                                    :href="
                                                      assignedInnovation
                                                        .innovation_social
                                                        .fb_url
                                                    "
                                                    target="_blank"
                                                    style="
                                                      text-decoration: none;
                                                    "
                                                    >{{
                                                      assignedInnovation
                                                        .innovation_social
                                                        .fb_url
                                                    }}</a
                                                  >
                                                </div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex
                                            xs12
                                            class="mt-6"
                                          >
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Instagram URL: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div>
                                                  <a
                                                    :href="
                                                      assignedInnovation
                                                        .innovation_social
                                                        .insta_url
                                                    "
                                                    target="_blank"
                                                    style="
                                                      text-decoration: none;
                                                    "
                                                    >{{
                                                      assignedInnovation
                                                        .innovation_social
                                                        .insta_url
                                                    }}</a
                                                  >
                                                </div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex
                                            xs12
                                            class="mt-6"
                                          >
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Visibility: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div
                                                  v-if="
                                                    assignedInnovation
                                                      .innovation_social
                                                      .visible == 1
                                                  "
                                                >
                                                  Public
                                                </div>
                                                <div v-else>Hidden</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs1>
                                    <v-btn
                                      icon
                                      @click="
                                        editContact(
                                          assignedInnovation.innovation_social
                                        )
                                      "
                                      v-if="$can('innovation_social_edit') && assignedInnovation.permission == 'write'"
                                    >
                                      <v-icon small class="green--text"
                                        >mdi-pencil</v-icon
                                      >
                                    </v-btn>
                                    <v-btn
                                      icon
                                      @click="
                                        deleteContact(
                                          assignedInnovation.innovation_social
                                        )
                                      "
                                      v-if="$can('innovation_social_archive') && assignedInnovation.permission == 'write'"
                                      :loading="loading"
                                    >
                                      <v-icon small class="red--text"
                                        >mdi-delete</v-icon
                                      >
                                    </v-btn>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </v-flex>
                          </v-layout>
                        </div>
                      </div>
                    </div>
                    <div v-if="contactView == 'create'" class="mt-5">
                      <v-form v-model="valid" ref="contactForm" lazy-validation>
                        <v-layout row wrap>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Email Address</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newSocial.email"
                                :rules="emailRules"
                              ></v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Phone Number</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newSocial.phone"
                              ></v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Website URL</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newSocial.web_url"
                                :rules="websiteRules"
                              ></v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Facebook Page URL</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newSocial.fb_url"
                              ></v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Instagram Account URL</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newSocial.insta_url"
                              ></v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Twitter Account URL</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newSocial.twitter_url"
                              ></v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Linkedin Account URL</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newSocial.linkedin_url"
                              ></v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="mt-9">
                              <v-checkbox
                                class="text_field"
                                outlined
                                dense
                                v-model="newSocial.visible"
                                label="Visible"
                              ></v-checkbox>
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div align="right">
                              <v-btn
                                depressed
                                class="primary text-none mt-3"
                                @click="saveContact"
                                :disabled="!valid"
                                :loading="loading"
                              >
                                Save
                                <v-icon right> mdi-content-save </v-icon>
                              </v-btn>
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-form>
                    </div>
                  </v-card-text>
                </div>

                <div v-if="activeTab == 'investments'">
                  <v-card elevation="0" class="mt-5">
                      <v-flex xs12 class="mt-10 mb-2">
                        <div class="mx-5">
                          <v-layout row wrap>
                            <v-flex xs12 md4> </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <v-text-field
                                  class="text_field header"
                                  outlined
                                  dense
                                  label="Search"
                                  append-icon="mdi-undo-variant"
                                  @click:append="
                                    resetInvestmentSearch()
                                  "
                                  v-on:keyup.enter="
                                    investmentSearch
                                  "
                                  v-model="investmentSearchTerm"
                                  @input="
                                    enableInvestmentSearch()
                                  "
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md2>
                              <div class="pa-1">
                                <v-btn
                                  class="secondary primary--text text-none"
                                  depressed
                                  block
                                  @click="investmentSearch"
                                  :loading="
                                    investmentSearchLoader
                                  "
                                  :disabled="
                                    investmentSearchButtonDisabled
                                  "
                                >
                                  Search
                                  <v-icon right>mdi-magnify</v-icon>
                                </v-btn>
                              </div>
                            </v-flex>
                            <v-flex xs12 md2>
                              
                            </v-flex>
                          </v-layout>
                        </div>
                      </v-flex>
                      <v-divider class="mt-9 mx-5"></v-divider>
                      <v-flex xs12 class="mt-5">
                        <div v-if="investments.length == 0">
                          <v-card elevation="0">
                            <v-layout row wrap>
                              <v-flex xs12 md1>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      <v-icon large class="primary--text">
                                        mdi-alert-circle-outline
                                      </v-icon>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                              <v-flex xs12 md11>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      No Investment(s) Found
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </div>
                        <div v-else>
                          <div class="hidden-sm-and-down">
                            <v-card elevation="0" class="pa-2">
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead class="header">
                                    <tr class="tableHeader">
                                      <th class="text-left text--text">Pic</th>
                                      <th class="text-left text--text">Name</th>
                                      <th class="text-left text--text">Investor Type</th>
                                      <th class="text-left text--text">Amount (Kshs)</th>
                                      <th class="text-left text--text">Funding Type</th>
                                      <th class="text-left text--text">Offer Date</th>
                                      <th class="text-left text--text">Status</th>                                      
                                      <th class="text-right text--text">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="item in investments"
                                      :key="item.id"
                                    >
                                      <td>
                                        <div class="my-2">
                                          <div v-if="item.individual_investor_investment == null">
                                            <v-avatar size="48" v-if="item.investment_firm_investments[0].investment_firm.logo==null">
                                              <v-img
                                                max-height="150"
                                                
                                                src="img/innovation.jpg"
                                              >
                                              </v-img>
                                            </v-avatar>
                                            <v-avatar size="48" v-else>
                                              <v-img
                                                  max-height="150"
                                                  :src="path+'/storage/investment_firm_pictures/'+item.investment_firm_investments[0].investment_firm.logo"
                                                >
                                                </v-img>
                                            </v-avatar>
                                          </div>
                                          <div v-else>
                                            <v-avatar color="primary" class="white--text" size="48" v-if="item.individual_investor_investment.user.display_img==null">
                                              {{item.individual_investor_investment.user.f_name[0]}}
                                            </v-avatar>
                                            <v-avatar size="48" v-else>
                                              <v-img
                                                  max-height="150"
                                                  :src="path+'/storage/profile_pics/'+item.individual_investor_investment.user.display_img"
                                                >
                                                </v-img>
                                            </v-avatar>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div v-if="item.individual_investor_investment == null">
                                          {{ item.investment_firm_investments[0].investment_firm.name }}
                                        </div>
                                        <div v-else>
                                          {{ item.individual_investor_investment.user.f_name }} {{ item.individual_investor_investment.user.m_name }} {{ item.individual_investor_investment.user.l_name }}
                                        </div>                                        
                                      </td>
                                      <td>
                                        <div v-if="item.individual_investor_investment == null">
                                          {{ item.investment_firm_investments[0].investment_firm.investor_type.name }}
                                        </div>
                                        <div v-else>
                                          {{ item.individual_investor_investment.user.individual_investor.investor_type.name }}
                                        </div>
                                      </td>
                                      <td>{{ intToString(item.amount) }}</td>
                                      <td>{{ item.funding_type.name }}</td>
                                      <td>{{ item.created_at | moment("Do MMMM YYYY") }}</td>
                                      <td>
                                        <div v-if="(item.status==0)">
                                            Pending
                                        </div>
                                        <div v-if="(item.status==1)">
                                            Accepted
                                        </div>
                                        <div v-if="(item.status==2)">
                                            Rejected
                                        </div>
                                      </td>
                                      
                                      <td>
                                        <div align="right">
                                          <v-btn
                                            icon
                                            @click="
                                              showInvestmentFirm(
                                                item.investment_firm_investments[0].investment_firm
                                              )
                                            "
                                            v-if="item.individual_investor_investment == null"
                                            class="button mr-1 universal--text"
                                          >
                                            <v-icon small> mdi-eye </v-icon>
                                          </v-btn>
                                          <v-btn
                                            class="button mr-1 green--text text-none my-1"
                                            @click="
                                              acceptInvestment(
                                                item
                                              )
                                            "
                                            :loading="
                                              activateLoading &&
                                              innovationAdministratorIndex ==
                                                item.id
                                            "
                                            depressed
                                            v-if="item.status == 0 && assignedInnovation.permission == 'write'"
                                          >
                                            Accept
                                            <v-icon right small>
                                              mdi-toggle-switch-off-outline
                                            </v-icon>
                                          </v-btn>
                                          <v-btn
                                            class="button mr-1 red--text text-none my-1"
                                            @click="
                                              rejectInvestment(
                                                item
                                              )
                                            "
                                            :loading="
                                              deactivateLoading &&
                                              innovationAdministratorIndex ==
                                                item.id
                                            "
                                            depressed
                                            v-if="item.status == 0 && assignedInnovation.permission == 'write'"
                                          >
                                            Reject
                                            <v-icon right small>
                                              mdi-toggle-switch
                                            </v-icon>
                                          </v-btn>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card>
                          </div>
                          <div class="hidden-md-and-up">
                            <div>
                              <v-layout column>
                                <template
                                  v-for="(
                                    investment, index
                                  ) in investments"
                                >
                                  <div :key="index">
                                    <v-flex xs12 class="mb-2">
                                      <v-card elevation="0">
                                        <div class="pa-5">
                                          <v-layout column>
                                            <v-flex xs12>
                                              <v-layout row wrap>
                                                <v-flex xs11>
                                                  <div v-if="investment.individual_investor_investment == null">
                                                    {{ investment.investment_firm_investments[0].investment_firm.name }}
                                                  </div>
                                                  <div v-else>
                                                    {{ investment.individual_investor_investment.user.f_name }} {{ investment.individual_investor_investment.user.m_name }} {{ investment.individual_investor_investment.user.l_name }}
                                                  </div>  
                                                </v-flex>
                                                <v-flex xs1>
                                                  
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-divider class="mt-2"></v-divider>
                                            <v-flex xs12 class="mt-1">
                                              <v-layout column>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Investor Type</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      <div v-if="investment.individual_investor_investment == null">
                                                        {{ investment.investment_firm_investments[0].investment_firm.investor_type.name }}
                                                      </div>
                                                      <div v-else>
                                                        {{ investment.individual_investor_investment.user.individual_investor.investor_type.name }}
                                                      </div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Amount</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      Kshs {{ intToString(investment.amount) }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Funding Type</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{ investment.funding_type.name }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Offer Date</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{ investment.created_at | moment("Do MMMM YYYY") }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Status</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      <div v-if="(investment.status==0)">
                                                          Pending
                                                      </div>
                                                      <div v-if="(investment.status==1)">
                                                          Accepted
                                                      </div>
                                                      <div v-if="(investment.status==2)">
                                                          Rejected
                                                      </div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>

                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="primary text-none white--text mt-3"
                                                          block
                                                          @click="
                                                            showInvestmentFirm(
                                                              investment.investment_firm_investments[0].investment_firm
                                                            )
                                                          "
                                                          v-if="investment.individual_investor_investment == null"
                                                          >View
                                                          <v-icon right>
                                                            mdi-eye
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>
                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="green darken-1 text-none white--text mt-1"
                                                          block
                                                          :loading="
                                                            activateLoading &&
                                                            innovationAdministratorIndex ==
                                                            investment.id
                                                          "
                                                          @click="
                                                            acceptInvestment(
                                                              investment
                                                            )
                                                          "
                                                          v-if="investment.status == 0 && assignedInnovation.permission == 'write'"
                                                          >Accept
                                                          <v-icon right>
                                                            mdi-toggle-switch-off-outline
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>
                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="red text-none white--text mt-1"
                                                          block
                                                          :loading="
                                                            deactivateLoading &&
                                                            innovationAdministratorIndex ==
                                                            investment.id
                                                          "
                                                          @click="
                                                            rejectInvestment(
                                                              investment
                                                            )
                                                          "
                                                          v-if="investment.status == 0 && assignedInnovation.permission == 'write'"
                                                          >Reject
                                                          <v-icon right>
                                                            mdi-toggle-switch
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>
                                                    
                                                  </v-layout>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                          </v-layout>
                                        </div>
                                      </v-card>
                                    </v-flex>
                                  </div>
                                </template>
                              </v-layout>
                            </div>
                          </div>
                        </div>
                      </v-flex>
                    </v-card>
                    <v-layout column class="mt-5">
                      <v-flex xs12>
                        <div align="left">
                          <v-pagination
                            v-if="length != 0"
                            :length="investmentLength"
                            total-visible="10"
                            v-model="
                              investmentPagination.current_page
                            "
                            @input="changeInvestmentPage()"
                            circle
                          >
                          </v-pagination>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div
                          v-if="investmentLength != 0"
                          align="center"
                          class="mt-5"
                        >
                          <b class="primary--text">Total: </b
                          >{{
                            investmentPagination.total
                              | formatNumber
                          }}
                        </div>
                      </v-flex>
                    </v-layout>
                </div>
                


                <div v-if="activeTab == 'institutionAffiliations'">
                  <div v-if="institutionAffiliationView == 'default'">
                    <v-card elevation="0" class="mt-5">
                      <v-flex xs12 class="mt-10 mb-2">
                        <div class="mx-5">
                          <v-layout row wrap>
                            <v-flex xs12 md4> </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <v-text-field
                                  class="text_field header"
                                  outlined
                                  dense
                                  label="Search"
                                  append-icon="mdi-undo-variant"
                                  @click:append="
                                    resetInstitutionAffiliationSearch()
                                  "
                                  v-on:keyup.enter="
                                    institutionAffiliationSearch
                                  "
                                  v-model="institutionAffiliationSearchTerm"
                                  @input="enableInstitutionAffiliationSearch()"
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md2>
                              <div class="pa-1">
                                <v-btn
                                  class="secondary primary--text text-none"
                                  depressed
                                  block
                                  @click="institutionAffiliationSearch"
                                  :loading="institutionAffiliationSearchLoader"
                                  :disabled="
                                    institutionAffiliationSearchButtonDisabled
                                  "
                                >
                                  Search
                                  <v-icon right>mdi-magnify</v-icon>
                                </v-btn>
                              </div>
                            </v-flex>
                            <v-flex xs12 md2>
                              <div class="pa-1">
                                <v-btn
                                  block
                                  depressed
                                  class="primary text-none"
                                  @click="
                                    changeInstitutionAffiliationView('create')
                                  "
                                  v-if="$can('innovation_institution_affiliation_create') && assignedInnovation.permission == 'write'"
                                >
                                  Add Affiliation
                                  <v-icon right>mdi-plus-circle-outline</v-icon>
                                </v-btn>
                              </div>
                            </v-flex>
                          </v-layout>
                        </div>
                      </v-flex>
                      <v-divider class="mt-9 mx-5"></v-divider>
                      <v-flex xs12 class="mt-5">
                        <div v-if="institutionAffiliations.length == 0">
                          <v-card elevation="0">
                            <v-layout row wrap>
                              <v-flex xs12 md1>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      <v-icon large class="primary--text">
                                        mdi-alert-circle-outline
                                      </v-icon>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                              <v-flex xs12 md11>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      No Institution Affiliations Found
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </div>
                        <div v-else>
                          <div class="hidden-sm-and-down">
                            <v-card elevation="0" class="pa-2">
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead class="header">
                                    <tr class="tableHeader">
                                      <th class="text-left text--text">Logo</th>
                                      <th class="text-left text--text">Name</th>
                                      <th class="text-left text--text">County</th>
                                      <th class="text-left text--text">Status</th>
                                      <th class="text-right text--text">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="item in institutionAffiliations"
                                      :key="item.id"
                                    >
                                      <td>
                                        <div class="my-2">
                                          <v-avatar size="48" v-if="item.institution.logo==null">
                                            <v-img
                                              max-height="150"
                                              
                                              src="img/innovation.jpg"
                                            >
                                            </v-img>
                                          </v-avatar>
                                          <v-avatar size="48" v-else>
                                            <v-img
                                                max-height="150"
                                                :src="path+'/storage/institution_pictures/'+item.institution.logo"
                                              >
                                              </v-img>
                                          </v-avatar>
                                        </div>
                                      </td>
                                      <td>
                                        {{ item.institution.name }}
                                      </td>
                                      <td>
                                        {{
                                          item.institution.county
                                            .name
                                        }}
                                      </td>
                                      <td>
                                        <div v-if="item.status == 1">
                                          Approved
                                        </div>
                                        <div v-if="item.status == 0">Pending Approval</div>
                                      </td>
                                      <td>
                                        <div align="right">
                                          <v-btn
                                            icon
                                            @click="
                                              showInstitutionAffiliation(
                                                item.institution
                                              )
                                            "
                                            class="button mr-1 universal--text"
                                          >
                                            <v-icon small> mdi-eye </v-icon>
                                          </v-btn>
                                          <v-btn
                                            v-if="
                                              $can(
                                                'innovation_institution_affiliation_archive'
                                              ) && assignedInnovation.permission == 'write'
                                            "
                                            icon
                                            class="button mr-1 error--text"
                                            @click="
                                              deleteInstitutionAffiliation(item)
                                            "
                                            :loading="
                                              loading &&
                                              institutionAffiliationIndex ==
                                                item.id
                                            "
                                          >
                                            <v-icon small> mdi-delete </v-icon>
                                          </v-btn>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card>
                          </div>
                          <div class="hidden-md-and-up">
                            <div>
                              <v-layout column>
                                <template
                                  v-for="(
                                    institution, index
                                  ) in institutionAffiliations"
                                >
                                  <div :key="index">
                                    <v-flex xs12 class="mb-2">
                                      <v-card elevation="0">
                                        <div class="pa-2 mt-3">
                                          <v-layout column>
                                            <v-flex xs12>
                                              <v-layout row wrap>
                                                <v-flex xs11>
                                                  <div
                                                    class="title text--text"
                                                  >
                                                    <b>{{
                                                      institution
                                                        .institution
                                                        .name
                                                    }}</b>
                                                  </div>
                                                </v-flex>
                                                <v-flex xs1>
                                                  <v-btn
                                                    depressed
                                                    class="red--text"
                                                    :loading="
                                                      loading &&
                                                      institutionAffiliationIndex ==
                                                        institution.id
                                                    "
                                                    icon
                                                    @click="
                                                      deleteInstitutionAffiliation(
                                                        institution
                                                      )
                                                    "
                                                    v-if="
                                                      $can(
                                                        'innovation_institution_affiliation_archive'
                                                      ) && assignedInnovation.permission == 'write'
                                                    "
                                                  >
                                                    <v-icon>
                                                      mdi-delete
                                                    </v-icon>
                                                  </v-btn>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-divider class="mt-2"></v-divider>
                                            <v-flex xs12 class="mt-1">
                                              <v-layout column>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>County</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{
                                                        institution
                                                          .institution
                                                          .county.name
                                                      }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Status</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      <div
                                                        v-if="
                                                          institution
                                                            .institution
                                                            .status == 1
                                                        "
                                                      >
                                                        Approved
                                                      </div>
                                                      <div v-else>
                                                        Pending Approval
                                                      </div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>

                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="primary text-none white--text mt-3"
                                                          block
                                                          @click="
                                                            showInstitutionAffiliation(
                                                              institution.institution
                                                            )
                                                          "
                                                          >View
                                                          <v-icon right>
                                                            mdi-eye
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                          </v-layout>
                                        </div>
                                      </v-card>
                                    </v-flex>
                                  </div>
                                </template>
                              </v-layout>
                            </div>
                          </div>
                        </div>
                      </v-flex>
                    </v-card>
                    <v-layout column class="mt-5">
                      <v-flex xs12>
                        <div align="left">
                          <v-pagination
                            v-if="length != 0"
                            :length="institutionAffiliationLength"
                            total-visible="10"
                            v-model="
                              institutionAffiliationPagination.current_page
                            "
                            @input="changeInstitutionAffiliationPage()"
                            circle
                          >
                          </v-pagination>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div
                          v-if="institutionAffiliationLength != 0"
                          align="center"
                          class="mt-5"
                        >
                          <b class="primary--text">Total: </b
                          >{{
                            institutionAffiliationPagination.total
                              | formatNumber
                          }}
                        </div>
                      </v-flex>
                    </v-layout>
                  </div>
                  <div v-if="institutionAffiliationView == 'create'">
                    <v-flex class="mt-5">
                      <v-card-title class="tableHeader text--text">
                        <v-spacer></v-spacer>
                        <div v-if="newInstitutionAffiliation.id == null">
                          Add Institution Affiliation
                        </div>
                        <div v-else>Edit Institution Affiliation</div>
                        <v-spacer></v-spacer>
                        <v-btn
                          icon
                          @click="changeInstitutionAffiliationView('default')"
                        >
                          <v-icon class="text--text"> mdi-close </v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <v-form
                          v-model="valid"
                          ref="institutionAffiliationForm"
                          lazy-validation
                        >
                          <v-layout row wrap class="mt-5">
                            <v-flex xs12 md4>
                              <div class="pa-3">
                                <div>
                                  Institution<span class="error--text">*</span>
                                </div>
                                <v-select
                                  :items="formData.institutions"
                                  v-model="newInstitutionAffiliation.institution_id"
                                  class="text_field background my-2"
                                  item-value="id"
                                  item-text="name"
                                  outlined
                                  dense
                                  :rules="inputRules"
                                ></v-select>
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          depressed
                          class="primary text-none mt-3"
                          @click="saveAffliliatedInstitution"
                          :disabled="!valid"
                          :loading="loading"
                        >
                          Save
                          <v-icon right> mdi-content-save </v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-flex>
                  </div>
                </div>
                <div v-if="activeTab == 'organisationAffiliations'">
                  <div v-if="organisationAffiliationView == 'default'">
                    <v-card elevation="0" class="mt-5">
                      <v-flex xs12 class="mt-10 mb-2">
                        <div class="mx-5">
                          <v-layout row wrap>
                            <v-flex xs12 md4> </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <v-text-field
                                  class="text_field header"
                                  outlined
                                  dense
                                  label="Search"
                                  append-icon="mdi-undo-variant"
                                  @click:append="
                                    resetOrganisationAffiliationSearch()
                                  "
                                  v-on:keyup.enter="
                                    organisationAffiliationSearch
                                  "
                                  v-model="organisationAffiliationSearchTerm"
                                  @input="enableOrganisationAffiliationSearch()"
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md2>
                              <div class="pa-1">
                                <v-btn
                                  class="secondary primary--text text-none"
                                  depressed
                                  block
                                  @click="organisationAffiliationSearch"
                                  :loading="organisationAffiliationSearchLoader"
                                  :disabled="
                                    organisationAffiliationSearchButtonDisabled
                                  "
                                >
                                  Search
                                  <v-icon right>mdi-magnify</v-icon>
                                </v-btn>
                              </div>
                            </v-flex>
                            <v-flex xs12 md2>
                              <div class="pa-1">
                                <v-btn
                                  block
                                  depressed
                                  class="primary text-none"
                                  @click="
                                    changeOrganisationAffiliationView('create')
                                  "
                                  v-if="$can('innovation_organisation_affiliation_create') && assignedInnovation.permission == 'write'"
                                >
                                  Add Affiliation
                                  <v-icon right>mdi-plus-circle-outline</v-icon>
                                </v-btn>
                              </div>
                            </v-flex>
                          </v-layout>
                        </div>
                      </v-flex>
                      <v-divider class="mt-9 mx-5"></v-divider>
                      <v-flex xs12 class="mt-5">
                        <div v-if="organisationAffiliations.length == 0">
                          <v-card elevation="0">
                            <v-layout row wrap>
                              <v-flex xs12 md1>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      <v-icon large class="text--text">
                                        mdi-alert-circle-outline
                                      </v-icon>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                              <v-flex xs12 md11>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      No Organisation Affiliations Found
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </div>
                        <div v-else>
                          <div class="hidden-sm-and-down">
                            <v-card elevation="0" class="pa-2">
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead class="header">
                                    <tr class="tableHeader">
                                      <th class="text-left text--text">Logo</th>
                                      <th class="text-left text--text">Name</th>
                                      <th class="text-left text--text">County</th>
                                      <th class="text-left text--text">Status</th>
                                      <th class="text-right text--text">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="item in organisationAffiliations"
                                      :key="item.id"
                                    >
                                      <td>
                                        <div class="my-2">
                                          <v-img
                                            v-if="
                                              item.organisation
                                                .logo == null
                                            "
                                            src="img/badge.png"
                                            max-width="40"
                                          ></v-img>
                                          <v-avatar
                                            size="48"
                                            v-if="
                                              item.organisation
                                                .logo != null
                                            "
                                          >
                                            <v-img
                                              contain
                                              :src="
                                                path +
                                                '/school_emblems/' +
                                                item.organisation.logo
                                              "
                                            ></v-img>
                                          </v-avatar>
                                        </div>
                                      </td>
                                      <td>
                                        {{ item.organisation.name }}
                                      </td>
                                      <td>
                                        {{
                                          item.organisation.county
                                            .name
                                        }}
                                      </td>
                                      <td>
                                        <div v-if="item.status == 1">
                                          Approved
                                        </div>
                                        <div v-else>Pending Approval</div>
                                      </td>
                                      <td>
                                        <div align="right">
                                          <v-btn
                                            icon
                                            @click="
                                              showOrganisationAffiliation(
                                                item.organisation
                                              )
                                            "
                                            class="button mr-1 universal--text"
                                          >
                                            <v-icon small> mdi-eye </v-icon>
                                          </v-btn>
                                          <v-btn
                                            v-if="
                                              $can(
                                                'innovation_organisation_affiliation_archive'
                                              ) && assignedInnovation.permission == 'write'
                                            "
                                            icon
                                            class="button mr-1 error--text"
                                            @click="
                                              deleteOrganisationAffiliation(item)
                                            "
                                            :loading="
                                              loading &&
                                              organisationAffiliationIndex ==
                                                item.id
                                            "
                                          >
                                            <v-icon small> mdi-delete </v-icon>
                                          </v-btn>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card>
                          </div>
                          <div class="hidden-md-and-up">
                            <div>
                              <v-layout column>
                                <template
                                  v-for="(
                                    organisation, index
                                  ) in organisationAffiliations"
                                >
                                  <div :key="index">
                                    <v-flex xs12 class="mb-2">
                                      <v-card elevation="0">
                                        <div class="pa-2 mt-2">
                                          <v-layout column>
                                            <v-flex xs12>
                                              <v-layout row wrap>
                                                <v-flex xs11>
                                                  <div
                                                    class="title text--text"
                                                  >
                                                    <b>{{
                                                      organisation
                                                        .organisation
                                                        .name
                                                    }}</b>
                                                  </div>
                                                </v-flex>
                                                <v-flex xs1>
                                                  <v-btn
                                                    depressed
                                                    class="red--text"
                                                    :loading="
                                                      loading &&
                                                      organisationAffiliationIndex ==
                                                        organisation.id
                                                    "
                                                    icon
                                                    @click="
                                                      deleteOrganisationAffiliation(
                                                        organisation
                                                      )
                                                    "
                                                    v-if="
                                                      $can(
                                                        'innovation_organisation_affiliation_archive'
                                                      ) && assignedInnovation.permission == 'write'
                                                    "
                                                  >
                                                    <v-icon>
                                                      mdi-delete
                                                    </v-icon>
                                                  </v-btn>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-divider class="mt-2"></v-divider>
                                            <v-flex xs12 class="mt-1">
                                              <v-layout column>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>County</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{
                                                        organisation
                                                          .organisation
                                                          .county.name
                                                      }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                               
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Status</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      <div
                                                        v-if="
                                                          organisation
                                                            .organisation
                                                            .status == 1
                                                        "
                                                      >
                                                        Approved
                                                      </div>
                                                      <div v-else>
                                                        Pending Approval
                                                      </div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>

                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="primary text-none white--text mt-3"
                                                          block
                                                          @click="
                                                            showOrganisationAffiliation(
                                                              organisation.organisation
                                                            )
                                                          "
                                                          >View
                                                          <v-icon right>
                                                            mdi-eye
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                          </v-layout>
                                        </div>
                                      </v-card>
                                    </v-flex>
                                  </div>
                                </template>
                              </v-layout>
                            </div>
                          </div>
                        </div>
                      </v-flex>
                    </v-card>
                    <v-layout column class="mt-5">
                      <v-flex xs12>
                        <div align="left">
                          <v-pagination
                            v-if="length != 0"
                            :length="organisationAffiliationLength"
                            total-visible="10"
                            v-model="
                              organisationAffiliationPagination.current_page
                            "
                            @input="changeOrganisationAffiliationPage()"
                            circle
                          >
                          </v-pagination>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div
                          v-if="organisationAffiliationLength != 0"
                          align="center"
                          class="mt-5"
                        >
                          <b class="primary--text">Total: </b
                          >{{
                            organisationAffiliationPagination.total
                              | formatNumber
                          }}
                        </div>
                      </v-flex>
                    </v-layout>
                  </div>
                  <div v-if="organisationAffiliationView == 'create'">
                    <v-flex class="mt-5">
                      <v-card-title class="tableHeader text--text">
                        <v-spacer></v-spacer>
                        <div v-if="newOrganisationAffiliation.id == null">
                          Add Organisation Affiliation
                        </div>
                        <div v-else>Edit Organisation Affiliation</div>
                        <v-spacer></v-spacer>
                        <v-btn
                          icon
                          @click="changeOrganisationAffiliationView('default')"
                        >
                          <v-icon class="text--text"> mdi-close </v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <v-form
                          v-model="valid"
                          ref="organisationAffiliationForm"
                          lazy-validation
                        >
                          <v-layout row wrap class="mt-5">
                            <v-flex xs12 md4>
                              <div class="pa-3">
                                <div>
                                  Organisation<span class="error--text">*</span>
                                </div>
                                <v-select
                                  :items="formData.organisations"
                                  v-model="newOrganisationAffiliation.organisation_id"
                                  class="text_field background my-2"
                                  item-value="id"
                                  item-text="name"
                                  outlined
                                  dense
                                  :rules="inputRules"
                                ></v-select>
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          depressed
                          class="primary text-none mt-3"
                          @click="saveOrganisationInstitution"
                          :disabled="!valid"
                          :loading="loading"
                        >
                          Save
                          <v-icon right> mdi-content-save </v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-flex>
                  </div>
                </div>
                <div v-if="activeTab == 'innovationAdministrators'">
                  <div v-if="innovationAdministratorView == 'default'">
                    <v-card elevation="0" class="mt-5">
                      <v-flex xs12 class="mt-10 mb-2">
                        <div class="mx-5">
                          <v-layout row wrap>
                            <v-flex xs12 md4> </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <v-text-field
                                  class="text_field header"
                                  outlined
                                  dense
                                  label="Search"
                                  append-icon="mdi-undo-variant"
                                  @click:append="
                                    resetInnovationAdministratorSearch()
                                  "
                                  v-on:keyup.enter="
                                    innovationAdministratorSearch
                                  "
                                  v-model="innovationAdministratorSearchTerm"
                                  @input="
                                    enableInnovationAdministratorSearch()
                                  "
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md2>
                              <div class="pa-1">
                                <v-btn
                                  class="secondary primary--text text-none"
                                  depressed
                                  block
                                  @click="innovationAdministratorSearch"
                                  :loading="
                                    innovationAdministratorSearchLoader
                                  "
                                  :disabled="
                                    innovationAdministratorSearchButtonDisabled
                                  "
                                >
                                  Search
                                  <v-icon right>mdi-magnify</v-icon>
                                </v-btn>
                              </div>
                            </v-flex>
                            <v-flex xs12 md2>
                              <div class="pa-1">
                                <v-btn
                                  block
                                  depressed
                                  class="primary text-none"
                                  @click="
                                    changeInnovationAdministratorView('create')
                                  "
                                  v-if="
                                    $can('innovation_administrator_create') &&
                                    assignedInnovation.permission == 'write'
                                  "
                                >
                                  Add Member
                                  <v-icon right>mdi-plus-circle-outline</v-icon>
                                </v-btn>
                              </div>
                            </v-flex>
                          </v-layout>
                        </div>
                      </v-flex>
                      <v-divider class="mt-9 mx-5"></v-divider>
                      <v-flex xs12 class="mt-5">
                        <div v-if="innovationAdministrators.length == 0">
                          <v-card elevation="0">
                            <v-layout row wrap>
                              <v-flex xs12 md1>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      <v-icon large class="primary--text">
                                        mdi-alert-circle-outline
                                      </v-icon>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                              <v-flex xs12 md11>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      No Institution Administrators Found
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </div>
                        <div v-else>
                          <div class="hidden-sm-and-down">
                            <v-card elevation="0" class="pa-2">
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead class="header">
                                    <tr class="tableHeader">
                                      <th class="text-left text--text">Pic</th>
                                      <th class="text-left text--text">Name</th>
                                      <th class="text-left text--text">Role</th>
                                      <th class="text-left text--text">Email Address</th>
                                      <th class="text-left text--text">Phone #</th>
                                      <th class="text-left text--text">Code</th>
                                      <th class="text-left text--text">Permission</th>
                                      <th class="text-left text--text">Status</th>                                      
                                      <th class="text-right text--text">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="item in innovationAdministrators"
                                      :key="item.id"
                                    >
                                      <td>
                                        <div class="my-2">
                                          <v-avatar size="48" v-if="item.display_img==null">
                                            <v-img
                                              max-height="150"
                                              
                                              src="img/innovation.jpg"
                                            >
                                            </v-img>
                                          </v-avatar>
                                          <v-avatar size="48" v-else>
                                            <v-img
                                                max-height="150"
                                                :src="path+'/storage/profile_pics/'+item.display_img"
                                              >
                                              </v-img>
                                          </v-avatar>
                                        </div>
                                      </td>
                                      <td>
                                        {{ item.user.f_name }}
                                        {{ item.user.m_name }}
                                        {{ item.user.l_name }}
                                      </td>
                                      <td>{{ item.role }}</td>
                                      <td>{{ item.user.email }}</td>
                                      <td>{{ item.user.phone }}</td>
                                      <td>{{ item.user.code }}</td>
                                      <td>
                                        <div v-if="(item.write==0)">
                                            View Only
                                        </div>
                                        <div v-if="(item.write==1)">
                                            View & Update
                                        </div>
                                      </td>
                                      <td>
                                        <div v-if="item.status == 0">Inactive</div>
                                        <div v-if="item.status == 1">Active</div>
                                        <div v-if="item.status == 2">Request Sent</div>
                                        <div v-if="item.status == 3">Request Rejected</div>
                                      </td>
                                      <td>
                                        <div align="right">
                                          <v-btn
                                            icon
                                            @click="
                                              showInnovationAdministrator(
                                                item.user
                                              )
                                            "
                                            class="button mr-1 universal--text"
                                          >
                                            <v-icon small> mdi-eye </v-icon>
                                          </v-btn>
                                          <v-btn
                                            v-if="
                                              $can(
                                                'innovation_administrator_archive'
                                              ) && assignedInnovation.permission == 'write'
                                            "
                                            icon
                                            class="button mr-1 error--text"
                                            @click="
                                              deleteInnovationAdministrator(
                                                item
                                              )
                                            "
                                            :loading="
                                              loading &&
                                              innovationAdministratorIndex ==
                                                item.id
                                            "
                                          >
                                            <v-icon small> mdi-delete </v-icon>
                                          </v-btn>
                                          <v-tooltip
                                            top
                                            v-if="
                                              $can(
                                                'innovation_administrator_create'
                                              ) && item.write == 0 && assignedInnovation.permission == 'write'
                                            "
                                          >
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <v-btn
                                                icon
                                                class="button mr-1 teal--text"
                                                @click="
                                                  upgradeInnovationAdministrator(
                                                    item
                                                  )
                                                "
                                                :loading="
                                                  upgradeLoading &&
                                                  innovationAdministratorIndex ==
                                                    item.id
                                                "
                                                v-bind="attrs"
                                                v-on="on"
                                              >
                                                <v-icon small>
                                                  mdi-arrow-up-thick
                                                </v-icon>
                                              </v-btn>
                                            </template>
                                            <span>Upgrade Permission</span>
                                          </v-tooltip>
                                          <v-tooltip
                                            top
                                            v-if="
                                              $can(
                                                'innovation_administrator_create'
                                              ) && item.write == 1 && assignedInnovation.permission == 'write'
                                            "
                                          >
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <v-btn
                                                icon
                                                class="button mr-1 deep-orange--text"
                                                @click="
                                                  downgradeInnovationAdministrator(
                                                    item
                                                  )
                                                "
                                                :loading="
                                                  downgradeLoading &&
                                                  innovationAdministratorIndex ==
                                                    item.id
                                                "
                                                v-bind="attrs"
                                                v-on="on"
                                              >
                                                <v-icon small>
                                                  mdi-arrow-down-thick
                                                </v-icon>
                                              </v-btn>
                                            </template>
                                            <span>Downgrade Permission</span>
                                          </v-tooltip>
                                          <v-tooltip
                                            top
                                            v-if="
                                              $can(
                                                'innovation_administrator_create'
                                              ) && item.status == 0 && assignedInnovation.permission == 'write'
                                            "
                                          >
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <v-btn
                                                icon
                                                class="button mr-1 red--text"
                                                @click="
                                                  activateInnovationAdministrator(
                                                    item
                                                  )
                                                "
                                                :loading="
                                                  activateLoading &&
                                                  innovationAdministratorIndex ==
                                                    item.id
                                                "
                                                v-bind="attrs"
                                                v-on="on"
                                              >
                                                <v-icon small>
                                                  mdi-toggle-switch-off-outline
                                                </v-icon>
                                              </v-btn>
                                            </template>
                                            <span>Enable Administrator</span>
                                          </v-tooltip>
                                          <v-tooltip
                                            top
                                            v-if="
                                              $can(
                                                'innovation_administrator_deactivate'
                                              ) && item.status == 1 && assignedInnovation.permission == 'write'
                                            "
                                          >
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <v-btn
                                                icon
                                                class="button mr-1 green--text"
                                                @click="
                                                  deactivateInnovationAdministrator(
                                                    item
                                                  )
                                                "
                                                :loading="
                                                  deactivateLoading &&
                                                  innovationAdministratorIndex ==
                                                    item.id
                                                "
                                                v-bind="attrs"
                                                v-on="on"
                                              >
                                                <v-icon small>
                                                  mdi-toggle-switch-off-outline
                                                </v-icon>
                                              </v-btn>
                                            </template>
                                            <span>Deactivate Administrator</span>
                                          </v-tooltip>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card>
                          </div>
                          <div class="hidden-md-and-up">
                            <div>
                              <v-layout column>
                                <template
                                  v-for="(
                                    administrator, index
                                  ) in innovationAdministrators"
                                >
                                  <div :key="index">
                                    <v-flex xs12 class="mb-2">
                                      <v-card elevation="0">
                                        <div class="pa-2 mt-3">
                                          <v-layout column>
                                            <v-flex xs12>
                                              <v-layout row wrap>
                                                <v-flex xs11>
                                                  <div
                                                    class="title text--text"
                                                  >
                                                    <b
                                                      >{{
                                                        administrator.user
                                                          .f_name
                                                      }}
                                                      {{
                                                        administrator.user
                                                          .m_name
                                                      }}
                                                      {{
                                                        administrator.user
                                                          .l_name
                                                      }}</b
                                                    >
                                                  </div>
                                                </v-flex>
                                                <v-flex xs1>
                                                  <v-btn
                                                    depressed
                                                    class="red--text"
                                                    :loading="
                                                      loading &&
                                                      innovationAdministratorIndex ==
                                                        administrator.id
                                                    "
                                                    icon
                                                    @click="
                                                      deleteInnovationAdministrator(
                                                        administrator
                                                      )
                                                    "
                                                    v-if="
                                                      $can(
                                                        'innovation_administrator_archive'
                                                      ) && assignedInnovation.permission == 'write'
                                                    "
                                                  >
                                                    <v-icon>
                                                      mdi-delete
                                                    </v-icon>
                                                  </v-btn>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-divider class="mt-2"></v-divider>
                                            <v-flex xs12 class="mt-1">
                                              <v-layout column>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Role</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{
                                                        administrator.role
                                                      }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Email Address</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{
                                                        administrator.user.email
                                                      }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Phone #</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{
                                                        administrator.user.phone
                                                      }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Code</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{
                                                        administrator.user.code
                                                      }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Permission</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      <div v-if="(administrator.write==0)">
                                                          View Only
                                                      </div>
                                                      <div v-if="(administrator.write==1)">
                                                          View & Update
                                                      </div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Status</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      <div v-if="administrator.status == 0">Inactive</div>
                                                      <div v-if="administrator.status == 1">Active</div>
                                                      <div v-if="administrator.status == 2">Request Sent</div>
                                                      <div v-if="administrator.status == 3">Request Rejected</div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                

                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="primary text-none white--text mt-3"
                                                          block
                                                          @click="
                                                            showInnovationAdministrator(
                                                              administrator.user
                                                            )
                                                          "
                                                          >View
                                                          <v-icon right>
                                                            mdi-eye
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>

                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="teal text-none white--text mt-1"
                                                          block
                                                          :loading="
                                                            upgradeLoading &&
                                                            innovationAdministratorIndex ==
                                                            administrator.id
                                                          "
                                                          @click="
                                                            upgradeInnovationAdministrator(
                                                              administrator
                                                            )
                                                          "
                                                          v-if="
                                                            $can(
                                                              'innovation_administrator_create'
                                                            ) && administrator.write == 0 && assignedInnovation.permission == 'write'
                                                          "
                                                          >Upgrade Permission
                                                          <v-icon right>
                                                            mdi-arrow-up-thick
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>

                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="deep-orange text-none white--text mt-1"
                                                          block
                                                          :loading="
                                                            downgradeLoading &&
                                                            innovationAdministratorIndex ==
                                                            administrator.id
                                                          "
                                                          @click="
                                                            downgradeInnovationAdministrator(
                                                              administrator
                                                            )
                                                          "
                                                          v-if="
                                                            $can(
                                                              'innovation_administrator_create'
                                                            ) && administrator.write == 1 && assignedInnovation.permission == 'write'
                                                          "
                                                          >Downgrade Permission
                                                          <v-icon right>
                                                            mdi-arrow-down-thick
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>




                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="green text-none white--text mt-1"
                                                          block
                                                          :loading="
                                                            activateLoading &&
                                                            innovationAdministratorIndex ==
                                                              administrator.id
                                                          "
                                                          @click="
                                                            activateInnovationAdministrator(
                                                              administrator
                                                            )
                                                          "
                                                          v-if="
                                                            $can(
                                                              'innovation_administrator_activate'
                                                            ) && administrator.status == 0 && assignedInnovation.permission == 'write'
                                                          "
                                                          >Enable Administrator
                                                          <v-icon right>
                                                            mdi-toggle-switch-off-outline
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>
                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="red darken-1 text-none white--text mt-1"
                                                          block
                                                          :loading="
                                                            deactivateLoading &&
                                                            innovationAdministratorIndex ==
                                                              administrator.id
                                                          "
                                                          @click="
                                                            deactivateInnovationAdministrator(
                                                              administrator
                                                            )
                                                          "
                                                          v-if="
                                                            $can(
                                                              'innovation_administrator_deactivate'
                                                            ) && administrator.status == 1 && assignedInnovation.permission == 'write'
                                                          "
                                                          >Deactivate Administrator
                                                          <v-icon right>
                                                            mdi-toggle-switch-off-outline
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                          </v-layout>
                                        </div>
                                      </v-card>
                                    </v-flex>
                                  </div>
                                </template>
                              </v-layout>
                            </div>
                          </div>
                        </div>
                      </v-flex>
                    </v-card>
                    <v-layout column class="mt-5">
                      <v-flex xs12>
                        <div align="left">
                          <v-pagination
                            v-if="length != 0"
                            :length="institutionAdministratorLength"
                            total-visible="10"
                            v-model="
                              innovationAdministratorPagination.current_page
                            "
                            @input="changeInnovationAdministratorPage()"
                            circle
                          >
                          </v-pagination>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div
                          v-if="institutionAdministratorLength != 0"
                          align="center"
                          class="mt-5"
                        >
                          <b class="primary--text">Total: </b
                          >{{
                            innovationAdministratorPagination.total
                              | formatNumber
                          }}
                        </div>
                      </v-flex>
                    </v-layout>
                  </div>
                  <div v-if="innovationAdministratorView == 'create'">
                    <v-flex class="mt-5">
                      <v-card-title class="tableHeader text--text">
                        <v-img
                          v-if="newAdministrator.id != null"
                          src="img/badge.png"
                          max-width="40"
                        ></v-img>
                        <v-spacer></v-spacer>
                        <div v-if="newAdministrator.id == null">
                          Add Innovation Administrator
                        </div>
                        <div v-else>Edit Innovation Administrator</div>
                        <v-spacer></v-spacer>
                        <v-btn
                          icon
                          @click="changeInnovationAdministratorView('default')"
                        >
                          <v-icon class="text--text"> mdi-close </v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <v-form
                          v-model="valid"
                          ref="innovationAdministratorForm"
                          lazy-validation
                        >
                          <v-layout row wrap class="mt-5">
                            <v-flex xs12 md4>
                              <div class="pa-3">
                                <div>
                                  User Code<span class="error--text">*</span> (Check User Profile)
                                </div>
                                <v-autocomplete
                                  v-model="newAdministrator.user_id"
                                  :loading="loading"
                                  :items="items"
                                  :search-input.sync="searchUsers"
                                  item-value="id"
                                  item-text="code"
                                  cache-items
                                  class="text_field background my-2"
                                  flat
                                  hide-no-data
                                  hide-details
                                  outlined
                                  dense
                                  :rules="inputRules"
                                >
                                  <template v-slot:selection="{ item }">
                                    {{ getText(item) }}
                                  </template>
                                  <template v-slot:item="{ item }">
                                    {{ getText(item) }}
                                  </template>
                                </v-autocomplete>
                              </div>
                            </v-flex>
                            <v-flex xs12 md4>
                                <div class="pa-3">
                                  <div>
                                    Permission Level
                                  </div>
                                  <v-select
                                    :items="accessLevels"
                                    v-model="newAdministrator.write"
                                    class="text_field background my-2"
                                    item-value="value"
                                    item-text="name"
                                    outlined
                                    dense
                                  ></v-select>
                                </div>
                              </v-flex>
                              <v-flex xs12 md4>
                                <div class="pa-3">
                                  <div>
                                    Role
                                  </div>
                                  <v-text-field
                                    class="text_field background my-2"
                                    outlined
                                    dense
                                    v-model="newAdministrator.role"
                                  ></v-text-field>
                                </div>
                              </v-flex>
                          </v-layout>
                        </v-form>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          depressed
                          class="primary text-none mt-3"
                          @click="saveInnovationAdministrator"
                          :disabled="!valid"
                          :loading="loading"
                        >
                          Save
                          <v-icon right> mdi-content-save </v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-flex>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-layout>
    </v-container>
    <logData v-if="logDataModel" v-model="logDataModel" />
  </div>
</template>
<style>
.ck-editor__editable {
  min-height: 200px;
}
</style>
<script>
import Vue from "vue";
import apiCall from "@/utils/api";
import { mapGetters, mapState, mapActions } from "vuex";
import logData from "@/views/accessControl/logData";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadAdapter from '@/uploadAdapter.js';

Vue.use(CKEditor);

export default {
  components: {
    logData,
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,

      innovationStatus: 'all',
      innovationOrderBy: 'createdDown',
      sortBy: 'dateUp',

      editor: ClassicEditor,
      editorConfig: {
          extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
              return new uploadAdapter(loader);
          } }],
          language: 'en',
      },

      importDialog: false,
      activeTab: "details",
      view: "default",
      institutionAffiliationView: "default",
      organisationAffiliationView: "default",
      innovationAdministratorView: "default",
      contactView: "default",
      innovationStatisticView: "default",
      loading: false,
      statisticLoading: false,
      institutionAffiliationLoader: false,
      organisationAffiliationLoader: false,
      innovationAdministratorLoader: false,
      investmentLoader: false,
      upgradeLoading: false,
      downgradeLoading: false,
      activateLoading: false,
      deactivateLoading: false,
      verifyLoading: false,
      unverifyLoading: false,

      valid: true,
      message: "",
      color: "",
      delete: false,
      activate: false,
      confirm: false,
      tab: null,
      innovationTab: null,
      institutionAffiliationIndex: null,
      organisationAffiliationIndex: null,
      innovationAdministratorIndex: null,
      innovationIndex: null,
      snackbar: false,
      inputRules: [(v) => !!v || "Input is required"],

      emailRules: [
        (v) =>
          !v ||
          /^[a-zA-Z0-9\.!#$%&'*+/=?^_~-]+@([a-zA-Z0-9]+\.)+[a-zA-Z]{2,3}$/.test(
            v
          ) ||
          "E-mail must be valid",
        (v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
      ],
      websiteRules: [
        (v) =>
          !v ||
          /^[a-zA-Z0-9\.!#:$%&'*+/=?^_~-]+[a-zA-Z]{2,3}$/.test(v) ||
          "URL must be valid",
        (v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
      ],

      accessLevels: [
        {
          value: false,
          name: 'Can Only View Innovation Details'
        },
        {
          value: true,
          name: 'Can View & Edit Innovation Details'
        }
      ],

      items: [],
      years: [],
      searchUsers: null,

      searchTerm: null,
      institutionAffiliationSearchTerm: null,
      organisationAffiliationSearchTerm: null,
      innovationAdministratorSearchTerm: null,
      investmentSearchTerm: null,
      searchLoader: false,
      searchButtonDisabled: false,
      institutionAffiliationSearchButtonDisabled: false,
      organisationAffiliationSearchButtonDisabled: false,
      innovationAdministratorSearchButtonDisabled: false,
      investmentSearchButtonDisabled: false,

      filteredInnovations: false,
      filteredInstitutionAffiliations: false,
      filteredOrganisationAffiliations: false,
      filteredInnovationAdministrators: false,
      filteredInvestments: false,
      newInnovation: {
        id: null,
        title: null,
        tagline: null,
        year: null,
        ip_protection_type_id: null,
        stage_id: null,
        county_id: null,
        rating_public: null,
        interests: [],
        sectors: [],
        sdgs: [],
        institutions: [],
        organisations: [],
        description: null,
      },
      newSocial: {
        id: null,
        innovation_id: null,
        email: null,
        phone: null,
        web_url: null,
        fb_url: null,
        insta_url: null,
        twitter_url: null,
        linkedin_url: null,
        visible: null,
      },
      newInnovationStatistic: {
        id: null,
        user_class_id: null,
        customer_class_id: null,
        employee_class_id: null,
        revenue_class_id: null,
        investment_class_id: null,
      },
      newInstitutionAffiliation: {
        id: null,
        institution_id: null,
        innovation_id: null,
      },
      newOrganisationAffiliation: {
        id: null,
        organisation_id: null,
        innovation_id: null,
      },
      newAdministrator: {
        id: null,
        innovation_id: null,
        user_id: null,
        write: null,
        role: null
      },
      newInvestment: {
        id: null
      },

      assignedInnovation: null,
      formData: null,

      logoImageDialog: false,
      imageDialog: false,
      imageLoading: false,
      fileRules: [(v) => !!v || "Kindly selecta a File"],
      imageFile: null,
      imageValid: true,

      searchTerm: null,
      searchLoader: false,
      institutionAffiliationSearchLoader: false,
      organisationAffiliationSearchLoader: false,
      innovationAdministratorSearchLoader: false,
      investmentSearchLoader: false,
      searchButtonDisabled: false,

      filteredInnovations: [],
      institutionAffiliations: [],
      organisationAffiliations: [],
      innovationAdministrators: [],
      investments: [],
      institutionAffiliationPagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },
      organisationAffiliationPagination: {
        search: ' ',
        current_page: 1,
        per_page: 0,
          total: 0,
          visible: 10
      },
      innovationAdministratorPagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },
      investmentPagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },
      logData: {
        message: null,
        model: null,
      },
    };
  },
  watch: {
    searchUsers(val) {
      val && val !== this.newAdministrator.user_id && this.findUser(val);
    },
  },
  created() {
    if (this.$can("innovation_view")) {
      this.startInnovationLoader();
      this.getInnovations();
      this.getFormData();
      this.populateYears();
    }
  },
  methods: {
    ...mapActions([
      "fetchInnovations",
      "startInnovationLoader",
      "stopInnovationLoader",
      "filterInnovations",

      "filterLogData",
      "openLogDataModel",

      "assignInnovationData",
      "changeInnovationApprovalCardState"
    ]),
    changeInnovationStatus(status){
      this.innovationStatus = status
      this.changePage()
    },
    changeInnovationSortOrder(order){
      this.innovationOrderBy = order
      this.changePage()
    },

    getInnovations(){
      this.startInnovationLoader();
      apiCall({
        url: "/api/innovation?page=" +
            this.innovationPagination.current_page + "&innovationOrderBy=" + this.innovationOrderBy +
            "&innovationStatus=" + this.innovationStatus,
        method: "GET",
      })
        .then((resp) => {
          this.filterInnovations(resp);
          this.stopInnovationLoader();
        })
        .catch((error) => {
          this.stopInnovationLoader();
          this.message = "An Error Occurred";
          this.color = "error";
          this.snackbar = true;
        });
    },


    intToString(value) {
      var suffixes = ["", "k", "M", "B", "T"];
      var suffixNum = Math.floor(("" + value).length / 3);
      var shortValue = parseFloat(
        (suffixNum != 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(2)
      );
      if (shortValue % 1 != 0) {
        shortValue = shortValue.toFixed(1);
      }
      return shortValue + suffixes[suffixNum];
    },
    sendInnovationMessage(message){
      this.assignInnovationData(message)
      this.changeInnovationApprovalCardState()
    },
    populateYears() {
      console.log("here");
      var max = new Date().getFullYear();
      var min = max - 100;
      var years = [];

      for (var i = max; i >= min; i--) {
        years.push(i);
      }
      this.years = years;
    },
    findUser(v) {
      this.loading = true;
      // Simulated ajax query
      setTimeout(() => {
        apiCall({
          url: "/api/user?type=search&search=" + this.searchUsers,
          method: "GET",
        })
          .then((resp) => {
            this.items = resp.data;
          })
          .catch((error) => {
            this.loading = false;
          });

        this.loading = false;
      }, 3000);
    },
    showInvestmentFirm(investmentFirm){
      this.$router.push("/investors/" + investmentFirm.slug);
    },
    showInstitutionAffiliation(institution) {
      this.$router.push("/institutions/" + institution.slug);
    },
    showOrganisationAffiliation(organisation) {
      this.$router.push("/organisations/" + organisation.slug);
    },
    showInnovationAdministrator(user) {
      this.$router.push("/user/" + user.code);
    },
    showInstitutionUser(user) {
      this.$router.push("/user/" + user.code);
    },
    showInstitutionInnovation(innovation) {
      this.$router.push("/innovation/" + innovation.slug);
    },
    enableSearch() {
      this.searchButtonDisabled = false;
    },
    enableInstitutionAffiliationSearch() {
      this.institutionAffiliationSearchButtonDisabled = false;
    },
    enableOrganisationAffiliationSearch() {
      this.organisationAffiliationSearchButtonDisabled = false;
    },
    enableInnovationAdministratorSearch() {
      this.innovationAdministratorSearchButtonDisabled = false;
    },
    enableInvestmentSearch() {
      this.investmentSearchButtonDisabled = false;
    },
    resetSearch() {
      this.searchTerm = null;
      this.filteredInnovations = true;
      this.startInnovationLoader();
      this.fetchInnovations(1);
    },

    resetInstitutionAffiliationSearch() {
      this.institutionAffiliationSearchTerm = null;
      this.filteredInstitutionAffiliations = false;
      this.institutionAffiliationLoader = false;
      this.changeInstitutionAffiliationPage();
    },

    resetOrganisationAffiliationSearch() {
      this.organisationAffiliationSearchTerm = null;
      this.filteredOrganisationAffiliations = false;
      this.organisationAffiliationLoader = false;
      this.changeOrganisationAffiliationPage();
    },

    resetInnovationAdministratorSearch() {
      this.innovationAdministratorSearchTerm = null;
      this.filteredInnovationAdministrators = false;
      this.innovationAdministratorLoader = false;
      this.changeInnovationAdministratorPage();
    },

    resetInvestmentSearch() {
      this.investmentSearchTerm = null;
      this.filteredInvestments = false;
      this.investmentLoader = false;
      this.changeInvestmentPage();
    },

    changeInstitutionAffiliationPage() {
      this.institutionAffiliationLoader = true;
      if (this.filteredInstitutionAffiliations == true) {
        this.institutionAffiliationSearchButtonDisabled = false;
        apiCall({
          url:
            "/api/innovation-institution?type=innovation&innovation_id=" +
            this.assignedInnovation.id +
            "&page=" +
            this.institutionAffiliationPagination.current_page +
            "&type=search&search=" +
            this.institutionAffiliationSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.institutionAffiliationLoader = false;

            this.institutionAffiliations = resp.data;
            this.institutionAffiliationPagination.current_page =
              resp.current_page;
            this.institutionAffiliationPagination.total = resp.total;
            this.institutionAffiliationPagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.institutionAffiliationLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        apiCall({
          url:
            "/api/innovation-institution?type=innovation&innovation_id=" +
            this.assignedInnovation.id +
            "&page=" +
            this.institutionAffiliationPagination.current_page,
          method: "GET",
        })
          .then((resp) => {
            this.institutionAffiliationLoader = false;

            this.institutionAffiliations = resp.data;
            this.institutionAffiliationPagination.current_page =
              resp.current_page;
            this.institutionAffiliationPagination.total = resp.total;
            this.institutionAffiliationPagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.institutionAffiliationLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },

    changeOrganisationAffiliationPage() {
      this.organisationAffiliationLoader = true;
      if (this.filteredOrganisationAffiliations == true) {
        this.organisationAffiliationSearchButtonDisabled = false;
        apiCall({
          url:
            "/api/innovation-organisation?type=innovation&innovation_id=" +
            this.assignedInnovation.id +
            "&page=" +
            this.organisationAffiliationPagination.current_page +
            "&type=search&search=" +
            this.organisationAffiliationSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.organisationAffiliationLoader = false;

            this.organisationAffiliations = resp.data;
            this.organisationAffiliationPagination.current_page =
              resp.current_page;
            this.organisationAffiliationPagination.total = resp.total;
            this.organisationAffiliationPagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.organisationAffiliationLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        apiCall({
          url:
            "/api/innovation-organisation?type=innovation&innovation_id=" +
            this.assignedInnovation.id +
            "&page=" +
            this.organisationAffiliationPagination.current_page,
          method: "GET",
        })
          .then((resp) => {
            this.organisationAffiliationLoader = false;

            this.organisationAffiliations = resp.data;
            this.organisationAffiliationPagination.current_page =
              resp.current_page;
            this.organisationAffiliationPagination.total = resp.total;
            this.organisationAffiliationPagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.organisationAffiliationLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },

    changeInnovationAdministratorPage() {
      this.innovationAdministratorLoader = true;
      if (this.filteredInnovationAdministrators == true) {
        this.innovationAdministratorSearchButtonDisabled = false;
        apiCall({
          url:
            "/api/innovation-administrator?innovation_id=" +
            this.assignedInnovation.id +
            "&page=" +
            this.innovationAdministratorPagination.current_page +
            "&type=search&search=" +
            this.innovationAdministratorSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.innovationAdministratorLoader = false;

            this.innovationAdministrators = resp.data;
            this.innovationAdministratorPagination.current_page =
              resp.current_page;
            this.innovationAdministratorPagination.total = resp.total;
            this.innovationAdministratorPagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.innovationAdministratorLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        apiCall({
          url:
            "/api/innovation-administrator?innovation_id=" +
            this.assignedInnovation.id +
            "&page=" +
            this.innovationAdministratorPagination.current_page,
          method: "GET",
        })
          .then((resp) => {
            this.innovationAdministratorLoader = false;

            this.innovationAdministrators = resp.data;
            this.innovationAdministratorPagination.current_page =
              resp.current_page;
            this.innovationAdministratorPagination.total = resp.total;
            this.innovationAdministratorPagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.innovationAdministratorLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    changeInvestmentPage(){
      this.investmentLoader = true;
      if (this.filteredInvestments == true) {
        this.investmentSearchButtonDisabled = false;
        apiCall({
          url:
            "/api/investment?type=innaovationAdmin&innovation_id=" +
            this.assignedInnovation.id +
            "&page=" +
            this.investmentPagination.current_page +
            "&typeB=search&search=" +
            this.investmentSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.investmentLoader = false;

            this.investments = resp.data;
            this.investmentPagination.current_page =
              resp.current_page;
            this.investmentPagination.total = resp.total;
            this.investmentPagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.investmentLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        apiCall({
          url:
            "/api/investment?type=innaovationAdmin&innovation_id=" +
            this.assignedInnovation.id +
            "&page=" +
            this.investmentPagination.current_page,
          method: "GET",
        })
          .then((resp) => {
            this.investmentLoader = false;

            this.investments = resp.data;
            this.investmentPagination.current_page =
              resp.current_page;
            this.investmentPagination.total = resp.total;
            this.investmentPagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.investmentLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    getText(item) {
      return (
        item.f_name + " " + item.m_name + " " + item.l_name + " #" + item.code
      );
    },
    showInnovation(innovation) {
      this.assignedInnovation = innovation;
      this.newSocial.innovation_id = innovation.id;

      this.newInnovation.rating_public = innovation.rating_public

      this.innovationAffiliationLoader = true;
      if (this.$can("innovation_institution_affiliation_view")) {
        this.changeInstitutionAffiliationPage();
      }
      if (this.$can("innovation_organisation_affiliation_view")) {
        this.changeOrganisationAffiliationPage();
      }
      if (this.$can("innovation_administrator_view")) {
        this.changeInnovationAdministratorPage();
      }

      this.changeInvestmentPage();

      this.changeView("show");
    },

    editInnovation(innovation) {
      this.newInnovation.id = innovation.id;
      this.newInnovation.title = innovation.name;
      this.newInnovation.tagline = innovation.brief;
      this.newInnovation.year = innovation.establishment_year;
      this.newInnovation.ip_protection_type_id =
        innovation.ip_protection_type_id;
      this.newInnovation.stage_id = innovation.stage_id;
      this.newInnovation.county_id = innovation.county_id;
      this.newInnovation.description = innovation.description;
      this.newInnovation.rating_public = innovation.rating_public;

      for (var i = 0; i < innovation.innovation_interests.length; i++) {
        this.newInnovation.interests.push(
          innovation.innovation_interests[i].interest_id
        );
      }
      for (var i = 0; i < innovation.innovation_sdgs.length; i++) {
        this.newInnovation.sdgs.push(innovation.innovation_sdgs[i].sdg_id);
      }
      for (var i = 0; i < innovation.innovation_sectors.length; i++) {
        this.newInnovation.sectors.push(
          innovation.innovation_sectors[i].sector_id
        );
      }

      this.changeView("create");
    },
    editContact(contact) {
      this.newSocial.id = contact.id;
      this.newSocial.contact_person = contact.contact_person;
      this.newSocial.email = contact.email;
      this.newSocial.phone = contact.phone;
      this.newSocial.web_url = contact.web_url;
      this.newSocial.fb_url = contact.fb_url;
      this.newSocial.insta_url = contact.insta_url;
      this.newSocial.twitter_url = contact.twitter_url;
      this.newSocial.linkedin_url = contact.linkedin_url;
      this.newSocial.visible = contact.visible;
      this.contactView = "create";
    },
    editInnovationStatistic(innovationStatistic) {
      this.newInnovationStatistic.id = innovationStatistic.id;
      this.newInnovationStatistic.user_class_id = innovationStatistic.user_class_id;
      this.newInnovationStatistic.customer_class_id = innovationStatistic.customer_class_id;
      this.newInnovationStatistic.employee_class_id = innovationStatistic.employee_class_id;
      this.newInnovationStatistic.revenue_class_id = innovationStatistic.revenue_class_id;
      this.newInnovationStatistic.investment_class_id = innovationStatistic.investment_class_id;
      this.innovationStatisticView = "create";
    },
    uploadLogoImage() {
      if (this.$refs.imageForm.validate()) {
        this.imageLoading = true;
        let formData = new FormData();

        // files

        formData.append("files", this.imageFile, this.imageFile.name);
        formData.append("id", this.assignedInnovation.id);

        apiCall({
          url: "/api/innovation?type=logoImage",
          data: formData,
          method: "POST",
        })
          .then((resp) => {
            this.snackbar = true;
            this.message = "Logo Uploaded Successfully";
            this.color = "success";
            this.fetchInnovations(this.innovationPagination.current_page);
            this.imageLoading = false;
            this.logoImageDialog = false;
            this.imageFile = null;
            this.assignedInnovation.logo = resp.logo;
            // this.changeView('default')
          })
          .catch((error) => {
            this.imageLoading = false;
          });
      }
    },
    uploadImage() {
      if (this.$refs.imageForm.validate()) {
        this.imageLoading = true;
        let formData = new FormData();

        // files

        formData.append("files", this.imageFile, this.imageFile.name);
        formData.append("id", this.assignedInnovation.id);

        apiCall({
          url: "/api/innovation?type=image",
          data: formData,
          method: "POST",
        })
          .then((resp) => {
            this.snackbar = true;
            this.message = "Feature Image Uploaded Successfully";
            this.color = "success";
            this.fetchInnovations(this.innovationPagination.current_page);
            this.imageLoading = false;
            this.imageDialog = false;
            this.assignedInnovation.featured_image = resp.featured_image;
            // this.changeView('default')
          })
          .catch((error) => {
            this.imageLoading = false;
          });
      }
    },
    updateRating(innovation){
      this.newInnovation.id = innovation.id
      this.newInnovation.title = innovation.name;
      this.newInnovation.tagline = innovation.brief;
      this.newInnovation.year = innovation.establishment_year;
      this.newInnovation.ip_protection_type_id =
        innovation.ip_protection_type_id;
      this.newInnovation.stage_id = innovation.stage_id;
      this.newInnovation.county_id = innovation.county_id;
      this.newInnovation.description = innovation.description;

      for (var i = 0; i < innovation.innovation_interests.length; i++) {
        this.newInnovation.interests.push(
          innovation.innovation_interests[i].interest_id
        );
      }
      for (var i = 0; i < innovation.innovation_sdgs.length; i++) {
        this.newInnovation.sdgs.push(innovation.innovation_sdgs[i].sdg_id);
      }
      for (var i = 0; i < innovation.innovation_sectors.length; i++) {
        this.newInnovation.sectors.push(
          innovation.innovation_sectors[i].sector_id
        );
      }

      this.loading = true;
      apiCall({
              url: "/api/innovation/" + this.newInnovation.id,
              data: this.newInnovation,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Rating Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.fetchInnovations(this.innovationPagination.current_page);
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.newInnovation.id != null) {
          confirm("Are you sure you want to update innovation?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/innovation/" + this.newInnovation.id,
              data: this.newInnovation,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Innovation Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeView("default");
                this.fetchInnovations(1);
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm("Are you sure you want to create innovation?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/innovation",
              data: this.newInnovation,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Innovation Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeView("default");
                this.fetchInnovations(this.innovationPagination.current_page);
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
                this.changeView("default");
                this.fetchInnovations(1);
              });
          }
        }
      }
    },
    saveContact() {
      if (this.$refs.contactForm.validate()) {
        if (this.newSocial.id != null) {
          confirm("Are you sure you want to update social information?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/innovation-social/" + this.newSocial.id,
              data: this.newSocial,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Contact Information Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.$nextTick(() => {
                  this.assignedInnovation.innovation_social = resp;
                });

                this.changeSocialView("default");

                this.fetchInnovations(1);
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm("Are you sure you want to create social information?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            this.newSocial.innovation_id = this.assignedInnovation.id
            apiCall({
              url: "/api/innovation-social",
              data: this.newSocial,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Contact Information Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeSocialView("default");
                this.assignedInnovation.innovation_social = resp;
                this.fetchInnovations(1);
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
                this.fetchInnovations(1);
              });
          }
        }
      }
    },
    saveInnovationStatistic() {
      if (this.$refs.innovationStatisticForm.validate()) {
        if (this.newInnovationStatistic.id != null) {
          confirm("Are you sure you want to update statistic information?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.statisticLoading = true;
            apiCall({
              url: "/api/innovation-statistic/" + this.newInnovationStatistic.id,
              data: this.newInnovationStatistic,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Statistic Information Updated Successfully";
                this.color = "orange";
                this.statisticLoading = false;
                this.snackbar = true;
                this.confirm = false;
                this.$nextTick(() => {
                  this.assignedInnovation.innovation_statistic = resp;
                });

                this.changeInnovationStatisticView("default");

                this.fetchInnovations(1);
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.statisticLoading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm("Are you sure you want to create statistic information?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.statisticLoading = true;
            this.newInnovationStatistic.innovation_id = this.assignedInnovation.id
            apiCall({
              url: "/api/innovation-statistic",
              data: this.newInnovationStatistic,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Statistic Information Added Successfully";
                this.color = "success";
                this.statisticLoading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeInnovationStatisticView("default");
                this.assignedInnovation.innovation_statistic = resp;
                this.fetchInnovations(1);
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.statisticLoading = false;
                this.snackbar = true;
                this.fetchInnovations(1);
              });
          }
        }
      }
    },
    saveAffliliatedInstitution() {
      if (this.$refs.institutionAffiliationForm.validate()) {
        confirm("Are you sure you want to create institution affiliation?") &&
          (this.confirm = true);
        if (this.confirm) {
          this.loading = true;
          this.newInstitutionAffiliation.innovation_id = this.assignedInnovation.id;
          apiCall({
            url: "/api/innovation-institution",
            data: this.newInstitutionAffiliation,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Institution Affiliation Added Successfully";
              this.color = "success";
              this.loading = false;
              this.snackbar = true;
              this.confirm = false;
              this.newInstitutionAffiliation.innovation_id = null;
              this.changeInstitutionAffiliationView("default");
              this.changeInstitutionAffiliationPage();
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.loading = false;
              this.snackbar = true;
              this.fetchInnovations(1);
            });
        }
      }
    },
    saveOrganisationInstitution() {
      if (this.$refs.organisationAffiliationForm.validate()) {
        confirm("Are you sure you want to create organisation affiliation?") &&
          (this.confirm = true);
        if (this.confirm) {
          this.loading = true;
          this.newOrganisationAffiliation.innovation_id = this.assignedInnovation.id;
          apiCall({
            url: "/api/innovation-organisation",
            data: this.newOrganisationAffiliation,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Organisation Affiliation Added Successfully";
              this.color = "success";
              this.loading = false;
              this.snackbar = true;
              this.confirm = false;
              this.newOrganisationAffiliation.innovation_id = null;
              this.changeOrganisationAffiliationView("default");
              this.changeOrganisationAffiliationPage();
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.loading = false;
              this.snackbar = true;
              this.fetchInnovations(1);
            });
        }
      }
    },
    saveInnovationAdministrator() {
      if (this.$refs.innovationAdministratorForm.validate()) {
        confirm("Are you sure you want to add innovation administrator?") &&
          (this.confirm = true);
        if (this.confirm) {
          this.loading = true;
          this.newAdministrator.innovation_id = this.assignedInnovation.id;
          apiCall({
            url: "/api/innovation-administrator",
            data: this.newAdministrator,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Innovation Administrator Added Successfully";
              this.color = "success";
              this.loading = false;
              this.snackbar = true;
              this.confirm = false;
              this.newAdministrator.user_id = null;
              this.changeInnovationAdministratorView("default");
              this.changeInnovationAdministratorPage();
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.loading = false;
              this.snackbar = true;
            });
        }
      }
    },

    search() {
      if (this.searchTerm == null) {
        this.searchButtonDisabled = true;
      } else {
        this.searchLoader = true;
        this.searchButtonDisabled = false;
        this.startInnovationLoader();
        apiCall({
          url: "/api/innovation?type=adminSearch&search=" + this.searchTerm + "&innovationOrderBy=" + this.innovationOrderBy +
            "&innovationStatus=" + this.innovationStatus,
          method: "GET",
        })
          .then((resp) => {
            this.filterInnovations(resp);
            this.searchLoader = false;
            this.stopInnovationLoader();
            this.filteredInnovations = true;
          })
          .catch((error) => {
            console.log(error);
            this.searchLoader = false;
            this.stopInnovationLoader();
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    institutionAffiliationSearch() {
      if (this.institutionAffiliationSearchTerm == null) {
        this.institutionAffiliationSearchButtonDisabled = true;
      } else {
        this.institutionAffiliationSearchLoader = true;
        this.institutionAffiliationSearchButtonDisabled = false;
        this.startInnovationLoader();
        apiCall({
          url:
          "/api/innovation-institution?type=innovation&innovation_id=" +
            this.assignedInnovation.id +
            "&page=1&typeB=search&search=" +
            this.institutionAffiliationSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.institutionAffiliations = resp.data;
            this.institutionAffiliationPagination.current_page =
              resp.current_page;
            this.institutionAffiliationPagination.total = resp.total;
            this.institutionAffiliationPagination.per_page = resp.per_page;

            this.institutionAffiliationSearchLoader = false;
            this.filteredInstitutionAffiliations = true;
          })
          .catch((error) => {
            this.institutionAffiliationSearchLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            console.log(error);
          });
      }
    },
    organisationAffiliationSearch() {
      if (this.organisationAffiliationSearchTerm == null) {
        this.organisationAffiliationSearchButtonDisabled = true;
      } else {
        this.organisationAffiliationSearchLoader = true;
        this.organisationAffiliationSearchButtonDisabled = false;
        this.startInnovationLoader();
        apiCall({
          url:
          "/api/innovation-organisation?type=innovation&innovation_id=" +
            this.assignedInnovation.id +
            "&page=1&typeB=search&search=" +
            this.organisationAffiliationSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.organisationAffiliations = resp.data;
            this.organisationAffiliationPagination.current_page =
              resp.current_page;
            this.organisationAffiliationPagination.total = resp.total;
            this.organisationAffiliationPagination.per_page = resp.per_page;

            this.organisationAffiliationSearchLoader = false;
            this.filteredOrganisationAffiliations = true;
          })
          .catch((error) => {
            this.organisationAffiliationSearchLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            console.log(error);
          });
      }
    },
    innovationAdministratorSearch() {
      if (this.innovationAdministratorSearchTerm == null) {
        this.innovationAdministratorSearchButtonDisabled = true;
      } else {
        this.innovationAdministratorSearchLoader = true;
        this.innovationAdministratorSearchButtonDisabled = false;
        this.startInnovationLoader();
        apiCall({
          url:
            "/api/innovation-administrator?type=search&search=" +
            this.innovationAdministratorSearchTerm +
            "&innovation_id=" +
            this.assignedInnovation.id,
          method: "GET",
        })
          .then((resp) => {
            this.innovationAdministrators = resp.data;
            this.innovationAdministratorPagination.current_page =
              resp.current_page;
            this.innovationAdministratorPagination.total = resp.total;
            this.innovationAdministratorPagination.per_page = resp.per_page;

            this.innovationAdministratorSearchLoader = false;
            this.filteredInnovationAdministrators = true;
          })
          .catch((error) => {
            this.innovationAdministratorSearchLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            console.log(error);
          });
      }
    },
    investmentSearch() {
      if (this.investmentSearchTerm == null) {
        this.investmentSearchButtonDisabled = true;
      } else {
        this.investmentSearchLoader = true;
        this.investmentSearchButtonDisabled = false;
        this.startInnovationLoader();
        apiCall({
          url:
            "/api/investment?type=innaovationAdmin&innovation_id=" +
            this.assignedInnovation.id +
            "&page=" +
            this.investmentPagination.current_page +
            "&typeB=search&search=" +
            this.investmentSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.investments = resp.data;
            this.investmentPagination.current_page =
              resp.current_page;
            this.investmentPagination.total = resp.total;
            this.investmentPagination.per_page = resp.per_page;

            this.investmentSearchLoader = false;
            this.filteredInnovationAdministrators = true;
          })
          .catch((error) => {
            this.investmentSearchLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            console.log(error);
          });
      }
    },
    verifyInnovation(item) {
      confirm("Are You Sure You Want to Verify Innovation?") &&
        (this.activate = true);
      if (this.activate) {
        this.verifyLoading = true;
        this.innovationIndex = item.id;
        this.newInnovation.id = item.id;
        apiCall({
          url: "/api/innovation?type=verify",
          data: this.newInnovation,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Innovation Verified Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.verifyLoading = false;
            this.activate = false;
            this.innovationIndex = null;
            this.changePage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.verifyLoading = false;
            this.activate = false;
            this.InstitutionIndex = null;
            console.log(error);
          });
      }
    },
    unverifyInnovation(item) {
      confirm("Are You Sure You Want to Unverify Innovation?") &&
        (this.activate = true);
      if (this.activate) {
        this.unverifyLoading = true;
        this.innovationIndex = item.id;
        this.newInnovation.id = item.id;
        apiCall({
          url: "/api/innovation?type=unverify",
          data: this.newInnovation,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Innovation UnVerified Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.unverifyLoading = false;
            this.activate = false;
            this.innovationIndex = null;
            this.changePage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.unverifyLoading = false;
            this.activate = false;
            this.InstitutionIndex = null;
            console.log(error);
          });
      }
    },
    activateInnovation(item) {
      confirm("Are You Sure You Want to Activate Innovation?") &&
        (this.activate = true);
      if (this.activate) {
        this.activateLoading = true;
        this.innovationIndex = item.id;
        this.newInnovation.id = item.id;
        apiCall({
          url: "/api/innovation?type=activate",
          data: this.newInnovation,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Innovation Approved Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.innovationIndex = null;

            if(this.assignedInnovation != null){
              this.assignedInnovation.status = 1
            }

            this.changePage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.InstitutionIndex = null;
            console.log(error);
          });
      }
    },
    deactivateInnovation(item) {
      confirm("Are You Sure You Want to Deactivate Innovation?") &&
        (this.activate = true);
      if (this.activate) {
        this.deactivateLoading = true;
        this.innovationIndex = item.id;
        this.newInnovation.id = item.id;
        apiCall({
          url: "/api/innovation?type=deactivate",
          data: this.newInnovation,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Innovation Deactivated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.innovationIndex = null;

            if(this.assignedInnovation != null){
              this.assignedInnovation.status = 0
            }

            this.changePage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.innovationIndex = null;
            console.log(error);
          });
      }
    },
    upgradeInnovationAdministrator(item) {
      confirm("Are You Sure You Want to Upgrade Institution Administrator?") &&
        (this.activate = true);
      if (this.activate) {
        this.upgradeLoading = true;
        this.innovationAdministratorIndex = item.id;
        this.newAdministrator.id = item.id;
        apiCall({
          url: "/api/innovation-administrator?type=upgrade",
          data: this.newAdministrator,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Institution Administrator Upgraded Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.upgradeLoading = false;
            this.activate = false;
            this.innovationAdministratorIndex = null;
            this.changeInnovationAdministratorPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.upgradeLoading = false;
            this.activate = false;
            this.innovationAdministratorIndex = null;
            console.log(error);
          });
      }
    },
    downgradeInnovationAdministrator(item) {
      confirm("Are You Sure You Want to Downgrade Institution Administrator?") &&
        (this.activate = true);
      if (this.activate) {
        this.downgradeLoading = true;
        this.innovationAdministratorIndex = item.id;
        this.newAdministrator.id = item.id;
        apiCall({
          url: "/api/innovation-administrator?type=downgrade",
          data: this.newAdministrator,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Institution Administrator Downgraded Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.downgradeLoading = false;
            this.activate = false;
            this.innovationAdministratorIndex = null;
            this.changeInnovationAdministratorPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.downgradeLoading = false;
            this.activate = false;
            this.innovationAdministratorIndex = null;
            console.log(error);
          });
      }
    },
    acceptInvestment(item) {
      confirm("Are You Sure You Want to Accept this Offer?") &&
        (this.activate = true);
      if (this.activate) {
        this.activateLoading = true;
        this.innovationAdministratorIndex = item.id;
        this.newInvestment.id = item.id;
        apiCall({
          url: "/api/investment?type=accept",
          data: this.newInvestment,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Investment Offer Accepted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.innovationAdministratorIndex = null;
            this.changeInvestmentPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.innovationAdministratorIndex = null;
            console.log(error);
          });
      }
    },
    rejectInvestment(item) {
      confirm(
        "Are You Sure You Want to Reject this Offer?"
      ) && (this.activate = true);
      if (this.activate) {
        this.deactivateLoading = true;
        this.innovationAdministratorIndex = item.id;
        this.newInvestment.id = item.id;
        apiCall({
          url: "/api/investment?type=reject",
          data: this.newInvestment,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Institution Administrator Deactivated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.innovationAdministratorIndex = null;
            this.changeInvestmentPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.innovationAdministratorIndex = null;
            console.log(error);
          });
      }
    },

    activateInnovationAdministrator(item) {
      confirm("Are You Sure You Want to Activate Institution Administrator?") &&
        (this.activate = true);
      if (this.activate) {
        this.activateLoading = true;
        this.innovationAdministratorIndex = item.id;
        this.newAdministrator.id = item.id;
        apiCall({
          url: "/api/innovation-administrator?type=activate",
          data: this.newAdministrator,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Institution Administrator Activated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.innovationAdministratorIndex = null;
            this.changeInnovationAdministratorPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.innovationAdministratorIndex = null;
            console.log(error);
          });
      }
    },
    deactivateInnovationAdministrator(item) {
      confirm(
        "Are You Sure You Want to Deactivate Institution Administrator?"
      ) && (this.activate = true);
      if (this.activate) {
        this.deactivateLoading = true;
        this.innovationAdministratorIndex = item.id;
        this.newAdministrator.id = item.id;
        apiCall({
          url: "/api/innovation-administrator?type=deactivate",
          data: this.newAdministrator,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Institution Administrator Deactivated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.innovationAdministratorIndex = null;
            this.changeInnovationAdministratorPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.innovationAdministratorIndex = null;
            console.log(error);
          });
      }
    },
    deactivateInstitutionInnovation(item) {
      confirm("Are You Sure You Want to Deactivate Institution Affiliation?") &&
        (this.activate = true);
      if (this.activate) {
        this.deactivateLoading = true;
        this.institutionInnovationIndex = item.id;
        this.newInnovation.id = item.id;
        apiCall({
          url: "/api/innovation-institution?type=activate",
          data: this.newInnovation,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Innovation Affiliation Deactivated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.institutionInnovationIndex = null;
            this.changeInstitutionInnovationPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.institutionInnovationIndex = null;
            console.log(error);
          });
      }
    },
    changeView(view) {
      if (view == "default") {
        this.newInnovation.id = null;
        this.newInnovation.title = null;
        this.newInnovation.tagline = null;
        this.newInnovation.year = null;
        this.newInnovation.ip_protection_type_id = null;
        this.newInnovation.stage_id = null;
        this.newInnovation.county_id = null;
        this.newInnovation.description = null;

        this.newInnovation.interests = [];
        this.newInnovation.sectors = [];
        this.newInnovation.sdgs = [];
        this.newInnovation.institutions = [];
        this.newInnovation.organisations = [];


      } else if (view == "create") {
        this.getFormData();
      } else if (view == "show") {
      }
      this.view = view;
    },

    changeSocialView(view) {
      if (view == "default") {
        this.newSocial.id = null;
        this.newSocial.innovation_id = null;
        this.newSocial.email = null;
        this.newSocial.phone = null;
        this.newSocial.web_url = null;
        this.newSocial.fb_url = null;
        this.newSocial.insta_url = null;
        this.newSocial.twitter_url = null;
        this.newSocial.linkedin_url = null;
        this.newSocial.visible = null;
      } else if (view == "create") {
        //this.getFormData();
      } else if (view == "show") {
      }
      this.contactView = view;
    },
    changeInnovationStatisticView(view) {
      if (view == "default") {
        this.newInnovationStatistic.id = null;
        this.newInnovationStatistic.user_class_id = null;
        this.newInnovationStatistic.customer_class_id = null;
        this.newInnovationStatistic.employee_class_id = null;
        this.newInnovationStatistic.revenue_class_id = null;
        this.newInnovationStatistic.investment_class_id = null;
      } else if (view == "create") {
        //this.getFormData();
      } else if (view == "show") {
      }
      this.innovationStatisticView = view;
    },
    changeInstitutionAffiliationView(view) {
      if (view == "default") {
        this.newInstitutionAffiliation.id = null;
        this.newInstitutionAffiliation.institution_id = null;
        this.newInstitutionAffiliation.innovation_id = null;
      } else if (view == "create") {
      } else if (view == "show") {
      }
      this.institutionAffiliationView = view;
    },
    changeOrganisationAffiliationView(view) {
      if (view == "default") {
        this.newOrganisationAffiliation.id = null;
        this.newOrganisationAffiliation.organisation_id = null;
        this.newOrganisationAffiliation.innovation_id = null;
      } else if (view == "create") {
      } else if (view == "show") {
      }
      this.organisationAffiliationView = view;
    },
    changeInnovationAdministratorView(view) {
      if (view == "default") {
        this.newAdministrator.id = null;
        this.newAdministrator.institution_id = null;
        this.newAdministrator.user_id = null;
        this.newAdministrator.role = null;
      } else if (view == "create") {
      } else if (view == "show") {
      }
      this.innovationAdministratorView = view;
    },
    changePage() {
      this.startInnovationLoader();
      if (this.filteredInnovations == true) {
        this.searchButtonDisabled = false;
        apiCall({
          url:
            "/api/innovation?page=" +
            this.innovationPagination.current_page +
            "&type=adminSearch&search=" +
            this.searchTerm + "&innovationOrderBy=" + this.innovationOrderBy +
            "&innovationStatus=" + this.innovationStatus,
          method: "GET",
        })
          .then((resp) => {
            this.filterInnovations(resp);
            this.stopInnovationLoader();
          })
          .catch((error) => {
            console.log(error);
            this.stopInnovationLoader();
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        this.getInnovations()
      }
    },
    deleteInnovation(item) {
      confirm("Are You Sure You Want to Delete Innovation") &&
        (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.innovationIndex = item.id;
        apiCall({ url: "/api/innovation/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "Innovation Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.innovationIndex = null;
            this.changeView('default')
            this.changePage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.innovationIndex = null;
            console.log(error);
          });
      }
    },
    deleteContact(item) {
      confirm("Are You Sure You Want to Delete Social Information?") &&
        (this.delete = true);
      if (this.delete) {
        this.loading = true;
        apiCall({
          url: "/api/innovation-social/" + item.id,
          method: "DELETE",
        })
          .then((resp) => {
            this.message = "Social Information Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.$nextTick(() => {
              this.assignedInnovation.innovation_social = null;
            });
            this.fetchInnovations(this.innovationPagination.current_page);
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.innovationIndex = null;
            console.log(error);
          });
      }
    },
    deleteInnovationStatistic(item) {
      confirm("Are You Sure You Want to Delete Statistic Information?") &&
        (this.delete = true);
      if (this.delete) {
        this.statisticLoading = true;
        apiCall({
          url: "/api/innovation-statistic/" + item.id,
          method: "DELETE",
        })
          .then((resp) => {
            this.message = "Statistic Information Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.statisticLoading = false;
            this.delete = false;
            this.$nextTick(() => {
              this.assignedInnovation.innovation_statistic = null;
            });
            this.fetchInnovations(this.innovationPagination.current_page);
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.statisticLoading = false;
            this.delete = false;
            this.innovationIndex = null;
            console.log(error);
          });
      }
    },
    deleteInstitutionAffiliation(item) {
      confirm("Are You Sure You Want to Remove Affiliation") &&
        (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.institutionAffiliationIndex = item.id;
        apiCall({
          url: "/api/innovation-institution/" + item.id,
          method: "DELETE",
        })
          .then((resp) => {
            this.message = "Institution Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.institutionAffiliationIndex = null;
            this.changeInstitutionAffiliationPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.institutionAffiliationIndex = null;
            console.log(error);
          });
      }
    },
    deleteOrganisationAffiliation(item) {
      confirm("Are You Sure You Want to Remove Affiliation") &&
        (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.organisationAffiliationIndex = item.id;
        apiCall({
          url: "/api/organisation-affiliation/" + item.id,
          method: "DELETE",
        })
          .then((resp) => {
            this.message = "Organisation Affiliation Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.organisationAffiliationIndex = null;
            this.changeOrganisationAffiliationPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.organisationAffiliationIndex = null;
            console.log(error);
          });
      }
    },
    deleteInnovationAdministrator(item) {
      confirm("Are You Sure You Want to Remove Administrator?") &&
        (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.innovationAdministratorIndex = item.id;
        apiCall({
          url: "/api/innovation-administrator/" + item.id,
          method: "DELETE",
        })
          .then((resp) => {
            this.message = "Administrator Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.innovationAdministratorIndex = null;
            this.changeInnovationAdministratorPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.innovationAdministratorIndex = null;
            console.log(error);
          });
      }
    },
    getFormData() {
      apiCall({
        url: "/api/innovation?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
        })
        .catch((error) => {
          this.message = "Error obtaining form data; Kindly try again.";
          this.loading = false;
          this.snackbar = true;
          this.alertType = "error";
        });
    },
    viewLog(data) {
      this.logData.message = data.id;
      this.logData.model = "App\\Models\\Innovation";
      this.filterLogData(this.logData);
      this.openLogDataModel();
    },
  },
  computed: {
    ...mapGetters(["innovations", "innovationPagination", "innovationLoader", "logDataModel"]),
    length: function () {
      return Math.ceil(
        this.innovationPagination.total / this.innovationPagination.per_page
      );
    },
    institutionAffiliationLength: function () {
      return Math.ceil(
        this.institutionAffiliationPagination.total /
          this.institutionAffiliationPagination.per_page
      );
    },
    organisationAffiliationLength: function () {
      return Math.ceil(
        this.organisationAffiliationPagination.total /
          this.organisationAffiliationPagination.per_page
      );
    },
    institutionAdministratorLength: function () {
      return Math.ceil(
        this.innovationAdministratorPagination.total /
          this.innovationAdministratorPagination.per_page
      );
    },
    investmentLength: function () {
      return Math.ceil(
        this.investmentPagination.total /
          this.investmentPagination.per_page
      );
    },
    dateToday() {
      var today = new Date();

      var timestamp =
        today.getFullYear() +
        "-" +
        this.months[today.getMonth()] +
        "-" +
        ("0" + today.getDate()).slice(-2);
      return timestamp;
    },
  },
};
</script>
