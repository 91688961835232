<template>
  <div class="adminInvestmentFirms" v-if="$can('investment_firm_view')">
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="imageDialog"
    >
      <v-card max-width="600">
        <v-toolbar flat>
          Upload Logo
          <v-spacer></v-spacer>
          <v-btn icon @click="imageDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form ref="imageForm" v-model="imageValid" lazy-validation>
          <v-layout column>
            <v-flex xs12>
              <v-file-input
                class="mx-2 mt-3"
                :loading="imageLoading"
                :rules="fileRules"
                filled
                dense
                show-size
                chips
                accept="image/*"
                v-model="imageFile"
                label="Select Image"
              ></v-file-input>
            </v-flex>
            <v-flex xs12>
              <div class="mx-3 mb-5">
                <v-btn
                  depressed
                  block
                  class="primary text-none"
                  @click="uploadImage"
                  :loading="imageLoading"
                  :disabled="!imageValid"
                >
                  Submit <v-icon right dark>mdi-upload</v-icon>
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="coverImageDialog"
    >
      <v-card max-width="600">
        <v-toolbar flat>
          Upload Cover Photo
          <v-spacer></v-spacer>
          <v-btn icon @click="coverImageDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form ref="imageForm" v-model="imageValid" lazy-validation>
          <v-layout column>
            <v-flex xs12>
              <v-file-input
                class="mx-2 mt-3"
                :loading="imageLoading"
                :rules="fileRules"
                filled
                dense
                show-size
                chips
                accept="image/*"
                v-model="imageFile"
                label="Select Image"
              ></v-file-input>
            </v-flex>
            <v-flex xs12>
              <div class="mx-3 mb-5">
                <v-btn
                  depressed
                  block
                  class="primary text-none"
                  @click="uploadCoverImage"
                  :loading="imageLoading"
                  :disabled="!imageValid"
                >
                  Submit <v-icon right dark>mdi-upload</v-icon>
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card>
    </v-dialog>
    <v-container>
      <v-layout column>
        <div v-if="view == 'default'">
          <v-card outlined class="mt-5">
            <v-progress-linear
              v-if="investmentFirmLoader"
              height="1"
              indeterminate
              color="primary"
            >
            </v-progress-linear>
            <v-flex xs12 class="mt-10 mb-2">
              <div class="mx-5">
                <v-layout row wrap>
                  <v-flex xs12 md4> <h1>Investment Firms</h1> </v-flex>
                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-text-field
                        class="text_field header"
                        outlined
                        dense
                        label="Search"
                        append-icon="mdi-undo-variant"
                        @click:append="resetSearch()"
                        v-on:keyup.enter="search"
                        v-model="searchTerm"
                        @input="enableSearch()"
                      ></v-text-field>
                    </div>
                  </v-flex>
                  <v-flex xs12 md2>
                    <div class="pa-1">
                      <v-btn
                        class="secondary primary--text text-none"
                        depressed
                        block
                        @click="search"
                        :loading="searchLoader"
                        :disabled="searchButtonDisabled"
                      >
                        Search
                        <v-icon right>mdi-magnify</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs12 md2>
                    <div class="pa-1">
                      <v-btn
                        block
                        depressed
                        class="primary text-none"
                        @click="changeView('create')"
                        v-if="$can('investment_firm_create')"
                      >
                        Add Firm
                        <v-icon right>mdi-plus-circle-outline</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
            <v-divider class="mt-9 mx-5"></v-divider>
            <v-flex xs12 class="mt-5">
              <div v-if="adminInvestmentFirms.length == 0">
                <v-card elevation="0">
                  <v-layout row wrap>
                    <v-flex xs12 md1>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center">
                            <v-icon large class="primary--text">
                              mdi-alert-circle-outline
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                    <v-flex xs12 md11>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center">
                            No Investment Firm Found
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                  </v-layout>
                </v-card>
              </div>
              <div v-else>
                <div class="hidden-sm-and-down">
                  <v-card elevation="0" class="pa-2">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead class="header">
                          <tr class="tableHeader">
                            <th class="text-left text--text">Logo</th>
                            <th class="text-left text--text">Name</th>
                            <th class="text-left text--text">County</th>
                            <th class="text-left text--text">Status</th>
                            <th class="text-left text--text">Date Created</th>
                            <th class="text-right text--text">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in adminInvestmentFirms"
                            :key="item.id"
                          >
                            <td>
                              <div class="my-2">
                                <v-avatar size="48" v-if="item.logo == null">
                                  <v-img
                                    max-height="150"
                                    src="img/logo_fav.png"
                                  >
                                  </v-img>
                                </v-avatar>
                                <v-avatar size="48" v-else>
                                  <v-img
                                    max-height="150"
                                    :src="
                                      path +
                                      '/storage/investment_firm_pictures/' +
                                      item.logo
                                    "
                                  >
                                  </v-img>
                                </v-avatar>
                              </div>
                            </td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.county.name }}</td>
                            <td>
                              <div v-if="item.status == 1">Active</div>
                              <div v-else>Inactive</div>
                            </td>
                            <td>
                              {{
                                item.created_at | moment("DD/MM/YYYY - hh:mm a")
                              }}
                            </td>
                            <td>
                              <div align="right">
                                <v-btn
                                  icon
                                  v-if="$can('investment_firm_view')"
                                  @click="showInvestmentFirm(item)"
                                  class="button mr-1 universal--text"
                                >
                                  <v-icon small> mdi-eye </v-icon>
                                </v-btn>
                                <v-btn
                                  v-if="
                                    $can('investment_firm_edit') &&
                                    item.permission == 'write'
                                  "
                                  icon
                                  class="button mr-1 success--text"
                                  @click="editInvestmentFirm(item)"
                                >
                                  <v-icon small> mdi-pencil </v-icon>
                                </v-btn>
                                <v-btn
                                  v-if="
                                    $can('investment_firm_archive') &&
                                    item.permission == 'write'
                                  "
                                  icon
                                  class="button mr-1 error--text"
                                  @click="deleteInvestmentFirm(item)"
                                  :loading="
                                    loading && investmentFirmIndex == item.id
                                  "
                                >
                                  <v-icon small> mdi-delete </v-icon>
                                </v-btn>
                                <v-tooltip
                                  top
                                  v-if="
                                    $can('investment_firm_activate') &&
                                    item.status == 0 &&
                                    item.permission == 'write'
                                  "
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      class="button mr-1 red--text"
                                      @click="activateInvestmentFirm(item)"
                                      :loading="
                                        activateLoading &&
                                        investmentFirmIndex == item.id
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small>
                                        mdi-toggle-switch-off-outline
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Activate Investment Firm</span>
                                </v-tooltip>
                                <v-tooltip
                                  top
                                  v-if="
                                    $can('investment_firm_deactivate') &&
                                    item.status == 1 &&
                                    item.permission == 'write'
                                  "
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      class="button mr-1 green--text"
                                      @click="deactivateInvestmentFirm(item)"
                                      :loading="
                                        deactivateLoading &&
                                        investmentFirmIndex == item.id
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small> mdi-toggle-switch </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Deactivate Investment Firm</span>
                                </v-tooltip>
                                <v-tooltip top v-if="$can('log_view')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      class="button mr-1 purple--text"
                                      @click="viewLog(item)"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small> mdi-script-text </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Log</span>
                                </v-tooltip>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </div>
                <div class="hidden-md-and-up">
                  <div>
                    <v-layout column>
                      <template
                        v-for="(adminInvestmentFirm, index) in adminInvestmentFirms"
                      >
                        <div :key="index">
                          <v-flex xs12 class="mb-2">
                            <v-card elevation="0">
                              <div class="pa-5">
                                <v-layout column>
                                  <v-flex xs12>
                                    <v-layout row wrap>
                                      <v-flex xs11>
                                        <div class="title text--text ml-3">
                                          <b>{{ adminInvestmentFirm.name }}</b>
                                        </div>
                                      </v-flex>
                                      <v-flex xs1>
                                        <v-btn
                                          depressed
                                          class="red--text"
                                          :loading="
                                            loading &&
                                            investmentFirmIndex ==
                                              adminInvestmentFirm.id
                                          "
                                          icon
                                          @click="
                                            deleteInvestmentFirm(adminInvestmentFirm)
                                          "
                                          v-if="$can('investment_firm_archive')"
                                        >
                                          <v-icon> mdi-delete </v-icon>
                                        </v-btn>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-divider class="mt-2"></v-divider>
                                  <v-flex xs12 class="mt-1">
                                    <v-layout column>
                                      <v-flex xs12 class="mt-1">
                                        <div>
                                          <b>County</b>
                                        </div>
                                        <div>
                                          {{ adminInvestmentFirm.county.name }}
                                        </div>
                                      </v-flex>
                                      <v-flex xs12 class="mt-1">
                                        <div>
                                          <b>Status</b>
                                        </div>
                                        <div>
                                          <div v-if="adminInvestmentFirm.status == 1">
                                            Active
                                          </div>
                                          <div v-else>Inactive</div>
                                        </div>
                                      </v-flex>
                                      <v-flex xs12 class="mt-1">
                                        <div>
                                          <b>Date Created</b>
                                        </div>
                                        <div>
                                          {{
                                            adminInvestmentFirm.created_at
                                              | moment("DD/MM/YYYY - hh:mm a")
                                          }}
                                        </div>
                                      </v-flex>

                                      <v-flex xs12 class="mt-5">
                                        <v-layout row wrap>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn
                                                depressed
                                                class="primary text-none white--text mt-3"
                                                block
                                                @click="
                                                  showInvestmentFirm(
                                                    adminInvestmentFirm
                                                  )
                                                "
                                                v-if="
                                                  $can('investment_firm_view')
                                                "
                                                >View
                                                <v-icon right> mdi-eye </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn
                                                depressed
                                                class="blue text-none white--text mt-1"
                                                block
                                                @click="
                                                  editInvestmentFirm(
                                                    adminInvestmentFirm
                                                  )
                                                "
                                                v-if="
                                                  $can('investment_firm_edit')
                                                "
                                                >Edit
                                                <v-icon right>
                                                  mdi-border-color
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn
                                                depressed
                                                class="green text-none white--text mt-1"
                                                block
                                                @click="
                                                  activateInvestmentFirm(
                                                    adminInvestmentFirm
                                                  )
                                                "
                                                v-if="
                                                  $can(
                                                    'investment_firm_activate'
                                                  ) && adminInvestmentFirm.status == 0
                                                "
                                                :loading="
                                                  activateLoading &&
                                                  investmentFirmIndex ==
                                                    adminInvestmentFirm.id
                                                "
                                                >Activate Investment Firm
                                                <v-icon right>
                                                  mdi-toggle-switch-off-outline
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn
                                                depressed
                                                class="red text-none white--text mt-1"
                                                block
                                                @click="
                                                  deactivateInvestmentFirm(
                                                    adminInvestmentFirm
                                                  )
                                                "
                                                v-if="
                                                  $can(
                                                    'investment_firm_deactivate'
                                                  ) && adminInvestmentFirm.status == 1
                                                "
                                                :loading="
                                                  deactivateLoading &&
                                                  investmentFirmIndex ==
                                                    adminInvestmentFirm.id
                                                "
                                                >Deactivate Investment Firm
                                                <v-icon right>
                                                  mdi-toggle-switch
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn
                                              v-if="$can('log_view')" 
                                              block
                                              depressed
                                              class="purple text-none white--text"
                                              @click="viewLog(event)"
                                              v-bind="attrs"
                                              v-on="on"
                                            > Log
                                              <v-icon small> mdi-script-text </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </v-card>
                          </v-flex>
                        </div>
                      </template>
                    </v-layout>
                  </div>
                </div>
              </div>
            </v-flex>
          </v-card>
          <v-layout column class="mt-5">
            <v-flex xs12>
              <div align="left">
                <v-pagination
                  v-if="length != 0"
                  :length="length"
                  total-visible="10"
                  v-model="adminInvestmentFirmPagination.current_page"
                  @input="changePage()"
                  circle
                >
                </v-pagination>
              </div>
            </v-flex>
            <v-flex xs12>
              <div v-if="length != 0" align="center" class="mt-5">
                <b class="primary--text">Total: </b
                >{{ adminInvestmentFirmPagination.total | formatNumber }}
              </div>
            </v-flex>
          </v-layout>
        </div>
        <div v-if="view == 'create'">
          <v-flex class="mt-5">
            <v-card outlined>
              <v-card-title class="tableHeader text--text">
                <v-img
                  v-if="newInvestmentFirm.id != null"
                  src="img/badge.png"
                  max-width="40"
                ></v-img>
                <v-spacer></v-spacer>
                <div v-if="newInvestmentFirm.id == null">
                  Add Investment Firm
                </div>
                <div v-else>Edit Investment Firm</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeView('default')">
                  <v-icon class="text--text"> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-layout row wrap class="mt-5">
                    <v-flex xs12 md4>
                      <div class="mx-3">
                        <div>Name<span class="error--text">*</span></div>
                        <v-text-field
                          class="text_field background"
                          outlined
                          dense
                          v-model="newInvestmentFirm.name"
                          :rules="inputRules"
                        ></v-text-field>
                      </div>
                    </v-flex>

                    <v-flex xs12 md4>
                      <div class="mx-3">
                        <div>
                          Investor Type<span class="error--text">*</span>
                        </div>
                        <v-select
                          :items="formData.investor_types"
                          v-model="newInvestmentFirm.investor_type_id"
                          class="text_field background"
                          item-value="id"
                          item-text="name"
                          outlined
                          dense
                          :rules="inputRules"
                        ></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="mx-3">
                        <div>County<span class="error--text">*</span></div>
                        <v-select
                          :items="formData.counties"
                          v-model="newInvestmentFirm.county_id"
                          class="text_field background"
                          item-value="id"
                          item-text="name"
                          outlined
                          dense
                          :rules="inputRules"
                        ></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 md9>
                      <div class="mx-3 mt-2">
                        <div>Brief<span class="error--text">*</span></div>
                        <v-text-field
                          class="text_field background"
                          outlined
                          dense
                          v-model="newInvestmentFirm.brief"
                          :rules="inputRules"
                        ></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md3>
                      <div class="mx-3 mt-2">
                        <div>
                          Establishment Year<span class="red--text">*</span>
                        </div>
                        <v-select
                          :items="years"
                          :rules="inputRules"
                          outlined
                          dense
                          v-model="newInvestmentFirm.establishment_year"
                          prepend-inner-icon="mdi-calendar"
                          class="background login_field ml-1 mr-3"
                        ></v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <div class="mx-3 mt-2">
                        <div>Description<span class="error--text">*</span></div>
                        <div class="black--text">
                          <ckeditor
                            :editor="editor"
                            v-model="newInvestmentFirm.description"
                            :config="editorConfig"
                            :rules="inputRules"
                          ></ckeditor>
                        </div>
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <div class="mx-3 mt-2">
                        <div>
                          Physical Address<span class="error--text">*</span>
                        </div>
                        <v-text-field
                          class="text_field background"
                          outlined
                          dense
                          v-model="newInvestmentFirm.physical_address"
                          :rules="inputRules"
                        ></v-text-field>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  class="primary text-none mt-3"
                  @click="save"
                  :disabled="!valid"
                  :loading="loading"
                >
                  Save
                  <v-icon right> mdi-content-save </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </div>
        <div v-if="view == 'show'">
          <v-card outlined class="mt-5">
            <v-progress-linear
              v-if="investmentFirmAdministratorLoader"
              height="1"
              indeterminate
              color="primary"
            >
            </v-progress-linear>
            <v-card-title class="tableHeader">
              <v-spacer></v-spacer>
              <div class="text--text">View Investment Firm</div>
              <v-spacer></v-spacer>
              <v-btn icon @click="changeView('default')">
                <v-icon class="text--text"> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div class="pa-1">
                <v-tabs show-arrows v-model="investmentFirmTab">
                  <v-tabs-slider color="primary"></v-tabs-slider>

                  <v-tab @click="activeTab = 'details'">
                    <div class="text-none text--text">Details</div>
                  </v-tab>
                  <v-tab @click="activeTab = 'investmentFirmInvestments'">
                    <div class="text-none text--text">
                      Investments ({{
                        investmentFirmInvestmentPagination.total
                      }})
                    </div>
                  </v-tab>
                  <v-tab @click="activeTab = 'investmentFirmInnovations'">
                    <div class="text-none text--text">
                      Innovations ({{
                        investmentFirmInnovationPagination.total
                      }})
                    </div>
                  </v-tab>
                  
                  <v-tab
                    @click="activeTab = 'investmentFirmAdministrators'"
                    v-if="$can('investment_firm_administrator_view')"
                  >
                    <div class="text-none text--text">
                      Administrators ({{
                        investmentFirmAdministratorPagination.total
                      }})
                    </div>
                  </v-tab>
                </v-tabs>
                <div v-if="activeTab == 'details'">
                  <v-card-text>
                    <div class="pa-3">
                      <v-layout row wrap>
                        <v-flex xs12 md3>
                          <div class="mr-8">
                            <v-img
                              v-if="assignedInvestmentFirm.cover_image == null"
                              src="img/logo_primary.png"
                              height="200px"
                              style="z-index: 2; border-radius: 25px"
                            >
                              <div align="right" class="mt-2 mr-2">
                                <v-btn
                                  class="white"
                                  icon
                                  small
                                  @click="coverImageDialog = true"
                                  v-if="$can('investment_firm_image_upload')"
                                  ><v-icon class="primary--text" small
                                    >mdi-pen</v-icon
                                  ></v-btn
                                >
                              </div>

                              <v-avatar
                                size="86"
                                style="
                                  position: absolute;
                                  top: 110px;
                                  left: 3px;
                                  z-index: 10;
                                "
                              >
                                <v-img
                                  v-if="assignedInvestmentFirm.logo == null"
                                  src="img/logo_fav.png"
                                >
                                </v-img>
                                <v-img
                                  v-else
                                  :src="
                                    path +
                                    '/storage/investment_firm_pictures/' +
                                    assignedInvestmentFirm.logo
                                  "
                                >
                                </v-img>
                              </v-avatar>
                            </v-img>
                            <v-img
                              v-else
                              :src="
                                path +
                                '/storage/investment_firm_pictures/' +
                                assignedInvestmentFirm.cover_image
                              "
                              height="200px"
                              style="z-index: 2; border-radius: 25px"
                            >
                              <div align="right" class="mt-2 mr-2">
                                <v-btn
                                  class="white"
                                  icon
                                  small
                                  @click="coverImageDialog = true"
                                  v-if="$can('investment_firm_image_upload')"
                                  ><v-icon class="primary--text" small
                                    >mdi-pen</v-icon
                                  ></v-btn
                                >
                              </div>

                              <v-avatar
                                size="86"
                                style="
                                  position: absolute;
                                  top: 110px;
                                  left: 3px;
                                  z-index: 10;
                                "
                              >
                                <v-img
                                  v-if="assignedInvestmentFirm.logo == null"
                                  src="img/logo_fav.png"
                                >
                                </v-img>
                                <v-img
                                  v-else
                                  :src="
                                    path +
                                    '/storage/investment_firm_pictures/' +
                                    assignedInvestmentFirm.logo
                                  "
                                >
                                </v-img>
                              </v-avatar>
                            </v-img>
                          </div>
                          <div align="center" class="mt-5">
                            <v-btn
                              class="primary text-none"
                              depressed
                              @click="imageDialog = true"
                              v-if="$can('investment_firm_image_upload')"
                            >
                              Upload Logo
                              <v-icon right>mdi-file-upload</v-icon>
                            </v-btn>
                          </div>
                        </v-flex>
                        <v-flex xs12 md9>
                          <v-layout column class="mt-3">
                            <v-flex xs12>
                              <v-layout row wrap>
                                <v-flex xs12 md6>
                                  <v-layout column>
                                    <v-flex xs12 class="mt-2">
                                      <v-layout row wrap>
                                        <v-flex xs12 md4>
                                          <b>Institution Name: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          <div>
                                            {{ assignedInvestmentFirm.name }}
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-6">
                                      <v-layout row wrap>
                                        <v-flex xs12 md4>
                                          <b>County: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          <div>
                                            {{
                                              assignedInvestmentFirm.county.name
                                            }}
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 md6>
                                  <v-layout column>
                                    <v-flex xs12 class="mt-2">
                                      <v-layout row wrap>
                                        <v-flex xs12 md4>
                                          <b>Status: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          <div
                                            v-if="
                                              assignedInvestmentFirm.status == 1
                                            "
                                          >
                                            Active
                                          </div>
                                          <div v-else>Inactive</div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="mt-6">
                                      <v-layout row wrap>
                                        <v-flex xs12 md4>
                                          <b>Date Created: </b>
                                        </v-flex>
                                        <v-flex xs12 md8>
                                          <div>
                                            {{
                                              assignedInvestmentFirm.created_at
                                                | moment("DD/MM/YYYY - hh:mm a")
                                            }}
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-card-text>
                  <v-card-title class="tableHeader text--text mt-5">
                    <v-spacer></v-spacer>
                    <div>Investment Firm Contact Details</div>
                    <v-spacer></v-spacer>
                    <v-btn
                      icon
                      @click="changeContactView('create')"
                      v-if="
                        $can('investment_firm_contact_create') &&
                        contactView == 'default' &&
                        assignedInvestmentFirm.investment_firm_contact == null
                      "
                    >
                      <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn>

                    <v-btn
                      icon
                      @click="changeContactView('default')"
                      v-if="contactView != 'default'"
                    >
                      <v-icon> mdi-close </v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <div v-if="contactView == 'default'">
                      <div class="pa-3 mt-5">
                        <div
                          v-if="
                            assignedInvestmentFirm.investment_firm_contact ==
                            null
                          "
                        >
                          <v-layout row wrap>
                            <v-flex xs12 md1>
                              <v-container fill-height fluid>
                                <v-row align="center" justify="center">
                                  <v-col class="text-center">
                                    <v-icon large class="primary--text">
                                      mdi-alert-circle-outline
                                    </v-icon>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md11>
                              <v-container fill-height fluid>
                                <v-row align="center" justify="center">
                                  <v-col class="text-center">
                                    Contacts Not Added
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-flex>
                          </v-layout>
                        </div>
                        <div v-else>
                          <v-layout column>
                            <v-flex xs12>
                              <div class="ml-3">
                                <v-layout row wrap>
                                  <v-flex xs11>
                                    <v-layout row wrap>
                                      <v-flex xs12 md6>
                                        <v-layout column>
                                          <v-flex xs12 class="mt-6">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Contact Person: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div
                                                  v-if="
                                                    assignedInvestmentFirm
                                                      .investment_firm_contact
                                                      .contact_person != null
                                                  "
                                                >
                                                  {{
                                                    assignedInvestmentFirm
                                                      .investment_firm_contact
                                                      .contact_person
                                                  }}
                                                </div>
                                                <div v-else>N/A</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-6">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Primary Email Address: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div
                                                  v-if="
                                                    assignedInvestmentFirm
                                                      .investment_firm_contact
                                                      .email != null
                                                  "
                                                >
                                                  {{
                                                    assignedInvestmentFirm
                                                      .investment_firm_contact
                                                      .email
                                                  }}
                                                </div>
                                                <div v-else>N/A</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-6">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Secondary Email Address: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div
                                                  v-if="
                                                    assignedInvestmentFirm
                                                      .investment_firm_contact
                                                      .email_2 != null
                                                  "
                                                >
                                                  {{
                                                    assignedInvestmentFirm
                                                      .investment_firm_contact
                                                      .email_2
                                                  }}
                                                </div>
                                                <div v-else>N/A</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-6">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Website URL: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div
                                                  v-if="
                                                    assignedInvestmentFirm
                                                      .investment_firm_contact
                                                      .web_url != null
                                                  "
                                                >
                                                  <a
                                                    :href="
                                                      assignedInvestmentFirm
                                                        .investment_firm_contact
                                                        .web_url
                                                    "
                                                    target="_blank"
                                                    style="
                                                      text-decoration: none;
                                                    "
                                                    >{{
                                                      assignedInvestmentFirm
                                                        .investment_firm_contact
                                                        .web_url
                                                    }}</a
                                                  >
                                                </div>
                                                <div v-else>N/A</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-6">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Twitter URL: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div
                                                  v-if="
                                                    assignedInvestmentFirm
                                                      .investment_firm_contact
                                                      .twitter_url != null
                                                  "
                                                >
                                                  <a
                                                    :href="
                                                      assignedInvestmentFirm
                                                        .investment_firm_contact
                                                        .twitter_url
                                                    "
                                                    target="_blank"
                                                    style="
                                                      text-decoration: none;
                                                    "
                                                    >{{
                                                      assignedInvestmentFirm
                                                        .investment_firm_contact
                                                        .twitter_url
                                                    }}</a
                                                  >
                                                </div>
                                                <div v-else>N/A</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 md6>
                                        <v-layout column>
                                          <v-flex xs12 class="mt-6">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Primary Phone #: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div
                                                  v-if="
                                                    assignedInvestmentFirm
                                                      .investment_firm_contact
                                                      .phone != null
                                                  "
                                                >
                                                  {{
                                                    assignedInvestmentFirm
                                                      .investment_firm_contact
                                                      .phone
                                                  }}
                                                </div>
                                                <div v-else>N/A</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-6">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Secondary Phone #: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div
                                                  v-if="
                                                    assignedInvestmentFirm
                                                      .investment_firm_contact
                                                      .phone_2 != null
                                                  "
                                                >
                                                  {{
                                                    assignedInvestmentFirm
                                                      .investment_firm_contact
                                                      .phone_2
                                                  }}
                                                </div>
                                                <div v-else>N/A</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-6">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Facebook URL: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div
                                                  v-if="
                                                    assignedInvestmentFirm
                                                      .investment_firm_contact
                                                      .fb_url != null
                                                  "
                                                >
                                                  <a
                                                    :href="
                                                      assignedInvestmentFirm
                                                        .investment_firm_contact
                                                        .fb_url
                                                    "
                                                    target="_blank"
                                                    style="
                                                      text-decoration: none;
                                                    "
                                                    >{{
                                                      assignedInvestmentFirm
                                                        .investment_firm_contact
                                                        .fb_url
                                                    }}</a
                                                  >
                                                </div>
                                                <div v-else>N/A</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-6">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Instagram URL: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div
                                                  v-if="
                                                    assignedInvestmentFirm
                                                      .investment_firm_contact
                                                      .insta_url != null
                                                  "
                                                >
                                                  <a
                                                    :href="
                                                      assignedInvestmentFirm
                                                        .investment_firm_contact
                                                        .insta_url
                                                    "
                                                    target="_blank"
                                                    style="
                                                      text-decoration: none;
                                                    "
                                                    >{{
                                                      assignedInvestmentFirm
                                                        .investment_firm_contact
                                                        .insta_url
                                                    }}</a
                                                  >
                                                </div>
                                                <div v-else>N/A</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-flex xs12 class="mt-6">
                                            <v-layout row wrap>
                                              <v-flex xs12 md4>
                                                <b>Visibility: </b>
                                              </v-flex>
                                              <v-flex xs12 md8>
                                                <div
                                                  v-if="
                                                    assignedInvestmentFirm
                                                      .investment_firm_contact
                                                      .visible == 1
                                                  "
                                                >
                                                  Public
                                                </div>
                                                <div v-else>Hidden</div>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs1>
                                    <v-btn
                                      icon
                                      @click="
                                        editContact(
                                          assignedInvestmentFirm.investment_firm_contact
                                        )
                                      "
                                      v-if="$can('investment_firm_contact_create')"
                                    >
                                      <v-icon small class="green--text"
                                        >mdi-pencil</v-icon
                                      >
                                    </v-btn>
                                    <v-btn
                                      icon
                                      @click="
                                        deleteContact(
                                          assignedInvestmentFirm.investment_firm_contact
                                        )
                                      "
                                      v-if="$can('investment_firm_contact_archive')"
                                      :loading="loading"
                                    >
                                      <v-icon small class="red--text"
                                        >mdi-delete</v-icon
                                      >
                                    </v-btn>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </v-flex>
                          </v-layout>
                        </div>
                      </div>
                    </div>
                    <div v-if="contactView == 'create'" class="mt-5">
                      <v-form v-model="valid" ref="contactForm" lazy-validation>
                        <v-layout row wrap>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Contact Person</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newContact.contact_person"
                              >
                              </v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Primary Email</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newContact.email"
                                :rules="emailRules"
                              ></v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Secondary Email</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newContact.email_2"
                                :rules="emailRules"
                              ></v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Primary Phone Number</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newContact.phone"
                              ></v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Secondary Phone Number</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newContact.phone_2"
                              ></v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Website URL</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newContact.web_url"
                                :rules="websiteRules"
                              ></v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Facebook Page URL</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newContact.fb_url"
                              ></v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Instagram Account URL</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newContact.insta_url"
                              ></v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <div>Twitter Account URL</div>
                              <v-text-field
                                class="text_field background my-2"
                                outlined
                                dense
                                v-model="newContact.twitter_url"
                              ></v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md4>
                            <div class="mt-4">
                              <v-checkbox
                                class="text_field"
                                outlined
                                dense
                                v-model="newContact.visible"
                                label="Visible"
                              ></v-checkbox>
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div align="right">
                              <v-btn
                                depressed
                                class="primary text-none mt-3"
                                @click="saveContact"
                                :disabled="!valid"
                                :loading="loading"
                              >
                                Save
                                <v-icon right> mdi-content-save </v-icon>
                              </v-btn>
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-form>
                    </div>
                  </v-card-text>
                </div>
                <div v-if="activeTab == 'investmentFirmAdministrators'">
                  <div v-if="investmentFirmAdministratorView == 'default'">
                    <v-card elevation="0" class="mt-5">
                      <v-flex xs12 class="mt-10 mb-2">
                        <div class="mx-5">
                          <v-layout row wrap>
                            <v-flex xs12 md4> </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <v-text-field
                                  class="text_field header"
                                  outlined
                                  dense
                                  label="Search"
                                  append-icon="mdi-undo-variant"
                                  @click:append="
                                    resetInstitutionAdministratorSearch()
                                  "
                                  v-on:keyup.enter="
                                    investmentFirmAdministratorSearch
                                  "
                                  v-model="
                                    investmentFirmAdministratorSearchTerm
                                  "
                                  @input="
                                    enableInstitutionAdministratorSearch()
                                  "
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md2>
                              <div class="pa-1">
                                <v-btn
                                  class="secondary primary--text text-none"
                                  depressed
                                  block
                                  @click="investmentFirmAdministratorSearch"
                                  :loading="
                                    investmentFirmAdministratorSearchLoader
                                  "
                                  :disabled="
                                    investmentFirmAdministratorSearchButtonDisabled
                                  "
                                >
                                  Search
                                  <v-icon right>mdi-magnify</v-icon>
                                </v-btn>
                              </div>
                            </v-flex>
                            <v-flex xs12 md2>
                              <div class="pa-1">
                                <v-btn
                                  block
                                  depressed
                                  class="primary text-none"
                                  @click="
                                    changeInvestmentFirmAdministratorView(
                                      'create'
                                    )
                                  "
                                  v-if="
                                    $can(
                                      'investment_firm_administrator_create'
                                    ) &&
                                    assignedInvestmentFirm.permission == 'write'
                                  "
                                >
                                  Add Administrator
                                  <v-icon right>mdi-plus-circle-outline</v-icon>
                                </v-btn>
                              </div>
                            </v-flex>
                          </v-layout>
                        </div>
                      </v-flex>
                      <v-divider class="mt-9 mx-5"></v-divider>
                      <v-flex xs12 class="mt-5">
                        <div v-if="investmentFirmAdministrators.length == 0">
                          <v-card elevation="0">
                            <v-layout row wrap>
                              <v-flex xs12 md1>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      <v-icon large class="text--text">
                                        mdi-alert-circle-outline
                                      </v-icon>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                              <v-flex xs12 md11>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      No Investment Firm Administrator(s) Found
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </div>
                        <div v-else>
                          <div class="hidden-sm-and-down">
                            <v-card elevation="0" class="pa-2">
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead class="header">
                                    <tr class="tableHeader">
                                      <th class="text-left text--text">Pic</th>
                                      <th class="text-left text--text">Name</th>
                                      <th class="text-left text--text">
                                        Email Address
                                      </th>
                                      <th class="text-left text--text">
                                        Phone #
                                      </th>
                                      <th class="text-left text--text">Code</th>
                                      <th class="text-left text--text">
                                        Permission
                                      </th>
                                      <th class="text-left text--text">
                                        Status
                                      </th>
                                      <th class="text-right text--text">
                                        Actions
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="item in investmentFirmAdministrators"
                                      :key="item.id"
                                    >
                                      <td>
                                        <div class="my-2">
                                          <v-avatar
                                            size="48"
                                            v-if="item.user.display_img == null"
                                          >
                                            <v-img
                                              max-height="150"
                                              src="img/logo_fav.png"
                                            >
                                            </v-img>
                                          </v-avatar>
                                          <v-avatar size="48" v-else>
                                            <v-img
                                              max-height="150"
                                              :src="
                                                path +
                                                '/storage/profile_pics/' +
                                                item.user.display_img
                                              "
                                            >
                                            </v-img>
                                          </v-avatar>
                                        </div>
                                      </td>
                                      <td>
                                        {{ item.user.f_name }}
                                        {{ item.user.m_name }}
                                        {{ item.user.l_name }}
                                      </td>
                                      <td>{{ item.user.email }}</td>
                                      <td>{{ item.user.phone }}</td>
                                      <td>{{ item.user.code }}</td>
                                      <td>
                                        <div v-if="item.write == 0">
                                          View Only
                                        </div>
                                        <div v-if="item.write == 1">
                                          View & Update
                                        </div>
                                      </td>
                                      <td>
                                        <div v-if="item.status == 1">
                                          Active
                                        </div>
                                        <div v-else>Inactive</div>
                                      </td>
                                      <td>
                                        <div align="right">
                                          <v-btn
                                            icon
                                            @click="
                                              showInvestmentFirmAdministrator(
                                                item.user
                                              )
                                            "
                                            class="button mr-1 universal--text"
                                          >
                                            <v-icon small> mdi-eye </v-icon>
                                          </v-btn>
                                          <v-btn
                                            v-if="
                                              $can(
                                                'investment_firm_administrator_archive'
                                              ) &&
                                              assignedInvestmentFirm.permission ==
                                                'write'
                                            "
                                            icon
                                            class="button mr-1 error--text"
                                            @click="
                                              deleteInvestmentFirmAdministrator(
                                                item
                                              )
                                            "
                                            :loading="
                                              loading &&
                                              investmentFirmAdministratorIndex ==
                                                item.id
                                            "
                                          >
                                            <v-icon small> mdi-delete </v-icon>
                                          </v-btn>
                                          <v-tooltip
                                            top
                                            v-if="
                                              $can(
                                                'investment_firm_administrator_create'
                                              ) &&
                                              item.write == 0 &&
                                              assignedInvestmentFirm.permission ==
                                                'write'
                                            "
                                          >
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <v-btn
                                                icon
                                                class="button mr-1 teal--text"
                                                @click="
                                                  upgradeInvestmentFirmAdministrator(
                                                    item
                                                  )
                                                "
                                                :loading="
                                                  upgradeLoading &&
                                                  investmentFirmAdministratorIndex ==
                                                    item.id
                                                "
                                                v-bind="attrs"
                                                v-on="on"
                                              >
                                                <v-icon small>
                                                  mdi-arrow-up-thick
                                                </v-icon>
                                              </v-btn>
                                            </template>
                                            <span>Upgrade Permission</span>
                                          </v-tooltip>
                                          <v-tooltip
                                            top
                                            v-if="
                                              $can(
                                                'investment_firm_administrator_create'
                                              ) &&
                                              item.write == 1 &&
                                              assignedInvestmentFirm.permission ==
                                                'write'
                                            "
                                          >
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <v-btn
                                                icon
                                                class="button mr-1 deep-orange--text"
                                                @click="
                                                  downgradeInnovationAdministrator(
                                                    item
                                                  )
                                                "
                                                :loading="
                                                  downgradeLoading &&
                                                  investmentFirmAdministratorIndex ==
                                                    item.id
                                                "
                                                v-bind="attrs"
                                                v-on="on"
                                              >
                                                <v-icon small>
                                                  mdi-arrow-down-thick
                                                </v-icon>
                                              </v-btn>
                                            </template>
                                            <span>Downgrade Permission</span>
                                          </v-tooltip>
                                          <v-tooltip
                                            top
                                            v-if="
                                              $can(
                                                'investment_firm_administrator_activate'
                                              ) && item.status == 0
                                            "
                                          >
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <v-btn
                                                icon
                                                class="button mr-1 red--text"
                                                @click="
                                                  activateInvestmentFirmAdministrator(
                                                    item
                                                  )
                                                "
                                                :loading="
                                                  activateLoading &&
                                                  investmentFirmAdministratorIndex ==
                                                    item.id
                                                "
                                                v-bind="attrs"
                                                v-on="on"
                                              >
                                                <v-icon small>
                                                  mdi-toggle-switch-off-outline
                                                </v-icon>
                                              </v-btn>
                                            </template>
                                            <span>Activate Administrator</span>
                                          </v-tooltip>
                                          <v-tooltip
                                            top
                                            v-if="
                                              $can(
                                                'investment_firm_administrator_deactivate'
                                              ) && item.status == 1
                                            "
                                          >
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <v-btn
                                                icon
                                                class="button mr-1 green--text"
                                                @click="
                                                  deactivateInvestmentFirmAdministrator(
                                                    item
                                                  )
                                                "
                                                :loading="
                                                  deactivateLoading &&
                                                  investmentFirmAdministratorIndex ==
                                                    item.id
                                                "
                                                v-bind="attrs"
                                                v-on="on"
                                              >
                                                <v-icon small>
                                                  mdi-toggle-switch
                                                </v-icon>
                                              </v-btn>
                                            </template>
                                            <span
                                              >Deactivate Administrator</span
                                            >
                                          </v-tooltip>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card>
                          </div>
                          <div class="hidden-md-and-up">
                            <div>
                              <v-layout column>
                                <template
                                  v-for="(
                                    administrator, index
                                  ) in investmentFirmAdministrators"
                                >
                                  <div :key="index">
                                    <v-flex xs12 class="mb-2">
                                      <v-card elevation="0">
                                        <div class="pa-5">
                                          <v-layout column>
                                            <v-flex xs12>
                                              <v-layout row wrap>
                                                <v-flex xs11>
                                                  <div class="title text--text">
                                                    <b
                                                      >{{
                                                        administrator.user
                                                          .f_name
                                                      }}
                                                      {{
                                                        administrator.user
                                                          .m_name
                                                      }}
                                                      {{
                                                        administrator.user
                                                          .l_name
                                                      }}</b
                                                    >
                                                  </div>
                                                </v-flex>
                                                <v-flex xs1>
                                                  <v-btn
                                                    depressed
                                                    class="red--text"
                                                    :loading="
                                                      loading &&
                                                      investmentFirmAdministratorIndex ==
                                                        administrator.id
                                                    "
                                                    icon
                                                    @click="
                                                      deleteInvestmentFirmAdministrator(
                                                        administrator
                                                      )
                                                    "
                                                    v-if="
                                                      $can(
                                                        'investment_firm_administrator_archive'
                                                      )
                                                    "
                                                  >
                                                    <v-icon>
                                                      mdi-delete
                                                    </v-icon>
                                                  </v-btn>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-divider class="mt-2"></v-divider>
                                            <v-flex xs12 class="mt-1">
                                              <v-layout column>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Email Address</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{
                                                        administrator.user.email
                                                      }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Phone #</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{
                                                        administrator.user.phone
                                                      }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Code</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{
                                                        administrator.user.code
                                                      }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Permission</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      <div
                                                        v-if="
                                                          administrator.write ==
                                                          0
                                                        "
                                                      >
                                                        View Only
                                                      </div>
                                                      <div
                                                        v-if="
                                                          administrator.write ==
                                                          1
                                                        "
                                                      >
                                                        View & Update
                                                      </div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Status</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      <div
                                                        v-if="
                                                          administrator.status ==
                                                          1
                                                        "
                                                      >
                                                        Active
                                                      </div>
                                                      <div v-else>Inactive</div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>

                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="primary text-none white--text mt-3"
                                                          block
                                                          @click="
                                                            showInvestmentFirmAdministrator(
                                                              administrator.user
                                                            )
                                                          "
                                                          >View
                                                          <v-icon right>
                                                            mdi-eye
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>
                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="teal text-none white--text mt-1"
                                                          block
                                                          :loading="
                                                            upgradeLoading &&
                                                            investmentFirmAdministratorIndex ==
                                                              administrator.id
                                                          "
                                                          @click="
                                                            upgradeInvestmentFirmAdministrator(
                                                              administrator
                                                            )
                                                          "
                                                          v-if="
                                                            $can(
                                                              'investment_firm_administrator_create'
                                                            ) &&
                                                            administrator.write ==
                                                              0 &&
                                                            assignedInvestmentFirm.permission ==
                                                              'write'
                                                          "
                                                          >Upgrade Permission
                                                          <v-icon right>
                                                            mdi-arrow-up-thick
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>
                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="deep-orange text-none white--text mt-1"
                                                          block
                                                          :loading="
                                                            downgradeLoading &&
                                                            investmentFirmAdministratorIndex ==
                                                              administrator.id
                                                          "
                                                          @click="
                                                            downgradeInnovationAdministrator(
                                                              administrator
                                                            )
                                                          "
                                                          v-if="
                                                            $can(
                                                              'investment_firm_administrator_create'
                                                            ) &&
                                                            administrator.write ==
                                                              1 &&
                                                            assignedInvestmentFirm.permission ==
                                                              'write'
                                                          "
                                                          >Downgrade Permission
                                                          <v-icon right>
                                                            mdi-arrow-down-thick
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>
                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="green darken-1 text-none white--text mt-1"
                                                          block
                                                          :loading="
                                                            activateLoading &&
                                                            investmentFirmAdministratorIndex ==
                                                              administrator.id
                                                          "
                                                          @click="
                                                            activateInvestmentFirmAdministrator(
                                                              administrator
                                                            )
                                                          "
                                                          v-if="
                                                            $can(
                                                              'investment_firm_administrator_activate'
                                                            ) &&
                                                            administrator.status ==
                                                              0
                                                          "
                                                          >Activate
                                                          Administrator
                                                          <v-icon right>
                                                            mdi-toggle-switch
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>
                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="red darken-1 text-none white--text mt-1"
                                                          block
                                                          :loading="
                                                            deactivateLoading &&
                                                            investmentFirmAdministratorIndex ==
                                                              administrator.id
                                                          "
                                                          @click="
                                                            deactivateInvestmentFirmAdministrator(
                                                              administrator
                                                            )
                                                          "
                                                          v-if="
                                                            $can(
                                                              'investment_firm_administrator_deactivate'
                                                            ) &&
                                                            administrator.status ==
                                                              1
                                                          "
                                                          >Deactivate
                                                          Administrator
                                                          <v-icon right>
                                                            mdi-toggle-switch-off-outline
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                          </v-layout>
                                        </div>
                                      </v-card>
                                    </v-flex>
                                  </div>
                                </template>
                              </v-layout>
                            </div>
                          </div>
                        </div>
                      </v-flex>
                    </v-card>
                    <v-layout column class="mt-5">
                      <v-flex xs12>
                        <div align="left">
                          <v-pagination
                            v-if="length != 0"
                            :length="investmentFirmAdministratorLength"
                            total-visible="10"
                            v-model="
                              investmentFirmAdministratorPagination.current_page
                            "
                            @input="changeInvestmentFirmAdministratorPage()"
                            circle
                          >
                          </v-pagination>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div
                          v-if="investmentFirmAdministratorLength != 0"
                          align="center"
                          class="mt-5"
                        >
                          <b class="text--text">Total: </b
                          >{{
                            investmentFirmAdministratorPagination.total
                              | formatNumber
                          }}
                        </div>
                      </v-flex>
                    </v-layout>
                  </div>
                  <div v-if="investmentFirmAdministratorView == 'create'">
                    <v-flex class="mt-5">
                      <v-card-title class="tableHeader text--text">
                        <v-spacer></v-spacer>
                        <div v-if="newAdministrator.id == null">
                          Add Investment Firm Administrator
                        </div>
                        <div v-else>Edit Investment Firm Administrator</div>
                        <v-spacer></v-spacer>
                        <v-btn
                          icon
                          @click="
                            changeInvestmentFirmAdministratorView('default')
                          "
                        >
                          <v-icon class="text--text"> mdi-close </v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <v-form
                          v-model="valid"
                          ref="investmentFirmAdministratorForm"
                          lazy-validation
                        >
                          <v-layout row wrap class="mt-5">
                            <v-flex xs12 md4>
                              <div class="pa-3">
                                <div>
                                  User Code<span class="error--text">*</span>
                                </div>
                                <v-autocomplete
                                  v-model="newAdministrator.user_id"
                                  :loading="loading"
                                  :items="items"
                                  :search-input.sync="searchUsers"
                                  item-value="id"
                                  item-text="code"
                                  cache-items
                                  class="text_field background my-2"
                                  flat
                                  hide-no-data
                                  hide-details
                                  outlined
                                  dense
                                  :rules="inputRules"
                                >
                                  <template v-slot:selection="{ item }">
                                    {{ getText(item) }}
                                  </template>
                                  <template v-slot:item="{ item }">
                                    {{ getText(item) }}
                                  </template>
                                </v-autocomplete>
                              </div>
                            </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-3">
                                <div>Permission Level</div>
                                <v-select
                                  :items="accessLevels"
                                  v-model="newAdministrator.write"
                                  class="text_field background my-2"
                                  item-value="value"
                                  item-text="name"
                                  outlined
                                  dense
                                ></v-select>
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          depressed
                          class="primary text-none mt-3"
                          @click="saveInvestmentFirmAdministrator"
                          :disabled="!valid"
                          :loading="loading"
                        >
                          Save
                          <v-icon right> mdi-content-save </v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-flex>
                  </div>
                </div>
                <div v-if="activeTab == 'investmentFirmInnovations'">
                  <div v-if="investmentFirmInnovationView == 'default'">
                    <v-card elevation="0" class="mt-5">
                      <v-flex xs12 class="mt-10 mb-2">
                        <div class="mx-5">
                          <v-layout row wrap>
                            <v-flex xs12 md4> </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <v-text-field
                                  class="text_field header"
                                  outlined
                                  dense
                                  label="Search"
                                  append-icon="mdi-undo-variant"
                                  @click:append="
                                    resetInvestmentFirmInnovationSearch()
                                  "
                                  v-on:keyup.enter="
                                    investmentFirmInnovationSearch
                                  "
                                  v-model="investmentFirmInnovationSearchTerm"
                                  @input="
                                    enableInvestmentFirmInnovationSearch()
                                  "
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md2>
                              <div class="pa-1">
                                <v-btn
                                  class="secondary primary--text text-none"
                                  depressed
                                  block
                                  @click="investmentFirmInnovationSearch"
                                  :loading="
                                    investmentFirmInnovationSearchLoader
                                  "
                                  :disabled="
                                    investmentFirmInnovationSearchButtonDisabled
                                  "
                                >
                                  Search
                                  <v-icon right>mdi-magnify</v-icon>
                                </v-btn>
                              </div>
                            </v-flex>
                            <v-flex xs12 md2> </v-flex>
                          </v-layout>
                        </div>
                      </v-flex>
                      <v-divider class="mt-9 mx-5"></v-divider>
                      <v-flex xs12 class="mt-5">
                        <div v-if="investmentFirmInnovations.length == 0">
                          <v-card elevation="0">
                            <v-layout row wrap>
                              <v-flex xs12 md1>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      <v-icon large class="text--text">
                                        mdi-alert-circle-outline
                                      </v-icon>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                              <v-flex xs12 md11>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      No Innovation Investment(s) Found
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </div>
                        <div v-else>
                          <div class="hidden-sm-and-down">
                            <v-card elevation="0" class="pa-2">
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead class="header">
                                    <tr class="tableHeader">
                                      <th class="text-left text--text">Logo</th>
                                      <th class="text-left text--text">Name</th>
                                      <!-- <th class="text-left text--text">Establishment Year</th> -->
                                      <th class="text-left text--text">
                                        IP Protection
                                      </th>
                                      <th class="text-left text--text">
                                        Stage
                                      </th>
                                      <th class="text-left text--text">
                                        County
                                      </th>
                                      <th class="text-left text--text">
                                        Investments
                                      </th>
                                      <th class="text-right text--text">
                                        Actions
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="item in investmentFirmInnovations"
                                      :key="item.id"
                                    >
                                      <td>
                                        <div class="my-2">
                                          <v-avatar
                                            size="48"
                                            v-if="item.featured_image == null"
                                          >
                                            <v-img
                                              max-height="150"
                                              src="img/logo_fav.png"
                                            >
                                            </v-img>
                                          </v-avatar>
                                          <v-avatar size="48" v-else>
                                            <v-img
                                              max-height="150"
                                              :src="
                                                path +
                                                '/storage/innovation_pictures/' +
                                                item.featured_image
                                              "
                                            >
                                            </v-img>
                                          </v-avatar>
                                        </div>
                                      </td>
                                      <td>{{ item.name }}</td>
                                      <!-- <td>{{ item.establishment_year }}</td> -->
                                      <td>
                                        {{ item.ip_protection_type.name }}
                                      </td>
                                      <td>{{ item.stage.name }}</td>
                                      <td>{{ item.county.name }}</td>
                                      <td>
                                        <template
                                          v-for="(
                                            investment, index
                                          ) in item.investments"
                                        >
                                          <div
                                            v-if="
                                              investment
                                                .investment_firm_investments
                                                .length != 0
                                            "
                                            class="mt-2"
                                          >
                                            <b>Investor: </b
                                            >{{
                                              investment
                                                .investment_firm_investments[0]
                                                .investment_firm.name
                                            }}
                                          </div>
                                          <div>
                                            <b>Amount: </b> Kshs
                                            {{ intToString(investment.amount) }}
                                          </div>
                                          <div>
                                            <b>Funding: </b
                                            >{{ investment.funding_type.name }}
                                          </div>
                                          <div class="mb-2">
                                            <b>Date: </b
                                            >{{
                                              investment.created_at
                                                | moment("Do MMMM YYYY")
                                            }}
                                          </div>
                                        </template>
                                      </td>
                                      <td>
                                        <div align="right">
                                          <v-btn
                                            icon
                                            @click="showInnovation(item)"
                                            class="button mr-1 universal--text"
                                          >
                                            <v-icon small> mdi-eye </v-icon>
                                          </v-btn>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card>
                          </div>
                          <div class="hidden-md-and-up">
                            <div>
                              <v-layout column>
                                <template
                                  v-for="(
                                    innovation, index
                                  ) in investmentFirmInnovations"
                                >
                                  <div :key="index">
                                    <v-flex xs12 class="mb-2">
                                      <v-card elevation="0">
                                        <div class="pa-5">
                                          <v-layout column>
                                            <v-flex xs12>
                                              <v-layout row wrap>
                                                <v-flex xs11>
                                                  <div
                                                    class="title primary--text"
                                                  >
                                                    <b>{{ innovation.name }}</b>
                                                  </div>
                                                </v-flex>
                                                <v-flex xs1> </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-divider class="mt-2"></v-divider>
                                            <v-flex xs12 class="mt-1">
                                              <v-layout column>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Establishment Year</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{
                                                        innovation.establishment_year
                                                      }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>IP Protection Type</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{
                                                        innovation
                                                          .ip_protection_type
                                                          .name
                                                      }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Stage</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{
                                                        innovation.stage.name
                                                      }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>County</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{
                                                        innovation.county.name
                                                      }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>

                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="primary text-none white--text mt-3"
                                                          block
                                                          @click="
                                                            showInnovation(
                                                              innovation
                                                            )
                                                          "
                                                          >View
                                                          <v-icon right>
                                                            mdi-eye
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                          </v-layout>
                                        </div>
                                      </v-card>
                                    </v-flex>
                                  </div>
                                </template>
                              </v-layout>
                            </div>
                          </div>
                        </div>
                      </v-flex>
                    </v-card>
                    <v-layout column class="mt-5">
                      <v-flex xs12>
                        <div align="left">
                          <v-pagination
                            v-if="length != 0"
                            :length="investmentFirmInnovationLength"
                            total-visible="10"
                            v-model="
                              investmentFirmInnovationPagination.current_page
                            "
                            @input="changeInvestmentFirmInnovationPage()"
                            circle
                          >
                          </v-pagination>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div
                          v-if="investmentFirmInnovationLength != 0"
                          align="center"
                          class="mt-5"
                        >
                          <b class="text--text">Total: </b
                          >{{
                            investmentFirmInnovationPagination.total
                              | formatNumber
                          }}
                        </div>
                      </v-flex>
                    </v-layout>
                  </div>
                </div>
                <div v-if="activeTab == 'investmentFirmInvestments'">
                  <v-card elevation="0" class="mt-5">
                    <v-flex xs12 class="mt-10 mb-2">
                      <div class="mx-5">
                        <v-layout row wrap>
                          <v-flex xs12 md4> </v-flex>
                          <v-flex xs12 md4>
                            <div class="pa-1">
                              <v-text-field
                                class="text_field header"
                                outlined
                                dense
                                label="Search"
                                append-icon="mdi-undo-variant"
                                @click:append="
                                  resetInvestmentFirmInvestmentSearch()
                                "
                                v-on:keyup.enter="
                                  investmentFirmInvestmentSearch
                                "
                                v-model="investmentFirmInvestmentSearchTerm"
                                @input="
                                  enableInvestmentFirmInvestmentSearch()
                                "
                              ></v-text-field>
                            </div>
                          </v-flex>
                          <v-flex xs12 md2>
                            <div class="pa-1">
                              <v-btn
                                class="secondary primary--text text-none"
                                depressed
                                block
                                @click="investmentFirmInvestmentSearch"
                                :loading="
                                  investmentFirmInvestmentSearchLoader
                                "
                                :disabled="
                                  investmentFirmInvestmentSearchButtonDisabled
                                "
                              >
                                Search
                                <v-icon right>mdi-magnify</v-icon>
                              </v-btn>
                            </div>
                          </v-flex>
                          <v-flex xs12 md2> </v-flex>
                        </v-layout>
                      </div>
                    </v-flex>
                    <v-divider class="mt-9 mx-5"></v-divider>
                    <v-flex xs12 class="mt-5">
                      <div v-if="investmentFirmInvestments.length == 0">
                        <v-card elevation="0">
                          <v-layout row wrap>
                            <v-flex xs12 md1>
                              <v-container fill-height fluid>
                                <v-row align="center" justify="center">
                                  <v-col class="text-center">
                                    <v-icon large class="text--text">
                                      mdi-alert-circle-outline
                                    </v-icon>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-flex>
                            <v-flex xs12 md11>
                              <v-container fill-height fluid>
                                <v-row align="center" justify="center">
                                  <v-col class="text-center">
                                    No Investment(s) Found
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </div>
                      <div v-else>
                        <div class="hidden-sm-and-down">
                          <v-card elevation="0" class="pa-2">
                            <v-simple-table>
                              <template v-slot:default>
                                <thead class="header">
                                  <tr class="tableHeader">
                                    <th class="text-left text--text">Date</th>
                                    <th class="text-left text--text">Innovation</th>
                                    <th class="text-right text--text">Amount (Kshs)</th>
                                    <!-- <th class="text-left text--text">Establishment Year</th> -->
                                    <th class="text-left text--text">
                                      Funding Type
                                    </th>
                                    <th class="text-left text--text">
                                      Public
                                    </th>
                                    <th class="text-left text--text">
                                      Status
                                    </th>
                                    
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="item in investmentFirmInvestments"
                                    :key="item.id"
                                  >
                                    <td>{{ item.created_at | moment("Do MMMM YYYY") }}</td>
                                    <td>{{ item.innovation.name }}</td>
                                    <td>
                                      <div align="right">
                                        {{ intToString(item.amount) }}
                                      </div>
                                    </td>
                                    <td>{{ item.funding_type.name }}</td>
                                    <td>
                                      <div v-if="item.public==1">
                                        Yes  
                                      </div>
                                      <div v-else>
                                        No
                                      </div>
                                    </td>
                                    <td>
                                      <div v-if="(item.status==0)">
                                          Pending
                                      </div>
                                      <div v-if="(item.status==1)">
                                          Accepted
                                      </div>
                                      <div v-if="(item.status==2)">
                                          Rejected
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-card>
                        </div>
                        <div class="hidden-md-and-up">
                          <div>
                            <v-layout column>
                              <template
                                v-for="(
                                  investment, index
                                ) in investmentFirmInvestments"
                              >
                                <div :key="index">
                                  <v-flex xs12 class="mb-2">
                                    <v-card elevation="0">
                                      <div class="pa-5">
                                        <v-layout column>
                                          <v-flex xs12>
                                            <v-layout row wrap>
                                              <v-flex xs11>
                                                <div
                                                  class="title primary--text"
                                                >
                                                  <b>{{ investment.created_at | moment("Do MMMM YYYY") }}</b>
                                                </div>
                                              </v-flex>
                                              <v-flex xs1> </v-flex>
                                            </v-layout>
                                          </v-flex>
                                          <v-divider class="mt-2"></v-divider>
                                          <v-flex xs12 class="mt-1">
                                            <v-layout column>
                                              <v-flex xs12 class="mt-5">
                                                <v-layout row wrap>
                                                  <v-flex xs4>
                                                    <b>Innovation</b>
                                                  </v-flex>
                                                  <v-flex xs8>
                                                    {{
                                                      investment.innovation.name
                                                    }}
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                              <v-flex xs12 class="mt-5">
                                                <v-layout row wrap>
                                                  <v-flex xs4>
                                                    <b>Amount (Kshs)</b>
                                                  </v-flex>
                                                  <v-flex xs8>
                                                    {{ intToString(investment.amount) }}
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                              <v-flex xs12 class="mt-5">
                                                <v-layout row wrap>
                                                  <v-flex xs4>
                                                    <b>Funding Type</b>
                                                  </v-flex>
                                                  <v-flex xs8>
                                                    {{
                                                      investment.funding_type.name
                                                    }}
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                              <v-flex xs12 class="mt-5">
                                                <v-layout row wrap>
                                                  <v-flex xs4>
                                                    <b>Public</b>
                                                  </v-flex>
                                                  <v-flex xs8>
                                                    <div v-if="investment.public==1">
                                                        Yes  
                                                      </div>
                                                      <div v-else>
                                                        No
                                                      </div>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                              <v-flex xs12 class="mt-5">
                                                <v-layout row wrap>
                                                  <v-flex xs4>
                                                    <b>Status</b>
                                                  </v-flex>
                                                  <v-flex xs8>
                                                    <div v-if="(investment.status==0)">
                                                        Pending
                                                    </div>
                                                    <div v-if="(investment.status==1)">
                                                        Accepted
                                                    </div>
                                                    <div v-if="(investment.status==2)">
                                                        Rejected
                                                    </div>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </div>
                                    </v-card>
                                  </v-flex>
                                </div>
                              </template>
                            </v-layout>
                          </div>
                        </div>
                      </div>
                    </v-flex>
                  </v-card>
                  <v-layout column class="mt-5">
                      <v-flex xs12>
                        <div align="left">
                          <v-pagination
                            v-if="length != 0"
                            :length="investmentFirmInvestmentLength"
                            total-visible="10"
                            v-model="
                              investmentFirmInvestmentPagination.current_page
                            "
                            @input="changeInvestmentFirmInvestmentPage()"
                            circle
                          >
                          </v-pagination>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div
                          v-if="investmentFirmInvestmentLength != 0"
                          align="center"
                          class="mt-5"
                        >
                          <b class="text--text">Total: </b
                          >{{
                            investmentFirmInvestmentPagination.total
                              | formatNumber
                          }}
                        </div>
                      </v-flex>
                    </v-layout>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-layout>
    </v-container>
    <logData v-if="logDataModel" v-model="logDataModel" />
  </div>
</template>
<style>
.ck-editor__editable {
  min-height: 200px;
}
</style>
<script>
import Vue from "vue";
import apiCall from "@/utils/api";
import { mapGetters, mapState, mapActions } from "vuex";
import logData from "@/views/accessControl/logData";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadAdapter from '@/uploadAdapter.js';

Vue.use(CKEditor);

export default {
  components: {
    logData
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,

      editor: ClassicEditor,
      editorConfig: {
          extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
              return new uploadAdapter(loader);
          } }],
          language: 'en',
      },

      importDialog: false,
      activeTab: "details",
      view: "default",
      years: [],
      investmentFirmAdministratorView: "default",
      investmentFirmInnovationView: "default",

      contactView: "default",
      loading: false,
      upgradeLoading: false,
      downgradeLoading: false,
      investmentFirmAdministratorLoader: false,
      investmentFirmInnovationLoader: false,
      investmentFirmInvestmentLoader: false,
      investmentFirmAdministratorIndex: null,

      activateLoading: false,
      deactivateLoading: false,

      valid: true,
      message: "",
      color: "",
      delete: false,
      activate: false,
      confirm: false,
      tab: null,
      investmentFirmTab: null,
      investmentFirmAdministratorIndex: null,
      investmentFirmInnovationIndex: null,
      investmentFirmIndex: null,

      snackbar: false,
      inputRules: [(v) => !!v || "Input is required"],

      emailRules: [
        (v) =>
          !v ||
          /^[a-zA-Z0-9\.!#$%&'*+/=?^_~-]+@([a-zA-Z0-9]+\.)+[a-zA-Z]{2,3}$/.test(
            v
          ) ||
          "E-mail must be valid",
        (v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
      ],
      websiteRules: [
        (v) =>
          !v ||
          /^[a-zA-Z0-9\.!#:$%&'*+/=?^_~-]+[a-zA-Z]{2,3}$/.test(v) ||
          "URL must be valid",
        (v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
      ],

      items: [],
      searchUsers: null,

      accessLevels: [
        {
          value: false,
          name: "Can Only View Investment Firm Details",
        },
        {
          value: true,
          name: "Can View & Edit Investment Firm Details",
        },
      ],

      searchTerm: null,
      investmentFirmAdministratorSearchTerm: null,
      investmentFirmInnovationSearchTerm: null,
      investmentFirmInvestmentSearchTerm: null,

      searchLoader: false,
      searchButtonDisabled: false,
      investmentFirmAdministratorSearchButtonDisabled: false,
      investmentFirmInnovationSearchButtonDisabled: false,
      investmentFirmInvestmentSearchButtonDisabled: false,

      filteredInvestmentFirms: false,
      filteredinvestmentFirmAdministrators: false,
      filteredInvestmentFirmInnovations: false,
      filteredInvestmentFirmInvestments: false,

      newInvestmentFirm: {
        id: null,
        name: null,
        brief: null,
        description: null,
        investor_type_id: null,
        establishment_year: null,
        physical_address: null,
        county_id: null,
      },

      newContact: {
        id: null,
        investment_firm_id: null,
        contact_person: null,
        email: null,
        email_2: null,
        phone: null,
        phone_2: null,
        web_url: null,
        fb_url: null,
        insta_url: null,
        twitter_url: null,
        visible: null,
      },

      newAdministrator: {
        id: null,
        investment_firm_id: null,
        user_id: null,
        write: null,
      },

      newInnovation: {
        id: null,
        investment_firm_id: null,
        innovation_id: null,
      },

      assignedInvestmentFirm: null,
      formData: null,

      coverImageDialog: false,
      imageDialog: false,
      imageLoading: false,
      fileRules: [(v) => !!v || "Kindly selecta a File"],
      imageFile: null,
      imageValid: true,

      searchTerm: null,
      searchLoader: false,
      investmentFirmAdministratorSearchLoader: false,
      investmentFirmInnovationSearchLoader: false,
      investmentFirmInvestmentSearchLoader: false,

      searchButtonDisabled: false,

      filteredInvestmentFirms: [],
      investmentFirmAdministrators: [],
      investmentFirmInnovations: [],
      investmentFirmInvestments: [],

      investmentFirmAdministratorPagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },
      investmentFirmInnovationPagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },
      investmentFirmInvestmentPagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },
      logData: {
        message: null,
        model: null,
      },
    };
  },
  watch: {
    searchUsers(val) {
      val && val !== this.newAdministrator.user_id && this.findUser(val);
    },
  },
  created() {
    if (this.$can("investment_firm_view")) {
      this.startInvestmentFirmLoader();
      this.fetchAdminInvestmentFirms(
        this.adminInvestmentFirmPagination.current_page
      );
      this.getFormData();
      this.populateYears();
    }
  },
  methods: {
    ...mapActions([
      "fetchAdminInvestmentFirms",
      "startInvestmentFirmLoader",
      "stopInvestmentFirmLoader",
      "filterInvestmentFirms",

      "filterLogData",
      "openLogDataModel",
    ]),
    intToString(value) {
      var suffixes = ["", "k", "M", "B", "T"];
      var suffixNum = Math.floor(("" + value).length / 3);
      var shortValue = parseFloat(
        (suffixNum != 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(2)
      );
      if (shortValue % 1 != 0) {
        shortValue = shortValue.toFixed(1);
      }
      return shortValue + suffixes[suffixNum];
    },
    populateYears() {
      var max = new Date().getFullYear();
      var min = max - 100;
      var years = [];

      for (var i = max; i >= min; i--) {
        years.push(i);
      }
      this.years = years;
    },
    findUser(v) {
      this.loading = true;
      // Simulated ajax query
      setTimeout(() => {
        apiCall({
          url: "/api/user?type=search&search=" + this.searchUsers,
          method: "GET",
        })
          .then((resp) => {
            this.items = resp.data;
          })
          .catch((error) => {
            this.loading = false;
          });

        this.loading = false;
      }, 3000);
    },
    showInvestmentFirmAdministrator(user) {
      this.$router.push("/user/" + user.code);
    },
    showInnovation(innovation) {
      console.log("innovation", innovation);
      this.$router.push("/innovations/" + innovation.slug);
    },
    enableSearch() {
      this.searchButtonDisabled = false;
    },

    enableInstitutionAdministratorSearch() {
      this.investmentFirmAdministratorSearchButtonDisabled = false;
    },
    enableInvestmentFirmInnovationSearch() {
      this.investmentFirmInnovationSearchButtonDisabled = false;
    },
    enableInvestmentFirmInvestmentSearch() {
      this.investmentFirmInvestmentSearchButtonDisabled = false;
    },
    resetSearch() {
      this.searchTerm = null;
      this.filteredInvestmentFirms = true;
      this.startInvestmentFirmLoader();
      this.fetchAdminInvestmentFirms(1);
    },

    resetInstitutionAdministratorSearch() {
      this.investmentFirmAdministratorSearchTerm = null;
      this.filteredinvestmentFirmAdministrators = false;
      this.investmentFirmAdministratorLoader = false;
      this.changeInvestmentFirmAdministratorPage();
    },

    resetInvestmentFirmInnovationSearch() {
      this.investmentFirmInnovationSearchTerm = null;
      this.filteredInvestmentFirmInnovations = false;
      this.investmentFirmInnovationLoader = false;
      this.changeInvestmentFirmInnovationPage();
    },

    resetInvestmentFirmInvestmentSearch() {
      this.investmentFirmInvestmentSearchTerm = null;
      this.filteredInvestmentFirmInvestments = false;
      this.investmentFirmInvestmentLoader = false;
      this.changeInvestmentFirmInvestmentPage();
    },

    changeInvestmentFirmAdministratorPage() {
      this.investmentFirmAdministratorLoader = true;
      if (this.filteredinvestmentFirmAdministrators == true) {
        this.investmentFirmAdministratorSearchButtonDisabled = false;
        apiCall({
          url:
            "/api/investment-firm-administrator?investment_firm_id=" +
            this.assignedInvestmentFirm.id +
            "&page=" +
            this.investmentFirmAdministratorPagination.current_page +
            "&type=search&search=" +
            this.investmentFirmAdministratorSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.investmentFirmAdministratorLoader = false;

            this.investmentFirmAdministrators = resp.data;
            this.investmentFirmAdministratorPagination.current_page =
              resp.current_page;
            this.investmentFirmAdministratorPagination.total = resp.total;
            this.investmentFirmAdministratorPagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.investmentFirmAdministratorLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        apiCall({
          url:
            "/api/investment-firm-administrator?investment_firm_id=" +
            this.assignedInvestmentFirm.id +
            "&page=" +
            this.investmentFirmAdministratorPagination.current_page,
          method: "GET",
        })
          .then((resp) => {
            this.investmentFirmAdministratorLoader = false;

            this.investmentFirmAdministrators = resp.data;
            this.investmentFirmAdministratorPagination.current_page =
              resp.current_page;
            this.investmentFirmAdministratorPagination.total = resp.total;
            this.investmentFirmAdministratorPagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.investmentFirmAdministratorLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    changeInvestmentFirmInnovationPage() {
      this.investmentFirmInnovationLoader = true;
      if (this.filteredInvestmentFirmInnovations == true) {
        this.investmentFirmInnovationSearchButtonDisabled = false;
        apiCall({
          url:
            "/api/innovation-investment-firm?investment_firm_id=" +
            this.assignedInvestmentFirm.id +
            "&page=" +
            this.investmentFirmInnovationPagination.current_page +
            "&type=search&search=" +
            this.investmentFirmInnovationSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.investmentFirmInnovationLoader = false;

            this.investmentFirmInnovations = resp.data;
            this.investmentFirmInnovationPagination.current_page =
              resp.current_page;
            this.investmentFirmInnovationPagination.total = resp.total;
            this.investmentFirmInnovationPagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.investmentFirmInnovationLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        apiCall({
          url:
            "/api/innovation-investment-firm?investment_firm_id=" +
            this.assignedInvestmentFirm.id +
            "&page=" +
            this.investmentFirmInnovationPagination.current_page,
          method: "GET",
        })
          .then((resp) => {
            this.investmentFirmInnovationLoader = false;

            this.investmentFirmInnovations = resp.data;
            this.investmentFirmInnovationPagination.current_page =
              resp.current_page;
            this.investmentFirmInnovationPagination.total = resp.total;
            this.investmentFirmInnovationPagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.investmentFirmInnovationLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    changeInvestmentFirmInvestmentPage() {
      this.investmentFirmInvestmentLoader = true;
      if (this.filteredInvestmentFirmInvestments == true) {
        this.investmentFirmInvestmentSearchButtonDisabled = false;
        apiCall({
          url:
            "/api/investment?type=investmentFirmInvestments&investment_firm_id=" +
            this.assignedInvestmentFirm.id +
            "&page=" +
            this.investmentFirmInvestmentPagination.current_page +
            "&type=search&search=" +
            this.investmentFirmInvestmentSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.investmentFirmInvestmentLoader = false;

            this.investmentFirmInvestments = resp.data;
            this.investmentFirmInvestmentPagination.current_page =
              resp.current_page;
            this.investmentFirmInvestmentPagination.total = resp.total;
            this.investmentFirmInvestmentPagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.investmentFirmInvestmentLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        apiCall({
          url:
            "/api/investment?type=investmentFirmInvestments&investment_firm_id=" +
            this.assignedInvestmentFirm.id +
            "&page=" +
            this.investmentFirmInvestmentPagination.current_page,
          method: "GET",
        })
          .then((resp) => {
            this.investmentFirmInvestmentLoader = false;

            this.investmentFirmInvestments = resp.data;
            this.investmentFirmInvestmentPagination.current_page =
              resp.current_page;
            this.investmentFirmInvestmentPagination.total = resp.total;
            this.investmentFirmInvestmentPagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.investmentFirmInvestmentLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    getText(item) {
      return (
        item.f_name + " " + item.m_name + " " + item.l_name + " #" + item.code
      );
    },
    showInvestmentFirm(investmentFirm) {
      this.assignedInvestmentFirm = investmentFirm;
      this.newContact.investment_firm_id = investmentFirm.id;

      if (this.$can("investment_firm_administrator_view")) {
        this.changeInvestmentFirmAdministratorPage();
      }
      // if (this.$can("institution_innovation_view")) {
      this.changeInvestmentFirmInnovationPage();
      this.changeInvestmentFirmInvestmentPage();
      // }

      this.changeView("show");
    },

    editInvestmentFirm(investmentFirm) {
      this.newInvestmentFirm.id = investmentFirm.id;
      this.newInvestmentFirm.name = investmentFirm.name;
      this.newInvestmentFirm.establishment_year = parseInt(
        investmentFirm.establishment_year
      );
      this.newInvestmentFirm.investor_type_id = investmentFirm.investor_type_id;
      this.newInvestmentFirm.county_id = investmentFirm.county_id;
      this.newInvestmentFirm.brief = investmentFirm.brief;
      this.newInvestmentFirm.description = investmentFirm.description;
      this.newInvestmentFirm.physical_address = investmentFirm.physical_address;

      this.changeView("create");
    },
    editContact(contact) {
      this.newContact.id = contact.id;
      this.newContact.contact_person = contact.contact_person;
      this.newContact.email = contact.email;
      this.newContact.email_2 = contact.email_2;
      this.newContact.phone = contact.phone;
      this.newContact.phone_2 = contact.phone_2;
      this.newContact.web_url = contact.web_url;
      this.newContact.fb_url = contact.fb_url;
      this.newContact.insta_url = contact.insta_url;
      this.newContact.twitter_url = contact.twitter_url;
      this.newContact.visible = contact.visible;
      this.contactView = "create";
    },
    uploadImage() {
      if (this.$refs.imageForm.validate()) {
        this.imageLoading = true;
        let formData = new FormData();

        // files

        formData.append("files", this.imageFile, this.imageFile.name);
        formData.append("id", this.assignedInvestmentFirm.id);

        apiCall({
          url: "/api/investment-firm?type=image",
          data: formData,
          method: "POST",
        })
          .then((resp) => {
            this.snackbar = true;
            this.message = "Logo Uploaded Successfully";
            this.color = "success";
            this.fetchAdminInvestmentFirms(
              this.adminInvestmentFirmPagination.current_page
            );
            this.imageLoading = false;
            this.imageDialog = false;
            this.imageFile = null;
            this.assignedInvestmentFirm.logo = resp.logo;
            // this.changeView('default')
          })
          .catch((error) => {
            this.imageLoading = false;
          });
      }
    },
    uploadCoverImage() {
      if (this.$refs.imageForm.validate()) {
        this.imageLoading = true;
        let formData = new FormData();

        // files

        formData.append("files", this.imageFile, this.imageFile.name);
        formData.append("id", this.assignedInvestmentFirm.id);

        apiCall({
          url: "/api/investment-firm?type=coverImage",
          data: formData,
          method: "POST",
        })
          .then((resp) => {
            this.snackbar = true;
            this.message = "Cover Image Uploaded Successfully";
            this.color = "success";
            this.fetchAdminInvestmentFirms(
              this.adminInvestmentFirmPagination.current_page
            );
            this.imageLoading = false;
            this.coverImageDialog = false;
            this.imageFile = null;
            this.assignedInvestmentFirm.cover_image = resp.cover_image;
            // this.changeView('default')
          })
          .catch((error) => {
            this.imageLoading = false;
          });
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.newInvestmentFirm.id != null) {
          confirm("Are you sure you want to update investment-firm?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/investment-firm/" + this.newInvestmentFirm.id,
              data: this.newInvestmentFirm,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Investment Firm Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeView("default");
                this.fetchAdminInvestmentFirms(1);
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm("Are you sure you want to create Investment Firm?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/investment-firm",
              data: this.newInvestmentFirm,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Investment Firm Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeView("default");
                this.fetchAdminInvestmentFirms(1);
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
                this.changeView("default");
                this.fetchAdminInvestmentFirms(1);
              });
          }
        }
      }
    },
    saveContact() {
      if (this.$refs.contactForm.validate()) {
        if (this.newContact.id != null) {
          confirm("Are you sure you want to update contact information?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/investment-firm-contact/" + this.newContact.id,
              data: this.newContact,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Contact Information Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.$nextTick(() => {
                  this.assignedInvestmentFirm.investment_firm_contact = resp;
                });

                this.changeContactView("default");

                this.fetchAdminInvestmentFirms(1);
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm("Are you sure you want to create contact information?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/investment-firm-contact",
              data: this.newContact,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Contact Information Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeContactView("default");
                this.assignedInvestmentFirm.investment_firm_contact = resp;
                this.fetchAdminInvestmentFirms(1);
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
                this.fetchAdminInvestmentFirms(1);
              });
          }
        }
      }
    },

    saveInvestmentFirmAdministrator() {
      if (this.$refs.investmentFirmAdministratorForm.validate()) {
        confirm(
          "Are you sure you want to add Investment Firm administrator?"
        ) && (this.confirm = true);
        if (this.confirm) {
          this.loading = true;
          this.newAdministrator.investment_firm_id =
            this.assignedInvestmentFirm.id;
          apiCall({
            url: "/api/investment-firm-administrator",
            data: this.newAdministrator,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Investment Firm Administrator Added Successfully";
              this.color = "success";
              this.loading = false;
              this.snackbar = true;
              this.confirm = false;
              this.newAdministrator.user_id = null;
              this.changeInvestmentFirmAdministratorView("default");
              this.changeInvestmentFirmAdministratorPage();
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.loading = false;
              this.snackbar = true;
            });
        }
      }
    },

    search() {
      if (this.searchTerm == null) {
        this.searchButtonDisabled = true;
      } else {
        this.searchLoader = true;
        this.searchButtonDisabled = false;
        this.startInvestmentFirmLoader();
        apiCall({
          url: "/api/investment-firm?type=search&search=" + this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterInvestmentFirms(resp);
            this.searchLoader = false;
            this.stopInvestmentFirmLoader();
            this.filteredInvestmentFirms = true;
          })
          .catch((error) => {
            console.log(error);
            this.searchLoader = false;
            this.stopInvestmentFirmLoader();
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },

    investmentFirmAdministratorSearch() {
      if (this.investmentFirmAdministratorSearchTerm == null) {
        this.investmentFirmAdministratorSearchButtonDisabled = true;
      } else {
        this.investmentFirmAdministratorSearchLoader = true;
        this.investmentFirmAdministratorSearchButtonDisabled = false;
        this.startInvestmentFirmLoader();
        apiCall({
          url:
            "/api/investment-firm-administrator?type=search&search=" +
            this.investmentFirmAdministratorSearchTerm +
            "&investment_firm_id=" +
            this.assignedInvestmentFirm.id,
          method: "GET",
        })
          .then((resp) => {
            this.investmentFirmAdministrators = resp.data;
            this.investmentFirmAdministratorPagination.current_page =
              resp.current_page;
            this.investmentFirmAdministratorPagination.total = resp.total;
            this.investmentFirmAdministratorPagination.per_page = resp.per_page;

            this.investmentFirmAdministratorSearchLoader = false;
            this.filteredinvestmentFirmAdministrators = true;
          })
          .catch((error) => {
            this.investmentFirmAdministratorSearchLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            console.log(error);
          });
      }
    },
    investmentFirmInnovationSearch() {
      if (this.investmentFirmInnovationSearchTerm == null) {
        this.investmentFirmInnovationSearchButtonDisabled = true;
      } else {
        this.investmentFirmInnovationSearchLoader = true;
        this.investmentFirmInnovationSearchButtonDisabled = false;
        this.startInvestmentFirmLoader();
        apiCall({
          url:
            "/api/investment-firm-user?type=search&search=" +
            this.investmentFirmInnovationSearchTerm +
            "&investment_firm_id=" +
            this.assignedInvestmentFirm.id,
          method: "GET",
        })
          .then((resp) => {
            this.investmentFirm = resp.data;
            this.investmentFirmInnovationPagination.current_page =
              resp.current_page;
            this.investmentFirmInnovationPagination.total = resp.total;
            this.investmentFirmInnovationPagination.per_page = resp.per_page;

            this.investmentFirmInnovationSearchLoader = false;
          })
          .catch((error) => {
            this.investmentFirmInnovationSearchLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            console.log(error);
          });
      }
    },
    investmentFirmInvestmentSearch() {
      if (this.investmentFirmInvestmentSearchTerm == null) {
        this.investmentFirmInvestmentSearchButtonDisabled = true;
      } else {
        this.investmentFirmInvestmentSearchLoader = true;
        this.investmentFirmInvestmentSearchButtonDisabled = false;
        this.startInvestmentFirmLoader();
        apiCall({
          url:
            "/api/investment?type=investmentFirmInvestments&typeB=search&search=" +
            this.investmentFirmInvestmentSearchTerm +
            "&investment_firm_id=" +
            this.assignedInvestmentFirm.id,
          method: "GET",
        })
          .then((resp) => {
            this.investmentFirmInvestments = resp.data;
            this.investmentFirmInvestmentPagination.current_page =
              resp.current_page;
            this.investmentFirmInvestmentPagination.total = resp.total;
            this.investmentFirmInvestmentPagination.per_page = resp.per_page;

            this.investmentFirmInvestmentSearchLoader = false;
          })
          .catch((error) => {
            this.investmentFirmInvestmentSearchLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            console.log(error);
          });
      }
    },
    activateInvestmentFirm(item) {
      confirm("Are You Sure You Want to Activate Investment Firm?") &&
        (this.activate = true);
      if (this.activate) {
        this.activateLoading = true;
        this.investmentFirmIndex = item.id;
        this.newInvestmentFirm.id = item.id;
        apiCall({
          url: "/api/investment-firm?type=activate",
          data: this.newInvestmentFirm,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Investment Firm Account Activated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.investmentFirmIndex = null;
            this.fetchAdminInvestmentFirms(
              this.adminInvestmentFirmPagination.current_page
            );
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.InstitutionIndex = null;
            console.log(error);
          });
      }
    },
    deactivateInvestmentFirm(item) {
      confirm("Are You Sure You Want to Deactivate Investment Firm?") &&
        (this.activate = true);
      if (this.activate) {
        this.deactivateLoading = true;
        this.investmentFirmIndex = item.id;
        this.newInvestmentFirm.id = item.id;
        apiCall({
          url: "/api/investment-firm?type=deactivate",
          data: this.newInvestmentFirm,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Investment Firm Account Deactivated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.investmentFirmIndex = null;
            this.fetchAdminInvestmentFirms(
              this.adminInvestmentFirmPagination.current_page
            );
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.investmentFirmIndex = null;
            console.log(error);
          });
      }
    },

    upgradeInvestmentFirmAdministrator(item) {
      confirm(
        "Are You Sure You Want to Upgrade Investment Firm Administrator?"
      ) && (this.activate = true);
      if (this.activate) {
        this.upgradeLoading = true;
        this.investmentFirmAdministratorIndex = item.id;
        this.newAdministrator.id = item.id;
        apiCall({
          url: "/api/investment-firm-administrator?type=upgrade",
          data: this.newAdministrator,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Investment Firm Administrator Upgraded Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.upgradeLoading = false;
            this.activate = false;
            this.investmentFirmAdministratorIndex = null;
            this.changeInvestmentFirmAdministratorPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.upgradeLoading = false;
            this.activate = false;
            this.investmentFirmAdministratorIndex = null;
            console.log(error);
          });
      }
    },
    downgradeInnovationAdministrator(item) {
      confirm(
        "Are You Sure You Want to Downgrade Investment Firm Administrator?"
      ) && (this.activate = true);
      if (this.activate) {
        this.downgradeLoading = true;
        this.investmentFirmAdministratorIndex = item.id;
        this.newAdministrator.id = item.id;
        apiCall({
          url: "/api/investment-firm-administrator?type=downgrade",
          data: this.newAdministrator,
          method: "POST",
        })
          .then((resp) => {
            this.message =
              "Investment Firm Administrator Downgraded Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.downgradeLoading = false;
            this.activate = false;
            this.investmentFirmAdministratorIndex = null;
            this.changeInvestmentFirmAdministratorPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.downgradeLoading = false;
            this.activate = false;
            this.investmentFirmAdministratorIndex = null;
            console.log(error);
          });
      }
    },

    activateInvestmentFirmAdministrator(item) {
      confirm("Are You Sure You Want to Activate Institution Administrator?") &&
        (this.activate = true);
      if (this.activate) {
        this.activateLoading = true;
        this.investmentFirmAdministratorIndex = item.id;
        this.newAdministrator.id = item.id;
        apiCall({
          url: "/api/investment-firm-administrator?type=activate",
          data: this.newAdministrator,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Institution Administrator Activated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.investmentFirmAdministratorIndex = null;
            this.changeInvestmentFirmAdministratorPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.investmentFirmAdministratorIndex = null;
            console.log(error);
          });
      }
    },
    deactivateInvestmentFirmAdministrator(item) {
      confirm(
        "Are You Sure You Want to Deactivate Institution Administrator?"
      ) && (this.activate = true);
      if (this.activate) {
        this.deactivateLoading = true;
        this.investmentFirmAdministratorIndex = item.id;
        this.newAdministrator.id = item.id;
        apiCall({
          url: "/api/investment-firm-administrator?type=deactivate",
          data: this.newAdministrator,
          method: "POST",
        })
          .then((resp) => {
            this.message =
              "Investment Firm Administrator Deactivated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.investmentFirmAdministratorIndex = null;
            this.changeInvestmentFirmAdministratorPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.investmentFirmAdministratorIndex = null;
            console.log(error);
          });
      }
    },
    changeView(view) {
      if (view == "default") {
        this.newInvestmentFirm.id = null;
        this.newInvestmentFirm.name = null;
        this.newInvestmentFirm.brief = null;
        this.newInvestmentFirm.description = null;
        this.newInvestmentFirm.investor_type_id = null;
        this.newInvestmentFirm.establishment_year = null;
        this.newInvestmentFirm.physical_address = null;
        this.newInvestmentFirm.county_id = null;
      } else if (view == "create") {
        this.getFormData();
      } else if (view == "show") {
      }
      this.view = view;
    },

    changeContactView(view) {
      if (view == "default") {
        this.newContact.id = null;
        this.newContact.telephone = null;
        this.newContact.mobile = null;
        this.newContact.email = null;
        this.newContact.website = null;
      } else if (view == "create") {
        //this.getFormData();
      } else if (view == "show") {
      }
      this.contactView = view;
    },

    changeInvestmentFirmAdministratorView(view) {
      if (view == "default") {
        this.newAdministrator.id = null;
        this.newAdministrator.investment_firm_id = null;
        this.newAdministrator.user_id = null;
      } else if (view == "create") {
      } else if (view == "show") {
      }
      this.investmentFirmAdministratorView = view;
    },
    changePage() {
      this.startInvestmentFirmLoader();
      if (this.filteredInvestmentFirms == true) {
        this.searchButtonDisabled = false;
        apiCall({
          url:
            "/api/investment-firm?page=" +
            this.adminInvestmentFirmPagination.current_page +
            "&type=search&search=" +
            this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterInvestmentFirms(resp);
            this.stopInvestmentFirmLoader();
          })
          .catch((error) => {
            console.log(error);
            this.stopInvestmentFirmLoader();
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        this.fetchAdminInvestmentFirms(
          this.adminInvestmentFirmPagination.current_page
        );
      }
    },
    deleteInvestmentFirm(item) {
      confirm("Are You Sure You Want to Delete Investment Firm") &&
        (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.investmentFirmIndex = item.id;
        apiCall({ url: "/api/investment-firm/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "Investment Firm Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.investmentFirmIndex = null;
            this.fetchAdminInvestmentFirms(
              this.adminInvestmentFirmPagination.current_page
            );
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.investmentFirmIndex = null;
            console.log(error);
          });
      }
    },
    deleteContact(item) {
      confirm("Are You Sure You Want to Delete Contact Information?") &&
        (this.delete = true);
      if (this.delete) {
        this.loading = true;
        apiCall({
          url: "/api/investment-firm-contact/" + item.id,
          method: "DELETE",
        })
          .then((resp) => {
            this.message = "Contact Information Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.$nextTick(() => {
              this.assignedInvestmentFirm.investment_firm_contact = null;
            });
            this.fetchAdminInvestmentFirms(
              this.adminInvestmentFirmPagination.current_page
            );
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.investmentFirmIndex = null;
            console.log(error);
          });
      }
    },
    deleteInvestmentFirmAdministrator(item) {
      confirm("Are You Sure You Want to Remove Administrator?") &&
        (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.investmentFirmAdministratorIndex = item.id;
        apiCall({
          url: "/api/investment-firm-administrator/" + item.id,
          method: "DELETE",
        })
          .then((resp) => {
            this.message = "Administrator Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.investmentFirmAdministratorIndex = null;
            this.changeInvestmentFirmAdministratorPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.investmentFirmAdministratorIndex = null;
            console.log(error);
          });
      }
    },
    deleteInstitutionInnovation(item) {
      confirm("Are You Sure You Want to Remove Innovation?") &&
        (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.investmentFirmInnovationIndex = item.id;
        apiCall({
          url: "/api/innovation-institution/" + item.id,
          method: "DELETE",
        })
          .then((resp) => {
            this.message = "Innovation Association Removed Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.investmentFirmInnovationIndex = null;
            this.changeInvestmentFirmInnovationPage();
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.investmentFirmInnovationIndex = null;
            console.log(error);
          });
      }
    },
    getFormData() {
      apiCall({
        url: "/api/investment-firm?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
    viewLog(data) {
      this.logData.message = data.id;
      this.logData.model = "App\\Models\\InvestmentFirm";
      this.filterLogData(this.logData);
      this.openLogDataModel();
    },
  },
  computed: {
    ...mapGetters([
      "adminInvestmentFirms",
      "adminInvestmentFirmPagination",
      "investmentFirmLoader",
      "logDataModel",
    ]),
    length: function () {
      return Math.ceil(
        this.adminInvestmentFirmPagination.total /
          this.adminInvestmentFirmPagination.per_page
      );
    },

    investmentFirmAdministratorLength: function () {
      return Math.ceil(
        this.investmentFirmAdministratorPagination.total /
          this.investmentFirmAdministratorPagination.per_page
      );
    },
    investmentFirmInnovationLength: function () {
      return Math.ceil(
        this.investmentFirmInnovationPagination.total /
          this.investmentFirmInnovationPagination.per_page
      );
    },
    investmentFirmInvestmentLength: function () {
      return Math.ceil(
        this.investmentFirmInvestmentPagination.total /
          this.investmentFirmInvestmentPagination.per_page
      );
    },
  },
};
</script>
