import apiCall from '@/utils/api'

const state = {
	organisations: [],
	allOrganisations: [],
    organisationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    organisationLoader: false
};

const getters = {
	organisations: (state) => state.organisations,
	allOrganisations: (state) => state.allOrganisations,
    organisationLoader: (state) => state.organisationLoader,
    organisationPagination: (state) => state.organisationPagination
}

const actions = {
	async fetchAllOrganisations({commit}, page) {
		const response = await apiCall({url: `/api/organisation?type=all`, method: 'GET' });
		commit('setAllOrganisations', response)
        commit('stopLoader', response)
	},
    async fetchOrganisations({commit}, page) {
		const response = await apiCall({url: `/api/organisation?page=${page}`, method: 'GET' });
		commit('setOrganisations', response)
        commit('stopLoader', response)
	},
    async filterOrganisations({commit, state},resp){
		commit('setOrganisations', resp)
	},
	async startOrganisationLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopOrganisationLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setOrganisations: (state, organisations) => {
		state.organisations = organisations.data
		state.organisationPagination.current_page = organisations.current_page
		state.organisationPagination.total = organisations.total
		state.organisationPagination.per_page = organisations.per_page
	},
	setAllOrganisations: (state, organisations) => {
		state.allOrganisations = organisations
	},
    startLoader: (state) => state.organisationLoader = true,
	stopLoader: (state) => state.organisationLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
