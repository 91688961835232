import apiCall from '@/utils/api'

const state = {
	esos: [],
	allEsos: [],
	publicEsos: [],
    esoPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	publicEsoPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    esoLoader: false
};

const getters = {
	esos: (state) => state.esos,
	publicEsos: (state) => state.publicEsos,
	allEsos: (state) => state.allEsos,
    esoLoader: (state) => state.esoLoader,
    esoPagination: (state) => state.esoPagination,
	publicEsoPagination: (state) => state.publicEsoPagination,
}

const actions = {
	async fetchAllEsos({commit}, page) {
		const response = await apiCall({url: `/api/eso?type=all`, method: 'GET' });
		commit('setAllEsos', response)
        commit('stopLoader', response)
	},
    async fetchEsos({commit}, page) {
		const response = await apiCall({url: `/api/eso?page=${page}`, method: 'GET' });
		commit('setEsos', response)
        commit('stopLoader', response)
	},
	async fetchPublicEsos({commit}, page) {
		const response = await apiCall({url: `/api/eso?type=public&page=${page}`, method: 'GET' });
		commit('setPublicEsos', response)
        commit('stopPublicEsoLoader', response)
	},
	async filterPublicEsos({commit, state},resp){
		commit('setPublicEsos', resp)
	},
    async filterEsos({commit, state},resp){
		commit('setEsos', resp)
	},
	async startEsoLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopEsoLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setEsos: (state, esos) => {
		state.esos = esos.data
		state.esoPagination.current_page = esos.current_page
		state.esoPagination.total = esos.total
		state.esoPagination.per_page = esos.per_page
	},
	setPublicEsos: (state, publicEsos) => {
		state.publicEsos = publicEsos.data
		state.publicEsoPagination.current_page = publicEsos.current_page
		state.publicEsoPagination.total = publicEsos.total
		state.publicEsoPagination.per_page = publicEsos.per_page
	},
	setAllEsos: (state, esos) => {
		state.allEsos = esos
	},
    startLoader: (state) => state.esoLoader = true,
	stopLoader: (state) => state.esoLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
