import apiCall from '@/utils/api'

const state = {
	sdgs: [],
	allSdgs: [],
    sdgPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    sdgLoader: false
};

const getters = {
	sdgs: (state) => state.sdgs,
	allSdgs: (state) => state.allSdgs,
    sdgLoader: (state) => state.sdgLoader,
    sdgPagination: (state) => state.sdgPagination
}

const actions = {
	async fetchAllSdgs({commit}, page) {
		const response = await apiCall({url: `/api/sdg?type=all`, method: 'GET' });
		commit('setAllSdgs', response)
        commit('stopLoader', response)
	},
    async fetchSdgs({commit}, page) {
		const response = await apiCall({url: `/api/sdg?page=${page}`, method: 'GET' });
		commit('setSdgs', response)
        commit('stopLoader', response)
	},
    async filterSdgs({commit, state},resp){
		commit('setSdgs', resp)
	},
	async startSdgLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopSdgLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setSdgs: (state, sdgs) => {
		state.sdgs = sdgs.data
		state.sdgPagination.current_page = sdgs.current_page
		state.sdgPagination.total = sdgs.total
		state.sdgPagination.per_page = sdgs.per_page
	},
	setAllSdgs: (state, sdgs) => {
		state.allSdgs = sdgs
	},
    startLoader: (state) => state.sdgLoader = true,
	stopLoader: (state) => state.sdgLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
