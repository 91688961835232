import apiCall from '@/utils/api'

const state = {
	innovations: [],
	searchInnovations: [],
    interestInnovations: [],
    recommendedInnovations: [],
    popularInnovations: [],
    recentInnovations: [],

	investorTopRankedInnovations: [],
	investorRecentlyViewedInnovations: [],
	investorRecommendedInnovations: [],
	investorSavedInnovations: [],
	investorNoteInnovations: [],
	investorDealInnovations: [],

    innovationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	searchInnovationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    interestInnovationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    recommendedInnovationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    popularInnovationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    recentInnovationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},


	investorTopRankedInnovationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    investorRecentlyViewedInnovationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    investorRecommendedInnovationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	investorSavedInnovationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	investorNoteInnovationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	investorDealInnovationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},

    innovationLoader: false,
	searchInnovationLoader: false,
    interestInnovationLoader: false,
    recommendedInnovationLoader: false,
    popularInnovationLoader: false,
    recentInnovationLoader: false,

	investorTopRankedInnovationLoader: false,
    investorRecentlyViewedInnovationLoader: false,
    investorRecommendedInnovationLoader: false,
	investorSavedInnovationLoader: false,
	investorNoteInnovationLoader: false,
	investorDealInnovationLoader: false,

	innovationApprovalShow: false,
	innovationData: {}
};

const getters = {
	innovations: (state) => state.innovations,
	innovationLoader: (state) => state.innovationLoader,	
    innovationPagination: (state) => state.innovationPagination,

	searchInnovations: (state) => state.searchInnovations,
	searchInnovationLoader: (state) => state.searchInnovationLoader,	
    searchInnovationPagination: (state) => state.searchInnovationPagination,

	interestInnovations: (state) => state.interestInnovations,
	interestInnovationLoader: (state) => state.interestInnovationLoader,
	interestInnovationPagination: (state) => state.interestInnovationPagination,

	recommendedInnovations: (state) => state.recommendedInnovations,
	recommendedInnovationLoader: (state) => state.recommendedInnovationLoader,
	recommendedInnovationPagination: (state) => state.recommendedInnovationPagination,

	popularInnovations: (state) => state.popularInnovations,
	popularInnovationLoader: (state) => state.popularInnovationLoader,
	popularInnovationPagination: (state) => state.popularInnovationPagination,

	recentInnovations: (state) => state.recentInnovations,
	recentInnovationLoader: (state) => state.recentInnovationLoader,
	recentInnovationPagination: (state) => state.recentInnovationPagination,

	investorTopRankedInnovations: (state) => state.investorTopRankedInnovations,
	investorTopRankedInnovationLoader: (state) => state.investorTopRankedInnovationLoader,
	investorTopRankedInnovationPagination: (state) => state.investorTopRankedInnovationPagination,

	investorRecentlyViewedInnovations: (state) => state.investorRecentlyViewedInnovations,
	investorRecentlyViewedInnovationLoader: (state) => state.investorRecentlyViewedInnovationLoader,
	investorRecentlyViewedInnovationPagination: (state) => state.investorRecentlyViewedInnovationPagination,

	investorRecommendedInnovations: (state) => state.investorRecommendedInnovations,
	investorRecommendedInnovationLoader: (state) => state.investorRecommendedInnovationLoader,
	investorRecommendedInnovationPagination: (state) => state.investorRecommendedInnovationPagination,
	
	investorSavedInnovations: (state) => state.investorSavedInnovations,
	investorSavedInnovationLoader: (state) => state.investorSavedInnovationLoader,
	investorSavedInnovationPagination: (state) => state.investorSavedInnovationPagination,

	investorNoteInnovations: (state) => state.investorNoteInnovations,
	investorNoteInnovationLoader: (state) => state.investorNoteInnovationLoader,
	investorNoteInnovationPagination: (state) => state.investorNoteInnovationPagination,

	investorDealInnovations: (state) => state.investorDealInnovations,
	investorDealInnovationLoader: (state) => state.investorDealInnovationLoader,
	investorDealInnovationPagination: (state) => state.investorDealInnovationPagination,

	innovationApprovalState: (state) => state.innovationApprovalShow,
	innovationData: (state) => state.innovationData,
}

const actions = {
    async fetchInnovations({commit}, page) {
		const response = await apiCall({url: `/api/innovation?page=${page}`, method: 'GET' });
		commit('setInnovations', response)
        commit('stopInnovationLoader', response)
	},
	async fetchSearchInnovations({commit}, page) {
		const response = await apiCall({url: `/api/innovation?page=${page}`, method: 'GET' });
		commit('setSearchInnovations', response)
        commit('stopSearchInnovationLoader', response)
	},
    async fetchInterestInnovations({commit}, page) {
		const response = await apiCall({url: `/api/innovation?type=interest&page=${page}`, method: 'GET' });
		commit('setInterestInnovations', response)
        commit('stopInterestInnovationLoader', response)
	},
    async fetchRecommendedInnovations({commit}, page) {
		const response = await apiCall({url: `/api/innovation?type=recommended&page=${page}`, method: 'GET' });
		commit('setRecommendedInnovations', response)
        commit('stopRecommendedInnovationLoader', response)
	},
    async fetchPopularInnovations({commit}, page) {
		const response = await apiCall({url: `/api/innovation?type=popular&page=${page}`, method: 'GET' });
		commit('setPopularInnovations', response)
        commit('stopPopularInnovationLoader', response)
	},
    async fetchRecentInnovations({commit}, page) {
		const response = await apiCall({url: `/api/innovation?type=recent&page=${page}`, method: 'GET' });
		commit('setRecentInnovations', response)
        commit('stopRecentInnovationLoader', response)
	},

	async fetchInvestorTopRankedInnovations({commit}, page) {
		const response = await apiCall({url: `/api/innovation?type=investorTopRanked&page=${page}`, method: 'GET' });
		commit('setInvestorTopRankedInnovations', response)
        commit('stopInvestorTopRankedInnovationLoader', response)
	},
    async fetchInvestorRecentlyVieweds({commit}, page) {
		const response = await apiCall({url: `/api/innovation?type=investorRecentlyViewed&page=${page}`, method: 'GET' });
		commit('setInvestorRecentlyViewedInnovations', response)
        commit('stopInvestorRecentlyViewedInnovationLoader', response)
	},
    async fetchInvestorRecommendedInnovations({commit}, page) {
		const response = await apiCall({url: `/api/innovation?type=investorRecommended&page=${page}`, method: 'GET' });
		commit('setInvestorRecommendedInnovations', response)
        commit('stopInvestorRecommendedInnovationLoader', response)
	},
	async fetchInvestorSavedInnovations({commit}, page) {
		const response = await apiCall({url: `/api/innovation?type=investorSaved&page=${page}`, method: 'GET' });
		commit('setInvestorSavedInnovations', response)
        commit('stopInvestorSavedInnovationLoader', response)
	},
	async fetchInvestorNoteInnovations({commit}, page) {
		const response = await apiCall({url: `/api/investor-innovation-note?type=investor&page=${page}`, method: 'GET' });
		commit('setInvestorNoteInnovations', response)
        commit('stopInvestorNoteInnovationLoader', response)
	},
	async fetchInvestorDealInnovations({commit}, page) {
		const response = await apiCall({url: `/api/investment?type=investor&page=${page}`, method: 'GET' });
		commit('setInvestorDealInnovations', response)
        commit('stopInvestorDealInnovationLoader', response)
	},

    async filterInnovations({commit, state},resp){
		commit('setInnovations', resp)
	},
	async filterSearchInnovations({commit, state},resp){
		commit('setSearchInnovations', resp)
	},
    async filterInterestInnovations({commit, state},resp){
		commit('setInterestInnovations', resp)
	},
    async filterRecommendedInnovations({commit, state},resp){
		commit('setRecommendedInnovations', resp)
	},
    async filterPopularInnovations({commit, state},resp){
		commit('setPopularInnovations', resp)
	},
    async filterRecentInnovations({commit, state},resp){
		commit('setRecentInnovations', resp)
	},

	async startInnovationLoader({commit, state},resp){
		commit('startInnovationLoader', resp)
	},
	async stopInnovationLoader({commit, state},resp){
		commit('stopInnovationLoader', resp)
	},
	async startSearchInnovationLoader({commit, state},resp){
		commit('startSearchInnovationLoader', resp)
	},
	async stopSearchInnovationLoader({commit, state},resp){
		commit('stopSearchInnovationLoader', resp)
	},
    async startInterestInnovationLoader({commit, state},resp){
		commit('startInterestInnovationLoader', resp)
	},
	async stopInterestInnovationLoader({commit, state},resp){
		commit('stopInterestInnovationLoader', resp)
	},
    async startRecommendedInnovationLoader({commit, state},resp){
		commit('startRecommendedInnovationLoader', resp)
	},
	async stopRecommendedInnovationLoader({commit, state},resp){
		commit('stopRecommendedInnovationLoader', resp)
	},
    async startPopularInnovationLoader({commit, state},resp){
		commit('startPopularInnovationLoader', resp)
	},
	async stopPopularInnovationLoader({commit, state},resp){
		commit('stopPopularInnovationLoader', resp)
	},
    async startRecentInnovationLoader({commit, state},resp){
		commit('startRecentInnovationLoader', resp)
	},
	async stopRecentInnovationLoader({commit, state},resp){
		commit('stopRecentInnovationLoader', resp)
	},

	async startInvestorTopRankedInnovationLoader({commit, state},resp){
		commit('startInvestorTopRankedInnovationLoader', resp)
	},
	async stopInvestorTopRankedInnovationLoader({commit, state},resp){
		commit('stopInvestorTopRankedInnovationLoader', resp)
	},
	async startInvestorRecentlyViewedInnovationLoader({commit, state},resp){
		commit('startInvestorRecentlyViewedInnovationLoader', resp)
	},
	async stopInvestorRecentlyViewedInnovationLoader({commit, state},resp){
		commit('stopInvestorRecentlyViewedInnovationLoader', resp)
	},
	async startInvestorRecommendedInnovationLoader({commit, state},resp){
		commit('startInvestorRecommendedInnovationLoader', resp)
	},
	async stopInvestorRecommendedInnovationLoader({commit, state},resp){
		commit('stopInvestorRecommendedInnovationLoader', resp)
	},
	async startInvestorSavedInnovationLoader({commit, state},resp){
		commit('startInvestorSavedInnovationLoader', resp)
	},
	async stopInvestorSavedInnovationLoader({commit, state},resp){
		commit('stopInvestorSavedInnovationLoader', resp)
	},
	async startInvestorDealInnovationLoader({commit, state},resp){
		commit('startInvestorDealInnovationLoader', resp)
	},
	async stopInvestorDealInnovationLoader({commit, state},resp){
		commit('stopInvestorDealInnovationLoader', resp)
	},
	async startInvestorDealInnovationLoader({commit, state},resp){
		commit('startInvestorDealInnovationLoader', resp)
	},
	async stopInvestorDealInnovationLoader({commit, state},resp){
		commit('stopInvestorDealInnovationLoader', resp)
	},

	async changeInnovationApprovalCardState({commit}) {
		commit('updateInnovationApprovalCard')
	},
	async assignInnovationData({commit, state},resp){
		commit('setInnovationData', resp)
	},
};

const mutations = {
	setInnovations: (state, innovations) => {
		state.innovations = innovations.data
		state.innovationPagination.current_page = innovations.current_page
		state.innovationPagination.total = innovations.total
		state.innovationPagination.per_page = innovations.per_page
	},
    startInnovationLoader: (state) => state.innovationLoader = true,
	stopInnovationLoader: (state) => state.innovationLoader = false,

	setSearchInnovations: (state, innovations) => {
		state.searchInnovations = innovations.data
		state.searchInnovationPagination.current_page = innovations.current_page
		state.searchInnovationPagination.total = innovations.total
		state.searchInnovationPagination.per_page = innovations.per_page
	},
    startSearchInnovationLoader: (state) => state.searchInnovationLoader = true,
	stopSearchInnovationLoader: (state) => state.searchInnovationLoader = false,

    setInterestInnovations: (state, innovations) => {
		state.interestInnovations = innovations.data
		state.interestInnovationPagination.current_page = innovations.current_page
		state.interestInnovationPagination.total = innovations.total
		state.interestInnovationPagination.per_page = innovations.per_page
	},
    startInterestInnovationLoader: (state) => state.interestInnovationLoader = true,
	stopInterestInnovationLoader: (state) => state.interestInnovationLoader = false,

    setRecommendedInnovations: (state, innovations) => {
		state.recommendedInnovations = innovations.data
		state.recommendedInnovationPagination.current_page = innovations.current_page
		state.recommendedInnovationPagination.total = innovations.total
		state.recommendedInnovationPagination.per_page = innovations.per_page
	},
    startRecommendedInnovationLoader: (state) => state.recommendedInnovationLoader = true,
	stopRecommendedInnovationLoader: (state) => state.recommendedInnovationLoader = false,

    setPopularInnovations: (state, innovations) => {
		state.popularInnovations = innovations.data
		state.popularInnovationPagination.current_page = innovations.current_page
		state.popularInnovationPagination.total = innovations.total
		state.popularInnovationPagination.per_page = innovations.per_page
	},
    startPopularInnovationLoader: (state) => state.popularInnovationLoader = true,
	stopPopularInnovationLoader: (state) => state.popularInnovationLoader = false,

    setRecentInnovations: (state, innovations) => {
		state.recentInnovations = innovations.data
		state.recentInnovationPagination.current_page = innovations.current_page
		state.recentInnovationPagination.total = innovations.total
		state.recentInnovationPagination.per_page = innovations.per_page
	},
    startRecentInnovationLoader: (state) => state.recentInnovationLoader = true,
	stopRecentInnovationLoader: (state) => state.recentInnovationLoader = false,

	setInvestorTopRankedInnovations: (state, innovations) => {
		state.investorTopRankedInnovations = innovations.data
		state.investorTopRankedInnovationPagination.current_page = innovations.current_page
		state.investorTopRankedInnovationPagination.total = innovations.total
		state.investorTopRankedInnovationPagination.per_page = innovations.per_page
	},
    startInvestorTopRankedInnovationLoader: (state) => state.investorTopRankedInnovationLoader = true,
	stopInvestorTopRankedInnovationLoader: (state) => state.investorTopRankedInnovationLoader = false,

	setInvestorRecentlyViewedInnovations: (state, innovations) => {
		state.investorRecentlyViewedInnovations = innovations.data
		state.investorRecentlyViewedInnovationPagination.current_page = innovations.current_page
		state.investorRecentlyViewedInnovationPagination.total = innovations.total
		state.investorRecentlyViewedInnovationPagination.per_page = innovations.per_page
	},
    startInvestorRecentlyViewedInnovationLoader: (state) => state.investorRecentlyViewedInnovationLoader = true,
	stopInvestorRecentlyViewedInnovationLoader: (state) => state.investorRecentlyViewedInnovationLoader = false,

	setInvestorRecommendedInnovations: (state, innovations) => {
		state.investorRecommendedInnovations = innovations.data
		state.investorRecommendedInnovationPagination.current_page = innovations.current_page
		state.investorRecommendedInnovationPagination.total = innovations.total
		state.investorRecommendedInnovationPagination.per_page = innovations.per_page
	},
    startInvestorRecommendedInnovationLoader: (state) => state.investorRecommendedInnovationLoader = true,
	stopInvestorRecommendedInnovationLoader: (state) => state.investorRecommendedInnovationLoader = false,

	setInvestorSavedInnovations: (state, innovations) => {
		state.investorSavedInnovations = innovations.data
		state.investorSavedInnovationPagination.current_page = innovations.current_page
		state.investorSavedInnovationPagination.total = innovations.total
		state.investorSavedInnovationPagination.per_page = innovations.per_page
	},
    startInvestorSavedInnovationLoader: (state) => state.investorSavedInnovationLoader = true,
	stopInvestorSavedInnovationLoader: (state) => state.investorSavedInnovationLoader = false,

	setInvestorNoteInnovations: (state, innovations) => {
		state.investorNoteInnovations = innovations.data
		state.investorNoteInnovationPagination.current_page = innovations.current_page
		state.investorNoteInnovationPagination.total = innovations.total
		state.investorNoteInnovationPagination.per_page = innovations.per_page
	},
    startInvestorNoteInnovationLoader: (state) => state.investorNoteInnovationLoader = true,
	stopInvestorNoteInnovationLoader: (state) => state.investorNoteInnovationLoader = false,

	setInvestorDealInnovations: (state, innovations) => {
		state.investorDealInnovations = innovations.data
		state.investorDealInnovationPagination.current_page = innovations.current_page
		state.investorDealInnovationPagination.total = innovations.total
		state.investorDealInnovationPagination.per_page = innovations.per_page
	},
    startInvestorDealInnovationLoader: (state) => state.investorDealInnovationLoader = true,
	stopInvestorDealInnovationLoader: (state) => state.investorDealInnovationLoader = false,

	updateInnovationApprovalCard: (state) => (state.innovationApprovalShow = !state.innovationApprovalShow),
	setInnovationData: (state, innovation) => {
		state.innovationData = innovation
	},
};

export default {
	state,
	getters,
	actions,
	mutations
}
