import apiCall from '../../utils/api'

const state = {
	notification: {},
	notifications: [],
	paginatedNotifications: [],
	notificationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
};

const getters = {
	notification: (state) => state.notification,
	notifications: (state) => state.notifications,
	paginatedNotifications: (state) => state.paginatedNotifications,
	notificationPagination: (state) => state.notificationPagination
}

const actions = {
	async commitNotification({commit}, notifications) {
		commit('setnotification', notifications)
	},
	async commitNotifications({commit}, notifications) {
		commit('setnotifications', notifications)
	},
	async commitPaginatedNotifications({commit}, notifications) {
		commit('setPaginatedNotifications', notifications)
	},
};

const mutations = {
	setnotification: (state, notification) => (state.notification = notification),
	setnotifications : (state, notifications) => state.notifications = notifications,
	setPaginatedNotifications: (state, notifications) => {
		state.paginatedNotifications = notifications.data
		state.notificationPagination.current_page = notifications.current_page
		state.notificationPagination.total = notifications.total
		state.notificationPagination.per_page = notifications.per_page
	},
};

export default {
	state,
	getters,
	actions,
	mutations
}
