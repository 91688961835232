import apiCall from '@/utils/api'

const state = {
	investorTypes: [],
	allInvestorTypes: [],
    investorTypePagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    investorTypeLoader: false
};

const getters = {
	investorTypes: (state) => state.investorTypes,
	allInvestorTypes: (state) => state.allInvestorTypes,
    investorTypeLoader: (state) => state.investorTypeLoader,
    investorTypePagination: (state) => state.investorTypePagination
}

const actions = {
	async fetchAllInvestorTypes({commit}, page) {
		const response = await apiCall({url: `/api/investor-type?type=all`, method: 'GET' });
		commit('setAllInvestorTypes', response)
        commit('stopLoader', response)
	},
    async fetchInvestorTypes({commit}, page) {
		const response = await apiCall({url: `/api/investor-type?page=${page}`, method: 'GET' });
		commit('setInvestorTypes', response)
        commit('stopLoader', response)
	},
    async filterInvestorTypes({commit, state},resp){
		commit('setInvestorTypes', resp)
	},
	async startInvestorTypeLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopInvestorTypeLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setInvestorTypes: (state, investorTypes) => {
		state.investorTypes = investorTypes.data
		state.investorTypePagination.current_page = investorTypes.current_page
		state.investorTypePagination.total = investorTypes.total
		state.investorTypePagination.per_page = investorTypes.per_page
	},
	setAllInvestorTypes: (state, investorTypes) => {
		state.allInvestorTypes = investorTypes
	},
    startLoader: (state) => state.investorTypeLoader = true,
	stopLoader: (state) => state.investorTypeLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
