import apiCall from '@/utils/api'

const state = {
	openCalls: [],
	searchCalls: [],
	closedCalls: [],
	publicCalls: [],
    openCallPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	closedCallPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	publicCallPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	searchCallPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    openCallLoader: false,
	closedCallLoader: false,
	publicCallLoader: false,
	searchCallLoader: false,
};

const getters = {
	openCalls: (state) => state.openCalls,
	closedCalls: (state) => state.closedCalls,
	publicCalls: (state) => state.publicCalls,

	searchCalls: (state) => state.searchCalls,
	searchCallLoader: (state) => state.searchCallLoader,	
    searchCallPagination: (state) => state.searchCallPagination,

    openCallLoader: (state) => state.openCallLoader,
	closedCallLoader: (state) => state.closedCallLoader,
	publicCallLoader: (state) => state.publicCallLoader,
    openCallPagination: (state) => state.openCallPagination,
	closedCallPagination: (state) => state.closedCallPagination,
	publicCallPagination: (state) => state.publicCallPagination,
}

const actions = {
    async fetchOpenCalls({commit}, page) {
		const response = await apiCall({url: `/api/call?type=open&page=${page}`, method: 'GET' });
		commit('setOpenCalls', response)
        commit('stopOpenCallLoader', response)
	},
	async fetchClosedCalls({commit}, page) {
		const response = await apiCall({url: `/api/call?type=closed&page=${page}`, method: 'GET' });
		commit('setClosedCalls', response)
        commit('stopClosedCallLoader', response)
	},

	async fetchSearchCalls({commit}, page) {
		const response = await apiCall({url: `/api/event?page=${page}`, method: 'GET' });
		commit('setSearchCalls', response)
        commit('stopSearchCallLoader', response)
	},

	async fetchPublicCalls({commit}, page) {
		const response = await apiCall({url: `/api/call?type=public&page=${page}`, method: 'GET' });
		commit('setPublicCalls', response)
        commit('stopPublicCallLoader', response)
	},
    async filterOpenCalls({commit, state},resp){
		commit('setOpenCalls', resp)
	},
	async filterClosedCalls({commit, state},resp){
		commit('setClosedCalls', resp)
	},
	async filterPublicCalls({commit, state},resp){
		commit('setPublicCalls', resp)
	},
	async filterSearchCalls({commit, state},resp){
		commit('setSearchCalls', resp)
	},
	async startOpenCallLoader({commit, state},resp){
		commit('startOpenCallLoader', resp)
	},
	async startClosedCallLoader({commit, state},resp){
		commit('startClosedCallLoader', resp)
	},
	async startPublicCallLoader({commit, state},resp){
		commit('startPublicCallLoader', resp)
	},
	async stopOpenCallLoader({commit, state},resp){
		commit('stopOpenCallLoader', resp)
	},
	async stopClosedCallLoader({commit, state},resp){
		commit('stopClosedCallLoader', resp)
	},
	async stopPublicCallLoader({commit, state},resp){
		commit('stopPublicCallLoader', resp)
	},
	async startSearchCallLoader({commit, state},resp){
		commit('startSearchCallLoader', resp)
	},
	async stopSearchCallLoader({commit, state},resp){
		commit('stopSearchCallLoader', resp)
	},
};

const mutations = {
	setOpenCalls: (state, openCalls) => {
		state.openCalls = openCalls.data
		state.openCallPagination.current_page = openCalls.current_page
		state.openCallPagination.total = openCalls.total
		state.openCallPagination.per_page = openCalls.per_page
	},
	setClosedCalls: (state, closedCalls) => {
		state.closedCalls = closedCalls.data
		state.closedCallPagination.current_page = closedCalls.current_page
		state.closedCallPagination.total = closedCalls.total
		state.closedCallPagination.per_page = closedCalls.per_page
	},
	setPublicCalls: (state, publicCalls) => {
		state.publicCalls = publicCalls.data
		state.publicCallPagination.current_page = publicCalls.current_page
		state.publicCallPagination.total = publicCalls.total
		state.publicCallPagination.per_page = publicCalls.per_page
	},
	setSearchCalls: (state, events) => {
		state.searchCalls = events.data
		state.searchCallPagination.current_page = events.current_page
		state.searchCallPagination.total = events.total
		state.searchCallPagination.per_page = events.per_page
	},
    startSearchCallLoader: (state) => state.searchCallLoader = true,
	stopSearchCallLoader: (state) => state.searchCallLoader = false,

    startOpenCallLoader: (state) => state.openCallLoader = true,
	stopOpenCallLoader: (state) => state.openCallLoader = false,
	startClosedCallLoader: (state) => state.closedCallLoader = true,
	stopClosedCallLoader: (state) => state.closedCallLoader = false,
	startPublicCallLoader: (state) => state.publicCallLoader = true,
	stopPublicCallLoader: (state) => state.publicCallLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
