import apiCall from '@/utils/api'

const state = {
	sectors: [],
	allSectors: [],
    sectorPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    sectorLoader: false
};

const getters = {
	sectors: (state) => state.sectors,
	allSectors: (state) => state.allSectors,
    sectorLoader: (state) => state.sectorLoader,
    sectorPagination: (state) => state.sectorPagination
}

const actions = {
	async fetchAllSectors({commit}, page) {
		const response = await apiCall({url: `/api/sector?type=all`, method: 'GET' });
		commit('setAllSectors', response)
        commit('stopLoader', response)
	},
    async fetchSectors({commit}, page) {
		const response = await apiCall({url: `/api/sector?page=${page}`, method: 'GET' });
		commit('setSectors', response)
        commit('stopLoader', response)
	},
    async filterSectors({commit, state},resp){
		commit('setSectors', resp)
	},
	async startSectorLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopSectorLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setSectors: (state, sectors) => {
		state.sectors = sectors.data
		state.sectorPagination.current_page = sectors.current_page
		state.sectorPagination.total = sectors.total
		state.sectorPagination.per_page = sectors.per_page
	},
	setAllSectors: (state, sectors) => {
		state.allSectors = sectors
	},
    startLoader: (state) => state.sectorLoader = true,
	stopLoader: (state) => state.sectorLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
