<template>
  <div class="welcome">
    <v-progress-linear
      v-if="popularInnovationLoader"
      height="1"
      indeterminate
      color="primary"
    >
    </v-progress-linear>
    <v-container>
      <v-layout column>
        <v-flex xs12>
          <v-layout row wrap>
            <v-flex xs12 md9 class="mt-5">
              <v-card class="pa-5" outlined style="border-radius: 25px">
                <div class="title mb-5">
                  <b>Popular Now</b>
                  <v-icon right>mdi-star-shooting</v-icon>
                </div>
                <div v-if="popularInnovationPagination.total != 0">
                  <v-layout column>
                    <v-flex xs12>
                      <v-layout row wrap>
                        <v-flex xs12 md4>
                          <v-layout column>
                            <template
                              v-for="(
                                interest, index
                              ) in investmentFirmArray[0]"
                            >
                              <v-flex xs12>
                                <v-card
                                  :loading="loading"
                                  class="mx-1 mt-2 d-flex flex-column"
                                  style="border-radius: 25px"
                                  outlined
                                >
                                  <template slot="progress">
                                    <v-progress-linear
                                      color="deep-purple"
                                      height="10"
                                      indeterminate
                                    ></v-progress-linear>
                                  </template>
                                  <v-badge
                                    bordered
                                    color="success"
                                    class="mr-5"
                                    :content="interest.views"
                                    overlap
                                    style="z-index: 3"
                                  >
                                  </v-badge>
                                  <v-img
                                    max-height="150"
                                    v-if="interest.featured_image == null"
                                    src="img/innovation.jpg"
                                    style="border-radius: 25px"
                                  >
                                  </v-img>
                                  <v-img
                                    max-height="150"
                                    v-else
                                    :src="
                                      path +
                                      '/storage/innovation_pictures/' +
                                      interest.featured_image
                                    "
                                    style="border-radius: 25px"
                                  >
                                  </v-img>

                                  <div class="title pa-3">
                                    <v-clamp autoresize :max-lines="2"
                                      >{{ interest.name }}
                                    </v-clamp>
                                  </div>

                                  <v-card-text>
                                    <v-row
                                      align="center"
                                      class="mx-0"
                                      v-if="
                                        $can('rating_view') &&
                                        interest.rating_public == 1
                                      "
                                    >
                                      <v-rating
                                        :value="interest.average_rating"
                                        color="amber"
                                        dense
                                        half-increments
                                        readonly
                                        size="14"
                                      ></v-rating>

                                      <div class="grey--text ms-4">
                                        {{ interest.average_rating }} ({{
                                          interest.total_rating
                                        }})
                                      </div>
                                    </v-row>

                                    <div class="my-4 text-subtitle-1">
                                      <template
                                        v-for="(
                                          innovation_sector, index
                                        ) in interest.innovation_sectors.slice(
                                          0,
                                          5
                                        )"
                                      >
                                        <v-chip
                                          x-small
                                          :color="
                                            innovation_sector.sector.color
                                          "
                                          class="white--text mr-1"
                                          :key="index"
                                        >
                                          #{{ innovation_sector.sector.name }}
                                        </v-chip>
                                      </template>
                                      <v-chip
                                        class="white--text"
                                        color="primary"
                                        small
                                        v-if="
                                          interest.innovation_sectors.length > 5
                                        "
                                      >
                                        + More
                                      </v-chip>
                                    </div>
                                    <v-clamp autoresize :max-lines="10"
                                      >{{ interest.brief }}
                                    </v-clamp>
                                  </v-card-text>
                                  <v-spacer></v-spacer>
                                  <div
                                    align="center"
                                    v-if="interest.verified == 1"
                                  >
                                    <v-chip
                                      class="ma-2"
                                      color="blue"
                                      label
                                      text-color="white"
                                    >
                                      <v-icon left> mdi-check-decagram </v-icon>
                                      IB Verified
                                    </v-chip>
                                  </div>

                                  <v-divider class="mx-4"></v-divider>

                                  <v-card-actions>
                                    <v-btn
                                      class="text-none"
                                      color="primary"
                                      block
                                      depressed
                                      rounded
                                      @click="goToInnovation(interest)"
                                    >
                                      View
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-flex>
                            </template>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 md4>
                          <v-layout column>
                            <template
                              v-for="(
                                interest, index
                              ) in investmentFirmArray[1]"
                            >
                              <v-flex xs12>
                                <v-card
                                  :loading="loading"
                                  class="mx-1 mt-2 d-flex flex-column"
                                  style="border-radius: 25px"
                                  outlined
                                >
                                  <template slot="progress">
                                    <v-progress-linear
                                      color="deep-purple"
                                      height="10"
                                      indeterminate
                                    ></v-progress-linear>
                                  </template>
                                  <v-badge
                                    bordered
                                    color="success"
                                    class="mr-5"
                                    :content="interest.views"
                                    overlap
                                    style="z-index: 3"
                                  >
                                  </v-badge>
                                  <v-img
                                    max-height="150"
                                    v-if="interest.featured_image == null"
                                    src="img/innovation.jpg"
                                    style="border-radius: 25px"
                                  >
                                  </v-img>
                                  <v-img
                                    max-height="150"
                                    v-else
                                    :src="
                                      path +
                                      '/storage/innovation_pictures/' +
                                      interest.featured_image
                                    "
                                    style="border-radius: 25px"
                                  >
                                  </v-img>

                                  <div class="title pa-3">
                                    <v-clamp autoresize :max-lines="2"
                                      >{{ interest.name }}
                                    </v-clamp>
                                  </div>

                                  <v-card-text>
                                    <v-row
                                      align="center"
                                      class="mx-0"
                                      v-if="
                                        $can('rating_view') &&
                                        interest.rating_public == 1
                                      "
                                    >
                                      <v-rating
                                        :value="interest.average_rating"
                                        color="amber"
                                        dense
                                        half-increments
                                        readonly
                                        size="14"
                                      ></v-rating>

                                      <div class="grey--text ms-4">
                                        {{ interest.average_rating }} ({{
                                          interest.total_rating
                                        }})
                                      </div>
                                    </v-row>

                                    <div class="my-4 text-subtitle-1">
                                      <template
                                        v-for="(
                                          innovation_sector, index
                                        ) in interest.innovation_sectors.slice(
                                          0,
                                          5
                                        )"
                                      >
                                        <v-chip
                                          x-small
                                          :color="
                                            innovation_sector.sector.color
                                          "
                                          class="white--text mr-1"
                                          :key="index"
                                        >
                                          #{{ innovation_sector.sector.name }}
                                        </v-chip>
                                      </template>
                                      <v-chip
                                        class="white--text"
                                        color="primary"
                                        small
                                        v-if="
                                          interest.innovation_sectors.length > 5
                                        "
                                      >
                                        + More
                                      </v-chip>
                                    </div>
                                    <v-clamp autoresize :max-lines="10"
                                      >{{ interest.brief }}
                                    </v-clamp>
                                  </v-card-text>
                                  <v-spacer></v-spacer>
                                  <div
                                    align="center"
                                    v-if="interest.verified == 1"
                                  >
                                    <v-chip
                                      class="ma-2"
                                      color="blue"
                                      label
                                      text-color="white"
                                    >
                                      <v-icon left> mdi-check-decagram </v-icon>
                                      IB Verified
                                    </v-chip>
                                  </div>

                                  <v-divider class="mx-4"></v-divider>

                                  <v-card-actions>
                                    <v-btn
                                      class="text-none"
                                      color="primary"
                                      block
                                      depressed
                                      rounded
                                      @click="goToInnovation(interest)"
                                    >
                                      View
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-flex>
                            </template>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 md4>
                          <v-layout column>
                            <template
                              v-for="(
                                interest, index
                              ) in investmentFirmArray[2]"
                            >
                              <v-flex xs12>
                                <v-card
                                  :loading="loading"
                                  class="mx-1 mt-2 d-flex flex-column"
                                  style="border-radius: 25px"
                                  outlined
                                >
                                  <template slot="progress">
                                    <v-progress-linear
                                      color="deep-purple"
                                      height="10"
                                      indeterminate
                                    ></v-progress-linear>
                                  </template>
                                  <v-badge
                                    bordered
                                    color="success"
                                    class="mr-5"
                                    :content="interest.views"
                                    overlap
                                    style="z-index: 3"
                                  >
                                  </v-badge>
                                  <v-img
                                    max-height="150"
                                    v-if="interest.featured_image == null"
                                    src="img/innovation.jpg"
                                    style="border-radius: 25px"
                                  >
                                  </v-img>
                                  <v-img
                                    max-height="150"
                                    v-else
                                    :src="
                                      path +
                                      '/storage/innovation_pictures/' +
                                      interest.featured_image
                                    "
                                    style="border-radius: 25px"
                                  >
                                  </v-img>

                                  <div class="title pa-3">
                                    <v-clamp autoresize :max-lines="2"
                                      >{{ interest.name }}
                                    </v-clamp>
                                  </div>

                                  <v-card-text>
                                    <v-row
                                      align="center"
                                      class="mx-0"
                                      v-if="
                                        $can('rating_view') &&
                                        interest.rating_public == 1
                                      "
                                    >
                                      <v-rating
                                        :value="interest.average_rating"
                                        color="amber"
                                        dense
                                        half-increments
                                        readonly
                                        size="14"
                                      ></v-rating>

                                      <div class="grey--text ms-4">
                                        {{ interest.average_rating }} ({{
                                          interest.total_rating
                                        }})
                                      </div>
                                    </v-row>

                                    <div class="my-4 text-subtitle-1">
                                      <template
                                        v-for="(
                                          innovation_sector, index
                                        ) in interest.innovation_sectors.slice(
                                          0,
                                          5
                                        )"
                                      >
                                        <v-chip
                                          x-small
                                          :color="
                                            innovation_sector.sector.color
                                          "
                                          class="white--text mr-1"
                                          :key="index"
                                        >
                                          #{{ innovation_sector.sector.name }}
                                        </v-chip>
                                      </template>
                                      <v-chip
                                        class="white--text"
                                        color="primary"
                                        small
                                        v-if="
                                          interest.innovation_sectors.length > 5
                                        "
                                      >
                                        + More
                                      </v-chip>
                                    </div>
                                    <v-clamp autoresize :max-lines="10"
                                      >{{ interest.brief }}
                                    </v-clamp>
                                  </v-card-text>
                                  <v-spacer></v-spacer>
                                  <div
                                    align="center"
                                    v-if="interest.verified == 1"
                                  >
                                    <v-chip
                                      class="ma-2"
                                      color="blue"
                                      label
                                      text-color="white"
                                    >
                                      <v-icon left> mdi-check-decagram </v-icon>
                                      IB Verified
                                    </v-chip>
                                  </div>

                                  <v-divider class="mx-4"></v-divider>

                                  <v-card-actions>
                                    <v-btn
                                      class="text-none"
                                      color="primary"
                                      block
                                      depressed
                                      rounded
                                      @click="goToInnovation(interest)"
                                    >
                                      View
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-flex>
                            </template>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </div>
                <div v-else class="mx-1 mt-1">
                  <v-card outlined style="border-radius: 25px">
                    <v-layout row wrap>
                      <v-flex xs12 md4>
                        <div align="center" class="my-10">
                          <v-avatar color="text" size="96">
                            <v-icon class="white--text">
                              mdi-file-document-alert
                            </v-icon>
                          </v-avatar>
                        </div>
                      </v-flex>
                      <v-flex xs12 md8>
                        <v-layout column>
                          <v-flex xs12 class="mt-1">
                            <div align="center" class="mt-15 ml-5 title">
                              No Popular Innovations Found..
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </div>
              </v-card>
              <div
                align="center"
                class="my-7"
                v-if="popularInnovationPagination.total != 0"
              >
                <v-pagination
                  v-if="investmentFirmLength != 0"
                  :length="investmentFirmLength"
                  total-visible="10"
                  v-model="popularInnovationPagination.current_page"
                  @input="changePage()"
                  circle
                >
                </v-pagination>
              </div>
            </v-flex>
            <v-flex xs12 md3>
              <div class="ml-3 mt-6">
                <v-card class="mx-auto pa-1" outlined>
                  <v-layout column>
                    
                   
                   
                      <v-flex xs12>
                        <div class="pa-1 mt-2">
                          <div class="mb-2"><b>County</b></div>
                          <v-select
                            :items="allCounties"
                            item-text="name"
                            item-value="id"
                            v-model="filter.county_id"
                            class="search_field background"
                            dense
                            outlined
                            height="10"
                            @input="search()"
                          >
                          </v-select>
                        </div>
                      </v-flex>
                      <div v-if="$can('investment_view') && formData!=null">
                        <v-flex xs12>
                          <div class="pa-1 mt-2">
                            <div class="mb-2"><b>Customers</b></div>
                            <v-select
                              :items="formData.customer_classes"
                              item-text="name"
                              item-value="id"
                              v-model="filter.customer_class_id"
                              class="search_field background"
                              dense
                              outlined
                              height="10"
                              @input="search()"
                            >
                            </v-select>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div class="pa-1 mt-2">
                            <div class="mb-2"><b>Users</b></div>
                            <v-select
                              :items="formData.user_classes"
                              item-text="name"
                              item-value="id"
                              v-model="filter.user_class_id"
                              class="search_field background"
                              dense
                              outlined
                              height="10"
                              @input="search()"
                            >
                            </v-select>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div class="pa-1 mt-2">
                            <div class="mb-2"><b>Employees</b></div>
                            <v-select
                              :items="formData.employee_classes"
                              item-text="name"
                              item-value="id"
                              v-model="filter.employee_class_id"
                              class="search_field background"
                              dense
                              outlined
                              height="10"
                              @input="search()"
                            >
                            </v-select>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div class="pa-1 mt-2">
                            <div class="mb-2"><b>Investments</b></div>
                            <v-select
                              :items="formData.investment_classes"
                              item-text="name"
                              item-value="id"
                              v-model="filter.investment_class_id"
                              class="search_field background"
                              dense
                              outlined
                              height="10"
                              @input="search()"
                            >
                            </v-select>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div class="pa-1 mt-2">
                            <div class="mb-2"><b>Revenue</b></div>
                            <v-select
                              :items="formData.revenue_classes"
                              item-text="name"
                              item-value="id"
                              v-model="filter.revenue_class_id"
                              class="search_field background"
                              dense
                              outlined
                              height="10"
                              @input="search()"
                            >
                            </v-select>
                          </div>
                        </v-flex>
                      </div>
                  </v-layout>
                </v-card>

                <v-btn
                  depressed
                  block
                  class="primary text-none mt-5"
                  @click="reset()"
                  :loading="resetLoading"
                  >Reset</v-btn
                >
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      loading: false,
      searchLoading: false,
      resetLoading: false,
      filteredInvestmentFirms: false,

      investmentFirmArray: [],
      inputRules: [(v) => !!v || "Input is required"],

      filter: {
        search: null,
        investor_type_id: null,
        county_id: null,
        customer_class_id: null,
        employee_class_id: null,
        investment_class_id: null,
        revenue_class_id: null,
      },

      formData: null,

      originalArray: [],
    };
  },
  watch: {
    popularInnovations() {
      this.chunkInvestmentFirms();
    },
  },
  mounted() {},
  created() {
    window.scrollTo(0, 0);
    this.initialize();
    this.getFormData();
  },
  methods: {
    ...mapActions([
      "fetchPopularInnovations",
      "filterPopularInnovations",
      "fetchAllCounties",
      "startPopularInnovationLoader",
      "stopPopularInnovationLoader",
    ]),
    initialize() {
      this.fetchPopularInnovations(
        this.popularInnovationPagination.current_page
      );
      // this.fetchAllCounties();
      this.startPopularInnovationLoader();
      this.chunkInvestmentFirms();
    },
    getFormData() {
      apiCall({
        url: "/api/innovation?type=filterFormData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
        })
        .catch((error) => {
          this.message = "An Error Occurred; Couldn't Get Form Data";
          this.color = "error";
        });
    },
    goToInnovation(innovation) {
      this.$router.push("/innovations/" + innovation.slug);
    },
    goToFirm(interest) {
      this.$router.push("/investors/" + interest.slug);
    },
    intToString(value) {
      var suffixes = ["", "k", "M", "B", "T"];
      var suffixNum = Math.floor(("" + value).length / 3);
      var shortValue = parseFloat(
        (suffixNum != 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(2)
      );
      if (shortValue % 1 != 0) {
        shortValue = shortValue.toFixed(1);
      }
      return shortValue + suffixes[suffixNum];
    },
    reset() {
      this.resetLoading = true;
      this.startPopularInnovationLoader()
      apiCall({
        url: "/api/innovation?type=popular&page=1",
        method: "GET",
      })
        .then((resp) => {
          this.filter.search = null;
          this.filter.investor_type_id = null;
          this.filter.county_id = null;
          this.filter.customer_class_id = null;
          this.filter.employee_class_id = null;
          this.filter.investment_class_id = null;
          this.filter.revenue_class_id = null;

          this.loading = false;
          this.resetLoading = false;
          this.filteredInvestmentFirms = true;
          this.filterPopularInnovations(resp);
          this.chunkInvestmentFirms();
          this.stopPopularInnovationLoader()
        })
        .catch((error) => {
          this.message = "An Error Occurred; Please Try Again";
          this.color = "error";
          this.loading = false;
          this.resetLoading = false;
          this.snackbar = true;
          this.stopPopularInnovationLoader()
        });
    },
    search() {
      this.searchLoading = true;
      this.startPopularInnovationLoader()
      apiCall({
        url: "/api/innovation?type=popular&page=1",
        data: this.filter,
        method: "POST",
      })
        .then((resp) => {
          this.loading = false;
          this.searchLoading = false;
          this.filteredInvestmentFirms = true;
          this.filterPopularInnovations(resp);
          this.chunkInvestmentFirms();
          this.stopPopularInnovationLoader()
        })
        .catch((error) => {
          this.message = "An Error Occurred; Please Try Again";
          this.color = "error";
          this.loading = false;
          this.searchLoading = false;
          this.snackbar = true;
          this.stopPopularInnovationLoader()
        });
    },
    chunkInvestmentFirms() {
      this.investmentFirmArray = [];

      for (var i = 0; i < this.popularInnovations.length; i++) {
        this.originalArray.push(this.popularInnovations[i]);
      }

      let result = [];
      for (let i = 3; i > 0; i--) {
        result.push(
          this.originalArray.splice(0, Math.ceil(this.originalArray.length / i))
        );
      }
      this.investmentFirmArray = result;
    },

    changePage() {
      window.scrollTo(0, 0);
      this.startPopularInnovationLoader();
      if (this.filteredInvestmentFirms == true) {
        apiCall({
          url:
            "/api/innovation?type=popular&page=" +
            this.popularInnovationPagination.current_page,
          data: this.filter,
          method: "POST",
        })
          .then((resp) => {
            this.loading = false;
            this.searchLoading = false;
            this.filteredInvestmentFirms = true;
            this.filterPopularInnovations(resp);
            this.stopPopularInnovationLoader();
          })
          .catch((error) => {
            this.message = "An Error Occurred; Please Try Again";
            this.color = "error";
            this.loading = false;
            this.searchLoading = false;
            this.snackbar = true;
            this.stopPopularInnovationLoader();
          });
      } else {
        this.fetchPopularInnovations(
          this.popularInnovationPagination.current_page
        );
      }
    },
    goToInstitution(institution) {
      this.$router.push("/institutions/" + institution.slug);
    },
  },
  computed: {
    ...mapGetters([
      "allInvestorTypes",
      "allCounties",

      "popularInnovations",
      "popularInnovationLoader",
      "popularInnovationPagination",
    ]),
    investmentFirmLength: function () {
      return Math.ceil(
        this.popularInnovationPagination.total /
          this.popularInnovationPagination.per_page
      );
    },
  },
};
</script>
