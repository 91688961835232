<template>
    <div class="asks" v-if="$can('ask_view')">
      <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
        <span>{{ message }}</span>
      </v-snackbar>
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="imageDialog"
      >
        <v-card max-width="600">
          <v-toolbar flat>
            Upload Feature Image
            <v-spacer></v-spacer>
            <v-btn icon @click="imageDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
  
          <v-form ref="imageForm" v-model="imageValid" lazy-validation>
            <v-layout column>
              <v-flex xs12>
                <v-file-input
                  class="mx-2 mt-3"
                  :loading="imageLoading"
                  :rules="fileRules"
                  filled
                  dense
                  show-size
                  chips
                  accept="image/*"
                  v-model="imageFile"
                  label="Select Image"
                ></v-file-input>
              </v-flex>
              <v-flex xs12>
                <div class="mx-3 mb-5">
                  <v-btn
                    depressed
                    block
                    class="primary text-none"
                    @click="uploadImage"
                    :loading="imageLoading"
                    :disabled="!imageValid"
                  >
                    Submit <v-icon right dark>mdi-upload</v-icon>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card>
      </v-dialog>
      <v-container>
        <v-card outlined class="mt-5" v-if="view == 'open' || view == 'closed'">
          <v-tabs show-arrows v-model="viewTab">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab @click="changeView('open')">
              <div class="text-none text--text">Open Asks ({{ openAskPagination.total }})</div>
            </v-tab>
            <v-tab @click="changeView('closed')">
              <div class="text-none text--text">Closed Asks ({{ closedAskPagination.total }})</div>
            </v-tab>
  
          </v-tabs>
        </v-card>
  
        <div v-if="view == 'open'">
          <v-layout column>
            <v-card outlined class="mt-2">
              <v-progress-linear v-if="openAskLoader" height="1" indeterminate color="primary"></v-progress-linear>
              <v-flex xs12 class="mt-10 mb-2">
                <div class="mx-5">
                  <v-layout row wrap>
                    <v-flex xs12 md4>
                      <h1> </h1>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-1">
                        <v-text-field class="text_field header" outlined dense label="Search"
                          append-icon="mdi-undo-variant" @click:append="resetOpenAskSearch()"
                          v-on:keyup.enter="searchOpenAsk" v-model="openAskSearchTerm"
                          @input="enableOpenAskSearch()"></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md2>
                      <div class="pa-1">
                        <v-btn class="secondary primary--text text-none" depressed block @click="searchOpenAsk"
                          :loading="searchOpenAskLoader" :disabled="openAskSearchButtonDisabled">
                          Search
                          <v-icon right>mdi-magnify</v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                    <v-flex xs12 md2>
                      <div class="pa-1">
                        <v-btn block depressed class="primary text-none" @click="changeView('create')"
                          v-if="$can('ask_create')">
                          Add Ask
                          <v-icon right>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>
              <v-divider class="mt-9 mx-5"></v-divider>
              <v-flex xs12 class="mt-5">
                <div v-if="openAsks.length == 0">
                  <v-card elevation="0">
                    <v-layout row wrap>
                      <v-flex xs12 md1>
                        <v-container fill-height fluid>
                          <v-row align="center" justify="center">
                            <v-col class="text-center">
                              <v-icon large class="text--text">
                                mdi-alert-circle-outline
                              </v-icon>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-flex>
                      <v-flex xs12 md11>
                        <v-container fill-height fluid>
                          <v-row align="center" justify="center">
                            <v-col class="text-center"> No Open Asks Found </v-col>
                          </v-row>
                        </v-container>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </div>
                <div v-else>
                  <div class="hidden-sm-and-down">
                    <v-card elevation="0" class="pa-2">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead class="header">
                            <tr class="tableHeader">
                              <th class="text-left text--text">Title</th>
                              <th class="text-left text--text">Innovation(s)</th>
                              <th class="text-right text--text">Amount Requested (Kshs)</th>
                              <th class="text-left text--text">Status</th>
                              <th class="text-left text--text">Creation Date</th>
                              <th class="text-right text--text">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in openAsks" :key="item.id">
                              <td>
                                {{ item.title }}
                              </td>
                              <td>
                                <div v-if="item.innovation_asks.length != 0">
                                  <div v-for="item in item.innovation_asks" :key="item.id">
                                    {{ item.innovation.name }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div align="right">
                                  {{ item.amount | formatNumber  }}
                                </div>
                              </td>
                              <td>
                                <div v-if="item.status == 1">Active</div>
                                <div v-else>Inactive</div>
                              </td>
                              <td>
                                {{
                                  item.created_at | moment("DD/MM/YYYY - hh:mm a")
                                }}
                              </td>
                              <td>
                                <div align="right">
                                  <v-tooltip top v-if="$can('ask_view')">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon class="button mr-1 universal--text" @click="showAsk(item)">
                                        <v-icon small> mdi-eye </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>View Ask</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="$can('ask_edit')">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon class="button mr-1 success--text" @click="editAsk(item)" v-bind="attrs"
                                        v-on="on">
                                        <v-icon small> mdi-pencil </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>edit</span>
                                  </v-tooltip>
  
                                  <v-tooltip top v-if="$can('ask_archive')">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon class="button mr-1 error--text" @click="deleteAsk(item)"
                                        :loading="loading && askIndex == item.id" v-bind="attrs" v-on="on">
                                        <v-icon small> mdi-delete </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>delete</span>
                                  </v-tooltip>
  
                                  <v-tooltip top v-if="
                                    $can('ask_activate') && item.status == 0
                                  ">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon class="button mr-1 red--text" @click="activateAsk(item)" :loading="
                                        activateLoading && askIndex == item.id
                                      " v-bind="attrs" v-on="on">
                                        <v-icon small>
                                          mdi-toggle-switch-off-outline
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Activate Ask</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="
                                    $can('ask_deactivate') && item.status == 1
                                  ">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon class="button mr-1 green--text" @click="deactivateAsk(item)" :loading="
                                        deactivateLoading &&
                                        askIndex == item.id
                                      " v-bind="attrs" v-on="on">
                                        <v-icon small> mdi-toggle-switch </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Deactivate Ask</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="$can('log_view')">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        class="button mr-1 purple--text"
                                        @click="viewLog(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon small> mdi-script-text </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Log</span>
                                  </v-tooltip>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </div>
                  <div class="hidden-md-and-up">
                    <v-layout column>
                      <template v-for="(ask, index) in openAsks">
                        <div :key="index">
                          <v-flex xs12 class="mb-2">
                            <v-card elevation="0">
                              <div class="pa-5">
                                <v-layout column>
                                  <v-flex xs12>
                                    <v-layout row wrap>
                                      <v-flex xs11>
                                        <div class="title text--text ml-3">
                                          <b>{{ ask.title }}</b>
                                        </div>
                                      </v-flex>
                                      <v-flex xs1>
                                        <v-btn depressed class="red--text" :loading="
                                          loading && askIndex == ask.id
                                        " icon @click="deleteAsk(ask)" v-if="$can('ask_archive')">
                                          <v-icon> mdi-delete </v-icon>
                                        </v-btn>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-divider class="mt-2"></v-divider>
                                  <v-flex xs12 class="mt-1">
                                    <v-layout column>
                                      <v-flex xs12 class="mt-1">
                                        <div>
                                          <b>Innovation(s)</b>
                                        </div>
                                        <div v-if="ask.innovation_asks.length != 0">
                                        <div v-for="ask in ask.innovation_asks" :key="ask.id">
                                          {{ ask.innovation.name }}
                                        </div>
                                      </div>
                                      </v-flex>
                                      <v-flex xs12 class="mt-1">
                                        <div>
                                          <b>Amount Requested</b>
                                        </div>
                                        <div>
                                          Kshs {{ ask.amount | formatNumber  }}
                                        </div>
                                      </v-flex>
                                      <v-flex xs12 class="mt-1">
                                        <div>
                                          <b>Status</b>
                                        </div>
                                        <div v-if="ask.status == 1">Active</div>
                                        <div v-else>Inactive</div>
                                      </v-flex>
                                      <v-flex xs12 class="mt-1">
                                        <div>
                                          <b>Creation Date</b>
                                        </div>
                                        <div>
                                          {{
                                            ask.created_at | moment("DD/MM/YYYY - hh:mm a")
                                          }}
                                        </div>
                                      </v-flex>
  
                                      <v-flex xs12 class="mt-5">
                                        <v-layout row wrap>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn
                                                depressed
                                                class="
                                                  primary
                                                  text-none
                                                  white--text
                                                "
                                                block
                                                @click="showAsk(ask)"
                                                v-if="$can('ask_view')"
                                                >View
                                                <v-icon right> mdi-eye </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn depressed class="blue text-none white--text" block
                                                @click="editAsk(ask)" v-if="$can('ask_edit')">Edit
                                                <v-icon right>
                                                  mdi-border-color
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn depressed class="green text-none white--text" block
                                                @click="activateAsk(ask)" v-if="$can('ask_activate') && ask.status == 0"
                                                :loading="
                                                  activateLoading && askIndex == ask.id"
                                                >Activate Ask
                                                <v-icon right>
                                                  mdi-toggle-switch-off-outline
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn depressed class="red text-none white--text" block
                                                @click="deactivateAsk(ask)" v-if="$can('ask_deactivate') && ask.status == 1"
                                                :loading="
                                                  deactivateLoading && askIndex == ask.id"
                                                >Deactivate Ask
                                                <v-icon right>
                                                  mdi-toggle-switch
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn
                                                depressed block
                                                class="purple text-none white--text"
                                                @click="viewLog(ask)"
                                                v-bind="attrs"
                                                v-on="on"
                                              >Log
                                                <v-icon right> mdi-script-text </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </v-card>
                          </v-flex>
                        </div>
                      </template>
                    </v-layout>
                  </div>
                </div>
              </v-flex>
            </v-card>
  
            <v-layout column class="mt-5">
              <v-flex xs12>
                <div align="center">
                  <v-pagination v-if="openAskLength != 0" :length="openAskLength" total-visible="10"
                    v-model="openAskPagination.current_page" @input="changeOpenAskPage()" circle>
                  </v-pagination>
                </div>
              </v-flex>
              <v-flex xs12>
                <div v-if="length != 0" align="center" class="mt-5">
                  <b class="text--text">Total: </b>{{ openAskPagination.total | formatNumber }}
                </div>
              </v-flex>
            </v-layout>
  
  
  
          </v-layout>
        </div>
        <div v-if="view == 'closed'">
          <v-layout column>
            <v-card outlined class="mt-2">
              <v-progress-linear v-if="closedAskLoader" height="1" indeterminate color="primary"></v-progress-linear>
              <v-flex xs12 class="mt-10 mb-2">
                <div class="mx-5">
                  <v-layout row wrap>
                    <v-flex xs12 md4>
                      <h1> </h1>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-1">
                        <v-text-field class="text_field header" outlined dense label="Search"
                          append-icon="mdi-undo-variant" @click:append="resetClosedAskSearch()"
                          v-on:keyup.enter="searchClosedAsk" v-model="closedAskSearchTerm"
                          @input="enableClosedAskSearch()"></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md2>
                      <div class="pa-1">
                        <v-btn class="secondary primary--text text-none" depressed block @click="searchClosedAsk"
                          :loading="searchClosedAskLoader" :disabled="closedAskSearchButtonDisabled">
                          Search
                          <v-icon right>mdi-magnify</v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                    <v-flex xs12 md2>
                      <div class="pa-1">
                        <v-btn block depressed class="primary text-none" @click="changeView('create')"
                          v-if="$can('ask_create')">
                          Add Ask
                          <v-icon right>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>
              <v-divider class="mt-9 mx-5"></v-divider>
              <v-flex xs12 class="mt-5">
                <div v-if="closedAsks.length == 0">
                  <v-card elevation="0">
                    <v-layout row wrap>
                      <v-flex xs12 md1>
                        <v-container fill-height fluid>
                          <v-row align="center" justify="center">
                            <v-col class="text-center">
                              <v-icon large class="text--text">
                                mdi-alert-circle-outline
                              </v-icon>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-flex>
                      <v-flex xs12 md11>
                        <v-container fill-height fluid>
                          <v-row align="center" justify="center">
                            <v-col class="text-center"> No Closed Asks Found </v-col>
                          </v-row>
                        </v-container>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </div>
                <div v-else>
                  <div class="hidden-sm-and-down">
                    <v-card elevation="0" class="pa-2">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead class="header">
                            <tr class="tableHeader">
                              <th class="text-left text--text">Title</th>
                              <th class="text-left text--text">Innovation(s)</th>
                              <th class="text-right text--text">Amount Requested (Kshs)</th>
                              <th class="text-left text--text">Status</th>
                              <th class="text-left text--text">Creation Date</th>
                              <th class="text-right text--text">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in closedAsks" :key="item.id">
                              <td>
                                {{ item.title }}
                              </td>
                              <td>
                                <div v-if="item.innovation_asks.length != 0">
                                  <div v-for="item in item.innovation_asks" :key="item.id">
                                    {{ item.innovation.name }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div align="right">
                                  {{ item.amount | formatNumber  }}
                                </div>
                              </td>
                              <td>
                                <div v-if="item.status == 1">Active</div>
                                <div v-else>Inactive</div>
                              </td>
                              <td>
                                {{
                                  item.created_at | moment("DD/MM/YYYY - hh:mm a")
                                }}
                              </td>
                              <td>
                                <div align="right">
                                  <v-tooltip top v-if="$can('ask_view')">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon class="button mr-1 universal--text" @click="showAsk(item)">
                                        <v-icon small> mdi-eye </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>View Ask</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="$can('ask_edit')">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon class="button mr-1 success--text" @click="editAsk(item)" v-bind="attrs"
                                        v-on="on">
                                        <v-icon small> mdi-pencil </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>edit</span>
                                  </v-tooltip>
  
                                  <v-tooltip top v-if="$can('ask_archive')">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon class="button mr-1 error--text" @click="deleteAsk(item)"
                                        :loading="loading && askIndex == item.id" v-bind="attrs" v-on="on">
                                        <v-icon small> mdi-delete </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>delete</span>
                                  </v-tooltip>
  
                                  <v-tooltip top v-if="
                                    $can('ask_activate') && item.status == 0
                                  ">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon class="button mr-1 red--text" @click="activateAsk(item)" :loading="
                                        activateLoading && askIndex == item.id
                                      " v-bind="attrs" v-on="on">
                                        <v-icon small>
                                          mdi-toggle-switch-off-outline
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Activate Account</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="
                                    $can('ask_deactivate') && item.status == 1
                                  ">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon class="button mr-1 green--text" @click="deactivateAsk(item)" :loading="
                                        deactivateLoading &&
                                        askIndex == item.id
                                      " v-bind="attrs" v-on="on">
                                        <v-icon small> mdi-toggle-switch </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Deactivate Account</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="$can('log_view')">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        class="button mr-1 purple--text"
                                        @click="viewLog(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon small> mdi-script-text </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Log</span>
                                  </v-tooltip>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </div>
                  <div class="hidden-md-and-up">
                    <v-layout column>
                      <template v-for="(ask, index) in closedAsks">
                        <div :key="index">
                          <v-flex xs12 class="mb-2">
                            <v-card elevation="0">
                              <div class="pa-5">
                                <v-layout column>
                                  <v-flex xs12>
                                    <v-layout row wrap>
                                      <v-flex xs11>
                                        <div class="title text--text ml-3">
                                          <b>{{ ask.title }}</b>
                                        </div>
                                      </v-flex>
                                      <v-flex xs1>
                                        <v-btn depressed class="red--text" :loading="
                                          loading && askIndex == ask.id
                                        " icon @click="deleteAsk(ask)" v-if="$can('ask_archive')">
                                          <v-icon> mdi-delete </v-icon>
                                        </v-btn>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-divider class="mt-2"></v-divider>
                                  <v-flex xs12 class="mt-1">
                                    <v-layout column>
                                      <v-flex xs12 class="mt-1">
                                        <div>
                                          <b>Innovation(s)</b>
                                        </div>
                                        <div v-if="ask.innovation_asks.length != 0">
                                        <div v-for="ask in ask.innovation_asks" :key="ask.id">
                                          {{ ask.innovation.name }}
                                        </div>
                                      </div>
                                      </v-flex>
                                      <v-flex xs12 class="mt-1">
                                        <div>
                                          <b>Amount Requested</b>
                                        </div>
                                        <div>
                                          Kshs {{ ask.amount | formatNumber  }}
                                        </div>
                                      </v-flex>
                                      <v-flex xs12 class="mt-1">
                                        <div>
                                          <b>Status</b>
                                        </div>
                                        <div v-if="ask.status == 1">Active</div>
                                        <div v-else>Inactive</div>
                                      </v-flex>
                                      <v-flex xs12 class="mt-1">
                                        <div>
                                          <b>Creation Date</b>
                                        </div>
                                        <div>
                                          {{
                                            ask.created_at | moment("DD/MM/YYYY - hh:mm a")
                                          }}
                                        </div>
                                      </v-flex>
  
                                      <v-flex xs12 class="mt-5">
                                        <v-layout row wrap>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn
                                                depressed
                                                class="
                                                  primary
                                                  text-none
                                                  white--text
                                                "
                                                block
                                                @click="showAsk(ask)"
                                                v-if="$can('ask_view')"
                                                >View
                                                <v-icon right> mdi-eye </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn depressed class="blue text-none white--text" block
                                                @click="editAsk(ask)" v-if="$can('ask_edit')">Edit
                                                <v-icon right>
                                                  mdi-border-color
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn depressed class="green text-none white--text" block
                                                @click="activateAsk(ask)" v-if="$can('ask_activate') && ask.status == 0"
                                                :loading="
                                                  activateLoading && askIndex == ask.id"
                                                >Activate Ask
                                                <v-icon right>
                                                  mdi-toggle-switch-off-outline
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn depressed class="red text-none white--text" block
                                                @click="deactivateAsk(ask)" v-if="$can('ask_deactivate') && ask.status == 1"
                                                :loading="
                                                  deactivateLoading && askIndex == ask.id"
                                                >Deactivate Ask
                                                <v-icon right>
                                                  mdi-toggle-switch
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn
                                                depressed block
                                                class="purple text-none white--text"
                                                @click="viewLog(ask)"
                                                v-bind="attrs"
                                                v-on="on"
                                              >Log
                                                <v-icon right> mdi-script-text </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </v-card>
                          </v-flex>
                        </div>
                      </template>
                    </v-layout>
                  </div>
                </div>
              </v-flex>
            </v-card>
            <v-layout column class="mt-5">
              <v-flex xs12>
                <div align="center">
                  <v-pagination v-if="closedAskLength != 0" :length="closedAskLength" total-visible="10"
                    v-model="closedAskPagination.current_page" @input="changeClosedAskPage()" circle>
                  </v-pagination>
                </div>
              </v-flex>
              <v-flex xs12>
                <div v-if="length != 0" align="center" class="mt-5">
                  <b class="text--text">Total: </b>{{ closedAskPagination.total | formatNumber }}
                </div>
              </v-flex>
            </v-layout>
          </v-layout>
        </div>
        <div v-if="view == 'create'">
          <v-flex class="mt-5">
            <v-card outlined>
              <v-card-title class="tableHeader text--text">
                <v-spacer></v-spacer>
                <div v-if="newAsk.id == null">Add Ask</div>
                <div v-else>Edit Ask</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeView('open')">
                  <v-icon class="text--text"> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-layout column>
                    <v-flex xs12>
                      <v-layout row wrap class="mt-5">
                        <v-flex xs12>
                          <div class="pa-3">
                            <div>Title<span class="error--text">*</span></div>
                            <v-text-field class="text_field background" outlined dense v-model="newAsk.title"
                              :rules="inputRules"></v-text-field>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div class="pa-3">
                            <div>Brief<span class="error--text">*</span></div>
                            <v-text-field class="text_field background" outlined dense v-model="newAsk.brief"
                              :rules="inputRules"></v-text-field>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div class="pa-3">
                            <div>
                              Description<span class="error--text">*</span>
                            </div>
                            <div class="black--text">
                              <ckeditor :editor="editor" v-model="newAsk.description" :config="editorConfig"
                                :rules="inputRules"></ckeditor>
                            </div>
                          </div>
                        </v-flex>
                        <v-flex xs12 md4>
                          <div class="pa-3">
                            <div><b>Amount Requested</b></div>
                              <v-text-field class="text_field background" outlined dense v-model="newAsk.amount"
                              :rules="inputRules" type="number" min="0"></v-text-field>
                          </div>
                        </v-flex>
                        <v-flex xs12 md4>
                          <div class="pa-3">
                            <div><b>Funding Type</b></div>
                                <v-select
                                :items="formData.funding_types"
                                item-text="name"
                                item-value="id"
                                v-model="newAsk.funding_type_id"
                                class="background org_text_field"
                                dense
                                outlined
                                height="10"
                                >
                                </v-select>
                          </div>
                        </v-flex>
                        <v-flex xs12 md4>
                          <div class="pa-3">
                            <div><b>Tech Readiness Level</b></div>
                                <v-select
                                :items="formData.tech_readiness_levels"
                                item-text="name"
                                item-value="id"
                                v-model="newAsk.tech_readiness_level_id"
                                class="background org_text_field"
                                dense
                                outlined
                                height="10"
                                >
                                </v-select>
                          </div>
                        </v-flex>
                        
                      </v-layout>
                    </v-flex>
                    <v-card-title class="tableHeader text--text mt-10">
                      <div align="center">
                        Innovation
                      </div>
                    </v-card-title>
                    <v-flex xs12 class="mt-5">
                      <div class="mt-5">
                        <v-combobox v-model="newAsk.innovations" :items="formData.innovations" item-text="name"
                          item-value="id" chips clearable multiple prepend-inner-icon="mdi-office-building-outline"
                          outlined class="login_field mb-10">
                          <template v-slot:selection="{ attrs, item, select, selected }">
                            <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                              @click:close="removeInnovation(item)">
                              <strong>{{ item.name }}</strong>
                            </v-chip>
                          </template>
                        </v-combobox>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed class="primary text-none mt-3" @click="save" :disabled="!valid" :loading="loading">
                  Save
                  <v-icon right> mdi-content-save </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </div>
        <div v-if="view == 'show'">
          <v-card outlined class="mt-2">
            <v-card-title class="tableHeader text--text">
              {{ assignedAsk.title }}
              <v-spacer></v-spacer>
              <v-btn icon @click="changeView('open')">
                <v-icon class="text--text"> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div class="pa-3">
                <v-tabs show-arrows v-model="callTab">
                  <v-tabs-slider color="primary"></v-tabs-slider>
  
                  <v-tab @click="activeTab = 'details'">
                    <div class="text-none text--text">Details</div>
                  </v-tab>
                  <!-- <v-tab @click="activeTab = 'askResponses'" v-if="$can('ask_reponse_view')">
                    <div class="text-none">
                      Ask Reponses ({{askResponsePagination.total}})
                    </div>
                  </v-tab> -->
                </v-tabs>
                <div v-if="activeTab == 'details'" class="mx-3 mt-2">
                  <v-layout row wrap>
                        <v-flex xs12 md3>
                          <div class="mr-8 mt-5">
                            <v-img
                              max-height="150"
                              v-if="assignedAsk.featured_image==null"
                              src="img/logo_primary.png"
                              style="border-radius: 25px;"
                            >
                            </v-img>
                            <v-img
                              max-height="150"
                              v-else
                              :src="path+'/storage/ask_pics/'+assignedAsk.featured_image"
                              style="border-radius: 25px;"
                            >
                            </v-img>
                          </div>
                          <div align="center" class="mt-5">
                            <v-btn class="primary text-none" depressed @click="imageDialog = true" v-if="$can('ask_image_upload')">
                              Upload Image
                              <v-icon right>mdi-file-upload</v-icon>
                            </v-btn>
                          </div>
                          
                        </v-flex>
                        <v-flex xs12 md9>
                  
                              <v-layout column>
                                <v-flex xs12 class="mt-7">
                                  <v-layout row wrap>
                                    <v-flex xs12 md2>
                                      <div><b>Action URL:</b></div>
                                    </v-flex>
                                    <v-flex xs12 md10>
                                      <div v-if="assignedAsk.action_url == null">
                                        N/A
                                      </div>
                                      <div v-else>
                                        {{ assignedAsk.action_url }}
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-5">
                                  <v-layout row wrap>
                                    <v-flex xs12 md2>
                                      <div><b>Creation Date:</b></div>
                                    </v-flex>
                                    <v-flex xs12 md10>
                                      {{
                                        assignedAsk.created_at
                                          | moment("DD/MM/YYYY - hh:mm a")
                                      }}
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-5">
                                  <v-layout row wrap>
                                    <v-flex xs12 md2>
                                      <div><b>Status:</b></div>
                                    </v-flex>
                                    <v-flex xs12 md10>
                                      <div v-if="assignedAsk.status == 1">Active</div>
                                      <div v-else>Inactive</div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-12">
                                  <v-layout row wrap>
                                    <v-flex xs12 md2>
                                      <div><b>Amount Requested:</b></div>
                                    </v-flex>
                                    <v-flex xs12 md10>
                                      Kshs {{ assignedAsk.amount | formatNumber }}
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-6">
                                  <v-layout row wrap>
                                    <v-flex xs12 md2>
                                      <div><b>Brief:</b></div>
                                    </v-flex>
                                    <v-flex xs12 md10>
                                      {{ assignedAsk.brief }}
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-6">
                                  <v-layout row wrap>
                                    <v-flex xs12 md2>
                                      <b>Description: </b>
                                    </v-flex>
                                    <v-flex xs12 md10>
                                      <p v-html="assignedAsk.description"></p>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12>
                                <v-layout row wrap>
                                  <v-flex xs12 md2>
                                    <div class="mt-12"><b>Visibility:</b></div>
                                  </v-flex>
                                  <v-flex xs12 md10>
                                    <v-layout row wrap>
                                      <v-flex xs2>
                                        <v-switch
                                          class="mt-13 ml-3"
                                          v-model="assignedAsk.visibility"
                                          :loading="activateLoading"
                                          @change="updateVisibility(assignedAsk)"
                                        ></v-switch>
                                      </v-flex>
                                      <v-flex xs10>
                                        <div class="grey--text ms-4 mt-14" v-if="assignedAsk.visibility == 1">
                                        <b>Public</b>
                                      </div>
                                      <div class="grey--text ms-4 mt-14" v-if="assignedAsk.visibility == 0">
                                        <b>Private</b>
                                      </div>
                                      </v-flex>
                                    </v-layout>
                                    
                                      
                                  </v-flex>
                                  
                                </v-layout>
                              </v-flex>
                              </v-layout>
                        </v-flex>
                      </v-layout>
                </div>
                <div v-if="activeTab == 'askResponses'" class="mt-2">
                  <div v-if="askView == 'default'">
                      <v-card elevation="0" class="mt-5">
                        <v-flex xs12 class="mt-10 mb-2">
                          <div class="mx-5">
                            <v-layout row wrap>
                              <v-flex xs12 md4> </v-flex>
                              <v-flex xs12 md4>
                                <div class="pa-1">
                                  <v-text-field
                                    class="text_field header"
                                    outlined
                                    dense
                                    label="Search"
                                    append-icon="mdi-undo-variant"
                                    @click:append="
                                      resetAskResponseSearch()
                                    "
                                    v-on:keyup.enter="
                                      askResponseSearch
                                    "
                                    v-model="askResponseSearchTerm"
                                    @input="enableInstitutionAffiliationSearch()"
                                  ></v-text-field>
                                </div>
                              </v-flex>
                              <v-flex xs12 md2>
                                <div class="pa-1">
                                  <v-btn
                                    class="secondary primary--text text-none"
                                    depressed
                                    block
                                    @click="askResponseSearch"
                                    :loading="askResponseSearchLoader"
                                    :disabled="
                                      askResponseSearchButtonDisabled
                                    "
                                  >
                                    Search
                                    <v-icon right>mdi-magnify</v-icon>
                                  </v-btn>
                                </div>
                              </v-flex>
                              <v-flex xs12 md2>
                                <div class="pa-1">
                                  
                                </div>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-flex>
                        <v-divider class="mt-9 mx-5"></v-divider>
                        <v-flex xs12 class="mt-5">
                          <div v-if="askResponses.length == 0">
                            <v-card elevation="0">
                              <v-layout row wrap>
                                <v-flex xs12 md1>
                                  <v-container fill-height fluid>
                                    <v-row align="center" justify="center">
                                      <v-col class="text-center">
                                        <v-icon large class="primary--text">
                                          mdi-alert-circle-outline
                                        </v-icon>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-flex>
                                <v-flex xs12 md11>
                                  <v-container fill-height fluid>
                                    <v-row align="center" justify="center">
                                      <v-col class="text-center">
                                        No Responses Found
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </div>
                          <div v-else>
                            <div class="hidden-sm-and-down">
                              <v-card elevation="0" class="pa-2">
                                <v-simple-table>
                                  <template v-slot:default>
                                    <thead class="header">
                                      <tr class="secondary">
                                        <th class="text-left">Responder</th>
                                        <th class="text-left">Date Created</th>
                                        <th class="text-left">Last Update</th>
                                        <th class="text-right">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="item in askResponses"
                                        :key="item.id"
                                      >
                                        <td>
                                          {{ item.user.f_name }} {{ item.user.m_name }} {{ item.user.l_name }}
                                        </td>
                                        <td>
                                          {{ item.created_at | moment("DD/MM/YYYY - hh:mm a")}}
                                        </td>
                                        <td>
                                          {{ item.updated_at | moment("DD/MM/YYYY - hh:mm a") }}
                                        </td>
                                        <td>
                                          <div align="right">
                                            <v-btn
                                              icon
                                              @click="
                                                showAskResponse(
                                                  item
                                                )
                                              "
                                              class="button mr-1 universal--text"
                                            >
                                              <v-icon small> mdi-eye </v-icon>
                                            </v-btn>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </v-card>
                            </div>
                            <div class="hidden-md-and-up">
                              <div>
                                <v-layout column>
                                  <template
                                    v-for="(
                                      ask, index
                                    ) in askResponses"
                                  >
                                    <div :key="index">
                                      <v-flex xs12 class="mb-2">
                                        <v-card elevation="0">
                                          <div class="pa-5">
                                            <v-layout column>
                                              <v-flex xs12>
                                                <v-layout row wrap>
                                                  <v-flex xs12>
                                                    <div
                                                      class="title primary--text"
                                                    >
                                                      <b>{{ ask.user.f_name }} {{ ask.user.m_name }} {{ ask.user.l_name }}</b>
                                                    </div>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                              <v-divider class="mt-2"></v-divider>
                                              <v-flex xs12 class="mt-1">
                                                <v-layout column>
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs4>
                                                        <b>Date Created</b>
                                                      </v-flex>
                                                      <v-flex xs8>
                                                        {{ ask.created_at }}
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs4>
                                                        <b>Last Update</b>
                                                      </v-flex>
                                                      <v-flex xs8>
                                                        {{ ask.updated_at }}
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs12>
                                                        <div>
                                                          <v-btn
                                                            depressed
                                                            class="primary text-none white--text mt-3"
                                                            block
                                                            @click="
                                                              showAskResponse(
                                                                ask
                                                              )
                                                            "
                                                            >View
                                                            <v-icon right>
                                                              mdi-eye
                                                            </v-icon>
                                                          </v-btn>
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                            </v-layout>
                                          </div>
                                        </v-card>
                                      </v-flex>
                                    </div>
                                  </template>
                                </v-layout>
                              </div>
                            </div>
                          </div>
                        </v-flex>
                      </v-card>
                      <v-layout column class="mt-5">
                        <v-flex xs12>
                          <div align="left">
                            <v-pagination
                              v-if="askResponseLength != 0"
                              :length="askResponseLength"
                              total-visible="10"
                              v-model="
                                askResponsePagination.current_page
                              "
                              @input="changeAskResponsePage()"
                              circle
                            >
                            </v-pagination>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div
                            v-if="askResponseLength != 0"
                            align="center"
                            class="mt-5"
                          >
                            <b class="text--text">Total: </b
                            >{{
                              askResponsePagination.total
                                | formatNumber
                            }}
                          </div>
                        </v-flex>
                      </v-layout>
                    </div>
                  <div v-if="askView == 'show'">
                    <v-card-title class="secondary primary--text mt-5">
                      Responder Details
                      <v-spacer></v-spacer>
                      <v-btn icon @click="askView = 'default'">
                        <v-icon class="primary--text"> mdi-close </v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <v-layout row rap class="mx-1 mt-5">
                        <v-flex xs12 md6>
                          <v-layout column>
                            <v-flex xs12>
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <b>Name:</b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  {{ assignedResponse.user.f_name }} {{ assignedResponse.user.m_name }} {{ assignedResponse.user.l_name }}
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-5">
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <b>Code:</b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  {{ assignedResponse.user.code }}
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-layout column>
                            <v-flex xs12>
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <b>Email Address:</b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  {{ assignedResponse.user.email }}
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-5">
                              <v-layout row wrap>
                                <v-flex xs12 md4>
                                  <b>Phone #:</b>
                                </v-flex>
                                <v-flex xs12 md8>
                                  {{ assignedResponse.user.phone }}
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 class="mt-5">
                          <v-layout row wrap>
                                <v-flex xs12 md2>
                                  <b>Bio:</b>
                                </v-flex>
                                <v-flex xs12 md10>
                                  <div class="ml-1">
                                    {{ assignedResponse.user.bio }}
                                  </div>
                                </v-flex>
                              </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                    <v-card-title class="secondary primary--text mt-5">
                      Response
                    </v-card-title>
                    <v-card-text>
                      <v-layout column>
                        <v-flex xs12 class="mt-3">
                          <div><b>1. Which Challenge does your solution address?</b></div>
                          <div v-html="assignedResponse.challenge_addressed"></div>
                        </v-flex>
                        <v-flex xs12 class="mt-3">
                          <div><b>2. Provide a one-line summary of your solution.</b></div>
                          <div>{{ assignedResponse.one_line_summary }}</div>
                        </v-flex>
                        <v-flex xs12 class="mt-3">
                          <div><b>3. What specific problem are you solving?</b></div>
                          <div v-html="assignedResponse.problem_solved"></div>
                        </v-flex>
                        <v-flex xs12 class="mt-3">
                          <div><b>4. What is your solution?</b></div>
                          <div v-html="assignedResponse.solution"></div>
                        </v-flex>
                        <v-flex xs12 class="mt-3">
                          <div><b>5. Who does your solution serve, and in what ways will the solution impact their lives?</b></div>
                          <div v-html="assignedResponse.target_audience"></div>
                        </v-flex>
                        <v-flex xs12 class="mt-3">
                          <div><b>6. How are you and your team well-positioned to deliver this solution?</b></div>
                          <div v-html="assignedResponse.positioning"></div>
                        </v-flex>
                        <v-flex xs12 class="mt-3">
                          <div><b>7. Why are you applying to Solve?</b></div>
                          <div v-html="assignedResponse.application_reason"></div>
                        </v-flex>
                        <v-flex xs12 class="mt-3">
                          <div><b>8. What makes your solution innovative?</b></div>
                          <div v-html="assignedResponse.innovative"></div>
                        </v-flex>
                        <v-flex xs12 class="mt-3">
                          <div><b>9. What are your impact goals for the next year and the next five years, and how will you achieve them?</b></div>
                          <div v-html="assignedResponse.impact_goals"></div>
                        </v-flex>
                        <v-flex xs12 class="mt-3">
                          <div><b>10. How are you measuring your progress toward your impact goals?</b></div>
                          <div v-html="assignedResponse.impact_goal_measure"></div>
                        </v-flex>
                        <v-flex xs12 class="mt-3">
                          <div><b>11. What is your theory of change?</b></div>
                          <div v-html="assignedResponse.change_theory"></div>
                        </v-flex>
                        <v-flex xs12 class="mt-3">
                          <div><b>12. Describe the core technology that powers your solution.</b></div>
                          <div v-html="assignedResponse.core_technology"></div>
                        </v-flex>
                        <v-flex xs12 class="mt-3">
                          <div><b>13. What is your approach to incorporating diversity, equity, and inclusivity into your work?</b></div>
                          <div v-html="assignedResponse.incorporation_approach"></div>
                        </v-flex>
                        <v-flex xs12 class="mt-3">
                          <div><b>14. What is your business model?</b></div>
                          <div v-html="assignedResponse.business_model"></div>
                        </v-flex>
                        <v-flex xs12 class="mt-3">
                          <div><b>15. What is your plan for becoming financially sustainable?</b></div>
                          <div v-html="assignedResponse.financial_stability"></div>
                        </v-flex>
                        <v-flex xs12 class="mt-3">
                          <div><b>16. Share some examples of how your plan to achieve financial sustainability has been successful so far.</b></div>
                          <div v-html="assignedResponse.financial_stability_success"></div>
                        </v-flex>
                        <v-flex xs12 class="mt-3">
                          <div><b>17. Link to Innovaton.</b></div>
                          <div> <a :href="
                            assignedResponse.innovation_link
                          " target="_blank" style="
                            text-decoration: none;
                          ">{{ assignedResponse.innovation_link }}</a> </div>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-container>
      <logData v-if="logDataModel" v-model="logDataModel" />
    </div>
  </template>
  <style>
  .ck-editor__editable {
    min-height: 200px;
  }
  </style>
  <script>
  import apiCall from "@/utils/api";
  import { mapGetters, mapActions } from "vuex";
  import logData from "@/views/accessControl/logData";
  import Vue from "vue";
  import CKEditor from "@ckeditor/ckeditor5-vue2";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  import uploadAdapter from '@/uploadAdapter.js';
  
  var numeral = require("numeral");
  Vue.filter("formatNumber", function (value) {
    return numeral(value).format("0,0");
  });
  
  Vue.use(require("vue-moment"));
  
  export default {
    components: {
      logData,
    },
    data() {
      return {
        path: process.env.VUE_APP_API_URL,
        view: "open",
        loading: false,
        activateLoading: false,
        deactivateLoading: false,
        askView: "default",
  
        activeTab: 'details',
        callTab: null,
  
        editor: ClassicEditor,
        editorConfig: {
            extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                return new uploadAdapter(loader);
            } }],
            language: 'en',
        },
  
        menu1: false,
        allowedStep: (m) => m % 15 === 0,
        valid: true,
        datePicker: false,
        message: "",
        color: "",
        delete: false,
        activate: false,
        confirm: false,
        askIndex: null,
        snackbar: false,
        inputRules: [(v) => !!v || "Input is required"],
  
        openAskSearchTerm: null,
        closedAskSearchTerm: null,
        askResponseSearchTerm: null,
        searchOpenAskLoader: false,
        searchClosedAskLoader: false,
        openAskSearchButtonDisabled: false,
        closedAskSearchButtonDisabled: false,
        activeAskOwnerTab: 'self',
        filteredOpenAsks: false,
        filteredClosedAsks: false,
  
        askResponseLoader: false,
        askResponseSearchLoader: false,
        filteredAskResponses: false,
        askResponseSearchButtonDisabled: false,

        imageDialog: false,
        imageLoading: false,
        fileRules: [(v) => !!v || "Kindly selecta a File"],
        imageFile: null,
        imageValid: true,
  
        viewTab: null,
        newAsk: {
          id: null,
          amount: null,
          funding_type_id: null,
          tech_readiness_level_id: null,
          title: null,
          brief: null,
          description: null,          
          innovations: [],
        },
        assignedAsk: null,
        assignedResponse: null,
        formData: null,
        months: [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
        ],
        monthNames: [
          { name: "January" },
          { name: "February" },
          { name: "March" },
          { name: "April" },
          { name: "May" },
          { name: "June" },
          { name: "July" },
          { name: "August" },
          { name: "September" },
          { name: "October" },
          { name: "November" },
          { name: "December" },
        ],
        askResponses: [],
        askResponsePagination: {
          search: " ",
          current_page: 1,
          per_page: 0,
          total: 0,
          visible: 10,
        },
        logData: {
          message: null,
          model: null,
        },
      };
    },
    created() {
      if (this.$can("ask_view")) {
        this.startOpenAskLoader();
        this.startClosedAskLoader();
        this.fetchOpenAsks(this.openAskPagination.current_page);
        this.fetchClosedAsks(this.closedAskPagination.current_page);
        this.getFormData();
      }
    },
    methods: {
      ...mapActions([
        "fetchOpenAsks",
        "fetchClosedAsks",
        "startOpenAskLoader",
        "startClosedAskLoader",
        "stopOpenAskLoader",
        "stopClosedAskLoader",
        "filterOpenAsks",
        "filterClosedAsks",

        "filterLogData",
        "openLogDataModel",
      ]),
      enableOpenAskSearch() {
        this.openAskSearchButtonDisabled = false;
      },
      enableClosedAskSearch() {
        this.closedAskSearchButtonDisabled = false;
      },
      closeDate() {
        this.datePicker = false;
      },
      removeInnovation(item) {
        this.newAsk.innovations.splice(this.newAsk.innovations.indexOf(item), 1)
      },
      resetAskResponseSearch() {
        this.askResponseSearchTerm = null;
        this.filteredAskResponses = false;
        this.askResponseLoader = false;
        this.changeAskResponsePage();
      },
      changeAskOwnerTab(tab) {
        if (tab == 'self') {
          this.newAsk.personal = true
        } else {
          this.newAsk.personal = false
        }
        this.newAsk.institutions = [],
          this.newAsk.organisations = [],
  
          this.activeAskOwnerTab = tab
      },
      resetOpenAskSearch() {
        this.openAskSearchTerm = null;
        this.filteredClosedAsks = true;
        this.startOpenAskLoader();
        this.fetchOpenAsks(1);
      },
      resetClosedAskSearch() {
        this.closedAskSearchTerm = null;
        this.filteredClosedAsks = true;
        this.stopClosedAskLoader();
        this.fetchClosedAsks(1);
      },
      showAsk(ask) {
        this.assignedAsk = ask;
        // if (this.$can("ask_reponse_view")) {
        //   this.changeAskResponsePage();
        // }
        this.changeView("show");
      },
      showAskResponse(response){
        this.assignedResponse = response
        this.askView = 'show'
      },
      askResponseSearch() {
        if (this.askResponseSearchTerm == null) {
          this.askResponseSearchButtonDisabled = true;
        } else {
          this.askResponseSearchLoader = true;
          this.askResponseSearchButtonDisabled = false;
          this.startOpenAskLoader();
          this.startClosedAskLoader();
          apiCall({
            url:
            "/api/ask-response?call_id=" +
              this.assignedAsk.id +
              "&page=1&type=search&search=" +
              this.askResponseSearchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.askResponses = resp.data;
              this.askResponsePagination.current_page =
                resp.current_page;
              this.askResponsePagination.total = resp.total;
              this.askResponsePagination.per_page = resp.per_page;
  
              this.askResponseSearchLoader = false;
              this.filteredAskResponses = true;
            })
            .catch((error) => {
              this.askResponseSearchLoader = false;
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              console.log(error);
            });
        }
      },
      changeAskResponsePage() {
        this.askResponseLoader = true;
        if (this.filteredAskResponses == true) {
          this.askResponseSearchButtonDisabled = false;
          apiCall({
            url:
              "/api/ask-response?call_id=" +
              this.assignedAsk.id +
              "&page=" +
              this.askResponsePagination.current_page +
              "&type=search&search=" +
              this.askResponseSearchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.askResponseLoader = false;
  
              this.askResponses = resp.data;
              this.askResponsePagination.current_page =
                resp.current_page;
              this.askResponsePagination.total = resp.total;
              this.askResponsePagination.per_page = resp.per_page;
            })
            .catch((error) => {
              console.log(error);
              this.askResponseLoader = false;
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
            });
        } else {
          apiCall({
            url:
              "/api/ask-response?call_id=" +
              this.assignedAsk.id +
              "&page=" +
              this.askResponsePagination.current_page,
            method: "GET",
          })
            .then((resp) => {
              this.askResponseLoader = false;
  
              this.askResponses = resp.data;
              this.askResponsePagination.current_page =
                resp.current_page;
              this.askResponsePagination.total = resp.total;
              this.askResponsePagination.per_page = resp.per_page;
            })
            .catch((error) => {
              console.log(error);
              this.askResponseLoader = false;
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
            });
        }
      },
      editAsk(ask) {
        this.newAsk.id = ask.id;
        this.newAsk.amount = ask.amount
        this.newAsk.funding_type_id = ask.funding_type_id
        this.newAsk.tech_readiness_level_id = ask.tech_readiness_level_id
        this.newAsk.title = ask.title
        this.newAsk.brief = ask.brief
        this.newAsk.description = ask.description

        if(ask.innovation_asks.length != 0){
          for (let i = 0; i < ask.innovation_asks.length; i++) {
            this.newAsk.innovations.push(ask.innovation_asks[i].innovation);
          }
          
        }

        this.changeView("create");
      },
      uploadImage() {
      if (this.$refs.imageForm.validate()) {
        this.imageLoading = true;
        let formData = new FormData();

        // files

        formData.append("files", this.imageFile, this.imageFile.name);
        formData.append("id", this.assignedAsk.id);

        apiCall({
          url: "/api/ask?type=image",
          data: formData,
          method: "POST",
        })
          .then((resp) => {
            this.snackbar = true;
            this.message = "Feature Image Uploaded Successfully";
            this.color = "success";
            this.fetchOpenAsks(this.openAskPagination.current_page);
            this.fetchClosedAsks(this.closedAskPagination.current_page);
            this.imageLoading = false;
            this.imageDialog = false;
            this.assignedAsk.featured_image = resp.featured_image;
            // this.changeView('default')
          })
          .catch((error) => {
            this.imageLoading = false;
          });
      }
    },
      save() {
        if (this.$refs.form.validate()) {
          if (this.newAsk.id != null) {
            confirm("Are You Sure You Want to Update Ask") &&
              (this.confirm = true);
            if (this.confirm) {
              this.loading = true;
              apiCall({
                url: "/api/ask/" + this.newAsk.id,
                data: this.newAsk,
                method: "PUT",
              })
                .then((resp) => {
                  this.message = "Ask Updated Successfully";
                  this.color = "orange";
                  this.loading = false;
                  this.snackbar = true;
                  this.confirm = false;
                  this.changeView("open");
                  this.fetchOpenAsks(1);
                })
                .catch((error) => {
                  this.message = "An Error Occurred";
                  this.color = "error";
                  this.loading = false;
                  this.snackbar = true;
                });
            }
          } else {
            confirm("Are You Sure You Want to Create Ask?") &&
              (this.confirm = true);
            if (this.confirm) {
              this.loading = true;
              apiCall({
                url: "/api/ask",
                data: this.newAsk,
                method: "POST",
              })
                .then((resp) => {
                  this.message = "Ask Added Successfully";
                  this.color = "success";
                  this.loading = false;
                  this.snackbar = true;
                  this.changeView("open");
                  this.fetchOpenAsks(1);
                })
                .catch((error) => {
                  this.message = "An Error Occurred";
                  this.color = "error";
                  this.loading = false;
                  this.snackbar = true;
                  this.fetchOpenAsks(1);
                });
            }
          }
        }
      },
      searchOpenAsk() {
        if (this.openAskSearchTerm == null) {
          this.openAskSearchButtonDisabled = true;
        } else {
          this.searchOpenAskLoader = true;
          this.openAskSearchButtonDisabled = false;
          this.stopOpenAskLoader();
          apiCall({
            url: "/api/ask?type=open&typeB=search&search=" + this.openAskSearchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.filterOpenAsks(resp);
              this.searchOpenAskLoader = false;
              this.stopOpenAskLoader();
              this.filteredOpenAsks = true;
            })
            .catch((error) => {
              console.log(error.response);
              this.searchOpenAskLoader = false;
              this.stopOpenAskLoader();
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
            });
        }
      },
      searchClosedAsk() {
        if (this.closedAskSearchTerm == null) {
          this.closedAskSearchButtonDisabled = true;
        } else {
          this.searchClosedAskLoader = true;
          this.closedAskSearchButtonDisabled = false;
          this.stopClosedAskLoader();
          apiCall({
            url: "/api/ask?type=closed&typeB=search&search=" + this.closedAskSearchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.filterClosedAsks(resp);
              this.searchClosedAskLoader = false;
              this.stopClosedAskLoader();
              this.filteredOpenAsks = true;
            })
            .catch((error) => {
              this.searchClosedAskLoader = false;
              this.stopClosedAskLoader();
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              console.log(error);
            });
        }
      },
      changeView(view) {
        if (view == "open") {
          this.newAsk.id = null;
          this.newAsk.amount = null
          this.newAsk.funding_type_id = null
          this.newAsk.tech_readiness_level_id = null
          this.newAsk.title = null
          this.newAsk.brief = null
          this.newAsk.description = null
          this.newAsk.innovations = []
          
        } else if (view == "show") {
  
        }
        this.view = view;
      },
      changeOpenAskPage() {
        this.startOpenAskLoader();
        if (this.filteredOpenAsks == true) {
          this.openAskSearchButtonDisabled = false;
          apiCall({
            url:
              "/api/ask?type=open&page=" +
              this.openAskPagination.current_page +
              "&typeB=search&search=" +
              this.openAskSearchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.filterOpenAsks(resp);
              this.stopOpenAskLoader();
            })
            .catch((error) => {
              this.stopOpenAskLoader();
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              console.log(error);
            });
        } else {
          this.fetchOpenAsks(this.openAskPagination.current_page);
        }
      },
      changeClosedAskPage() {
        this.startClosedAskLoader();
        if (this.filteredClosedAsks == true) {
          this.closedAskSearchButtonDisabled = false;
          apiCall({
            url:
              "/api/ask?type=closed&page=" +
              this.closedAskPagination.current_page +
              "&typeB=search&search=" +
              this.closedAskSearchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.filterClosedAsks(resp);
              this.stopClosedAskLoader();
            })
            .catch((error) => {
              this.stopClosedAskLoader();
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              console.log(error);
            });
        } else {
          this.fetchClosedAsks(this.closedAskPagination.current_page);
        }
      },
      deleteAsk(item) {
        confirm("Are You Sure You Want to Delete Ask?") && (this.delete = true);
        if (this.delete) {
          this.loading = true;
          this.askIndex = item.id;
          apiCall({ url: "/api/ask/" + item.id, method: "DELETE" })
            .then((resp) => {
              this.message = "Ask Deleted Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.askIndex = null;
              this.fetchOpenAsks(this.openAskPagination.current_page);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.askIndex = null;
              console.log(error.response);
            });
        }
      },
      updateVisibility(item){
      confirm("Are You Sure You Want to Update Public Visibility?") &&
        (this.activate = true);
      if (this.activate) {
        this.activateLoading = true;
        this.newAsk.id = item.id;
        apiCall({
          url: "/api/ask?type=visibility",
          data: this.newAsk,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Ask Public Visibility Updated";
            this.color = "success";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.fetchOpenAsks(this.openAskPagination.current_page);
            this.fetchClosedAsks(this.closedAskPagination.current_page);
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            console.log(error);
          });
      }
    },
      activateAsk(item) {
        confirm("Are You Sure You Want to Activate Ask?") &&
          (this.activate = true);
        if (this.activate) {
          this.activateLoading = true;
          this.askIndex = item.id;
          this.newAsk.id = item.id;
          apiCall({
            url: "/api/ask?type=activate",
            data: this.newAsk,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Ask Activated Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.activateLoading = false;
              this.activate = false;
              this.askIndex = null;
              this.fetchOpenAsks(this.openAskPagination.current_page);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.activateLoading = false;
              this.activate = false;
              this.askIndex = null;
              console.log(error.response);
            });
        }
      },
      deactivateAsk(item) {
        confirm("Are You Sure You Want to Deactivate Ask?") &&
          (this.activate = true);
        if (this.activate) {
          this.deactivateLoading = true;
          this.askIndex = item.id;
          this.newAsk.id = item.id;
          apiCall({
            url: "/api/ask?type=deactivate",
            data: this.newAsk,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Ask Account Deactivated Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.deactivateLoading = false;
              this.activate = false;
              this.askIndex = null;
              this.fetchOpenAsks(this.openAskPagination.current_page);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.deactivateLoading = false;
              this.activate = false;
              this.askIndex = null;
              console.log(error);
            });
        }
      },
      getFormData() {
        apiCall({
          url: "/api/ask?type=formData",
          method: "GET",
        })
          .then((resp) => {
            this.formData = resp;
          })
          .catch((error) => {
            this.message = "An Error Occurred; Couldn't Get Form Data";
            this.color = "error";
          });
      },
      viewLog(data) {
        this.logData.message = data.id;
        this.logData.model = "App\\Models\\Ask"
        this.filterLogData(this.logData);
        this.openLogDataModel();
      },
    },
    computed: {
      ...mapGetters([
        "openAsks", "openAskPagination", "openAskLoader",
        "closedAsks", "closedAskPagination", "closedAskLoader",
        "logDataModel"
      ]),
  
      openAskLength: function () {
        return Math.ceil(
          this.openAskPagination.total / this.openAskPagination.per_page
        );
      },
      closedAskLength: function () {
        return Math.ceil(
          this.closedAskPagination.total / this.closedAskPagination.per_page
        );
      },
      askResponseLength: function () {
        return Math.ceil(
          this.askResponsePagination.total / this.askResponsePagination.per_page
        );
      },
    },
  };
  </script>
  