<template>
  <div class="welcome">
    <v-layout column>
      <v-flex xs12 class="mb-5">
        <v-progress-linear
          v-if="organisationStatisticLoader"
          height="1"
          indeterminate
          color="primary"
        >
        </v-progress-linear>
        <v-parallax
          v-if="
            this.organisationStatisticPagination.current_page == 1 &&
            organisationStatistics.length != 0
          "
          dark
          class="hidden-sm-and-down"
          src="img/34.jpg"
          height="650"
        >
          <v-container>
            <v-layout row wrap>
              <v-flex xs2>
                <div
                  v-if="organisationStatistics[0].rank == null"
                  style="font-size: 22.2em"
                  class="font-weight-black white--text hidden-sm-and-down"
                >
                  1
                </div>
                <div
                  align="center"
                  v-else
                  style="font-size: 9.2em"
                  class="font-weight-black white--text hidden-sm-and-down mt-16"
                >
                  {{ organisationStatistics[0].rank + 1 }}
                </div>
              </v-flex>
              <v-flex xs10>
                <v-layout column>
                  <v-flex xs12 class="mt-16">
                    <v-layout row wrap>
                      <v-flex xs12 md6>
                        <v-layout column>
                          <v-flex xs12>
                            <div class="mx-5">
                              <h1 class="mt-16">
                                {{ organisationStatistics[0].organisation.name }}
                              </h1>
                              <v-divider class="white"></v-divider>
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div class="pa-8">
                              <v-layout column>
                                <v-flex xs12>
                                  <v-layout row wrap>
                                    <v-flex xs12 md6>
                                      <div><b>Total Innovations</b></div>
                                    </v-flex>
                                    <v-flex xs12 md6>
                                      <div v-if="time == 'All Time'">
                                        {{
                                          organisationStatistics[0]
                                            .total_innovations
                                        }}
                                      </div>
                                      <div v-if="time == '24 Hrs'">
                                        {{
                                          organisationStatistics[0]
                                            .day_innovations
                                        }}
                                      </div>
                                      <div v-if="time == 'This Week'">
                                        {{
                                          organisationStatistics[0]
                                            .week_innovations
                                        }}
                                      </div>
                                      <div v-if="time == 'This Month'">
                                        {{
                                          organisationStatistics[0]
                                            .month_innovations
                                        }}
                                      </div>
                                      <div v-if="time == 'This Year'">
                                        {{
                                          organisationStatistics[0]
                                            .year_innovations
                                        }}
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-7">
                                  <v-layout row wrap>
                                    <v-flex xs12 md6>
                                      <div><b>Affiliated Users</b></div>
                                    </v-flex>
                                    <v-flex xs12 md6>
                                      <div v-if="time == 'All Time'">
                                        {{
                                          organisationStatistics[0].total_users
                                        }}
                                      </div>
                                      <div v-if="time == '24 Hrs'">
                                        {{ organisationStatistics[0].day_users }}
                                      </div>
                                      <div v-if="time == 'This Week'">
                                        {{
                                          organisationStatistics[0].week_users
                                        }}
                                      </div>
                                      <div v-if="time == 'This Month'">
                                        {{
                                          organisationStatistics[0].month_users
                                        }}
                                      </div>
                                      <div v-if="time == 'This Year'">
                                        {{
                                          organisationStatistics[0].year_users
                                        }}
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <!-- <v-flex xs12 class="mt-7">
                                  <v-layout row wrap>
                                    <v-flex xs12 md6>
                                      <div><b>Connections Made</b></div>
                                    </v-flex>
                                    <v-flex xs12 md6>
                                      <div v-if="time == 'All Time'">
                                        {{
                                          organisationStatistics[0]
                                            .total_connections
                                        }}
                                      </div>
                                      <div v-if="time == '24 Hrs'">
                                        {{
                                          organisationStatistics[0]
                                            .day_connections
                                        }}
                                      </div>
                                      <div v-if="time == 'This Week'">
                                        {{
                                          organisationStatistics[0]
                                            .week_connections
                                        }}
                                      </div>
                                      <div v-if="time == 'This Month'">
                                        {{
                                          organisationStatistics[0]
                                            .month_connections
                                        }}
                                      </div>
                                      <div v-if="time == 'This Year'">
                                        {{
                                          organisationStatistics[0]
                                            .year_connections
                                        }}
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex> -->
                              </v-layout>
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div class="mx-5">
                              <h3 class="mt-5">Top 10 Sectors</h3>
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div class="pa-3">
                              <v-layout column>
                                <v-flex xs12>
                                  <div
                                    v-if="
                                      organisationStatistics[0]
                                        .organisation_sector_stats.length != 0
                                    "
                                  >
                                    <template
                                      v-for="(
                                        organisation_sector, index
                                      ) in organisationStatistics[0].organisation_sector_stats.slice(
                                        0,
                                        10
                                      )"
                                    >
                                      <v-chip
                                        small
                                        :color="organisation_sector.sector.color"
                                        class="white--text mr-1"
                                        :key="index"
                                      >
                                        <div>
                                          #{{ organisation_sector.sector.name }}
                                          <b v-if="time == 'All Time'"
                                            >({{ organisation_sector.total }})</b
                                          >
                                          <b v-if="time == '24 Hrs'"
                                            >({{ organisation_sector.day }})</b
                                          >
                                          <b v-if="time == 'This Week'"
                                            >({{ organisation_sector.week }})</b
                                          >
                                          <b v-if="time == 'This Month'"
                                            >({{ organisation_sector.month }})</b
                                          >
                                          <b v-if="time == 'This Year'"
                                            >({{ organisation_sector.year }})</b
                                          >
                                        </div>
                                      </v-chip>
                                    </template>
                                  </div>
                                </v-flex>
                              </v-layout>
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div align="center" class="mt-5">
                              <v-btn
                                outlined
                                class="white--text text-none"
                                router
                                @click="
                                  goToOrganisation(
                                    organisationStatistics[0].organisation
                                  )
                                "
                                >Visit Page</v-btn
                              >
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-layout column>
                          <v-flex xs12>
                            <div align="center" class="mt-10">
                              <v-img
                                width="350"
                                height="350"
                                v-if="organisationStatistics[0].organisation.logo==null"
                                src="img/organisation.png"
                                style="border-radius: 25px;"
                              >
                              </v-img>
                              <v-img
                                width="350"
                                height="350"
                                v-else
                                :src="path+'/storage/organisation_pictures/'+organisationStatistics[0].organisation.logo"
                                style="border-radius: 25px;"
                              >
                              </v-img>
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div align="center" class="mt-5" v-if="organisationStatistics[0].organisation.organisation_contact != null">
                              <template>
                                <a
                                  v-if="organisationStatistics[0].organisation.organisation_contact.fb_url != null"
                                  :href="organisationStatistics[0].organisation.organisation_contact.fb_url"
                                  target="_blank"
                                  style="text-decoration: none"
                                >
                                  <v-btn class="mx-2" icon>
                                    <v-icon size="25px" color="white"> mdi-facebook </v-icon>
                                  </v-btn>
                                </a>
                                <a
                                  v-if="organisationStatistics[0].organisation.organisation_contact.twitter_url != null"
                                  :href="organisationStatistics[0].organisation.organisation_contact.twitter_url"
                                  target="_blank"
                                  style="text-decoration: none"
                                >
                                  <v-btn class="mx-2" icon>
                                    <v-icon size="25px" color="white"> mdi-twitter </v-icon>
                                  </v-btn>
                                </a>
                                <a
                                  v-if="organisationStatistics[0].organisation.organisation_contact.linkedin_url != null"
                                  :href="organisationStatistics[0].organisation.organisation_contact.linkedin_url"
                                  target="_blank"
                                  style="text-decoration: none"
                                >
                                  <v-btn class="mx-2" icon>
                                    <v-icon size="25px" color="white"> mdi-linkedin </v-icon>
                                  </v-btn>
                                </a>
                                <a
                                  v-if="organisationStatistics[0].organisation.organisation_contact.insta_url != null"
                                  :href="organisationStatistics[0].organisation.organisation_contact.insta_url"
                                  target="_blank"
                                  style="text-decoration: none"
                                >
                                  <v-btn class="mx-2" icon>
                                    <v-icon size="25px" color="white"> mdi-instagram </v-icon>
                                  </v-btn>
                                </a>
                                <a
                                  v-if="organisationStatistics[0].organisation.organisation_contact.web_url != null"
                                  :href="organisationStatistics[0].organisation.organisation_contact.web_url"
                                  target="_blank"
                                  style="text-decoration: none"
                                >
                                  <v-btn class="mx-2" icon>
                                    <v-icon size="25px" color="white"> mdi-web </v-icon>
                                  </v-btn>
                                </a>
                                
                              </template>
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-parallax>
        <v-parallax
          v-if="
            this.organisationStatisticPagination.current_page == 1 &&
            organisationStatistics.length != 0
          "
          dark
          height="1100"
          class="hidden-md-and-up"
          src="img/34.jpg"
        >
          <v-container>
            <v-layout row wrap>
              <v-flex xs12>
                <div
                  align="center"
                  style="font-size: 7em"
                  class="font-weight-black white--text"
                >
                  1
                </div>
              </v-flex>
              <v-flex xs12>
                <v-layout column>
                  <v-flex xs12>
                    <v-layout row wrap>
                      <v-flex xs12 md6>
                        <v-layout column>
                          <v-flex xs12>
                            <div class="mx-5">
                              <h1 class="mt-5">
                                {{ organisationStatistics[0].organisation.name }}
                              </h1>
                              <v-divider class="white"></v-divider>
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div class="pa-8">
                              <v-layout column>
                                <v-flex xs12>
                                  <v-layout row wrap>
                                    <v-flex xs6>
                                      <div><b>Total Innovations</b></div>
                                    </v-flex>
                                    <v-flex xs6>
                                      <div v-if="time == 'All Time'">
                                        {{
                                          organisationStatistics[0]
                                            .total_innovations
                                        }}
                                      </div>
                                      <div v-if="time == '24 Hrs'">
                                        {{
                                          organisationStatistics[0]
                                            .day_innovations
                                        }}
                                      </div>
                                      <div v-if="time == 'This Week'">
                                        {{
                                          organisationStatistics[0]
                                            .week_innovations
                                        }}
                                      </div>
                                      <div v-if="time == 'This Month'">
                                        {{
                                          organisationStatistics[0]
                                            .month_innovations
                                        }}
                                      </div>
                                      <div v-if="time == 'This Year'">
                                        {{
                                          organisationStatistics[0]
                                            .year_innovations
                                        }}
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 class="mt-7">
                                  <v-layout row wrap>
                                    <v-flex xs6>
                                      <div><b>Affiliated Users</b></div>
                                    </v-flex>
                                    <v-flex xs6>
                                      <div v-if="time == 'All Time'">
                                        {{
                                          organisationStatistics[0].total_users
                                        }}
                                      </div>
                                      <div v-if="time == '24 Hrs'">
                                        {{ organisationStatistics[0].day_users }}
                                      </div>
                                      <div v-if="time == 'This Week'">
                                        {{
                                          organisationStatistics[0].week_users
                                        }}
                                      </div>
                                      <div v-if="time == 'This Month'">
                                        {{
                                          organisationStatistics[0].month_users
                                        }}
                                      </div>
                                      <div v-if="time == 'This Year'">
                                        {{
                                          organisationStatistics[0].year_users
                                        }}
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <!-- <v-flex xs12 class="mt-7">
                                  <v-layout row wrap>
                                    <v-flex xs6>
                                      <div><b>Connections Made</b></div>
                                    </v-flex>
                                    <v-flex xs6>
                                      <div v-if="time == 'All Time'">
                                        {{
                                          organisationStatistics[0]
                                            .total_connections
                                        }}
                                      </div>
                                      <div v-if="time == '24 Hrs'">
                                        {{
                                          organisationStatistics[0]
                                            .day_connections
                                        }}
                                      </div>
                                      <div v-if="time == 'This Week'">
                                        {{
                                          organisationStatistics[0]
                                            .week_connections
                                        }}
                                      </div>
                                      <div v-if="time == 'This Month'">
                                        {{
                                          organisationStatistics[0]
                                            .month_connections
                                        }}
                                      </div>
                                      <div v-if="time == 'This Year'">
                                        {{
                                          organisationStatistics[0]
                                            .year_connections
                                        }}
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex> -->
                              </v-layout>
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div class="mx-5">
                              <h3 class="mt-5">Top 10 Sectors</h3>
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div class="pa-3">
                              <v-layout column>
                                <v-flex xs12>
                                  <div
                                    v-if="
                                      organisationStatistics[0]
                                        .organisation_sector_stats.length != 0
                                    "
                                  >
                                    <template
                                      v-for="(
                                        organisation_sector, index
                                      ) in organisationStatistics[0].organisation_sector_stats.slice(
                                        0,
                                        10
                                      )"
                                    >
                                      <v-chip
                                        x-small
                                        :color="organisation_sector.sector.color"
                                        class="white--text mr-1"
                                        :key="index"
                                      >
                                        <div>
                                          #{{ organisation_sector.sector.name }}
                                          <b v-if="time == 'All Time'"
                                            >({{ organisation_sector.total }})</b
                                          >
                                          <b v-if="time == '24 Hrs'"
                                            >({{ organisation_sector.day }})</b
                                          >
                                          <b v-if="time == 'This Week'"
                                            >({{ organisation_sector.week }})</b
                                          >
                                          <b v-if="time == 'This Month'"
                                            >({{ organisation_sector.month }})</b
                                          >
                                          <b v-if="time == 'This Year'"
                                            >({{ organisation_sector.year }})</b
                                          >
                                        </div>
                                      </v-chip>
                                    </template>
                                  </div>
                                </v-flex>
                              </v-layout>
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div align="center" class="mt-5">
                              <v-btn
                                outlined
                                class="white--text text-none"
                                router
                                @click="
                                  goToOrganisation(
                                    organisationStatistics[0].organisation
                                  )
                                "
                                >Visit Page</v-btn
                              >
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-layout column>
                          <v-flex xs12>
                            <div align="center">
                              <v-img
                                width="350"
                                height="350"
                                src="img/organisation.png"
                                style="border-radius: 25px;"
                              >
                              </v-img>
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <div align="center" class="mt-5" v-if="organisationStatistics[0].organisation.organisation_contact != null">
                              <template>
                                <a
                                  v-if="organisationStatistics[0].organisation.organisation_contact.fb_url != null"
                                  :href="organisationStatistics[0].organisation.organisation_contact.fb_url"
                                  target="_blank"
                                  style="text-decoration: none"
                                >
                                  <v-btn class="mx-2" icon>
                                    <v-icon size="25px" color="white"> mdi-facebook </v-icon>
                                  </v-btn>
                                </a>
                                <a
                                  v-if="organisationStatistics[0].organisation.organisation_contact.twitter_url != null"
                                  :href="organisationStatistics[0].organisation.organisation_contact.twitter_url"
                                  target="_blank"
                                  style="text-decoration: none"
                                >
                                  <v-btn class="mx-2" icon>
                                    <v-icon size="25px" color="white"> mdi-twitter </v-icon>
                                  </v-btn>
                                </a>
                                <a
                                  v-if="organisationStatistics[0].organisation.organisation_contact.linkedin_url != null"
                                  :href="organisationStatistics[0].organisation.organisation_contact.linkedin_url"
                                  target="_blank"
                                  style="text-decoration: none"
                                >
                                  <v-btn class="mx-2" icon>
                                    <v-icon size="25px" color="white"> mdi-linkedin </v-icon>
                                  </v-btn>
                                </a>
                                <a
                                  v-if="organisationStatistics[0].organisation.organisation_contact.insta_url != null"
                                  :href="organisationStatistics[0].organisation.organisation_contact.insta_url"
                                  target="_blank"
                                  style="text-decoration: none"
                                >
                                  <v-btn class="mx-2" icon>
                                    <v-icon size="25px" color="white"> mdi-instagram </v-icon>
                                  </v-btn>
                                </a>
                                <a
                                  v-if="organisationStatistics[0].organisation.organisation_contact.web_url != null"
                                  :href="organisationStatistics[0].organisation.organisation_contact.web_url"
                                  target="_blank"
                                  style="text-decoration: none"
                                >
                                  <v-btn class="mx-2" icon>
                                    <v-icon size="25px" color="white"> mdi-web </v-icon>
                                  </v-btn>
                                </a>
                                
                              </template>
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-parallax>
      </v-flex>
      <v-flex xs12>
        <v-container>
          <v-layout row wrap>
            <v-flex xs12 md9>
              <v-card outlined
                class="mx-4 mt-5 pa-3"
                style="border-radius: 25px;"
                v-if="organisationStatistics.length >1"
              >
                <v-layout column>
                  <div v-if="organisationStatistics.length != 0">
                    <template
                      v-for="(
                        organisationStatistic, index
                      ) in organisationStatistics"
                    >
                      <div :key="index">
                        <v-flex xs12>
                          <v-card
                            outlined
                            class="mb-3"
                            v-if="resultNumber + index + 1 != 1"
                            style="border-radius: 25px;"
                          >
                          <v-card-text>
                            <v-layout row wrap>
                              <v-flex xs2>
                                <div
                                  v-if="organisationStatistic.rank == null"
                                  align="center"
                                  class="display-1 mt-3 font-weight-black hidden-sm-and-down"
                                >
                                {{ resultNumber + index + 1 }}
                                </div>
                                <div
                                  v-if="organisationStatistic.rank != null"
                                  align="center"
                                  class="display-1 mt-3 font-weight-black hidden-sm-and-down"
                                >
                                  {{ organisationStatistic.rank }}
                                </div>
                                <div
                                  v-if="organisationStatistic.rank == null"
                                  align="center"
                                  class="display-1 mt-1 font-weight-black hidden-md-and-up mt-5"
                                >
                                  {{ resultNumber + index + 1 }}
                                </div>
                                <div
                                  v-if="organisationStatistic.rank != null"
                                  align="center"
                                  class="display-1 mt-1 font-weight-black hidden-md-and-up mt-5"
                                >
                                  {{ organisationStatistic.rank }}
                                </div>
                              </v-flex>
                              <v-flex xs10>
                                <v-layout column>
                                  <v-flex xs12>
                                    <v-layout row wrap>
                                      <v-flex xs12 md6>
                                        <v-layout column>
                                          <v-flex xs12>
                                            <div class="mx-5">
                                              <div class="display-1 mt-5">
                                                {{
                                                  organisationStatistic
                                                    .organisation.name
                                                }}
                                              </div>
                                              <v-divider
                                                class="white"
                                              ></v-divider>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="pa-8">
                                              <v-layout column>
                                                <v-flex xs12>
                                                  <v-layout row wrap>
                                                    <v-flex xs12 md6>
                                                      <div>
                                                        <b>Total Innovations</b>
                                                      </div>
                                                    </v-flex>
                                                    <v-flex xs12 md6>
                                                      <div
                                                        v-if="
                                                          time == 'All Time'
                                                        "
                                                      >
                                                        {{
                                                          organisationStatistic.total_innovations
                                                        }}
                                                      </div>
                                                      <div
                                                        v-if="time == '24 Hrs'"
                                                      >
                                                        {{
                                                          organisationStatistic.day_innovations
                                                        }}
                                                      </div>
                                                      <div
                                                        v-if="
                                                          time == 'This Week'
                                                        "
                                                      >
                                                        {{
                                                          organisationStatistic.week_innovations
                                                        }}
                                                      </div>
                                                      <div
                                                        v-if="
                                                          time == 'This Month'
                                                        "
                                                      >
                                                        {{
                                                          organisationStatistic.month_innovations
                                                        }}
                                                      </div>
                                                      <div
                                                        v-if="
                                                          time == 'This Year'
                                                        "
                                                      >
                                                        {{
                                                          organisationStatistic.year_innovations
                                                        }}
                                                      </div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-7">
                                                  <v-layout row wrap>
                                                    <v-flex xs12 md6>
                                                      <div>
                                                        <b>Affiliated Users</b>
                                                      </div>
                                                    </v-flex>
                                                    <v-flex xs12 md6>
                                                      <div
                                                        v-if="
                                                          time == 'All Time'
                                                        "
                                                      >
                                                        {{
                                                          organisationStatistic.total_users
                                                        }}
                                                      </div>
                                                      <div
                                                        v-if="time == '24 Hrs'"
                                                      >
                                                        {{
                                                          organisationStatistic.day_users
                                                        }}
                                                      </div>
                                                      <div
                                                        v-if="
                                                          time == 'This Week'
                                                        "
                                                      >
                                                        {{
                                                          organisationStatistic.week_users
                                                        }}
                                                      </div>
                                                      <div
                                                        v-if="
                                                          time == 'This Month'
                                                        "
                                                      >
                                                        {{
                                                          organisationStatistic.month_users
                                                        }}
                                                      </div>
                                                      <div
                                                        v-if="
                                                          time == 'This Year'
                                                        "
                                                      >
                                                        {{
                                                          organisationStatistic.year_users
                                                        }}
                                                      </div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <!-- <v-flex xs12 class="mt-7">
                                                  <v-layout row wrap>
                                                    <v-flex xs12 md6>
                                                      <div>
                                                        <b>Connections Made</b>
                                                      </div>
                                                    </v-flex>
                                                    <v-flex xs12 md6>
                                                      <div
                                                        v-if="
                                                          time == 'All Time'
                                                        "
                                                      >
                                                        {{
                                                          organisationStatistic.total_connections
                                                        }}
                                                      </div>
                                                      <div
                                                        v-if="time == '24 Hrs'"
                                                      >
                                                        {{
                                                          organisationStatistic.day_connections
                                                        }}
                                                      </div>
                                                      <div
                                                        v-if="
                                                          time == 'This Week'
                                                        "
                                                      >
                                                        {{
                                                          organisationStatistic.week_connections
                                                        }}
                                                      </div>
                                                      <div
                                                        v-if="
                                                          time == 'This Month'
                                                        "
                                                      >
                                                        {{
                                                          organisationStatistic.month_connections
                                                        }}
                                                      </div>
                                                      <div
                                                        v-if="
                                                          time == 'This Year'
                                                        "
                                                      >
                                                        {{
                                                          organisationStatistic.year_connections
                                                        }}
                                                      </div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex> -->
                                              </v-layout>
                                            </div>
                                          </v-flex>
                                          <v-flex
                                            xs12
                                            v-if="
                                              organisationStatistic
                                                .organisation_sector_stats
                                                .length != 0
                                            "
                                          >
                                            <div class="mx-5">
                                              <h3 class="mt-5">
                                                Top 10 Sectors
                                              </h3>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="pa-3">
                                              <v-layout column>
                                                <v-flex xs12>
                                                  <div
                                                    v-if="
                                                      organisationStatistic
                                                        .organisation_sector_stats
                                                        .length != 0
                                                    "
                                                  >
                                                    <template
                                                      v-for="(
                                                        organisation_sector,
                                                        index
                                                      ) in organisationStatistic.organisation_sector_stats.slice(
                                                        0,
                                                        10
                                                      )"
                                                    >
                                                      <v-chip
                                                        x-small
                                                        :color="
                                                          organisation_sector
                                                            .sector.color
                                                        "
                                                        class="white--text mr-1"
                                                        :key="index"
                                                      >
                                                        <div>
                                                          #{{
                                                            organisation_sector
                                                              .sector.name
                                                          }}
                                                          <b
                                                            v-if="
                                                              time == 'All Time'
                                                            "
                                                            >({{
                                                              organisation_sector.total
                                                            }})</b
                                                          >
                                                          <b
                                                            v-if="
                                                              time == '24 Hrs'
                                                            "
                                                            >({{
                                                              organisation_sector.day
                                                            }})</b
                                                          >
                                                          <b
                                                            v-if="
                                                              time ==
                                                              'This Week'
                                                            "
                                                            >({{
                                                              organisation_sector.week
                                                            }})</b
                                                          >
                                                          <b
                                                            v-if="
                                                              time ==
                                                              'This Month'
                                                            "
                                                            >({{
                                                              organisation_sector.month
                                                            }})</b
                                                          >
                                                          <b
                                                            v-if="
                                                              time ==
                                                              'This Year'
                                                            "
                                                            >({{
                                                              organisation_sector.year
                                                            }})</b
                                                          >
                                                        </div>
                                                      </v-chip>
                                                    </template>
                                                  </div>
                                                </v-flex>
                                              </v-layout>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div
                                              align="center"
                                              class="mt-5 mb-5"
                                            >
                                              <v-btn
                                                outlined
                                                class="text-none"
                                                @click="
                                                  goToOrganisation(
                                                    organisationStatistic.organisation
                                                  )
                                                "
                                                >Visit Page</v-btn
                                              >
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 md6>
                                        <v-layout column>
                                          <v-flex xs12>
                                            <div align="center" class="pa-5">
                                              <v-img
                                                width="350"
                                                height="350"
                                                v-if="organisationStatistic.organisation.logo==null"
                                                src="img/organisation.png"
                                                style="border-radius: 25px;"
                                              >
                                              </v-img>
                                              <v-img
                                                width="350"
                                                height="350"
                                                v-else
                                                :src="path+'/storage/organisation_pictures/'+organisationStatistic.organisation.logo"
                                                style="border-radius: 25px;"
                                              >
                                              </v-img>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <v-flex xs12>
                                              <div align="center" class="my-5" v-if="organisationStatistic.organisation.organisation_contact != null">
                                                <template>
                                                  <a
                                                    v-if="organisationStatistic.organisation.organisation_contact.fb_url != null"
                                                    :href="organisationStatistic.organisation.organisation_contact.fb_url"
                                                    target="_blank"
                                                    style="text-decoration: none"
                                                  >
                                                    <v-btn class="mx-2" icon>
                                                      <v-icon size="25px" color="#3b5998"> mdi-facebook </v-icon>
                                                    </v-btn>
                                                  </a>
                                                  <a
                                                    v-if="organisationStatistic.organisation.organisation_contact.twitter_url != null"
                                                    :href="organisationStatistic.organisation.organisation_contact.twitter_url"
                                                    target="_blank"
                                                    style="text-decoration: none"
                                                  >
                                                    <v-btn class="mx-2" icon>
                                                      <v-icon size="25px" color="#1DA1F2"> mdi-twitter </v-icon>
                                                    </v-btn>
                                                  </a>
                                                  <a
                                                    v-if="organisationStatistic.organisation.organisation_contact.linkedin_url != null"
                                                    :href="organisationStatistic.organisation.organisation_contact.linkedin_url"
                                                    target="_blank"
                                                    style="text-decoration: none"
                                                  >
                                                    <v-btn class="mx-2" icon>
                                                      <v-icon size="25px" color="#0e76a8"> mdi-linkedin </v-icon>
                                                    </v-btn>
                                                  </a>
                                                  <a
                                                    v-if="organisationStatistic.organisation.organisation_contact.insta_url != null"
                                                    :href="organisationStatistic.organisation.organisation_contact.insta_url"
                                                    target="_blank"
                                                    style="text-decoration: none"
                                                  >
                                                    <v-btn class="mx-2" icon>
                                                      <v-icon size="25px" color="#8a3ab9"> mdi-instagram </v-icon>
                                                    </v-btn>
                                                  </a>
                                                  <a
                                                    v-if="organisationStatistic.organisation.organisation_contact.web_url != null"
                                                    :href="organisationStatistic.organisation.organisation_contact.web_url"
                                                    target="_blank"
                                                    style="text-decoration: none"
                                                  >
                                                    <v-btn class="mx-2" icon>
                                                      <v-icon size="25px" color="#8a3ab9"> mdi-web </v-icon>
                                                    </v-btn>
                                                  </a>
                                                  
                                                </template>
                                              </div>
                                            </v-flex>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-card-text>
                          </v-card>
                        </v-flex>
                      </div>
                    </template>
                  </div>
                  <div v-else class="mx-1 mt-1">
                    <v-card outlined style="border-radius: 25px;">
                      <v-layout row wrap>
                        <v-flex xs12 md4>
                          <div align="center" class="my-10">
                            <v-avatar color="primary" size="96">
                              <v-icon class="white--text">
                                mdi-file-document-alert
                              </v-icon>
                            </v-avatar>
                          </div>
                        </v-flex>
                        <v-flex xs12 md8>
                          <v-layout column>
                            <v-flex xs12 class="mt-1">
                              <div align="center" class="mt-15 ml-5 title">
                                No Organisation Found..
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </div>
                </v-layout>
              </v-card>
              <v-card outlined
                class="mx-4 mt-5 pa-3"
                style="border-radius: 25px;"
                v-if="organisationStatistics.length == 0"
              >
                <v-layout column>
                  <div class="mx-1 mt-1">
                    <v-card outlined style="border-radius: 25px;">
                      <v-layout row wrap>
                        <v-flex xs12 md4>
                          <div align="center" class="my-10">
                            <v-avatar color="primary" size="96">
                              <v-icon class="white--text">
                                mdi-file-document-alert
                              </v-icon>
                            </v-avatar>
                          </div>
                        </v-flex>
                        <v-flex xs12 md8>
                          <v-layout column>
                            <v-flex xs12 class="mt-1">
                              <div align="center" class="mt-15 ml-5 title">
                                No Organisation Found..
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </div>
                </v-layout>
              </v-card>
              <div>
                  <v-container>
                    <div align="center" class="mt-4">
                      <v-pagination
                        v-if="length != 0 && length != 1"
                        :length="length"
                        total-visible="10"
                        v-model="organisationStatisticPagination.current_page"
                        @input="changePage()"
                        circle
                      >
                      </v-pagination>
                    </div>
                  </v-container>
                <div v-if="length != 0" align="center" class="my-5 mb-10">
                  <b class="text--text mr-1">Total: </b
                  >{{ organisationStatisticPagination.total }}
                </div>
              </div>
            </v-flex>
            <v-flex xs12 md3>
              <v-card outlined class="mx-1 mt-5">
                <div class="pa-2">
                  <v-flex xs12>
                    <div>
                      <div align="left">
                        <b>Search</b>
                      </div>
                      <v-text-field
                        v-model="filters.search"
                        class="search_field background"
                        dense
                        outlined
                        append-icon="mdi-magnify"
                        height="10"
                        v-on:keyup.enter="search()"
                        @click:append="search()"
                      >
                      </v-text-field>
                    </div>
                  </v-flex>
                  <v-flex xs12 class="mt-5">
                    <div><b>Type</b></div>
                    <v-select
                      :items="allOrganisationTypes"
                      item-text="name"
                      item-value="id"
                      v-model="filters.organisation_type"
                      class="search_field background"
                      dense
                      outlined
                      height="10"
                      @input="filterByOrganisationType()"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 class="mt-5">
                    <div>
                      <b>Time Period</b>
                    </div>
                    <v-select
                      :items="timePeriods"
                      item-text="name"
                      item-value="name"
                      v-model="filters.time_period"
                      class="search_field background"
                      dense
                      outlined
                      height="10"
                      @input="filterByTimePeriod()"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 class="mt-5">
                    <div><b>Order By</b></div>
                    <v-select
                      item-text="name"
                      item-value="name"
                      :items="orderBy"
                      v-model="filters.order_by"
                      class="search_field background mb-7"
                      dense
                      outlined
                      height="10"
                      @input="filterByOrder()"
                    >
                    </v-select>
                  </v-flex>
                </div>
              </v-card>
              <v-btn
                  depressed
                  block
                  class="primary text-none mt-5"
                  @click="reset()"
                  :loading="organisationStatisticLoader"
                  >Reset 
                    <v-icon right>mdi-undo</v-icon>
                  </v-btn
                >
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      loading: false,
      filteredOrganisations: false,

      time: "All Time",

      filters: {
        search: null,
        organisation_type: null,
        time_period: null,
        order_by: "Innovations",
      },

      items: [
        "Public Universities",
        "Private Universities",
        "Public OrganisationStatistics",
        "Private OrganisationStatistics",
      ],
      timePeriods: [
        { name: "24 Hrs" },
        { name: "This Week" },
        { name: "This Month" },
        { name: "This Year" },
        { name: "All Time" },
      ],
      orderBy: [
        { name: "Innovations" },
        { name: "Users" },
        // { name: "Connections" },
      ],
    };
  },
  mounted() {},
  created() {
    window.scrollTo(0, 0);
    this.initialize();
  },
  methods: {
    ...mapActions([
      "fetchAllOrganisationTypes",
      "fetchOrganisationStatistics",
      "filterOrganisationStatistics",
      "startOrganisationStatisticLoader",
      "stopOrganisationStatisticLoader",
    ]),
    initialize() {
      this.fetchAllOrganisationTypes();
      this.fetchOrganisationStatistics(this.organisationStatisticPagination.current_page);
      this.startOrganisationStatisticLoader();
    },
    filterByOrganisationType() {
      this.filteredOrganisations = true;
      this.changePage();
    },
    filterByTimePeriod() {
      this.filteredOrganisations = true;
      this.time = this.filters.time_period;
      this.changePage();
    },
    search() {
      this.filteredOrganisations = true;
      this.organisationStatisticPagination.current_page = 1
      this.changePage();
    },
    filterByOrder() {
      this.filteredOrganisations = true;
      this.changePage();
    },
    changePage() {
      window.scrollTo(0, 0);
      this.startOrganisationStatisticLoader();
      if (this.filteredOrganisations == true) {
        apiCall({
          url:
            "/api/organisation-statistic?type=filter&page=" +
            this.organisationStatisticPagination.current_page +
            "&organisation_type=" +
            this.filters.organisation_type +
            "&time_period=" +
            this.filters.time_period +
            "&order_by=" +
            this.filters.order_by +
            "&search_filter=" +
            this.filters.search,
          method: "GET",
        })
          .then((resp) => {
            this.filterOrganisationStatistics(resp);
            this.stopOrganisationStatisticLoader();
          })
          .catch((error) => {
            console.log(error);
            this.stopOrganisationStatisticLoader();
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        this.fetchOrganisationStatistics(
          this.organisationStatisticPagination.current_page
        );
      }
    },
    goToOrganisation(organisation) {
      this.$router.push("/organisations/" + organisation.slug);
    },
    reset() {
      this.filters.search = null;
      this.filters.organisation_type = null;
      this.filters.time_period = null;
      this.filters.order_by = "Innovations";

      this.startOrganisationStatisticLoader();
      this.fetchOrganisationStatistics(1);
    },
  },
  computed: {
    ...mapGetters([
      "allOrganisationTypes",

      "organisationStatistics",
      "organisationStatisticLoader",
      "organisationStatisticPagination",
    ]),
    length: function () {
      return Math.ceil(
        this.organisationStatisticPagination.total /
          this.organisationStatisticPagination.per_page
      );
    },
    resultNumber: function () {
      return (
        (this.organisationStatisticPagination.current_page - 1) *
        this.organisationStatisticPagination.per_page
      );
    },
  },
};
</script>
