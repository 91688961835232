<template>
  <div class="interest">
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-layout column>
      <div v-if="view == 'default'">
        <v-flex xs12 class="mt-5 mb-5">
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-container>
              <v-card outlined style="border-radius: 25px">
                <v-layout column>
                  <v-flex xs12>
                    <div align="center" class="title font-weight-black mt-5">
                      Get Started
                    </div>
                    <div align="center" class="pa-2">
                      <p>
                        Welcome to the platform <b>{{ getProfile.f_name }}</b
                        >! Setup your interests to get started..
                      </p>
                    </div>
                  </v-flex>
                  <v-flex xs12>
                    <v-divider></v-divider>
                    <v-progress-linear
                      v-if="roleLoader"
                      height="1"
                      indeterminate
                      color="primary"
                    >
                    </v-progress-linear>
                  </v-flex>
                  <v-flex xs12>
                    <div class="pa-2 my-3">
                      <div align="center">
                        <b>Specify Your Role(s) on the Bridge</b>
                      </div>
                      <v-row justify="space-around">
                        <v-col cols="12" sm="10" md="4">
                          <v-chip-group
                            v-model="data.roles"
                            multiple
                            active-class="primary--text"
                          >
                            <v-chip
                              v-for="role in publicRoles"
                              :key="role.id"
                              :value="role.id"
                            >
                              {{ role.name }}
                            </v-chip>
                          </v-chip-group>
                        </v-col>
                      </v-row>
                    </div>
                  </v-flex>
                  <v-flex xs12>
                    <v-divider></v-divider>
                    <v-progress-linear
                      v-if="interestLoader"
                      height="1"
                      indeterminate
                      color="primary"
                    >
                    </v-progress-linear>
                  </v-flex>
                  <v-flex xs12>
                    <div class="pa-2 my-3">
                      <div align="center"><b>Interests</b></div>
                      <div align="center" class="pa-2">
                        We'll use this data to personalise your dashboard
                      </div>
                      <div class="ml-3" align="left">
                        <b>I'm interested in exploring;</b>
                      </div>
                      <div class="pa-5">
                        <v-layout row wrap>
                          <template v-for="(interest, index) in allInterests">
                            <v-flex xs12 md4 :key="index">
                              <v-checkbox
                                v-model="data.interests"
                                :label="interest.name"
                                :color="interest.color"
                                :value="interest.id"
                                hide-details
                              >
                              </v-checkbox>
                            </v-flex>
                          </template>
                        </v-layout>
                      </div>
                      <div class="ml-3 mt-5" align="left">
                        <b>Sectors of Interest</b>
                      </div>
                      <div class="pa-5">
                        <v-layout row wrap>
                          <template v-for="(sector, index) in allSectors">
                            <v-flex xs12 md4 :key="index">
                              <v-checkbox
                                v-model="data.sectors"
                                :label="sector.name"
                                :color="sector.color"
                                :value="sector.id"
                                hide-details
                              >
                              </v-checkbox>
                            </v-flex>
                          </template>
                        </v-layout>
                      </div>
                      <div class="ml-3 mt-5" align="left">
                        <b>SDGs of Interest</b>
                      </div>
                      <div class="pa-5">
                        <v-layout row wrap>
                          <template v-for="(sdg, index) in allSdgs">
                            <v-flex xs12 md4 :key="index">
                              <v-checkbox
                                v-model="data.sdgs"
                                :label="sdg.name"
                                :color="sdg.color"
                                :value="sdg.id"
                                hide-details
                              >
                              </v-checkbox>
                            </v-flex>
                          </template>
                        </v-layout>
                      </div>
                    </div>
                  </v-flex>
                  <v-flex xs12>
                    <div align="center">
                      <v-btn
                        @click="saveInterests"
                        depressed
                        :loading="loading"
                        class="primary white--text mt-4 mb-10 text-none"
                        >Let's Go!
                        <v-icon right>mdi-rocket-launch</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-container>
          </v-form>
        </v-flex>
      </div>
      <div v-if="view == 'investor'">
        <v-flex xs12 class="mt-5 mb-5">
          <v-container>
            <v-card outlined style="border-radius: 25px">
              <div align="center">
                <v-avatar class="green mt-10" size="100">
                  <v-icon class="white--text">mdi-check-all</v-icon>
                </v-avatar>
              </div>
              <div class="title mt-5" align="center">
                Your user profile has been created
              </div>
              <div class="mb-10" align="center">
                Are you an Individual Investor or part of an Investment Firm(s)?
              </div>
              <v-divider v-if="level"></v-divider>
              <div class="mt-5" v-if="level">
                <v-layout row wrap>
                  <v-flex xs12>
                    <div align="center">
                      <v-btn
                        :depressed="individualDepressed"
                        color="primary"
                        class="text-none my-1"
                        @click="individualType('individual')"
                      >
                        Individual
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs12>
                    <div align="center">
                      <v-btn
                        :depressed="investmentFirmDepressed"
                        color="secondary"
                        class="text-none my-1 primary--text"
                        @click="individualType('investmentFirm')"
                      >
                        Investment Firm
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>
              </div>
              <v-divider class="mt-6"></v-divider>
              <div v-if="accountType == 'individual'">
                <v-form v-model="valid" ref="indvForm" lazy-validation>
                    <div class="mx-16">
                        <div class="mt-5">
                            What Type of an Investor Are You?<span class="error--text">*</span>
                        </div>
                        <v-select
                            :items="formData.investor_types"
                            v-model="newIndividualInvestor.investor_type_id"
                            class="text_field background"
                            item-value="id"
                            item-text="name"
                            outlined
                            dense
                            :rules="inputRules"
                        ></v-select>
                    </div>
                </v-form>
                    
                <div align="center">
                  <v-btn
                    router
                    depressed
                    :loading="loading"
                    :disabled="!valid"
                    @click="saveIndvInvestor"
                    class="primary white--text mt-4 mb-10 text-none"
                  >
                    Save
                    <v-icon right>mdi-content-save</v-icon>
                  </v-btn>
                </div>
              </div>
              <div v-if="accountType == 'investmentFirm'" class="mx-2">
                <v-tabs show-arrows v-model="tab" class="mt-6">
                  <v-tabs-slider color="primary"></v-tabs-slider>
                  <v-tab @click="activeTab = 'existing'">
                    <div class="text-none">Existing Investment Firm</div>
                  </v-tab>
                  <v-tab @click="activeTab = 'create'">
                    <div class="text-none">Create Investment Firm</div>
                  </v-tab>
                </v-tabs>
                <div v-if="activeTab == 'existing'">
                  <v-layout row wrap class="mt-5">
                    <v-flex xs12 md9>
                      <div class="mx-4">Find Investment Firm</div>
                      <v-combobox
                        v-model="data.investment_firms"
                        :items="formData.all_investment_firms"
                        item-text="name"
                        item-value="id"
                        chips
                        clearable
                        multiple
                        prepend-inner-icon="mdi-briefcase-outline"
                        outlined
                        class="mx-4"
                      >
                        <template
                          v-slot:selection="{ attrs, item, select, selected }"
                        >
                          <v-chip
                            v-bind="attrs"
                            :input-value="selected"
                            close
                            @click="select"
                            @click:close="removeInvestmentFirm(item)"
                          >
                            <strong>{{ item.name }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-flex>
                    <v-flex xs12 md3>
                      <div align="center">
                        <v-btn
                          class="primary text-none my-10"
                          @click="joinInvesmentFirm()"
                          :loading="loading"
                        >
                          Join Investment Firm(s)
                          <v-icon right> mdi-handshake-outline </v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
                <div v-if="activeTab == 'create'">
                  <div class="mx-2 mb-6">
                    <v-form v-model="valid" ref="invstFirmForm" lazy-validation>
                      <v-layout row wrap class="mt-5">
                        <v-flex xs12 md4>
                          <div class="mx-3">
                            <div>Name<span class="error--text">*</span></div>
                            <v-text-field
                              class="text_field background"
                              outlined
                              dense
                              v-model="newInvestmentFirm.name"
                              :rules="inputRules"
                            ></v-text-field>
                          </div>
                        </v-flex>

                        <v-flex xs12 md4>
                          <div class="mx-3">
                            <div>
                              Investor Type<span class="error--text">*</span>
                            </div>
                            <v-select
                              :items="formData.investor_types"
                              v-model="newInvestmentFirm.investor_type_id"
                              class="text_field background"
                              item-value="id"
                              item-text="name"
                              outlined
                              dense
                              :rules="inputRules"
                            ></v-select>
                          </div>
                        </v-flex>
                        <v-flex xs12 md4>
                          <div class="mx-3">
                            <div>County<span class="error--text">*</span></div>
                            <v-select
                              :items="formData.counties"
                              v-model="newInvestmentFirm.county_id"
                              class="text_field background"
                              item-value="id"
                              item-text="name"
                              outlined
                              dense
                              :rules="inputRules"
                            ></v-select>
                          </div>
                        </v-flex>
                        <v-flex xs12 md9>
                          <div class="mx-3 mt-2">
                            <div>Brief<span class="error--text">*</span></div>
                            <v-text-field
                              class="text_field background"
                              outlined
                              dense
                              v-model="newInvestmentFirm.brief"
                              :rules="inputRules"
                            ></v-text-field>
                          </div>
                        </v-flex>
                        <v-flex xs12 md3>
                          <div class="mx-3 mt-2">
                            <div>
                              Establishment Year<span class="red--text">*</span>
                            </div>
                            <v-select
                              :items="years"
                              :rules="inputRules"
                              outlined
                              dense
                              v-model="newInvestmentFirm.establishment_year"
                              prepend-inner-icon="mdi-calendar"
                              class="background login_field ml-1 mr-3"
                            ></v-select>
                          </div>
                          
                        </v-flex>
                        <v-flex xs12>
                          <div class="mx-3 mt-2">
                            <div>
                              Description<span class="error--text">*</span>
                            </div>
                            <div class="black--text">
                              <ckeditor
                                :editor="editor"
                                v-model="newInvestmentFirm.description"
                                :config="editorConfig"
                                :rules="inputRules"
                              ></ckeditor>
                            </div>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div class="mx-3 mt-2">
                            <div>
                              Physical Address<span class="error--text">*</span>
                            </div>
                            <v-text-field
                              class="text_field background"
                              outlined
                              dense
                              v-model="newInvestmentFirm.physical_address"
                              :rules="inputRules"
                            ></v-text-field>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div align="right">
                            <v-btn
                              depressed
                              class="primary text-none mt-3 mr-3"
                              @click="save"
                              :disabled="!valid"
                              :loading="loading"
                            >
                              Save
                              <v-icon right> mdi-content-save </v-icon>
                            </v-btn>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-form>
                  </div>
                </div>
              </div>
              <div v-if="accountType == 'investmentFirmCreated'" class="mx-2">
                <div class="my-5" align="center">
                  <v-alert type="success"
                    >Investment Firm Created Successfully!</v-alert
                  >
                  <v-btn
                    depressed
                    class="primary white--text text-none"
                    router
                    to="/investment-firms-admin"
                  >
                    Manage Investment Firm
                  </v-btn>
                </div>
              </div>
              <div v-if="accountType == 'investmentFirmJoined'" class="mx-2">
                <div class="my-5" align="center">
                  <v-alert type="success"
                    >Investment Firm(s) Joined Successfully; Pending Approval..</v-alert
                  >
                  <v-btn
                    depressed
                    class="primary white--text text-none"
                    router
                    to="/innovations"
                  >
                    View Innovations
                  </v-btn>
                </div>
              </div>
              <div v-if="accountType == 'individualCreated'" class="mx-2">
                <div class="my-5" align="center">
                  <v-alert type="success"
                    >Your Investor Profile has been created Successfully!</v-alert
                  >
                  <v-btn
                    depressed
                    class="primary white--text text-none"
                    router
                    to="/innovations"
                  >
                    View Innovations
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-container>
        </v-flex>
      </div>
    </v-layout>
  </div>
</template>
<style>
.ck-editor__editable {
  min-height: 200px;
}
</style>
<script>
import Vue from "vue";
import apiCall from "@/utils/api";
import { USER_REQUEST } from "@/store/actions/user";
import { mapState, mapGetters, mapActions } from "vuex";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadAdapter from '@/uploadAdapter.js';

Vue.use(CKEditor);

export default {
  components: {
    
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      valid: true,
      color: "",
      message: "",
      snackbar: false,
      drawer: false,
      loading: false,
      confirm: false,
      inputRules: [(v) => !!v || "Input is required"],
      level: true,
      years: [],
      editor: ClassicEditor,
      editorConfig: {
          extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
              return new uploadAdapter(loader);
          } }],
          language: 'en',
      },

      individualDepressed: false,
      investmentFirmDepressed: false,
      accountType: null,
      tab: null,
      activeTab: "existing",

      view: "default",
      data: {
        interests: [],
        sectors: [],
        sdgs: [],
        roles: [],
        investment_firms: [],
      },
      newInvestmentFirm: {
        id: null,
        name: null,
        brief: null,
        description: null,
        investor_type_id: null,
        establishment_year: null,
        physical_address: null,
        county_id: null,        
      },
      newIndividualInvestor: {
        id: null,
        investor_type_id: null,   
      },
      newAdministrator: {
        id: null,
        investment_firm_id: null,
        user_id: null,
        type:'owner'
      },

      organisations: [],
    };
  },
  mounted() {},
  created() {
    this.$store.dispatch(USER_REQUEST);
    this.assignInterests();
    this.initialize();
    this.populateYears();
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapActions([
      "fetchAllSdgs",
      "fetchPublicRoles",
      "fetchAllSectors",
      "fetchAllInterests",

      "startRoleLoader",
      "stopRoleLoader",
      "startInterestLoader",
      "stopInterestLoader",
      "startSectorLoader",
      "stopSectorLoader",
      "startSdgLoader",
      "stopSdgLoader",
    ]),
    initialize() {
      this.fetchAllSdgs();
      this.fetchPublicRoles();
      this.fetchAllSectors();
      this.fetchAllInterests();
      this.startRoleLoader();
    },
    populateYears() {
      var max = new Date().getFullYear();
      var min = max - 100;
      var years = [];

      for (var i = max; i >= min; i--) {
        years.push(i);
      }
      this.years = years;
    },
    individualType(type) {
      if (type == "individual") {
        this.individualDepressed = true;
        this.investmentFirmDepressed = false;
      } else {
        this.individualDepressed = false;
        this.investmentFirmDepressed = true;
      }
      this.accountType = type;
    },
    getInvestmentFirms() {
      apiCall({
        url: "/api/organisation?type=all",
        method: "GET",
      })
        .then((resp) => {
          this.organisations = resp;
        })
        .catch((error) => {});
    },
    removeInvestmentFirm(item) {
      this.data.investment_firms.splice(this.data.investment_firms.indexOf(item), 1);
    },
    assignInterests() {
      if (this.getProfile.roles.length != 0) {
        for (var a = 0; a < this.getProfile.roles.length; a++) {
          this.data.roles.push(this.getProfile.roles[a].id);
        }
      }
      if (this.getProfile.user_interests.length != 0) {
        for (var b = 0; b < this.getProfile.user_interests.length; b++) {
          this.data.interests.push(
            this.getProfile.user_interests[b].interest_id
          );
        }
      }
      if (this.getProfile.user_sdgs.length != 0) {
        for (var c = 0; c < this.getProfile.user_sdgs.length; c++) {
          this.data.sdgs.push(this.getProfile.user_sdgs[c].sdg_id);
        }
      }
      if (this.getProfile.user_sectors.length != 0) {
        for (var d = 0; d < this.getProfile.user_sectors.length; d++) {
          this.data.sectors.push(this.getProfile.user_sectors[d].sector_id);
        }
      }
    },
    saveIndvInvestor(){
        if (this.$refs.indvForm.validate()) {
        confirm("Is this data accurate?") &&
          (this.confirm = true);
        if (this.confirm) {
          this.loading = true;
          apiCall({
            url: "/api/individual-investor",
            data: this.newIndividualInvestor,
            method: "POST",
          })
            .then((resp) => {
                this.message = "Investor Information Saved Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.accountType = "individualCreated";
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.loading = false;
              this.snackbar = true;
            });
        }
      }
    },
    save() {
      if (this.$refs.invstFirmForm.validate()) {
        confirm("Are you sure you want to create Investment Firm?") &&
          (this.confirm = true);
        if (this.confirm) {
          this.loading = true;
          apiCall({
            url: "/api/investment-firm",
            data: this.newInvestmentFirm,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Investment Firm Added Successfully";
              this.color = "success";
              this.loading = false;
              this.snackbar = true;
              this.confirm = false;
              this.level = false;
              this.accountType = "investmentFirmCreated";
              this.$store.dispatch(USER_REQUEST);             
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.loading = false;
              this.snackbar = true;
            });
        }
      }
    },
    joinInvesmentFirm() {
      this.loading = true;
      apiCall({
        url: "/api/investment-firm-administrator?type=join",
        data: this.data,
        method: "POST",
      })
        .then((resp) => {
          this.message = "Joined Firm(s) Successfully. Pending Approval";
          this.color = "success";
          this.loading = false;
          this.snackbar = true;
          this.confirm = false;
          this.level = false;
          this.accountType = "investmentFirmJoined";
          this.$store.dispatch(USER_REQUEST);
        })
        .catch((error) => {
          this.message = "An Error Occurred";
          this.color = "error";
          this.loading = false;
          this.snackbar = true;
        });
    },
    saveInterests() {
      if (this.data.roles.length == 0) {
        this.message = "Please pick at least one Role";
        this.color = "error";
        this.snackbar = true;
      } else if (this.data.interests.length == 0) {
        this.message = "Please pick at least one Interest";
        this.color = "error";
        this.snackbar = true;
      } else if (this.data.sectors.length == 0) {
        this.message = "Please pick at least one Sector";
        this.color = "error";
        this.snackbar = true;
      } else if (this.data.sdgs.length == 0) {
        this.message = "Please pick at least one SDG";
        this.color = "error";
        this.snackbar = true;
      } else {
        this.loading = true;
        apiCall({
          url: "/api/user?type=interests",
          data: this.data,
          method: "POST",
        })
          .then((resp) => {
            this.loading = false;
            this.$store.dispatch(USER_REQUEST);

            var investor = false;
            for (var i = 0; i < this.publicRoles.length; i++) {
              if (this.publicRoles[i].name == "Investor") {
                var inventoId = this.publicRoles[i].id;
                break;
              }
            }

            for (var i = 0; i < this.data.roles.length; i++) {
              if (this.data.roles[i] == inventoId) {
                investor = true;
              }
            }
            
            if (investor == true) {
              this.view = "investor";
              this.getFormData();
              //this.getInvestmentFirms();
            } else {
              this.$router.push("/innovations");
            }
          })
          .catch((error) => {
            this.message = "An Error Occurred; Please Try Again";
            this.color = "error";
            this.loading = false;
            this.snackbar = true;
          });
      }
    },
    getFormData() {
      apiCall({
        url: "/api/investment-firm?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
  },
  computed: {
    ...mapGetters([
      "loginCardState",
      "getProfile",
      "publicRoles",
      "roleLoader",
      "allInterests",
      "interestLoader",
      "allSectors",
      "sectorLoader",
      "allSdgs",
      "sdgLoader",
    ]),
  },
};
</script>
