<template>
    <div class="esos" v-if="$can('eso_view')">
      <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
        <span>{{ message }}</span>
      </v-snackbar>
      <v-dialog
          transition="dialog-bottom-transition"
          max-width="600"
          v-model="imageDialog"
        >
          <v-card max-width="600">
            <v-toolbar flat>
              Upload Logo
              <v-spacer></v-spacer>
              <v-btn icon @click="imageDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
    
            <v-form ref="imageForm" v-model="imageValid" lazy-validation>
              <v-layout column>
                <v-flex xs12>
                  <v-file-input
                    class="mx-2 mt-3"
                    :loading="imageLoading"
                    :rules="fileRules"
                    filled
                    dense
                    show-size
                    chips
                    accept="image/*"
                    v-model="imageFile"
                    label="Select Image"
                  ></v-file-input>
                </v-flex>
                <v-flex xs12>
                  <div class="mx-3 mb-5">
                    <v-btn
                      depressed
                      block
                      class="primary text-none"
                      @click="uploadImage"
                      :loading="imageLoading"
                      :disabled="!imageValid"
                    >
                      Submit <v-icon right dark>mdi-upload</v-icon>
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card>
        </v-dialog>
        <v-dialog
          transition="dialog-bottom-transition"
          max-width="600"
          v-model="coverImageDialog"
        >
          <v-card max-width="600">
            <v-toolbar flat>
              Upload Cover Photo
              <v-spacer></v-spacer>
              <v-btn icon @click="coverImageDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
    
            <v-form ref="imageForm" v-model="imageValid" lazy-validation>
              <v-layout column>
                <v-flex xs12>
                  <v-file-input
                    class="mx-2 mt-3"
                    :loading="imageLoading"
                    :rules="fileRules"
                    filled
                    dense
                    show-size
                    chips
                    accept="image/*"
                    v-model="imageFile"
                    label="Select Image"
                  ></v-file-input>
                </v-flex>
                <v-flex xs12>
                  <div class="mx-3 mb-5">
                    <v-btn
                      depressed
                      block
                      class="primary text-none"
                      @click="uploadCoverImage"
                      :loading="imageLoading"
                      :disabled="!imageValid"
                    >
                      Submit <v-icon right dark>mdi-upload</v-icon>
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card>
        </v-dialog>
      <v-container>
        <v-layout column>
          <div v-if="view == 'default'">
            <v-card outlined class="mt-5">
              <v-progress-linear
                v-if="esoLoader"
                height="1"
                indeterminate
                color="primary"
              >
              </v-progress-linear>
              <v-flex xs12 class="mt-10 mb-2">
                <div class="mx-5">
                  <v-layout row wrap>
                    <v-flex xs12 md4> <h1>Esos</h1> </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-1">
                        <v-text-field
                          class="text_field header"
                          outlined
                          dense
                          label="Search"
                          append-icon="mdi-undo-variant"
                          @click:append="resetSearch()"
                          v-on:keyup.enter="search"
                          v-model="searchTerm"
                          @input="enableSearch()"
                        ></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md2>
                      <div class="pa-1">
                        <v-btn
                          class="secondary primary--text text-none"
                          depressed
                          block
                          @click="search"
                          :loading="searchLoader"
                          :disabled="searchButtonDisabled"
                        >
                          Search
                          <v-icon right>mdi-magnify</v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                    <v-flex xs12 md2>
                      <div class="pa-1">
                        <v-btn
                          block
                          depressed
                          class="primary text-none"
                          @click="changeView('create')"
                          v-if="$can('eso_create')"
                        >
                          Add Eso
                          <v-icon right>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>
              <v-divider class="mt-9 mx-5"></v-divider>
              <v-flex xs12 class="mt-5">
                <div v-if="esos.length == 0">
                  <v-card elevation="0">
                    <v-layout row wrap>
                      <v-flex xs12 md1>
                        <v-container fill-height fluid>
                          <v-row align="center" justify="center">
                            <v-col class="text-center">
                              <v-icon large class="primary--text">
                                mdi-alert-circle-outline
                              </v-icon>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-flex>
                      <v-flex xs12 md11>
                        <v-container fill-height fluid>
                          <v-row align="center" justify="center">
                            <v-col class="text-center">
                              No Eso Found
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </div>
                <div v-else>
                  <div class="hidden-sm-and-down">
                    <v-card elevation="0" class="pa-2">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead class="header">
                            <tr class="tableHeader">
                              <th class="text-left text--text">Logo</th>
                              <th class="text-left text--text">Name</th>
                              <th class="text-left text--text">County</th>
                              <th class="text-left text--text">Status</th>
                              <th class="text-left text--text">Date Created</th>
                              <th class="text-right text--text">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in esos" :key="item.id">
                              <td>
                                <div class="my-2">
                                  <v-avatar size="48" v-if="item.logo==null">
                                    <v-img
                                      max-height="150"
                                      
                                      src="img/logo_fav.png"
                                    >
                                    </v-img>
                                  </v-avatar>
                                  <v-avatar size="48" v-else>
                                    <v-img
                                        max-height="150"
                                        :src="path+'/storage/eso_pictures/'+item.logo"
                                      >
                                      </v-img>
                                  </v-avatar>
                                </div>
                              </td>
                              <td>{{ item.name }}</td>
                              <td>{{ item.county.name }}</td>
                              <td>
                                <div v-if="item.status == 1">Active</div>
                                <div v-else>Inactive</div>
                              </td>
                              <td>
                                {{
                                  item.created_at | moment("DD/MM/YYYY - hh:mm a")
                                }}
                              </td>
                              <td>
                                <div align="right">
                                  <v-btn
                                    icon
                                    v-if="$can('eso_view')"
                                    @click="showEso(item)"
                                    class="button mr-1 universal--text"
                                  >
                                    <v-icon small> mdi-eye </v-icon>
                                  </v-btn>
                                  <v-btn
                                    v-if="$can('eso_edit')"
                                    icon
                                    class="button mr-1 success--text"
                                    @click="editEso(item)"
                                  >
                                    <v-icon small> mdi-pencil </v-icon>
                                  </v-btn>
                                  <v-btn
                                    v-if="$can('eso_archive')"
                                    icon
                                    class="button mr-1 error--text"
                                    @click="deleteEso(item)"
                                    :loading="
                                      loading && esoIndex == item.id
                                    "
                                  >
                                    <v-icon small> mdi-delete </v-icon>
                                  </v-btn>
                                  <v-tooltip
                                    top
                                    v-if="
                                      $can('eso_activate') &&
                                      item.status == 0
                                    "
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        class="button mr-1 red--text"
                                        @click="activateEso(item)"
                                        :loading="
                                          activateLoading &&
                                          esoIndex == item.id
                                        "
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon small>
                                          mdi-toggle-switch-off-outline
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Activate Eso</span>
                                  </v-tooltip>
                                  <v-tooltip
                                    top
                                    v-if="
                                      $can('eso_deactivate') &&
                                      item.status == 1
                                    "
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        class="button mr-1 green--text"
                                        @click="deactivateEso(item)"
                                        :loading="
                                          deactivateLoading &&
                                          esoIndex == item.id
                                        "
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon small> mdi-toggle-switch </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Deactivate Eso</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="$can('log_view')">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        class="button mr-1 purple--text"
                                        @click="viewLog(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon small> mdi-script-text </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Log</span>
                                  </v-tooltip>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </div>
                  <div class="hidden-md-and-up">
                    <div>
                      <v-layout column>
                        <template v-for="(eso, index) in esos">
                          <div :key="index">
                            <v-flex xs12 class="mb-2">
                              <v-card elevation="0">
                                <div class="pa-5">
                                  <v-layout column>
                                    <v-flex xs12>
                                      <v-layout row wrap>
                                        <v-flex xs11>
                                          <div class="title text--text">
                                            <b>{{ eso.name }}</b>
                                          </div>
                                        </v-flex>
                                        <v-flex xs1>
                                          <v-btn
                                            depressed
                                            class="red--text"
                                            :loading="
                                              loading &&
                                              esoIndex == eso.id
                                            "
                                            icon
                                            @click="
                                              deleteEso(eso)
                                            "
                                            v-if="$can('eso_archive')"
                                          >
                                            <v-icon> mdi-delete </v-icon>
                                          </v-btn>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-divider class="mt-2"></v-divider>
                                    <v-flex xs12 class="mt-1">
                                      <v-layout column>
                                        <v-flex xs12 class="mt-5">
                                          <v-layout row wrap>
                                            <v-flex xs4>
                                              <b>County</b>
                                            </v-flex>
                                            <v-flex xs8>
                                              {{
                                                eso.county.name
                                              }}
                                            </v-flex>
                                          </v-layout>
                                        </v-flex>
                                        <v-flex xs12 class="mt-5">
                                          <v-layout row wrap>
                                            <v-flex xs4>
                                              <b>Status</b>
                                            </v-flex>
                                            <v-flex xs8>
                                              <div v-if="eso.status == 1">Active</div>
                                              <div v-else>Inactive</div>
                                            </v-flex>
                                          </v-layout>
                                        </v-flex>
                                        <v-flex xs12 class="mt-5">
                                          <v-layout row wrap>
                                            <v-flex xs4>
                                              <b>Date Created</b>
                                            </v-flex>
                                            <v-flex xs8>
                                              {{
                                                eso.created_at
                                                  | moment("DD/MM/YYYY - hh:mm a")
                                              }}
                                            </v-flex>
                                          </v-layout>
                                        </v-flex>
                                        <v-flex xs12 class="mt-5">
                                          <v-layout row wrap>
                                            <v-flex xs12>
                                              <div>
                                                <v-btn
                                                  depressed
                                                  class="primary text-none white--text mt-3"
                                                  block
                                                  @click="
                                                    showEso(eso)
                                                  "
                                                  v-if="$can('eso_view')"
                                                  >View
                                                  <v-icon right> mdi-eye </v-icon>
                                                </v-btn>
                                              </div>
                                            </v-flex>
                                            <v-flex xs12>
                                              <div>
                                                <v-btn
                                                  depressed
                                                  class="blue text-none white--text mt-1"
                                                  block
                                                  @click="
                                                    editEso(eso)
                                                  "
                                                  v-if="$can('eso_edit')"
                                                  >Edit
                                                  <v-icon right>
                                                    mdi-border-color
                                                  </v-icon>
                                                </v-btn>
                                              </div>
                                            </v-flex>
                                            <v-flex xs12>
                                              <div>
                                                <v-btn
                                                  depressed
                                                  class="green text-none white--text mt-1"
                                                  block
                                                  :loading="
                                                    activateLoading &&
                                                    esoIndex == eso.id
                                                  "
                                                  @click="
                                                    activateEso(eso)
                                                  "
                                                  v-if="$can('eso_activate') &&
                                                    eso.status == 0"
                                                  >Activate Eso
                                                  <v-icon right>
                                                    mdi-toggle-switch-off-outline
                                                  </v-icon>
                                                </v-btn>
                                              </div>
                                            </v-flex>
                                            <v-flex xs12>
                                              <div>
                                                <v-btn
                                                  depressed
                                                  class="red text-none white--text mt-1"
                                                  block
                                                  :loading="
                                                    deactivateLoading &&
                                                    esoIndex == eso.id
                                                  "
                                                  @click="
                                                    deactivateEso(eso)
                                                  "
                                                  v-if="$can('eso_deactivate') &&
                                                    eso.status == 1"
                                                  >Deactivate Eso
                                                  <v-icon right>
                                                    mdi-toggle-switch
                                                  </v-icon>
                                                </v-btn>
                                              </div>
                                            </v-flex>
                                            <v-flex xs12>
                                              <div class="mt-1">
                                                <v-btn
                                                  v-if="$can('log_view')" 
                                                  block
                                                  depressed
                                                  class="purple text-none white--text"
                                                  @click="viewLog(eso)"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                > Log
                                                  <v-icon small> mdi-script-text </v-icon>
                                                </v-btn>
                                              </div>
                                            </v-flex>
                                          </v-layout>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </div>
                              </v-card>
                            </v-flex>
                          </div>
                        </template>
                      </v-layout>
                    </div>
                  </div>
                </div>
              </v-flex>
            </v-card>
            <v-layout column class="mt-5">
              <v-flex xs12>
                <div align="left">
                  <v-pagination
                    v-if="length != 0"
                    :length="length"
                    total-visible="10"
                    v-model="esoPagination.current_page"
                    @input="changePage()"
                    circle
                  >
                  </v-pagination>
                </div>
              </v-flex>
              <v-flex xs12>
                <div v-if="length != 0" align="center" class="mt-5">
                  <b class="primary--text">Total: </b
                  >{{ esoPagination.total | formatNumber }}
                </div>
              </v-flex>
            </v-layout>
          </div>
          <div v-if="view == 'create'">
            <v-flex class="mt-5">
              <v-card outlined>
                <v-card-title class="tableHeader text--text">
                  <v-img
                    v-if="newEso.id != null"
                    src="img/badge.png"
                    max-width="40"
                  ></v-img>
                  <v-spacer></v-spacer>
                  <div v-if="newEso.id == null">Add Eso</div>
                  <div v-else>Edit Eso</div>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="changeView('default')">
                    <v-icon class="text--text"> mdi-close </v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-form v-model="valid" ref="form" lazy-validation>
                    <v-layout row wrap class="mt-5">
                      <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>Name<span class="error--text">*</span></div>
                          <v-text-field
                            class="text_field background my-2"
                            outlined
                            dense
                            v-model="newEso.name"
                            :rules="inputRules"
                          ></v-text-field>
                        </div>
                      </v-flex>
  
                      <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>
                            Eso Type<span class="error--text">*</span>
                          </div>
                          <v-select
                            :items="formData.eso_types"
                            v-model="newEso.eso_type_id"
                            class="text_field background my-2"
                            item-value="id"
                            item-text="name"
                            outlined
                            dense
                            :rules="inputRules"
                          ></v-select>
                        </div>
                      </v-flex>
                      <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>County<span class="error--text">*</span></div>
                          <v-select
                            :items="formData.counties"
                            v-model="newEso.county_id"
                            class="text_field background my-2"
                            item-value="id"
                            item-text="name"
                            outlined
                            dense
                            :rules="inputRules"
                          ></v-select>
                        </div>
                      </v-flex>
                      <v-flex xs12 md10>
                        <div class="pa-3">
                          <div>Brief<span class="error--text">*</span></div>
                          <v-text-field
                            class="text_field background my-2"
                            outlined
                            dense
                            v-model="newEso.brief"
                            :rules="inputRules"
                          ></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md2>
                        <div class="mt-5 ml-1">
                          <b>Establishment Year</b
                          ><span class="red--text">*</span>
                        </div>
                        <v-select
                          :items="years"
                          :rules="inputRules"
                          outlined
                          dense
                          v-model="newEso.year"
                          prepend-inner-icon="mdi-calendar"
                          class="background login_field ml-1 mr-3"
                        ></v-select>
                      </v-flex>
                      <v-flex xs12>
                        <div class="pa-3">
                          <div>Description<span class="error--text">*</span></div>
                          <div class="black--text">
                            <ckeditor
                              :editor="editor"
                              v-model="newEso.description"
                              :config="editorConfig"
                              :rules="inputRules"
                            ></ckeditor>
                          </div>                          
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div class="pa-3">
                          <div>
                            Physical Address<span class="error--text">*</span>
                          </div>
                          <v-text-field
                            class="text_field background my-2"
                            outlined
                            dense
                            v-model="newEso.physical_address"
                            :rules="inputRules"
                          ></v-text-field>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    class="primary text-none mt-3"
                    @click="save"
                    :disabled="!valid"
                    :loading="loading"
                  >
                    Save
                    <v-icon right> mdi-content-save </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </div>
          <div v-if="view == 'show'">
            <v-card outlined class="mt-5">
              <v-progress-linear
                v-if="esoAdministratorLoader"
                height="1"
                indeterminate
                color="primary"
              >
              </v-progress-linear>
              <v-card-title class="tableHeader text--text">
                <v-spacer></v-spacer>
                <div>View Eso</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeView('default')">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <div class="pa-1">
                  <v-tabs show-arrows v-model="esoTab">
                    <v-tabs-slider color="primary"></v-tabs-slider>
  
                    <v-tab @click="activeTab = 'details'">
                      <div class="text-none text--text">Details</div>
                    </v-tab>
                    <v-tab
                      @click="activeTab = 'esoAdministrators'"
                      v-if="$can('eso_administrator_view')"
                    >
                      <div class="text-none text--text">
                        Administrators ({{
                          esoAdministratorPagination.total
                        }})
                      </div>
                    </v-tab>
                  </v-tabs>
                  <div v-if="activeTab == 'details'">
                    <v-card-text>
                      <div class="pa-3">
                        <v-layout row wrap>
                          <v-flex xs12 md3>
                            <div class="mr-8">
                              <v-img v-if="assignedEso.cover_image == null" src="img/logo_primary.png" height="200px" style="z-index: 2;border-radius: 25px;">
                                <div align="right" class="mt-2 mr-2">
                                  <v-btn
                                    class="white"
                                    icon
                                    small
                                    @click="coverImageDialog = true"
                                    v-if="$can('eso_image_upload')"
                                    ><v-icon class="primary--text" small>mdi-pen</v-icon></v-btn
                                  >
                                </div>
                                
                                <v-avatar
                                  size="86"
                                  style="position: absolute; top: 110px; left: 3px; z-index: 10"
                                >
                                  <v-img
                                    v-if="assignedEso.logo==null"
                                    src="img/logo_fav.png"
                                  >
                                  </v-img>
                                  <v-img
                                    v-else
                                    :src="path+'/storage/eso_pictures/'+assignedEso.logo"
                                  >
                                  </v-img>
                                </v-avatar>
                              </v-img>
                              <v-img v-else :src="path+'/storage/eso_pictures/'+assignedEso.cover_image" height="200px" style="z-index: 2;border-radius: 25px;">
                                <div align="right" class="mt-2 mr-2">
                                  <v-btn
                                    class="white"
                                    icon
                                    small
                                    @click="coverImageDialog = true"
                                    v-if="$can('eso_image_upload')"
                                    ><v-icon class="primary--text" small>mdi-pen</v-icon></v-btn
                                  >
                                </div>
                                
                                <v-avatar
                                  size="86"
                                  style="position: absolute; top: 110px; left: 3px; z-index: 10"
                                >
                                  <v-img
                                    v-if="assignedEso.logo==null"
                                    src="img/logo_fav.png"
                                  >
                                  </v-img>
                                  <v-img
                                    v-else
                                    :src="path+'/storage/eso_pictures/'+assignedEso.logo"
                                  >
                                  </v-img>
                                </v-avatar>
                              </v-img>
                              
                            </div>
                            <div align="center" class="mt-5">
                              <v-btn class="primary text-none" depressed @click="imageDialog = true" v-if="$can('eso_image_upload')">
                                Upload Logo
                                <v-icon right>mdi-file-upload</v-icon>
                              </v-btn>
                            </div>
                          </v-flex>
                          <v-flex xs12 md9>
                            <v-layout column class="mt-3">
                              <v-flex xs12>
                                <v-layout row wrap>
                                  <v-flex xs12 md6>
                                    <v-layout column>
                                      <v-flex xs12 class="mt-6">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>Eso Name: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div>
                                              {{ assignedEso.name }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-6">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>County: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div>
                                              {{
                                                assignedEso.county.name
                                              }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 md6>
                                    <v-layout column>
                                      <v-flex xs12 class="mt-6">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>Status: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div
                                              v-if="
                                                assignedEso.status == 1
                                              "
                                            >
                                              Active
                                            </div>
                                            <div v-else>Inactive</div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-6">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>Date Created: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div>
                                              {{
                                                assignedEso.created_at
                                                  | moment("DD/MM/YYYY - hh:mm a")
                                              }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </div>
                    </v-card-text>
                    <v-card-title class="tableHeader text--text mt-5">
                      <v-spacer></v-spacer>
                      <div>Eso Contact Details</div>
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        @click="changeContactView('create')"
                        v-if="
                          $can('eso_contact_create') &&
                          contactView == 'default' &&
                          assignedEso.eso_contact == null
                        "
                      >
                        <v-icon>mdi-plus-circle-outline</v-icon>
                      </v-btn>
  
                      <v-btn
                        icon
                        @click="changeContactView('default')"
                        v-if="contactView != 'default'"
                      >
                        <v-icon> mdi-close </v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <div v-if="contactView == 'default'">
                        <div class="pa-3 mt-5">
                          <div
                            v-if="
                              assignedEso.eso_contact == null
                            "
                          >
                            <v-layout row wrap>
                              <v-flex xs12 md1>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      <v-icon large class="primary--text">
                                        mdi-alert-circle-outline
                                      </v-icon>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                              <v-flex xs12 md11>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      Contacts Not Added
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                            </v-layout>
                          </div>
                          <div v-else>
                            <v-layout column>
                              <v-flex xs12>
                                <div class="ml-3">
                                  <v-layout row wrap>
                                    <v-flex xs11>
                                      <v-layout row wrap>
                                        <v-flex xs12 md6>
                                          <v-layout column>
                                            <v-flex xs12 class="mt-6">
                                              <v-layout row wrap>
                                                <v-flex xs12 md4>
                                                  <b>Contact Person: </b>
                                                </v-flex>
                                                <v-flex xs12 md8>
                                                  <div
                                                    v-if="
                                                      assignedEso
                                                        .eso_contact
                                                        .contact_person != null
                                                    "
                                                  >
                                                    {{
                                                      assignedEso
                                                        .eso_contact
                                                        .contact_person
                                                    }}
                                                  </div>
                                                  <div v-else>N/A</div>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-flex xs12 class="mt-6">
                                              <v-layout row wrap>
                                                <v-flex xs12 md4>
                                                  <b>Primary Email Address: </b>
                                                </v-flex>
                                                <v-flex xs12 md8>
                                                  <div
                                                    v-if="
                                                      assignedEso
                                                        .eso_contact
                                                        .email != null
                                                    "
                                                  >
                                                    {{
                                                      assignedEso
                                                        .eso_contact
                                                        .email
                                                    }}
                                                  </div>
                                                  <div v-else>N/A</div>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-flex xs12 class="mt-6">
                                              <v-layout row wrap>
                                                <v-flex xs12 md4>
                                                  <b>Secondary Email Address: </b>
                                                </v-flex>
                                                <v-flex xs12 md8>
                                                  <div
                                                    v-if="
                                                      assignedEso
                                                        .eso_contact
                                                        .email_2 != null
                                                    "
                                                  >
                                                    {{
                                                      assignedEso
                                                        .eso_contact
                                                        .email_2
                                                    }}
                                                  </div>
                                                  <div v-else>N/A</div>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-flex xs12 class="mt-6">
                                              <v-layout row wrap>
                                                <v-flex xs12 md4>
                                                  <b>Website URL: </b>
                                                </v-flex>
                                                <v-flex xs12 md8>
                                                  <div
                                                    v-if="
                                                      assignedEso
                                                        .eso_contact
                                                        .web_url != null
                                                    "
                                                  >
                                                    <a
                                                      :href="
                                                        assignedEso
                                                          .eso_contact
                                                          .web_url
                                                      "
                                                      target="_blank"
                                                      style="
                                                        text-decoration: none;
                                                      "
                                                      >{{
                                                        assignedEso
                                                          .eso_contact
                                                          .web_url
                                                      }}</a
                                                    >
                                                  </div>
                                                  <div v-else>N/A</div>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-flex xs12 class="mt-6">
                                              <v-layout row wrap>
                                                <v-flex xs12 md4>
                                                  <b>Twitter URL: </b>
                                                </v-flex>
                                                <v-flex xs12 md8>
                                                  <div
                                                    v-if="
                                                      assignedEso
                                                        .eso_contact
                                                        .twitter_url != null
                                                    "
                                                  >
                                                    <a
                                                      :href="
                                                        assignedEso
                                                          .eso_contact
                                                          .twitter_url
                                                      "
                                                      target="_blank"
                                                      style="
                                                        text-decoration: none;
                                                      "
                                                      >{{
                                                        assignedEso
                                                          .eso_contact
                                                          .twitter_url
                                                      }}</a
                                                    >
                                                  </div>
                                                  <div v-else>N/A</div>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                          </v-layout>
                                        </v-flex>
                                        <v-flex xs12 md6>
                                          <v-layout column>
                                            <v-flex xs12 class="mt-6">
                                              <v-layout row wrap>
                                                <v-flex xs12 md4>
                                                  <b>Primary Phone #: </b>
                                                </v-flex>
                                                <v-flex xs12 md8>
                                                  <div
                                                    v-if="
                                                      assignedEso
                                                        .eso_contact
                                                        .phone != null
                                                    "
                                                  >
                                                    {{
                                                      assignedEso
                                                        .eso_contact
                                                        .phone
                                                    }}
                                                  </div>
                                                  <div v-else>N/A</div>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-flex xs12 class="mt-6">
                                              <v-layout row wrap>
                                                <v-flex xs12 md4>
                                                  <b>Secondary Phone #: </b>
                                                </v-flex>
                                                <v-flex xs12 md8>
                                                  <div
                                                    v-if="
                                                      assignedEso
                                                        .eso_contact
                                                        .phone_2 != null
                                                    "
                                                  >
                                                    {{
                                                      assignedEso
                                                        .eso_contact
                                                        .phone_2
                                                    }}
                                                  </div>
                                                  <div v-else>N/A</div>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-flex xs12 class="mt-6">
                                              <v-layout row wrap>
                                                <v-flex xs12 md4>
                                                  <b>Facebook URL: </b>
                                                </v-flex>
                                                <v-flex xs12 md8>
                                                  <div
                                                    v-if="
                                                      assignedEso
                                                        .eso_contact
                                                        .fb_url != null
                                                    "
                                                  >
                                                    <a
                                                      :href="
                                                        assignedEso
                                                          .eso_contact
                                                          .fb_url
                                                      "
                                                      target="_blank"
                                                      style="
                                                        text-decoration: none;
                                                      "
                                                      >{{
                                                        assignedEso
                                                          .eso_contact
                                                          .fb_url
                                                      }}</a
                                                    >
                                                  </div>
                                                  <div v-else>N/A</div>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-flex xs12 class="mt-6">
                                              <v-layout row wrap>
                                                <v-flex xs12 md4>
                                                  <b>Instagram URL: </b>
                                                </v-flex>
                                                <v-flex xs12 md8>
                                                  <div
                                                    v-if="
                                                      assignedEso
                                                        .eso_contact
                                                        .insta_url != null
                                                    "
                                                  >
                                                    <a
                                                      :href="
                                                        assignedEso
                                                          .eso_contact
                                                          .insta_url
                                                      "
                                                      target="_blank"
                                                      style="
                                                        text-decoration: none;
                                                      "
                                                      >{{
                                                        assignedEso
                                                          .eso_contact
                                                          .insta_url
                                                      }}</a
                                                    >
                                                  </div>
                                                  <div v-else>N/A</div>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-flex xs12 class="mt-6">
                                              <v-layout row wrap>
                                                <v-flex xs12 md4>
                                                  <b>Visibility: </b>
                                                </v-flex>
                                                <v-flex xs12 md8>
                                                  <div
                                                    v-if="
                                                      assignedEso
                                                        .eso_contact
                                                        .visible == 1
                                                    "
                                                  >
                                                    Public
                                                  </div>
                                                  <div v-else>Hidden</div>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                          </v-layout>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs1>
                                      <v-btn
                                        icon
                                        @click="
                                          editContact(
                                            assignedEso.eso_contact
                                          )
                                        "
                                        v-if="$can('eso_contact_edit')"
                                      >
                                        <v-icon small class="green--text"
                                          >mdi-pencil</v-icon
                                        >
                                      </v-btn>
                                      <v-btn
                                        icon
                                        @click="
                                          deleteContact(
                                            assignedEso.eso_contact
                                          )
                                        "
                                        v-if="
                                          $can('eso_contact_archive')
                                        "
                                        :loading="loading"
                                      >
                                        <v-icon small class="red--text"
                                          >mdi-delete</v-icon
                                        >
                                      </v-btn>
                                    </v-flex>
                                  </v-layout>
                                </div>
                              </v-flex>
                            </v-layout>
                          </div>
                        </div>
                      </div>
                      <div v-if="contactView == 'create'" class="mt-5">
                        <v-form v-model="valid" ref="contactForm" lazy-validation>
                          <v-layout row wrap>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <div>Contact Person</div>
                                <v-text-field
                                  class="text_field background my-2"
                                  outlined
                                  dense
                                  v-model="newContact.contact_person"
                                >
                                </v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <div>Primary Email</div>
                                <v-text-field
                                  class="text_field background my-2"
                                  outlined
                                  dense
                                  v-model="newContact.email"
                                  :rules="emailRules"
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <div>Secondary Email</div>
                                <v-text-field
                                  class="text_field background my-2"
                                  outlined
                                  dense
                                  v-model="newContact.email_2"
                                  :rules="emailRules"
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <div>Primary Phone Number</div>
                                <v-text-field
                                  class="text_field background my-2"
                                  outlined
                                  dense
                                  v-model="newContact.phone"
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <div>Secondary Phone Number</div>
                                <v-text-field
                                  class="text_field background my-2"
                                  outlined
                                  dense
                                  v-model="newContact.phone_2"
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <div>Website URL</div>
                                <v-text-field
                                  class="text_field background my-2"
                                  outlined
                                  dense
                                  v-model="newContact.web_url"
                                  :rules="websiteRules"
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <div>Facebook Page URL</div>
                                <v-text-field
                                  class="text_field background my-2"
                                  outlined
                                  dense
                                  v-model="newContact.fb_url"
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <div>Instagram Account URL</div>
                                <v-text-field
                                  class="text_field background my-2"
                                  outlined
                                  dense
                                  v-model="newContact.insta_url"
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <div>Twitter Account URL</div>
                                <v-text-field
                                  class="text_field background my-2"
                                  outlined
                                  dense
                                  v-model="newContact.twitter_url"
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md4>
                              <div class="mt-4">
                                <v-checkbox
                                  class="text_field"
                                  outlined
                                  dense
                                  v-model="newContact.visible"
                                  label="Visible"
                                ></v-checkbox>
                              </div>
                            </v-flex>
                            <v-flex xs12>
                              <div align="right">
                                <v-btn
                                  depressed
                                  class="primary text-none mt-3"
                                  @click="saveContact"
                                  :disabled="!valid"
                                  :loading="loading"
                                >
                                  Save
                                  <v-icon right> mdi-content-save </v-icon>
                                </v-btn>
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </div>
                    </v-card-text>
                  </div>
  
                  <div v-if="activeTab == 'esoAdministrators'">
                    <div v-if="esoAdministratorView == 'default'">
                      <v-card elevation="0" class="mt-5">
                        <v-flex xs12 class="mt-10 mb-2">
                          <div class="mx-5">
                            <v-layout row wrap>
                              <v-flex xs12 md4> </v-flex>
                              <v-flex xs12 md4>
                                <div class="pa-1">
                                  <v-text-field
                                    class="text_field header"
                                    outlined
                                    dense
                                    label="Search"
                                    append-icon="mdi-undo-variant"
                                    @click:append="
                                      resetEsoAdministratorSearch()
                                    "
                                    v-on:keyup.enter="
                                      esoAdministratorSearch
                                    "
                                    v-model="esoAdministratorSearchTerm"
                                    @input="
                                      enableEsoAdministratorSearch()
                                    "
                                  ></v-text-field>
                                </div>
                              </v-flex>
                              <v-flex xs12 md2>
                                <div class="pa-1">
                                  <v-btn
                                    class="secondary primary--text text-none"
                                    depressed
                                    block
                                    @click="esoAdministratorSearch"
                                    :loading="
                                      esoAdministratorSearchLoader
                                    "
                                    :disabled="
                                      esoAdministratorSearchButtonDisabled
                                    "
                                  >
                                    Search
                                    <v-icon right>mdi-magnify</v-icon>
                                  </v-btn>
                                </div>
                              </v-flex>
                              <v-flex xs12 md2>
                                <div class="pa-1">
                                  <v-btn
                                    block
                                    depressed
                                    class="primary text-none"
                                    @click="
                                      changeEsoAdministratorView(
                                        'create'
                                      )
                                    "
                                    v-if="
                                      $can('eso_administrator_create')
                                    "
                                  >
                                    Add Administrator
                                    <v-icon right>mdi-plus-circle-outline</v-icon>
                                  </v-btn>
                                </div>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-flex>
                        <v-divider class="mt-9 mx-5"></v-divider>
                        <v-flex xs12 class="mt-5">
                          <div v-if="esoAdministrators.length == 0">
                            <v-card elevation="0">
                              <v-layout row wrap>
                                <v-flex xs12 md1>
                                  <v-container fill-height fluid>
                                    <v-row align="center" justify="center">
                                      <v-col class="text-center">
                                        <v-icon large class="text--text">
                                          mdi-alert-circle-outline
                                        </v-icon>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-flex>
                                <v-flex xs12 md11>
                                  <v-container fill-height fluid>
                                    <v-row align="center" justify="center">
                                      <v-col class="text-center">
                                        No Eso Administrators Found
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </div>
                          <div v-else>
                            <div class="hidden-sm-and-down">
                              <v-card elevation="0" class="pa-2">
                                <v-simple-table>
                                  <template v-slot:default>
                                    <thead class="header">
                                      <tr class="tableHeader">
                                        <th class="text-left text--text">Pic</th>
                                        <th class="text-left text--text">Name</th>
                                        <th class="text-left text--text">Email Address</th>
                                        <th class="text-left text--text">Phone #</th>
                                        <th class="text-left text--text">Code</th>
                                        <th class="text-left text--text">Status</th>
                                        <th class="text-right text--text">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="item in esoAdministrators"
                                        :key="item.id"
                                      >
                                        <td>
                                          <div class="my-2">
                                            <v-avatar size="48" class="primary white--text" v-if="item.user.display_img==null">
                                              {{ item.user.f_name[0] }}
                                            </v-avatar>
                                            <v-avatar size="48" v-else>
                                              <v-img
                                                  :src="path+'/storage/profile_pics/'+item.user.display_img"
                                                >
                                                </v-img>
                                            </v-avatar>
                                          </div>
                                        </td>
                                        <td>
                                          {{ item.user.f_name }}
                                          {{ item.user.m_name }}
                                          {{ item.user.l_name }}
                                        </td>
                                        <td>{{ item.user.email }}</td>
                                        <td>{{ item.user.phone }}</td>
                                        <td>{{ item.user.code }}</td>
                                        <td>
                                          <div v-if="item.status == 1">
                                            Active
                                          </div>
                                          <div v-else>Inactive</div>
                                        </td>
                                        <td>
                                          <div align="right">
                                            <v-btn
                                              icon
                                              @click="
                                                showEsoAdministrator(
                                                  item.user
                                                )
                                              "
                                              class="button mr-1 universal--text"
                                            >
                                              <v-icon small> mdi-eye </v-icon>
                                            </v-btn>
                                            <v-btn
                                              v-if="
                                                $can(
                                                  'eso_administrator_archive'
                                                )
                                              "
                                              icon
                                              class="button mr-1 error--text"
                                              @click="
                                                deleteEsoAdministrator(
                                                  item
                                                )
                                              "
                                              :loading="
                                                loading &&
                                                esoAdministratorIndex ==
                                                  item.id
                                              "
                                            >
                                              <v-icon small> mdi-delete </v-icon>
                                            </v-btn>
                                            <v-tooltip
                                              top
                                              v-if="
                                                $can(
                                                  'eso_administrator_activate'
                                                ) && item.status == 0
                                              "
                                            >
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-btn
                                                  icon
                                                  class="button mr-1 red--text"
                                                  @click="
                                                    activateEsoAdministrator(
                                                      item
                                                    )
                                                  "
                                                  :loading="
                                                    activateLoading &&
                                                    esoAdministratorIndex ==
                                                      item.id
                                                  "
                                                  v-bind="attrs"
                                                  v-on="on"
                                                >
                                                  <v-icon small>
                                                    mdi-toggle-switch-off-outline
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span>Activate Administrator</span>
                                            </v-tooltip>
                                            <v-tooltip
                                              top
                                              v-if="
                                                $can(
                                                  'eso_administrator_deactivate'
                                                ) && item.status == 1
                                              "
                                            >
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-btn
                                                  icon
                                                  class="button mr-1 green--text"
                                                  @click="
                                                    deactivateEsoAdministrator(
                                                      item
                                                    )
                                                  "
                                                  :loading="
                                                    deactivateLoading &&
                                                    esoAdministratorIndex ==
                                                      item.id
                                                  "
                                                  v-bind="attrs"
                                                  v-on="on"
                                                >
                                                  <v-icon small>
                                                    mdi-toggle-switch
                                                  </v-icon>
                                                </v-btn>
                                              </template>
                                              <span>Deactivate Administrator</span>
                                            </v-tooltip>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </v-card>
                            </div>
                            <div class="hidden-md-and-up">
                              <div>
                                <v-layout column>
                                  <template
                                    v-for="(
                                      administrator, index
                                    ) in esoAdministrators"
                                  >
                                    <div :key="index">
                                      <v-flex xs12 class="mb-2">
                                        <v-card elevation="0">
                                          <div class="pa-2 mt-2">
                                            <v-layout column>
                                              <v-flex xs12>
                                                <v-layout row wrap>
                                                  <v-flex xs11>
                                                    <div
                                                      class="title text--text"
                                                    >
                                                      <b
                                                        >{{
                                                          administrator.user
                                                            .f_name
                                                        }}
                                                        {{
                                                          administrator.user
                                                            .m_name
                                                        }}
                                                        {{
                                                          administrator.user
                                                            .l_name
                                                        }}</b
                                                      >
                                                    </div>
                                                  </v-flex>
                                                  <v-flex xs1>
                                                    <v-btn
                                                      depressed
                                                      class="red--text"
                                                      :loading="
                                                        loading &&
                                                        esoAdministratorIndex ==
                                                          administrator.id
                                                      "
                                                      icon
                                                      @click="
                                                        deleteEsoAdministrator(
                                                          administrator
                                                        )
                                                      "
                                                      v-if="
                                                        $can(
                                                          'eso_administrator_archive'
                                                        )
                                                      "
                                                    >
                                                      <v-icon>
                                                        mdi-delete
                                                      </v-icon>
                                                    </v-btn>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                              <v-divider class="mt-2"></v-divider>
                                              <v-flex xs12 class="mt-1">
                                                <v-layout column>
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs4>
                                                        <b>Email Address</b>
                                                      </v-flex>
                                                      <v-flex xs8>
                                                        {{
                                                          administrator.user.email
                                                        }}
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs4>
                                                        <b>Phone #</b>
                                                      </v-flex>
                                                      <v-flex xs8>
                                                        {{
                                                          administrator.user.phone
                                                        }}
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs4>
                                                        <b>Code</b>
                                                      </v-flex>
                                                      <v-flex xs8>
                                                        {{
                                                          administrator.user.code
                                                        }}
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs4>
                                                        <b>Status</b>
                                                      </v-flex>
                                                      <v-flex xs8>
                                                        <div
                                                          v-if="
                                                            administrator.status ==
                                                            1
                                                          "
                                                        >
                                                          Active
                                                        </div>
                                                        <div v-else>Inactive</div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
  
                                                  <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                      <v-flex xs12>
                                                        <div>
                                                          <v-btn
                                                            depressed
                                                            class="primary text-none white--text mt-3"
                                                            block
                                                            @click="
                                                              showEsoAdministrator(
                                                                administrator.user
                                                              )
                                                            "
                                                            >View
                                                            <v-icon right>
                                                              mdi-eye
                                                            </v-icon>
                                                          </v-btn>
                                                        </div>
                                                      </v-flex>
                                                      <v-flex xs12>
                                                        <div>
                                                          <v-btn
                                                            depressed
                                                            class="green darken-1 text-none white--text mt-1"
                                                            block
                                                            :loading="
                                                              activateLoading &&
                                                              esoAdministratorIndex ==
                                                                administrator.id
                                                            "
                                                            @click="
                                                              activateEsoAdministrator(
                                                                administrator
                                                              )
                                                            "
                                                            v-if="
                                                              $can(
                                                                'eso_administrator_activate'
                                                              ) && administrator.status == 0
                                                            "
                                                            >Activate Administrator
                                                            <v-icon right>
                                                              mdi-toggle-switch
                                                            </v-icon>
                                                          </v-btn>
                                                        </div>
                                                      </v-flex>
                                                      <v-flex xs12>
                                                        <div>
                                                          <v-btn
                                                            depressed
                                                            class="red darken-1 text-none white--text mt-1"
                                                            block
                                                            :loading="
                                                              deactivateLoading &&
                                                              esoAdministratorIndex ==
                                                                administrator.id
                                                            "
                                                            @click="
                                                              deactivateEsoAdministrator(
                                                                administrator
                                                              )
                                                            "
                                                            v-if="
                                                              $can(
                                                                'eso_administrator_deactivate'
                                                              ) && administrator.status == 1
                                                            "
                                                            >Deactivate Administrator
                                                            <v-icon right>
                                                              mdi-toggle-switch-off-outline
                                                            </v-icon>
                                                          </v-btn>
                                                        </div>
                                                      </v-flex>
                                                    </v-layout>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                            </v-layout>
                                          </div>
                                        </v-card>
                                      </v-flex>
                                    </div>
                                  </template>
                                </v-layout>
                              </div>
                            </div>
                          </div>
                        </v-flex>
                      </v-card>
                      <v-layout column class="mt-5">
                        <v-flex xs12>
                          <div align="left">
                            <v-pagination
                              v-if="length != 0"
                              :length="esoAdministratorLength"
                              total-visible="10"
                              v-model="
                                esoAdministratorPagination.current_page
                              "
                              @input="changeEsoAdministratorPage()"
                              circle
                            >
                            </v-pagination>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <div
                            v-if="esoAdministratorLength != 0"
                            align="center"
                            class="mt-5"
                          >
                            <b class="text--text">Total: </b
                            >{{
                              esoAdministratorPagination.total
                                | formatNumber
                            }}
                          </div>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div v-if="esoAdministratorView == 'create'">
                      <v-flex class="mt-5">
                        <v-card-title class="tableHeader text--text">
                          <v-spacer></v-spacer>
                          <div v-if="newAdministrator.id == null">
                            Add Eso Administrator
                          </div>
                          <div v-else>Edit Eso Administrator</div>
                          <v-spacer></v-spacer>
                          <v-btn
                            icon
                            @click="
                              changeEsoAdministratorView('default')
                            "
                          >
                            <v-icon class="text--text"> mdi-close </v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-form
                            v-model="valid"
                            ref="esoAdministratorForm"
                            lazy-validation
                          >
                            <v-layout row wrap class="mt-5">
                              <v-flex xs12 md4>
                                <div class="pa-3">
                                  <div>
                                    User Code<span class="error--text">*</span>
                                  </div>
                                  <v-autocomplete
                                    v-model="newAdministrator.user_id"
                                    :loading="loading"
                                    :items="items"
                                    :search-input.sync="searchUsers"
                                    item-value="id"
                                    item-text="code"
                                    cache-items
                                    class="text_field background my-2"
                                    flat
                                    hide-no-data
                                    hide-details
                                    outlined
                                    dense
                                    :rules="inputRules"
                                  >
                                    <template v-slot:selection="{ item }">
                                      {{ getText(item) }}
                                    </template>
                                    <template v-slot:item="{ item }">
                                      {{ getText(item) }}
                                    </template>
                                  </v-autocomplete>
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-form>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            depressed
                            class="primary text-none mt-3"
                            @click="saveEsoAdministrator"
                            :disabled="!valid"
                            :loading="loading"
                          >
                            Save
                            <v-icon right> mdi-content-save </v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-flex>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </v-layout>
      </v-container>
      <logData v-if="logDataModel" v-model="logDataModel" />
    </div>
  </template>
  <style>
  .ck-editor__editable {
    min-height: 200px;
  }
  </style>
  <script>
  import Vue from "vue";
  import apiCall from "@/utils/api";
  import { mapGetters, mapState, mapActions } from "vuex";
  import logData from "@/views/accessControl/logData";
  import CKEditor from "@ckeditor/ckeditor5-vue2";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  import uploadAdapter from '@/uploadAdapter.js';
  
  Vue.use(CKEditor);
  
  export default {
    components: {
      logData,
    },
    data() {
      return {
        path: process.env.VUE_APP_API_URL,
  
        editor: ClassicEditor,
        editorConfig: {
            extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                return new uploadAdapter(loader);
            } }],
            language: 'en',
        },
  
        importDialog: false,
        activeTab: "details",
        
        view: "default",
        esoAdministratorView: "default",
  
        contactView: "default",
        loading: false,
        esoAdministratorLoader: false,
  
        activateLoading: false,
        deactivateLoading: false,
  
        valid: true,
        message: "",
        color: "",
        delete: false,
        activate: false,
        confirm: false,
        tab: null,
        
        esoTab: null,
        esoAdministratorIndex: null,
        esoIndex: null,
  
        snackbar: false,
        inputRules: [(v) => !!v || "Input is required"],
  
        emailRules: [
          (v) =>
            !v ||
            /^[a-zA-Z0-9\.!#$%&'*+/=?^_~-]+@([a-zA-Z0-9]+\.)+[a-zA-Z]{2,3}$/.test(
              v
            ) ||
            "E-mail must be valid",
          (v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
        ],
        websiteRules: [
          (v) =>
            !v ||
            /^[a-zA-Z0-9\.!#:$%&'*+/=?^_~-]+[a-zA-Z]{2,3}$/.test(v) ||
            "URL must be valid",
          (v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
        ],
  
        items: [],
        searchUsers: null,
  
        searchTerm: null,
        esoAdministratorSearchTerm: null,
  
        searchLoader: false,
        searchButtonDisabled: false,
        esoAdministratorSearchButtonDisabled: false,
  
  
        filteredEsos: false,
        filteredEsoAdministrators: false,
  
        filteredInstitutions: false,
        filteredInstitutionAdministrators: false,
  
        years: [],
        newEso: {
          id: null,
          name: null,
          eso_type_id: null,
          county_id: null,
          brief: null,
          year: null,
          physical_address: null,
        },
  
        newContact: {
          id: null,
          eso_id: null,
          contact_person: null,
          email: null,
          email_2: null,
          phone: null,
          phone_2: null,
          web_url: null,
          fb_url: null,
          insta_url: null,
          twitter_url: null,
          visible: null,
        },
  
        newAdministrator: {
          id: null,
          eso_id: null,
          user_id: null,
        },
  
        assignedEso: null,
        formData: null,
  
        coverImageDialog: false,
        imageDialog: false,
        imageLoading: false,
        fileRules: [(v) => !!v || this.$t("kindlySelectAFileMsg")],
        imageFile: null,
        imageValid: true,
  
        searchTerm: null,
        searchLoader: false,
        esoAdministratorSearchLoader: false,
  
        institutionAdministratorSearchLoader: false,
  
        searchButtonDisabled: false,
  
        filteredEsos: [],
        esoAdministrators: [],
  
        institutionAdministrators: [],

  
        esoAdministratorPagination: {
          search: " ",
          current_page: 1,
          per_page: 0,
          total: 0,
          visible: 10,
        },

        
        logData: {
          message: null,
          model: null,
        },
      };
    },
    watch: {
      searchUsers(val) {
        val && val !== this.newAdministrator.user_id && this.findUser(val);
      },
    },
    created() {
      if (this.$can("eso_view")) {
        this.startEsoLoader();
        this.fetchEsos(this.esoPagination.current_page);
        this.getFormData();
        this.populateYears();
      }
    },
    methods: {
      ...mapActions([
        "fetchEsos",
        "startEsoLoader",
        "stopEsoLoader",
        "filterEsos",
  
        "filterLogData",
        "openLogDataModel",
      ]),
      populateYears() {
        var max = new Date().getFullYear();
        var min = max - 100;
        var years = [];
  
        for (var i = max; i >= min; i--) {
          years.push(i);
        }
        this.years = years;
      },
      findUser(v) {
        this.loading = true;
        
        setTimeout(() => {
          apiCall({
            url: "/api/user?type=search&search=" + this.searchUsers,
            method: "GET",
          })
            .then((resp) => {
              this.items = resp.data;
            })
            .catch((error) => {
              this.loading = false;
            });
  
          this.loading = false;
        }, 3000);
      },
  
      showEsoAdministrator(user) {
        this.$router.push("/user/" + user.code);
      },
     
      enableSearch() {
        this.searchButtonDisabled = false;
      },
      
  
      enableEsoAdministratorSearch() {
        this.esoAdministratorSearchButtonDisabled = false;
      },
      
     
      resetSearch() {
        this.searchTerm = null;
        this.filteredEsos = true;
        this.startEsoLoader();
        this.fetchEsos(1);
      },    
  
      resetEsoAdministratorSearch() {
        this.esoAdministratorSearchTerm = null;
        this.filteredEsoAdministrators = false;
        this.esoAdministratorLoader = false;
        this.changeEsoAdministratorPage();
      },

      changeEsoAdministratorPage() {
        this.esoAdministratorLoader = true;
        if (this.filteredEsoAdministrators == true) {
          this.esoAdministratorSearchButtonDisabled = false;
          apiCall({
            url:
              "/api/eso-administrator?eso_id=" +
              this.assignedEso.id +
              "&page=" +
              this.esoAdministratorPagination.current_page +
              "&type=search&search=" +
              this.esoAdministratorSearchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.esoAdministratorLoader = false;
  
              this.esoAdministrators = resp.data;
              this.esoAdministratorPagination.current_page =
                resp.current_page;
              this.esoAdministratorPagination.total = resp.total;
              this.esoAdministratorPagination.per_page = resp.per_page;
            })
            .catch((error) => {
              console.log(error);
              this.esoAdministratorLoader = false;
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
            });
        } else {
          apiCall({
            url:
              "/api/eso-administrator?eso_id=" +
              this.assignedEso.id +
              "&page=" +
              this.esoAdministratorPagination.current_page,
            method: "GET",
          })
            .then((resp) => {
              this.esoAdministratorLoader = false;
  
              this.esoAdministrators = resp.data;
              this.esoAdministratorPagination.current_page =
                resp.current_page;
              this.esoAdministratorPagination.total = resp.total;
              this.esoAdministratorPagination.per_page = resp.per_page;
            })
            .catch((error) => {
              console.log(error);
              this.esoAdministratorLoader = false;
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
            });
        }
      },
      
      getText(item) {
        return (
          item.f_name + " " + item.m_name + " " + item.l_name + " #" + item.code
        );
      },
      showEso(eso) {
        this.assignedEso = eso;
        this.newContact.eso_id = eso.id;

        if (this.$can("eso_administrator_view")) {
          this.changeEsoAdministratorPage();
        }
  
        this.changeView("show");
      },
  
      editEso(eso) {
        this.newEso.id = eso.id;
        this.newEso.name = eso.name;
        this.newEso.eso_type_id =
          eso.eso_type_id;
        this.newEso.county_id = eso.county_id;
        this.newEso.brief = eso.brief;
        this.newEso.year = parseInt(eso.establishment_year);
        this.newEso.description = eso.description;
        this.newEso.physical_address = eso.physical_address;
  
        console.log("this.newEso", this.newEso)
  
        this.changeView("create");
      },
      editContact(contact) {
        this.newContact.id = contact.id;
        this.newContact.contact_person = contact.contact_person;
        this.newContact.email = contact.email;
        this.newContact.email_2 = contact.email_2;
        this.newContact.phone = contact.phone;
        this.newContact.phone_2 = contact.phone_2;
        this.newContact.web_url = contact.web_url;
        this.newContact.fb_url = contact.fb_url;
        this.newContact.insta_url = contact.insta_url;
        this.newContact.twitter_url = contact.twitter_url;
        this.newContact.visible = contact.visible;
        this.contactView = "create";
      },
  
      uploadImage() {
        if (this.$refs.imageForm.validate()) {
          this.imageLoading = true;
          let formData = new FormData();
  
          // files
  
          formData.append("files", this.imageFile, this.imageFile.name);
          formData.append("id", this.assignedEso.id);
  
          apiCall({
            url: "/api/eso?type=image",
            data: formData,
            method: "POST",
          })
            .then((resp) => {
              this.snackbar = true;
              this.message = "Logo Uploaded Successfully";
              this.color = "success";
              this.fetchEsos(this.esoPagination.current_page);
              this.imageLoading = false;
              this.imageDialog = false;
              this.imageFile = null;
              this.assignedEso.logo = resp.logo;
              // this.changeView('default')
            })
            .catch((error) => {
              this.imageLoading = false;
            });
        }
      },
      uploadCoverImage() {
        if (this.$refs.imageForm.validate()) {
          this.imageLoading = true;
          let formData = new FormData();
  
          // files
  
          formData.append("files", this.imageFile, this.imageFile.name);
          formData.append("id", this.assignedEso.id);
  
          apiCall({
            url: "/api/eso?type=coverImage",
            data: formData,
            method: "POST",
          })
            .then((resp) => {
              this.snackbar = true;
              this.message = "Cover Image Uploaded Successfully";
              this.color = "success";
              this.fetchEsos(this.esoPagination.current_page);
              this.imageLoading = false;
              this.coverImageDialog = false;
              this.imageFile = null;
              this.assignedEso.cover_image = resp.cover_image;
              // this.changeView('default')
            })
            .catch((error) => {
              this.imageLoading = false;
            });
        }
      },
      save() {
        if (this.$refs.form.validate()) {
          if (this.newEso.id != null) {
            confirm("Are you sure you want to update eso?") &&
              (this.confirm = true);
            if (this.confirm) {
              this.loading = true;
              apiCall({
                url: "/api/eso/" + this.newEso.id,
                data: this.newEso,
                method: "PUT",
              })
                .then((resp) => {
                  this.message = "Eso Updated Successfully";
                  this.color = "orange";
                  this.loading = false;
                  this.snackbar = true;
                  this.confirm = false;
                  this.changeView("default");
                  this.fetchEsos(1);
                })
                .catch((error) => {
                  this.message = "An Error Occurred";
                  this.color = "error";
                  this.loading = false;
                  this.snackbar = true;
                });
            }
          } else {
            confirm("Are you sure you want to create eso?") &&
              (this.confirm = true);
            if (this.confirm) {
              this.loading = true;
              apiCall({
                url: "/api/eso",
                data: this.newEso,
                method: "POST",
              })
                .then((resp) => {
                  this.message = "Eso Added Successfully";
                  this.color = "success";
                  this.loading = false;
                  this.snackbar = true;
                  this.confirm = false;
                  this.changeView("default");
                  this.fetchEsos(1);
                })
                .catch((error) => {
                  this.message = "An Error Occurred";
                  this.color = "error";
                  this.loading = false;
                  this.snackbar = true;
                  this.changeView("default");
                  this.fetchEsos(1);
                });
            }
          }
        }
      },
      saveContact() {
        if (this.$refs.contactForm.validate()) {
          if (this.newContact.id != null) {
            confirm("Are you sure you want to update contact information?") &&
              (this.confirm = true);
            if (this.confirm) {
              this.loading = true;
              apiCall({
                url: "/api/eso-contact/" + this.newContact.id,
                data: this.newContact,
                method: "PUT",
              })
                .then((resp) => {
                  this.message = "Contact Information Updated Successfully";
                  this.color = "orange";
                  this.loading = false;
                  this.snackbar = true;
                  this.confirm = false;
                  this.$nextTick(() => {
                    this.assignedEso.eso_contact = resp;
                  });
  
                  this.changeContactView("default");
  
                  this.fetchEsos(1);
                })
                .catch((error) => {
                  this.message = "An Error Occurred";
                  this.color = "error";
                  this.loading = false;
                  this.snackbar = true;
                });
            }
          } else {
            confirm("Are you sure you want to create contact information?") &&
              (this.confirm = true);
            if (this.confirm) {
              this.loading = true;
              apiCall({
                url: "/api/eso-contact",
                data: this.newContact,
                method: "POST",
              })
                .then((resp) => {
                  this.message = "Contact Information Added Successfully";
                  this.color = "success";
                  this.loading = false;
                  this.snackbar = true;
                  this.confirm = false;
                  this.changeContactView("default");
                  this.assignedEso.eso_contact = resp;
                  this.fetchEsos(1);
                })
                .catch((error) => {
                  this.message = "An Error Occurred";
                  this.color = "error";
                  this.loading = false;
                  this.snackbar = true;
                  this.fetchEsos(1);
                });
            }
          }
        }
      },
  
      saveEsoAdministrator() {
        if (this.$refs.esoAdministratorForm.validate()) {
          confirm("Are you sure you want to add eso administrator?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            this.newAdministrator.eso_id = this.assignedEso.id;
            apiCall({
              url: "/api/eso-administrator",
              data: this.newAdministrator,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Eso Administrator Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.newAdministrator.user_id = null;
                this.changeEsoAdministratorView("default");
                this.changeEsoAdministratorPage();
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        }
      },
  
      search() {
        if (this.searchTerm == null) {
          this.searchButtonDisabled = true;
        } else {
          this.searchLoader = true;
          this.searchButtonDisabled = false;
          this.startEsoLoader();
          apiCall({
            url: "/api/eso?type=search&search=" + this.searchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.filterEsos(resp);
              this.searchLoader = false;
              this.stopEsoLoader();
              this.filteredEsos = true;
            })
            .catch((error) => {
              console.log(error);
              this.searchLoader = false;
              this.stopEsoLoader();
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
            });
        }
      },
      
  
      esoAdministratorSearch() {
        if (this.esoAdministratorSearchTerm == null) {
          this.esoAdministratorSearchButtonDisabled = true;
        } else {
          this.esoAdministratorSearchLoader = true;
          this.esoAdministratorSearchButtonDisabled = false;
          this.startEsoLoader();
          apiCall({
            url:
              "/api/eso-administrator?type=search&search=" +
              this.esoAdministratorSearchTerm +
              "&eso_id=" +
              this.assignedEso.id,
            method: "GET",
          })
            .then((resp) => {
              this.esoAdministrators = resp.data;
              this.esoAdministratorPagination.current_page =
                resp.current_page;
              this.esoAdministratorPagination.total = resp.total;
              this.esoAdministratorPagination.per_page = resp.per_page;
  
              this.esoAdministratorSearchLoader = false;
              this.filteredEsoAdministrators = true;
            })
            .catch((error) => {
              this.esoAdministratorSearchLoader = false;
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              console.log(error);
            });
        }
      },
      activateEso(item) {
        confirm("Are You Sure You Want to Activate Eso?") &&
          (this.activate = true);
        if (this.activate) {
          this.activateLoading = true;
          this.esoIndex = item.id;
          this.newEso.id = item.id;
          apiCall({
            url: "/api/eso?type=activate",
            data: this.newEso,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Eso Account Activated Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.activateLoading = false;
              this.activate = false;
              this.esoIndex = null;
              this.fetchEsos(this.esoPagination.current_page);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.activateLoading = false;
              this.activate = false;
              this.EsoIndex = null;
              console.log(error);
            });
        }
      },
      deactivateEso(item) {
        confirm("Are You Sure You Want to Deactivate Eso?") &&
          (this.activate = true);
        if (this.activate) {
          this.deactivateLoading = true;
          this.esoIndex = item.id;
          this.newEso.id = item.id;
          apiCall({
            url: "/api/eso?type=deactivate",
            data: this.newEso,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Eso Account Deactivated Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.deactivateLoading = false;
              this.activate = false;
              this.esoIndex = null;
              this.fetchEsos(this.esoPagination.current_page);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.deactivateLoading = false;
              this.activate = false;
              this.esoIndex = null;
              console.log(error);
            });
        }
      },
     
      activateEsoAdministrator(item) {
        confirm(
          "Are You Sure You Want to Activate Eso Administrator?"
        ) && (this.activate = true);
        if (this.activate) {
          this.activateLoading = true;
          this.esoAdministratorIndex = item.id;
          this.newAdministrator.id = item.id;
          apiCall({
            url: "/api/eso-administrator?type=activate",
            data: this.newAdministrator,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Eso Administrator Activated Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.activateLoading = false;
              this.activate = false;
              this.esoAdministratorIndex = null;
              this.changeEsoAdministratorPage();
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.activateLoading = false;
              this.activate = false;
              this.esoAdministratorIndex = null;
              console.log(error);
            });
        }
      },
  
      deactivateEsoAdministrator(item) {
        confirm(
          "Are You Sure You Want to Deactivate Eso Administrator?"
        ) && (this.activate = true);
        if (this.activate) {
          this.deactivateLoading = true;
          this.esoAdministratorIndex = item.id;
          this.newAdministrator.id = item.id;
          apiCall({
            url: "/api/eso-administrator?type=deactivate",
            data: this.newAdministrator,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Eso Administrator Deactivated Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.deactivateLoading = false;
              this.activate = false;
              this.esoAdministratorIndex = null;
              this.changeEsoAdministratorPage();
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.deactivateLoading = false;
              this.activate = false;
              this.esoAdministratorIndex = null;
              console.log(error);
            });
        }
      },
      
      changeView(view) {
        if (view == "default") {
          this.newEso.id = null;
          this.newEso.name = null;
          this.newEso.eso_type_id = null;
          this.newEso.county_id = null;
          this.newEso.brief = null;
          this.newEso.physical_address = null;
        } else if (view == "create") {
          this.getFormData();
        } else if (view == "show") {
        }
        this.view = view;
      },
  
      changeContactView(view) {
        if (view == "default") {
          this.newContact.id = null;
          this.newContact.telephone = null;
          this.newContact.mobile = null;
          this.newContact.email = null;
          this.newContact.website = null;
        } else if (view == "create") {
          //this.getFormData();
        } else if (view == "show") {
        }
        this.contactView = view;
      },
     
  
      changeEsoAdministratorView(view) {
        if (view == "default") {
          this.newAdministrator.id = null;
          this.newAdministrator.eso_id = null;
          this.newAdministrator.user_id = null;
        } else if (view == "create") {
        } else if (view == "show") {
        }
        this.esoAdministratorView = view;
      },
      
      changePage() {
        this.startEsoLoader();
        if (this.filteredEsos == true) {
          this.searchButtonDisabled = false;
          apiCall({
            url:
              "/api/eso?page=" +
              this.esoPagination.current_page +
              "&type=search&search=" +
              this.searchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.filterEsos(resp);
              this.stopEsoLoader();
            })
            .catch((error) => {
              console.log(error);
              this.stopEsoLoader();
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
            });
        } else {
          this.fetchEsos(this.esoPagination.current_page);
        }
      },
      deleteEso(item) {
        confirm("Are You Sure You Want to Delete Eso") &&
          (this.delete = true);
        if (this.delete) {
          this.loading = true;
          this.esoIndex = item.id;
          apiCall({ url: "/api/eso/" + item.id, method: "DELETE" })
            .then((resp) => {
              this.message = "Eso Deleted Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.esoIndex = null;
              this.fetchEsos(this.esoPagination.current_page);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.esoIndex = null;
              console.log(error);
            });
        }
      },
      deleteContact(item) {
        confirm("Are You Sure You Want to Delete Contact Information?") &&
          (this.delete = true);
        if (this.delete) {
          this.loading = true;
          apiCall({
            url: "/api/eso-contact/" + item.id,
            method: "DELETE",
          })
            .then((resp) => {
              this.message = "Contact Information Deleted Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.$nextTick(() => {
                this.assignedEso.eso_contact = null;
              });
              this.fetchEsos(this.esoPagination.current_page);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.esoIndex = null;
              console.log(error);
            });
        }
      },
  
      deleteEsoAdministrator(item) {
        confirm("Are You Sure You Want to Remove Administrator?") &&
          (this.delete = true);
        if (this.delete) {
          this.loading = true;
          this.esoAdministratorIndex = item.id;
          apiCall({
            url: "/api/eso-administrator/" + item.id,
            method: "DELETE",
          })
            .then((resp) => {
              this.message = "Administrator Deleted Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.esoAdministratorIndex = null;
              this.changeEsoAdministratorPage();
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.esoAdministratorIndex = null;
              console.log(error);
            });
        }
      },
      
      getFormData() {
        apiCall({
          url: "/api/eso?type=formData",
          method: "GET",
        })
          .then((resp) => {
            this.formData = resp;
          })
          .catch((error) => {
            this.message = "anErrorOccurredCouldntGetFormDataMsg";
            this.color = "error";
          });
      },
      viewLog(data) {
        this.logData.message = data.id;
        this.logData.model = "App\\Models\\Eso";
        this.filterLogData(this.logData);
        this.openLogDataModel();
      },
    },
    computed: {
      ...mapGetters([
        "esos",
        "esoPagination",
        "esoLoader",
        "logDataModel"
      ]),
      length: function () {
        return Math.ceil(
          this.esoPagination.total / this.esoPagination.per_page
        );
      },
      esoAdministratorLength: function () {
        return Math.ceil(
          this.esoAdministratorPagination.total /
            this.esoAdministratorPagination.per_page
        );
      },
    
      dateToday() {
        var today = new Date();
  
        var timestamp =
          today.getFullYear() +
          "-" +
          this.months[today.getMonth()] +
          "-" +
          ("0" + today.getDate()).slice(-2);
        return timestamp;
      },
    },
  };
  </script>
  