<template>
    <div class="TermsOfUse">
        <v-container>
            <v-card elevation="0" class="pa-16 my-10">
                <div class="headline">
                    <b>TERMS OF USE</b>
                </div>
                <div class="mt-5">
                    <b>Last updated January 01, 2023</b>
                </div>
                <div class="mt-10">
                    <b class="body-2">AGREEMENT TO OUR LEGAL TERMS</b>
                    <p class="body-2 mt-5">
                        We are Kenya National Innovation Agency, doing business as KENIA ('Company', 'we', 'us', or 'our'),
                        a
                        company registered in Kenya at Ground Floor, NACOSTI Building, Nairobi P.O. Box 22269-00100.
                    </p>
                    <p class="body-2 mt-5">
                        We operate the website https://bridge.innovationagency.go.ke (the 'Site'), as well as any other
                        related
                        products and services that refer or link to these legal terms (the 'Legal Terms') (collectively, the
                        'Services').
                    </p>
                    <p class="body-2 mt-5">
                        Kenya Innovation Bridge helps in the discoverability of innovations in Kenya. The platform is a
                        marketplace
                        that enables innovators, inventors, researchers and startups to introduce their solutions to
                        partners,
                        funders, customers and users. The goal is to get as many innovations scale by attracting appropriate
                        funding
                        and partnerships.
                    </p>
                    <p class="body-2 mt-5">
                        You can contact us by phone at +254-792 446 976, email at info@innovationagency.go.ke, or by mail to
                        Ground
                        Floor, NACOSTI Building, Nairobi P.O. Box 22269-00100, Kenya.
                    </p>
                    <p class="body-2 mt-5">
                        These Legal Terms constitute a legally binding agreement made between you, whether personally or on
                        behalf
                        of an entity ('you'), and Kenya National Innovation Agency, concerning your access to and use of the
                        Services. You agree that by accessing the Services, you have read, understood, and agreed to be
                        bound by all
                        of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY
                        PROHIBITED
                        FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                    </p>
                    <p class="body-2 mt-5">
                        We will provide you with prior notice of any scheduled changes to the Services you are using. The
                        modified
                        Legal Terms will become effective upon posting or notifying you by info@innovationagency.go.ke, as
                        stated in
                        the email message. By continuing to use the Services after the effective date of any changes, you
                        agree to
                        be bound by the modified terms.
                    </p>
                    <p class="body-2 mt-5">
                        The Services are intended for users who are at least 18 years old. Persons under the age of 18 are
                        not
                        permitted to use or register for the Services.
                    </p>
                    <p class="body-2 mt-5">
                        We recommend that you print a copy of these Legal Terms for your records.
                    </p>

                </div>
                <div class="title mt-10">
                    <b>TABLE OF CONTENTS</b>
                </div>
                <div class="body-2 mt-5">
                    <ol>
                        <li> <a @click="scrollMeTo('section-1')">OUR SERVICES</a></li>
                        <li> <a @click="scrollMeTo('section-2')">INTELLECTUAL PROPERTY RIGHTS</a></li>
                        <li> <a @click="scrollMeTo('section-3')">USER REPRESENTATIONS</a></li>
                        <li> <a @click="scrollMeTo('section-4')">USER REGISTRATION</a></li>
                        <li> <a @click="scrollMeTo('section-5')">PROHIBITED ACTIVITIES</a></li>
                        <li> <a @click="scrollMeTo('section-6')">USER GENERATED CONTRIBUTIONS</a></li>
                        <li> <a @click="scrollMeTo('section-7')">CONTRIBUTION LICENCE</a></li>
                        <li> <a @click="scrollMeTo('section-8')">GUIDELINES FOR REVIEWS</a></li>
                        <li> <a @click="scrollMeTo('section-9')">THIRD-PARTY WEBSITES AND CONTENT</a></li>
                        <li> <a @click="scrollMeTo('section-10')">SERVICES MANAGEMENT</a></li>
                        <li> <a @click="scrollMeTo('section-11')">PRIVACY POLICY</a></li>
                        <li> <a @click="scrollMeTo('section-12')">TERM AND TERMINATION</a></li>
                        <li> <a @click="scrollMeTo('section-13')">MODIFICATIONS AND INTERRUPTIONS</a></li>
                        <li> <a @click="scrollMeTo('section-14')">GOVERNING LAW</a></li>
                        <li> <a @click="scrollMeTo('section-15')">DISPUTE RESOLUTION</a></li>
                        <li> <a @click="scrollMeTo('section-16')">CORRECTIONS</a></li>
                        <li> <a @click="scrollMeTo('section-17')">DISCLAIMER</a></li>
                        <li> <a @click="scrollMeTo('section-18')">LIMITATIONS OF LIABILITY</a></li>
                        <li> <a @click="scrollMeTo('section-19')">INDEMNIFICATION</a></li>
                        <li> <a @click="scrollMeTo('section-20')">USER DATA</a></li>
                        <li> <a @click="scrollMeTo('section-21')">ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND
                                SIGNATURES</a></li>
                        <li> <a @click="scrollMeTo('section-22')">MISCELLANEOUS</a></li>
                        <li> <a @click="scrollMeTo('section-23')">CONTACT US</a></li>
                    </ol>
                </div>
                <div class="title mt-10" ref="section-1">
                    <b>1. OUR SERVICES</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        The information provided when using the Services is not intended for distribution to or use by any
                        person or
                        entity in any jurisdiction or country where such distribution or use would be contrary to law or
                        regulation
                        or which would subject us to any registration requirement within such jurisdiction or country.
                        Accordingly,
                        those persons who choose to access the Services from other locations do so on their own initiative
                        and are
                        solely responsible for compliance with local laws, if and to the extent local laws are applicable.
                    </p>
                </div>
                <div class="title mt-10" ref="section-2">
                    <b>2. INTELLECTUAL PROPERTY RIGHTS</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        <b>Our intellectual property</b>
                    </p>
                    <p class="body-2 mt-5">
                        We are the owner or the licensee of all intellectual property rights in our Services, including all
                        source
                        code, databases, functionality, software, website designs, audio, video, text, photographs, and
                        graphics in
                        the Services (collectively, the 'Content'), as well as the trademarks, service marks, and logos
                        contained
                        therein (the 'Marks').
                    </p>
                    <p class="body-2 mt-5">
                        Our Content and Marks are protected by copyright and trademark laws (and various other intellectual
                        property
                        rights and unfair competition laws) and treaties in the United States and around the world.
                    </p>
                    <p class="body-2 mt-5">
                        The Content and Marks are provided in or through the Services 'AS IS' for your personal,
                        non-commercial use
                        or internal business purpose only.
                    </p>
                    <p class="body-2 mt-5">
                        <b>Your use of our Services</b>
                    </p>
                    <p class="body-2 mt-5">
                        Subject to your compliance with these Legal Terms, including the 'PROHIBITED ACTIVITIES' section
                        below, we
                        grant you a non-exclusive, non-transferable, revocable licence to:
                    </p>
                    <ul class="body-2 mt-5">
                        <li>
                            access the Services; and
                        </li>
                        <li class="mt-5">
                            download or print a copy of any portion of the Content to which you have properly gained access.
                        </li>
                    </ul>
                    <p class="body-2 mt-5">
                        solely for your personal, non-commercial use or internal business purpose.
                    </p>
                    <p class="body-2 mt-5">
                        Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no
                        Content or
                        Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed,
                        encoded,
                        translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial
                        purpose
                        whatsoever, without our express prior written permission.
                    </p>
                    <p class="body-2 mt-5">
                        If you wish to make any use of the Services, Content, or Marks other than as set out in this section
                        or
                        elsewhere in our Legal Terms, please address your request to: info@innovationagency.go.ke. If we
                        ever grant
                        you the permission to post, reproduce, or publicly display any part of our Services or Content, you
                        must
                        identify us as the owners or licensors of the Services, Content, or Marks and ensure that any
                        copyright or
                        proprietary notice appears or is visible on posting, reproducing, or displaying our Content.
                    </p>
                    <p class="body-2 mt-5">
                        We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.
                    </p>
                    <p class="body-2 mt-5">
                        Any breach of these Intellectual Property Rights will constitute a material breach of our Legal
                        Terms and
                        your right to use our Services will terminate immediately.
                    </p>
                    <p class="body-2 mt-5">
                        <b>Your submissions and contributions</b>
                    </p>
                    <p class="body-2 mt-5">
                        Please review this section and the 'PROHIBITED ACTIVITIES' section carefully prior to using our
                        Services to
                        understand the (a) rights you give us and (b) obligations you have when you post or upload any
                        content
                        through the Services.
                    </p>
                    <p class="body-2 mt-5">
                        <b>Submissions:</b> By directly sending us any question, comment, suggestion, idea, feedback, or
                        other information
                        about the Services ('Submissions'), you agree to assign to us all intellectual property rights in
                        such
                        Submission. You agree that we shall own this Submission and be entitled to its unrestricted use and
                        dissemination for any lawful purpose, commercial or otherwise, without acknowledgment or
                        compensation to
                        you.
                    </p>
                    <p class="body-2 mt-5">
                        <b>Contributions:</b> The Services may invite you to chat, contribute to, or participate in blogs,
                        message boards,
                        online forums, and other functionality during which you may create, submit, post, display, transmit,
                        publish, distribute, or broadcast content and materials to us or through the Services, including but
                        not
                        limited to text, writings, video, audio, photographs, music, graphics, comments, reviews, rating
                        suggestions, personal information, or other material ('Contributions'). Any Submission that is
                        publicly
                        posted shall also be treated as a Contribution.
                    </p>
                    <p class="body-2 mt-5">
                        You understand that Contributions may be viewable by other users of the Services and possibly
                        through
                        third-party websites.
                    </p>
                    <p class="body-2 mt-5">
                        When you post Contributions, you grant us a licence (including use of your name, trademarks, and
                        logos): By
                        posting any Contributions, you grant us an unrestricted, unlimited, irrevocable, perpetual,
                        non-exclusive,
                        transferable, royalty-free, fully-paid, worldwide right, and licence to: use, copy, reproduce,
                        distribute,
                        sell, resell, publish, broadcast, retitle, store, publicly perform, publicly display, reformat,
                        translate,
                        excerpt (in whole or in part), and exploit your Contributions (including, without limitation, your
                        image,
                        name, and voice) for any purpose, commercial, advertising, or otherwise, to prepare derivative works
                        of, or
                        incorporate into other works, your Contributions, and to sublicence the licences granted in this
                        section.
                        Our use and distribution may occur in any media formats and through any media channels.
                    </p>
                    <p class="body-2 mt-5">
                        This licence includes our use of your name, company name, and franchise name, as applicable, and any
                        of the
                        trademarks, service marks, trade names, logos, and personal and commercial images you provide.
                    </p>
                    <p class="body-2 mt-5">
                        You are responsible for what you post or upload: By sending us Submissions and/or posting
                        Contributions
                        through any part of the Services or making Contributions accessible through the Services by linking
                        your
                        account through the Services to any of your social networking accounts, you:
                    </p>
                    <ul class="body-2 mt-5">
                        <li>
                            confirm that you have read and agree with our 'PROHIBITED ACTIVITIES' and will not post, send,
                            publish,
                            upload, or transmit through the Services any Submission nor post any Contribution that is
                            illegal,
                            harassing, hateful, harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening
                            to any
                            person or group, sexually explicit, false, inaccurate, deceitful, or misleading;
                        </li>
                        <li>
                            to the extent permissible by applicable law, waive any and all moral rights to any such
                            Submission and/or
                            Contribution;
                        </li>
                        <li>
                            warrant that any such Submission and/or Contributions are original to you or that you have the
                            necessary
                            rights and licences to submit such Submissions and/or Contributions and that you have full
                            authority to
                            grant us the above-mentioned rights in relation to your Submissions and/or Contributions; and
                        </li>
                        <li>
                            warrant and represent that your Submissions and/or Contributions do not constitute confidential
                            information.
                        </li>
                    </ul>
                    <p class="body-2 mt-5">
                        You are solely responsible for your Submissions and/or Contributions and you expressly agree to
                        reimburse us
                        for any and all losses that we may suffer because of your breach of (a) this section, (b) any third
                        party’s
                        intellectual property rights, or (c) applicable law.
                    </p>
                    <p class="body-2 mt-5">
                        <b>We may remove or edit your Content:</b> Although we have no obligation to monitor any
                        Contributions, we shall
                        have the right to remove or edit any Contributions at any time without notice if in our reasonable
                        opinion
                        we consider such Contributions harmful or in breach of these Legal Terms. If we remove or edit any
                        such
                        Contributions, we may also suspend or disable your account and report you to the authorities.
                    </p>

                </div>
                <div class="title mt-10" ref="section-3">
                    <b>3. USER REPRESENTATIONS</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        By using the Services, you represent and warrant that: (1) all registration information you submit
                        will be
                        true, accurate, current, and complete; (2) you will maintain the accuracy of such information and
                        promptly
                        update such registration information as necessary; (3) you have the legal capacity and you agree to
                        comply
                        with these Legal Terms; (4) you are not a minor in the jurisdiction in which you reside; (5) you
                        will not
                        access the Services through automated or non-human means, whether through a bot, script or
                        otherwise; (6)
                        you will not use the Services for any illegal or unauthorised purpose; and (7) your use of the
                        Services will
                        not violate any applicable law or regulation.
                    </p>
                    <p class="body-2 mt-5">
                        If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the
                        right to
                        suspend or terminate your account and refuse any and all current or future use of the Services (or
                        any
                        portion thereof).
                    </p>

                </div>
                <div class="title mt-10" ref="section-4">
                    <b>4. USER REGISTRATION</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        You may be required to register to use the Services. You agree to keep your password confidential
                        and will
                        be responsible for all use of your account and password. We reserve the right to remove, reclaim, or
                        change
                        a username you select if we determine, in our sole discretion, that such username is inappropriate,
                        obscene,
                        or otherwise objectionable.
                    </p>
                </div>
                <div class="title mt-10" ref="section-5">
                    <b>5. PROHIBITED ACTIVITIES</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        You may not access or use the Services for any purpose other than that for which we make the
                        Services
                        available. The Services may not be used in connection with any commercial endeavours except those
                        that are
                        specifically endorsed or approved by us.
                    </p>
                    <p class="body-2 mt-5">
                        As a user of the Services, you agree not to:
                    </p>
                    <p class="body-2 mt-5">
                    <ul>
                        <li>
                            Systematically retrieve data or other content from the Services to create or compile, directly
                            or
                            indirectly, a collection, compilation, database, or directory without written permission from
                            us.
                        </li>
                        <li>
                            Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive
                            account
                            information such as user passwords.
                        </li>
                        <li>
                            Circumvent, disable, or otherwise interfere with security-related features of the Services,
                            including
                            features that prevent or restrict the use or copying of any Content or enforce limitations on
                            the use of the
                            Services and/or the Content contained therein.
                        </li>
                        <li>
                            Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.
                        </li>
                        <li>
                            Use any information obtained from the Services in order to harass, abuse, or harm another
                            person.
                        </li>
                        <li>
                            Make improper use of our support services or submit false reports of abuse or misconduct.
                        </li>
                        <li>
                            Use the Services in a manner inconsistent with any applicable laws or regulations.
                        </li>
                        <li>
                            Engage in unauthorised framing of or linking to the Services.
                        </li>
                        <li>
                            Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other
                            material,
                            including excessive use of capital letters and spamming (continuous posting of repetitive text),
                            that
                            interferes with any party’s uninterrupted use and enjoyment of the Services or modifies,
                            impairs, disrupts,
                            alters, or interferes with the use, features, functions, operation, or maintenance of the
                            Services.
                        </li>
                        <li>
                            Engage in any automated use of the system, such as using scripts to send comments or messages,
                            or using any
                            data mining, robots, or similar data gathering and extraction tools.
                        </li>
                        <li>
                            Delete the copyright or other proprietary rights notice from any Content.
                        </li>
                        <li>
                            Attempt to impersonate another user or person or use the username of another user.
                        </li>
                        <li>
                            Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or
                            active
                            information collection or transmission mechanism, including without limitation, clear graphics
                            interchange
                            formats ('gifs'), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to
                            as
                            'spyware' or 'passive collection mechanisms' or 'pcms').
                        </li>
                        <li>
                            Interfere with, disrupt, or create an undue burden on the Services or the networks or services
                            connected to
                            the Services.
                        </li>
                        <li>
                            Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any
                            portion of
                            the Services to you.
                        </li>
                        <li>
                            Attempt to bypass any measures of the Services designed to prevent or restrict access to the
                            Services, or
                            any portion of the Services.
                        </li>
                        <li>
                            Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript,
                            or other
                            code.
                        </li>
                        <li>
                            Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any
                            of the
                            software comprising or in any way making up a part of the Services.
                        </li>
                        <li>
                            Except as may be the result of standard search engine or Internet browser usage, use, launch,
                            develop, or
                            distribute any automated system, including without limitation, any spider, robot, cheat utility,
                            scraper, or
                            offline reader that accesses the Services, or use or launch any unauthorised script or other
                            software.
                        </li>
                        <li>
                            Use a buying agent or purchasing agent to make purchases on the Services.
                        </li>
                        <li>
                            Make any unauthorised use of the Services, including collecting usernames and/or email addresses
                            of users by
                            electronic or other means for the purpose of sending unsolicited email, or creating user
                            accounts by
                            automated means or under false pretences.
                        </li>
                        <li>
                            Use the Services as part of any effort to compete with us or otherwise use the Services and/or
                            the Content
                            for any revenue-generating endeavour or commercial enterprise.
                        </li>
                    </ul>
                    </p>
                </div>
                <div class="title mt-10" ref="section-6">
                    <b>6. USER GENERATED CONTRIBUTIONS</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        The Services may invite you to chat, contribute to, or participate in blogs, message boards, online
                        forums,
                        and other functionality, and may provide you with the opportunity to create, submit, post, display,
                        transmit, perform, publish, distribute, or broadcast content and materials to us or on the Services,
                        including but not limited to text, writings, video, audio, photographs, graphics, comments,
                        suggestions, or
                        personal information or other material (collectively, 'Contributions'). Contributions may be
                        viewable by
                        other users of the Services and through third-party websites. As such, any Contributions you
                        transmit may be
                        treated as non-confidential and non-proprietary. When you create or make available any
                        Contributions, you
                        thereby represent and warrant that:
                    </p>
                    <p class="body-2 mt-5">
                    <ul>
                        <li>
                            The creation, distribution, transmission, public display, or performance, and the accessing,
                            downloading, or
                            copying of your Contributions do not and will not infringe the proprietary rights, including but
                            not limited
                            to the copyright, patent, trademark, trade secret, or moral rights of any third party.
                        </li>
                        <li>
                            You are the creator and owner of or have the necessary licences, rights, consents, releases, and
                            permissions
                            to use and to authorise us, the Services, and other users of the Services to use your
                            Contributions in any
                            manner contemplated by the Services and these Legal Terms.
                        </li>
                        <li>
                            You have the written consent, release, and/or permission of each and every identifiable
                            individual person in
                            your Contributions to use the name or likeness of each and every such identifiable individual
                            person to
                            enable inclusion and use of your Contributions in any manner contemplated by the Services and
                            these Legal
                            Terms.
                        </li>
                        <li>
                            Your Contributions are not false, inaccurate, or misleading.
                        </li>
                        <li>
                            Your Contributions are not unsolicited or unauthorised advertising, promotional materials,
                            pyramid schemes,
                            chain letters, spam, mass mailings, or other forms of solicitation.
                        </li>
                        <li>
                            Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libellous,
                            slanderous, or
                            otherwise objectionable (as determined by us).
                        </li>
                        <li>
                            Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.
                        </li>
                        <li>
                            Your Contributions are not used to harass or threaten (in the legal sense of those terms) any
                            other person
                            and to promote violence against a specific person or class of people.
                        </li>
                        <li>
                            Your Contributions do not violate any applicable law, regulation, or rule.
                        </li>
                        <li>
                            Your Contributions do not violate the privacy or publicity rights of any third party.
                        </li>
                        <li>
                            Your Contributions do not violate any applicable law concerning child pornography, or otherwise
                            intended to
                            protect the health or well-being of minors.
                        </li>
                        <li>
                            Your Contributions do not include any offensive comments that are connected to race, national
                            origin,
                            gender, sexual preference, or physical handicap.
                        </li>
                        <li>
                            Your Contributions do not otherwise violate, or link to material that violates, any provision of
                            these Legal
                            Terms, or any applicable law or regulation.
                        </li>
                    </ul>
                    </p>
                    <p class="body-2 mt-5">
                        Any use of the Services in violation of the foregoing violates these Legal Terms and may result in,
                        among
                        other things, termination or suspension of your rights to use the Services.
                    </p>
                </div>
                <div class="title mt-10" ref="section-7">
                    <b>7. CONTRIBUTION LICENCES</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        By posting your Contributions to any part of the Services, you automatically grant, and you
                        represent and
                        warrant that you have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual,
                        non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and licence to host, use,
                        copy,
                        reproduce, disclose, sell, resell, publish, broadcast, retitle, archive, store, cache, publicly
                        perform,
                        publicly display, reformat, translate, transmit, excerpt (in whole or in part), and distribute such
                        Contributions (including, without limitation, your image and voice) for any purpose, commercial,
                        advertising, or otherwise, and to prepare derivative works of, or incorporate into other works, such
                        Contributions, and grant and authorise sublicences of the foregoing. The use and distribution may
                        occur in
                        any media formats and through any media channels.
                    </p>
                    <p class="body-2 mt-5">
                        This licence will apply to any form, media, or technology now known or hereafter developed, and
                        includes our
                        use of your name, company name, and franchise name, as applicable, and any of the trademarks,
                        service marks,
                        trade names, logos, and personal and commercial images you provide. You waive all moral rights in
                        your
                        Contributions, and you warrant that moral rights have not otherwise been asserted in your
                        Contributions.
                    </p>
                    <p class="body-2 mt-5">
                        We do not assert any ownership over your Contributions. You retain full ownership of all of your
                        Contributions and any intellectual property rights or other proprietary rights associated with your
                        Contributions. We are not liable for any statements or representations in your Contributions
                        provided by you
                        in any area on the Services. You are solely responsible for your Contributions to the Services and
                        you
                        expressly agree to exonerate us from any and all responsibility and to refrain from any legal action
                        against
                        us regarding your Contributions.
                    </p>
                    <p class="body-2 mt-5">
                        We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any
                        Contributions; (2) to re-categorise any Contributions to place them in more appropriate locations on
                        the
                        Services; and (3) to pre-screen or delete any Contributions at any time and for any reason, without
                        notice.
                        We have no obligation to monitor your Contributions.
                    </p>
                </div>
                <div class="title mt-10" ref="section-8">
                    <b>8. GUIDELINES FOR REVIEWS</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        We may provide you areas on the Services to leave reviews or ratings. When posting a review, you
                        must comply
                        with the following criteria: (1) you should have firsthand experience with the person/entity being
                        reviewed;
                        (2) your reviews should not contain offensive profanity, or abusive, racist, offensive, or hateful
                        language;
                        (3) your reviews should not contain discriminatory references based on religion, race, gender,
                        national
                        origin, age, marital status, sexual orientation, or disability; (4) your reviews should not contain
                        references to illegal activity; (5) you should not be affiliated with competitors if posting
                        negative
                        reviews; (6) you should not make any conclusions as to the legality of conduct; (7) you may not post
                        any
                        false or misleading statements; and (8) you may not organise a campaign encouraging others to post
                        reviews,
                        whether positive or negative.
                    </p>
                    <p class="body-2 mt-5">
                        We may accept, reject, or remove reviews in our sole discretion. We have absolutely no obligation to
                        screen
                        reviews or to delete reviews, even if anyone considers reviews objectionable or inaccurate. Reviews
                        are not
                        endorsed by us, and do not necessarily represent our opinions or the views of any of our affiliates
                        or
                        partners. We do not assume liability for any review or for any claims, liabilities, or losses
                        resulting from
                        any review. By posting a review, you hereby grant to us a perpetual, non-exclusive, worldwide,
                        royalty-free,
                        fully paid, assignable, and sublicensable right and licence to reproduce, modify, translate,
                        transmit by any
                        means, display, perform, and/or distribute all content relating to review.
                    </p>
                </div>
                <div class="title mt-10" ref="section-9">
                    <b>9. THIRD-PARTY WEBSITES AND CONTENT</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        The Services may contain (or you may be sent via the Site) links to other websites ('Third-Party
                        Websites')
                        as well as articles, photographs, text, graphics, pictures, designs, music, sound, video,
                        information,
                        applications, software, and other content or items belonging to or originating from third parties
                        ('Third-Party Content'). Such Third-Party Websites and Third-Party Content are not investigated,
                        monitored,
                        or checked for accuracy, appropriateness, or completeness by us, and we are not responsible for any
                        Third-Party Websites accessed through the Services or any Third-Party Content posted on, available
                        through,
                        or installed from the Services, including the content, accuracy, offensiveness, opinions,
                        reliability,
                        privacy practices, or other policies of or contained in the Third-Party Websites or the Third-Party
                        Content.
                        Inclusion of, linking to, or permitting the use or installation of any Third-Party Websites or any
                        Third-Party Content does not imply approval or endorsement thereof by us. If you decide to leave the
                        Services and access the Third-Party Websites or to use or install any Third-Party Content, you do so
                        at your
                        own risk, and you should be aware these Legal Terms no longer govern. You should review the
                        applicable terms
                        and policies, including privacy and data gathering practices, of any website to which you navigate
                        from the
                        Services or relating to any applications you use or install from the Services. Any purchases you
                        make
                        through Third-Party Websites will be through other websites and from other companies, and we take no
                        responsibility whatsoever in relation to such purchases which are exclusively between you and the
                        applicable
                        third party. You agree and acknowledge that we do not endorse the products or services offered on
                        Third-Party Websites and you shall hold us blameless from any harm caused by your purchase of such
                        products
                        or services. Additionally, you shall hold us blameless from any losses sustained by you or harm
                        caused to
                        you relating to or resulting in any way from any Third-Party Content or any contact with Third-Party
                        Websites.
                    </p>
                </div>
                <div class="title mt-10" ref="section-10">
                    <b>10. SERVICES MANAGEMENT</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these
                        Legal
                        Terms; (2) take appropriate legal action against anyone who, in our sole discretion, violates the
                        law or
                        these Legal Terms, including without limitation, reporting such user to law enforcement authorities;
                        (3) in
                        our sole discretion and without limitation, refuse, restrict access to, limit the availability of,
                        or
                        disable (to the extent technologically feasible) any of your Contributions or any portion thereof;
                        (4) in
                        our sole discretion and without limitation, notice, or liability, to remove from the Services or
                        otherwise
                        disable all files and content that are excessive in size or are in any way burdensome to our
                        systems; and
                        (5) otherwise manage the Services in a manner designed to protect our rights and property and to
                        facilitate
                        the proper functioning of the Services.
                    </p>
                </div>
                <div class="title mt-10" ref="section-11">
                    <b>11. PRIVACY POLICY</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        We care about data privacy and security. Please review our Privacy Policy:
                        <a href="/#/privacy-policy">https://bridge.innovationagency.go.ke/#/privacy-policy</a>. By using the
                        Services, you agree to be bound by our
                        Privacy Policy, which is incorporated into these Legal Terms. Please be advised the Services are
                        hosted in
                        Kenya. If you access the Services from any other region of the world with laws or other requirements
                        governing personal data collection, use, or disclosure that differ from applicable laws in Kenya,
                        then
                        through your continued use of the Services, you are transferring your data to Kenya, and you
                        expressly
                        consent to have your data transferred to and processed in Kenya.
                    </p>
                </div>
                <div class="title mt-10" ref="section-12">
                    <b>12. TERM AND TERMINATION</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT LIMITING
                        ANY
                        OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
                        NOTICE OR
                        LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY
                        PERSON
                        FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
                        WARRANTY, OR
                        COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE
                        YOUR USE OR
                        PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED
                        AT ANY
                        TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
                    </p>
                    <p class="body-2 mt-5">
                        If we terminate or suspend your account for any reason, you are prohibited from registering and
                        creating a
                        new account under your name, a fake or borrowed name, or the name of any third party, even if you
                        may be
                        acting on behalf of the third party. In addition to terminating or suspending your account, we
                        reserve the
                        right to take appropriate legal action, including without limitation pursuing civil, criminal, and
                        injunctive redress.
                    </p>
                </div>
                <div class="title mt-10" ref="section-13">
                    <b>13. MODIFICATIONS AND INTERRUPTIONS</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        We reserve the right to change, modify, or remove the contents of the Services at any time or for
                        any reason
                        at our sole discretion without notice. However, we have no obligation to update any information on
                        our
                        Services. We will not be liable to you or any third party for any modification, price change,
                        suspension, or
                        discontinuance of the Services.
                    </p>
                    <p class="body-2 mt-5">
                        We cannot guarantee the Services will be available at all times. We may experience hardware,
                        software, or
                        other problems or need to perform maintenance related to the Services, resulting in interruptions,
                        delays,
                        or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify
                        the
                        Services at any time or for any reason without notice to you. You agree that we have no liability
                        whatsoever
                        for any loss, damage, or inconvenience caused by your inability to access or use the Services during
                        any
                        downtime or discontinuance of the Services. Nothing in these Legal Terms will be construed to
                        obligate us to
                        maintain and support the Services or to supply any corrections, updates, or releases in connection
                        therewith.
                    </p>
                </div>
                <div class="title mt-10" ref="section-14">
                    <b>14. GOVERNING LAW</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        These Legal Terms shall be governed by and defined following the laws of Kenya. Kenya National
                        Innovation
                        Agency and yourself irrevocably consent that the courts of Kenya shall have exclusive jurisdiction
                        to
                        resolve any dispute which may arise in connection with these Legal Terms.
                    </p>
                </div>
                <div class="title mt-10" ref="section-15">
                    <b>15. DISPUTE RESOLUTION</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        <b>Binding Arbitration</b>
                    </p>
                    <p class="body-2 mt-5">
                        Any dispute arising out of or in connection with these Legal Terms, including any question regarding
                        its
                        existence, validity, or termination, shall be referred to and finally resolved by the International
                        Commercial Arbitration Court under the European Arbitration Chamber (Belgium, Brussels, Avenue
                        Louise, 146)
                        according to the Rules of this ICAC, which, as a result of referring to it, is considered as the
                        part of
                        this clause. The number of arbitrators shall be __________. The seat, or legal place, or arbitration
                        shall
                        be Nairobi, Kenya. The language of the proceedings shall be English. The governing law of these
                        Legal Terms
                        shall be substantive law of Kenya.
                    </p>
                    <p class="body-2 mt-5">
                        <b>Restrictions</b>
                    </p>
                    <p class="body-2 mt-5">
                        The Parties agree that any arbitration shall be limited to the Dispute between the Parties
                        individually. To
                        the full extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b)
                        there is
                        no right or authority for any Dispute to be arbitrated on a class-action basis or to utilise class
                        action
                        procedures; and (c) there is no right or authority for any Dispute to be brought in a purported
                        representative capacity on behalf of the general public or any other persons.
                    </p>
                    <p class="body-2 mt-5">
                        <b>Exceptions to Arbitration</b>
                    </p>
                    <p class="body-2 mt-5">
                        The Parties agree that the following Disputes are not subject to the above provisions concerning
                        binding
                        arbitration: (a) any Disputes seeking to enforce or protect, or concerning the validity of, any of
                        the
                        intellectual property rights of a Party; (b) any Dispute related to, or arising from, allegations of
                        theft,
                        piracy, invasion of privacy, or unauthorised use; and (c) any claim for injunctive relief. If this
                        provision
                        is found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute
                        falling
                        within that portion of this provision found to be illegal or unenforceable and such Dispute shall be
                        decided
                        by a court of competent jurisdiction within the courts listed for jurisdiction above, and the
                        Parties agree
                        to submit to the personal jurisdiction of that court.
                    </p>
                </div>
                <div class="title mt-10" ref="section-16">
                    <b>16. CORRECTIONS</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        There may be information on the Services that contains typographical errors, inaccuracies, or
                        omissions,
                        including descriptions, pricing, availability, and various other information. We reserve the right
                        to
                        correct any errors, inaccuracies, or omissions and to change or update the information on the
                        Services at
                        any time, without prior notice.
                    </p>
                </div>
                <div class="title mt-10" ref="section-17">
                    <b>17. DISCLAIMER</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE
                        SERVICES WILL
                        BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR
                        IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE
                        IMPLIED
                        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO
                        WARRANTIES
                        OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY
                        WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR
                        RESPONSIBILITY FOR
                        ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY
                        DAMAGE,
                        OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY
                        UNAUTHORISED ACCESS
                        TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
                        STORED
                        THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS,
                        VIRUSES,
                        TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY,
                        AND/OR
                        (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND
                        INCURRED AS A
                        RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES.
                        WE DO
                        NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
                        OFFERED
                        BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION
                        FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
                        RESPONSIBLE FOR
                        MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS
                        WITH THE
                        PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
                        JUDGEMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
                    </p>
                </div>
                <div class="title mt-10" ref="section-18">
                    <b>18. LIMITATIONS OF LIABILITY</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR
                        ANY
                        DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
                        PROFIT,
                        LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE
                        BEEN
                        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
                        HEREIN, OUR
                        LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL
                        TIMES BE
                        LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO
                        NOT ALLOW
                        LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS
                        APPLY TO
                        YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE
                        ADDITIONAL
                        RIGHTS.
                    </p>
                </div>
                <div class="title mt-10" ref="section-19">
                    <b>19. INDEMNIFICATION</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and
                        all of our
                        respective officers, agents, partners, and employees, from and against any loss, damage, liability,
                        claim,
                        or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or
                        arising out
                        of: (1) your Contributions; (2) use of the Services; (3) breach of these Legal Terms; (4) any breach
                        of your
                        representations and warranties set forth in these Legal Terms; (5) your violation of the rights of a
                        third
                        party, including but not limited to intellectual property rights; or (6) any overt harmful act
                        toward any
                        other user of the Services with whom you connected via the Services. Notwithstanding the foregoing,
                        we
                        reserve the right, at your expense, to assume the exclusive defence and control of any matter for
                        which you
                        are required to indemnify us, and you agree to cooperate, at your expense, with our defence of such
                        claims.
                        We will use reasonable efforts to notify you of any such claim, action, or proceeding which is
                        subject to
                        this indemnification upon becoming aware of it.
                    </p>
                </div>
                <div class="title mt-10" ref="section-20">
                    <b>20. USER DATA</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        We will maintain certain data that you transmit to the Services for the purpose of managing the
                        performance
                        of the Services, as well as data relating to your use of the Services. Although we perform regular
                        routine
                        backups of data, you are solely responsible for all data that you transmit or that relates to any
                        activity
                        you have undertaken using the Services. You agree that we shall have no liability to you for any
                        loss or
                        corruption of any such data, and you hereby waive any right of action against us arising from any
                        such loss
                        or corruption of such data.
                    </p>
                </div>
                <div class="title mt-10" ref="section-21">
                    <b>21. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        Visiting the Services, sending us emails, and completing online forms constitute electronic
                        communications.
                        You consent to receive electronic communications, and you agree that all agreements, notices,
                        disclosures,
                        and other communications we provide to you electronically, via email and on the Services, satisfy
                        any legal
                        requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC
                        SIGNATURES,
                        CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS
                        OF
                        TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or
                        requirements
                        under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require
                        an
                        original signature or delivery or retention of non-electronic records, or to payments or the
                        granting of
                        credits by any means other than electronic means.
                    </p>
                </div>
                <div class="title mt-10" ref="section-22">
                    <b>22. MISCELLANEOUS</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        These Legal Terms and any policies or operating rules posted by us on the Services or in respect to
                        the
                        Services constitute the entire agreement and understanding between you and us. Our failure to
                        exercise or
                        enforce any right or provision of these Legal Terms shall not operate as a waiver of such right or
                        provision. These Legal Terms operate to the fullest extent permissible by law. We may assign any or
                        all of
                        our rights and obligations to others at any time. We shall not be responsible or liable for any
                    loss,
                    damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision
                    or part
                    of a provision of these Legal Terms is determined to be unlawful, void, or unenforceable, that
                    provision or
                    part of the provision is deemed severable from these Legal Terms and does not affect the validity
                    and
                    enforceability of any remaining provisions. There is no joint venture, partnership, employment or
                    agency
                    relationship created between you and us as a result of these Legal Terms or use of the Services. You
                    agree
                    that these Legal Terms will not be construed against us by virtue of having drafted them. You hereby
                    waive
                    any and all defences you may have based on the electronic form of these Legal Terms and the lack of
                    signing
                    by the parties hereto to execute these Legal Terms.
                </p>
            </div>
            <div class="title mt-10" ref="section-23">
                <b>23. CONTACT US</b>
            </div>
            <div>
                <p class="body-2 mt-5">
                    In order to resolve a complaint regarding the Services or to receive further information regarding
                    use of
                    the Services, please contact us at:
                </p>
                <p class="body-2 mt-5">
                    <div class="body-2 font-weight-black">
                        Kenya National Innovation Agency
                    </div>
                    <div class="body-2 font-weight-black">
                        Ground Floor, NACOSTI Building
                    </div>
                    <div class="body-2 font-weight-black">
                        P.O. Box 22269-00100
                    </div>
                    <div class="body-2 font-weight-black">
                        Nairobi, Kenya
                    </div>
                    <div class="body-2 font-weight-black">
                        Phone: +254-792 446 976
                    </div>
                    <div class="body-2 font-weight-black">
                        info@innovationagency.go.ke
                    </div>
                </p>
                <p class="body-2 mt-5">
                    These terms of use were created using Termly's Terms and Conditions Generator.
                </p>
            </div>
        </v-card>
    </v-container>
</div></template>
<script>
export default {
    methods: {
        scrollMeTo(refName) {
            var element = this.$refs[refName];
            var top = element.offsetTop;

            window.scrollTo(0, top);
        }
    }
}
</script>