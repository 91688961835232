<template>
  <div class="openEvents" v-if="$can('event_view')">
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="imageDialog"
      >
        <v-card max-width="600">
          <v-toolbar flat>
            Upload Logo
            <v-spacer></v-spacer>
            <v-btn icon @click="imageDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
  
          <v-form ref="imageForm" v-model="imageValid" lazy-validation>
            <v-layout column>
              <v-flex xs12>
                <v-file-input
                  class="mx-2 mt-3"
                  :loading="imageLoading"
                  :rules="fileRules"
                  filled
                  dense
                  show-size
                  chips
                  accept="image/*"
                  v-model="imageFile"
                  label="Select Image"
                ></v-file-input>
              </v-flex>
              <v-flex xs12>
                <div class="mx-3 mb-5">
                  <v-btn
                    depressed
                    block
                    class="primary text-none"
                    @click="uploadImage"
                    :loading="imageLoading"
                    :disabled="!imageValid"
                  >
                    Submit <v-icon right dark>mdi-upload</v-icon>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="coverImageDialog"
      >
        <v-card max-width="600">
          <v-toolbar flat>
            Upload Cover Photo
            <v-spacer></v-spacer>
            <v-btn icon @click="coverImageDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
  
          <v-form ref="imageForm" v-model="imageValid" lazy-validation>
            <v-layout column>
              <v-flex xs12>
                <v-file-input
                  class="mx-2 mt-3"
                  :loading="imageLoading"
                  :rules="fileRules"
                  filled
                  dense
                  show-size
                  chips
                  accept="image/*"
                  v-model="imageFile"
                  label="Select Image"
                ></v-file-input>
              </v-flex>
              <v-flex xs12>
                <div class="mx-3 mb-5">
                  <v-btn
                    depressed
                    block
                    class="primary text-none"
                    @click="uploadCoverImage"
                    :loading="imageLoading"
                    :disabled="!imageValid"
                  >
                    Submit <v-icon right dark>mdi-upload</v-icon>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card>
      </v-dialog>
    <v-container>
      <v-card outlined class="mt-5" v-if="view == 'open' || view == 'closed'">
        <v-tabs show-arrows v-model="viewTab">
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab @click="changeView('open')">
            <div class="text-none text--text">Open Events ({{ openEventPagination.total }})</div>
          </v-tab>
          <v-tab @click="changeView('closed')">
            <div class="text-none text--text">Closed Events ({{ closedEventPagination.total }})</div>
          </v-tab>

        </v-tabs>
      </v-card>

      <div v-if="view == 'open'">
        <v-layout column>
          <v-card outlined class="mt-2">
            <v-progress-linear v-if="openEventLoader" height="1" indeterminate color="primary"></v-progress-linear>
            <v-flex xs12 class="mt-10 mb-2">
              <div class="mx-5">
                <v-layout row wrap>
                  <v-flex xs12 md4>
                    <h1> </h1>
                  </v-flex>
                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-text-field class="text_field header" outlined dense label="Search"
                        append-icon="mdi-undo-variant" @click:append="resetOpenEventSearch()"
                        v-on:keyup.enter="searchOpenEvent" v-model="openEventSearchTerm"
                        @input="enableOpenEventSearch()"></v-text-field>
                    </div>
                  </v-flex>
                  <v-flex xs12 md2>
                    <div class="pa-1">
                      <v-btn class="secondary primary--text text-none" depressed block @click="searchOpenEvent"
                        :loading="searchOpenEventLoader" :disabled="openEventSearchButtonDisabled">
                        Search
                        <v-icon right>mdi-magnify</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs12 md2>
                    <div class="pa-1">
                      <v-btn block depressed class="primary text-none" @click="changeView('create')"
                        v-if="$can('event_create')">
                        Add Event
                        <v-icon right>mdi-plus-circle-outline</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
            <v-divider class="mt-9 mx-5"></v-divider>
            <v-flex xs12 class="mt-5">
              <div v-if="openEvents.length == 0">
                <v-card elevation="0">
                  <v-layout row wrap>
                    <v-flex xs12 md1>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center">
                            <v-icon large class="text--text">
                              mdi-alert-circle-outline
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                    <v-flex xs12 md11>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center"> No Open Events Found </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                  </v-layout>
                </v-card>
              </div>
              <div v-else>
                <div class="hidden-sm-and-down">
                  <v-card elevation="0" class="pa-2">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead class="header">
                          <tr class="tableHeader">
                            <th class="text-left text--text">Title</th>
                            <th class="text-left text--text">Owner(s)</th>
                            <th class="text-left text--text">Event Type</th>
                            <th class="text-left text--text">Start</th>
                            <th class="text-left text--text">End</th>
                            <th class="text-left text--text">Status</th>
                            <th class="text-left text--text">Creation Date</th>
                            <th class="text-right text--text">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in openEvents" :key="item.id">
                            <td>
                              {{ item.title }}
                            </td>
                            <td>
                              <div v-if="item.institution_events.length != 0">
                                <div v-for="item in item.institution_events" :key="item.id">
                                  {{ item.institution.name }}
                                </div>
                              </div>
                              <div v-if="item.organisation_events.length != 0">
                                <div v-for="item in item.organisation_events" :key="item.id">
                                  {{ item.organisation.name }}
                                </div>
                              </div>
                            </td>
                            <td>
                              {{ item.event_type.name }}
                            </td>
                            <td>
                              {{ item.start_time | moment("DD/MM/YYYY - hh:mm a") }}
                            </td>
                            <td>
                              {{ item.end_time | moment("DD/MM/YYYY - hh:mm a") }}
                            </td>

                            <td>
                              <div v-if="item.status == 1">Active</div>
                              <div v-else>Inactive</div>
                            </td>
                            <td>
                              {{
                                item.created_at | moment("DD/MM/YYYY - hh:mm a")
                              }}
                            </td>
                            <td>
                              <div align="right">
                                <v-tooltip top v-if="$can('event_view')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 universal--text" @click="showEvent(item)">
                                      <v-icon small> mdi-eye </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>View Event</span>
                                </v-tooltip>
                                <v-tooltip top v-if="$can('event_edit')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 success--text" @click="editEvent(item)" v-bind="attrs"
                                      v-on="on">
                                      <v-icon small> mdi-pencil </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>edit</span>
                                </v-tooltip>

                                <v-tooltip top v-if="$can('event_archive')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 error--text" @click="deleteEvent(item)"
                                      :loading="loading && eventIndex == item.id" v-bind="attrs" v-on="on">
                                      <v-icon small> mdi-delete </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>delete</span>
                                </v-tooltip>

                                <v-tooltip top v-if="
                                  $can('event_activate') && item.status == 0
                                ">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 red--text" @click="activateEvent(item)" :loading="
                                      activateLoading && eventIndex == item.id
                                    " v-bind="attrs" v-on="on">
                                      <v-icon small>
                                        mdi-toggle-switch-off-outline
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Activate Event</span>
                                </v-tooltip>
                                <v-tooltip top v-if="
                                  $can('event_deactivate') && item.status == 1
                                ">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 green--text" @click="deactivateEvent(item)" :loading="
                                      deactivateLoading &&
                                      eventIndex == item.id
                                    " v-bind="attrs" v-on="on">
                                      <v-icon small> mdi-toggle-switch </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Deactivate Event</span>
                                </v-tooltip>
                                <v-tooltip top v-if="$can('event_broadcast')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 purple--text" @click="broadcastEvent(item)"
                                      :loading="broadcastLoading && eventIndex == item.id" v-bind="attrs" v-on="on">
                                      <v-icon small> mdi-bullhorn-variant </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Broadcast</span>
                                </v-tooltip>
                                <v-tooltip top v-if="$can('log_view')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      class="button mr-1 purple--text"
                                      @click="viewLog(item)"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small> mdi-script-text </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Log</span>
                                </v-tooltip>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </div>
                <div class="hidden-md-and-up">
                  <v-layout column>
                    <template v-for="(event, index) in openEvents">
                      <div :key="index">
                        <v-flex xs12 class="mb-2">
                          <v-card elevation="0">
                            <div class="pa-5">
                              <v-layout column>
                                <v-flex xs12>
                                  <v-layout row wrap>
                                    <v-flex xs11>
                                      <div class="title title--text ml-3">
                                        <b>{{ event.title }}</b>
                                      </div>
                                    </v-flex>
                                    <v-flex xs1>
                                      <v-btn depressed class="red--text" :loading="
                                        loading && eventIndex == event.id
                                      " icon @click="deleteEvent(event)" v-if="$can('event_archive')">
                                        <v-icon> mdi-delete </v-icon>
                                      </v-btn>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-divider class="mt-2"></v-divider>
                                <v-flex xs12 class="mt-1">
                                  <v-layout column>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Owner(s)</b>
                                      </div>
                                      <div v-if="event.institution_events.length != 0">
                                        <div v-for="event in event.institution_events" :key="event.id">
                                          {{ event.institution.name }}
                                        </div>
                                      </div>
                                      <div v-if="event.organisation_events.length != 0">
                                        <div v-for="event in event.organisation_events" :key="event.id">
                                          {{ event.organisation.name }}
                                        </div>
                                      </div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Event Type</b>
                                      </div>
                                      <div>
                                        {{ event.event_type.name }}
                                      </div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Start</b>
                                      </div>
                                      <div>
                                        {{ event.start_time | moment("DD/MM/YYYY - hh:mm a") }}
                                      </div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>End</b>
                                      </div>
                                      <div>
                                        {{ event.end_time | moment("DD/MM/YYYY - hh:mm a") }}
                                      </div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Status</b>
                                      </div>
                                      <div>
                                        <div v-if="event.status == 1">Active</div>
                                        <div v-else>Inactive</div>
                                      </div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Creation Date</b>
                                      </div>
                                      <div>
                                        {{
                                          event.created_at | moment("DD/MM/YYYY - hh:mm a")
                                        }}
                                      </div>
                                    </v-flex>

                                    <v-flex xs12 class="mt-5">
                                      <v-layout row wrap>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn
                                              depressed
                                              class="
                                                primary
                                                text-none
                                                white--text
                                              "
                                              block
                                              @click="showEvent(event)"
                                              >View
                                              <v-icon right> mdi-eye </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn depressed class="blue text-none white--text" block
                                              @click="editEvent(event)" v-if="$can('event_edit')">Edit
                                              <v-icon right>
                                                mdi-border-color
                                              </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn depressed class="green text-none white--text" block
                                              @click="activateEvent(event)" v-if="$can('event_activate') && event.status == 0" :loading="
                                                activateLoading && eventIndex == event.id
                                              ">Activate Event
                                              <v-icon right>
                                                mdi-toggle-switch-off-outline
                                              </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn depressed class="red text-none white--text" block
                                              @click="deactivateEvent(event)" v-if="$can('event_deactivate') && event.status == 1" :loading="
                                                deactivateLoading && eventIndex == event.id
                                              ">Deactivate Event
                                              <v-icon right>
                                                mdi-toggle-switch
                                              </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn
                                              v-if="$can('log_view')" 
                                              block
                                              depressed
                                              class="purple text-none white--text"
                                              @click="viewLog(event)"
                                              v-bind="attrs"
                                              v-on="on"
                                            > Log
                                              <v-icon small> mdi-script-text </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </div>
                          </v-card>
                        </v-flex>
                      </div>
                    </template>
                  </v-layout>
                </div>
              </div>
            </v-flex>
          </v-card>

          <v-layout column class="mt-5">
            <v-flex xs12>
              <div align="center">
                <v-pagination v-if="openEventLength != 0" :length="openEventLength" total-visible="10"
                  v-model="openEventPagination.current_page" @input="changeOpenEventPage()" circle>
                </v-pagination>
              </div>
            </v-flex>
            <v-flex xs12>
              <div v-if="length != 0" align="center" class="mt-5">
                <b class="text--text">Total: </b>{{ openEventPagination.total | formatNumber }}
              </div>
            </v-flex>
          </v-layout>



        </v-layout>
      </div>
      <div v-if="view == 'closed'">
        <v-layout column>
          <v-card outlined class="mt-2">
            <v-progress-linear v-if="closedEventLoader" height="1" indeterminate color="primary"></v-progress-linear>
            <v-flex xs12 class="mt-10 mb-2">
              <div class="mx-5">
                <v-layout row wrap>
                  <v-flex xs12 md4>
                    <h1> </h1>
                  </v-flex>
                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-text-field class="text_field header" outlined dense label="Search"
                        append-icon="mdi-undo-variant" @click:append="resetClosedEventSearch()"
                        v-on:keyup.enter="searchClosedEvent" v-model="closedEventSearchTerm"
                        @input="enableClosedEventSearch()"></v-text-field>
                    </div>
                  </v-flex>
                  <v-flex xs12 md2>
                    <div class="pa-1">
                      <v-btn class="secondary primary--text text-none" depressed block @click="searchClosedEvent"
                        :loading="searchClosedEventLoader" :disabled="closedEventSearchButtonDisabled">
                        Search
                        <v-icon right>mdi-magnify</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs12 md2>
                    <div class="pa-1">
                      <v-btn block depressed class="primary text-none" @click="changeView('create')"
                        v-if="$can('event_create')">
                        Add Event
                        <v-icon right>mdi-plus-circle-outline</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
            <v-divider class="mt-9 mx-5"></v-divider>
            <v-flex xs12 class="mt-5">
              <div v-if="closedEvents.length == 0">
                <v-card elevation="0">
                  <v-layout row wrap>
                    <v-flex xs12 md1>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center">
                            <v-icon large class="text--text">
                              mdi-alert-circle-outline
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                    <v-flex xs12 md11>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center"> No Closed Events Found </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                  </v-layout>
                </v-card>
              </div>
              <div v-else>
                <div class="hidden-sm-and-down">
                  <v-card elevation="0" class="pa-2">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead class="header">
                          <tr class="tableHeader">
                            <th class="text-left text--text">Title</th>
                            <th class="text-left text--text">Owner(s)</th>
                            <th class="text-left text--text">Event Type</th>
                            <th class="text-left text--text">Start</th>
                            <th class="text-left text--text">End</th>
                            <th class="text-left text--text">Status</th>
                            <th class="text-left text--text">Creation Date</th>
                            <th class="text-right text--text">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in closedEvents" :key="item.id">
                            <td>
                              {{ item.title }}
                            </td>
                            <td>
                              <div v-if="item.institution_events.length != 0">
                                <div v-for="item in item.institution_events" :key="item.id">
                                  {{ item.institution.name }}
                                </div>
                              </div>
                              <div v-if="item.organisation_events.length != 0">
                                <div v-for="item in item.organisation_events" :key="item.id">
                                  {{ item.organisation.name }}
                                </div>
                              </div>
                            </td>
                            <td>
                              {{ item.event_type.name }}
                            </td>
                            <td>
                              {{ item.start_time | moment("DD/MM/YYYY - hh:mm a") }}
                            </td>
                            <td>
                              {{ item.end_time | moment("DD/MM/YYYY - hh:mm a") }}
                            </td>

                            <td>
                              <div v-if="item.status == 1">Active</div>
                              <div v-else>Inactive</div>
                            </td>
                            <td>
                              {{
                                item.created_at | moment("DD/MM/YYYY - hh:mm a")
                              }}
                            </td>
                            <td>
                              <div align="right">
                                <v-tooltip top v-if="$can('event_view')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 universal--text" @click="showEvent(item)">
                                      <v-icon small> mdi-eye </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>View Event</span>
                                </v-tooltip>
                                <v-tooltip top v-if="$can('event_edit')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 success--text" @click="editEvent(item)" v-bind="attrs"
                                      v-on="on">
                                      <v-icon small> mdi-pencil </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>edit</span>
                                </v-tooltip>

                                <v-tooltip top v-if="$can('event_archive')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 error--text" @click="deleteEvent(item)"
                                      :loading="loading && eventIndex == item.id" v-bind="attrs" v-on="on">
                                      <v-icon small> mdi-delete </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>delete</span>
                                </v-tooltip>

                                <v-tooltip top v-if="
                                  $can('event_activate') && item.status == 0
                                ">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 red--text" @click="activateEvent(item)" :loading="
                                      activateLoading && eventIndex == item.id
                                    " v-bind="attrs" v-on="on">
                                      <v-icon small>
                                        mdi-toggle-switch-off-outline
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Activate Event</span>
                                </v-tooltip>
                                <v-tooltip top v-if="
                                  $can('event_deactivate') && item.status == 1
                                ">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 green--text" @click="deactivateEvent(item)" :loading="
                                      deactivateLoading &&
                                      eventIndex == item.id
                                    " v-bind="attrs" v-on="on">
                                      <v-icon small> mdi-toggle-switch </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Deactivate Event</span>
                                </v-tooltip>
                                <v-tooltip top v-if="$can('log_view')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      class="button mr-1 purple--text"
                                      @click="viewLog(item)"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small> mdi-script-text </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Log</span>
                                </v-tooltip>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </div>
                <div class="hidden-md-and-up">
                  <v-layout column>
                    <template v-for="(event, index) in closedEvents">
                      <div :key="index">
                        <v-flex xs12 class="mb-2">
                          <v-card elevation="0">
                            <div class="pa-5">
                              <v-layout column>
                                <v-flex xs12>
                                  <v-layout row wrap>
                                    <v-flex xs11>
                                      <div class="title title--text ml-3">
                                        <b>{{ event.title }}</b>
                                      </div>
                                    </v-flex>
                                    <v-flex xs1>
                                      <v-btn depressed class="red--text" :loading="
                                        loading && eventIndex == event.id
                                      " icon @click="deleteEvent(event)" v-if="$can('event_archive')">
                                        <v-icon> mdi-delete </v-icon>
                                      </v-btn>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-divider class="mt-2"></v-divider>
                                <v-flex xs12 class="mt-1">
                                  <v-layout column>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Owner(s)</b>
                                      </div>
                                      <div v-if="event.institution_events.length != 0">
                                        <div v-for="event in event.institution_events" :key="event.id">
                                          {{ event.institution.name }}
                                        </div>
                                      </div>
                                      <div v-if="event.organisation_events.length != 0">
                                        <div v-for="event in event.organisation_events" :key="event.id">
                                          {{ event.organisation.name }}
                                        </div>
                                      </div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Event Type</b>
                                      </div>
                                      <div>
                                        {{ event.event_type.name }}
                                      </div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Start</b>
                                      </div>
                                      <div>
                                        {{ event.start_time | moment("DD/MM/YYYY - hh:mm a") }}
                                      </div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>End</b>
                                      </div>
                                      <div>
                                        {{ event.end_time | moment("DD/MM/YYYY - hh:mm a") }}
                                      </div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Status</b>
                                      </div>
                                      <div>
                                        <div v-if="event.status == 1">Active</div>
                                        <div v-else>Inactive</div>
                                      </div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Creation Date</b>
                                      </div>
                                      <div>
                                        {{
                                          event.created_at | moment("DD/MM/YYYY - hh:mm a")
                                        }}
                                      </div>
                                    </v-flex>

                                    <v-flex xs12 class="mt-5">
                                      <v-layout row wrap>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn
                                              depressed
                                              class="
                                                primary
                                                text-none
                                                white--text
                                              "
                                              block
                                              @click="showEvent(event)"
                                              >View
                                              <v-icon right> mdi-eye </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn depressed class="blue text-none white--text" block
                                              @click="editEvent(event)" v-if="$can('event_edit')">Edit
                                              <v-icon right>
                                                mdi-border-color
                                              </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn depressed class="green text-none white--text" block
                                              @click="activateEvent(event)" v-if="$can('event_activate') && event.status == 0" :loading="
                                                activateLoading && eventIndex == event.id
                                              ">Activate Event
                                              <v-icon right>
                                                mdi-toggle-switch-off-outline
                                              </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn depressed class="red text-none white--text" block
                                              @click="deactivateEvent(event)" v-if="$can('event_deactivate') && event.status == 1" :loading="
                                                deactivateLoading && eventIndex == event.id
                                              ">Deactivate Event
                                              <v-icon right>
                                                mdi-toggle-switch
                                              </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn
                                              v-if="$can('log_view')" 
                                              depressed
                                              block
                                              class="purple text-none white--text"
                                              @click="viewLog(event)"
                                              v-bind="attrs"
                                              v-on="on"
                                            > Log
                                              <v-icon small> mdi-script-text </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </div>
                          </v-card>
                        </v-flex>
                      </div>
                    </template>
                  </v-layout>
                </div>
              </div>
            </v-flex>
          </v-card>
          <v-layout column class="mt-5">
            <v-flex xs12>
              <div align="center">
                <v-pagination v-if="closedEventLength != 0" :length="closedEventLength" total-visible="10"
                  v-model="closedEventPagination.current_page" @input="changeClosedEventPage()" circle>
                </v-pagination>
              </div>
            </v-flex>
            <v-flex xs12>
              <div v-if="length != 0" align="center" class="mt-5">
                <b class="text--text">Total: </b>{{ closedEventPagination.total | formatNumber }}
              </div>
            </v-flex>
          </v-layout>
        </v-layout>
      </div>
      <div v-if="view == 'create'">
        <v-flex class="mt-5">
          <v-card outlined>
            <v-card-title class="tableHeader text--text">
              <v-spacer></v-spacer>
              <div v-if="newEvent.id == null">Add Event</div>
              <div v-else>Edit Event</div>
              <v-spacer></v-spacer>
              <v-btn icon @click="changeView('open')">
                <v-icon class="text--text"> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-layout column>
                  <v-flex xs12>
                    <v-layout row wrap class="mt-5">
                      <v-flex xs12>
                        <div class="pa-3">
                          <div>Title<span class="error--text">*</span></div>
                          <v-text-field class="text_field background" outlined dense v-model="newEvent.title"
                            :rules="inputRules"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div class="pa-3">
                          <div>Brief<span class="error--text">*</span></div>
                          <v-text-field class="text_field background" outlined dense v-model="newEvent.brief"
                            :rules="inputRules"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div class="pa-3">
                          <div>Event Type<span class="error--text">*</span></div>
                          <v-select outlined dense :items="formData.event_types" item-text="name" item-value="id" v-model="newEvent.event_type_id"
                            class="text_field background"></v-select>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div class="pa-3">
                          <div>
                            Description<span class="error--text">*</span>
                          </div>
                          <div class="black--text">
                            <ckeditor :editor="editor" v-model="newEvent.description" :config="editorConfig"
                              :rules="inputRules"></ckeditor>
                          </div>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-3">
                          <div>
                            Start Date<span class="error--text">*</span>
                          </div>
                          <v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
                            v-model="startDatePicker" offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field outlined dense :value="formatStartDate" slot="activator" readonly v-bind="attrs"
                                v-on="on" class="text_field background" append-icon="mdi-calendar"></v-text-field>
                            </template>
                            <v-date-picker v-model="newEvent.start_date" :min="dateToday" :max="newEvent.end_date" @change="closeStartDate">
                            </v-date-picker>
                          </v-menu>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-3">
                          <div>
                            Start Time<span class="error--text">*</span>
                          </div>
                          <v-menu ref="menu12" v-model="menu1" :close-on-content-click="false"
                            :return-value.sync="newEvent.start_time" transition="scale-transition" offset-y full-width>
                            <template v-slot:activator="{ on }">
                              <v-text-field v-model="newEvent.start_time" class="text_field background"
                                append-icon="mdi-clock-time-five-outline" readonly v-on="on" outlined dense
                                :rules="inputRules"></v-text-field>
                            </template>
                            <v-time-picker v-if="menu1" v-model="newEvent.start_time" :allowed-minutes="allowedStep"
                              full-width color="primary" @click:minute="
                                $refs.menu12.save(newEvent.start_time)
                              " format="24hr"></v-time-picker>
                          </v-menu>
                        </div>
                      </v-flex>

                      <v-flex xs12 md3>
                        <div class="pa-3">
                          <div>
                            End Date<span class="error--text">*</span>
                          </div>
                          <v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
                            v-model="endDatePicker" offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field outlined dense :value="formatEndDate" slot="activator" readonly v-bind="attrs"
                                v-on="on" class="text_field background" append-icon="mdi-calendar"></v-text-field>
                            </template>
                            <v-date-picker v-model="newEvent.end_date" :min="newEvent.start_date" @change="closeEndDate">
                            </v-date-picker>
                          </v-menu>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-3">
                          <div>
                            End Time<span class="error--text">*</span>
                          </div>
                          <v-menu ref="menu13" v-model="menu2" :close-on-content-click="false"
                            :return-value.sync="newEvent.end_time" transition="scale-transition" offset-y full-width>
                            <template v-slot:activator="{ on }">
                              <v-text-field v-model="newEvent.end_time" class="text_field background"
                                append-icon="mdi-clock-time-five-outline" readonly v-on="on" outlined dense
                                :rules="inputRules"></v-text-field>
                            </template>
                            <v-time-picker v-if="menu2" v-model="newEvent.end_time" :allowed-minutes="allowedStep"
                              full-width color="primary" @click:minute="
                                $refs.menu13.save(newEvent.end_time)
                              " format="24hr"></v-time-picker>
                          </v-menu>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-3">
                          <div>Entry Cost<span class="error--text">*</span></div>
                          <v-text-field class="text_field background" outlined dense v-model="newEvent.entry_cost"
                            :rules="inputRules" type="number" min="0"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-3">
                          <div>Location<span class="error--text">*</span></div>
                          <v-text-field class="text_field background" outlined dense v-model="newEvent.location"
                            :rules="inputRules"></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-3">
                          <div>Longitude</div>
                          <v-text-field class="text_field background" outlined dense v-model="newEvent.longitude"
                          ></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md3>
                        <div class="pa-3">
                          <div>Latitude</div>
                          <v-text-field class="text_field background" outlined dense v-model="newEvent.latitude"
                          ></v-text-field>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-card-title class="tableHeader text--text mt-10">
                    <div align="center">
                      Event Owner
                    </div>
                  </v-card-title>
                  <v-flex xs12 class="mt-5 mx-1">
                    <v-tabs show-arrows v-model="viewTab">
                      <v-tabs-slider color="primary"></v-tabs-slider>
                      <v-tab @click="changeEventOwnerTab('organisation')">
                        <div class="text-none">Organisation ({{ formData.organisations.length }})</div>
                      </v-tab>
                      <v-tab @click="changeEventOwnerTab('institution')">
                        <div class="text-none">Institution ({{ formData.institutions.length }})</div>
                      </v-tab>
                    </v-tabs>
                    <div class="mt-5" v-if="activeEventOwnerTab == 'organisation'">
                      <v-combobox v-model="newEvent.organisations" :items="formData.organisations" item-text="name"
                        item-value="id" chips clearable multiple prepend-inner-icon="mdi-office-building-outline"
                        outlined class="login_field mx-3 mb-10">
                        <template v-slot:selection="{ attrs, item, select, selected }">
                          <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                            @click:close="removeOrganisation(item)">
                            <strong>{{ item.name }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </div>
                    <div class="mt-5" v-if="activeEventOwnerTab == 'institution'">
                      <v-combobox v-model="newEvent.institutions" :items="formData.institutions" item-text="name"
                        item-value="id" chips clearable multiple prepend-inner-icon="mdi-office-building-outline"
                        outlined class="login_field mx-3 mb-10">
                        <template v-slot:selection="{ attrs, item, select, selected }">
                          <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                            @click:close="removeInstitution(item)">
                            <strong>{{ item.name }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </div>
                  </v-flex>
                  <v-card-title class="tableHeader text--text mt-10">
                    <div align="centre">
                      Interest(s)
                    </div>
                  </v-card-title>
                  <v-flex xs12 class="mt-5 mx-3">
                    <v-layout row wrap>
                      <template v-for="(interest, index) in formData.interests">
                        <v-flex xs12 md3 :key="index">
                          <v-checkbox v-model="newEvent.interests" :value="interest.id"
                            :label="interest.name"></v-checkbox>
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-flex>
                  <v-card-title class="tableHeader text--text mt-10">
                    <div align="center">
                      Sector(s)
                    </div>
                  </v-card-title>
                  <v-flex xs12 class="mt-5 mx-3">
                    <v-layout row wrap>
                      <template v-for="(sector, index) in formData.sectors">
                        <v-flex xs12 md3 :key="index">
                          <v-checkbox v-model="newEvent.sectors" :value="sector.id" :label="sector.name"></v-checkbox>
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-flex>
                  <v-card-title class="tableHeader text--text mt-10">
                    <div align="center">
                      SDG(s)
                    </div>
                  </v-card-title>
                  <v-flex xs12 class="mt-5 mx-3">
                    <v-layout row wrap>
                      <template v-for="(sdg, index) in formData.sdgs">
                        <v-flex xs12 md3 :key="index">
                          <v-checkbox v-model="newEvent.sdgs" :value="sdg.id" :label="sdg.name"></v-checkbox>
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed class="primary text-none mt-3" @click="save" :disabled="!valid" :loading="loading">
                Save
                <v-icon right> mdi-content-save </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </div>
      <div v-if="view == 'show'">
        <v-card outlined class="mt-2">
          <v-card-title class="tableHeader text--text">
            {{ assignedEvent.title }}
            <v-spacer></v-spacer>
            <v-btn icon @click="changeView('open')">
              <v-icon class="text--text"> mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="pa-3">
              <v-tabs show-arrows v-model="eventTab">
                <v-tabs-slider color="primary"></v-tabs-slider>

                <v-tab @click="activeTab = 'details'">
                  <div class="text-none text--text">Details</div>
                </v-tab>
                <v-tab @click="activeTab = 'eventResponses'" v-if="$can('event_reponse_view')">
                  <div class="text-none text--text">
                    Event Reponses ({{eventResponsePagination.total}})
                  </div>
                </v-tab>
              </v-tabs>
              <div v-if="activeTab == 'details'" class="mx-3 mt-7">
                <v-layout row wrap>
                        <v-flex xs12 md3>
                          <div class="mr-8">
                            <v-img v-if="assignedEvent.cover_photo == null" src="img/innovation.jpg" height="200px" style="z-index: 2;border-radius: 25px;">
                              <div align="right" class="mt-2 mr-2">
                                <v-btn
                                  class="white"
                                  icon
                                  small
                                  @click="coverImageDialog = true"
                                  v-if="$can('institution_image_upload')"
                                  ><v-icon class="primary--text" small>mdi-pen</v-icon></v-btn
                                >
                              </div>
                              
                              <v-avatar
                                size="86"
                                style="position: absolute; top: 110px; left: 3px; z-index: 10"
                              >
                                <v-img
                                  v-if="assignedEvent.logo==null"
                                  src="img/innovation.jpg"
                                >
                                </v-img>
                                <v-img
                                  v-else
                                  :src="path+'/storage/event_pics/'+assignedEvent.logo"
                                >
                                </v-img>
                              </v-avatar>
                            </v-img>
                            <v-img v-else :src="path+'/storage/event_pics/'+assignedEvent.cover_photo" height="200px" style="z-index: 2;border-radius: 25px;">
                              <div align="right" class="mt-2 mr-2">
                                <v-btn
                                  class="white"
                                  icon
                                  small
                                  @click="coverImageDialog = true"
                                  v-if="$can('institution_image_upload')"
                                  ><v-icon class="primary--text" small>mdi-pen</v-icon></v-btn
                                >
                              </div>
                              
                              <v-avatar
                                size="86"
                                style="position: absolute; top: 110px; left: 3px; z-index: 10"
                              >
                                <v-img
                                  v-if="assignedEvent.logo==null"
                                  src="img/innovation.jpg"
                                >
                                </v-img>
                                <v-img
                                  v-else
                                  :src="path+'/storage/event_pics/'+assignedEvent.logo"
                                >
                                </v-img>
                              </v-avatar>
                            </v-img>
                            
                          </div>
                          <div align="center" class="mt-5">
                            <v-btn class="primary text-none" depressed @click="imageDialog = true" v-if="$can('institution_image_upload')">
                              Upload Logo
                              <v-icon right>mdi-file-upload</v-icon>
                            </v-btn>
                          </div>
                          
                        </v-flex>
                        <v-flex xs12 md9>
                        <v-layout column>
                          <v-flex xs12 class="mt-5">
                            <v-layout row wrap>
                              <v-flex xs12 md2>
                                <div><b>Creation Date:</b></div>
                              </v-flex>
                              <v-flex xs12 md10>
                                {{
                                  assignedEvent.created_at
                                    | moment("DD/MM/YYYY - hh:mm a")
                                }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 class="mt-5">
                            <v-layout row wrap>
                              <v-flex xs12 md2>
                                <div><b>Start:</b></div>
                              </v-flex>
                              <v-flex xs12 md10>
                                {{
                                  assignedEvent.start_time
                                    | moment("DD/MM/YYYY - hh:mm a")
                                }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 class="mt-5">
                            <v-layout row wrap>
                              <v-flex xs12 md2>
                                <div><b>End:</b></div>
                              </v-flex>
                              <v-flex xs12 md10>
                                {{
                                  assignedEvent.end_time
                                    | moment("DD/MM/YYYY - hh:mm a")
                                }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 class="mt-5">
                            <v-layout row wrap>
                              <v-flex xs12 md2>
                                <div><b>Entry Cost:</b></div>
                              </v-flex>
                              <v-flex xs12 md10>
                                Kshs {{
                                  assignedEvent.entry_cost | formatNumber
                                }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 class="mt-5">
                            <v-layout row wrap>
                              <v-flex xs12 md2>
                                <div><b>Location:</b></div>
                              </v-flex>
                              <v-flex xs12 md10>
                                {{
                                  assignedEvent.location 
                                }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 class="mt-5">
                            <v-layout row wrap>
                              <v-flex xs12 md2>
                                <div><b>Status:</b></div>
                              </v-flex>
                              <v-flex xs12 md10>
                                <div v-if="assignedEvent.status == 1">Active</div>
                                <div v-else>Inactive</div>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 class="mt-12">
                            <v-layout row wrap>
                              <v-flex xs12 md2>
                                <div><b>Brief:</b></div>
                              </v-flex>
                              <v-flex xs12 md10>
                                {{ assignedEvent.brief }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 class="mt-6">
                            <v-layout row wrap>
                              <v-flex xs12 md2>
                                <b>Description: </b>
                              </v-flex>
                              <v-flex xs12 md10>
                                <p v-html="assignedEvent.description"></p>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                        </v-flex>
                        </v-layout>
              </div>
              <div v-if="activeTab == 'eventResponses'" class="mt-2">
                <div v-if="eventView == 'default'">
                    <v-card elevation="0" class="mt-5">
                      <v-flex xs12 class="mt-10 mb-2">
                        <div class="mx-5">
                          <v-layout row wrap>
                            <v-flex xs12 md4> </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <v-text-field
                                  class="text_field header"
                                  outlined
                                  dense
                                  label="Search"
                                  append-icon="mdi-undo-variant"
                                  @click:append="
                                    resetEventResponseSearch()
                                  "
                                  v-on:keyup.enter="
                                    eventResponseSearch
                                  "
                                  v-model="eventResponseSearchTerm"
                                  @input="enableInstitutionAffiliationSearch()"
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md2>
                              <div class="pa-1">
                                <v-btn
                                  class="secondary primary--text text-none"
                                  depressed
                                  block
                                  @click="eventResponseSearch"
                                  :loading="eventResponseSearchLoader"
                                  :disabled="
                                    eventResponseSearchButtonDisabled
                                  "
                                >
                                  Search
                                  <v-icon right>mdi-magnify</v-icon>
                                </v-btn>
                              </div>
                            </v-flex>
                            <v-flex xs12 md2>
                              <div class="pa-1">
                                
                              </div>
                            </v-flex>
                          </v-layout>
                        </div>
                      </v-flex>
                      <v-divider class="mt-9 mx-5"></v-divider>
                      <v-flex xs12 class="mt-5">
                        <div v-if="eventResponses.length == 0">
                          <v-card elevation="0">
                            <v-layout row wrap>
                              <v-flex xs12 md1>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      <v-icon large class="text--text">
                                        mdi-alert-circle-outline
                                      </v-icon>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                              <v-flex xs12 md11>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      No Responses Found
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </div>
                        <div v-else>
                          <div class="hidden-sm-and-down">
                            <v-card elevation="0" class="pa-2">
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead class="header">
                                    <tr class="secondary">
                                      <th class="text-left">Responder</th>
                                      <th class="text-left">Date Created</th>
                                      <th class="text-left">Last Update</th>
                                      <th class="text-right">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="item in eventResponses"
                                      :key="item.id"
                                    >
                                      <td>
                                        {{ item.user.f_name }} {{ item.user.m_name }} {{ item.user.l_name }}
                                      </td>
                                      <td>
                                        {{ item.created_at | moment("DD/MM/YYYY - hh:mm a")}}
                                      </td>
                                      <td>
                                        {{ item.updated_at | moment("DD/MM/YYYY - hh:mm a") }}
                                      </td>
                                      <td>
                                        <div align="right">
                                          <v-btn
                                            icon
                                            @click="
                                              showEventResponse(
                                                item
                                              )
                                            "
                                            class="button mr-1 universal--text"
                                          >
                                            <v-icon small> mdi-eye </v-icon>
                                          </v-btn>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card>
                          </div>
                          <div class="hidden-md-and-up">
                            <div>
                              <v-layout column>
                                <template
                                  v-for="(
                                    event, index
                                  ) in eventResponses"
                                >
                                  <div :key="index">
                                    <v-flex xs12 class="mb-2">
                                      <v-card elevation="0">
                                        <div class="pa-5">
                                          <v-layout column>
                                            <v-flex xs12>
                                              <v-layout row wrap>
                                                <v-flex xs12>
                                                  <div
                                                    class="title primary--text"
                                                  >
                                                    <b>{{ event.user.f_name }} {{ event.user.m_name }} {{ event.user.l_name }}</b>
                                                  </div>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-divider class="mt-2"></v-divider>
                                            <v-flex xs12 class="mt-1">
                                              <v-layout column>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Date Created</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{ event.created_at }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs4>
                                                      <b>Last Update</b>
                                                    </v-flex>
                                                    <v-flex xs8>
                                                      {{ event.updated_at }}
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                  <v-layout row wrap>
                                                    <v-flex xs12>
                                                      <div>
                                                        <v-btn
                                                          depressed
                                                          class="primary text-none white--text mt-3"
                                                          block
                                                          @click="
                                                            showEventResponse(
                                                              event
                                                            )
                                                          "
                                                          >View
                                                          <v-icon right>
                                                            mdi-eye
                                                          </v-icon>
                                                        </v-btn>
                                                      </div>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                          </v-layout>
                                        </div>
                                      </v-card>
                                    </v-flex>
                                  </div>
                                </template>
                              </v-layout>
                            </div>
                          </div>
                        </div>
                      </v-flex>
                    </v-card>
                    <v-layout column class="mt-5">
                      <v-flex xs12>
                        <div align="left">
                          <v-pagination
                            v-if="eventResponseLength != 0"
                            :length="eventResponseLength"
                            total-visible="10"
                            v-model="
                              eventResponsePagination.current_page
                            "
                            @input="changeEventResponsePage()"
                            circle
                          >
                          </v-pagination>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div
                          v-if="eventResponseLength != 0"
                          align="center"
                          class="mt-5"
                        >
                          <b class="text--text">Total: </b
                          >{{
                            eventResponsePagination.total
                              | formatNumber
                          }}
                        </div>
                      </v-flex>
                    </v-layout>
                  </div>
                <div v-if="eventView == 'show'">
                  <v-card-title class="secondary primary--text mt-5">
                    Responder Details
                    <v-spacer></v-spacer>
                    <v-btn icon @click="eventView = 'default'">
                      <v-icon class="primary--text"> mdi-close </v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-layout row rap class="mx-1 mt-5">
                      <v-flex xs12 md6>
                        <v-layout column>
                          <v-flex xs12>
                            <v-layout row wrap>
                              <v-flex xs12 md4>
                                <b>Name:</b>
                              </v-flex>
                              <v-flex xs12 md8>
                                {{ assignedResponse.user.f_name }} {{ assignedResponse.user.m_name }} {{ assignedResponse.user.l_name }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 class="mt-5">
                            <v-layout row wrap>
                              <v-flex xs12 md4>
                                <b>Code:</b>
                              </v-flex>
                              <v-flex xs12 md8>
                                {{ assignedResponse.user.code }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-layout column>
                          <v-flex xs12>
                            <v-layout row wrap>
                              <v-flex xs12 md4>
                                <b>Email Address:</b>
                              </v-flex>
                              <v-flex xs12 md8>
                                {{ assignedResponse.user.email }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 class="mt-5">
                            <v-layout row wrap>
                              <v-flex xs12 md4>
                                <b>Phone #:</b>
                              </v-flex>
                              <v-flex xs12 md8>
                                {{ assignedResponse.user.phone }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 class="mt-5">
                        <v-layout row wrap>
                              <v-flex xs12 md2>
                                <b>Bio:</b>
                              </v-flex>
                              <v-flex xs12 md10>
                                <div class="ml-1">
                                  {{ assignedResponse.user.bio }}
                                </div>
                              </v-flex>
                            </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                  <v-card-title class="secondary primary--text mt-5">
                    Response
                  </v-card-title>
                  <v-card-text>
                    <v-layout column>
                      <v-flex xs12 class="mt-3">
                        <div><b>1. Which Challenge does your solution address?</b></div>
                        <div v-html="assignedResponse.challenge_addressed"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>2. Provide a one-line summary of your solution.</b></div>
                        <div>{{ assignedResponse.one_line_summary }}</div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>3. What specific problem are you solving?</b></div>
                        <div v-html="assignedResponse.problem_solved"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>4. What is your solution?</b></div>
                        <div v-html="assignedResponse.solution"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>5. Who does your solution serve, and in what ways will the solution impact their lives?</b></div>
                        <div v-html="assignedResponse.target_audience"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>6. How are you and your team well-positioned to deliver this solution?</b></div>
                        <div v-html="assignedResponse.positioning"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>7. Why are you applying to Solve?</b></div>
                        <div v-html="assignedResponse.application_reason"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>8. What makes your solution innovative?</b></div>
                        <div v-html="assignedResponse.innovative"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>9. What are your impact goals for the next year and the next five years, and how will you achieve them?</b></div>
                        <div v-html="assignedResponse.impact_goals"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>10. How are you measuring your progress toward your impact goals?</b></div>
                        <div v-html="assignedResponse.impact_goal_measure"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>11. What is your theory of change?</b></div>
                        <div v-html="assignedResponse.change_theory"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>12. Describe the core technology that powers your solution.</b></div>
                        <div v-html="assignedResponse.core_technology"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>13. What is your approach to incorporating diversity, equity, and inclusivity into your work?</b></div>
                        <div v-html="assignedResponse.incorporation_approach"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>14. What is your business model?</b></div>
                        <div v-html="assignedResponse.business_model"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>15. What is your plan for becoming financially sustainable?</b></div>
                        <div v-html="assignedResponse.financial_stability"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>16. Share some examples of how your plan to achieve financial sustainability has been successful so far.</b></div>
                        <div v-html="assignedResponse.financial_stability_success"></div>
                      </v-flex>
                      <v-flex xs12 class="mt-3">
                        <div><b>17. Link to Innovaton.</b></div>
                        <div> <a :href="
                          assignedResponse.innovation_link
                        " target="_blank" style="
                          text-decoration: none;
                        ">{{ assignedResponse.innovation_link }}</a> </div>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
    <logData v-if="logDataModel" v-model="logDataModel" />
  </div>
</template>
<style>
.ck-editor__editable {
  min-height: 200px;
}
</style>
<script>
import apiCall from "@/utils/api";
import { mapGetters, mapActions } from "vuex";
import logData from "@/views/accessControl/logData";
import Vue from "vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadAdapter from '@/uploadAdapter.js';

var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0");
});

Vue.use(require("vue-moment"));

export default {
  components: {
    logData,
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      
      view: "open",
      loading: false,
      activateLoading: false,
      deactivateLoading: false,
      broadcastLoading: false,
      eventView: "default",

      activeTab: 'details',
      eventTab: null,

      editor: ClassicEditor,
      editorConfig: {
          extraPlugins: [ function(editor) {editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
              return new uploadAdapter(loader);
          } }],
          language: 'en',
      },

      menu1: false,
      menu2: false,
      allowedStep: (m) => m % 15 === 0,
      valid: true,
      startDatePicker: false,
      endDatePicker: false,
      message: "",
      color: "",
      delete: false,
      activate: false,
      confirm: false,
      eventIndex: null,
      snackbar: false,
      inputRules: [(v) => !!v || "Input is required"],

      coverImageDialog: false,
      imageDialog: false,
      imageLoading: false,
      fileRules: [(v) => !!v || "Kindly selecta a File"],
      imageFile: null,
      imageValid: true,

      openEventSearchTerm: null,
      closedEventSearchTerm: null,
      eventResponseSearchTerm: null,
      searchOpenEventLoader: false,
      searchClosedEventLoader: false,
      openEventSearchButtonDisabled: false,
      closedEventSearchButtonDisabled: false,
      activeEventOwnerTab: 'organisation',
      filteredOpenEvents: false,
      filteredClosedEvents: false,

      eventResponseLoader: false,
      eventResponseSearchLoader: false,
      filteredEventResponses: false,
      eventResponseSearchButtonDisabled: false,

      viewTab: null,
      newEvent: {
        id: null,
        event_type_id: null,
        title: null,
        brief: null,
        description: null,
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        entry_cost: null,
        location: null,
        longitude: null,
        latitude: null,
        interests: [],
        sectors: [],
        sdgs: [],
        institutions: [],
        organisations: []
      },
      assignedEvent: null,
      assignedResponse: null,
      formData: null,
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      monthNames: [
        { name: "January" },
        { name: "February" },
        { name: "March" },
        { name: "April" },
        { name: "May" },
        { name: "June" },
        { name: "July" },
        { name: "August" },
        { name: "September" },
        { name: "October" },
        { name: "November" },
        { name: "December" },
      ],
      eventResponses: [],
      eventResponsePagination: {
        search: " ",
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10,
      },
      logData: {
        message: null,
        model: null,
      },
    };
  },
  created() {
    if (this.$can("event_view")) {
      this.startOpenEventLoader();
      this.startClosedEventLoader();
      this.fetchOpenEvents(this.openEventPagination.current_page);
      this.fetchClosedEvents(this.closedEventPagination.current_page);
      this.getFormData();
    }
  },
  methods: {
    ...mapActions([
      "fetchOpenEvents",
      "fetchClosedEvents",
      "startOpenEventLoader",
      "startClosedEventLoader",
      "stopOpenEventLoader",
      "stopClosedEventLoader",
      "filterOpenEvents",
      "filterClosedEvents",

      "filterLogData",
      "openLogDataModel",
    ]),
    enableOpenEventSearch() {
      this.openEventSearchButtonDisabled = false;
    },
    enableClosedEventSearch() {
      this.closedEventSearchButtonDisabled = false;
    },
    closeStartDate() {
      this.startDatePicker = false;
    },
    closeEndDate() {
      this.endDatePicker = false;
    },
    removeInstitution(item) {
      this.newEvent.institutions.splice(this.newEvent.institutions.indexOf(item), 1)
    },
    removeOrganisation(item) {
      this.newEvent.organisations.splice(this.newEvent.organisations.indexOf(item), 1)
    },
    resetEventResponseSearch() {
      this.eventResponseSearchTerm = null;
      this.filteredEventResponses = false;
      this.eventResponseLoader = false;
      this.changeEventResponsePage();
    },
    changeEventOwnerTab(tab) {
      this.newEvent.institutions = [],
        this.newEvent.organisations = [],

        this.activeEventOwnerTab = tab
    },
    resetOpenEventSearch() {
      this.openEventSearchTerm = null;
      this.filteredClosedEvents = true;
      this.startOpenEventLoader();
      this.fetchOpenEvents(1);
    },
    resetClosedEventSearch() {
      this.closedEventSearchTerm = null;
      this.filteredClosedEvents = true;
      this.stopClosedEventLoader();
      this.fetchClosedEvents(1);
    },
    showEvent(event) {
      this.assignedEvent = event;
      if (this.$can("event_reponse_view")) {
        this.changeEventResponsePage();
      }
      this.changeView("show");
    },
    showEventResponse(response){
      this.assignedResponse = response
      this.eventView = 'show'
    },

    uploadImage() {
      if (this.$refs.imageForm.validate()) {
        this.imageLoading = true;
        let formData = new FormData();

        // files

        formData.append("files", this.imageFile, this.imageFile.name);
        formData.append("id", this.assignedEvent.id);

        apiCall({
          url: "/api/event?type=logo",
          data: formData,
          method: "POST",
        })
          .then((resp) => {
            this.snackbar = true;
            this.message = "Logo Uploaded Successfully";
            this.color = "success";
            this.fetchOpenEvents(this.openEventPagination.current_page);
            this.imageLoading = false;
            this.imageDialog = false;
            this.imageFile = null;
            this.assignedEvent.logo = resp.logo;
            // this.changeView('default')
          })
          .catch((error) => {
            this.imageLoading = false;
          });
      }
    },
    uploadCoverImage() {
      if (this.$refs.imageForm.validate()) {
        this.imageLoading = true;
        let formData = new FormData();

        // files
        formData.append("files", this.imageFile, this.imageFile.name);
        formData.append("id", this.assignedEvent.id);

        apiCall({
          url: "/api/event?type=coverPhoto",
          data: formData,
          method: "POST",
        })
          .then((resp) => {
            this.snackbar = true;
            this.message = "Cover Image Uploaded Successfully";
            this.color = "success";
            this.fetchClosedEvents(this.closedEventPagination.current_page);
            this.imageLoading = false;
            this.coverImageDialog = false;
            this.imageFile = null;
            this.assignedEvent.cover_photo = resp.cover_photo;
            // this.changeView('default')
          })
          .catch((error) => {
            this.imageLoading = false;
          });
      }
    },

    eventResponseSearch() {
      if (this.eventResponseSearchTerm == null) {
        this.eventResponseSearchButtonDisabled = true;
      } else {
        this.eventResponseSearchLoader = true;
        this.eventResponseSearchButtonDisabled = false;
        this.startOpenEventLoader();
        this.startClosedEventLoader();
        apiCall({
          url:
          "/api/event-response?event_id=" +
            this.assignedEvent.id +
            "&page=1&type=search&search=" +
            this.eventResponseSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.eventResponses = resp.data;
            this.eventResponsePagination.current_page =
              resp.current_page;
            this.eventResponsePagination.total = resp.total;
            this.eventResponsePagination.per_page = resp.per_page;

            this.eventResponseSearchLoader = false;
            this.filteredEventResponses = true;
          })
          .catch((error) => {
            this.eventResponseSearchLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            console.log(error);
          });
      }
    },
    changeEventResponsePage() {
      this.eventResponseLoader = true;
      if (this.filteredEventResponses == true) {
        this.eventResponseSearchButtonDisabled = false;
        apiCall({
          url:
            "/api/event-response?event_id=" +
            this.assignedEvent.id +
            "&page=" +
            this.eventResponsePagination.current_page +
            "&type=search&search=" +
            this.eventResponseSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.eventResponseLoader = false;

            this.eventResponses = resp.data;
            this.eventResponsePagination.current_page =
              resp.current_page;
            this.eventResponsePagination.total = resp.total;
            this.eventResponsePagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.eventResponseLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        apiCall({
          url:
            "/api/event-response?event_id=" +
            this.assignedEvent.id +
            "&page=" +
            this.eventResponsePagination.current_page,
          method: "GET",
        })
          .then((resp) => {
            this.eventResponseLoader = false;

            this.eventResponses = resp.data;
            this.eventResponsePagination.current_page =
              resp.current_page;
            this.eventResponsePagination.total = resp.total;
            this.eventResponsePagination.per_page = resp.per_page;
          })
          .catch((error) => {
            console.log(error);
            this.eventResponseLoader = false;
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    editEvent(event) {
      this.newEvent.id = event.id;
      this.newEvent.event_type_id = event.event_type_id
      this.newEvent.title = event.title
      this.newEvent.brief = event.brief
      this.newEvent.description = event.description
      this.newEvent.entry_cost = event.entry_cost
      this.newEvent.location = event.location
      this.newEvent.longitude = event.longitude
      this.newEvent.latitude = event.latitude
    
      this.newEvent.start_date = event.start_time.split(/(\s+)/)[0]
      this.newEvent.start_time = event.start_time.split(/(\s+)/)[2]
      this.newEvent.end_date = event.end_time.split(/(\s+)/)[0]
      this.newEvent.end_time = event.end_time.split(/(\s+)/)[2]

      if(event.institution_events.length != 0){
        this.changeEventOwnerTab('institution')
        this.newEvent.personal = false
        for (let i = 0; i < event.institution_events.length; i++) {
          this.newEvent.institutions.push(event.institution_events[i].institution);
        }
        
      }
      if(event.organisation_events.length != 0){
        this.changeEventOwnerTab('organisation')
        this.newEvent.personal = false
        for (let i = 0; i < event.organisation_events.length; i++) {
          this.newEvent.organisations.push(event.organisation_events[i].organisation);
        }
        
      }

      for (let i = 0; i < event.event_interests.length; i++) {
        this.newEvent.interests.push(event.event_interests[i].interest_id);
      }
      for (let i = 0; i < event.event_sectors.length; i++) {
        this.newEvent.sectors.push(event.event_sectors[i].sector_id);
      }
      for (let i = 0; i < event.event_sdgs.length; i++) {
        this.newEvent.sdgs.push(event.event_sdgs[i].sdg_id);
      }

      this.changeView("create");
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.newEvent.id != null) {
          confirm("Are You Sure You Want to Update Event") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/event/" + this.newEvent.id,
              data: this.newEvent,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Event Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeView("open");
                this.fetchOpenEvents(1);
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm("Are You Sure You Want to Create Event?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/event",
              data: this.newEvent,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Event Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.changeView("open");
                this.fetchOpenEvents(1);
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
                this.fetchOpenEvents(1);
              });
          }
        }
      }
    },
    searchOpenEvent() {
      if (this.openEventSearchTerm == null) {
        this.openEventSearchButtonDisabled = true;
      } else {
        this.searchOpenEventLoader = true;
        this.openEventSearchButtonDisabled = false;
        this.stopOpenEventLoader();
        apiCall({
          url: "/api/event?type=open&typeB=search&search=" + this.openEventSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterOpenEvents(resp);
            this.searchOpenEventLoader = false;
            this.stopOpenEventLoader();
            this.filteredOpenEvents = true;
          })
          .catch((error) => {
            console.log(error.response);
            this.searchOpenEventLoader = false;
            this.stopOpenEventLoader();
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    searchClosedEvent() {
      if (this.closedEventSearchTerm == null) {
        this.closedEventSearchButtonDisabled = true;
      } else {
        this.searchClosedEventLoader = true;
        this.closedEventSearchButtonDisabled = false;
        this.stopClosedEventLoader();
        apiCall({
          url: "/api/event?type=closed&typeB=search&search=" + this.closedEventSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterClosedEvents(resp);
            this.searchClosedEventLoader = false;
            this.stopClosedEventLoader();
            this.filteredOpenEvents = true;
          })
          .catch((error) => {
            this.searchClosedEventLoader = false;
            this.stopClosedEventLoader();
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            console.log(error);
          });
      }
    },
    changeView(view) {
      if (view == "open") {
        this.newEvent.id = null;
        this.newEvent.event_type_id = null
        this.newEvent.title = null
        this.newEvent.brief = null
        this.newEvent.description = null
        this.newEvent.start_date = null
        this.newEvent.start_time = null
        this.newEvent.end_date = null
        this.newEvent.end_time = null
        this.newEvent.entry_cost = null
        this.newEvent.location = null
        this.newEvent.longitude = null
        this.newEvent.latitude = null
        this.newEvent.interests = []
        this.newEvent.sectors = []
        this.newEvent.sdgs = []
        this.newEvent.institutions = []
        this.newEvent.organisations = []
      } else if (view == "show") {

      }
      this.view = view;
    },
    changeOpenEventPage() {
      this.startOpenEventLoader();
      if (this.filteredOpenEvents == true) {
        this.openEventSearchButtonDisabled = false;
        apiCall({
          url:
            "/api/event?type=open&page=" +
            this.openEventPagination.current_page +
            "&typeB=search&search=" +
            this.openEventSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterOpenEvents(resp);
            this.stopOpenEventLoader();
          })
          .catch((error) => {
            this.stopOpenEventLoader();
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            console.log(error);
          });
      } else {
        this.fetchOpenEvents(this.openEventPagination.current_page);
      }
    },
    changeClosedEventPage() {
      this.startClosedEventLoader();
      if (this.filteredClosedEvents == true) {
        this.closedEventSearchButtonDisabled = false;
        apiCall({
          url:
            "/api/event?type=closed&page=" +
            this.closedEventPagination.current_page +
            "&typeB=search&search=" +
            this.closedEventSearchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterClosedEvents(resp);
            this.stopClosedEventLoader();
          })
          .catch((error) => {
            this.stopClosedEventLoader();
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            console.log(error);
          });
      } else {
        this.fetchClosedEvents(this.closedEventPagination.current_page);
      }
    },
    deleteEvent(item) {
      confirm("Are You Sure You Want to Delete Event?") && (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.eventIndex = item.id;
        apiCall({ url: "/api/event/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "Event Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.eventIndex = null;
            this.fetchOpenEvents(this.openEventPagination.current_page);
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.eventIndex = null;
            console.log(error.response);
          });
      }
    },
    activateEvent(item) {
      confirm("Are You Sure You Want to Activate Event?") &&
        (this.activate = true);
      if (this.activate) {
        this.activateLoading = true;
        this.eventIndex = item.id;
        this.newEvent.id = item.id;
        apiCall({
          url: "/api/event?type=activate",
          data: this.newEvent,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Event Activated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.eventIndex = null;
            this.fetchOpenEvents(this.openEventPagination.current_page);
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.eventIndex = null;
            console.log(error.response);
          });
      }
    },
    deactivateEvent(item) {
      confirm("Are You Sure You Want to Deactivate Event?") &&
        (this.activate = true);
      if (this.activate) {
        this.deactivateLoading = true;
        this.eventIndex = item.id;
        this.newEvent.id = item.id;
        apiCall({
          url: "/api/event?type=deactivate",
          data: this.newEvent,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Event Account Deactivated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.eventIndex = null;
            this.fetchOpenEvents(this.openEventPagination.current_page);
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.eventIndex = null;
            console.log(error);
          });
      }
    },
    broadcastEvent(item) {
      confirm("Are You Sure You Want to Broadcast this Event?") &&
        (this.activate = true);
      if (this.activate) {
        this.broadcastLoading = true;
        this.eventIndex = item.id;
        this.newEvent.id = item.id;
        apiCall({
          url: "/api/event?type=broadcast",
          data: this.newEvent,
          method: "POST",
        })
          .then((resp) => {
            this.message = "Event Broadcastd Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.broadcastLoading = false;
            this.activate = false;
            this.eventIndex = null;
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.eventIndex = null;
            console.log(error);
          });
      }
    },
    getFormData() {
      apiCall({
        url: "/api/event?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
        })
        .catch((error) => {
          this.message = "An Error Occurred; Couldn't Get Form Data";
          this.color = "error";
        });
    },
    viewLog(data) {
      this.logData.message = data.id;
      this.logData.model = "App\\Models\\Event"
      this.filterLogData(this.logData);
      this.openLogDataModel();
    },
  },
  computed: {
    ...mapGetters([
      "openEvents", "openEventPagination", "openEventLoader",
      "closedEvents", "closedEventPagination", "closedEventLoader",
      "logDataModel"
    ]),
    formatStartDate() {
      if (this.newEvent.start_date != null) {
        const d = new Date(this.newEvent.start_date);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
    formatEndDate() {
      if (this.newEvent.end_date != null) {
        const d = new Date(this.newEvent.end_date);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
    dateToday() {
      var today = new Date();

      var timestamp =
        today.getFullYear() +
        "-" +
        this.months[today.getMonth()] +
        "-" +
        ("0" + today.getDate()).slice(-2);
      return timestamp;
    },

    openEventLength: function () {
      return Math.ceil(
        this.openEventPagination.total / this.openEventPagination.per_page
      );
    },
    closedEventLength: function () {
      return Math.ceil(
        this.closedEventPagination.total / this.closedEventPagination.per_page
      );
    },
    eventResponseLength: function () {
      return Math.ceil(
        this.eventResponsePagination.total / this.eventResponsePagination.per_page
      );
    },
  },
};
</script>
